import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import { actionCreators as notificationActions } from '../../../store/Notifications'
import { actionCreators as settingActions } from '../../../store/Settings'
import ActionsScheduleTab from './ActionsScheduleTab'
import GeneralScheduleTab from './GeneralScheduleTab'
import TriggerScheduleTab from './TriggerScheduleTab'
import AddReceiversScheduleTab from './AddReceiversScheduleTab'
import ModalFooter from '../ModalFooter'
import API from '../../../REST/RestApi';
import actionTypes from '../../../Utils/actionTypes';
import resultTypes from '../../../Utils/schedulerResultTypes'
import urlValidation from '../../../Utils/urlValidation';
import moment from 'moment'

const RECURRING_DAYS_KEY_PREFIX = "sche_Recur_Days"

const sche_Trigger_Map = {
    0: "sche_Once", //does not have a corresponding input to map to
    1: "sche_Recur_Count",
    2: "sche_Recur_Days",
    3: "sche_Day_Of_Month",
    4: "sche_Minutes"
}

class ScheduleModal extends React.Component {
    //props {arg1: {successCb: fn(), isEdit: true, data: {}, taskId: ""}}
    //Data is the info of the edited task
    state = {
        tab: "General",
        warningActive: false,
        showGlobalWarning: false,
        General: {
            sche_Name: "",
            sche_Desc: "",
        },
        Actions: {
            sche_Policy_Command: -1,
            sche_Policy_Content: {},
            sche_Policy_Firmware_Version: null //only relevant for settings action
        },
        Trigger: {
            sche_Start_Time: new Date(Math.ceil(new Date().getTime() / (1000 * 60 * 5)) * (1000 * 60 * 5)),
            sche_End_Date: new Date(Math.ceil(new Date().getTime() / (1000 * 60 * 5)) * (1000 * 60 * 5)),
            sche_Recur_Count: 1,
            sche_End_Type_Date: 0,
            sche_Trigger_Type: 0,
            sche_Minutes: 5,
            sche_Day_Of_Month: 1,
            sche_Recur_Days_0: 0,
            sche_Recur_Days_1: 0,
            sche_Recur_Days_2: 0,
            sche_Recur_Days_3: 0,
            sche_Recur_Days_4: 0,
            sche_Recur_Days_5: 0,
            sche_Recur_Days_6: 0,
            sche_Last_Run_Result: resultTypes.None
        },

        warningMessages: {
            sche_Name: "",
            sche_Desc: "",
            sche_Recur_Days: "",
            sche_Minutes: "",
            sche_Day_Of_Month: "",
            sche_Recur_Count: "",
            sche_Start_Time: "",
            sche_Policy_Content: ""
        },
    }

    preOnChangeFns = {
        sche_Name: (value) => {
            //Must be between 4-256 characters
            if (value.length > 256) {
                return false
            }
            return true
        },
        sche_Desc: (value) => {
            if (value.length > 256) {
                return false
            }
            return true
        },
        sche_Recur_Count: (value) => {
            //Must be a number
            if (!(/^[0-9]*$/.test(value))) {
                return false
            }
            return true
        },
        sche_Day_Of_Month: (value) => {
            //Must be a number
            if (!(/^[0-9]*$/.test(value))) {
                return false
            }
            return true
        },
        sche_Minutes: (value) => {
            //Must be a number
            if (!(/^[0-9]*$/.test(value))) {
                return false
            }
            return true
        },
    }

    postOnChangeFns = {
        sche_Start_Time: (cb) => {
            if (this.state.Trigger.sche_End_Date < this.state.Trigger.sche_Start_Time) {
                this.setState({ Trigger: { ...this.state.Trigger, sche_End_Date: this.state.Trigger.sche_Start_Time } }, cb())
            }
            else {
                cb()
            }
        },
        sche_Trigger_Type: (cb) => {
            var newState = { ...this.state.warningMessages }
            var currentTarget = sche_Trigger_Map[this.state.Trigger.sche_Trigger_Type]
            //Clear all warnings
            Object.keys(sche_Trigger_Map).forEach((key) => {
                newState[sche_Trigger_Map[key]] = ""
            })
            var warningMessage = ""
            var validationFn = this.validations[currentTarget]
            if (validationFn) {
                warningMessage = validationFn()
            }
            newState[currentTarget] = warningMessage
            this.setState({ warningMessages: newState }, cb)
        },
        sche_Policy_Command: (cb) => {
            this.setState({ Actions: { ...this.state.Actions, sche_Policy_Content: {} } }, cb)
        }
    }

    validations = {
        sche_Start_Time: () => {
            if (this.state.Trigger.sche_Start_Time < new Date()) {
                return "StartTimeError"
            }
            return ""
        },
        sche_Name: () => {
            if (this.state.General.sche_Name.length < 4) {
                return "NameGreaterThan4"
            }
            return ""
        },
        // sche_Desc: () => {
        //     if(this.state.General.sche_Desc.length < 4){
        //         return "Description must be at least 4 characters.(Placeholder Message)"
        //     }
        //     return "" 
        // },
        sche_Recur_Days: () => {
            //Validate that at least one day of the week is seleccted
            var validationState = [0, 1, 2, 3, 4, 5, 6].some((n) => {
                if (this.state.Trigger["sche_Recur_Days_" + n]) {
                    return true
                }
            })
            if (validationState || this.state.Trigger.sche_Trigger_Type != 2) {
                return ""
            }
            else {
                return "AtLeastOneDay"
            }
        },
        sche_Policy_Command: () => {
            if (this.state.Actions.sche_Policy_Command == -1) {
                return "ActionMustBeSelected"
            }
            return ""
        },
        sche_Day_Of_Month: () => {
            if ((this.state.Trigger.sche_Day_Of_Month <= 0 || this.state.Trigger.sche_Day_Of_Month > 31) && this.state.Trigger.sche_Trigger_Type == 3) {
                return "OutOfBoundDayWarning"
            }
            return ""
        },
        sche_Minutes: () => {
            if (this.state.Trigger.sche_Minutes <= 0 && this.state.Trigger.sche_Trigger_Type == 4) {
                return "MinutesInputWarning"
            }
            return ""
        },
        sche_Recur_Count: () => {
            if (this.state.Trigger.sche_Day_Of_Month <= 0 && this.state.Trigger.sche_Trigger_Type == 1) {
                return "DailyInputWarning"
            }
            return ""
        },
        sche_downloadUrl: () => {
            let policyCommand = this.state.Actions.sche_Policy_Command
            if (policyCommand == actionTypes.SetBackground || policyCommand == actionTypes.SetScreenSaver) {
                let downloadUrl = this.state.Actions.sche_Policy_Content.downloadURL
                if (policyCommand == actionTypes.SetBackground) {
                    if (!urlValidation.isValidBackgroundUrl(downloadUrl)) return "InvalidBackgroundUrlForm"
                } else if (policyCommand == actionTypes.SetScreenSaver) {
                    if (!urlValidation.isValidScreenSaverUrl(downloadUrl)) return "InvalidScreenSaverUrlForm"
                }
            } else if (policyCommand == actionTypes.UpdateFirmware) {
                let downloadUrl = this.state.Actions.sche_Policy_Content.fwupdateURL
                if (!urlValidation.isValidFirmwareUrl(downloadUrl)) return "InvalidFirmwareUrlForm"
            }
            return ""
        }
    }

    tabs = {
        "General": GeneralScheduleTab,
        "Actions": ActionsScheduleTab,
        "Trigger": TriggerScheduleTab,
        "Add Receivers": AddReceiversScheduleTab
    }

    componentDidMount() {
        if (this.props.arg1.isEdit) {
            var data = this.props.arg1.data
            var sche_Start_Time = moment.utc(data.sche_Start_Time).toDate();
            var sche_End_Date = moment.utc(data.sche_End_Date).toDate();
            if (sche_End_Date > moment(new Date(9990, 1, 1, 0, 0, 0, 0))) {
                sche_End_Date = moment.utc(data.sche_Start_Time).toDate();
            }
            // if(sche_Start_Time < new Date()){
            //     sche_Start_Time = new Date(Math.ceil(new Date().getTime()/(1000*60*5))*(1000*60*5))
            // }

            if (data.sche_Policy_Command == actionTypes.Settings) {
                this.props.saveData(data.sche_Policy_Content, data.sche_Policy_Firmware_Version)
            }
            this.setState({
                General: {
                    ...this.state.General, sche_Name: data.sche_Name, sche_Desc: data.sche_Desc
                },
                Actions: {
                    ...this.state.Actions,
                    sche_Policy_Command: data.sche_Policy_Command,
                    sche_Policy_Content: data.sche_Policy_Content,
                    sche_Policy_Firmware_Version: data.sche_Policy_Firmware_Version
                },
                Trigger: {
                    ...this.state.Trigger,
                    sche_Start_Time: sche_Start_Time,
                    sche_End_Date: sche_End_Date,
                    sche_Recur_Count: data.sche_Recur_Count,
                    sche_End_Type_Date: data.sche_End_Type_Date,
                    sche_Trigger_Type: data.sche_Trigger_Type,
                    sche_Minutes: data.sche_Minutes,
                    sche_Day_Of_Month: data.sche_Day_Of_Month,
                    sche_Recur_Days_0: data.sche_Recur_Days_0,
                    sche_Recur_Days_1: data.sche_Recur_Days_1,
                    sche_Recur_Days_2: data.sche_Recur_Days_2,
                    sche_Recur_Days_3: data.sche_Recur_Days_3,
                    sche_Recur_Days_4: data.sche_Recur_Days_4,
                    sche_Recur_Days_5: data.sche_Recur_Days_5,
                    sche_Recur_Days_6: data.sche_Recur_Days_6,
                    sche_Last_Run_Result: data.sche_Last_Run_Result
                }
            }, () => {
                //Initialize warning messages
                var newWarnings = {}
                Object.keys(this.validations).forEach((key) => {
                    newWarnings[key] = this.validations[key]()
                })
                this.setState({ warningMessages: newWarnings })
            })
        }
        else {
            //Initialize warning messages
            var newWarnings = {}
            Object.keys(this.validations).forEach((key) => {
                newWarnings[key] = this.validations[key]()
            })
            this.setState({ warningMessages: newWarnings })
        }
    }

    changeTab = (tabName) => {
        this.setState({
            tab: tabName
        })
    }

    updateWarningState = () => {
        if (this.state.warningActive) {
            //If the warning is currently active and all conflicts are resolved after the change turn off the global warning message
            if (Object.keys(this.state.warningMessages).every((key) => { if (this.state.warningMessages[key].length == 0) { return true } })) {
                this.setState({ showGlobalWarning: false })
            }
            else {
                this.setState({ showGlobalWarning: true })
            }
        }
    }

    onActionValidateChange = (validationState) => {
        var warningMessage = "Policy Error."
        if (validationState) {
            warningMessage = ""
        }
        this.setState({ warningMessages: { ...this.state.warningMessages, ["sche_Policy_Content"]: warningMessage } })
    }

    onChange = (event, key, subkey) => {
        if (this.preOnChangeFns[subkey]) {
            if (!this.preOnChangeFns[subkey](event.target.value)) {
                //If preOnChange returns false prevent the event from occurrinng
                return
            }
        }
        var value = event.target.value
        this.setState({
            [key]: {
                ...this.state[key],
                [subkey]: value
            }
        }, () => {
            var warningMessage = ""
            if (subkey.includes(RECURRING_DAYS_KEY_PREFIX)) {
                subkey = RECURRING_DAYS_KEY_PREFIX
            }
            var validationFn = this.validations[subkey]
            if (validationFn) {
                warningMessage = validationFn()
            }
            this.setState({ warningMessages: { ...this.state.warningMessages, [subkey]: warningMessage } }, () => {
                if (this.postOnChangeFns[subkey]) {
                    this.postOnChangeFns[subkey](this.updateWarningState)
                }
                else {
                    this.updateWarningState()
                }
            })
        })
    }

    renderTab = () => {
        var Tab = this.tabs[this.state.tab]
        return (
            <Tab onChange={(event, subkey) => { this.onChange(event, this.state.tab, subkey) }}
                onValidationChange={this.state.tab == "Actions" ? this.onActionValidateChange : null}
                values={this.state[this.state.tab]} warningActive={this.state.warningActive} warnings={this.state.warningMessages}
            />
        )
    }

    submit = () => {
        //Run checks and then on succes call the 
        //Actual submit function below
        if (this.state.Actions.sche_Policy_Content["host"]) {
            var submit = () => {
                if (Object.keys(this.state.warningMessages).every((key) => { if (this.state.warningMessages[key].length == 0) { return true } })) {
                    if (this.props.arg1.isEdit) {
                        API.editTask(this.props.arg1.data.sche_ID, {
                            ...this.state.General,
                            ...this.state.Actions,
                            ...this.state.Trigger,
                            sche_Stat: this.props.arg1.data.sche_Stat,
                            sche_Last_Run_Time: moment.utc(this.props.arg1.data.sche_Last_Run_Time).toDate(),
                        }, () => {
                            this.props.hideModal()
                            this.props.arg1.successCb() //successCb passed in from SchedulerTable.js
                        }, (err) => { this.props.arg1.errorCb(err) })
                    }
                    else {
                        API.createTaskUpload({ ...this.state.General, ...this.state.Actions, ...this.state.Trigger }, () => {
                            this.props.hideModal()
                            this.props.arg1.successCb() //successCb passed in from SchedulerTable.js
                        }, (err) => { this.props.arg1.errorCb(err) })
                    }
                }
                else {
                    this.setState({ warningActive: true, showGlobalWarning: true })
                }
            }
        }
        else {
            var submit = () => {
                if (Object.keys(this.state.warningMessages).every((key) => { if (this.state.warningMessages[key].length == 0) { return true } })) {
                    if (this.props.arg1.isEdit) {
                        API.editTask(this.props.arg1.data.sche_ID, {
                            ...this.state.General,
                            ...this.state.Actions,
                            ...this.state.Trigger,
                            sche_Stat: this.props.arg1.data.sche_Stat,
                            sche_Last_Run_Time: moment.utc(this.props.arg1.data.sche_Last_Run_Time).toDate(),
                        }, () => {
                            this.props.hideModal()
                            this.props.arg1.successCb() //successCb passed in from SchedulerTable.js
                        }, (err) => { this.props.arg1.errorCb(err) })
                    }
                    else {
                        API.createTask({ ...this.state.General, ...this.state.Actions, ...this.state.Trigger }, () => {
                            this.props.hideModal()
                            this.props.arg1.successCb() //successCb passed in from SchedulerTable.js
                        }, (err) => { this.props.arg1.errorCb(err) })
                    }
                }
                else {
                    this.setState({ warningActive: true, showGlobalWarning: true })
                }
            }
        }


        //Validation of submission and any pre-submit changes. Will call submit() from above if successful
        if (this.state.Actions.sche_Policy_Command == actionTypes.Settings) {
            if (this.props.state) {
                // var validationState = Object.keys(this.props.data).every((key) => {
                //     return (!this.props.state.settings[key].error)
                // })
                this.props.validateData((isDataValid, data) => {
                    if (!isDataValid) {
                        this.setState({ warningActive: true, showGlobalWarning: true })
                        return
                    }
                    else {
                        var warningMessage = this.validations.sche_Start_Time()
                        if (!warningMessage) {
                            this.setState({ Actions: { ...this.state.Actions, sche_Policy_Content: this.props.data, sche_Policy_Firmware_Version: this.props.fwVersion } }, submit)
                        }
                        else {
                            this.setState({ warningMessages: { ...this.state.warningMessages, sche_Start_Time: warningMessage } }, () => {
                                submit()
                            })
                        }
                    }
                })

            }
            else {
                submit()
            }
        }
        else if (this.state.Actions.sche_Policy_Command == actionTypes.UpdateFirmware) {
            var message = this.validations.sche_downloadUrl()
            if (!message) {
                if (!this.state.Actions.sche_Policy_Content.forceFwupdate) {
                    //Set to default values if values are empty
                    this.setState({
                        Actions: {
                            ...this.state.Actions, sche_Policy_Content: {
                                fwupdateURL: this.state.Actions.sche_Policy_Content.fwupdateURL,
                                forceFwupdate: "1",
                                downgradeEnable: "1",
                                specialFwUpdate: "0",
                            }
                        }
                    }, submit)
                } else {
                    submit()
                }
            }
            else this.setState({ warningMessages: { ...this.state.warningMessages, sche_Policy_Content: message } }, () => {
                submit()
            })
        }
        else if (this.state.Actions.sche_Policy_Command == actionTypes.SetBackground) {
            var message = this.validations.sche_downloadUrl()
            if (!message) submit()
            else this.setState({ warningMessages: { ...this.state.warningMessages, sche_Policy_Content: message } }, () => {
                submit()
            })
        }
        else if (this.state.Actions.sche_Policy_Command == actionTypes.SetScreenSaver) {
            var message = this.validations.sche_downloadUrl()
            if (!message) submit()
            else this.setState({ warningMessages: { ...this.state.warningMessages, sche_Policy_Content: message } }, () => {
                submit()
            })
        }
        else {
            var warningMessage = this.validations.sche_Start_Time()
            if (!warningMessage) {
                submit()
            }
            else {
                this.setState({ warningMessages: { ...this.state.warningMessages, sche_Start_Time: warningMessage } }, () => {
                    submit()
                })
            }
        }
    }


    render() {
        return (
            <div>
                <ul className="nav nav-tabs">
                    {Object.keys(this.tabs).map((key, index) => {
                        return (
                            <li key={"tab" + index} className="nav-item ">
                                <div className={key == this.state.tab ? "active nav-link" : "nav-link"} onClick={() => { this.changeTab(key) }}>{key}</div>
                            </li>
                        )
                    })}
                </ul>
                <div className='modal-body'>
                    <div className="error">{this.state.showGlobalWarning && "Resolve all conflicts before submitting"}</div>
                    {this.renderTab()}
                </div>
                <ModalFooter button2Submit={() => { this.submit() }} />
            </div>
        );
    }
}


export default connect(
    state => state.settings,
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions, ...notificationActions, ...settingActions }, dispatch)
)(ScheduleModal);
