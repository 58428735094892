import React from 'react';
import { Link } from 'react-router-dom'

class NoPrivilege extends React.Component {
    render() {
        return (
            <div className="page-wrap d-flex flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            {/* <span className="display-1 d-block">Error</span> */}
                            <div className="mb-4 lead">You do not have the privilege to view this page.</div>
                            <Link to="/Dashboard" className="btn btn-link">Back to Dashboard</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NoPrivilege