const urlValidation =  {
    isValidBackgroundUrl: (url) => {
        let regex = /^(http:\/\/).*\.(png|jpg|jpeg|PNG|JPG|JPEG)$/
        return regex.test(url)
    },
    isValidScreenSaverUrl: (url) => {
        let regex = /^(http:\/\/).*\.(png|PNG)$/
        return regex.test(url)
    },
    isValidFirmwareUrl: (url) => {
        let regex = /^(http:\/\/).*\.(img|IMG)$/
        return regex.test(url)
    }
}

export default urlValidation