const API = {
    login: function (username, password, successCb, errorCb) {
        var formData = new FormData()
        formData.append("username", username)
        formData.append("password", password)
        //fetch('https://172.20.0.5:4001/api/authenticate', {
        fetch('api/authenticate', {
            //fetch('https://google.com', {
            method: "POST",
            body: formData
        }).then((res) => {
            if (res)
                if (res.ok) {
                    return res.json()
                }
                else {
                    return res.json().then(message => {
                        throw message["message"];
                    });
                }
            else
                throw "Could not connect to the server."
        }).then((user) => {
            // console.log(user)
            if (successCb)
                successCb(user);
        }).catch((error) => {
            // console.log(error);
            if (errorCb)
                errorCb(String(error));
        })
    },
    // get all member
    getUsers: function (token, successCb, errorCb) {
        var formData = new FormData();
        formData.append("token", token);
        fetch('api/user/all', {
            method: "POST",
            body: formData
        }).then((res) => {
            if (res.ok)
                return res.json()
            else {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then((users) => {
            if (users && successCb) {
                successCb(users);
            }
        }).catch((error) => {
            if (errorCb)
                errorCb(String(error))
        })
    },
    //create new member
    createUser: function (token, firstName, lastName, username, email, password, successCb, errorCb) {
        var formData = new FormData()
        formData.append("token", token)
        formData.append("firstName", firstName)
        formData.append("lastName", lastName)
        formData.append("userName", username)
        formData.append("password", password)
        formData.append("email", email)
        fetch('/api/user', {
            method: "POST",
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then(() => {
            successCb()
        }).catch((error) => {
            errorCb(String(error))
        })
    },
    //edit member
    editUser: function (token, firstName, lastName, username, email, password, successCb, errorCb) {
        var formData = new FormData()
        formData.append("token", token)
        formData.append("firstName", firstName)
        formData.append("lastName", lastName)
        formData.append("userName", username)
        formData.append("password", password)
        formData.append("email", email)
        // formData.append("role", role)

        fetch('/api/user', {
            method: "PUT",
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then(() => {
            successCb()
        }).catch((error) => {
            errorCb(String(error))
        })
    },
    // update member admin role
    updateUserAdminRole: function (token, userId, roleId, successCb, errorCb) {
        var formData = new FormData();
        formData.append("token", token);
        formData.append("userId", userId);
        formData.append("roleId", roleId);
        fetch('api/user/role', {
            method: "PUT",
            body: formData
        }).then((res) => {
            if (res.ok)
                return
            else {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then(() => {
            successCb()
        }).catch((error) => {
            if (errorCb)
                errorCb(String(error))
        })
    },
    // delete member
    deleteUser: function (token, username, successCb, errorCb) {
        var formData = new FormData()
        formData.append("token", token);
        formData.append("username", username);
        fetch('/api/user/', {
            method: "DELETE",
            body: formData
        }).then((res) => {
            if (!res.ok) {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then(() => {
            successCb()
        }).catch((error) => {
            errorCb(String(error))
        })
    },
    // get all site users
    getSiteUsers: function (token, siteIdList, successCb, errorCb) {
        var formData = new FormData();
        formData.append("token", token);
        formData.append("siteList", JSON.stringify(siteIdList));
        var newSuccessCb = successCb;
        fetch('api/siteuser', {
            method: "post",
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then((users) => {
            if (successCb)
                successCb(users)
        }).catch((error) => {
            if (errorCb)
                errorCb(String(error))
        })
    },
    updateSiteUserRole: function (token, userId, siteId, roleId, successCb, errorCb) {
        var formData = new FormData()
        formData.append("token", token);
        formData.append("userId", userId);
        formData.append("siteId", siteId);
        formData.append("roleId", roleId);

        fetch('/api/siteuser', {
            method: "PUT",
            body: formData
        }).then((res) => {
            if (res.ok)
                return;
            else {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then(() => {
            if (successCb)
                successCb();
        }).catch((error) => {
            if (errorCb)
                errorCb(String(error));
        })
    },

    deleteSiteUserFromSite: function (token, userId, siteId, roleId, successCb, errorCb) {
        var formData = new FormData()
        formData.append("token", token);
        formData.append("userId", userId);
        formData.append("siteId", siteId);
        formData.append("roleId", roleId);

        fetch('/api/siteuser', {
            method: "DELETE",
            body: formData
        }).then((res) => {
            if (res.ok)
                return;
            else {
                return res.json().then(message => {
                    throw message["message"];
                });
            }
        }).then(() => {
            if (successCb)
                successCb();
        }).catch((error) => {
            if (errorCb)
                errorCb(String(error));
        })
    }
}

export default API;