import React from 'react'
import {ContextMenu, MenuItem,ContextMenuTrigger} from "react-contextmenu"

class SchedulerContext extends React.Component{

    render(){
        return(
            <ContextMenu className="context-menu" onHide={this.props.onHide} id={this.props.id}>
                <MenuItem><div onClick={!this.props.disabled ? this.props.onEdit : null} className={(this.props.disabled ? "context-disabled " : "") + "context-menu-item"} ><i className="fa fa-edit" />Edit Task</div></MenuItem>
                <MenuItem><div onClick={!this.props.disabled ? this.props.onDelete : null} className={(this.props.disabled ? "context-disabled " : "") + "context-menu-item"} ><i className="fa fa-minus-circle" />Delete Task</div></MenuItem>
                <MenuItem><div className="context-menu-separator"/></MenuItem>
                <MenuItem><div onClick={!this.props.disabled ? this.props.onAddRx : null} className={(this.props.disabled ? "context-disabled " : "") + "context-menu-item"} ><i className="fa fa-plus-circle" />Add Receivers</div></MenuItem>
                <MenuItem><div onClick={!this.props.disabled ? this.props.onReleaseRx : null} className={(this.props.disabled ? "context-disabled " : "") + "context-menu-item"} ><i className="fa fa-minus-circle" />Remove Receivers</div></MenuItem>
            </ContextMenu>
        )
    }
}

export default SchedulerContext