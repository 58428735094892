import {actionCreators as Sites} from './Sites'
import $ from 'jquery'

const taskSelectionType = 'UPDATE_TASKS';
const tasksTableType = "SET_TASKS_TABLE"
const lastTaskSelectionType = "LAST_SELECTED_TASK"
const initialState = {
    selectedTasks: {},
    tasksTable: null,
    lastSelected: null,
    lastSelectedIndex: null
}

export const actionCreators = {
    setTasksTable: (tasksTable) => async(dispatch,getState) => {
        dispatch({type: tasksTableType, tasksTable: tasksTable})
    },
    updateLastSelected: (id,index) => async(dispatch,getState) => {
        var state = getState()
        var tasks = state.tasks
        var receivers = state.receiver
        dispatch({type: lastTaskSelectionType, lastSelected: id, lastSelectedIndex: index})
        if(tasks.lastSelected != id){
            receivers.dataTables.rows().invalidate()
            receivers.dataTables.draw()
        }
    },
    updateTaskSelection: (tasks) => async (dispatch, getState) => {
        var state = getState()
        var receivers = state.receiver
        var sites = state.sites
        var selectedTasks = {}
        for(var i = 0; i<tasks.length; i++){
            selectedTasks[tasks[i].sche_ID] = tasks[i]
        }
        dispatch({ type: taskSelectionType, selectedTasks: selectedTasks })
        if(tasks.length > 0){
            dispatch(Sites.deselectSitesFull())
        }
        if(Object.keys(state.sites.selectedSites.sites).length <= 0 && tasks.length <=0){
            dispatch(Sites.deselectSites())
        }
    },
    removeTaskSelection: (tasks) => async (dispatch,getState) => {
        var state = getState()
        var receivers = state.receiver
        var selectedTasks = state.tasks.selectedTasks
        // if(Object.keys(selectedTasks).length == tasks.length && tasks.length > 0){
        //     dispatch(Sites.deselectSites())
        // }
        for(var i = 0; i<tasks.length; i++){
            delete selectedTasks[tasks[i].sche_ID]
        }

        if(Object.keys(selectedTasks).length <= 0){
            dispatch(Sites.deselectSites())
        }

        dispatch({ type: taskSelectionType, selectedTasks: selectedTasks })
        if(receivers.dataTables){
            receivers.dataTables.rows().invalidate()
            receivers.dataTables.draw()
        }
    },

    clearTaskSelection: () => async (dispatch,getState) => {
        var state = getState()
        var tasks = state.tasks
        if(tasks.tasksTable){
            tasks.tasksTable.rows().deselect()
        }
        dispatch({ type: taskSelectionType, selectedTasks: {}})
        dispatch(actionCreators.updateLastSelected(null,null))
        $('tr').removeClass('last-selected')
    },
};

export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;

    //Return copy of state with edited state
    if (action.type === taskSelectionType) {
        return { ...state, selectedTasks: action.selectedTasks};
    }
    if(action.type === tasksTableType){
        return {...state, tasksTable: action.tasksTable}
    }
    if(action.type === lastTaskSelectionType){
        return {...state, lastSelected: action.lastSelected, lastSelectedIndex: action.lastSelectedIndex}
    }
    return state;
};
