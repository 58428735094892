import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import ResetBody from '../ModalBodies/ResetBody'
import { Translate } from 'react-localize-redux'

class Reset extends React.Component {
    render() {
        return (
            <div>
                <div className='modal-body'>
                    <div>
                        {this.props.isModal && <p className="lead"><Translate id="ResetConfirmation"/></p>}
                        <p className="modal-text-reset-note">
                            <Translate id="Reset1" />
                        </p>
                        <ul className="list-unstyled modal-text">
                            <li >
                                <span>*</span><span className="reset-note"><Translate id="Reset2" /></span>
                            </li>
                            <li>
                            <span>*</span><span className="reset-note"><Translate id="Reset3" /></span>
                               
                            </li>
                            <li>
                            <span>*</span><span className="reset-note"><Translate id="Reset4" /></span>            
                            </li>             
                        </ul>
                    </div>
                </div>
                {this.props.isModal && <ModalFooter button2Submit={() => { this.props.resetRx(); this.props.hideModal() }} />}
            </div>
        );
    }
}


export default connect(
    state => state,
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions }, dispatch)
)(Reset);
