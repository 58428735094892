import React from 'react'
import MemberSummaryCard from './components/MemberSummaryCard'
import MembersTable from './components/MembersTable'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userActions } from '../../store/User'
import NoPrivilege from '../../components/NoPrivilegeGeneric'

class Members extends React.Component {
    componentDidMount() {
        this.props.validate();
    }
    render() {
        if (this.props.isSystemAdmin == "true" || this.props.username === "Administrator") {
            return (
                <div>
                    {/* <div className="row no-gutters">
                    <div className='col-md-12'>
                        <MemberSummaryCard/>
                    </div>
                </div> */}
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <MembersTable />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <NoPrivilege />
            )
        }
    }
}

export default connect(
    state => state.user,
    dispatch => bindActionCreators({ ...userActions }, dispatch)
)(Members);
