import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import { actionCreators as siteActions } from '../../../store/Sites'
import { actionCreators as userActions } from '../../../store/User'
import ModalFooter from '../ModalFooter'
import AddSiteBody from '../ModalBodies/AddSiteBody'
import AddUserToSiteBody from '../ModalBodies/AddUserToSiteBody'
import API from '../../../REST/RestApi'
import UsersApi from '../../../REST/UsersApi'
import Utils from '../../../Utils/utils.js'

class AddSite extends React.Component {
    constructor(props) {
        super(props);
        this.siteOptions = []
        this.state = {
            step: 1,
            parentSite: "",
            siteName: "",
            allUsers: [],
            admins: [],
            monitors: [],
            removed: {},
            selected: {
                start: -1,
                end: -1,
                direction: "UP",
                users: {}
            },
            warningText: "SiteNameLengthWarning",
            showWarning: false,
            selectTarget: 0,
            searchInput: "",
            overRideWarning: false
        }
    }

    originalAdmins = {}
    originalMonitors = {}

    componentDidMount = () => {
        var token = this.props.token
        if (this.props.arg1 == "addUser") {
            //If adding users to an existing site skip to the second step and auto select the parent site
            this.setState({ step: 2 })
            var siteId = this.props.arg2.id
            API.getUsers(token, (res) => {
                var users = []
                var userIndexMap = {}
                res.forEach((user,index)=>{
                    users.push({id: user.username, name: user.username, added: null, originIndex: index, roleId: null})
                    userIndexMap[user.username] = index
                })
                this.originalUsers = users
                API.getUserSiteRole(siteId,(res)=>{
                    var admins = []
                    var monitors = []
                    this.userMap = {}

                    res.data.forEach((user,index)=>{
                        if(!this.userMap[user.userId]){
                            this.userMap[user.userId] = {
                                admin: null,
                                monitor: null
                            }
                        }

                        if(user.roleId == "CMSSystemAdmin" || user.roleId == "ScreenBeamAdmin"){
                            this.userMap[user.userId].admin = user
                        }
                        else{
                            this.userMap[user.userId].monitor = user
                        }

                        // var user = this.userMap[userKey]                        
                        var originIndex = userIndexMap[user.userId]
                        if(originIndex == undefined){
                            return
                        }
                        var inherited 
                        var inheritedName
                        if(siteId != user.siteId){
                            inherited = user.siteId
                            user.inherited = user.siteId
                            if(user.siteId in this.props.flatSites){
                                inheritedName = this.props.flatSites[user.siteId].name
                            }
                            else{ //If inheritor is not in the site map it must be the root site
                                inheritedName = "Root"
                            }
                        }
                        users[originIndex].roleId = user.roleId
                        if(user.roleId == "CMSSystemAdmin" || user.roleId == "ScreenBeamAdmin"){
                            admins.push({id: user.userId, name: user.userId, added: true, originIndex: originIndex, roleId: user.roleId, inherited: inherited, inheritedName: inheritedName})
                            users[originIndex].added = true
                            this.originalAdmins[user.userId] = user
                            users[originIndex].inherited = inherited
                        }
                        else if (user.roleId == "ScreenBeamMonitorToASite"){
                            var added = true
                            if(user.inherited && users[originIndex].added == null){
                                added = false
                            }
                            monitors.push({id: user.userId, name: user.userId, added: added, originIndex: originIndex, roleId: user.roleId, inherited: inherited,inheritedName: inheritedName})
                            users[originIndex].added = added
                            this.originalMonitors[user.userId] = user
                        }
                        // users[originIndex].inherited = inherited
                    })
                    this.setState({allUsers: users, admins: admins, monitors: monitors})
                })
            })

        }
        else {
            this.siteOptions = [...this.props.orderedSites]
            this.siteOptions[0] = { key: "-1", value: "Root", nestLevel: 0 } //Replace the "All Receivers" option with "Root"
            if (this.siteOptions[this.siteOptions.length - 1].key == "-2") {
                this.siteOptions.pop()//Remove the "Unassigned" option
            }
            var parentSiteId = Object.keys(this.props.selectedSites.sites)[0] || this.siteOptions[0].key
            if(parentSiteId == "-2"){
                //If they selected 'unassigned' set it to the Root site
                parentSiteId = "-1"
            }
            this.setState({ parentSite: parentSiteId })
        }
    }


    //Create new initialized "select" object
    selectInit = () => {
        return {
            start: -1,
            end: -1,
            direction: "UP",
            users: {}
        }
    }
    addAdmin = () => {
        //Admins can only be added when selecting from all users
        if (this.state.selectTarget != 0) {
            return
        }
        var updatedAllUsers = []
        var updatedRemoved = { ...this.state.removed }
        var admins = this.state.admins
        this.state.allUsers.forEach((user) => {
            if (user.id in this.state.selected.users) {
                if (user.id in updatedRemoved) {
                    delete updatedRemoved[user.id]
                }
                user.added = true
                admins.push(user)
            }
            updatedAllUsers.push(user)
        })
        this.setState({ allUsers: updatedAllUsers, admins: admins, removed: updatedRemoved, selected: this.selectInit() })
    }

    removeAdmin = () => {
        //Admins can only be removed when selecting from admins box
        if (this.state.selectTarget != 1) {
            return
        }
        var updatedAllUsers = []
        var updatedRemoved = { ...this.state.removed }
        var updatedOriginalUsers = []
        var admins = this.state.admins
        //"Return" all selected users back to the Full All Users Group, at the same time rebuild the filtered groups
        this.originalUsers.forEach((user) => {
            if (user.id in this.state.selected.users) {
                user.added = false
            }
            updatedOriginalUsers.push(user)
            if (user.name.toUpperCase().includes(this.state.searchInput.toUpperCase())) {
                updatedAllUsers.push(user)
            }
        })
        this.originalUsers = updatedOriginalUsers

        //Remove all selected admins from the admins list and also push them into the removed dictionary
        admins = admins.filter((admin) => {
            if (admin.id in this.state.selected.users) {
                updatedRemoved[admin.id] = admin
                return false
            }
            return true
        })

        this.setState({ allUsers: updatedAllUsers, admins: admins, removed: updatedRemoved, selected: this.selectInit() })
    }

    addMonitor = () => {
        //Monitors can only be added when selecting from all users
        if (this.state.selectTarget != 0) {
            return
        }
        var updatedAllUsers = []
        var updatedRemoved = { ...this.state.removed }
        var monitors = this.state.monitors

        //Filter out users that inherited their role.
        var users = this.state.allUsers

        users.forEach((user) => {
            updatedAllUsers.push(user)
            if (user.id in this.state.selected.users) {
                if(this.userMap[user.id] && this.userMap[user.id].monitor && this.userMap[user.id].monitor.inherited){
                    return
                }
                if (user.id in updatedRemoved) {
                    delete updatedRemoved[user.id]
                }
                user.added = true
                monitors.push(user)
            }
            
        })
        this.setState({ allUsers: updatedAllUsers, monitors: monitors, removed: updatedRemoved, selected: this.selectInit() })
    }

    removeMonitor = () => {
        //Monitors can only be removed when selecting from Monitors box
        if (this.state.selectTarget != 2) {
            return
        }
        var updatedAllUsers = []
        var updatedRemoved = { ...this.state.removed }
        var updatedOriginalUsers = []
        var monitors = this.state.monitors
        //"Return" all selected users back to the Full All Users Group, at the same time rebuild the filtered groups
        this.originalUsers.forEach((user) => {
            if (user.id in this.state.selected.users) {
                user.added = false
            }
            updatedOriginalUsers.push(user)
            if (user.name.toUpperCase().includes(this.state.searchInput.toUpperCase())) {
                updatedAllUsers.push(user)
            }
        })
        this.originalUsers = updatedOriginalUsers

        //Remove all selected monitors from the monitors list
        monitors = monitors.filter((monitor) => {
            if (monitor.id in this.state.selected.users) {
                updatedRemoved[monitor.id] = monitor
                return false
            }
            return true
        })
        this.setState({ allUsers: updatedAllUsers, monitors: monitors, removed: updatedRemoved, selected: this.selectInit() })
    }

    onSiteParamsChange = (event, target) => {
        this.setState({ [target]: event.target.value })
    }

    onSiteNameChange = (event) => {
        var name = event.target.value
        if(name.length < 2 || name.length > 36){
            this.setState({ siteName: event.target.value, warningText: "SiteNameLengthWarning"})
        }
        else{
            this.setState({siteName: event.target.value, warningText: ""})
        }
    }

    renderButton1 = () => {
        if (this.state.step == 1) {
            return () => { 
                if(this.state.warningText.length > 0){
                    this.setState({showWarning: true})
                    return
                }
                var token = this.props.token
                var parentSiteId = this.state.parentSite
                API.getUsers(token,(res) => {
                    var users = []
                    var userIndexMap = {}
                    res.forEach((user,index)=>{
                        users.push({id: user.username, name: user.username, added: null, originIndex: index, roleId: null})
                        userIndexMap[user.username] = index
                    })
                    this.originalUsers = users
                    API.getUserSiteRole(parentSiteId,(res)=>{
                        var admins = []
                        var monitors = []
                        this.userMap = {}
                        res.data.forEach((user,index)=>{
                            if(!this.userMap[user.userId]){
                                this.userMap[user.userId] = {
                                    admin: null,
                                    monitor: null
                                }
                            }
    
                            if(user.roleId == "CMSSystemAdmin" || user.roleId == "ScreenBeamAdmin"){
                                this.userMap[user.userId].admin = user
                            }
                            else{
                                this.userMap[user.userId].monitor = user
                            }
    
                            var originIndex = userIndexMap[user.userId]
                            if(originIndex == undefined){
                                return
                            }

                            var inherited 
                            var inheritedName
                            // if(parentSiteId == user.siteId){
                            inherited = user.siteId
                            user.inherited = user.siteId
                            if(user.siteId in this.props.flatSites){
                                inheritedName = this.props.flatSites[user.siteId].name
                            }
                            else{ //If inheritor is not in the site map it must be the root site
                                inheritedName = "Root"
                            }
                            // }
                            users[originIndex].roleId = user.roleId
                            if(user.roleId == "CMSSystemAdmin" || user.roleId == "ScreenBeamAdmin"){
                                admins.push({id: user.userId, name: user.userId, added: true, originIndex: originIndex, roleId: user.roleId, inherited: inherited, inheritedName: inheritedName})
                                users[originIndex].added = true
                                this.originalAdmins[user.userId] = user
                                users[originIndex].inherited = inherited
                            }
                            else if (user.roleId == "ScreenBeamMonitorToASite"){
                                // console.log(user, users[originIndex])
                                var added = true
                                if(user.inherited && users[originIndex].added == null){
                                    added = false
                                }
                                monitors.push({id: user.userId, name: user.userId, added: added, originIndex: originIndex, roleId: user.roleId, inherited: inherited,inheritedName: inheritedName})
                                users[originIndex].added = added
                                this.originalMonitors[user.userId] = user
                            }
                            // users[originIndex].inherited = inherited
                        })
                        this.props.editTitle("AddUserToSite")
                        this.props.editAdditionalInfo(this.state.siteName)
                        this.setState({ step: this.state.step + 1,allUsers: users, admins: admins, monitors: monitors })
                    })
                })
            }
        }
        else {
            return null
        }
    }

    onSelect = (event, index, target) => {
        var items
        if (target == 0) {
            items = this.state.allUsers
        }
        else if (target == 1) {
            items = this.state.admins
        }
        else {
            items = this.state.monitors
        }

        //If the user selects a different from a different group empty the current selection
        var selected
        if (target == this.state.selectTarget) {
            selected = this.state.selected
        }
        else {
            selected = {
                start: -1,
                end: -1,
                direction: "UP",
                users: {}
            }
        }
        var filter = null
        if (target == 0) {
            filter = (item) => {
                return (!item.added && item.roleId!="CMSSystemAdmin" && !item.inherited)
            }
        }
        else{
            filter = (item) => {
                return (item.roleId != "CMSSystemAdmin" && !item.inherited)
            }
        }

        //Handle each common "select" case (SHIFT,CTRL)
        document.getSelection().removeAllRanges()

        if (event.ctrlKey) {
            selected = Utils.selectAdd(items, selected, 0, index, "users", "id", filter)
        }
        else if (event.shiftKey) {
            selected = Utils.selectMultiple(items, selected, index, "users", "id", filter)
        }
        else {
            selected = Utils.select(items, selected, 0, index, "users", "id", null, filter)
        }
        this.setState({ selected: selected, selectTarget: target })
    }

    onSearchChange = (event) => {
        var filteredAllUsers = this.originalUsers.filter((user) => {
            return (user.name.toUpperCase().includes(event.target.value.toUpperCase()))
        })
        var selected = this.state.selected
        selected.start = 0
        selected.end = 0
        this.setState({ searchInput: event.target.value, allUsers: filteredAllUsers, selected: selected })
    }

    submitRequest = () => {

        if(this.state.warningText.length > 0 && this.props.arg1 != "addUser"){
            this.setState({showWarning: true})
            return
        }

        if (this.state.overRideWarning) {
            API.forceAddUsersToSite(this.props.arg2.id, this.state.admins.slice(1,this.state.admins.length), this.state.monitors, (res) => {
                this.props.getSiteUsers(this.props.user.token,this.props.selectedSites.sites)
                this.props.hideModal()
            })
        }
        else if (this.props.arg1 == "addUser") {
            var filteredAdmins = [...this.state.admins]
            filteredAdmins = filteredAdmins.filter((user)=>{
                if(user.roleId == "CMSSystemAdmin" || user.id in this.originalAdmins){
                    return false
                }
                return true
            })
            var filteredMonitors = [...this.state.monitors]
            filteredMonitors = filteredMonitors.filter((user)=>{
                if(user.id in this.originalMonitors){
                    return false
                }
                return true
            })
            this.props.addUsersToSite(this.props.arg2.id, filteredAdmins, filteredMonitors, this.state.removed, (res) => {
                if (res.status == 400) {
                    this.setState({ overRideWarning: true })
                }
                else {
                    // this.props.getSiteUsers(this.props.username,Object.keys(this.props.selectedSites.sites))
                    this.props.hideModal()
                }
            })
        }
        else {
            var filteredAdmins = [...this.state.admins]
            filteredAdmins = filteredAdmins.filter((user)=>{
                if(user.roleId == "CMSSystemAdmin" || user.id in this.originalAdmins){
                    return false
                }
                return true
            })
            var filteredMonitors = [...this.state.monitors]
            filteredMonitors = filteredMonitors.filter((user)=>{
                if(user.id in this.originalMonitors){
                    return false
                }
                return true
            })
            this.props.createSite(this.state.siteName, this.state.parentSite, filteredAdmins, filteredMonitors, () => {
                // this.props.getSiteUsers(this.props.username,Object.keys(this.props.selectedSites.sites))
                this.props.hideModal()
            })
        }
    }

    render() {
        return (
            <div>
                <div className='modal-body'>
                    {this.state.overRideWarning ?
                        <div>Some of the users have a role conflict. Submit again to override previous roles.</div>

                        : <div>
                            {this.state.step == 1 ?
                                <AddSiteBody
                                    value={this.state.parentSite}
                                    siteOptions={this.siteOptions}
                                    onSiteNameChange={(event) => { this.onSiteNameChange(event, "siteName") }}
                                    onParentSiteChange={(event) => { this.onSiteParamsChange(event, "parentSite") }}
                                    warningText={this.state.showWarning ? this.state.warningText : null}
                                />
                                : <AddUserToSiteBody
                                    allUsers={this.state.allUsers}
                                    admins={this.state.admins}
                                    monitors={this.state.monitors}
                                    addAdmin={this.addAdmin}
                                    removeAdmin={this.removeAdmin}
                                    addMonitor={this.addMonitor}
                                    removeMonitor={this.removeMonitor}
                                    onSelect={this.onSelect}
                                    selected={this.state.selected.users}
                                    searchInput={this.state.searchInput}
                                    onSearchChange={this.onSearchChange}
                                />
                            }
                        </div>
                    }
                </div>
                <ModalFooter
                    button1Submit={this.renderButton1()} button1Text={this.state.step == 1 ? "Add User" : null}
                    button2Submit={this.submitRequest} button2Text={"Create"}
                />
            </div>
        );
    }
}


export default connect(
    state => ({...state.user,...state.sites}),
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions, ...siteActions, ...userActions }, dispatch)
)(AddSite);
