import axios from "axios"
import moment from "moment"
import actionTypes from "../Utils/actionTypes"
import {Utils,RESTUtils} from "../Utils/utils"

const API = {
    getReceiver: function (id, cb, errorCb) {
        // console.log("Session storage: " + sessionStorage.getItem("jwtoken"));
        fetch('/api/receiver/' + id, {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            }
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            cb(res.data)
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    setDescription: function (macs, description, cb, errorCb) {
        var formData = new FormData()
        var req = {
            description: description,
            macs: macs
        }
        formData.append("req", JSON.stringify(req))

        fetch('/api/receiver/description', {
            method: "PUT",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            cb(res.data)
        }).catch((err) => {
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    getReceivers: function (start, length, sites, tasks, status, keyword, sortColumn, sortAsc, cb, errorcb) {
        var formData = new FormData()
        var req = {
            start: start,
            length: length,
            status: status,
            sites: sites,
            tasks: tasks,
            sortColumn: sortColumn,
            keyword: keyword,
            sortAsc: sortAsc ? "ASC" : "DESC"
        }
        formData.append("req", JSON.stringify(req))
        fetch('/api/receiver/', {
            method: "POST",
            body: formData,
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            }
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            if (res.status == 404) {
                res.data = {
                    total: 0,
                    totalIdle: 0,
                    totalBusy: 0,
                    totalOffline: 0,
                    data: []
                }
            }
            cb(res.data)
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },

    importCSV: function (cb, errorcb) {
        fetch('/api/receiver/table', {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            }
        }).then((res) => {
            // console.log(res)
            if (res.ok) {
                return res.text()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)
            if (!cb) { return }
            cb(res.data.data)
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getSites: function (cb, errorcb) {
        fetch('/api/site/', {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            }
        }).then((res) => {
            if (res.status == 404) {
                return {
                    data: [],
                }
            }
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            if (res.roles) {
                cb(res.data, res.roles.data)
            }
            else {
                cb(res.data, [])
            }
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getUsers: function (token, cb, errorcb) {
        var formData = new FormData();
        formData.append("token", token);
        fetch('api/user/all', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                Error(res.message)
            }
        }).then((users) => {
            if (!cb) { return }
            // cb(res)
            cb(users)
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getUserSiteRole: function (siteId, cb, errorcb) {
        fetch('api/roles/' + siteId, {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
        }).then((users) => {
            if (!cb) { return }
            cb(users)
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    deleteReceivers: function (macs, cb, errorcb) {
        var formData = new FormData()
        var data = {
            macs: macs
        }
        formData.append("req", JSON.stringify(data))
        fetch('/api/receiver', {
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            method: "DELETE",
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            cb(res)
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },

    releaseReceivers: function (macs, cb, errorcb) {
        var formData = new FormData()
        var req = {
            macs: macs
        }
        formData.append("req", JSON.stringify(req))
        fetch('/api/site/receiver', {
            method: "DELETE",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.ok
            }
            else {
                throw Error(res.status)
            }
        }).then(() => {
            if (!cb) { return }
            cb()
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    joinReceivers: function (site, macs, cb, errorcb) {
        var formData = new FormData()
        var req = {
            id: site.id,
            macs: macs
        }
        formData.append("req", JSON.stringify(req))
        fetch('/api/site/receiver', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")

            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.ok
            }
            else {
                throw Error(res.status)
            }
        }).then(() => {
            if (!cb) { return }
            cb()
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    deleteSite: function (siteId, cb, errorcb) {
        var formData = new FormData()
        formData.append("id", siteId)
        fetch('/api/site/', {
            method: "DELETE",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.ok
            }
            else {
                throw Error(res.status)
            }
        }).then(() => {
            if (!cb) { return }
            cb()
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    renameSite: function (siteId, name, cb, errorcb) {
        var formData = new FormData()
        formData.append("name", name)
        fetch('/api/site/' + siteId + '/rename', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.ok
            }
            else {
                throw Error(res.status)
            }
        }).then(() => {
            if (!cb) { return }
            cb()
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    createSite: function (siteName, siteParent, admins, monitors, cb, errorcb) {
        var adminIds = admins.map((admin) => {
            return admin.id
        })
        var monitorIds = monitors.map((monitor) => {
            return monitor.id
        })

        var req = {
            name: siteName,
            parent: siteParent,
            admins: adminIds,
            monitors: monitorIds
        }
        var formData = new FormData()
        formData.append("req", JSON.stringify(req))
        fetch('/api/site/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.ok
            }
            else if (res.status == 404) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            cb(res)
        }).catch((err) => {
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    setScreenSaver: function (clientId, macs, file, host, cb)  {
        var formData = new FormData();
        formData.append("files", file)
        formData.append("connectionId", clientId)
        formData.append("macs", JSON.stringify(macs))
        formData.append("host", host)
        fetch('/api/values/screensaverupdate/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }, (res) => {
            cb(console.log(res))
        })
    },

    setScreenSaverUrl: function (clientId, macs, url, cb) {
        var formData = new FormData();
        formData.append("url", url)
        formData.append("connectionId", clientId)
        formData.append("macs", JSON.stringify(macs))
        fetch('/api/values/screensaverupdate/url/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }, (res) => {
            cb(console.log(res))
        })
    },

    setBackground: function (clientId, macs, file, host, cb) {
        var formData = new FormData();
        formData.append("files", file)
        formData.append("connectionId", clientId)
        formData.append("macs", JSON.stringify(macs))
        formData.append("host", host)
        fetch('/api/values/backgroundupdate/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }, (res) => {
            // console.log(res)
            cb()
        })
    },

    setBackgroundUrl: function (clientId, macs, url, cb) {
        var formData = new FormData();
        formData.append("url", url)
        formData.append("connectionId", clientId)
        formData.append("macs", JSON.stringify(macs))
        fetch('/api/values/backgroundupdate/url/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }, (res) => {
            console.log(res)
            cb()
        })
    },

    updateFirmware: function (clientId, macs, file, updateType, progressCb) {
        var formData = new FormData();
        formData.append("files", file)
        formData.append("connectionId", clientId)
        formData.append("forceFwupdate", updateType[0])
        formData.append("downgradeEnable", updateType[1])
        formData.append("specialFwUpdate", updateType[2])
        formData.append("macs", JSON.stringify(macs))
        axios.request({
            method: "post",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            url: "/api/values/fwupdate/",
            data: formData,
            onUploadProgress: (p) => {
                progressCb(p)
            }
        })
    },

    updateFirmwareUrl: function (clientId, macs, url, updateType, cb) {
        var formData = new FormData();
        formData.append("url", url)
        formData.append("connectionId", clientId)
        formData.append("forceFwupdate", updateType[0])
        formData.append("downgradeEnable", updateType[1])
        formData.append("specialFwUpdate", updateType[2])
        formData.append("macs", JSON.stringify(macs))
        fetch('/api/values/fwupdate/url/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }, (res) => {
            // console.log(res)
            cb()
        })
    },

    setReceiverLog: function (clientId, macs, uploadTimer, cb) {
        var formData = new FormData();
        formData.append("uploadTimer", uploadTimer)
        formData.append("connectionId", clientId)
        formData.append("macs", JSON.stringify(macs))
        fetch('/api/values/receiverlog/update/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }, (res) => {
            // console.log(res)
            cb()
        })
    },

    installCertificates: function (clientId, macs, type, dateTime, caCert, userCert, userKey, cb) {
        var formData = new FormData();
        formData.append("CertDateTime", dateTime)
        formData.append("RootCertURL", caCert)
        formData.append("UserCertURL", userCert)
        formData.append("UserKeyURL", userKey)
        formData.append("type", type)
        formData.append("connectionId", clientId)
        formData.append("macs", JSON.stringify(macs))
        fetch('/api/values/installcertificate/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }, (res) => {
            // console.log(res)
            cb()
        })
    },


    addUsersToSite: function (siteId, admins, monitors, removed, successCb, errorCb) {
        var formData = new FormData();
        //Map admins/monitors from their objects into their Ids
        var req = {
            siteId: siteId,
            admins: admins.map((admin) => { return admin.id }),
            monitors: monitors.map((monitor) => { return monitor.id }),
            removed: Object.keys(removed)
        }
        formData.append("req", JSON.stringify(req))
        fetch('/api/roles/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return (res.json())
            }
            else {
                throw Error(res)
            }
        }).then((res) => {
            if (!successCb) { return }
            successCb(res)
        }).catch((err) => {
            if (!errorCb) { return }
            errorCb()
        })
    },

    forceAddUsersToSite: function (siteId, admins, monitors, successCb, errorCb) {
        var formData = new FormData();
        //Map admins/monitors from their objects into their Ids
        var req = { siteId: siteId, admins: admins.map((admin) => { return admin.id }), monitors: monitors.map((monitor) => { return monitor.id }) }
        formData.append("req", JSON.stringify(req))
        fetch('/api/roles/force', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return (res.json())
            }
            else {
                throw Error(res)
            }
        }).then((res) => {
            if (!successCb) { return }
            successCb(res)
        }).catch((err) => {
            if (!errorCb) { return }
            errorCb()
        })
    },
    getTask: function (taskId, successCb, errorCb) {
        fetch('/api/task/' + taskId, {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            }
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error()
            }
        }).then((res) => {
            res.data["sche_Policy_Content"] = JSON.parse(res.data["sche_Policy_Content"])
            res.data["sche_Policy_Firmware_Version"] = JSON.parse(res.data["sche_Policy_Firmware_Version"])
            res.data["sche_Name"] = res.data["sche_Name"]
            res.data["sche_Desc"] = res.data["sche_Desc"]
            if (!successCb) { return }
            successCb(res)
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    getTasks: function (successCb, errorCb) {
        fetch('/api/tasks/', {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            }
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error()
            }
        }).then((res) => {
            if (res.status == 200) {
                res.data.data.forEach((task) => {
                    task["sche_Policy_Content"] = JSON.parse(task["sche_Policy_Content"])
                    if(task["sche_Policy_Firmware_Version"]){
                        task["sche_Policy_Firmware_Version"] = JSON.parse(task["sche_Policy_Firmware_Version"])
                    }
                    task["sche_Name"] = task["sche_Name"]
                    task["sche_Desc"] = task["sche_Desc"]
                })
            }
            else {
                res.data = { data: [] }
            }
            if (!successCb) { return }
            successCb(res)
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },

    releaseTaskReceivers: function (taskId, macs, successCb, errorCb) {
        var req = {
            macs: macs
        }
        var formData = new FormData()
        formData.append('req', JSON.stringify(req))
        fetch('/api/task/' + taskId + '/receivers', {
            method: "DELETE",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error()
            }
        }).then((res) => {
            if (!successCb) { return }
            successCb(res)
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    joinTaskReceivers: function (taskId, macs, successCb, errorCb) {
        var req = {
            macs: macs
        }
        var formData = new FormData()
        formData.append('req', JSON.stringify(req))
        fetch('/api/task/' + taskId + '/receivers', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                throw Error()
            }
        }).then(() => {
            if (!successCb) { return }
            successCb()
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    createTask: function (req, successCb, errorCb) {
        req["sche_Policy_Content"] = JSON.stringify(req["sche_Policy_Content"])
        req["sche_Policy_Firmware_Version"] = JSON.stringify(req["sche_Policy_Firmware_Version"])
        req["sche_Name"] = req["sche_Name"]
        req["sche_Desc"] = req["sche_Desc"]

        if (!req.sche_End_Type_Date) {
            req.sche_End_Date = null
        }
        var formData = new FormData()
        formData.append('req', JSON.stringify(req))
        formData.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
        fetch('/api/task/', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                throw res
            }
        }).then(() => {
            if (!successCb) { return }
            successCb()
        }).catch((err) => {
            console.log(err)
            err.json().then(err => {
                if (!errorCb) { return }
                errorCb(err.Message)
            })
        })
    },
    editTask: function (taskId, req, successCb, errorCb) {
        // console.log("Rest API")
        // console.log(req["sche_Policy_Content"])
        req["sche_Policy_Content"] = JSON.stringify(req["sche_Policy_Content"])
        req["sche_Policy_Firmware_Version"] = JSON.stringify(req["sche_Policy_Firmware_Version"])
        req["sche_Name"] = req["sche_Name"]
        req["sche_Desc"] = req["sche_Desc"]
        if (!req.sche_End_Type_Date) {
            req["sche_End_Date"] = null
        }
        var formData = new FormData()
        formData.append('req', JSON.stringify(req))
        formData.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
        fetch('/api/task/' + taskId, {
            method: "PUT",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                throw Error()
            }
        }).then(() => {
            if (!successCb) { return }
            successCb()
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    createTaskUpload: function (req, successCb, errorCb) {
        var formData = new FormData()

        //Move hostname and file keys so that sche Policy Content will contain only values sent to receiver
        var host = req["sche_Policy_Content"]["host"]
        var file = req["sche_Policy_Content"]["file"]
        delete req["sche_Policy_Content"]["host"] 
        delete req["sche_Policy_Content"]["file"]

        req["sche_Policy_Content"] = JSON.stringify(req["sche_Policy_Content"])
        req["sche_Policy_Firmware_Version"] = JSON.stringify(req["sche_Policy_Firmware_Version"])
        if (!req.sche_End_Type_Date) {
            req.sche_End_Date = null
        }

        formData.append('req', JSON.stringify(req))
        formData.append('host', host)
        formData.append('file', file)
        formData.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
        fetch('/api/task/upload', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                throw res
            }
        }).then(() => {
            if (!successCb) { return }
            successCb()
        }).catch((err) => {
            console.log(err)
            err.json().then(err => {
                if (!errorCb) { return }
                errorCb(err.Message)
            })
        })
    },
    toggleTask: function (taskId, req, successCb, errorCb) {
        req["sche_Policy_Content"] = JSON.stringify(req["sche_Policy_Content"])
        req["sche_Policy_Firmware_Version"] = JSON.stringify(req["sche_Policy_Firmware_Version"])
        if (!req.sche_End_Type_Date) {
            req["sche_End_Date"] = null
        }
        var formData = new FormData()
        formData.append('req', JSON.stringify(req))
        fetch('/api/task/' + taskId + '/toggle', {
            method: "PUT",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                throw Error()
            }
        }).then(() => {
            if (!successCb) { return }
            successCb()
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    deleteTask: function (taskIds, successCb, errorCb) {
        var req = {
            taskIds: taskIds
        }
        var formData = new FormData()
        formData.append('req', JSON.stringify(req))
        fetch('/api/task/', {
            method: "DELETE",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                throw Error()
            }
        }).then(() => {
            if (!successCb) { return }
            successCb()
        }).catch((err) => {
            console.log(err)
            if (!errorCb) { return }
            errorCb(err)
        })
    },
    getAllReceiverLogs: function (cb, errorcb) {
        fetch('/api/receiver/logs', {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)            
            if (!cb) { return }
            cb(res.data)
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getAllReceiverLogsBySearchColumn: function (keyword, searchColumn, isSearchedByDateTime, sortColumn, sortAsc, start, length, cb, errorcb) {
        var formData = new FormData()
        var req = {
            start: start,
            length: length,
            sortColumn: sortColumn,
            keyword: keyword,
            isSearchedByDateTime: isSearchedByDateTime,
            searchColumn: searchColumn,
            sortAsc: sortAsc ? "ASC" : "DESC"
        }
        formData.append("req", JSON.stringify(req))
        fetch('/api/receiver/logs/list', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)
            if (!cb) { return }
            if (res.status == 404) {
                res.data = { data: [] }
            }
            cb(res.data)
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    validateURL: function (url, cb, errorcb) {
        var formData = new FormData()
        var req = {
            url: url
        }
        formData.append("req", JSON.stringify(req))
        RESTUtils.timeoutWrapper(10000,
            fetch('/api/validateurl', {
                method: "POST",
                headers: {
                    'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
                },
                body: formData,
            })
        ).then((res) => {
            if (res.ok) {
                return true
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            cb(res.data)
        }).catch((err) => {
            // console.error("/api/validateurl/ " + err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getRxCmsSettingsFile: function (data, cb, errorcb) {
        var formData = new FormData();
        var req = {
            data: data
        }
        formData.append("req", JSON.stringify(req))
        fetch('/api/settings/usb/rxcmssettings', {
            method: "POST",
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            // console.log(res.data)
            cb(res.data)
        }).catch((err) => {
            console.log(err)
        })
    },
    retrieveAeiConfigFile : function (file, cb) {
        var formData = new FormData()
        formData.append("configFile", file)
        fetch('/api/settings/readzipfile', {
            method: "POST",
            headers: {
                'operatorId': localStorage.getItem("username") || sessionStorage.getItem("username")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                throw Error(res.status)
            }
           
        }).then((res) => {
            // console.log(res)
            if (!cb) { return }
            cb(res.data)
        }).catch((err) => {
            console.log(err)
        })

    },
    getBatchSettingsFileReadyOnServer: function (data, cb, errorcb) {
        var formData = new FormData();
        formData.append("data", data)
        
        fetch('/api/settings/batchconfig', {
            method: "POST",
            headers: {
                'operatorId': localStorage.getItem("username") || sessionStorage.getItem("username")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.text()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (!cb) { return }
            cb()
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getAeiConfigFileReadyOnServer: function (zipFile, data, isEthCert, ethCerts, isTlsCert, tlsCerts, isBackgroundCustom, backgroundFile, isScreensaverCustom, screensaverFile, cb, errorcb) {
        var formData = new FormData();
        formData.append("savedZipFile", zipFile)
        formData.append("data", data)
        formData.append("isEthCert", isEthCert)
        formData.append("isTlsCert", isTlsCert)
        formData.append("isBackgroundCustom", isBackgroundCustom)
        formData.append("isScreensaverCustom", isScreensaverCustom)
        
        
        if (isEthCert) {
            formData.append("EthCertDateTime", ethCerts["EthCertDateTime"])            
            formData.append("EthRootCertURL", ethCerts["EthRootCertURL"])
            formData.append("EthUserCertURL", ethCerts["EthUserCertURL"])
            formData.append("EthUserKeyURL", ethCerts["EthUserKeyURL"])            
        }

        if (isTlsCert) {
            formData.append("TLSCertDateTime", tlsCerts["TLSCertDateTime"])            
            formData.append("TLSRootCertURL", tlsCerts["TLSRootCertURL"])
            formData.append("TLSUserCertURL", tlsCerts["TLSUserCertURL"])
            formData.append("TLSUserKeyURL", tlsCerts["TLSUserKeyURL"])        
        }
        
        if (isBackgroundCustom) {
            formData.append("BackgroundCustom", backgroundFile)
        }

        if (isScreensaverCustom) {
            formData.append("ScreensaverCustom", screensaverFile)
        }
        
        fetch('/api/settings/aeiconfig', {
            method: "POST",
            headers: {
                'operatorId': localStorage.getItem("username") || sessionStorage.getItem("username")
            },
            body: formData
        }).then((res) => {
            // console.log(res)
            if (res.ok) {
                return res.text()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)
            if (!cb) { return }
            cb()
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },

    checkAeiConfigFileStatus: function(cb) {
        fetch('/api/settings/checkstatus', {
            method: "GET",
            headers: {
                'operatorId': localStorage.getItem("username") || sessionStorage.getItem("username")
            }
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            if (cb) cb(res.data)
        }).catch((err) => {
            console.log(err)
        })
    },

    deleteAeiConfigFileOnServer: function() {
        fetch('/api/settings/cleanup', {
            method: "GET",
            headers: {
                'operatorId': localStorage.getItem("username") || sessionStorage.getItem("username")
            }
        }).then((res) => {
            // console.log(res)
            if (res.ok) {
                return res.text()
            }
            else {
                throw Error(res.status)
            }
        }).catch((err) => {
            console.log(err)
        })
    },

    getAllSchedulerLogs: function (cb, errorcb) {
        fetch('/api/scheduler/logs', {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)            
            if (!cb) { return }
            cb(res.data)
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getAllSchedulerLogsBySearchColumn: function (keyword, column, startDate, endDate, sortColumn, sortAsc, start, length, cb, errorcb) {
        var formData = new FormData()
        var req = {
            start: start,
            length: length,
            sortColumn: sortColumn,
            keyword: keyword,
            sortAsc: sortAsc ? "ASC" : "DESC",
            startDate: moment(startDate).format("MM/DD/YYYY"),
            endDate: moment(endDate).format("MM/DD/YYYY"),
            searchColumn: column
        }
        formData.append("req", JSON.stringify(req))
        fetch('/api/task/logs/list', {
            method: "POST",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
            body: formData
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)
            if (!cb) { return }
            if (res.status == 404) {
                res.data = { data: [] }
            }
            cb(res.data)
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getHost: function(cb, errorcb){
        fetch('/api/host', {
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)            
            if (!cb) { return }
            cb(res)
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    },
    getFeatureToggle: function(cb,errorcb){
        fetch('/api/featuretoggle',{
            method: "GET",
            headers: {
                'token': sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            else {
                throw Error(res.status)
            }
        }).then((res) => {
            // console.log(res)            
            if (!cb) { return }
            cb(res)
        }).catch((err) => {
            console.log(err)
            if (!errorcb) { return }
            errorcb(err)
        })
    }
}

export default API;