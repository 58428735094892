import React from 'react'
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible'
import { actionCreators as siteActions } from '../../store/Sites'
import { bindActionCreators } from 'redux';
import SiteTitle from './SiteTitle'
class Site extends React.Component {
    componentDidUpdate(){
        if(this.nameInput){
            this.nameInput.focus()
        }
    }

    render() {     
        return (
            <Collapsible 
                open={true}
                transitionTime={200}
                className={this.props.selected ? "site-selected" : ""}
                openedClassName={this.props.selected ? "site-selected" : ""}
                contentOuterClassName={"collapsible-wrapper"}
                trigger={
                    <div className={"site-icon-collapse"} style={{marginLeft: (10*this.props.depth)+"px"}}>
                        {this.props.children && <i className="fa fa-lg fa-caret-right"/>}
                    </div>
                } 
                triggerWhenOpen={
                    <div className={"site-icon-collapse"} style={{marginLeft: (10*this.props.depth)+"px"}}>
                        {this.props.children && <i className="fa fa-lg fa-caret-down"/>}
                    </div>
                }

                triggerSibling={()=>{
                    return(
                        <SiteTitle onContextClick={this.props.onContextClick} onClick={this.props.onClick} id={this.props.id} name={this.props.name}/>
                    )
                }}
            >
                {this.props.children}
            </Collapsible>
            
        )
    }
}

export default connect(
    state => state.sites,
    dispatch => bindActionCreators({...siteActions},  dispatch)
  )(Site);
  
  