import React from 'react'
import { bindActionCreators } from 'redux';

import Dropdown from '../Dropdown/Dropdown';
import DropdownItem from '../Dropdown/DropdownItem'
import ReceiverTableInfo from './ReceiverTableInfo'

//Store imports
import { connect } from 'react-redux';
import { actionCreators as signalRActions } from '../../store/SignalR';
import { actionCreators as receiverActions } from '../../store/Receiver';
import { actionCreators as modalActions } from '../../store/Modal'
import { actionCreators as notificationActions, notifications, notificationTypes } from '../../store/Notifications'
import { Translate } from 'react-localize-redux'
import {helpers as FeatureHelpers} from '../../store/FeatureToggle'
import ConvertMessageCodeToText from './CodeConverter'

///datatable imports
import $ from 'jquery'
import moment from 'moment'
import 'datatables.net'
import 'datatables.net-dt'
import 'datatables.net-scroller'

// Get current active language
var tableInfo = require('./TableConfig')
var codeConverter = require('./CodeConverter')
var pollingInterval = 2000 //Time in ms

function addSelectedClass(dataTable, selectedMacs) {
    dataTable.rows().every((index) => {
        //Loop through all rows and update them if they should be selected.
        //Might be inefficient but there are only ~160 rows at a time
        var row = dataTable.row(index)
        var data = row.data()
        if (data.RXMAC in selectedMacs) {
            $(row.node()).addClass("selected")
        }
        else {
            $(row.node()).removeClass("selected")
        }
    })
}

class ReceiverTable extends React.Component {
    state = {
        searchParam: "",
        // searchColumn: "",
        headerVisibility: {}
    }

    dataTableRef = null
    dataTable = null

    //Select Parameters
    prevTarget = -1
    target = -1
    offset = 0
    dataTable = null

    //Current View Counts
    counts = {
        idle: 0,
        offline: 0,
        busy: 0,
        total: 0,
    }

    columnDefs = [
        {targets: 3, className:"dt-nowrap no-text-select dt-nowrap-nohide"},
        {targets: 11, render: function(data,type,full){
            if(!data){
                data = ""
            }
            return '<span data-toggle="tooltip" title="' + data + '">' + data + '</span>'
        }},
        {targets: [19,20], render: (data,type,row,meta) => {
            //Target Certficate Expiration and Eth Certificate Expiration
            if(!data){
                return ""
            }
            // var day = moment(data).format("M-D-YYYY")
            // console.log(day)
            // console.log(day.format("M-D-YYYY"),row)
            return moment(data).format("M-D-YYYY")
        }},
        { targets: '_all', className: "dt-nowrap no-text-select" },
    ]
    headerMap = tableInfo.getHeaderMap()

    //Parameters used for querying receivers
    sortParam = "Name";
    asc = true;
    data = null;

    pollTimer = null; //Will Store the timer used for polling
    cardPollTimer = null; //Will store the timer used for polling for idle,busy,offline counts
    pollHandler = () => { //Callback when pollTimer triggers
        if (this.dataTable) {
            this.dataTable.rows().invalidate()
            this.dataTable.draw('page')
        }
    }

    cardPollHandler = () => {
        this.props.updateCounts(() => {
            this.cardPollTimer = setTimeout(this.cardPollHandler, pollingInterval)
        }, () => {
            this.cardPollTimer = setTimeout(this.cardPollHandler, pollingInterval)
        })
    }

    onSearchChange = (event) => {
        this.setState({ searchParam: event.target.value })
        $('div.dataTables_scrollBody').scrollTop(0)
    }

    setParams(dataTable, data) {
        var changed = false
        if (dataTable) {
            //Retrieve sort column
            var sortParam = dataTable.column(data.order[0].column).dataSrc() //Map header names back into db column names
            if (sortParam == "translatedStatus") {
                sortParam = "RXStatus"
            }
            //retrieve ascending/descending
            var asc
            if (data.order[0].dir != "asc") {
                asc = false
            }
            else {
                asc = true
            }

            if (this.sortParam != sortParam || this.asc != asc) {
                this.sortParam = sortParam
                this.asc = asc
                changed = true
            }
        }
        return changed
    }

    componentWillUnmount() {
        clearTimeout(this.pollTimer)
        clearTimeout(this.cardPollTimer)
        this.dataTable.destroy(true)
        this.dataTable = null
        this.props.deselectReceivers()
    }

    componentDidMount() {
        
        // //Create reference to for use with jQuery
        var _ReceiverTable = this
        //Jquery is necessary to stop the propagation of Bootstrap's close dropdown on click
        $(".dropdown-receiver-header").on('click', function (event) {
            event.stopPropagation()
            var data = JSON.parse($(this).attr("data"))
            var column = _ReceiverTable.dataTable.column(data["index"])
            column.visible(!column.visible())
            sessionStorage.setItem("rxTable" + [data["key"]], column.visible())
            _ReceiverTable.setState({ headerVisibility: { ..._ReceiverTable.state.headerVisibility, [data["key"]]: !_ReceiverTable.state.headerVisibility[data["key"]] } })
        })

        var config = tableInfo.getConfig()
        config["columnDefs"] = this.columnDefs
        //Initialize header visibility state
        var headerVisibility = {}
        Object.keys(this.headerMap).forEach((key) => {
            if (this.headerMap[key].visible == true) {
                headerVisibility[key] = true
            }
            else {
                headerVisibility[key] = false
            }
        })

        this.setState({ headerVisibility: headerVisibility })

        if (!this.props.withActions) {
            config["scrollY"] = "200px"
        }
        //Configure datatable
        config["ajax"] = (data, cb, settings) => {
            if (this.dataTable) {
                this.data = data //Set this.data to data so that it is accessible by react methods
                //data.start, data.length, data.draw
                //API query here and call cb when received
                var changed = this.setParams(this.dataTable, data)
                if (changed) {
                    $('div.dataTables_scrollBody').scrollTop(0)
                }
                //Selected site/task passed in from store
                //Reset the timer for polling
                clearTimeout(this.pollTimer)
                this.props.updateReceivers(data.start, data.length, this.state.searchParam, this.sortParam, this.asc, () => {
                    var storedLanguage = localStorage.getItem("languageCode") || "en";
                    var out = [...this.props.receiver.receivers]
                    this.counts = this.props.receiver.rxCount
                    this.offset = data.start
                    for (var i = 0; i < out.length; i++) {
                        out[i]["translatedStatus"] = codeConverter.ConvertMessageCodeToText("status", out[i].RXStatus, storedLanguage)
                        if (out[i].Feedback) {
                            out[i].Feedback = codeConverter.ConvertMessageCodeToText("feedback", out[i].Feedback, storedLanguage)
                        }
                    }
                    cb({ draw: data.draw, data: out, recordsFiltered: this.counts.total })

                    this.pollTimer = setTimeout(this.pollHandler, pollingInterval)

                }, () => {
                    //errorCb
                    this.pollTimer = setTimeout(this.pollHandler, pollingInterval)
                })
            }
        }

        config["rowCallback"] = (row, data, index) => {

            var featurePermissions = this.props.featureToggle.state
            var rowAllowed = FeatureHelpers.checkRxAllowed(data,featurePermissions)
            if(!rowAllowed){
                $(row).addClass("rx-feature-disabled")
            }

            if (data.SiteID in this.props.sites.rolesMap) {
                var role = this.props.sites.rolesMap[data.SiteID]
                if (role == "ScreenBeamAdmin") {
                    $(row).addClass("rx-admin")
                }
                else if (role == "ScreenBeamMonitorToASite") {
                    $(row).addClass("rx-monitor")
                }
            }
            if (data.RXMAC in this.props.receiver.selected.macs) {
                $(row).addClass("selected")
            }
            if (data.RXStatus == 0) {

            }
            else if (data.RXStatus == 1) {
                $(row).addClass("green")
            }
            else if (data.RXStatus == 9) {
                $(row).addClass("red")
            }
        }


        //Instantiate datatable
        this.dataTable = $(this.dataTableRef).DataTable(config);
        this.dataTable.resetHeight = $('#receiver-table div.dataTables_scrollBody').scrollTop(0)
        this.dataTable.addSelectedClass = addSelectedClass
        this.props.setDataTable(this.dataTable) //Set table to store so it may be called from other react components
        this.pollTimer = setTimeout(this.pollHandler, 0) //Instantiate first polling Timer
        this.cardPollTimer = setTimeout(this.cardPollHandler, 0)
        $.fn.dataTable.ext.errMode = 'none'; //Suppress error messages
        // $.fn.dataTable.ColReorder(this.dataTable);

        //Add event handlers for datatable
        $('#receiver-table tbody').on('click', 'tr', function (event) {
            document.getSelection().removeAllRanges()
            if (_ReceiverTable.dataTable) {
                event.preventDefault()
                var row = _ReceiverTable.dataTable.row(this)
                var data = row.data()
                var index = row.index()
                _ReceiverTable.onDataClick(event, index, _ReceiverTable.offset, row)
            }
        })
        $('#receiver-table tbody').on('dblclick', 'tr', function (event) {
            document.getSelection().removeAllRanges()
            if (_ReceiverTable.dataTable && _ReceiverTable.props.withActions) {
                var row = _ReceiverTable.dataTable.row(this)
                var data = row.data()
                var index = row.index()
                _ReceiverTable.props.selectForce(index, _ReceiverTable.offset, row)
                if (Object.keys(_ReceiverTable.props.receiver.selected.macs).length) {
                    _ReceiverTable.props.requestConfigInfo()
                    addSelectedClass(_ReceiverTable.dataTable, _ReceiverTable.props.receiver.selected.macs)
                }
                var featurePermissions = _ReceiverTable.props.featureToggle.state
                var rowAllowed = FeatureHelpers.checkRxAllowed(data,featurePermissions)
                if(!rowAllowed){
                    _ReceiverTable.props.createNotification(notificationTypes.warning, notifications.warningMessages.featureDenied(data.ModelName,data.RXVersion))
                }
            }
        })

    }

    onDataClick = (event, rowIndex, offset, row) => {
        if (this.props.receiver.receivers.length <= 0) {
            return
        }
        this.prevTarget = this.target
        this.target = rowIndex + offset
        if (event.shiftKey) {
            event.preventDefault()
            document.getSelection().removeAllRanges();
            this.props.requestSelectMultiple(this.prevTarget, this.target, this.sortParam, this.state.searchParam, this.ascending, () => {
                //API call so we must wait for a callback trigger
                addSelectedClass(this.dataTable, this.props.receiver.selected.macs)
            })
        }
        else if (event.ctrlKey) {
            event.preventDefault()
            this.props.selectAdd(rowIndex, offset)
            addSelectedClass(this.dataTable, this.props.receiver.selected.macs)
        }
        else {
            this.props.select(rowIndex, offset)
            addSelectedClass(this.dataTable, this.props.receiver.selected.macs)
        }
    }

    openModal = (name, arg1 = null) => {
        // var offlineStatus = Object.keys(this.props.receiver.selected.macs).some((mac)=>{
        //     if(this.props.receiver.selected.macs[mac].RXStatus != 9){
        //         return true
        //     }
        // })
        if (Object.keys(this.props.receiver.selected.macs).length) {
            this.props.createModal(name)
        }
        // else if(!offlineStatus){
        //     this.props.createNotification(notificationTypes.error, notifications.errorMessages.onlyOfflineAction)
        // } 
        else {
            this.props.createNotification(notificationTypes.error, notifications.errorMessages.selectReceiver)
        }
    }
    onSettingsClick = () => {
        let numOfSelectedRx = Object.keys(this.props.receiver.selected.macs).length;

        // Single Rx Settings
        if (numOfSelectedRx == 1)
            this.props.requestConfigInfo()
        else if (numOfSelectedRx > 1) // Multiple Rx Setting            
            this.props.createModal("MultipleRxSettings")
        else
            this.props.createNotification(notificationTypes.error, notifications.errorMessages.selectReceiver)

    }

    onReceiverLogClick = () => {
        let numOfSelectedRx = Object.keys(this.props.receiver.selected.macs).length;

        // Single Rx Settings
        if (numOfSelectedRx == 1)
            this.props.requestReceiverLogInfo()
        else if (numOfSelectedRx > 1) // Multiple Rx Setting            
            this.props.createModal("ReceiverLog")
        else
            this.props.createNotification(notificationTypes.error, notifications.errorMessages.selectReceiver)
    }

    onUpdateCredentialsClick = () => {
        let numOfSelectedRx = Object.keys(this.props.receiver.selected.macs).length;  
        if (numOfSelectedRx == 1)
            this.props.getCredentials("credentials")
        else          
            this.props.getCredentials("default")
    }

    returnVisible = (headerkey) => {
        if (this.state.headerVisibility[headerkey]) {
            return "fa fa-check"
        }
        else {
            return ""
        }
    }
    
    raiseNoDataNotification = () => {
        this.props.createNotification(notificationTypes.error, notifications.errorMessages.noReceivers)
    }

    render() {
        return (
            <div>
                <div className="row justify-content-between align-items-center deselect-trigger">
                    <div className="col-md-6">
                        <div className="btn-toolbar">
                            <div className="btn-group mr-1">
                                <Dropdown title="ShowHideColumns">
                                    {Object.keys(this.headerMap).map((key, index) => {
                                        return (<DropdownItem key={key} className="dropdown-receiver-header" data={JSON.stringify({ key: key, index: index })} index={index} icon={this.returnVisible(key)}><Translate id={key} /></DropdownItem>)
                                    })}
                                </Dropdown>
                            </div>
                            {(this.props.withActions) &&
                                <div className="btn-group mr-1">
                                    <Dropdown title="Actions">
                                        <DropdownItem className="dropdown-item" icon="fa fa-cog" onClick={() => { this.onSettingsClick() }}><span> </span><Translate id='Settings' /></DropdownItem>
                                        <DropdownItem className="dropdown-item" icon="fa fa-refresh" onClick={() => { this.openModal("Update Firmware") }}><span> </span><Translate id="UpdateFirmware" /></DropdownItem>
                                        <DropdownItem className="dropdown-item" icon="fa fa-desktop" onClick={() => { this.openModal("Set Background") }}><span> </span><Translate id="SetBackground" /></DropdownItem>
                                        <DropdownItem className="dropdown-item" icon="fa fa-picture-o" onClick={() => { this.openModal("Set Screen Saver") }}><span> </span><Translate id="SetScreenSaver" /></DropdownItem>
                                        <DropdownItem className="dropdown-item" icon="fa fa-power-off" onClick={() => { this.openModal("Reboot") }}><span> </span><Translate id="Reboot" /></DropdownItem>
                                        <DropdownItem className="dropdown-item" icon="fa fa-undo" onClick={() => { this.openModal("Reset to Default") }}><span> </span><Translate id="ResetToDefault" /></DropdownItem>
                                        {(this.props.user.isSystemAdmin === true || this.props.user.isSystemAdmin === "true") && <DropdownItem className="dropdown-item" icon="fa fa-key" onClick={() => { this.onUpdateCredentialsClick() }}><span> </span><Translate id="UpdateCredentials" /></DropdownItem>}
                                        <DropdownItem className="dropdown-item" icon="fa fa-exchange" onClick={() => { this.openModal("Switch CMS for Receiver") }}><span> </span><Translate id="SwitchCmsForReceivers" /></DropdownItem>
                                        <DropdownItem className="dropdown-item" icon="fa fa-download" onClick={() => { this.openModal("Install Certificate") }}><span> </span><Translate id="InstallCertificate" /></DropdownItem>
                                        <DropdownItem className="dropdown-item" icon="fa fa-cog" onClick={() => { this.onReceiverLogClick() }}><span> </span><Translate id='ReceiverLog' /></DropdownItem>
                                    </Dropdown>
                                </div>
                            }
                            {(this.props.withActions) &&
                                <div className="btn-group mr-1">
                                    <Dropdown title="ModifyReceivers">
                                        {(this.props.user.isSystemAdmin === true || this.props.user.isSystemAdmin === "true") && <DropdownItem className="dropdown-item" onClick={() => { this.openModal("Delete Receiver") }}>Delete Receiver</DropdownItem>}
                                        <DropdownItem className="dropdown-item" onClick={() => { this.openModal("Edit Receiver Description") }}>Edit Receiver Description</DropdownItem>
                                    </Dropdown>
                                </div>
                            }
                            {this.props.withActions &&
                                <div className="btn-group mr-1">
                                    <Dropdown title="Export">
                                        {(this.props.receiver.receivers && this.props.receiver.receivers.length > 0) ?
                                            <DropdownItem href={"/api/receiver/table/" + (localStorage.getItem("jwtoken") || (sessionStorage.getItem("jwtoken")))} onClick={() => { }} icon="fa fa-file-o"> CSV</DropdownItem>
                                            : <DropdownItem onClick={this.raiseNoDataNotification} icon="fa fa-file-o"> CSV</DropdownItem>
                                        }
                                    </Dropdown>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end deselect-trigger">
                        <div className="btn-toolbar">
                            {/* <div className="btn-group mr-1">
                                <Dropdown title={this.state.searchColumn ? this.state.searchColumn : "Search"}>
                                    {Object.keys(this.headerMap).map((key, index) => {
                                        return (<DropdownItem key={"searchHeader" + index} onClick={(event) => { this.changeSearchColumn(event, key) }}><Translate id={key} /></DropdownItem>)
                                    })}
                                </Dropdown>
                            </div> */}
                            <div className="btn-group mr-1">
                                <i className="search-icon material-icons">search</i>
                                <Translate>
                                    {({ translate }) =>
                                        <input type="text" className="table-search-input form-control" value={this.state.searchParam} placeholder={translate("Search")} onChange={this.onSearchChange}></input>
                                    }
                                </Translate>
                            </div>
                        </div>
                    </div>
                </div>
                <table ref={(el) => { this.dataTableRef = el }} id="receiver-table" className="table table-bordered" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            {Object.keys(this.headerMap).map((header, index) => {
                                return (<th key={"header" + index}><Translate id={header} /></th>)
                            })}
                        </tr>
                    </thead>
                </table>
                <ReceiverTableInfo idle={this.counts.idle} offline={this.counts.offline} busy={this.counts.busy} total={this.counts.total} selected={Object.keys(this.props.receiver.selected.macs).length} />
            </div>
        )
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...signalRActions, ...receiverActions, ...modalActions, ...notificationActions }, dispatch)
)(ReceiverTable);
