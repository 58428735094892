import API from '../REST/UsersApi'
import { actionCreators as Notification, notifications, notificationTypes } from './Notifications'
import { actionCreators as Modal } from './Modal'

const loginType = 'LOGIN';
const toggleSidebarType = "TOGGLE";
const updateUsersType = 'UPDATE_USERS';
const updateSiteUsers = 'UPDATE_USER_SITE_ROLE';
const validateType = "VALIDATE";
const initialState = {
    username: '',
    token: '',
    isSystemAdmin: false,
    sidebarActive: true,
    users: [{}],
    siteUsers: [],
    error: ''
};

export const actionCreators = {

    login: (username, password, remember, successCb, errorCb) => async (dispatch, getState) => {
        API.login(username, password,
            (user) => {
                var username = user.username;
                var isSystemAdmin = user.isSystemAdmin;
                var token = user.Token;
                if (remember) {
                    localStorage.setItem('jwtoken', token);
                    localStorage.setItem('username', username);
                    localStorage.setItem('isSystemAdmin', isSystemAdmin);
                }
                sessionStorage.setItem('jwtoken', token);
                sessionStorage.setItem('username', username);
                sessionStorage.setItem('isSystemAdmin', isSystemAdmin);
                dispatch({ type: loginType, username, isSystemAdmin, token });
                dispatch(Modal.hideModal());
                if (successCb)
                    successCb(user);
            },
            (error) => {
                var username = '';
                var isSystemAdmin = false;
                var token = '';
                dispatch({ type: loginType, username, isSystemAdmin, token });
                if (errorCb)
                    errorCb(error);
            }
        );
    },
    logout: () => async (dispatch, getState) => {
        var username = '';
        var isSystemAdmin = false;
        var token = '';
        localStorage.removeItem('jwtoken');
        localStorage.removeItem('username');
        localStorage.removeItem('isSystemAdmin');
        sessionStorage.removeItem('jwtoken');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('isSystemAdmin');
        dispatch({ type: loginType, username, isSystemAdmin, token })
        window.location.assign("/Login")
    },
    validate: () => async (dispatch, getState) => {
        var formData = new FormData()
        var token = sessionStorage.jwtoken ? sessionStorage.jwtoken : localStorage.jwtoken;
        formData.append("token", token)
        fetch('/api/validate/', {
            method: "POST",
            body: formData
        }).then((res) => {
            if (res.ok) {
                return
            }
            else {
                // console.log(res);
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.sessionExpired));
                throw Error("Token invalid.")
            }
        }).then(() => {
            var username = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username")
            var isSystemAdmin = sessionStorage.getItem("isSystemAdmin") ? sessionStorage.getItem("isSystemAdmin") : localStorage.getItem("isSystemAdmin")
            var token = sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken")
            dispatch({ type: loginType, username, isSystemAdmin, token })
        }).catch((err) => {
            //If there is an error set the username and role to empty values
            // console.log('Error:', err)
            // console.log("Validate Error")
            var username = ''
            var isSystemAdmin = false
            var token = ''
            dispatch({ type: loginType, username, isSystemAdmin, token })
        })
    },
    isTokenValid: (successCb, errorCb) => async (dispatch, getState) => {
        var formData = new FormData()
        var token = sessionStorage.jwtoken ? sessionStorage.jwtoken : localStorage.jwtoken;
        formData.append("token", token)
        fetch('/api/validate/', {
            method: "POST",
            body: formData
        }).then((res) => {
            if (res.ok) {
                if (successCb)
                    successCb();
            }
            else {
                // console.log(res);
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.sessionExpired));
                if (errorCb)
                    errorCb();
            }
        }).catch((err) => {
            dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.sessionExpired));
            if (errorCb)
                errorCb();
        })
    },
    // Member Management
    getUsers: (token, successCb, errorCb) => async (dispatch) => {
        API.getUsers(
            token,
            users => {
                //dispatch(Notification.createNotification("success", "Getting list of members"));
                dispatch({ type: updateUsersType, users });
                if (successCb)
                    successCb();
            },
            error => {
                //dispatch(Notification.createNotification("Failed to get list of members"));
                console.log(error);
                // var users = [];
                // dispatch({ type: updateUsersType, users });
                if (errorCb)
                    errorCb();
            }
        );
    },


    createUser: (token, firstName, lastName, username, email, password, successCb, errorCb) => async (dispatch, getState) => {
        API.createUser(token, firstName, lastName, username, email, password, () => {
            dispatch(Notification.createNotification("success", "Added user successfully."));
            if (successCb)
                successCb();
        }, (error) => {
            console.log(error)
            dispatch(Notification.createNotification("error", error));
            if (errorCb)
                errorCb();
        })
    },

    editUser: (token, firstName, lastName, username, email, password, successCb, errorCb) => async (dispatch, getState) => {
        API.editUser(token, firstName, lastName, username, email, password,
            () => {
                dispatch(Notification.createNotification("success", "Updated user successfully."));
                if (successCb)
                    successCb();
            },
            error => {
                dispatch(Notification.createNotification("error", error));
                if (errorCb)
                    errorCb();
            });
    },
    deleteUser: (token, username, successCb, errorCb) => async (dispatch, getState) => {
        API.deleteUser(token, username,
            () => {
                dispatch(Notification.createNotification("success", username + " was deleted successfully"));
            },
            error => {
                dispatch(Notification.createNotification("error", " Failed to delete the user: " + error));
                console.log(error)
            });

        API.getUsers(
            token,
            users => {
                dispatch({ type: updateUsersType, users });
                if (successCb)
                    successCb();
            },
            error => {
                dispatch(Notification.createNotification("error", error));
                console.log(error);
                var users = [];
                dispatch({ type: updateUsersType, users });
                if (errorCb)
                    errorCb();
            }
        );
    },

    updateUserAdminRole: (token, userId, roleId, successCb, errorCb) => async (dispatch, getState) => {
        API.updateUserAdminRole(token, userId, roleId,
            () => {
                dispatch(Notification.createNotification("success", "Successfully Updated User Admin Role"));
                if (successCb)
                    successCb();
                // API.getUsers(
                //     token,
                //     users => {
                //         dispatch(Notification.createNotification("success", "Successfully Refresh User List"));
                //         dispatch({ type: updateUsersType, users });
                //         if (successCb)
                //             successCb();
                //     },
                //     error => {
                //         console.log(error);
                //         dispatch(Notification.createNotification("error", "Failed to Refresh User List. " + error));
                //         var users = [];
                //         dispatch({ type: updateUsersType, users });
                //         if (errorCb)
                //             errorCb();
                //     }
                // );
            },
            (error) => {
                dispatch(Notification.createNotification("error", "Failed to Update User Admin Role. " + error));
                console.log(error);
                if (errorCb)
                    errorCb();
            }
        );

    },
    getSiteUsers: (token, siteList, successCb, errorCb) => (dispatch, getState) => {
        API.getSiteUsers(
            token,
            siteList,
            siteUsers => {
                //dispatch(Notification.createNotification("success", "Successfully Get Site Users"));

                dispatch({ type: updateSiteUsers, siteUsers })
                if (successCb)
                    successCb();
            },
            error => {
                console.log(error);
                //dispatch(Notification.createNotification("error", "Failed to Get Site Users. " + error));
                if (errorCb)
                    errorCb(error);
            }
        );
    },
    // site users
    updateSiteUserRole: (token, userId, siteId, roleId, successCb, errorCb) => async (dispatch, getState) => {
        API.updateSiteUserRole(token, userId, siteId, roleId,
            () => {
                dispatch(Notification.createNotification("success", "Successfully Update Site User Role"));
                if (successCb)
                    successCb();
            },
            error => {
                dispatch(Notification.createNotification("error", "Failed to Update Site User Role. " + error));
                console.log(error);
                if (errorCb)
                    errorCb(error);
            }
        );
    },
    deleteSiteUserFromSite: (token, userId, siteId, roleId, successCb, errorCb) => async (dispatch, getState) => {
        API.deleteSiteUserFromSite(token, userId, siteId, roleId,
            () => {
                dispatch(Notification.createNotification("success", "Successfully Delete Site User"));
                if (successCb)
                    successCb();
            },
            error => {
                dispatch(Notification.createNotification("error", "Failed to Delete Site User. " + error));
                console.log(error);
                if (errorCb)
                    errorCb(error);
            }
        );
    },
    toggleSidebar: () => async (dispatch, getState) => {
        var sidebarActive = !(getState().user.sidebarActive)
        dispatch({ type: toggleSidebarType, sidebarActive })
    }
};

export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;

    //Return copy of state with edited state
    if (action.type === loginType) {
        return { ...state, username: action.username, isSystemAdmin: action.isSystemAdmin, token: action.token };
    }
    else if (action.type == toggleSidebarType) {
        return { ...state, sidebarActive: action.sidebarActive }
    }
    else if (action.type === validateType) {
        return { ...state, username: action.username, role: action.role }
    }
    else if (action.type === updateSiteUsers) {
        return { ...state, siteUsers: action.siteUsers }
    }
    else if (action.type === updateUsersType) {
        return { ...state, users: action.users }
    }
    return state;
};

