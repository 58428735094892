import React from 'react'
import {Translate } from 'react-localize-redux'
class ModalPinInput extends React.Component {
     Add8thCheckSumNumber(pinValue) {
        var pin = parseInt(pinValue) * 10;
        var acccum = 0;
        var result = "";

        acccum = 3 * parseInt(((pin / 10000000) % 10), 10);
        acccum += 1 * parseInt(((pin / 1000000) % 10), 10);
        acccum += 3 * parseInt(((pin / 100000) % 10), 10);
        acccum += 1 * parseInt(((pin / 10000) % 10), 10);
        acccum += 3 * parseInt(((pin / 1000) % 10), 10);
        acccum += 1 * parseInt(((pin / 100) % 10), 10);
        acccum += 3 * parseInt(((pin / 10) % 10), 10);
        result = ((10 - (acccum % 10)) % 10);
        return (pinValue + result.toString());
    }
    render() {
        return (
            <div>
                <div className="form-group row settings-row">
                    <label className="modal-label col-sm-4 col-form-label" htmlFor={"textInput-" + this.props.label}><Translate id={this.props.label}/></label>
                    <input
                        disabled={this.props.disabled}
                        onChange={(event) => { this.props.onChange(event) }}
                        className="modal-text-input form-control col-sm-4"
                        id={"textInput-" + this.props.label}
                        type="text"
                        value={this.props.maxLength && this.props.value ? this.props.value.slice(0, this.props.maxLength) : this.props.value}
                        maxLength={this.props.maxLength ? this.props.maxLength : "64"}
                    />

                    {this.props.error &&
                        <div className="col-sm-4 error"><Translate id={this.props.error}/></div>
                    }
                    {(!this.props.error) &&
                        <div className="col-sm-4 info"><Translate id={this.props.info}/></div>
                    }
                </div>
                <div className="form-group row settings-row">
                    <input
                        disabled={true}
                        className="modal-text-input form-control col-sm-4 offset-sm-4"
                        id={"textInput-" + "staticPin"}
                        type="text"
                        maxLength={this.props.maxLength == "4" ? "4" : "8"}
                        value={this.props.maxLength == "4" && this.props.value ? this.props.value.slice(0, this.props.maxLength) : this.Add8thCheckSumNumber(this.props.value)}
                    />
                </div>
            </div>
        );
    }
}

export default ModalPinInput