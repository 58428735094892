
import React from 'react';
import {Translate} from 'react-localize-redux'

class ModalFileInput extends React.Component {

    render() {
        var fileName = "No file chosen"
        var val = this.props.value;
        if (val != "") {
            if (val == "1") fileName = this.props.fileName
            else fileName = this.props.value.name;
        }
        return (     
            <div className="form-group row modal-row settings-row">
                <label className="modal-label col-sm-4"><Translate id={this.props.label}/></label>
                <input disabled={this.props.disabled} type="file" className="inputFile modal-file-input" accept={this.props.acceptFile} id={"formInput" + this.props.name}  onChange={(event)=>{this.props.onChange(event)}}/>
                <label htmlFor={"formInput" + this.props.name} className="modal-file col-sm-2">
                    <i className="material-icons large file-icon">publish</i>
                    <span className="file-title">Choose file</span>
                </label>
                <div class="file-path-wrapper col-sm-5">
                    <input disabled class="modal-file-path" type="text" placeholder={fileName} />
                </div>
                <small id="fileURLHelp" className="info col-sm-12"><Translate id={this.props.info} /></small>
            </div>
        );
    }
}

export default ModalFileInput
