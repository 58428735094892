import React from 'react';
import ModalSelect from '../ModalInputs/ModalSelect'
import ModalInput from '../ModalInputs/ModalTextInput'

class AddSite extends React.Component {
    render() {
        return (     
            <div className='modal-body'>
                <ModalSelect invertKeys={true} onChange={this.props.onParentSiteChange}  options={this.props.siteOptions} value={this.props.value} label="Parent" />
                <ModalInput onChange={this.props.onSiteNameChange}  value={this.props.siteName} label="SiteName" error={this.props.warningText} />
            </div>
        );
    }
}


export default AddSite