
const updateRxCmsSettingsType = 'UPDATE_RXCMSSETTINGS';
const updateSelectedCheckboxType = 'UPDATE_SELECTED_CHECKBOX'
const updateRxCmsSettingsAndRemoteType = 'UPDATE_RXCMSSETTINGSANDREMOTE'
const updateTcpIpSettingsType = 'UPDATE_TCPIPSETTINGS'
const updateWirelessConnectionSettingsType = 'UPDATE_WIRELESSCONNECTIONSETTINGS'
const resetStateType = 'RESET_STATE'
const initialState = {
    rxCmsSettings: {},
    rxCmsSettingsValidation: false,
    tcpIpSettings: {},
    tcpIpSettingsValidation: false,
    wirelessConnectionSettings: {},
    wirelessConnectionSettingsValidation: false,
    selectedCheckbox: {},
    settings: {}
};

export const actionCreators = {
    updateSelectedCheckbox: (data) => async (dispatch,getState) => {
        dispatch({type: updateSelectedCheckboxType, selectedCheckbox: data})
    },

    updateRxCmsSettings: (data, validationState) => async (dispatch,getState) => {
        dispatch({type: updateRxCmsSettingsType, rxCmsSettings: data, validationState: validationState})
    },

    updateTcpIpSettings: (data, validationState) => async (dispatch, getState) => {
        dispatch({type: updateTcpIpSettingsType, tcpIpSettings: data, validationState: validationState})        
    },

    updateWirelessConnectionSettings: (data, validationState) => async (dispatch, getState) => {
        dispatch({type: updateWirelessConnectionSettingsType, wirelessConnectionSettings: data, validationState: validationState})
    },

    resetState: () => async (dispatch, getState) => {
        dispatch({type: resetStateType})
    }
};

export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;

    //Return copy of state with edited state
    if (action.type === updateRxCmsSettingsType) {
        return { ...state, rxCmsSettings: action.rxCmsSettings, rxCmsSettingsValidation: action.validationState};
    }

     //Return copy of state with edited state
     if (action.type === updateSelectedCheckboxType) {
        return { ...state, selectedCheckbox: action.selectedCheckbox};
    }

    if (action.type === updateTcpIpSettingsType) {
        return {...state, tcpIpSettings: action.tcpIpSettings, tcpIpSettingsValidation: action.validationState}
    }

    if (action.type === updateWirelessConnectionSettingsType) {
        return {...state, wirelessConnectionSettings: action.wirelessConnectionSettings, wirelessConnectionSettingsValidation: action.validationState}
    }

    if (action.type === resetStateType) {
        return initialState;
    }
    return state;
};