import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalPasswordInput from '../ModalInputs/ModalPasswordInput'
import ModalSelect from '../ModalInputs/ModalSelect'

class UpdateCredentials extends React.Component {

    constructor(props) {
        super(props)
        if (this.props.arg1) {
            var data = this.props.arg1;
            var rxCredentials = {};
            if (this.props.arg2) {
                rxCredentials = this.props.arg2
            }
            this.state = {
                credentialType: "default",
                default: {
                    username: data.username,
                    password: data.password
                },
                credential: {
                    username: rxCredentials.webUserName ? rxCredentials.webUserName : "",
                    password: rxCredentials.webPassword ? rxCredentials.webPassword : ""
                }
            }
        } else {
            this.state = {
                credentialType: "default",
                default: {
                    username: "",
                    password: ""
                },
                credential: {
                    username: "",
                    password: ""
                }
            }
        }      
    }

    onChange = (event, key) => {
        var type = this.state.credentialType
        if (key != "credentialType") {
            this.setState(
                {
                    ...this.state,
                    [type]: {
                        ...this.state[type],
                        [key]: event.target.value
                    }
                })
        } else {
            this.setState({[key]: event.target.value})
        }  
    }

    options = {
        "ChangeDefaultCredentials": "default",
        "UpdateCredentials": "credential"
    }

    render() {
        return (
            <div>
                <div className='modal-body'>
                    <ModalSelect onChange={(event) => { this.onChange(event, "credentialType") }} index="0" options={this.options} value={this.state.credentialType} />
                    <ModalTextInput onChange={(event) => { this.onChange(event, "username") }} index="1" label="AdminUsername" value={this.state[this.state.credentialType].username} />
                    <ModalPasswordInput onChange={(event) => { this.onChange(event, "password") }} index="2" label="AdminPassword" value={this.state[this.state.credentialType].password} />
                </div>
                <ModalFooter button2Submit={() => { this.props.setCredentials(this.state.credentialType, this.state[this.state.credentialType].username, this.state[this.state.credentialType].password); this.props.hideModal() }} />
            </div>
        );
    }
}


export default connect(
    state => state,
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions }, dispatch)
)(UpdateCredentials);
