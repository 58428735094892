var getVisibility = () => {
    return {
        RowNo: sessionStorage.getItem('logsTableDateTime') == 'false' ? false : true,
        DateTime:  sessionStorage.getItem('logsTableDateTime') == 'false' ? false : true,
        ReceiverName: sessionStorage.getItem('logsTableReceiverName') =='false' ? false : true ,
        ReceiverMac: sessionStorage.getItem('logsTableReceiverMac') == 'false' ? false : true ,
        P2PMac: sessionStorage.getItem('logsTableP2PMac') == 'false' ? false : true ,
        FirmwareVersion: sessionStorage.getItem('logsTableFirmwareVersion') == 'false' ? false : true,
        EventType: sessionStorage.getItem('logsTableEventType') == 'false' ? false: true,
        EventCode: sessionStorage.getItem('logsTableEventCode') == 'false' ? false: true,
        EventDescription: sessionStorage.getItem('logsTableEventDescription') == 'false' ? false : true,
        SourceName: sessionStorage.getItem('logsTableSourceName') == 'false' ? false : true,
        SourceMac:  sessionStorage.getItem('logsTableSourceMac') == 'false' ? false: true,
        Bandwidth: sessionStorage.getItem('logsTableBandwidth') == 'false' ? false: true ,
        Channel:  sessionStorage.getItem('logsTableChannel') == 'false' ? false : true,
        Resolution: sessionStorage.getItem('logsTableResolution') == 'false' ? false: true,
        RateMbps: sessionStorage.getItem('logsTableRateMbps') == 'false' ? false: true,
        Duration: sessionStorage.getItem('logsTableDuration') == 'false' ? false: true
    }
}


var getConfig = () => {
    var visibilityState = getVisibility()
    //Return configs through a function to create a new instance of configs each time.
    return {
        "info": false,
        "dom": "ti",
        "serverSide": true,
        // "scrollY": "70vh",
        "scrollY": 'calc(100vh - 340px)',        
        "sScrollX": "100%",
        "scroller": {
            loadingIndicator: true,
        },
        "select": false,
        "columns": [
            {data: "rxl_Id", visible: visibilityState.RowNo},
            {data: "rxl_log_datetime", visible: visibilityState.DateTime},
            {data: "rxl_receiver_name", visible: visibilityState.ReceiverName},
            {data: "rxl_receiver_MAC", visible: visibilityState.ReceiverMac},
            {data: "rxl_p2p_MAC", visible: visibilityState.P2PMac},
            {data: "rxl_firmware", visible: visibilityState.FirmwareVersion},
            {data: "rxl_event_type", visible: visibilityState.EventType},
            {data: "rxl_event_code", visible: visibilityState.EventCode},
            {data: "rxl_event_desc", visible: visibilityState.EventDescription},
            {data: "rxl_source_name", visible: visibilityState.SourceName},
            {data: "rxl_source_mac", visible: visibilityState.SourceMac},
            {data: "rxl_BandwidthMHz", visible: visibilityState.Bandwidth},
            {data: "rxl_Channel", visible: visibilityState.Channel},
            {data: "rxl_Resolution", visible: visibilityState.Resolution},
            {data: "rxl_RateMBps", visible: visibilityState.RateMbps},
            {data: "rxl_Duration", visible: visibilityState.Duration}            
        ],

        columnDefs: [
            {targets: '_all', className: "dt-nowrap tooltip-trigger"},
            // {targets: 0, render: function(data,type,row,meta){
            //     var checked = data ? "checked" : ""
            //     return '<label class="switch" data='+row.sche_ID+'><input type="checkbox" ' + checked + '><span class="slider round"></span></label>'
            // }}
        ],
        order: [[0,"desc"]]

    }    
}
var getHeaderMap = () => {
    var visibilityState = getVisibility()
    return {
        "RowNo" : {name: "rxl_Id", visibile: visibilityState.RowNo},
        "DateTime" : {name: "rxl_log_datetime", visible: visibilityState.DateTime },
        "ReceiverName" : {name: "rxl_receiver_name", visible: visibilityState.ReceiverName},
        "ReceiverMac": {name: "rxl_receiver_MAC", visible: visibilityState.ReceiverMac},
        "P2PMac" : {name: "rxl_p2p_MAC", visible: visibilityState.P2PMac},
        "FirmwareVersion" : {name: "rxl_firmware", visible: visibilityState.FirmwareVersion},
        "EventType" : {name: "rxl_event_type", visible: visibilityState.EventType},
        "EventCode" : {name: "rxl_event_code", visible: visibilityState.EventCode},
        "EventDescription" : {name: "rxl_event_desc", visible: visibilityState.EventDescription},
        "SourceName": {name: "rxl_source_name", visible: visibilityState.SourceName},
        "SourceMac": {name: "rxl_source_mac", visible: visibilityState.SourceMac},
        "Bandwidth" : {name: "rxl_BandwidthMHz", visible: visibilityState.Bandwidth},
        "Channel" : {name: "rxl_Channel", visible: visibilityState.Channel},
        "Resolution": {name: "rxl_Resolution", visible: visibilityState.Resolution},
        "RateMbps": {name: "rxl_RateMBps", visible: visibilityState.RateMbps},
        "Duration" : {name: "rxl_Duration", visible: visibilityState.Duration}
    }
}

const scopeList = {
    "DateTime": "rxl_log_datetime",
    "ReceiverName": "rxl_receiver_name",
    "ReceiverMac": "rxl_receiver_MAC",
    "FirmwareVersion": "rxl_firmware",
    "EventType": "rxl_event_type",
    "EventDescription": "rxl_event_desc",
    "SourceName": "rxl_source_name",
    "SourceMac": "rxl_source_mac"
}

module.exports = {
    getConfig: getConfig,
    getHeaderMap: getHeaderMap,
    scopeList: scopeList
}
    