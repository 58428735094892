import './Bars.css'
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { actionCreators as userActions } from '../../store/User'


class Navbar extends React.Component {
    constructor(props) {
        super(props);

        // Get current active language
        const activeLanguage = localStorage.getItem("languageCode") || "en";
        this.state = {
            color: "#0D1626",
            language: activeLanguage
        }

        // Bind functions
        this.onLanguageChange = this.onLanguageChange.bind(this);
    }

    // componentDidMount() {
    //     var theme = localStorage.getItem("theme")
    //     if (theme) {
    //         this.setState({ color: localStorage.getItem("theme") })
    //     }
    // }

    // onColorChange = (event) => {
    //     this.setState({ color: event.target.value })
    //     localStorage.setItem("theme", event.target.value)
    //     var rootElement = document.getElementById("root")
    //     rootElement.style.setProperty('--primary-color', event.target.value)
    // }

    onLanguageChange = (event) => {
        this.setState({ language: event.target.value });
        this.props.setActiveLanguage(event.target.value);
    }

    onLogOutClicked() {
        localStorage.removeItem("jwtoken")
        sessionStorage.removeItem('jwtoken')
        window.location.assign("/Login")
    }

    render() {

        return (
            <nav className="navbar navbar-expand navbar-dark sticky-top flex-md-nowrap p-0 shadow bg-colored deselect-trigger">
                <button type="button" id="sidebarCollapseInNav" className="btn btn-basic sidebarCollapse" onClick={() => { this.props.toggleSidebar() }}>
                    <i className="menu material-icons menu-item-icon">menu</i>
                </button>
                {/* <Link to={"/"} className="navbar-brand">
                    <img src="/Images/Logo_400x400.jpg" className="company-logo align-top" />
                    Actiontec
                </Link> */}
                {/* <input type="color" className="nav-color-picker" onChange={this.onColorChange} value={this.state.color} /> */}
                <ul className="navbar-nav px-3">
                    <li className="nav-item">
                        <select disabled value={this.state.language} onChange={this.onLanguageChange}>
                            <option value="en">English</option>
                            <option value="zhcn">简体中文</option>
                            <option value="zhtw">正體中文</option>
                            <option value="ja">日本語</option>
                        </select>
                    </li>
                    <li className="nav-item text-nowrap">
                        <a className='nav-link' onClick={this.onLogOutClicked.bind(this)} style={{ cursor: "pointer" }}><Translate id="Logout" /></a>                    
                    </li>
                </ul>
            </nav>
        );
    }

}

export default (connect(
    state => state.user,
    dispatch => bindActionCreators({ ...userActions }, dispatch)
)(withLocalize(Navbar)));
