import { actionCreators as Receiver } from './Receiver'
import { actionCreators as User } from './User'
import { actionCreators as Sites } from './Sites'
import { actionCreators as Modal } from './Modal'
import { actionCreators as FeatureToggle } from './FeatureToggle'
import { actionCreators as Notification, notifications, notificationTypes } from './Notifications'
// import {notifications} from './Notifications'
import * as signalR from '@aspnet/signalr'
import { combineReducers } from 'redux';
const subscribeType = 'SUBSCRIBE';
const connectType = 'CONNECT';
const disconnectType = 'DISCONNECT';
const websocketStateType = "WEBSOCKET_STATE"
const updateType = "UPDATE"
const idType = "ID"
const initialState = {
    id: "",
    connection: null,
    updateInterval: null,
    websocketState: true,
    cb: null //used for settings info
};



const subscriptionTimer = 2000 //polling interval in ms

async function reconnect(connection, successCb, errorCb) {
    try {
        await connection.start();
        successCb()
    } catch (err) {
        console.log(err);
        errorCb()
        setTimeout(() => reconnect(connection, successCb, errorCb), 5000);
    }
};

export const actionCreators = {

    connect: () => async (dispatch, getState) => {
        var state = getState()

        //If a connection already exists do nothing
        if (state.signalr.connection != null) {
            return
        }
        var connection = new signalR.HubConnectionBuilder()
            .withUrl('/cmsHub')
            .build();

        connection.on('websocketClosed', () => {
            var websocketState = false
            dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.websocketClosed))
            dispatch({ type: websocketStateType, websocketState })
        })

        connection.on('websocketOpened', () => {
            var websocketState = true
            dispatch(Notification.createNotification(notificationTypes.success, notifications.successMessages.websocketOpened))
            dispatch({ type: websocketStateType, websocketState })
        })

        connection.on('initialize', (id, websocketState) => {
            dispatch({ type: idType, id })
            dispatch({ type: websocketStateType, websocketState: websocketState })
        });

        connection.on("macSelectRange", (message) => {
            var data = JSON.parse(message)
            dispatch(Receiver.selectMultiple(data.start, data.end, data.macs))
        });

        ///////Websocket Messages/////////
        connection.on("rxUploadLogSetReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            else if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
            else if (parsedMessage.RequestStatus == "6"){
                dispatch(Modal.createModal("Log Upload Busy"))
            }
        })
        
        connection.on("resetRxLogServerReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            // console.log(parsedMessage)
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
            else if (parsedMessage.RequestStatus == "0"){
                dispatch(Notification.createNotification(notificationTypes.success, notifications.successMessages.canceledRxLogUpload))
            }
        })

        connection.on("configInfoReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            else if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
            else {
                var data = JSON.parse(parsedMessage.Body)
                data["RxMacAddr"] = parsedMessage.AffectedMACs[0] // Add Receiver MAC Address to data for SSH Debug Console feature in Single Rx Settings
                if (data) { dispatch(Modal.createModal("Settings", data)) }
                else dispatch(Notification.createNotification(notificationTypes.error, "Receiver returns NULL."))
            }
        })

        connection.on("resetReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
        })
        connection.on("rebootReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
        })
        connection.on("backgroundReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
        })
        connection.on("screenSaverReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
        })
        connection.on("defaultCredentialsReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            } else {
                var data = JSON.parse(parsedMessage.Body)
                var state = getState()
                var rxCredentials = state.modal.additionalInfo
                if (rxCredentials) {
                    dispatch(Modal.createModal("Update Credentials", data, rxCredentials))
                } else {
                    dispatch(Modal.createModal("Update Credentials", data))
                }
            }
        })
        connection.on("rxCredentialsReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            } else {
                var data = JSON.parse(parsedMessage.Body)
                dispatch(Modal.editAdditionalInfo(data))
                connection.invoke("GetRxCredentials", [], "default")
            }
        })
        connection.on("firmwareReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
        })
        connection.on("settingsReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs != undefined)
            {
                if (parsedMessage.AffectedMACs.length > 0) {
                    return
                }
            }

            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "8") {
                dispatch(Notification.createNotification(notificationTypes.error, parsedMessage["Message"]))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
        })
        connection.on("receiverLogInfoReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            // if (parsedMessage.AffectedMACs.length > 0){
            //     return
            // }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            else if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
            else {
                var data = JSON.parse(parsedMessage.Body)
                dispatch(Modal.createModal("ReceiverLog", data))
            }
        })
        connection.on("switchCMSReceived", (message) => {
            var parsedMessage = JSON.parse(message)
            if (parsedMessage.AffectedMACs.length > 0) {
                return
            }
            if (parsedMessage.RequestStatus == "1") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.rxConnectionError))
            }
            if (parsedMessage.RequestStatus == "9") {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.hubConnectionError))
            }
        })
        connection.on("cmsWebInfoReceived", (version) => {
            dispatch(Modal.createModal("About", version))

        })
        connection.on("memberRoleUpdated", (message) => {
            var state = getState();
            var username = message.username ? message.username : ""
            if (username == state.user.username) {
                var isSystemAdmin = message.isSystemAdmin
                var token = message.token
                var loginType = "LOGIN"
                if (message.username == state.user.username) {
                    dispatch({ type: loginType, username, isSystemAdmin, token })
                    sessionStorage.setItem('username', username);
                    sessionStorage.setItem('isSystemAdmin', isSystemAdmin);
                    sessionStorage.setItem('token', token);
                    dispatch(Modal.createModal("RefreshNotice"))
                }
            }
            dispatch(User.getUsers(
                state.user.token,
                () => {
                })
            )
        })
        connection.on("memberInfoUpdated", (message) => {
            var state = getState();
            if (message.username == state.user.username) {
                dispatch(Modal.createModal("Logout"))
                localStorage.removeItem('jwtoken');
                sessionStorage.removeItem('jwtoken');
                window.location.assign("/Login")
            }
            dispatch(User.getUsers(
                state.user.token,
                () => {
                })
            )
        })

        connection.on("userCreated", () => {
            var state = getState();
            dispatch(User.getUsers(
                state.user.token,
                () => {
                })
            )
        })

        connection.on("userDeleted", (message) => {
            var state = getState();
            if (message.username == state.user.username) {
                dispatch(Modal.createModal("Logout"))
                localStorage.removeItem('jwtoken');
                sessionStorage.removeItem('jwtoken');
                window.location.assign("/Login")
            }
            dispatch(User.getUsers(
                state.user.token,
                () => {
                })
            )
        })
        connection.on("userSiteRoleUpdated", () => {
            var state = getState();
            dispatch(User.getSiteUsers(
                state.user.token,
                Object.keys(state.sites.selectedSites.sites),
                () => {
                    // console.log(state.user.siteUsers)
                })
            )
        })
        connection.on("siteUserUpdated", () => {
            var state = getState();
            dispatch(User.getSiteUsers(
                state.user.token,
                Object.keys(state.sites.selectedSites.sites),
                () => {
                    // console.log(state.user.siteUsers)
                })
            )
        })

        connection.on("siteUserDeleted", (message) => {
            var state = getState();
            dispatch(User.getSiteUsers(
                state.user.token,
                Object.keys(state.sites.selectedSites.sites),
                () => {
                })
            )
        })
        ///Manually reconnect if server is disconnected
        connection.onclose(async () => {
            dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.signalRLostConnectionError))
            setTimeout(async () => {
                await reconnect(connection, () => {
                    dispatch(Notification.createNotification(notificationTypes.success, notifications.successMessages.signalRReconnect))
                }, () => {
                    dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.attemptingSignalRReconnect))
                })
            }, 3000)

        })
        connection.start().then(() => { })
        dispatch({ type: connectType, connection })
    },

    rebootRx: () => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        if (macs.length > 0) {
            connection.invoke('RebootRx', macs);
        }
    },

    resetRx: () => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        if (macs.length > 0) {
            connection.invoke('ResetRx', macs);
        }
    },

    switchCMS: (ip, port) => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        if (macs.length > 0) {
            connection.invoke('switchCMS', macs, ip, port);
        }
    },

    updateRxSettings: (settings) => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        // console.log(settings)
        if (macs.length > 0) {
            connection.invoke("UpdateRxSettings", macs, JSON.stringify(settings))
        }
    },

    setCredentials: (credentialsType, username, password) => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        if (credentialsType == "default") {
            connection.invoke('SetRxCredentials', macs, credentialsType, username, password);
        }
        else {
            if (macs.length > 0) {
                connection.invoke('SetRxCredentials', macs, credentialsType, username, password);
            }
        }
    },

    getCredentials: (credentialsType) => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        if (credentialsType == "default") {
            connection.invoke("GetRxCredentials", macs, credentialsType)
        } else {
            if (macs.length > 0) {
                connection.invoke("GetRxCredentials", macs, credentialsType)
            }
        }

    },

    requestConfigInfo: () => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        connection.invoke("RequestConfigInfo", macs)
    },

    requestReceiverLogInfo: () => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        connection.invoke("RequestReceiverLogInfo", macs)
    },

    configureCMS: (ip, webPort, CMSPort, logPort, logCSV) => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        connection.invoke("ConfigureCMS", ip, webPort, CMSPort, logPort, logCSV)
    },

    autoConfig: (product, firmware, configType) => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        connection.invoke("autoConfig", product, firmware, configType)
    },

    getCmsWebInfo: () => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        connection.invoke("about")
    },

    resetRxLogServer: () => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        connection.invoke("ResetRxLogServer")
    },

    installCertificate: (type, dateTime, caCert, userCert, privateKey) => async (dispatch, getState) => {
        var state = getState()
        var connection = state.signalr.connection
        var selected = state.receiver.selected
        var macs = Object.keys(selected.macs)
        connection.invoke("installCertificate", macs, type, dateTime, caCert, userCert, privateKey)
    },

    disconnect: () => async (dispatch, getState) => {
        var state = getState()
        var connection = state.connection
        connection.stop()
        connection = null
        dispatch({ type: disconnectType, connection })
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type == connectType) {
        return { ...state, connection: action.connection };
    }
    //Return copy of state with edited state
    if (action.type === subscribeType) {
        return { ...state, updateInterval: action.updateInterval };
    }
    if (action.type === idType) {
        return { ...state, id: action.id }
    }
    if (action.type === websocketStateType) {
        return { ...state, websocketState: action.websocketState }
    }
    // if (action.type === updateType) {
    //     return { ...state, rxInfo: action.state};
    // }
    return state

};
