import React from 'react'
import ReactDOM from 'react-dom'
import './UserTable.css'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../store/Modal'
import { actionCreators as userActions } from '../../store/User'
import { actionCreators as siteActions } from '../../store/Sites'
import { Translate } from 'react-localize-redux'
import ModalSelect from '../Modals/ModalInputs/ModalSelect'

import $ from 'jquery';
import 'datatables.net-rowgroup';
import { isThisHour } from 'date-fns/esm';


class UserTable extends React.PureComponent {

    userRoles = { "CMS Monitor": "ScreenBeamMonitorToASite", "CMS Admin": "ScreenBeamAdmin" };
    groupColumn = 0;
    selectedSiteList = [];
    createSelect = (data, type, full, meta) => {
        var sel = "<select class='form-control role-selection'>";
        var userRoles = this.userRoles;
        for (var key in userRoles) {
            if (userRoles[key] == data) {
                sel += "<option selected value = '" + userRoles[key] + "' >" + key + "</option>";
            }
            else {
                sel += "<option  value = '" + userRoles[key] + "' >" + key + "</option>";
            }
        }
        return sel;
    }
    initializeUsertable = () => {
        var that = this;
        var groupColumn = 1;
        var userTable = $(this.refs.userTable).DataTable({
            data: this.props.user.siteUsers,
            // "order": [[groupColumn], 'asc'],
            columnDefs: [
                {
                    "targets": 0,
                    "data": "site.name",
                    // "visible": false
                },
                {
                    "visible": false,
                    "targets": groupColumn
                },
                {
                    "targets": 1,
                    "data": "firstName"
                },
                {
                    "targets": 2,
                    "data": "lastName"
                },
                {
                    "targets": 3,
                    "data": "userId"
                },
                {
                    "targets": 4,
                    "data": "email"
                },
                {
                    "targets": 5,
                    "data": "roleId",
                    render: function (data, type, full, meta) {
                        return that.createSelect(data, type, full, meta);
                    }
                },
                {
                    "targets": -1,
                    "data": null,
                    render: function (data, type, full, meta) {
                        return '<i class="material-icons medium md-dark delete-member-icon"  style={{ "cursor": "pointer" }}>delete</i>';
                    }
                }
            ],
            "autoWidth": true,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({ page: 'current' }).nodes();
                var last = null;

                api.column(that.groupColumn, { page: 'current' }).data().each(function (group, i) {
                    if (last !== group) {
                        $(rows).eq(i).before(
                            '<tr class="group"><td colspan="6">' + group + '</td></tr>'
                        );

                        last = group;
                    }
                });
            },
            "searching": false,
            "info": true,
            "ordering": false,
            "searching": false,
            // "paging": false,
            "scroller": true,
            "dom": "ti",
            "scrollY": 'calc(100vh - 340px)',
            // rowGroup: {
            //     dataSrc: 0
            // }
        })

        // handle role change
        userTable.on('change', 'select.role-selection', function () {
            var rowIdx = userTable.row($(this).closest('tr')).index();
            var cell = userTable.cell(rowIdx, 5);
            var selectedRole = $(this).prop("selected", true).val();
            cell.data(selectedRole);
            var updating_user = userTable.row(rowIdx).data();
            that.props.updateSiteUserRole(
                sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : (localStorage.getItem("jwtoken") || that.props.user.token),
                updating_user["userId"],
                updating_user["siteId"],
                updating_user["roleId"],
                () => {
                    that.reloadTable();
                },
                () => {
                    that.reloadTable();
                }
            )
        });
        userTable.on('click', 'tr .delete-member-icon', function () {
            var rowIdx = userTable.row($(this).closest('tr')).index();
            var updating_user = userTable.row(rowIdx).data();
            that.props.deleteSiteUserFromSite(
                sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : (localStorage.getItem("jwtoken") || that.props.user.token),
                updating_user["userId"],
                updating_user["siteId"],
                updating_user["roleId"],
                () => {
                    that.reloadTable();
                },
                () => {
                    that.reloadTable();
                }
            )
        });
        userTable.on('click', 'tr.group', function () {
            var currentOrder = userTable.order()[0];
            if (currentOrder[0] === that.groupColumn && currentOrder[1] === 'asc') {
                userTable.order([that.groupColumn, 'desc']).draw();
            }
            else {
                userTable.order([that.groupColumn, 'asc']).draw();
            }
        });
    }
    reloadTable = () => {
        var userTable = $(this.refs.userTable).DataTable();
        userTable.clear();
        userTable.rows.add(this.props.user.siteUsers)
        userTable.draw();
    }
    componentDidMount() {
        this.initializeUsertable();
        this.selectedSiteList = Object.keys(this.props.selectedSites);
        this.props.getSiteUsers(this.props.user.token, this.selectedSiteList, this.reloadTable)
    }
    componentDidUpdate() {
        var _this = this
        var newSelectedSites = Object.keys(this.props.selectedSites);
        var noNewSiteSelected = (newSelectedSites.length == this.selectedSiteList.length)
            && newSelectedSites.every(function (element, index) {
                return element === _this.selectedSiteList[index];
            });
        if (!noNewSiteSelected) {
            this.selectedSiteList = Object.keys(this.props.selectedSites);
            this.props.getSiteUsers(this.props.user.token, this.selectedSiteList,
                () => {
                    this.reloadTable();
                },
                () => {
                    this.reloadTable();
                }
            );
        }
        else {
            this.reloadTable();
        }
    }
    render() {
        return (
            <div className="card-wrapper">
                <div className="card ">
                    <div className="table-responsive table-wrapper">
                        <table id="user-table" ref="userTable" className="table" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Site</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(
    state => {
        return {
            user: state.user,
            selectedSites: state.sites.selectedSites.sites
        }
    },
    dispatch => bindActionCreators({ ...modalActions, ...userActions, ...siteActions }, dispatch)
)(UserTable);
