import Site from './Site'
import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as signalRActions } from '../../store/SignalR';
import { actionCreators as siteActions } from '../../store/Sites'
import {ContextMenu, MenuItem,ContextMenuTrigger} from "react-contextmenu"
import { showMenu } from 'react-contextmenu/modules/actions'

import SiteContext from './SiteContext'

class Sites extends React.Component {
    siteIndexCounter = 0 //Used for assigning indices for selection handling
    pollInterval = 5000
    pollTimer = null

    pollHandler = () => {
        if(this.props.renameId !== null){
            //When renaming site skip over polling requests
            this.pollTimer = setTimeout(this.pollHandler,this.pollInterval)
            return
        }
        this.props.updateSites(()=>{
            this.pollTimer = setTimeout(this.pollHandler,this.pollInterval)
        },()=>{
            this.pollTimer = setTimeout(this.pollHandler,this.pollInterval)
        })
    }
    
    onClick = (event,index) => {
        if(event.ctrlKey){
            this.props.selectAddSite(index)
        }
        else if(event.shiftKey){
            this.props.selectMultipleSites(index)     
       }
        else{
            this.props.selectSingleSite(index, true)
        }
    }

    onContextClick = (site) => {
        if(!this.props.isScheduler){
            this.props.selectContextTarget(site)
        }
    }

    onHide = () =>{
        this.props.removeContextTarget()
    }

    componentDidMount = () => {
        this.pollTimer = setTimeout(this.pollHandler,0)
    }

    componentWillUnmount = () => {
        clearTimeout(this.pollTimer)
    }

    buildSites = (sites) => {
        sites = [...sites]
        if(sites.length>1){
            if(sites[sites.length-1].id == "-2"){
                var unAssigned = sites.pop()
            }
            var siteElements = this.siteRecurse(sites)
            if(unAssigned){
                var unAssignedElem = this.siteRecurse([unAssigned], false)[0]
            }
            siteElements.push(unAssignedElem)
        }
        else{
            var siteElements = this.siteRecurse(sites)
        }

        return siteElements
    }

    siteRecurse = (sites,start = true,depth=0) => {
        if(start){
            this.siteIndexCounter = -1
            start = false
        }
        var siteElements = sites.map((site)=>{
            this.siteIndexCounter += 1 //Increment the counter for every site we map through
            var currIndex = this.siteIndexCounter //Create a copy of the current index counter
            if(site.children && site.children.length > 0){
                return(
                    <Site
                        id={site.id}
                        key={"site"+site.id}
                        name={site.name} 
                        onClick={(event)=>{this.onClick(event,currIndex )}}
                        onContextClick={(event)=>{this.onContextClick(site)}}
                        selected={site.id in this.props.selectedSites.sites}
                        depth={depth}
                    >
                        {this.siteRecurse(site.children,false,depth+1)}
                    </Site>
                )
            }
            else{
                return(
                    <Site
                        id={site.id}
                        key={"site"+site.id}
                        name={site.name} 
                        onClick={(event)=>{this.onClick(event,currIndex )}}
                        onContextClick={(event)=>{this.onContextClick(site)}}
                        selected={site.id in this.props.selectedSites.sites}
                        depth={depth}
                    />
                )
            }
        })
        return(siteElements)
    }

    render() {  
        return (
            <div id="sitesWrapper" className = "make-scrollable deselect-trigger site-list-scroll-height">
                {this.props.isScheduler ?
                    this.buildSites(this.props.nestedSites) :
                    <ContextMenuTrigger id="siteContext" renderTag="div">
                        {this.buildSites(this.props.nestedSites)}
                    </ContextMenuTrigger>
                }
                {!this.props.isScheduler && 
                    <ContextMenu onHide={this.onHide} id="siteContext">
                        <SiteContext/>
                    </ContextMenu>
                }
            </div>
        )
    }
}

export default connect(
    state => state.sites,
    dispatch => bindActionCreators({...signalRActions,...siteActions},  dispatch)
  )(Sites);
  