import React from 'react'
import { Translate } from 'react-localize-redux'
import ModalTextInput from '../Modals/ModalInputs/ModalTextInput'
import ModalSelect from '../Modals/ModalInputs/ModalSelect'
import ModalPasswordInput from '../Modals/ModalInputs/ModalPasswordInput'

// Store
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as usbProvisioningActions } from '../../store/UsbProvisioning'

class WirelessConnectionSettings extends React.Component {
    state = {
        CurConnectApName: "",
        CurConnectApNameError: "",
        CurConnectApSecType: "0",
        CurConnectApRadiusId: "",
        CurConnectApRadiusIdError: "",
        CurConnectApPwd: "",
        CurConnectApPwdError: ""
    }

    securityOptions = {
        "Open": "0",
        "Shared": "1",
        "WPA-PSK[TKIP]": "2",
        "WPA2-PSK[AES]": "3",
        "WPA-PSK[TKIP]+WPA2-PSK[AES]": "4",
        "PEAP-MSCHAPV2": "5",
        "EAP-TLS": "6"
    }

    data = {
        "CurConnectApName": "",
        "CurConnectApSecType": "0",
        "CurConnectApRadiusId": "",
        "CurConnectApPwd": ""
    }

    file = null

    componentDidMount = () => {
        this.props.updateWirelessConnectionSettings(this.data, false)
    }

    onChange = (event) => {
        var validation = false;
        let { name, value } = event.target;
        this.data[name] = value;
        var errorType = name + "Error"
        if (value != "") {
            validation = true;
            this.setState({ [name]: value, [errorType]: "" });

        } else {
            this.setState({ [name]: value, [errorType]: "CannotBeEmpty" })
        }
        this.props.updateWirelessConnectionSettings(this.data, validation);
    }

    onSecurityChange = (event, key) => {
        let { name, value } = event.target
        // console.log(value);
        this.data[key] = value
        this.setState({ [key]: value })
        // console.log(this.data)
        this.props.updateWirelessConnectionSettings(this.data, true);
    }

    // Handle File change
    onFileChange = (event, key) => {
        this.file = event.target.files[0]
    }

    render() {
        return (
            <div>
                <ModalTextInput label="NetworkName" name="CurConnectApName" value={this.state.CurConnectApName} onChange={(event) => this.onChange(event)} error="" />
                <ModalSelect label="SecurityType" name="CurConnectApSecType" value={this.state.CurConnectApSecType} options={this.securityOptions} onChange={(event) => this.onSecurityChange(event, "CurConnectApSecType")} />
                <ModalTextInput label="Username" name="CurConnectApRadiusId" value={this.state.CurConnectApRadiusId} onChange={(event) => this.onChange(event)} error="" />
                <ModalPasswordInput label="Password" name="CurConnectApPwd" value={this.state.CurConnectApPwd} onChange={(event) => this.onChange(event)} error="" />
                {/* <ModalFileInput label="CaCertificateUrl" name="CaCertificate" onChange={(event) => this.onFileChange(event)}/> */}
                    {/* <ModalDateInput label="SystemDateTime" name="" value="" onChange={(event) => this.onChange(event)} error=""/> */}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({...usbProvisioningActions}, dispatch)
)(WirelessConnectionSettings);