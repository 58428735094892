import { actionCreators as SettingsActions } from './Settings'
import { actionCreators as UserActions } from './User'
const createType = 'CREATE';
const hideType = "HIDE"
const additionalInfoType ="EDIT_MODAL_INFO"
const titleType = "EDIT_MODAL_TITLE"
const initialState = {
    show: false,
    id: "",
    title: "",
    arg1: null,
    arg2: null,
    additionalInfo: null,
};
//Helper function - Create Modal component
export const actionCreators = {
    createModal: (id, arg1, arg2, additionalInfo = null, title = "") => async (dispatch, getState) => {
        dispatch(UserActions.validate());
        var show = true
        dispatch({type:createType,show, id, arg1, arg2, additionalInfo,title})
    },

    editAdditionalInfo: (additionalInfo) => async(dispatch,getState)=>{
        dispatch({type:additionalInfoType, additionalInfo})
    },

    editTitle: (title) => async(dispatch,getState) => {
        dispatch({type:titleType, title})
    },

    hideModal: () => async (dispatch, getState) => {
        var show = false
        dispatch({type:hideType, show})
        dispatch(SettingsActions.saveState(null,null))
    }
}

export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;
    
    //Return copy of state with edited state
    if (action.type === createType) {
        return { ...state, show: action.show, id: action.id, title: action.title, arg1: action.arg1, arg2: action.arg2, additionalInfo: action.additionalInfo};
    }
    if(action.type === additionalInfoType){
        return {...state, additionalInfo: action.additionalInfo}
    }
    if (action.type === hideType){
        return {...state, show: action.show}
    }
    if( action.type === titleType){
        return {...state, title: action.title}
    }
    return state;
};
      