import React from "react"
import { Translate } from 'react-localize-redux'
import englishTranslation from "../../../localization/en.translations.json";

class ModalAlert extends React.Component {
    render() {
        const customStyles = {
            content: {
                top: '30px',
                left: '30px',
                right: '30px',
                bottom: '30px',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        }
        return (
            <div>
                <div className='add-dialog light-mode ConfirmAlertOverlay' styles={customStyles}>
                    <div className="modal-header">
                        <h5 className="modal-title">{englishTranslation["Download"]}</h5>
                        <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeAlert}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <div>
                            The batch_config zip file is ready to download.
                        </div>

                    </div>
                    {
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" onClick={this.props.closeAlert}>{englishTranslation["Cancel"]}</button>
                            <a className="btn btn-primary" type="button" href={this.props.downloadLink} onClick={this.props.closeAlert}>
                            {englishTranslation["Download"]}
                            </a>
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default ModalAlert;