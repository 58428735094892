import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as signalRActions } from '../../store/SignalR';
import { actionCreators as receiverActions } from '../../store/Receiver';
import { actionCreators as receiverLogActions } from '../../store/ReceiverLog';
import { actionCreators as modalActions } from '../../store/Modal'
import { actionCreators as notificationActions, notifications, notificationTypes } from '../../store/Notifications'
import { Translate } from 'react-localize-redux'
import Dropdown from '../Dropdown/Dropdown';
import DropdownItem from '../Dropdown/DropdownItem'

// Date picker
import DatePicker from 'react-datepicker'
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

///datatable imports
import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-dt'
import 'datatables.net-scroller'

// Get current active language
var tableInfo = require('./TableConfig')
var config = tableInfo.getConfig();
var scopeList = tableInfo.scopeList;
var pollingInterval = 2000 //Time in ms

class ReceiverLogTable extends React.Component {
    state = {
        searchKeyword: "",
        searchColumn: Object.keys(scopeList)[1],
        headerVisibility: {},
        startDate: new Date(),
        endDate: new Date(),
        isSearchedByDateTime: false,
        isSearchBtnClicked: false
    }

    tooltipHandler = null

    headerMap = tableInfo.getHeaderMap()

    dataTableRef = null
    dataTable = null

    //Select Parameters
    prevTarget = -1
    target = -1
    offset = 0
    dataTable = null

    //Parameters used for querying receivers
    sortParam = "Name";
    asc = true;
    data = null;

    pollTimer = null; //Will Store the timer used for polling
    pollHandler = () => { //Callback when pollTimer triggers
        if (this.dataTable) {
            this.dataTable.rows().invalidate()
            this.dataTable.draw('page')
        }
    }

    addJQueryEvents = () => {
        var _ReceiverLogTable = this
        //Reattach jquery events
        $(".tooltip-trigger").on('mousemove', function(e){
            var cellData = _ReceiverLogTable.dataTable.cell(this).data()
            var rowData = _ReceiverLogTable.dataTable.row($(this).closest('tr')).data()
            if(!rowData){
                return
            }
            // if($(this).hasClass('sche-trigger')){
            //     var cellData = _SchedulerTable.getTriggerToolTip(rowData)
            // }
            $("#tooltip").text(cellData).css({ left: e.pageX, top: e.pageY + 20 }, 1)

            if(_ReceiverLogTable.tooltipHandler === null){
                _ReceiverLogTable.tooltipHandler = setTimeout(()=>{
                    if(cellData && cellData.length > 1){
                        if (!$("#tooltip").is(':visible')) $("#tooltip").show()
                    }      
                },750)
            }
        })

        $(".tooltip-trigger").on('mouseleave', function(e) {
            if(_ReceiverLogTable.tooltipHandler){
                clearTimeout(_ReceiverLogTable.tooltipHandler)
                _ReceiverLogTable.tooltipHandler = null
            }
            $("#tooltip").hide()
        })  
    }

    componentWillUnmount() {
        clearTimeout(this.pollTimer)
        clearTimeout(this.cardPollTimer)
        this.dataTable.destroy(true)
        this.dataTable = null
    }
    componentDidMount() {
        // //Create reference to for use with jQuery
        var _ReceiverLogTable = this

        //Jquery is necessary to stop the propagation of Bootstrap's close dropdown on click
        $(".dropdown-receiver-log-header").on('click', function (event) {
            event.stopPropagation()
            var data = JSON.parse($(this).attr("data"))
            var column = _ReceiverLogTable.dataTable.column(data["index"])
            column.visible(!column.visible())
            sessionStorage.setItem("logsTable"+[data["key"]],column.visible())
            _ReceiverLogTable.setState({ headerVisibility: { ..._ReceiverLogTable.state.headerVisibility, [data["key"]]: !_ReceiverLogTable.state.headerVisibility[data["key"]] } })
        })


        //Initialize header visibility state as true for all
        var headerVisibility = {}
        Object.keys(this.headerMap).forEach((key) => {
            if(this.headerMap[key].visible == true){
                headerVisibility[key] = true
            }
            else{
                headerVisibility[key] = false
            }
        })

        this.setState({ headerVisibility: headerVisibility })
        var config = tableInfo.getConfig()

        //Configure datatable
        config["ajax"] = (data, cb, settings) => {
            if (this.dataTable) {
                this.data = data //Set this.data to data so that it is accessible by react methods
                //data.start, data.length, data.draw
                //API query here and call cb when received
                var changed = this.setParams(this.dataTable, data)
                if (changed) {
                    $('div.dataTables_scrollBody').scrollTop(0)
                }

                let column = scopeList[this.state.searchColumn];
                let keyword = this.state.searchKeyword;
                let isDateTime = false;
                if (this.state.isSearchedByDateTime) {
                    isDateTime = true;
                    keyword = moment(this.state.startDate).format("MM/DD/YYYY") + "-" + moment(this.state.endDate).format("MM/DD/YYYY")
                }

                this.props.getAllReceiverLogsBySearchColumn(keyword, column, isDateTime, this.sortParam, this.asc, data.start, data.length, () => {
                    var result = [...this.props.receiverLog.receiverLogs]
                    cb({ draw: data.draw, data: result , recordsFiltered: this.props.receiverLog.count })
                    this.addJQueryEvents()
                    clearTimeout(this.pollTimer)
                    // this.pollTimer = setTimeout(this.pollHandler, pollingInterval)
                }, () => {
                    // console.log("error");
                    clearTimeout(this.pollTimer)
                    // this.pollTimer = setTimeout(this.pollHandler, pollingInterval)
                })

            }
        }

        //Instantiate datatable
        this.dataTable = $(this.dataTableRef).DataTable(config);
        this.dataTable.resetHeight = $('#receiver-log-table div.dataTables_scrollBody').scrollTop(0)
        // this.props.setDataTable(this.dataTable) //Set table to store so it may be called from other react components
        this.pollHandler()//Instantiate first polling Timer
        $.fn.dataTable.ext.errMode = 'none'; //Suppress error messages
    }

    onSearchKeywordChange = (event) => {
        this.setState({ searchKeyword: event.target.value }, () => {this.pollHandler()})
        // $('div.dataTables_scrollBody').scrollTop(0)
    }

    onSearchColumnChange = (value) => {
        let isDateTime = false;
        let isSearchBtnClicked = this.state.isSearchBtnClicked;
        if (value == "DateTime") isDateTime = true;
        if (!isDateTime) isSearchBtnClicked = false;
        this.setState({ searchColumn: value, isSearchedByDateTime: isDateTime, isSearchBtnClicked: isSearchBtnClicked }, () => {this.pollHandler()});
    }

    setParams(dataTable, data) {
        var changed = false
        if (dataTable) {
            //Retrieve sort column
            var sortParam = dataTable.column(data.order[0].column).dataSrc() //Map header names back into db column names

            //retrieve ascending/descending
            var asc
            if (data.order[0].dir != "asc") {
                asc = false
            }
            else {
                asc = true
            }

            if (this.sortParam != sortParam || this.asc != asc) {
                this.sortParam = sortParam
                this.asc = asc
                changed = true
            }
        }
        return changed
    }

    onDateChange = (date, key) => {
        this.setState({ [key]: date, isSearchedByDateTime: true },()=>{
            // console.log(this.state)
            this.pollHandler()
        })
    }

    returnVisible = (headerkey) => {
        if (this.state.headerVisibility[headerkey]) {
            return "fa fa-check"
        }
        else {
            return ""
        }
    }

    handleSearchBtnClick = () => {
        this.setState({ isSearchBtnClicked: true },()=> {this.pollHandler()});
    }


    get SearchBox() {
        if (this.state.searchColumn != "DateTime") {
            return (
                <div className="col-md-auto px-0 mx-3 dropdown">
                    <i className="search-icon material-icons">search</i>

                    <Translate>
                        {({ translate }) =>
                            <input type="text" className="table-search-input form-control" value={this.state.searchKeyword} placeholder={translate("Search")} onChange={this.onSearchKeywordChange}></input>
                        }
                    </Translate>
                </div>
            )
        }
        //     } else {
        //     return (
        //         <div className="col-md-7 receiver-log-datepicker">
        //             {/* <div className="col-xm-2">
        //                         <label><Translate id="StartDate" /></label>
        //                         <DatePicker dateFormat="MM/dd/yyyy" selected={this.state.startDate} onChange={date => this.onDateChange(date, "startDate")} />
        //                     </div> */}
        //             <div className="col-xm-2">
        //                 <label ><Translate id="EndDate" /></label>
        //                 <DatePicker dateFormat="MM/dd/yyyy" selected={this.state.endDate} onChange={date => this.onDateChange(date, "endDate")} />
        //             </div>
        //         </div>
        //     )
        // }
    }

    raiseNoDataNotification = (event) => {
        event.preventDefault()
        this.props.createNotification(notificationTypes.error, notifications.errorMessages.noLogs)
    }

    onExportAsCsvClick = () => {
        this.props.createNotification(notificationTypes.warning, notifications.warningMessages.largeDataInReceiverLog)        
    }


    render() {
        return (
            <div className="table-wrapper">
                <div id="tooltip"></div>
                <div className="table-btns">
                    <div className="row">
                        <div className="col-md-9 justify-content-start">
                            <div className="row">
                                <Dropdown title="ShowHideColumns" className="col-md-auto pr-md-0 mr-1">
                                    {Object.keys(this.headerMap).map((key, index) => {
                                        return (<DropdownItem key={key} className="dropdown-receiver-log-header" data={JSON.stringify({ key: key, index: index })} index={index} icon={this.returnVisible(key)}><Translate id={key} /></DropdownItem>)
                                    })}
                                </Dropdown>
                                <Dropdown title={this.state.searchColumn} className="col-md-auto px-md-0 pr-md-0 mr-1">
                                    {Object.keys(scopeList).map((key, index) => {
                                        return (<DropdownItem key={"searchHeader" + index} onClick={this.onSearchColumnChange} value={key}><Translate id={key} /></DropdownItem>)
                                    })}
                                </Dropdown>
                                {this.state.isSearchedByDateTime && 
                                <div className="col mt-2">
                                    <div className="row">
                                        <div className="col col-md-auto">
                                            <label className="datepicker-label"><Translate id="StartDate" /></label>
                                            <DatePicker dateFormat="MM/dd/yyyy" selected={this.state.startDate} onChange={date => this.onDateChange(date, "startDate")} />
                                        </div>
                                        <div className="col col-md-auto">
                                            <label className="datepicker-label"><Translate id="EndDate" /></label>
                                            <DatePicker dateFormat="MM/dd/yyyy" selected={this.state.endDate} onChange={date => this.onDateChange(date, "endDate")} />
                                        </div>
                                    </div>
                                </div>
                                }
                                {/* {this.state.isSearchedByDateTime && <div className="col-md-1"><div className="mr-1 dropdown">
                                    <button id={this.state.searchColumn == "DateTime" ? "search-btn" : ""} className="btn btn-secondary" type="button" onClick={this.handleSearchBtnClick}><Translate id="Search" /></button>
                                </div></div>} */}
                                {this.SearchBox}
                            </div>
                        </div>
                        <div className="col-md-3 d-flex justify-content-md-end dropdown">
                            <a className="table-button-wrapper" href="#" onClick={(event)=>{event.preventDefault();this.pollHandler()}}>
                                <button className="btn btn-secondary table-button mr-1">
                                    <Translate id="Refresh" />
                                </button>
                            </a>
                            {(this.props.receiverLog.receiverLogs && this.props.receiverLog.receiverLogs.length > 0) ?
                                <a className="table-button-wrapper" href={"/api/receiver/logs/table/" + (localStorage.getItem("jwtoken") || (sessionStorage.getItem("jwtoken")))} onClick={this.onExportAsCsvClick}><button className="btn btn-secondary table-button mr-1"><Translate id="ExportAsCsv" /></button></a>
                                : <a className="table-button-wrapper" href="#" onClick={this.raiseNoDataNotification}><button className="btn btn-secondary table-button mr-1"><Translate id="ExportAsCsv" /></button></a>
                            }
                        </div>
                    </div>
                    <table ref={(el) => { this.dataTableRef = el }} id="receiver-log-table" className="table table-bordered" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                {Object.keys(this.headerMap).map((header, index) => {
                                    return (<th key={"header" + index}><Translate id={header} /></th>)
                                })}
                            </tr>
                        </thead>
                    </table>
                </div>
            </div >
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...signalRActions, ...receiverActions, ...receiverLogActions, ...modalActions, ...notificationActions }, dispatch)
)(ReceiverLogTable);