import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as userActions } from '../../../store/User'
import ModalFooter from '../ModalFooter'

class DeleteUser extends React.Component {
    render() {
        return (
            <div>
                <div className='modal-body'>
                    <div className="modal-text">
                        Are you sure you want to remove user <b>{this.props.arg1["username"]}</b>?
                    </div>
                </div>
                <ModalFooter button2Submit={() => {
                    this.props.deleteUser(this.props.arg1['token'], this.props.arg1["username"]);
                    this.props.hideModal()
                }} />
            </div>
        );
    }
}


export default connect(
    state => state,
    dispatch => bindActionCreators({ ...modalActions, ...userActions }, dispatch)
)(DeleteUser);
