import React from 'react'
import StatusCard from './StatusCard'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Receiver';

// Shows general info about the table of receivers
class StatusCards extends React.Component {
    onClick= (status) => {
        this.props.setTargetStatus(status)
    }
    render() {
        return (
            <div className="receivers-status-cards-wrapper row justify-content-between deselect-trigger">
                <StatusCard onClick={()=>{this.onClick("")}} name="Total" className={(this.props.statusSearch == "" ? " status-card-highlight" : "")} counts={this.props.globalRxCount.total} />
                <StatusCard onClick={()=>{this.onClick("0")}} className={"gray" + (this.props.statusSearch == "0" ? " status-card-highlight" : "")} name="Idle" counts={this.props.globalRxCount.idle} />
                <StatusCard onClick={()=>{this.onClick("1")}} className={"green" + (this.props.statusSearch == "1" ? " status-card-highlight" : "")} name="Busy" counts={this.props.globalRxCount.busy} />
                <StatusCard onClick={()=>{this.onClick("9")}} className={"red" + (this.props.statusSearch == "9" ? " status-card-highlight" : "")} name="Offline" counts={this.props.globalRxCount.offline} />
            </div>
        );
    }
}

export default connect(
    state => state.receiver,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(StatusCards);
