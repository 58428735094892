import React from 'react';
import ModalFooter from '../ModalFooter'
import ModalCheckbox from '../ModalInputs/ModalCheckbox'
import {Translate} from 'react-localize-redux'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'

class LogUploadBusy extends React.Component {
    state = {
        checked: false
    }
    logUploadCheck = (e) => {
        this.setState({checked: !this.state.checked})
    }

    render() {
        return (            
            <div>
                <div className='modal-body'>
                    <p className='modal-text'><Translate id="LogUploadBusyBody"/></p>
                    <ModalCheckbox nospacing={true} label={"CancelCurrentLogUpload"} onChange={this.logUploadCheck} checked={this.state.checked}/>
                </div>
                {this.props.isModal && <ModalFooter button2Submit={this.state.checked && (()=>{this.props.resetRxLogServer();this.props.hideModal()})}/>}       
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions }, dispatch)
)(LogUploadBusy);
