import Utils from "../Utils/utils";
import {actionCreators as Modal} from './Modal'
import API from "../REST/RestApi";
import {actionCreators as Notification, notifications, notificationTypes} from './Notifications'
import {actionCreators as siteActions } from './Sites'
import {helpers as FeatureHelpers} from './FeatureToggle'

const updateType = 'UPDATE';
const setTableType = "SET_TABLE"
const selectType = 'SELECT'
const updateCountsType = "UPDATE_COUNTS"
const setTaskType = "SET_TARGET_TASK"
const setTargetType = "SET_TARGET_TYPE"
const setStatusType = "SET_STATUS_TYPE"
const initialState = { 
    receivers: [],
    rxCount: {
        total: 0,
        idle: 0,
        offline: 0,
        busy: 0,
    },
    globalRxCount:{
        total: 0,
        idle: 0,
        offline: 0,
        busy: 0,
    },
    targetTask: null,
    startIndex: 0,
    length: 0,
    selected: {start: -1, end: -1, macs: {}},
    settings: {},
    targetType: "Sites",
    dataTables: null,
    statusSearch: ""
 };

 
export const actionCreators = {
    setDataTable: (dataTables) => async(dispatch,getState) => {
        dispatch({type: setTableType, dataTables})
    },
    //Datatables can be refreshed from anywhere in the app

    setTargetTask: (taskId) => async(dispatch,getState) => {
        var targetType = "Tasks"
        dispatch({type: setTaskType, taskId, targetType})
        var receivers = getState().receiver 
        receivers.dataTables.rows().invalidate()
        receivers.dataTables.draw()
    },

    
    setTargetType: (targetType) => async(dispatch,getState) => {
        dispatch({type: setTargetType, targetType})
        var receivers = getState().receiver 
        try{
            receivers.dataTables.rows().invalidate()
            receivers.dataTables.draw()
        }
        catch(e){
            if(e instanceof TypeError){
                console.log(e)
            }
            else{
                throw e
            }
        }

    },

    setTargetStatus: (statusSearch) => async(dispatch,getState) => {
        var receivers = getState().receiver
        if(receivers.statusSearch == statusSearch){
            statusSearch = ""
        }
        dispatch({type: setStatusType, statusSearch})
        if(receivers.dataTables){
            receivers.dataTables.rows().invalidate()
            receivers.dataTables.draw()
        }
    },

    getRx: (cb,errorCb) => async(dispatch,getState) => {
        var state = getState()
        var receiver = state.receiver
        if(Object.keys(receiver.selected.macs).length == 1 ){
            API.getReceiver(Object.keys(receiver.selected.macs)[0],cb,errorCb)
        }
    },

    updateReceivers: (start,length,keyword,sortColumn,sortAsc,dataTablesCb,errorCb) => async(dispatch,getState) => {
        var state = getState()
        var receiver = state.receiver
        var task = state.tasks
        var signalr = state.signalr
        var sites = []
        var tasks = []
        if(Object.keys(state.sites.selectedSites.sites).length > 0){
        // if(receiver.targetType == "Sites"){
            sites = Object.keys(state.sites.selectedSites.sites)
            for(var i = 0; i<sites.length; i++){
                if(sites[i] == "-1"){ //If it is "All Receivers" remove all keys and send back an empty array
                    sites = []
                    break
                }
            }
        }
        else{
            if(task.lastSelected){
                tasks = [task.lastSelected]
            }
        }
        var statusSearch = ""
        if(signalr.websocketState){ //If the user is not connected to the Hub all receivers appear as offline so the option is disabled
            var statusSearch = receiver.statusSearch
        }
        API.getReceivers(start,length,sites,tasks,statusSearch,keyword,sortColumn,sortAsc,(res)=>{
            var receivers = res.data
            var mappedCounts = { //Receivers will appear as offline when disconnected from hub
                "idle": 0,"busy":0,"offline":res.total, "total": res.total
            }
            if(signalr.websocketState){
                mappedCounts = {
                    "idle": res.totalIdle,"busy":res.totalBusy,"offline":res.totalOffline,"total": res.total
                }
            }

            receivers.forEach((receiver)=>{
                if(!signalr.websocketState){
                    receiver.RXStatus = 9
                }
                receiver.SiteName = receiver.SiteName
            })
            dispatch({type: updateType, receivers, mappedCounts, start, length})
            if(dataTablesCb){
                dataTablesCb() //update datatables in receivers page
            }
        },()=>{
            if(errorCb){
                errorCb()
            }
        })
    },

    updateCounts: (cb, errorCb) => async(dispatch,getState) => {
        var state = getState()
        var signalr = state.signalr
        var mappedCounts = {}
        API.getReceivers(0,1,[],[],"","","RXName",true,(res)=>{
            if(!signalr.websocketState){
                mappedCounts = {
                    "idle": 0, "busy": 0, "offline": res.totalOffline+res.totalBusy+res.totalIdle, "total": res.total
                }
            }
            else{
                mappedCounts = {
                    "idle": res.totalIdle,"busy":res.totalBusy,"offline":res.totalOffline,"total": res.total
                }
            }
            dispatch({type:updateCountsType, mappedCounts})
            cb()
        },()=>{
            errorCb()
        })
    },

    select: (target,offset) => async(dispatch,getState) => {
        
        var state = getState()
        var sites = state.sites
        var receivers = state.receiver.receivers
        var prevSelected = state.receiver.selected
        var featureMap = state.featureToggle.state
        var rolesMap = sites.rolesMap

        var filter = (rx) => {
            var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
            if(rowAllowed){
                if(rolesMap[rx.SiteID] == "ScreenBeamAdmin"){
                    return true
                }
            }
            return false
        }
        if(state.user.isSystemAdmin === "true"){
            filter = (rx) =>{
                var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
                return rowAllowed
            }
        }
        
        var selected = Utils.select(receivers,prevSelected,offset,target,"macs","RXMAC",false,filter)
        dispatch({type:selectType,selected})
        return selected
    },

    selectForce: (target,offset) => async(dispatch,getState) => {
        var state = getState()
        var sites = state.sites
        var receivers = state.receiver.receivers
        var prevSelected = state.receiver.selected
        var featureMap = state.featureToggle.state
        var rolesMap = sites.rolesMap
        var filter = (rx) => {
            var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
            if(rowAllowed){
                if(rolesMap[rx.SiteID] == "ScreenBeamAdmin"){
                    return true
                }
            }
            return false
        }
        if(state.user.isSystemAdmin === "true"){
            filter = (rx) =>{
                var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
                return rowAllowed
            }
        }
        var selected = Utils.select(receivers,prevSelected,offset,target,"macs","RXMAC", true, filter)
        dispatch({type:selectType,selected})
        return selected
    },
    
    selectAdd: (target,offset) => async(dispatch,getState) => {
        var state = getState()
        var sites = state.sites
        var receivers = state.receiver.receivers
        var featureMap = state.featureToggle.state
        var selected = state.receiver.selected
        var rolesMap = sites.rolesMap
        var filter = (rx) => {
            var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
            if(rowAllowed){
                if(rolesMap[rx.SiteID] == "ScreenBeamAdmin"){
                    return true
                }
            }
            return false
        }
        if(state.user.isSystemAdmin === "true"){
            filter = (rx) =>{
                var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
                return rowAllowed
            }
        }
        selected = Utils.selectAdd(receivers,selected,offset,target,"macs","RXMAC", filter)
        dispatch({type:selectType,selected})
        return selected
    },

    requestSelectMultiple: (start,end,sortColumn,keyword,ascending=true,cb) => async(dispatch,getState) => {
        var state = getState()
        var receiver = state.receiver
        var queryStart
        var queryEnd
        var receivers

        var rolesMap = state.sites.rolesMap
        var featureMap = state.featureToggle.state

        var filter = (rx) => {
            var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
            if(rowAllowed){
                if(rolesMap[rx.SiteID] == "ScreenBeamAdmin"){
                    return true
                }
            }
            return false
        }
        if(state.user.isSystemAdmin === "true"){
            filter = (rx) =>{
                var rowAllowed = FeatureHelpers.checkRxAllowed(rx,featureMap)
                return rowAllowed
            }
        }

        //Users requests to select a range of receivers that are above his current viewing range(selects and scrolls down)
        if(start<receiver.startIndex){
            queryStart = start
            queryEnd = receiver.startIndex
        }

        //User requests to select a range of receivers that are below his current viewing range(selects and scrolls up)
        else if(start > receiver.startIndex+receiver.length){
            queryStart = receiver.startIndex+receiver.length
            queryEnd = start
        }

        var selected
        if(!isNaN(queryStart)){
            var sites = []
            var tasks = []
            if(receiver.targetType == "Sites"){
                sites = Object.keys(state.sites.selectedSites.sites)
                for(var i = 0; i<sites.length; i++){
                    if(sites[i] == "-1"){ //If it is "All Receivers" remove all keys and send back an empty array
                        sites = []
                        break
                    }
                }
            }
            else{
                tasks = [receiver.targetTask]
            }
            API.getReceivers(queryStart,queryEnd-queryStart,sites,tasks,receiver.statusSearch,keyword,sortColumn,ascending,(res)=>{
                //POSSIBLE UX IMPROVEMENT: Selection for in view receivers can happen before the API call for a better experience. Will require a system for preventing the user from making an API call if the selection call hasn't responded
                if(start<receiver.startIndex){
                    receivers = [...res.data,...receiver.receivers.slice(0,end-receiver.startIndex + 1)]
                }
                else{
                    receivers = [...receiver.receivers.slice(end-receiver.startIndex),...res.data]                    
                }
                selected = Utils.selectMultipleMacs(end,receivers,receiver.selected,filter)
                dispatch({type:selectType,selected})
                cb()
            },()=>{
                dispatch(Notification.createNotification(notificationTypes.error,notifications.errorMessages.genericFail))
            })           
        }
        else{
            if(end>start){
                receivers = receiver.receivers.slice(start-receiver.startIndex,end-receiver.startIndex+1)
            }
            else{
                receivers = receiver.receivers.slice(end-receiver.startIndex,start-receiver.startIndex+1)
            }
            selected = Utils.selectMultipleMacs(end,receivers,receiver.selected,filter)
            dispatch({type:selectType,selected})
            cb()
        }
    },

    deselectReceivers: () => async(dispatch,getState) => {
        var receivers = getState().receiver 
        var selected =  {start: -1, end: -1, macs: {}}
        dispatch({type: selectType, selected})
        if(receivers.dataTables){
            receivers.dataTables.addSelectedClass(receivers.dataTables, {})
        }
    },
    setDescription: (description, cb) => async(dispatch,getState) => {
        var receivers = getState().receiver 
        var selectedMacs =  Object.keys(receivers.selected.macs)
        API.setDescription(selectedMacs, description, cb)
    },
    deleteReceiver: (successCb,errorCb) => async(dispatch,getState) => {
        var state = getState().receiver
        var selected = state.selected
        var macs = Object.keys(selected.macs)
        var startTime = new Date()

        API.deleteReceivers(macs,(res)=>{
            if(res.status == 400){
                dispatch(Notification.createNotification(notificationTypes.error,res.message))
                return
            }
            var successCount = macs.length - parseInt(res.message.split(" ")[0])
            if(successCount <= 0){
                dispatch(Notification.createNotification(notificationTypes.warning,notifications.warningMessages.deleteRxReceivedFail))
                return
            }
            if(state.dataTables){
                state.dataTables.rows().invalidate()
                state.dataTables.draw()
            }
            var timeElapsed = (new Date() - startTime)/1000
            dispatch(Notification.createNotification(notificationTypes.success,notifications.successMessages.deleteRxSuccess(macs.length,successCount, timeElapsed)))
            dispatch(actionCreators.deselectReceivers())
            if(successCb){
                successCb()
            }
        },(res)=>{
            dispatch(Notification.createNotification(notificationTypes.error,notifications.errorMessages.deleteRxFail))
            if(errorCb){
                errorCb()
            }
        })
    }
};

export const reducer = (state, action) => {
  //Initialize state
  state = state || initialState;

  //Return copy of state with edited state
  if (action.type === updateType) {
    return { ...state, receivers: action.receivers, rxCount: action.mappedCounts, startIndex: action.start, length: action.length};
  }
  else if (action.type === selectType){
      return {...state, selected: action.selected}
  }
  else if (action.type === setTableType){
      return {...state, dataTables: action.dataTables}
  }
  else if (action.type === updateCountsType){
      return {...state, globalRxCount: action.mappedCounts}
  }
  else if (action.type === setTaskType){
      return {...state, targetTask: action.taskId, targetType: action.targetType}
  }
  else if (action.type === setTargetType){
      return {...state, targetType: action.targetType}
  }
  else if (action.type === setStatusType){
      return {...state, statusSearch: action.statusSearch}
  }
  return state;
};
