import React from 'react';
import ModalFooter from '../ModalFooter'
import { actionCreators as siteActions } from '../../../store/Sites'
import { actionCreators as modalActions} from '../../../store/Modal'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//Modal grabs the selected site through the store.
//Currently this expects only one site to be able to be selected

class DeleteSiteWarning extends React.Component {
    render() {
        return (            
            <div>
                <div className='modal-body'>
                    <div className='modal-text'>Are you sure you want to delete {this.props.arg1.name}? All child sites will also be deleted.</div>
                </div>
                <ModalFooter button2Submit={()=>{this.props.deleteSite(this.props.arg1.id);this.props.hideModal()}}/>
            </div>
        );
    }
}

export default connect(
    state => state.sites,
    dispatch => bindActionCreators({...siteActions,...modalActions},  dispatch)
  )(DeleteSiteWarning);
  
