// const config = {
//     // By default, hide a few unnecessary columns
//     // "info": false,
//     // "scrollY": "calc(100vh - 500px)",
//     // "scrollX": true,
//     "info": false,
//     "dom": "tir",
//     "serverSide": true,
//     "ordering": true,
//     "searching": true,
//     "scrollY": "70vh",
//     "sScrollX": "100%",
//     "scroller": {
//         loadingIndicator: true
//     },

//     "columns": [
//         { "data" : "ReceiverName" },
//         { "data" : "ReceiverStatus" },
//         { "data" : "ReceiverMac" },
//         { "data" : "IpAddress" },
//         { "data" : "ModelName"},
//         { "data" : "HardwareVersion"},
//         { "data" : "FirmwareVersion"},
//         // { "data" : "RxVersion" },
//         // { "data" : "HardwareVersion" },
//         // { "data" : "HostName"},
//         { "data" : "ReceiverFeedback"}
//     ],
// }

// // const headerMap = {
// //     "ReceiverName" : "Name",
// //     "ReceiverStatus": "Status",
// //     "ReceiverMac" : "Id",
// //     "IpAddress" : "Ip",
// //     "Manufacturer" : "ManufacturerName",
// //     "Product Name" : "ProductName",
// //     "Model Name" : "ModelName",
// //     // "Firmware Version": "RxVersion",
// //     "Hardware Version": "HardwareVersion",
// //     "Host Name" : "HostName",
// //     "Feedback" : ""
// // }

// const headerList = [
//     "ReceiverName",
//     "ReceiverStatus",
//     "ReceiverMac",
//     "IpAddress",
//     "ModelName",
//     "HardwareVersion",
//     "FirmwareVersion",
//     "ReceiverFeedback"
// ]

// module.exports = {
//     config: config,
//     // headerMap: headerMap
//     headerList: headerList
// }
// import ReceiverStatus from './ReceiverStatus'
// import React from 'react'
var getVisibility = () => {
    return {
        RXName:  sessionStorage.getItem('rxTableReceiverName') == 'false' ? false : true,
        SiteName: sessionStorage.getItem('rxTableSiteName') =='false' ? false : true ,
        translatedStatus: sessionStorage.getItem('rxTableReceiverStatus') == 'false' ? false : true ,
        RXMAC: sessionStorage.getItem('rxTableReceiverMac') == 'false' ? false : true ,
        RXIP: sessionStorage.getItem('rxTableIpAddress') == 'false' ? false : true,
        ManufacName: sessionStorage.getItem('rxTableManufacturer') == 'true' ? true : false,
        ProductName: sessionStorage.getItem('rxTableProductName') == 'true' ? true : false,
        ModelName: sessionStorage.getItem('rxTableModelName') == 'false' ? false : true,
        RXVersion: sessionStorage.getItem('rxTableFirmwareVersion') == 'false' ? false : true,
        HwVersion:  sessionStorage.getItem('rxTableHardwareVersion') == 'true' ? true : false,
        RXHostname: sessionStorage.getItem('rxTableHostName') == 'true' ? true : false ,
        Feedback:  sessionStorage.getItem('rxTableReceiverFeedback') == 'false' ? false : true,
        description: sessionStorage.getItem('rxTableDescription') == 'true' ? true : false,
        chUptime: sessionStorage.getItem('rxTableUptime') == 'true' ? true : false,
        SerialNo: sessionStorage.getItem('rxTableSerialNo') == 'true' ? true: false,
        P2pMac: sessionStorage.getItem('rxTableP2PMac') == 'true' ? true : false,
        LmiMac: sessionStorage.getItem('rxTableLmiMac') == 'true' ? true: false,
        EthMac: sessionStorage.getItem("rxTableEthMac") == 'true' ? true: false,
        WlanMac: sessionStorage.getItem('rxTableWlanMac') == 'true' ? true: false,
        CertsExpiredDate: sessionStorage.getItem('rxTableWlanExpiration') == 'true' ? true: false,
        EthCertsExpiredDate: sessionStorage.getItem('rxTableEthExpiration') == 'true' ? true: false,
        wifiChannel: sessionStorage.getItem('rxTablewifiChannel') == 'true' ? true: false
    }
}


var getConfig = () => {
    var savedVisibility = getVisibility()
    //Return configs through a function to create a new instance of configs each time.
    var config = {
        "info": false,
        "dom": "ti",
        "serverSide": true,
        "ordering": true,
        "searching": true,
        // "scrollY": "70vh",
        "scrollY": 'calc(100vh - 340px)',        
        "sScrollX": "100%",
        "scroller": {
            loadingIndicator: true,
            // serverWait: 100, //default 200
        },
        // 'dom': 'Rlfrtip',        
        "processing": true,
        "columns": [
            { data: "RXName", visible: savedVisibility.RXName},
            { data: "SiteName", visible: savedVisibility.SiteName},
            { data: "translatedStatus", visible: savedVisibility.translatedStatus},
            { data: "RXMAC", visible: savedVisibility.RXMAC },
            { data: "RXIP", visible: savedVisibility.RXIP },
            { data: "ManufacName" , visible: savedVisibility.ManufacName},
            { data: "ProductName" , visible: savedVisibility.ProductName },
            { data: "ModelName" , visible: savedVisibility.ModelName} ,
            { data: "RXVersion" , visible: savedVisibility.RXVersion},
            { data: "HwVersion" , visible: savedVisibility.HwVersion},
            { data: "RXHostname" , visible: savedVisibility.RXHostname},
            { data: "Feedback" , visible: savedVisibility.Feedback},
            { data: "description", visible: savedVisibility.description},
            { data: "chUptime", visible: savedVisibility.chUptime},
            { data: "serialNumber", visible: savedVisibility.SerialNo},
            { data: "P2pMac", visible: savedVisibility.P2pMac},
            { data: "LmiMac", visible: savedVisibility.LmiMac},
            { data: "EthMac", visible: savedVisibility.EthMac},
            { data: "WlanMac", visible: savedVisibility.WlanMac},
            { data: "CertsExpiredDate", visible: savedVisibility.CertsExpiredDate},
            { data: "EthCertsExpiredDate", visible: savedVisibility.EthCertsExpiredDate},
            { data: "AutoGOChannel", visible: savedVisibility.wifiChannel}
        ],
        //ColumnDefs defined on receivertable.js for access to jquery and moment
    }

    return config
}

var getHeaderMap = () => {
    var savedVisibility = getVisibility()
    return {
        "ReceiverName": {name: "RXName", visible: savedVisibility.RXName},
        "SiteName": {name: "SiteName", visible: savedVisibility.SiteName},
        "ReceiverStatus": {name: "RXStatus", visible: savedVisibility.translatedStatus},
        "ReceiverMac": {name: "RXMAC", visible: savedVisibility.RXMAC},
        "IpAddress": {name: "RXIP", visible: savedVisibility.RXIP},
        "Manufacturer": {name: "ManufacName", visible: savedVisibility.ManufacName},
        "ProductName": {name: "ProductName", visible: savedVisibility.ProductName},
        "ModelName": {name: "ModelName", visible: savedVisibility.ModelName},
        "FirmwareVersion": {name: "RXVersion", visible: savedVisibility.RXVersion},
        "HardwareVersion": {name: "HwVersion", visible: savedVisibility.HwVersion},
        "HostName": {name: "RXHostname", visible: savedVisibility.RXHostname},
        "ReceiverFeedback": {name: "Feedback", visible: savedVisibility.Feedback},
        "Description": {name: "description", visible: savedVisibility.description},
        "Uptime": {name: "chUptime", visible: savedVisibility.chUptime},
        "SerialNo": {name: "SerialNo", visible: savedVisibility.SerialNo},
        "P2PMac": {name: "P2pMac", visible: savedVisibility.P2pMac},
        "LmiMac": {name: "LmiMac", visible: savedVisibility.LmiMac},
        "EthMac": {name: "ethMac", visible: savedVisibility.EthMac},
        "WlanMac": {name: "wlanMac", visible: savedVisibility.WlanMac},
        "WlanExpiration": {name: "CertsExpiredDate", visible: savedVisibility.CertsExpiredDate},
        "EthExpiration": {name: "EthCertsExpiredDate", visible: savedVisibility.EthCertsExpiredDate},
        "wifiChannel": {name: "wifiChannel", visible: savedVisibility.wifiChannel}
    }
}

module.exports = {
    getConfig: getConfig,
    getHeaderMap: getHeaderMap
}
