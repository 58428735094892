///////////////////////////////////////////////////////
//DEPRECATED USE LIBRARY react-contextmenu instead via
///////////////////////////////////////////////////////
//import {ContextMenu, MenuItem,ContextMenuTrigger} from "react-contextmenu"

const createType = 'CREATE_CONTEXT';
const hideType = "HIDE_CONTEXT"
const initialState = {
    componentName: "",
    x: 0,
    y: 0,
    show: false,
};
//Helper function - Create Modal component
export const actionCreators = {
    createContext: (x,y,componentName) => async(dispatch,getState) => {
        var show = true
        dispatch({type:createType,show, x,y,componentName})
    },

    hideContext: () => async(dispatch,getState) => {
        var show = false
        dispatch({type:hideType, show})
    }
}

export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;
    
    //Return copy of state with edited state
    if (action.type === createType) {
        return { ...state, show: action.show, x: action.x, y: action.y, componentName: action.componentName};
    }
    if (action.type === hideType){
        return {...state, show: action.show}
    }
    return state;
};
      