/*
    Description: Used to create dropdown list
    Example: 
           <ModalSelect
                key={"select" + index}
                index={index}
                label={setting.label}
                value={setting.value}
                info={setting.info}
                disabled={display == "2" ? true : false}
                options={setting.options}
                onChange={(event) => { this.onChange(event, key) }}
            />
*/

import React from 'react';
import { Translate } from 'react-localize-redux'

class ModalSelect extends React.Component {

    parseOptions = () => {
        //Handle differently if passed array vs object 
        if (Array.isArray(this.props.options)) {
            return (this.props.options.map((item, index) => {
                if(item.nestLevel != null && item.nestLevel != undefined){
                    return (<option key={item.key + index} value={item.key}>{"-".repeat(item.nestLevel) + item.value}</option>)
                }
                else{
                    return (<option key={item} value={item}>{item}</option>)
                }
            }))
        }
        else {
            return (Object.keys(this.props.options).map((key, index) => {
                if (this.props.invertKeys) {
                    return (
                        <option key={key + index} value={key}>{this.props.options[key]}</option>
                    )
                }
                else {
                    return (
                        <Translate key={this.props.options[key] + "-" + key}>
                            {({ translate }) =>
                                <option value={this.props.options[key]}>{translate(key)}</option>
                            }
                        </Translate>
                    )
                }
            }))
        }

    }

    render() {
        var componentClassName = "form-group row settings-row"
        if (this.props.customClassName) componentClassName = "form-group row settings-row " + this.props.customClassName
        var dropdownCustomClassName = "modal-select col-sm-4"
        if (this.props.customDropdownClassName) dropdownCustomClassName = "modal-select " + this.props.customDropdownClassName
        return (
            <div className={componentClassName}>
                <label className="modal-label col-sm-4 col-form-label" htmlFor={"updateType" + this.props.index}><Translate id={this.props.label} /></label>
                <select disabled={this.props.disabled} onChange={(event) => { this.props.onChange(event) }} id={"updateType" + this.props.index} className={dropdownCustomClassName} value={this.props.value}>
                    {this.parseOptions().map((element) => {
                        return (element)
                    })}
                </select>
                {this.props.error && 
                    <div className="col-sm-3 error"><Translate id={this.props.error}/></div>
                }
                {(!this.props.error) &&
                    <div className="col-sm-3 info"><Translate id={this.props.info}/></div>
                }
            </div>
        );
    }
}


export default ModalSelect
