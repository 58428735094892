var moment = require('moment')

var getVisibility = () => {
    return {
        rowNo: sessionStorage.getItem("scheLogRowNo") == 'false' ? false: true,
        scheName:  sessionStorage.getItem('scheLogscheName') == 'false' ? false : true,
        // RunCode: sessionStorage.getItem('scheLogRunCode') =='false' ? false : true ,
        ReportCode: sessionStorage.getItem('scheLogReportCode') == 'false' ? false : true ,
        ReportSubCode: sessionStorage.getItem('scheLogReportSubCode') == 'false' ? false : true ,
        Result: sessionStorage.getItem('scheLogResult') == 'false' ? false : true,
        // ResultStat: sessionStorage.getItem('scheLogResultStat') == 'false' ? false: true,
        DateTime: sessionStorage.getItem('scheLogDateTime') == 'false' ? false : true,
        CreationTime: sessionStorage.getItem('scheLogCreationTime') == 'false' ? false : true,
    }
}


var getConfig = () => {
    var visibilityState = getVisibility()
    // console.log(visibilityState)
    //Return configs through a function to create a new instance of configs each time.
    return {
        "info": false,
        "dom": "ti",
        "serverSide": true,
        // "scrollY": "70vh",
        "scrollY": 'calc(100vh - 340px)',        
        "sScrollX": "100%",
        "scroller": {
            loadingIndicator: true,
        },
        "select": false,
        "columns": [
            {data: "schelog_scheID", visible: visibilityState.rowNo},
            {data: "schelog_scheName", visible: visibilityState.scheName},
            // {data: "schelog_RunCode", visible: visibilityState.RunCode},
            {data: "schelog_ReportCode", visible: visibilityState.ReportCode},
            {data: "schelog_ReportSubCode", visible: visibilityState.ReportSubCode},
            {data: "schelog_Result", visible: visibilityState.Result},
            // {data: "schelog_ResultStat", visible: visibilityState.ResultStat},
            // {data: "schelog_scheID", visible: visibilityState.EventCode},
            {data: "schelog_DateTime_utc", visible: visibilityState.DateTime},
            {data: "schelog_CreationTime", visible: visibilityState.CreationTime},          
        ],

        order: [[0,"desc"]]
    }    
}
var getHeaderMap = () => {
    var visibilityState = getVisibility()
    return {
        "RowNo" : {name: "schelog_scheID", visible: visibilityState.rowNo},
        "TaskName" : {name: "schelog_scheName", visible: visibilityState.scheName },
        // "RunCode" : {name: "schelog_RunCode", visible: visibilityState.RunCode},
        "ReportCode": {name: "schelog_ReportCode", visible: visibilityState.ReportCode},
        "ReportSubcode" : {name: "schelog_ReportSubCode", visible: visibilityState.ReportSubCode},
        "Result": {name: "schelog_Result", visible: visibilityState.Result},
        // "ResultStat" : {name: "schelog_ResultStat", visible: visibilityState.ResultStat},
        // "RowNumber" : {name: "schelog_scheID", visible: visibilityState.RowNumber},
        "DateTime" : {name: "schelog_DateTime_utc", visible: visibilityState.DateTime},
        "CreationTime" : {name: "schelog_CreationTime", visible: visibilityState.CreationTime},
    }
}

const scopeList = {
    "TaskName": "schelog_scheName",
    // "RunCode": "schelog_RunCode",
    // "ReportCode": "schelog_ReportCode",
    // "ReportSubcode": "schelog_ReportSubCode",
    // "ResultStat": "schelog_ResultStat",
    // "RowNumber": "schelog_scheID",
    "DateTime": "schelog_DateTime_utc",
    // "CreationTime": "schelog_CreationTime"
}

module.exports = {
    getConfig: getConfig,
    getHeaderMap: getHeaderMap,
    scopeList: scopeList
}