import React from 'react';
import ModalFooter from '../ModalFooter'
import ModalSelect from '../ModalInputs/ModalSelect'
import ModalInput from '../ModalInputs/ModalTextInput'
import InputBox from '../../AddBoxes/InputBox'
import OutputBox from '../../AddBoxes/OutputBox'

class AddUserToSite extends React.Component {
    determineAllUserClassName = (user) => {
        if(user.roleId == "CMSSystemAdmin" || user.inherited){
            return "add-site-inherited"
        }
        if(user.added){
            return "add-site-added"
        }
        if(this.props.selected[user.id]){
            return "add-site-selected"
        }
    }
    determineUserClassName = (user) => {
        if(user.roleId == "CMSSystemAdmin" || user.inherited){
            return "add-site-inherited"
        }
        if(this.props.selected[user.id]){
            return "add-site-selected"
        }
    }
    render() {
        return (     
            <div className='modal-body'>
                <div className="row">
                    <div className="col-md-5">
                        <InputBox label="All Users" onChange={this.props.onSearchChange} value={this.props.searchInput}>
                            {this.props.allUsers.map((user,index)=>{
                                return(<div className={this.determineAllUserClassName(user)} onClick={(event)=>{this.props.onSelect(event,index,0)}} key={"allUser"+index}>{user.name}</div>)
                            })}
                        </InputBox>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <OutputBox onAdd={this.props.addAdmin} onRemove={this.props.removeAdmin} label="Admins">
                                {this.props.admins.map((user,index)=>{
                                    return(<div className={this.determineUserClassName(user)} onClick={(event)=>{this.props.onSelect(event,index,1)}} key={"admin"+index}>{user.name}</div>)
                                })}
                            </OutputBox>
                        </div>
                        <div className="row">
                            <OutputBox onAdd={this.props.addMonitor} onRemove={this.props.removeMonitor} label="Monitors">
                                {this.props.monitors.map((user,index)=>{
                                    return(<div className={this.determineUserClassName(user)} onClick={(event)=>{this.props.onSelect(event,index,2)}} key={"monitor"+index}>{user.name}</div>)
                                })}
                            </OutputBox>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default AddUserToSite