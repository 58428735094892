import React from 'react'
import { Translate } from 'react-localize-redux'

class ModalRatioInput extends React.Component {
    render() {
        return (
            <div className="row settings-row">
                <label className="col-sm-4 col-form-label"><Translate id={this.props.label}/></label>
                <div className="col-sm-1"><input type="text" disabled={this.props.disabled} className="ip-input" maxLength="3" value={this.props.value} name={this.props.horizontalName} onChange={(event) => { this.props.onChange(event, this.props.horizontalName) }} /></div>
                <div className="col-sm-s2">/</div>
                <div className="col-sm-1"><input type="text" disabled={this.props.disabled} className="ip-input" maxLength="3" value={this.props.verticalValue} name={this.props.verticalName} onChange={(event) => { this.props.onChange(event, this.props.verticalName) }} /></div>
            </div>
        );
    }
}

export default ModalRatioInput