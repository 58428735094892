import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import API from '../../../REST/RestApi'
import { Translate } from 'react-localize-redux'
import ModalTextInput from '../ModalInputs/ModalTextInput'


class ReceiverLog extends React.Component {
    constructor(props) {
        super(props)
        if (this.props.arg1) {
            var data = this.props.arg1;
            this.state = {
                uploadType: "0",
                uploadTimer: data.uploadTimer,
            }
        } else {
            this.state = {
                uploadType: "0",
                uploadTimer: "999999",
            }
        }
    }



    // Submit
    onSubmit = async () => {
        // Check selected receivers
        var selected = this.props.receiver.selected
        if (selected.start >= 0) {
            var macs = Object.keys(selected.macs)
        }
        let uploadTimer = "0"
        if (this.state.uploadType == "0") uploadTimer = "999999"
        var socket = "socket://" + this.state.uploadURL + "/";
        API.setReceiverLog(this.props.signalr.id, macs, uploadTimer, (res) => {
        })
        this.props.hideModal();
    }


    onChange = (event, key) => {
        this.setState({ [key]: event.target.value });
    }

    render() {
        return (
            <div className='modal-body'>
                <div><Translate id="LogUpload" /></div>
                <br/>
                <div className="form-group row settings-row">
                    <div className="custom-control custom-radio custom-control-inline col-sm-12">
                        <input
                            className="custom-control-input"
                            type="radio"
                            id="radio-disable-log"
                            checked={this.state.uploadType == "0"}
                            onChange={(event) => { this.onChange(event, "uploadType") }}
                            value="0"
                        />
                        <label className="custom-control-label" htmlFor="radio-disable-log"><Translate id="DisableUploadLog" /></label>
                    </div>
                    <div style={{"padding-left": "23px", "font-size":"11px"}}><Translate id="DisableUploadLogNote"/></div>
                </div>
                <div className="form-group row settings-row">
                    <div className="custom-control custom-radio custom-control-inline col-sm-12">
                        <input
                            className="custom-control-input"
                            type="radio"
                            id="radio-immediate-upload-log"
                            checked={this.state.uploadType == "1"}
                            onChange={(event) => { this.onChange(event, "uploadType") }}
                            value="1"
                        />
                        <label className="custom-control-label" htmlFor="radio-immediate-upload-log"><Translate id="ImmediateUploadLog" /></label>
                    </div>
                    <div style={{"padding-left": "23px", "font-size":"11px" }}><Translate id="ImmediateUploadLogNote"/></div>
                </div>
                {this.props.isModal && <ModalFooter button2Submit={() => { this.onSubmit() }} />}
            </div>
        );
    }
}


export default connect(
    state => state,
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions }, dispatch)
)(ReceiverLog);
