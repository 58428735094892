import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as receiverActions} from '../../../store/Receiver'
import ModalFooter from '../ModalFooter'

class ModalDelete extends React.Component {
    onDelete = () => {
        this.props.deleteReceiver(()=>{
            this.props.hideModal()
        });
    }
    render() {
        return (     
            <div>
                <div className='modal-body'>
                    <div className="lead">
                        Are you sure you want to delete the selected device(s)?
                    </div>
                </div>
                <ModalFooter button2Submit={this.onDelete}/>
            </div>
        );
    }
}


export default connect(
    null,
    dispatch => bindActionCreators({...modalActions,...receiverActions}, dispatch)
  )(ModalDelete);
