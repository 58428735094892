const LANGUAGE = {
    "en": {
        "Idle": "Idle",
        "Busy": "Busy",
        "Offline": "Offline",
        "ConfigSuccess": "Configuration successful.",
        "ConfigPartialSuccess" : "Configuration partially successful.",    
        "ConfigPartialReboot": "Configuration partially successful.",
        "ConfigFailed": "Configuration failed.",
        "RebootOK": "Reboot OK.",
        "Rebooting": "Rebooting",
        "RebootErr": "Reboot failed.",
        "ResetOK": "Reset OK.",
        "ResetErr": "Reset failed.",
        "ScreenSaverStart": "Screen saver download started.",
        "ScreenSaverSet": "Screen saver set successfully",
        "ScreenSaverUnsupported": "Unsupported image format.",
        "ScreenSaverFileTooLarge": "The file size exceeds limit.",
        "ScreenSaverFail": "Screen saver setting failed.",
        "ScreensaverURLErr": "Screensaver URL error.",
        "ScreensaverDownloadFail": "Screensaver Download file failure",
        "BackgroundLoad": "Custom background loaded successfully.",
        "BackgroundURLErr": "Background URL error.",
        "BackgroundDownload": "Background image downloading.",
        "BackgroundUnsupported": "Unsupported image format.",
        "BackgroundRenderFail": "The image cannot be rendered correctly",
        "BackgroundTooLarge": "The file size exceeds limit.",
        "BackgroundDownloadFail": "Background Download file failure",
        "BackgroundFail": "Custom background failed to load.",
        "GetDefaultCredentials": "Got Default Credentials successfully.",
        "SetDefaultCredentials": "Default credentials set successfully.",
        "SetDefaultCredentialsFail": "Default credential update failed.",
        "GetCredentials": "Got RX Credentials successfully.",
        "GetCredentialsFail": "Failed to get RX Credentials.",
        "SetCredentials": "Credentials updated successfully.",
        "SetCredentialsStateFail": "Credential update failed. Receiver is busy.",
        "SetCredentialsFail": "Credentials update failed.",
        "FirmwarePreCheck":"Received the policy and the preliminary check is OK.",
        "FirmwareOK":"Upgrade successful. Receiver rebooting.",
        "FirmwareDownload": "Downloading firmware.",
        "FirmwareVerify": "Verifying firmware.",
        "BurningFirmware": "Burning firmware.",
        "FirmwareLatest": "A higher firmware version is required.",
        "FirmwareNoSpace": "Insufficient space for update.",
        "FirmwareURLErr": "Download URL is invalid.",
        "FirmwareDownloadFail": "Download firmware failed.",
        "CheckFail": "Verification failed.",
        "URLErr" : "The URL format is invalid.",
        "FwProhibit": "Firmware update is disabled.",
        "StateDisallow": "Firmware update rejected. Device is busy.",
        "FwUpdating": "Device updating",
        "Resetting": "The receiver is resetting",
        "SwitchCmsSuccess": "Switch CMS For Rx successful.",
        "ServerChangeFail": "Server change failed.",
        "FirmwareFail": "Firmware upgrade failed.",
        "RxLogSetOk": "Log server set successfully.",
        "InvalidServerAddress": "Invalid server address.",
        "LogUploadFail": "Log upload failed.",
        "EmptyRxLog": "Receiver log is currently empty.",
        "UnreachableServer": "The server is unreachabled.",
        "LogServerFail": "Log server setting failed.",
        "ReceiverBusy": "Receiver is busy",
        "InstallCertSuccessfully": "Install certificate successfully.",
        "CertificateDownloadFail": "Download certificate file failed.",
        "DownloadFileBegin": "Receive the policy and the preliminary check is OK",
        "InstallCertificateFail": "Fail to install certificate.",
        "FirmwareDownloadComplete": "Firmware download completed. It will start to upgrade after reboot.",
        "InvalidCredentials": "Invalid credentials.",
        "InvalidIpAddr": "Invalid IP address."
    },
    "zhcn": {
        "Idle": "TestIdle",
        "Busy": "TestBusy",
        "Offline": "TestOffline",
        "ActionCompleted": "Test Action completed successfully.",
    }
}

var ConvertMessageCodeToText = (messageType, code,lang) => {
    if(code === null) return ""
    switch(messageType) {
        case "status":
            if (code == "0") return LANGUAGE[lang]["Idle"]
            if (code == "1") return LANGUAGE[lang]["Busy"]
            if (code == "9") return LANGUAGE[lang]["Offline"]
            break;
        case "feedback":
            // Set Receiver Config Info
            if (code == "1-0") return LANGUAGE[lang]["ConfigSuccess"]
            if (code == "1-15") return LANGUAGE[lang]["ConfigPartialSuccess"]
            if (code == "1-32") return LANGUAGE[lang]["ConfigPartialSuccess"]            
            if (code == "1-33") return LANGUAGE[lang]["ConfigPartialSuccess"]
            if (code == "1-1") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-3") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-11") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-14") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-18") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-19") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-8") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-25") return LANGUAGE[lang]["ConfigFailed"]
            if (code == "1-29") return LANGUAGE[lang]["ConfigFailed"]
            // Upgrade Firmware
            if (code == "2-16") return LANGUAGE[lang]["FirmwarePreCheck"]
            if (code == "2-0") return LANGUAGE[lang]["FirmwareOK"]
            if (code == "2-1") return LANGUAGE[lang]["FirmwareFail"]   
            if (code == "2-3") return LANGUAGE[lang]["FirmwareFail"]                        
            if (code == "2-4") return LANGUAGE[lang]["FirmwareLatest"]
            if (code == "2-5") return LANGUAGE[lang]["FirmwareNoSpace"]
            if (code == "2-6") return LANGUAGE[lang]["FirmwareDownloadFail"]
            if (code == "2-7") return LANGUAGE[lang]["CheckFail"]
            if (code == "2-8") return LANGUAGE[lang]["FwUpdating"]
            if (code == "2-9") return LANGUAGE[lang]["FirmwareURLErr"]
            if (code == "2-10") return LANGUAGE[lang]["FwProhibit"]
            if (code == "2-11") return LANGUAGE[lang]["StateDisallow"]
            if (code == "2-18") return LANGUAGE[lang]["Rebooting"]
            if (code == "2-19") return LANGUAGE[lang]["Resetting"]
            if (code == "2-21") return LANGUAGE[lang]["FirmwareDownload"]
            if (code == "2-22") return LANGUAGE[lang]["FirmwareVerify"]
            if (code == "2-23") return LANGUAGE[lang]["BurningFirmware"]
            if (code == "2-25") return LANGUAGE[lang]["FirmwareFail"]            
            if (code == "2-29") return LANGUAGE[lang]["FirmwareFail"]
            if (code == "2-38") return LANGUAGE[lang]["FirmwareDownloadComplete"]            
            // Reboot          
            if (code == "3-0") return LANGUAGE[lang]["RebootOK"] 
            if (code == "3-1") return LANGUAGE[lang]["RebootErr"]
            if (code == "3-18") return LANGUAGE[lang]["Rebooting"]
            if (code == "3-3") return LANGUAGE[lang]["RebootErr"]
            if (code == "3-8") return LANGUAGE[lang]["RebootErr"]
            if (code == "3-19") return LANGUAGE[lang]["RebootErr"]
            if (code == "3-25") return LANGUAGE[lang]["RebootErr"]
            if (code == "3-29") return LANGUAGE[lang]["RebootErr"]

            // Reset to default
            if (code == "4-0") return LANGUAGE[lang]["ResetOK"]
            if (code == "4-1") return LANGUAGE[lang]["ResetErr"]
            if (code == "4-3") return LANGUAGE[lang]["ResetErr"]
            if (code == "4-8") return LANGUAGE[lang]["ResetErr"]
            if (code == "4-18") return LANGUAGE[lang]["ResetErr"]
            if (code == "4-19") return LANGUAGE[lang]["ResetErr"]
            if (code == "4-25") return LANGUAGE[lang]["ResetErr"]
            if (code == "4-29") return LANGUAGE[lang]["ResetErr"]

            // Set screen saver
            if (code == "12-0") return LANGUAGE[lang]["ScreenSaverSet"]
            if (code == "12-16") return LANGUAGE[lang]["ScreenSaverStart"]
            if (code == "12-31") return LANGUAGE[lang]["ScreenSaverUnsupported"]
            if (code == "12-34") return LANGUAGE[lang]["ScreenSaverFileTooLarge"]
            if (code == "12-1") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-3") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-6") return LANGUAGE[lang]["ScreenDownloadFail"]            
            if (code == "12-8") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-9") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-18") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-19") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-20") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-25") return LANGUAGE[lang]["ScreenSaverFail"]
            if (code == "12-29") return LANGUAGE[lang]["ScreenSaverFail"]
            
            // Set background
            if (code == "16-0") return LANGUAGE[lang]["BackgroundLoad"]
            if (code == "16-6") return LANGUAGE[lang]["BackgroundDownloadFail"]
            if (code == "16-9") return LANGUAGE[lang]["BackgroundURLErr"]
            if (code == "16-10") return LANGUAGE[lang]["BackgroundDownload"]
            if (code == "16-16") return LANGUAGE[lang]["BackgroundDownload"]            
            if (code == "16-31") return LANGUAGE[lang]["BackgroundUnsupported"]
            if (code == "16-32") return LANGUAGE[lang]["BackgroundRenderFail"]
            if (code == "16-34") return LANGUAGE[lang]["BackgroundTooLarge"]   
            if (code == "16-1") return LANGUAGE[lang]["BackgroundFail"]
            if (code == "16-3") return LANGUAGE[lang]["BackgroundFail"]
            if (code == "16-8") return LANGUAGE[lang]["BackgroundFail"]
            if (code == "16-18") return LANGUAGE[lang]["BackgroundFail"]
            if (code == "16-19") return LANGUAGE[lang]["BackgroundFail"]
            if (code == "16-20") return LANGUAGE[lang]["BackgroundFail"]
            if (code == "16-25") return LANGUAGE[lang]["BackgroundFail"]
            if (code == "16-29") return LANGUAGE[lang]["BackgroundFail"]
            
            // Management Server IP/Port Migrate Policy
            if (code == "13-0") return LANGUAGE[lang]["SwitchCmsSuccess"]
            if (code == "13-1") return LANGUAGE[lang]["ServerChangeFail"]
            if (code == "13-3") return LANGUAGE[lang]["ServerChangeFail"]            
            if (code == "13-8") return LANGUAGE[lang]["ReceiverBusy"]
            if (code == "13-18") return LANGUAGE[lang]["ReceiverBusy"]
            if (code == "13-19") return LANGUAGE[lang]["ReceiverBusy"]
            if (code == "13-20") return LANGUAGE[lang]["ReceiverBusy"]
            if (code == "13-25") return LANGUAGE[lang]["ReceiverBusy"]
            if (code == "13-13") return LANGUAGE[lang]["InvalidIpAddr"]
            if (code == "13-24") return LANGUAGE[lang]["InvalidIpAddr"]            
            
            
            // Upload receiver log
            if (code == "14-0") return LANGUAGE[lang]["RxLogSetOk"]   
            if (code == "14-9") return LANGUAGE[lang]["InvalidServerAddress"]
            if (code == "14-26") return LANGUAGE[lang]["LogUploadFail"]
            if (code == "14-27") return LANGUAGE[lang]["EmptyRxLog"] 
            if (code == "14-28") return LANGUAGE[lang]["UnreachableServer"] 
            if (code == "14-1") return LANGUAGE[lang]["LogServerFail"]
            if (code == "14-3") return LANGUAGE[lang]["LogServerFail"]             
            if (code == "14-8") return LANGUAGE[lang]["ReceiverBusy"]             
            if (code == "14-18") return LANGUAGE[lang]["ReceiverBusy"]             
            if (code == "14-19") return LANGUAGE[lang]["ReceiverBusy"]             
            if (code == "14-20") return LANGUAGE[lang]["ReceiverBusy"]             
            if (code == "14-25") return LANGUAGE[lang]["ReceiverBusy"]     
            // Install Certificate - TLS
            if (code == "18-0") return LANGUAGE[lang]["InstallCertSuccessfully"]
            if (code == "18-6") return LANGUAGE[lang]["CertificateDownloadFail"]        
            if (code == "18-16") return LANGUAGE[lang]["DownloadFileBegin"]        
            if (code == "18-1") return LANGUAGE[lang]["InstallCertificateFail"]        
             // Install Certificate - ETH
             if (code == "22-0") return LANGUAGE[lang]["InstallCertSuccessfully"]
             if (code == "22-6") return LANGUAGE[lang]["CertificateDownloadFail"]        
             if (code == "22-16") return LANGUAGE[lang]["DownloadFileBegin"]        
             if (code == "22-1") return LANGUAGE[lang]["InstallCertificateFail"]                   
            // Credential update policy
            if (code == "21-0") return LANGUAGE[lang]["SetCredentials"]
            if (code == "21-1") return LANGUAGE[lang]["SetCredentialsFail"]
            if (code == "21-11") return LANGUAGE[lang]["SetCredentialsStateFail"]
            // Credential (9.6.x.x) CMSv4 and Receivers are different
            if (code == "5-65536") return LANGUAGE[lang]["InvalidCredentials"]        

            else{
                return "Unknown feedback."
            }
            break;
        default:
            return "Unknown feedback."
    }
}

module.exports = {
     ConvertMessageCodeToText: ConvertMessageCodeToText
}
