import React from 'react';
import {Translate} from 'react-localize-redux'
class ModalPasswordInput extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.props.name ? "#" + this.props.name : "#defaultPasswordToggle";
        this.SHOW_PASSWORD_ICON_CLASS = "fa fa-eye password-icon";
        this.HIDE_PASSWORD_ICON_CLASS = "fa fa-eye-slash password-icon";
        this.INPUT_TEXT = "text";
        this.INPUT_PASSWORD = "password";
        this.ICON_COLOR_BLACK = "black";
        this.ICON_COLOR_WHITE = "white";
        this.state = {
            inputType: this.INPUT_PASSWORD,
            iconClass: this.HIDE_PASSWORD_ICON_CLASS,
            iconColor: this.ICON_COLOR_BLACK
        };
    }

    handleInputClick = (event) => {
        this.setState({
            iconColor: this.ICON_COLOR_BLACK
        });
    }

    handleShowPasswordClick = (event) => {
        let newInputType = this.state.inputType === this.INPUT_TEXT ? this.INPUT_PASSWORD : this.INPUT_TEXT;
        let newIconClass = this.state.iconClass === this.SHOW_PASSWORD_ICON_CLASS ? this.HIDE_PASSWORD_ICON_CLASS : this.SHOW_PASSWORD_ICON_CLASS;
        this.setState({
            inputType: newInputType,
            iconClass: newIconClass,
            iconColor: this.ICON_COLOR_BLACK
        });
    };

    render() {
        return (     
            <div className="form-group row settings-row">
                <label className="modal-label col-form-label col-sm-4" htmlFor={"passwordInput-" + this.props.label}><Translate id={this.props.label}/></label>
                <Translate>
                    {({ translate }) =>
                        <input type={this.state.inputType}
                        disabled={this.props.disabled} 
                        className="modal-text-input form-control col-sm-4" 
                        id={"passwordInput-" + this.props.label} 
                        name={this.props.name ? this.props.name : ""}
                        value={this.props.value}
                        placeholder={this.props.placeholder ? translate(this.props.placeholder) : ""}
                        onChange={(event)=>{this.props.onChange(event)}} 
                        />
                    }
                </Translate>
                <span toggle={this.toggle} className={this.state.iconClass} style={{ color: this.state.iconColor }} onClick={this.handleShowPasswordClick} />
                {this.props.error && 
                    <div className="col-sm-4 error password"><Translate id={this.props.error}/></div>
                }
                {(!this.props.error) &&
                    <div className="col-sm-4 info password"><Translate id={this.props.info}/></div>
                }
            </div>
        );
    }
}


export default ModalPasswordInput
