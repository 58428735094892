import React from 'react';
// import './ReceiverPageContentStyle.css'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userActions } from '../../store/User'
import { actionCreators as modalActions} from '../../store/Modal'
import './SchedulerLogPageStyle.css'
import TabbedCardWrapper from '../../components/Wrappers/TabbedCardWrapper'
import TabbedTableWrapper from '../../components/Wrappers/TabbedTableWrapper'
import Dropdown from '../../components/Dropdown/Dropdown';
import SchedulerLogTable from '../../components/SchedulerLogTable/SchedulerLogTable'

import DropdownItem from '../../components/Dropdown/DropdownItem'
import NoPrivilege from '../../components/NoPrivilegeGeneric'
import { Translate } from 'react-localize-redux'




class SchedulerLogPage extends React.Component {
    state = {
        siteGroupTabIndex: 0,
        tableTabIndex: 0
    }
    componentDidMount() {
        if(this.props.location.pathname == "/SchedulerLogs/ServerBusy"){
            this.props.createModal("Server Busy")
        }
        this.props.validate();
    }


    render() {
        if(this.props.isSystemAdmin == true || this.props.isSystemAdmin == "true"){
            return (
                <div id="scheduler-log-page" className="row no-gutters" >
                    <div id="scheduler-log-card" className="col-md-12">
                        <div className="card-wrapper">
                            <div className="card">
                                <SchedulerLogTable/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return <NoPrivilege/>
        }

    }
}

export default connect(
    state => state.user,
    dispatch => bindActionCreators({ ...userActions, ...modalActions }, dispatch)
)(SchedulerLogPage);