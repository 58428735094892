import React from 'react'
import ReceiverTable from '../ReceiverTable/ReceiverTable'
import UserTable from '../UserTable/UserTable'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as UserActions } from '../../store/User';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import StatusCards from '../../Pages/ReceiverPage/StatusCards'
import { Translate } from 'react-localize-redux'

class TabbedTableWrapper extends React.Component {
    render() {
        return (
            <div className="card-wrapper deselect-trigger">
                <div className="card deselect-trigger">
                    <Tabs selectedIndex={this.props.tabIndex} onSelect={this.props.onChangeTab ? (tabIndex => this.props.onChangeTab(tabIndex)) : null}>
                        <TabList className="react-tabs__tab-list deselect-trigger">
                            <Tab><Translate id="Receivers" /></Tab>
                            {!this.props.isSchedule && (this.props.user.isSystemAdmin == "true") && <Tab><Translate id="Users" /></Tab>}
                        </TabList>
                        <TabPanel>
                            {!this.props.isSchedule && <StatusCards />}
                            <ReceiverTable withActions={this.props.isSchedule ? false : true} />
                        </TabPanel>
                        {!this.props.isSchedule && (this.props.user.isSystemAdmin == "true") &&
                            <TabPanel>
                                <UserTable withActions={true} />
                            </TabPanel>
                        }
                    </Tabs>
                </div>
            </div>
        )
    }
}

// export default TabbedTableWrapper

export default connect(
    state => (state),
    dispatch => bindActionCreators({ ...UserActions }, dispatch)
)(TabbedTableWrapper);
