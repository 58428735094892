import {actionCreators as Modal} from './Modal'
import API from "../REST/RestApi";
import {actionCreators as Notification, notifications, notificationTypes} from './Notifications'
import {actionCreators as siteActions } from './Sites'

const updateType = 'UPDATE';
const getTableType = "GET_TABLE"
const setTableType = "SET_TABLE"
const initialState = { 
    receiverLogs: [],
    startIndex: 0,
    length: 0,
    dataTables: null,
    table:[],
    count: 0
 };

export const actionCreators = {
    setDataTable: (dataTables) => async(dispatch,getState) => {
        dispatch({type: setTableType, dataTables})
    },

    getAllReceiverLogs: (cb, errorCb) => async(dispatch,getState) => {
        var startTime = new Date()
        API.getAllReceiverLogs((res)=>{
            var receiverLog = res.data
            dispatch({type: getTableType, receiverLog})
            var timeElapsed = (new Date() - startTime)/1000
            dispatch(Notification.createNotification(notificationTypes.success,notifications.successMessages.getRxLogSuccess(timeElapsed)))
            if(cb){
                cb() //update datatables in receivers page
            }
        },()=>{
            dispatch(Notification.createNotification(notificationTypes.error,notifications.errorMessages.genericFail))
            if(errorCb){
                errorCb()
            }
        })
    },

    getAllReceiverLogsBySearchColumn: (keyword, searchColumn, isSearchedByDateTime, sortColumn, sortAsc, start, length, dataTableCallback, errorCallback) => async(dispatch, getState) => {
        var startTime = new Date()
        API.getAllReceiverLogsBySearchColumn(keyword, searchColumn, isSearchedByDateTime, sortColumn, sortAsc, start, length, (res) => {
            let receiverLog = res.data
            var timeElapsed = (new Date() - startTime)/1000
            dispatch(Notification.createNotification(notificationTypes.success,notifications.successMessages.getRxLogSuccess(timeElapsed)))
            dispatch({type: updateType, receiverLog, count:res.total})
            if (dataTableCallback) {
                dataTableCallback()
            }
        }, ()=> {
            dispatch(Notification.createNotification(notificationTypes.error,notifications.errorMessages.genericFail))
            if (errorCallback) {
                errorCallback()
            }
        })
    }
};

export const reducer = (state, action) => {
  //Initialize state
  state = state || initialState;

  //Return copy of state with edited state
  if (action.type === updateType) {
    return { ...state, receiverLogs: action.receiverLog, count: action.count};
  }
//   else if (action.type === selectType){
//       return {...state, selected: action.selected}
//   }
  else if (action.type === getTableType){
      return {...state, table: action.receiverLog}
  }
  return state;
};
