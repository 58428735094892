import {actionCreators as Receiver} from './Receiver'
const groupUpdateType = 'UPDATE_GROUPS';
const groupInitType = "INIT_GROUPS"
const updateJoinQueue="UPDATE_JOIN"
const updateReleaseQueue="UPDATE_RELEASE"
const selectType = "SELECT_GROUP"
const initialState = {
    all: {},
    ungrouped: {}, 
    groups: {},
    flat_groups: {}, //Groups and flat_groups share the same objects flat_groups are just to more easily reference the nested groups
    no_parent: {},
    selected: "",
    selectedName: ""
}

export const actionCreators = {
    initialize: (data) => async(dispatch,getState) => {
        //Initialize all and ungrouped with all receivers
        var all = {}
        var ungrouped = {}
        Object.keys(data).map((MAC)=>{
            all[MAC] = 1
            ungrouped[MAC] = 1
        })
        dispatch({type: groupInitType, all, ungrouped})
    },

    selectGroup: (guid, name) => async(dispatch,getState) => {
        //Update store with selected receivers
        dispatch({type: selectType, guid, name})
    },

    updateGroups: (res) => async(dispatch,getState) => {
        //Creates the group tree structure
        var flat_groups = {}
        var no_parent = {}
        res.forEach((group)=>{
            //Build object with parent-child structure from raw data
            var ParentId = group.ParentId
            var Id = group.Id
            //If the current group has a parent AND it has not already been set create the child and parent objects
            if(ParentId){
                if(!flat_groups[ParentId]){
                    flat_groups[ParentId] = {Name:res[ParentId].Name}
                }
                if(!flat_groups[Id]){
                    flat_groups[Id] = {Name:group.Name, children: {}}
                }
                //Attach the child object to the parent
                flat_groups[ParentId].children[Id] = flat_groups[Id]
            }
            //If there is no parent simply create the child object
            else{
                if(!flat_groups[Id]){
                    flat_groups[Id] = {Name:group.Name,children:{}}
                }
                //Keep a list of all groups with no parent
                no_parent[Id] = 1
            }
        })
        var groups = {}
        //Build structured groups with only the keys that have no parents
        Object.keys(no_parent).forEach((Id)=>{
            groups[Id] = flat_groups[Id]
        })
        dispatch({type: groupUpdateType,groups, flat_groups, no_parent})
    },    
};

export const reducer = (state, action) => {
  //Initialize state
  state = state || initialState;

  //Return copy of state with edited state
  if (action.type === groupUpdateType) {
    return { ...state, groups: action.groups, flat_groups: action.flat_groups, no_parent: action.no_parent};
  }
  else if (action.type === groupInitType){
      return {...state,all: action.all, ungrouped: action.ungrouped}
  }
  else if (action.type === updateJoinQueue){
    return {...state, joinGroupQueue: action.queue}
  }
  else if(action.type === updateReleaseQueue){
    return {...state, releaseGroupQueue: action.queue}
}
  else if (action.type === selectType){
      return {...state, selected: action.guid, selectedName: action.name}
  }

  return state;
};
