import React from 'react';
import { Translate } from 'react-localize-redux'

class ModalTextInput extends React.Component {
    render() {
        let errorDiv;

        if (this.props.error && this.props.error != "")
            errorDiv = <div className="col-sm-4 error"><Translate id={this.props.error} /></div>
        else
            errorDiv = <div></div>

        return (
            <div className="form-group row settings-row">
                <label className="modal-label col-sm-4 col-form-label" htmlFor={"textInput-" + this.props.label}><Translate id={this.props.label} /></label>
                <Translate>
                    {({ translate }) =>
                        <input type="text"
                            disabled={this.props.disabled}
                            className={this.props.disabled ? "disabled modal-text-input form-control col-sm-4" : "modal-text-input form-control col-sm-4"}
                            id={"textInput-" + this.props.label}
                            name={this.props.name ? this.props.name : ""}
                            value={this.props.value}
                            placeholder={this.props.placeholder ? translate(this.props.placeholder) : ""}
                            onChange={(event) => { this.props.onChange(event) }} />
                    }
                </Translate>
                {errorDiv}
                {(!this.props.error) &&
                    <div className="col-sm-4 info"><Translate id={this.props.info} /></div>
                }
                <div>{this.props.children}</div>
            </div>
        );
    }
}


export default ModalTextInput
