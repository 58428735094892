import React from 'react'
import {Translate} from 'react-localize-redux'
//Displays counts of receivers based on incoming props. Expected to be child of StatusCards.
class StatusCard extends React.Component {
    render() {
        return (
            <div className={"receivers-status-card col-md-2 " + this.props.className} onClick={this.props.onClick}>
               <div className="receivers-status-card-name"><Translate id={this.props.name}/><span>: </span>{this.props.counts}</div>

                {/* <div class={"receivers-card-header " + this.props.className}><Translate id={this.props.name}/><span>: </span>{this.props.counts}</div> */}
                {/* <div class="receivers-card-body">
                    <p class={"receivers-card-text " + this.props.className}>{this.props.counts}</p>
                </div> */}
            </div>
        );
        // return (
        //     <div className={"receivers-status-card col-md-3 " + this.props.className} onClick={this.props.onClick}>
        //         <span className="receivers-status-card-count">{this.props.counts}</span><span className="receivers-status-card-name">{this.props.name}</span>
        //     </div>
        // );
    }
}

export default StatusCard;
