import React from 'react';
import TabbedCardWrapper from '../../components/Wrappers/TabbedCardWrapper'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userActions} from '../../store/User'
import TabbedTableWrapper from '../../components/Wrappers/TabbedTableWrapper'
import TabbedScheduleWrapper from '../../components/Wrappers/TabbedScheduleWrapper'
import './SchedulerPageStyle.css'

class Scheduler extends React.Component {
    state = {
        siteGroupTabIndex: 0,
    }
    componentDidMount(){
        this.props.validate();
    }
    onChangeTab = (tabIndex,target) => {
        this.setState({[target]: tabIndex})
    }
    render() {
        return (
            <div className = "container-fluid">
                <div className ="row deselect-trigger">
                    <div className="col-md-12 deselect-trigger">
                        <TabbedScheduleWrapper tableHeight="30vh"/>
                    </div>
                </div>
                <div className="row no-gutters deselect-trigger">
                    <div className="col-md-3 deselect-trigger">
                        <TabbedCardWrapper isScheduler={true} tabIndex={this.state.siteGroupTabIndex}  onChangeTab={(tabIndex)=>{this.onChangeTab(tabIndex,"siteGroupTabIndex")}} />
                    </div>
                    <div className="col-md-9 deselect-trigger">
                        <TabbedTableWrapper isSchedule={true} tableHeight="30vh"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    dispatch => bindActionCreators({...userActions}, dispatch)
  )(Scheduler);