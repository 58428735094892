import React from 'react'
import { connect } from 'react-redux';
import { actionCreators as siteActions } from '../../store/Sites'
import { bindActionCreators } from 'redux';

class SiteTitle extends React.Component {
    componentDidMount(){
        if(this.nameInput){
            this.nameInput.focus()
        }
    }

    onInputBlur = (event) =>{ //When user clicks off the name input make API call to change site name
        this.props.renameSite(event.target.value)
    }

    onKeyDown = (event) => {
        //On Enter pressed
        if(event.keyCode == 13){
            if(this.nameInput){
                this.nameInput.blur()
            }
        }
    }

    // changeName = (event) => {
    //     this.props.updateRenameValue(event.target.value)
    // }

    render() {     
        return (
            <div 
                onClick={(event)=>{if(!(this.props.renameId == this.props.id)){this.props.onClick(event,this.props.id)}}} 
                onContextMenu= {this.props.onContextClick}
                className={"site-title " + ((this.props.contextTargetSite && this.props.contextTargetSite.id == this.props.id) ? " context-target" : "")}
            >
                {this.props.renameId == this.props.id ? 
                    <input onChange={this.changeName} onKeyDown={this.onKeyDown} ref={(input)=>{this.nameInput = input;}} className="site-input" type="text" onBlur={this.onInputBlur} defaultValue={this.props.renameValue}/> 
                    : <div>{this.props.name}</div>}
            </div>
        )
    }
}

export default connect(
    state => state.sites,
    dispatch => bindActionCreators({...siteActions},  dispatch)
  )(SiteTitle);
  
  