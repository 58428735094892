/*
 * Created Date: 04/09/2019
 * Description: Display Receiver CMS and Remote Management Form
 *              Triggered by USBAutoConfigRxForm
*/
import React from 'react'
import {Translate} from 'react-localize-redux'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as usbProvisioningActions } from '../../store/UsbProvisioning'
import ModalMultipleCheckbox from '../Modals/ModalInputs/ModalMultipleCheckbox'
import RxCmsSettings from './RxCmsSettings'
import TcpIpSettings from './TcpIpSettings'
import WirelessConnectionSettings from './WirelessConnectionSettings'

class RxCmsSettingsAndRemoteMgt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCheckbox: {
                "rxCmsSettings": "0",
                "tcpIpSettings": "0",
                "wirelessConnectionSettings": "0"
            }
        };
        this.selectedCheckbox = {
            "rxCmsSettings": "0",
            "tcpIpSettings": "0",
            "wirelessConnectionSettings": "0"            
        }
    }

    handleSelectAllBtnClick = () => {
        // Update all selected Checkbox
        let temp = this.state.selectedCheckbox;
        Object.keys(temp).map((key, index) => (
            temp[key] = "1"
        ));
        this.selectedCheckbox = temp;
        this.props.updateSelectedCheckbox(this.selectedCheckbox)
        this.setState({ selectedCheckbox: temp });
    }

    handleDeselectAllBtnClick = () => {
        let temp = this.state.selectedCheckbox;
        Object.keys(temp).map((key, index) => (
            temp[key] = "0"
        ));
        this.selectedCheckbox = temp
        this.props.updateSelectedCheckbox(this.selectedCheckbox)        
        this.setState({ selectedCheckbox: temp });
    }

    onCheckboxClicked = (checkboxName, isChecked) => {
        let temp = this.state.selectedCheckbox;
        temp[checkboxName] = isChecked;
        this.selectedCheckbox = temp
        this.props.updateSelectedCheckbox(this.selectedCheckbox)        
        this.setState({ selectedCheckbox: temp });
    }

    render() {
        return (
            <div>
                <div className="row justify-content-end">
                    <div className="col-sm-2 offset-sm-5">
                        <button type="button" className="btn btn-light modal-button" onClick={this.handleSelectAllBtnClick}> Select All</button>
                    </div>
                    <div className="col-sm-2">
                        <button type="button" className="btn btn-light modal-button" onClick={this.handleDeselectAllBtnClick}> Deselect All</button>
                    </div>

                </div>
                <ModalMultipleCheckbox CheckboxName="rxCmsSettings"
                    CheckboxLabel="ReceiverCmsSettings"
                    IsChecked={this.state.selectedCheckbox["rxCmsSettings"]}
                    onCheckboxClicked={this.onCheckboxClicked}>
                    <RxCmsSettings />
                </ModalMultipleCheckbox>
                <ModalMultipleCheckbox CheckboxName="tcpIpSettings"
                    CheckboxLabel="TCP/IP Settings"
                    IsChecked={this.state.selectedCheckbox["tcpIpSettings"]}
                    onCheckboxClicked={this.onCheckboxClicked}>
                    <TcpIpSettings />
                </ModalMultipleCheckbox>
                <ModalMultipleCheckbox CheckboxName="wirelessConnectionSettings"
                    CheckboxLabel="Wireless Connection Settings"
                    IsChecked={this.state.selectedCheckbox["wirelessConnectionSettings"]}
                    onCheckboxClicked={this.onCheckboxClicked}>
                    <WirelessConnectionSettings />
                </ModalMultipleCheckbox>
            </div>);
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...usbProvisioningActions }, dispatch)
)(RxCmsSettingsAndRemoteMgt);