import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalRadio from '../ModalInputs/ModalRadio'
import { Translate } from 'react-localize-redux'
import API from '../../../REST/RestApi'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


class InstallCertificate extends React.Component {
    state = {
        interfaceType: "Eth",
        data: {
            Eth: {
                // Ethernet Interface
                EthCertDateTime: { value: new Date(), label: "SystemDateTime", msgType: "" },
                EthRootCertURL: { value: "", label: "CaCertificateUrl", msgType: "info" },
                EthUserCertURL: { value: "", label: "UserCertificateUrl", msgType: "info" },
                EthUserKeyURL: { value: "", label: "PrivateKeyUrl", msgType: "info" }
            },
            TLS: {
                // Wireless Interface
                TLSCertDateTime: { value: new Date(), label: "SystemDateTime", msgType: "" },
                TLSRootCertURL: { value: "", label: "CaCertificateUrl", msgType: "info" },
                TLSUserCertURL: { value: "", label: "UserCertificateUrl", msgType: "info" },
                TLSUserKeyURL: { value: "", label: "PrivateKeyUrl", msgType: "info" }
            }
        }
    }

    message = {
        "info": "PEM. 100 KB Max",
        "error": "The download file must be in .pem format.",
        "valid": "Valid url",
        "invalid": "Unable to reach the provided url"
    }

    onInputChange = (event, key) => {
        this.setState({ [key]: event.target.value })
    }

    onChange = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [this.state.interfaceType]: {
                    ...this.state.data[this.state.interfaceType],
                    [event.target.name]: {
                        ...this.state.data[this.state.interfaceType][event.target.name],
                        value: event.target.value
                    }
                }
            }
        })
    }

    onMessageTypeChange = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [this.state.interfaceType]: {
                    ...this.state.data[this.state.interfaceType],
                    [event.target.name]: {
                        ...this.state.data[this.state.interfaceType][event.target.name],
                        msgType: event.target.value
                    }
                }
            }
        })
    }

    onSubmit = () => {
        // Check selected receivers
        var selected = this.props.receiver.selected
        if (selected.start >= 0) {
            var macs = Object.keys(selected.macs)
        }
        else {
            return
        }
        // Get data based on interface type
        var data = []
        var validation = []
        let exts = ["pem"];
        var currentData = this.state.data[this.state.interfaceType]
        for (var i in currentData) {
            let isFormatValid = false
            // Check the format of URL
            if (i != "EthCertDateTime" && i != "TLSCertDateTime") {
                let regex = /^(http:\/\/|https:\/\/).*\.pem$/
                isFormatValid = regex.test(currentData[i].value)
                // isFormatValid = (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(currentData[i].value)
                validation.push(isFormatValid)
                if (!isFormatValid) currentData[i].msgType = "error"
                if (isFormatValid && currentData[i].msgType == "error") currentData[i].msgType = "info"
            }
            data.push(currentData[i].value)
            this.setState({ data: { ...this.state.data, [this.state.interfaceType]: currentData } })
        }

        // Check if the url is reachable
        if (validation[0] == true && validation[1] == true && validation[2] == true) {
            // validation = [false, false, false]
            // Get date time and convert it to right format that receiver understands
            var createdDate = this.convertDateTime(data[0])
            API.installCertificates(this.props.signalr.id, macs, this.state.interfaceType, createdDate, data[1], data[2], data[3])
            this.props.hideModal()

            // // Get list of urls
            // var urls = data.slice(1)
            // // Get list of keys for updating message type later
            // var keys = Object.keys(currentData).slice(1);
            // Validate URL
            // this.validateURL(urls, keys, validation, () => {
            //     // Callback - Send Install Certificate request
            //     // console.log("Call install certificates")
            //     API.installCertificates(this.props.signalr.id, macs, this.state.interfaceType, createdDate, data[1], data[2], data[3])
            //     this.props.hideModal()
            // }, 0)
        } else return
    }

    validateURL = (urls, keys, validation, cb, index) => {
        var currentData = this.state.data[this.state.interfaceType]
        // Check if url is reachable
        API.validateURL(urls[index], (res) => {
            validation[index] = true
            currentData[keys[index]].msgType = "valid"
            this.setState({ data: { ...this.state.data, [this.state.interfaceType]: currentData } })
            if (index == 2) {
                if (validation[0] == true && validation[1] == true && validation[2] == true) {
                    cb()
                    return
                }
                return
            }
            this.validateURL(urls, keys, validation, cb, index + 1)

        }, (res) => {
            validation[index] = false
            currentData[keys[index]].msgType = "invalid"
            this.setState({ data: { ...this.state.data, [this.state.interfaceType]: currentData } })
            if (index == 2) return
            this.validateURL(urls, keys, validation, cb, index + 1)
        })
    }

    convertDateTime = (dateTime) => {
        // Group date time into this format: 0614hhmm2019
        var result = ""
        var month = dateTime.getMonth() + 1;
        result += month < 10 ? "0" + month : month

        var date = dateTime.getDate();
        result += date < 10 ? "0" + date : date

        var hours = dateTime.getHours();
        result += hours < 10 ? "0" + hours : hours

        var mins = dateTime.getMinutes();
        result += mins < 10 ? "0" + mins : mins

        var year = dateTime.getFullYear();
        result += year

        // console.log(result)
        return result
    }

    get Content() {
        var data = this.state.data[this.state.interfaceType]
        var that = this
        return (
            <div>
                {Object.keys(data).map((key, index) => {
                    if (key == "EthCertDateTime" || key == "TLSCertDateTime") {
                        return (
                            <div className="form-group row settings-row">
                                <label className="col-sm-4 col-form-label"><Translate id={data[key].label} /></label>
                                <div className="col-sm-4">
                                    <DatePicker selected={data[key].value} onChange={date => this.onChange({ target: { name: key, value: date } })} />
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className="form-group row settings-row">
                            <label className="col-sm-4 col-form-label"><Translate id={data[key].label} /></label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" name={key} value={data[key].value} onChange={that.onChange} />
                            </div>
                            <div className={"col-sm-4 " + data[key].msgType}>
                                <span>{this.message[data[key].msgType]}</span>
                            </div>
                        </div>)
                })}
            </div>
        )
    }

    render() {
        return (
            <div>
                <div className='modal-body'>
                    <ModalRadio onChange={(event) => { this.onInputChange(event, "interfaceType") }} label="Interface" name="interface" checked={this.state.interfaceType} options={{ "Ethernet": "Eth", "Wireless": "TLS" }} />
                    {this.Content}
                </div>
                <ModalFooter button2Submit={() => this.onSubmit()} />
            </div>
        );
    }
}

export default connect(
    state => ({ receiver: state.receiver, signalr: state.signalr }),
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions }, dispatch)
)(InstallCertificate);
