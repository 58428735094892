import React from 'react';

class OutputBox extends React.Component {
    render() {
        return (     
            <div className="col-md-12">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <div className="row">
                            <button onClick={this.props.onAdd} className="btn btn-secondary output-button center-block">Add</button>
                        </div>
                        <div className="row output-user-box">
                            <button onClick={this.props.onRemove} className="btn btn-secondary output-button center-block">Remove</button>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="modal-label">{this.props.label}</div>
                        <div className="add-user-output-box">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default OutputBox