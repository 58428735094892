import React from 'react'

class MemberSummaryCard extends React.Component {
    render() {
        return (
            <div className="row no-gutters">
                <div className="col-md-3">
                    <div className="card-wrapper">
                        <div className="card-label">
                            Total Members
                        </div>
                        <div className="card card-with-label">
                            <div className="card-body">
                                <h5 className="card-title">1 Members</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card-wrapper">
                        <div className="card-label">
                            System Admin
                        </div>
                        <div className="card card-with-label">
                            <div className="card-body">
                                <h5 className="card-title">0 Members</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card-wrapper">
                        <div className="card-label">
                            SB Admin
                        </div>
                        <div className="card card-with-label">
                            <div className="card-body">
                                <h5 className="card-title">1 Members</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card-wrapper">
                        <div className="card-label">
                            SB Monitor
                        </div>
                        <div className="card card-with-label">
                            <div className="card-body">
                                <h5 className="card-title">1 Members</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MemberSummaryCard