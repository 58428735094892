import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import { Translate } from 'react-localize-redux'
import { actionCreators as modalActions } from '../../../store/Modal'
import { actionCreators as userActions } from '../../../store/User'
import $ from 'jquery';
import '../Members.css'
import 'datatables.net'
import 'datatables.net-bs4'
import 'datatables.net-scroller'
import 'datatables.net-scroller-bs4'
import 'datatables.net-buttons'
import 'datatables.net-buttons-bs4'


class UserTable extends React.Component {
    state = { searchParam: "" }
    userTable = null;
    // scrollPosition = null;
    initializeUsertable = () => {
        var that = this;
        var scrollPosition;
        var userTable = $(this.refs.userTable).DataTable({
            data: that.props.users,
            columnDefs: [
                {
                    "targets": 0,
                    "data": "firstName",
                    "defaultContent": ""
                },
                {
                    "targets": 1,
                    "data": "lastName",
                    "defaultContent": ""

                },
                {
                    "targets": 2,
                    "data": "username",
                    "defaultContent": ""

                },
                {
                    "targets": 3,
                    "data": "email",
                    "defaultContent": ""

                },
                {
                    "targets": 4,
                    "data": "isSystemAdmin",
                    "defaultContent": "CMS User",
                    render: function (data, type, full, meta) {
                        return that.createSelect(data, type, full, meta);
                    }
                },
                {
                    "targets": -1,
                    "data": null,
                    render: function (data, type, full, meta) {
                        return that.createActionButtons(data, type, full, meta);

                    }
                }
            ],
            "info": true,
            "ordering": true,
            "searching": true,
            "scrollY": 'calc(100vh - 340px)',
            "dom": "ti",
            "scroller": true,
            "preDrawCallback": function (settings) {
                scrollPosition = $(".dataTables_scrollBody").scrollTop();
            },
            "drawCallback": function (settings) {
                $(".dataTables_scrollBody").scrollTop(scrollPosition);
            },
        })

        userTable.on('click', 'tr .delete-member-icon', function () {
            var rowIdx = userTable.row($(this).closest('tr')).index();
            var updatingUser = userTable.row(rowIdx).data();
            that.props.createModal("Delete User", {
                "username": updatingUser["username"],
                "token": that.props.token
            })
        });

        userTable.on('click', 'tr .edit-member-icon', function () {
            var rowIdx = userTable.row($(this).closest('tr')).index();
            var updatingUser = userTable.row(rowIdx).data();

            that.props.createModal("Edit User", {
                'token': that.props.token,
                "firstName": updatingUser["firstName"],
                "lastName": updatingUser["lastName"],
                "username": updatingUser["username"],
                "email": updatingUser["email"],
            })
        });


        // handle role change
        userTable.on('change', 'select.role-selection', function () {
            var rowIdx = userTable.row($(this).closest('tr')).index();
            var cell = userTable.cell(rowIdx, 4); // column 4
            var selectedRole = $(this).prop("selected", true).val();
            var updating_user = userTable.row(rowIdx).data();
            selectedRole = (updating_user["isSystemAdmin"] == false || updating_user["isSystemAdmin"] == "false") ? "CMSSystemAdmin" : "NonCMSSystemAdmin";
            that.props.updateUserAdminRole(
                that.props.token,
                updating_user["username"],
                selectedRole,
                () => {
                    that.reloadTable();
                },
                () => {
                    that.reloadTable();
                }
            )
        });
        this.userTable = userTable;
        // $.fn.dataTable.ext.errMode = 'none';
    }

    createSelect = (data, type, full, meta) => {
        if (full.username != this.props.username)
            var sel = "<select class='form-control role-selection'>";
        else
            var sel = "<select class='form-control role-selection' disabled>";
        if (data == "true" || data == true) {
            sel += "<option selected value = '" + "CMSSystemAdmin" + "' >" + "CMS SysAdmin" + "</option>";
            sel += "<option  value = '" + "NonCMSSystemAdmin" + "' >" + "CMS User" + "</option>";
        }
        else {
            sel += "<option  value = '" + "CMSSystemAdmin" + "' >" + "CMS SysAdmin" + "</option>";
            sel += "<option selected value = '" + "NonCMSSystemAdmin" + "' >" + "CMS User" + "</option>";
        }
        sel += "</select>";
        return sel;
    }
    createActionButtons(data, type, full, meta) {
        if (full.username != this.props.username)
            return '<i class="material-icons medium md-dark edit-member-icon"  style={{ "cursor": "pointer" }}>edit</i>'
                + '<i class="material-icons medium md-dark delete-member-icon"  style={{ "cursor": "pointer" }}>delete</i>';
        else {
            return '<i class="material-icons medium md-dark edit-member-icon"  style={{ "cursor": "pointer" }}>edit</i>'
        }
    }
    newMemberOnclick = () => {
        this.props.createModal("Add New Member", {
            'token': this.props.token
        });
    }

    componentDidMount() {
        this.initializeUsertable()
        this.props.getUsers(sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : (this.props.token || localStorage.getItem("jwtoken")), this.reloadTable())
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    componentDidUpdate() {
        this.reloadTable();
    }

    reloadTable = () => {
        var userTable = $(this.refs.userTable).DataTable();
        userTable.clear();
        userTable.rows.add(this.props.users);
        userTable.draw();
    }

    onSearchChange = (event) => {
        this.setState({ searchParam: event.target.value })
        this.userTable.search(event.target.value).draw();
    }

    render() {
        return (
            <div id="user-card-wrapper" className="card-wrapper">
                <div id="user-card" className="card card-with-label">
                    <div className="table-responsive table-wrapper">
                        <div className="user-row row">
                            <div className="col-md-6">
                                <div className="mr-1 dropdown">
                                    <button type="button" className="btn btn-secondary" onClick={this.newMemberOnclick}><Translate id="AddNewMember" /></button>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                                <div className="btn-toolbar">
                                    <div className="btn-group mr-1">
                                        <i className="search-icon material-icons">search</i>
                                        <Translate>
                                            {({ translate }) =>
                                                <input type="text" className="table-search-input form-control" value={this.state.searchParam} placeholder={translate("Search")} onChange={this.onSearchChange}></input>
                                            }
                                        </Translate>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table id="user-table" ref="userTable" className="table">
                            <thead>
                                <tr>
                                    <th><Translate id="FirstName" /></th>
                                    <th><Translate id="LastName" /></th>
                                    <th><Translate id="Username" /></th>
                                    <th><Translate id="Email" /></th>
                                    <th><Translate id="Role" /></th>
                                    <th><Translate id="Action" /></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div >
        )
    }
}

export default connect(
    state => state.user,
    dispatch => bindActionCreators({ ...modalActions, ...userActions }, dispatch)
)(UserTable);
