import React from 'react';
import ModalFileInput from '../ModalInputs/ModalFileInput'

//FOR USE IN SCHEDULER/STANDALONE MODAL

class ResetBody extends React.Component {
    render(){
        return(
            <div>
                <p className="lead">Are you sure you want to reset the selected device(s)?</p>
                <p className="modal-text">
                    The following data <strong>will be preserved</strong> and not restored to defaults.
                    To reset all data to defaults, press and hold the "Reset" button on the receiver.
                </p>
                <ul className="list-unstyled modal-text-small">
                    <li>
                        CMS Server IP, Hostname, FQDN, and CMS Communication Port
                    </li>
                    <li>
                        Receiver Network Setting
                        <ul>
                            <li>
                                IP / Subnet Mask / Gateway
                            </li>
                            <li>
                                DNS Policy (Dynamic or Static) / Primary / Secondary DNS
                            </li>
                            <li>
                                Host Name
                            </li>
                            <li>
                                Wireless Connection Settings
                            </li>
                        </ul>
                    </li>
                    <li>
                        Log Policy
                        <ul>
                            <li>
                                Enable / Disable
                            </li>
                            <li>
                                Upload Location / Interval
                            </li>
                        </ul>
                    </li>
                    <li>
                        Time Zone / Enable Daylight Saving Time Updated
                    </li>
                </ul>
            </div>
        )
    }
}


export default ResetBody
