import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Translate } from 'react-localize-redux';
import React from 'react';
import '../Modal.css';
import englishTranslation from "../../../localization/en.translations.json";

/*
 * Created Date: 06/18/2019
 * Description: - Contain all default settings from all firmware versions
 *              - For each feature, there are 3 types of UI display (in "display" field)
 *                  "0": Show
 *                  "1": Hide
 *                  "2": Disable
 *              - There are some feature depending on other features to show/hide/disable.
 *                To do this, we add
 *                      "dependencies": ["feature name it depends on"]
 *                      "dependencyMap": {
 *                                  "0" (the value of the feature it depends on) : "1" (Hide) 
 *                       }
 *              - onChange: this function is called after setting the state of modified feature.
 *                          It is called when we need to change other feature along with modified feature.
 *                          OR add other feature value of changeMap because firmware needs to have extra information to make it work.
 *                          For example, timzone needs to have dstDaylightSavings along with it to make it work.
*/
function setSettingState(_this, key, subkey, value, cb = null) {
    _this.setState({
        settings: {
            ..._this.state.settings,
            [key]: {
                ..._this.state.settings[key],
                [subkey]: value
            }
        }
    }, cb)
}

function getP2POperatingChannel(chnPlan) {
    let p2pOperatingChannel;
    switch (chnPlan) {
        case "12": //0x0C
            p2pOperatingChannel = {
                "des": "CountryName0x0C",//China, Taiwan
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            };
            break;
        case "17": //0x11
            p2pOperatingChannel = {
                "des": "CountryName0x11", //"United States, Canada", 
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            };
            break;
        case "18": //0x12
            p2pOperatingChannel = {
                "des": "CountryName0x12", //"European Union, EUTA, Hong Kong, Japan"
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "12": "12",
                    "13": "13",
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48"
                }
            };
            break;
        case "127": //0x7F
            p2pOperatingChannel = {
                "des": "CountryName0x7F", //"Australia, New Zealand, Brazil"
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "12": "12",
                    "13": "13",
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            };
            break;
        case "67": //0x43
            p2pOperatingChannel = {
                "des": "CountryName0x43",//"United States, Canada, Taiwan, India, Colombia, Peru"
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            };
            break;
        case "66": //0x42
            p2pOperatingChannel = {
                "des": "CountryName0x42", //"European Union, Japan"
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "12": "12",
                    "13": "13",
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48"
                }
            };
            break;
        case "50": //0x32
            p2pOperatingChannel = {
                "des": "CountryName0x32", //"China"
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "12": "12",
                    "13": "13",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            };
            break;
        case "41": //0x29
            p2pOperatingChannel = {
                "des": "CountryName0x29", //"Australia, New Zealand"
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "12": "12",
                    "13": "13",
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            };
            break;
        default:
            p2pOperatingChannel = {
                "des": "CountryName0x11", //"United States, Canada", 
                "channels": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            };
            break;
    }
    return p2pOperatingChannel;
}

function check_hostname(str) {
    var rex = /^(([A-Za-z0-9][A-Za-z0-9-]{0,61})?[A-Za-z0-9]){1,1}$/i;
    if (rex.test(str))
        return true;
    else
        return false;
}

function check_domain(str) {
    var rex = /^(([A-Za-z0-9][A-Za-z0-9-]{0,61})?[A-Za-z0-9]\.){1,5}[a-zA-Z]{2,63}$/i;
    if (rex.test(str))
        return true;
    else
        return false;
}

function check_ip(ip) {
    var re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
    if (re.test(ip)) {
        if (RegExp.$1.length > 3 || RegExp.$2.length > 3 || RegExp.$3.length > 3 || RegExp.$4.length > 3)
            return false;
        if (RegExp.$1 < 256 && RegExp.$2 < 256 && RegExp.$3 < 256 && RegExp.$4 < 256)
            return true;
    }

    return false;
}

function check_cms_host(hostValue) {
    if (hostValue.length <= 0)
        return "CannotBeEmptyErr";

    if (hostValue == "") {
        return "CannotBeEmptyErr";
    }

    if (hostValue.length > 255) {
        // Cannot exceed 255 characters
        return "CannotExceed255Err";
    }

    if (check_ip(hostValue))
        return "";
    if (check_hostname(hostValue))
        return "";
    if (check_domain(hostValue))
        return "";

    // Invalid IP, hostname or domain name
    return "InvalidIPHostnameDomainErr";
}

function check_cms_port(cmsPortVal) {
    if (cmsPortVal === "") {
        return "CannotBeEmptyErr";
    }

    if (cmsPortVal < 5000 || cmsPortVal > 65535) {
        // "Invalid entry. The value must be between 5000 - 65535."
        return "CmsCommunicationPortErr";
    }
    return "";
}

function check_web_port(webPortVal) {
    if (webPortVal === "") {
        return "CannotBeEmptyErr";
    }

    if ((webPortVal != 443) && (webPortVal < 5000 || webPortVal > 65535)) {
        // "Invalid entry. The value must be between 5000 - 65535."
        return "WebCommunicationPortErr";
    }
    if (webPortVal === "5555") {
        return "WebCommunicationPortErr2";
    }
    return "";
}

class AllSettings {
    constructor() { }

    setChangeMapModifiedPinFea(_this) {
        _this.changeMap["pinEnforceEnable"] = _this.state.settings["pinEnforceEnable"].value;
        if (_this.changeMap["pinEnforceEnable"] === "1") {
            _this.changeMap["pinEachConnEnable"] = _this.state.settings["pinEachConnEnable"].value;
            _this.changeMap["fourDigitPINEn"] = _this.state.settings["fourDigitPINEn"].value;
            _this.changeMap["staticPinEnable"] = _this.state.settings["staticPinEnable"].value;
            if (_this.state.settings["staticPinEnable"].value === "1") {
                _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value;
            }
        }
    }

    /**
     * Only for sb960 9.15/9.6
     * @param {any} _this
     * @param {any} onChangeIPAssignment
     */
    setEthernetTCPIPSettings(_this, onChangeIPAssignment) {
        if (_this.state.settings["ethDhcpEnable"].value === "0") {
            _this.changeMap["ethDhcpEnable"] = _this.state.settings["ethDhcpEnable"].value;
            _this.changeMap["ethIpaddr"] = _this.state.settings["ethIpaddr"].value;
            _this.changeMap["ethSubnet"] = _this.state.settings["ethSubnet"].value;
            _this.changeMap["defaultGateway"] = _this.state.settings["defaultGateway"].value;
            if (onChangeIPAssignment) {
                _this.changeMap["DNSPolicy"] = "0";
                _this.changeMap["primaryDNS"] = _this.state.settings["primaryDNS"].value;
                _this.changeMap["secondDNS"] = _this.state.settings["secondDNS"].value;

                _this.setState({
                    settings: {
                        ..._this.state.settings,
                        "DNSPolicy": {
                            ..._this.state.settings["DNSPolicy"],
                            value: "0"
                        }
                    }
                });
            }
        }
    }

    /**
     * Only for sb960 9.15/9.6
     * @param {any} _this
     */
    setEthernetDNSAssignmentSettings(_this) {
        if (_this.state.settings["DNSPolicy"].value === "0") {
            _this.changeMap["DNSPolicy"] = _this.state.settings["DNSPolicy"].value;
            _this.changeMap["primaryDNS"] = _this.state.settings["primaryDNS"].value;
            _this.changeMap["secondDNS"] = _this.state.settings["secondDNS"].value;
        }
    }

    setWirelessInterfaceConnSettings(_this) {
        _this.changeMap["CurConnectApSecType"] = _this.state.settings["CurConnectApSecType"].value;
        switch (_this.changeMap["CurConnectApSecType"]) {
            case "0":
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                }
                break;
            case "5":
            case "6":
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                    _this.changeMap["CurConnectApRadiusId"] = _this.state.settings["CurConnectApRadiusId"].value;
                    _this.changeMap["CurConnectApPwd"] = _this.state.settings["CurConnectApPwd"].value;
                }
                break;
            case "1":
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                    _this.changeMap["CurConnectApPwd"] = _this.state.settings["CurConnectApPwd"].value;
                }
                break;
            default:
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                    _this.changeMap["CurConnectApPwd"] = _this.state.settings["CurConnectApPwd"].value;
                }
                break;
        }
    }

    setDigitalSignageModeSettings(_this) {
        _this.changeMap["digitalSignEn"] = _this.state.settings["digitalSignEn"].value;
        _this.changeMap["digitalSignFullScreenEn"] = _this.state.settings["digitalSignFullScreenEn"].value;
        _this.changeMap["digitalSignUrl"] = _this.state.settings["digitalSignUrl"].value;
        _this.changeMap["digitalSignDelaySec"] = _this.state.settings["digitalSignDelaySec"].value;
        _this.changeMap["digitalSignAudioVol"] = _this.state.settings["digitalSignAudioVol"].value;
    }

    ChangeLMIInterfaceAllowedList(_this, _settings, onStartType, _data) {
        /* 0: display all; 1:Remove Local WiFi AP; 2:Remove MiracastP2P; 3: Remove Remove Local WiFi AP + MiracastP2P*/
        var optionsType = 0;
        var removeLocalWiFiAP = false;
        var removeMiracastP2P = false;
        var WiFiOperateModeVal;
        var ApBsS1EnableVal;
        var miracastConnEnableVal;
        if (_data && onStartType) {
            WiFiOperateModeVal = _data["WiFiOperateMode"];
            ApBsS1EnableVal = _data["ApBsS1Enable"];
            miracastConnEnableVal = _data["miracastConnEnable"];
        } else {
            WiFiOperateModeVal = _settings["WiFiOperateMode"].value;
            ApBsS1EnableVal = _settings["ApBsS1Enable"].value;
            miracastConnEnableVal = _settings["miracastConnEnable"].value;
        }
        if ((_settings["networkInterfaceAllowed"].value != "3")
            && (WiFiOperateModeVal === "1" || ApBsS1EnableVal === "0")) {
            removeLocalWiFiAP = true;
        }
        if ((_settings["networkInterfaceAllowed"].value != "4")
            && (miracastConnEnableVal === "0")) {
            removeMiracastP2P = true;
        }
        if (removeLocalWiFiAP && removeMiracastP2P) {
            optionsType = 3;
        } else if (removeLocalWiFiAP) {
            optionsType = 1;
        } else if (removeMiracastP2P) {
            optionsType = 2;
        }
        var optionsTemp = "";
        switch (optionsType) {
            case 0:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2",
                        "LocalWi-FiAP": "3",
                        "MiracastP2P": "4"
                    };
                }
                break;
            case 1:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2",
                        "MiracastP2P": "4"
                    };
                }
                break;
            case 2:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2",
                        "LocalWi-FiAP": "3"
                    };
                }
                break;
            case 3:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2"
                    };
                }
                break;
            default:
                break;
        }
        if (onStartType) {
            _settings["networkInterfaceAllowed"].options = optionsTemp;
        } else {
            setSettingState(_this, "networkInterfaceAllowed", "options", optionsTemp);
        }
    }
    handleWarningClickedNo(_this, key) {
        setSettingState(_this, key, "value", "1");
        _this.changeMap[key] = "1";
    }
    popupConfirmAlertDialog(/*_this, key,*/displayMsg, WarningOnCancel, WarningOnProceed) {
        const addDialog = ({ onClose }) => {
            let handleClickedNo = () => {
                //setSettingState(_this, key, "value", "1");
                //_this.changeMap[key] = "1";
                if (WarningOnCancel) {
                    WarningOnCancel();
                }
                onClose();
            }
            const handleClickedYes = () => {
                if (WarningOnProceed) {
                    WarningOnProceed();
                }
                onClose()
            }
            //handleClickedNo = handleClickedNo.bind(_this, key);
            const customStyles = {
                content: {
                    top: '30px',
                    left: '30px',
                    right: '30px',
                    bottom: '30px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            }
            return (
                <div>
                    <div className='add-dialog light-mode ConfirmAlertOverlay' styles={customStyles}>
                        <div className="modal-header">
                            <h5 className="modal-title">{englishTranslation["Warning"]}</h5>
                            <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClickedNo}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            {<div>
                                {englishTranslation[displayMsg]}
                            </div>
                            }
                        </div>
                        {
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" onClick={handleClickedNo}>{englishTranslation["Cancel"]}</button>
                                <button type="button" className="btn btn-primary" onClick={handleClickedYes}>{englishTranslation["Proceed"]}</button>
                            </div>
                        }
                    </div>
                </div>
            )
        };
        confirmAlert({ customUI: addDialog });
    }

    getSettingsIn1100() {
        let Settings_11_0 = {
            "SingleRxSettings": {
                "TabHeader": [
                    "DeviceConfiguration",
                    "Features",
                    "DigitalSignage",
                    "NetworkSettings",
                    "LocalWifi",
                    "ManagementAccess",
                    "AdvancedSettings"
                ],
                "TabHeaderCCMode": [
                    "DeviceConfiguration",
                    "Features",
                    "NetworkSettings",
                    "ManagementAccess",
                    "AdvancedSettings"
                ],
                "TabContent": {
                    "DeviceConfiguration": [
                        "RxName", // Device Name
                        "manufacName", // Manufacture Name
                        "mdNameShort", // Model Name Short
                        "mdNameLong", // Model Name
                        "hwVersion", // Hardware Name
                        "fwVersion", // Firmware Version
                        "webUserName", // Administrator Name
                        "webPassword", // Administrator Password
                        "language", // Language
                        "RXHostname", // Host Name
                        "timezoneItem" // Time zone
                    ],
                    "Features": [
                        "WirelessDisplayModeTitle", // Wireless Display Mode Title
                        "WiFiOperateMode", // Wireless Display Mode
                        "WirelessDisplayOverLANTitle", // Wireless Display Over LAN
                        "infracastEnable", // Windows 10
                        "MacInfracastEn", // Windows 7 or Mac
                        "airPlayEn", // macOS or iOS
                        "AirplayMirroringOnlyEn", //iOS push mode
                        "GoogleCastEnable", //ChromeOS/Chrome native screen mirroring
                        "P2PWirelessSettingTitle", // P2P Wireless Setting Title
                        "miracastConnEnable", //Miracast Connection
                        "autoGoChannel", // Need to do P2P Operating Channel -- Need to create a new component
                        "txPowerItem", // Transmit Power
                        "SecuritySettingTitle", // Security Setting Title
                        "pinEnforceEnable", // Force PIN Pairing on First Connection
                        "pinEachConnEnable", // Require PIN on
                        "fourDigitPINEn", // PIN Length
                        "staticPinEnable", // PIN Generation Method
                        "staticPinVal", // Static PIN Val
                        "PINDisplayPeriod", // PIN Display Period
                        "DisplaySettingTitle", // Display Setting Title
                        "hijackingEn", //Display Sharing Mode
                        "showOverlayRxNameOpt", //Show Receiver Name
                        "overlayRxNamePlacement", //Receiver Name Placement
                        "showMoreInfoEnable", // ScreenBeam Network Info
                        "hideReadyInfoEnable", // Connection Instruction
                        "showConnectWiFiEnable", //Show "Connect to WiFi" instructions
                        "hdmiPortPowerManagement", // HDMI/VGA component
                        "scrSaverSecond", // Wait - Triggered when HDMI/VGA selects Screensaver or Display Off
                        "wakedByScan", // Wake up
                        "newOverScanVal", // Adjust TV Screen Size
                        "rtOverscanEnable", // Allow source device to override overscan value
                        "CECEnable", // HDMI-CEC
                        "UIBCEnable", // UIBC
                        "usbConfigEn", // USB Auto Configure
                        "fwupdateAccess", // Firmware Upgrade via USB Autorun
                        "InactivityRebootTime",
                        "RxCsvLogEn" // Receiver Logging
                    ],
                    "DigitalSignage": [
                        "DigitalSignageModeTitle",
                        "DigitalSignageModeSubTitle",
                        "digitalSignEn", //Digital Signage
                        "digitalSignFullScreenEn", //Display Mode
                        "digitalSignUrl", //Source URL
                        "digitalSignDelaySec", //Restart Delay(seconds)
                        "digitalSignAudioVol" //Digital Signage Audio Volume
                    ],
                    "NetworkSettings": [
                        "LocalWifiNetworkTitle",
                        "NetworkMode", // Network Mode
                        "BlockLanIpEnable", //Block communication to devices on the LAN
                        "InterfaceFeatureTitle",
                        "cmsInterface",
                        "apBridgeInterface",
                        "NetworkInterfaceTitle",
                        "EthernetInterfaceSubtitle",
                        "EthNetworkName", // Network Name
                        "ConnectionSettingsSubtitle",
                        "CurEthAuthentication",
                        "CurEthRadiusID",
                        "CurEthRadiusPWD",
                        "EthCertDateTime",
                        "EthRootCertURL",
                        "EthUserCertURL",
                        "EthUserKeyURL",
                        "EthCertsValidity",
                        "TCPIPSettingsSubtitle",
                        "ethAutoIpEnable",
                        "ethIpAddress",
                        "ethNetmask",
                        "ethGateway",
                        "ethAutoDnsEnable",
                        "ethPrimaryDns",
                        "ethSecondaryDns",
                        "WirelessInterfaceSubtitle",
                        "ConnectionSettingsSubtitle",
                        "CurConnectApName",
                        "CurConnectApSecType",
                        "CurConnectApRadiusId",
                        "CurConnectApPwd",
                        "TLSCertDateTime",
                        "TLSRootCertURL",
                        "TLSUserCertURL",
                        "TLSUserKeyURL",
                        "staAutoIpEnable",
                        "staIpAddress",
                        "staNetmask",
                        "staGateway",
                        "staAutoDnsEnable",
                        "staPrimaryDns",
                        "staSecondaryDns"
                    ],
                    "LocalWifi": [
                        "LocalWifiSettingsTitle",
                        "ApBsS1Enable",
                        "ApBsS1Name",
                        "ApBsS1SecurityType",
                        "ApBsS1Passwd",
                        "ApBsChannelNum"
                    ],
                    "ManagementAccess": [
                        "CentralMgtSystemTitle",
                        "mgtSrvFullAddr",
                        "mgtSrvPort",
                        "webServerPort",
                        "LocalMgtWirelessAccessTitle",
                        "webMgtOverWiFiEn",
                        "networkInterfaceAllowed"
                    ],
                    "AdvancedSettings": [
                        //"hijackingEn", //Move to Feature tab
                        "SecuritySettingTitle", //Security Setting
                        "ApBsDisableEapolKeyRetries",
                        "AdvancedFeatureTitle",//Advanced Feature
                        "dynAgoPassphraseEn",
                        "DisplaySettingTitle",
                        "PCRSyncEnable",
                        "HDCPEncrypMode",
                        "aspectRatio",
                        "scrHoffset",
                        "debugViewEnable",
                        "P2PWirelessSettingTitle",
                        "p2pIpAddr",
                        "p2pSubnet",
                        "p2pDhcpIpStart",
                        "p2pDhcpIpEnd",
                        "p2pSsid",
                        "p2pPassword",
                        "fastIPEn",
                        "txAgoBcnEnable"
                    ]
                }
            },
            "MultipleRxSettings": {
                "DeviceConfigurationGroupTitle": {
                    "type": "title",
                    "title": "DeviceConfiguration"
                },
                "LocalWebManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Web Management",
                    "children": [
                        "webUserName", // Administrator Name
                        "webPassword"  // Administrator Password
                    ]
                },
                "Language": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Language",
                    "children": [
                        "language" // Language
                    ]
                },
                "Timezone": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Time zone",
                    "children": [
                        "timezoneItem" // Time zone
                    ]
                },
                "FeaturesGroupTitle": {
                    "type": "title",
                    "title": "Features"
                },
                "WirelessDisplayMode": {
                    "type": "checkbox",
                    "selected": "0", 
                    "label": "Wireless display mode to support client devices",
                    "children": [
                        "WiFiOperateMode"
                    ]
                },
                "Infracast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast Over Network",
                    "children": [
                        "infracastEnable"
                    ]
                },
                "MacOSandWin7": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Project over infrastructure for macOS/Win7",
                    "children": [
                        "MacInfracastEn"
                    ]
                },
                "MacAirplay": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Mac Airplay",
                    "children": [
                        "airPlayEn"
                    ]
                },
                "iOSPushMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "iOS push mode",
                    "children": [
                        "AirplayMirroringOnlyEn"
                    ]
                },
                "GoogleCastMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Google Cast",
                    "children": [
                        "GoogleCastEnable"
                    ]
                },
                "MiracastConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast Connection",
                    "children": [
                        "miracastConnEnable"
                    ]
                },
                "OperatingChannel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Operating Channel",
                    "children": [
                        "autoGoChannel"
                    ]
                },
                "TransmitPower": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Transmit Power",
                    "children": [
                        "txPowerItem"
                    ]
                },
                "PIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PIN",
                    "children": [
                        "pinEnforceEnable",
                        "pinEachConnEnable",
                        "fourDigitPINEn",
                        "staticPinEnable",
                        "staticPinVal"
                    ]
                },
                "PinDisplayPeriod": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Pin Display Period",
                    "children": [
                        "PINDisplayPeriod"
                    ]
                },
                "NetworkInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Information",
                    "children": [
                        "showMoreInfoEnable"
                    ]
                },
                "ConnectInstruction": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Connect Instruction",
                    "children": [
                        "hideReadyInfoEnable"
                    ]
                },
                //Show "Connect to WiFi" instructions
                "ConnectToWiFiInstruction": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Show \"Connect to WiFi\" instructions",
                    "children": [
                        "showConnectWiFiEnable"
                    ]
                },
                "DisplaySharingMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Display Sharing Mode",
                    "children": [
                        "hijackingEn",
                        "showOverlayRxNameOpt",
                        "overlayRxNamePlacement"
                    ]
                },
                // "AllowForceDisconnection": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Allow Force Disconnection",
                //     "children": [
                //         "hijackingEn"
                //     ]
                // },
                // "ShowReceiverName": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Show Receiver Name",
                //     "children": [
                //         "showOverlayRxNameOpt"
                //     ]
                // },
                // "ShowReceiverNamePlacement": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Receiver Name Placement",
                //     "children": [
                //         "overlayRxNamePlacement"
                //     ]
                // },
                "HdmiPortPowerManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI/VGA Port Power Management",
                    "children": [
                        "hdmiPortPowerManagement",
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ],
                    "dataToSend": [
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ]
                },
                "Wakeup": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wake Up",
                    "children": [
                        "wakedByScan"
                    ]
                },
                "TVScreenSize": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Screen Size",
                    "children": [
                        "newOverScanVal",
                        "rtOverscanEnable"
                    ]
                },
                "HDMICEC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI-CEC",
                    "children": [
                        "CECEnable"
                    ]
                },
                "UIBC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "UIBC",
                    "children": [
                        "UIBCEnable"
                    ]
                },
                "USBAutoConfig": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "USB Auto Configure",
                    "children": [
                        "usbConfigEn"
                    ]
                },
                "FirmwareUpgrade": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Firmware Upgrade via USB Autorun",
                    "children": [
                        "fwupdateAccess"
                    ]
                },
                "InactivityRebootTime": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Inactivity Reboot Time",
                    "children": [
                        "InactivityRebootTime"
                    ]
                },
                "ReceiverLogging": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Receiver Logging",
                    "children": [
                        "RxCsvLogEn"
                    ]
                },
                "DigitalSignage": {
                    "type": "title",
                    "title": "DigitalSignage"
                },
                "DigitalSignageMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Digital Signage Mode",
                    "children": [
                        "DigitalSignageModeSubTitle",
                        "digitalSignEn", //Digital Signage
                        "digitalSignFullScreenEn", //Display Mode
                        "digitalSignUrl", //Source URL
                        "digitalSignDelaySec", //Restart Delay(seconds)
                        "digitalSignAudioVol" //Digital Signage Audio Volume
                    ]
                },
                "NetworkSettingsGroupTitle": {
                    "type": "title",
                    "title": "NetworkSettings"
                },
                "LocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Network",
                    "children": [
                        "NetworkMode"
                    ]
                },
                "BlockcommunicationontheLAN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Block communication on the LAN",
                    "children": [
                        "BlockLanIpEnable"
                    ]
                },
                "InterfaceFeature": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Interface Feature Assignment",
                    "children": [
                        "apBridgeInterface"
                    ]
                },
                "NetworkName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name",
                    "children": [
                        "EthNetworkName"
                    ]
                },
                /*"EthernetTCPIPSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Ethernet TCP/IP Settings",
                    "children": [
                        "ethAutoIpEnable",
                        "ethIpAddress",
                        "ethNetmask",
                        "ethGateway",
                        "ethAutoDnsEnable",
                        "ethPrimaryDns",
                        "ethSecondaryDns"
                    ]
                },
                "WirelessTCPIPSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless TCP/IP Settings",
                    "children": [
                        "staAutoIpEnable",
                        "staIpAddress",
                        "staNetmask",
                        "staGateway",
                        "staAutoDnsEnable",
                        "staPrimaryDns",
                        "staSecondaryDns"
                    ]
                },*/


                "WiredConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wired Connection Settings",
                    "children": [
                        "CurEthAuthentication",
                        "CurEthRadiusID",
                        "CurEthRadiusPWD",
                        "EthCertDateTime",
                        "EthRootCertURL",
                        "EthUserCertURL",
                        "EthUserKeyURL"
                    ]
                },
                "WirelessConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless Connection Settings",
                    "children": [
                        "CurConnectApName",
                        "CurConnectApSecType",
                        "CurConnectApRadiusId",
                        "CurConnectApPwd",
                        "TLSCertDateTime",
                        "TLSRootCertURL",
                        "TLSUserCertURL",
                        "TLSUserKeyURL"
                    ]
                },
                "LocalWifiGroupTitle": {
                    "type": "title",
                    "title": "LocalWifi"
                },
                "SbLocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Settings",
                    "children": [
                        "ApBsSSEnable",
                        "ApBsSSIDIndex"
                        //"ApBsS1Name",
                        //"ApBsS1SecurityType",
                        //"ApBsS1Passwd"
                    ]
                    // "dataToSend": [
                    //     "ApBsSSEnable",
                    //     "ApBsSSIDIndex"
                    //     // "ApBsSSSecurityType",
                    //     //"ApBsSSName",
                    //     //"ApBsSSPasswd"
                    // ]
                },
                /*"CentralManagementSystemSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Central Management System Settings",
                    "children": [
                        "mgtSrvFullAddr",
                        "mgtSrvPort"
                    ]
                },*/
                "ManagementAccessGroupTitle": {
                    "type": "title",
                    "title": "ManagementAccess"
                },
                "WebPort": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "WEB Communication Port",
                    "children": [
                        "webServerPort"

                    ]
                },
                "LocalMgtAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Management Access Settings",
                    "children": [
                        "webMgtOverWiFiEn"
                    ]
                },
                "NetworkInterfaceAllowed": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Interface Allowed",
                    "children": [
                        "networkInterfaceAllowed"
                    ]
                },
                // Advanced Settings
                "AdvancedSettingsGroupTitle": {
                    "type": "title",
                    "title": "AdvancedSettings"
                },
                "DebugView": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Debug View",
                    "children": [
                        "debugViewEnable"
                    ]
                },
                "DisableEapolKeyRetries": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": " Disable EAPOL Key Retries (for WPA2-PSK)",
                    "children": [
                        "ApBsDisableEapolKeyRetries"
                    ]
                },
                "PCRSync": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PCR Synchronization",
                    "children": [
                        "PCRSyncEnable"
                    ]
                },
                "HdcpEncryption": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDCP Encryption",
                    "children": [
                        "HDCPEncrypMode"
                    ]
                },
                "AspectRatio": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Aspect Ratio",
                    "children": [
                        "aspectRatio"
                    ]
                },
                "HorizontalVerticalOffset": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Horizontal/Vertical Offset",
                    "children": [
                        "scrHoffset",
                        "scrVoffset"]
                },
                "FastIP": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Fast-IP",
                    "children": [
                        "fastIPEn"
                    ]
                },
                "TxAgoBcnEnable": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "AGO beacon broadcast",
                    "children": [
                        "txAgoBcnEnable"
                    ]
                }

            },
            "MultipleRxSettingsForUSB": {
                "DeviceConfigurationGroupTitle": {
                    "type": "title",
                    "title": "DeviceConfiguration"
                },
                "DeviceName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Device Name",
                    "children": [
                        "RxName" // Device Name
                    ]
                },
                "LocalWebManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Web Management",
                    "children": [
                        "webUserName", // Administrator Name
                        "webPassword"  // Administrator Password
                    ]
                },
                "Language": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Language",
                    "children": [
                        "language" // Language
                    ]
                },
                "HostName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Host Name",
                    "children": [
                        "RXHostname" // Host Name
                    ]
                },
                "Timezone": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Time zone",
                    "children": [
                        "timezoneItem" // Time zone
                    ]
                },
                "FeaturesGroupTitle": {
                    "type": "title",
                    "title": "Features"
                },
                "WirelessDisplayMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless display mode to support client devices",
                    "children": [
                        "WiFiOperateMode"
                    ]
                },
                "Infracast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast Over Network",
                    "children": [
                        "infracastEnable"
                    ]
                },
                "MacOSandWin7": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Project over infrastructure for macOS/Win7",
                    "children": [
                        "MacInfracastEn"
                    ]
                },
                "MacAirplay": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Mac Airplay",
                    "children": [
                        "airPlayEn"
                    ]
                },
                "iOSPushMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "iOS push mode",
                    "children": [
                        "AirplayMirroringOnlyEn"
                    ]
                },
                "GoogleCastMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Google Cast",
                    "children": [
                        "GoogleCastEnable"
                    ]
                },
                "MiracastConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast Connection",
                    "children": [
                        "miracastConnEnable"
                    ]
                },
                "OperatingChannel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Operating Channel",
                    "children": [
                        "autoGoChannel"
                    ]
                },
                "TransmitPower": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Transmit Power",
                    "children": [
                        "txPowerItem"
                    ]
                },
                "PIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PIN",
                    "children": [
                        "pinEnforceEnable",
                        "pinEachConnEnable",
                        "fourDigitPINEn",
                        "staticPinEnable",
                        "staticPinVal"
                    ]
                },
                "PinDisplayPeriod": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Pin Display Period",
                    "children": [
                        "PINDisplayPeriod"
                    ]
                },
                "NetworkInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Information",
                    "children": [
                        "showMoreInfoEnable"
                    ]
                },
                "ConnectInstruction": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Connect Instruction",
                    "children": [
                        "hideReadyInfoEnable"
                    ]
                },
                //Show "Connect to WiFi" instructions
                "ConnectToWiFiInstruction": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Show \"Connect to WiFi\" instructions",
                    "children": [
                        "showConnectWiFiEnable"
                    ]
                },
                "DisplaySharingMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Display Sharing Mode",
                    "children": [
                        "hijackingEn",
                        "showOverlayRxNameOpt",
                        "overlayRxNamePlacement"
                    ]
                },
                // "AllowForceDisconnection": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Allow Force Disconnection",
                //     "children": [
                //         "hijackingEn"
                //     ]
                // },
                // "ShowReceiverName": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Show Receiver Name",
                //     "children": [
                //         "showOverlayRxNameOpt"
                //     ]
                // },
                // "ShowReceiverNamePlacement": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Receiver Name Placement",
                //     "children": [
                //         "overlayRxNamePlacement"
                //     ]
                // },
                "HdmiPortPowerManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI/VGA Port Power Management",
                    "children": [
                        "hdmiPortPowerManagement",
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ],
                    "dataToSend": [
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ]
                },
                "Wakeup": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wake Up",
                    "children": [
                        "wakedByScan"
                    ]
                },
                "TVScreenSize": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Screen Size",
                    "children": [
                        "newOverScanVal",
                        "rtOverscanEnable"
                    ]
                },
                "HDMICEC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI-CEC",
                    "children": [
                        "CECEnable"
                    ]
                },
                "UIBC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "UIBC",
                    "children": [
                        "UIBCEnable"
                    ]
                },
                "USBAutoConfig": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "USB Auto Configure",
                    "children": [
                        "usbConfigEn"
                    ]
                },
                "FirmwareUpgrade": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Firmware Upgrade via USB Autorun",
                    "children": [
                        "fwupdateAccess"
                    ]
                },
                "InactivityRebootTime": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Inactivity Reboot Time",
                    "children": [
                        "InactivityRebootTime"
                    ]
                },
                "ReceiverLogging": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Receiver Logging",
                    "children": [
                        "RxCsvLogEn"
                    ]
                },
                "DigitalSignageGroupTitle": {
                    "type": "title",
                    "title": "DigitalSignage"
                },
                "DigitalSignageMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Digital Signage Mode",
                    "children": [
                        "DigitalSignageModeSubTitle",
                        "digitalSignEn", //Digital Signage
                        "digitalSignFullScreenEn", //Display Mode
                        "digitalSignUrl", //Source URL
                        "digitalSignDelaySec", //Restart Delay(seconds)
                        "digitalSignAudioVol" //Digital Signage Audio Volume
                    ]
                },
                "LocalWifiGroupTitle": {
                    "type": "title",
                    "title": "LocalWifi"
                },
                "LocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Network",
                    "children": [
                        "NetworkMode",
                    ]
                },
                "ManagementAccessGroupTitle": {
                    "type": "title",
                    "title": "ManagementAccess"
                },
                "BlockcommunicationontheLAN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Block communication on the LAN",
                    "children": [
                        "BlockLanIpEnable"
                    ]
                },
                "InterfaceFeature": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Interface Feature Assignment",
                    "children": [
                        "apBridgeInterface"
                    ]
                },
                "NetworkName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name",
                    "children": [
                        "EthNetworkName"
                    ]
                },
                "EthernetTCPIPSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Ethernet TCP/IP Settings",
                    "children": [
                        "ethAutoIpEnable",
                        "ethIpAddress",
                        "ethNetmask",
                        "ethGateway",
                        "ethAutoDnsEnable",
                        "ethPrimaryDns",
                        "ethSecondaryDns"
                    ]
                },
                "WirelessTCPIPSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless TCP/IP Settings",
                    "children": [
                        "staAutoIpEnable",
                        "staIpAddress",
                        "staNetmask",
                        "staGateway",
                        "staAutoDnsEnable",
                        "staPrimaryDns",
                        "staSecondaryDns"
                    ]
                },
                "SbLocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Settings",
                    "children": [
                        "ApBsS1Enable",
                        "ApBsS1Name",
                        "ApBsS1SecurityType",
                        "ApBsS1Passwd"
                    ],
                    "dataToSend": [
                        "ApBsSSEnable",
                        "ApBsSSIDIndex",
                        "ApBsSSSecurityType",
                        "ApBsSSName",
                        "ApBsSSPasswd"
                    ]
                },
                "WiredConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wired Connection Settings",
                    "children": [
                        "CurEthAuthentication",
                        "CurEthRadiusID",
                        "CurEthRadiusPWD",
                        "EthCertDateTime",
                        "EthRootCertURL",
                        "EthUserCertURL",
                        "EthUserKeyURL"
                    ]
                },
                "WirelessConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless Connection Settings",
                    "children": [
                        "CurConnectApName",
                        "CurConnectApSecType",
                        "CurConnectApRadiusId",
                        "CurConnectApPwd",
                        "TLSCertDateTime",
                        "TLSRootCertURL",
                        "TLSUserCertURL",
                        "TLSUserKeyURL"
                    ]
                },
                "CentralManagementSystemSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Central Management System Settings",
                    "children": [
                        "mgtSrvFullAddr",
                        "mgtSrvPort"
                    ]
                },
                "WebPort": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "WEB Communication Port",
                    "children": [
                        "webServerPort"

                    ]
                },
                "LocalMgtAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Management Access Settings",
                    "children": [
                        "webMgtOverWiFiEn"
                    ]
                },
                "NetworkInterfaceAllowed": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Interface Allowed",
                    "children": [
                        "networkInterfaceAllowed"
                    ]
                },
                // Advanced Settings
                "AdvancedSettingsGroupTitle": {
                    "type": "title",
                    "title": "AdvancedSettings"
                },
                "DebugView": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Debug View",
                    "children": [
                        "debugViewEnable"
                    ]
                },
                "DisableEapolKeyRetries": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": " Disable EAPOL Key Retries (for WPA2-PSK)",
                    "children": [
                        "ApBsDisableEapolKeyRetries"
                    ]
                },
                "PCRSync": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PCR Synchronization",
                    "children": [
                        "PCRSyncEnable"
                    ]
                },
                "HdcpEncryption": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDCP Encryption",
                    "children": [
                        "HDCPEncrypMode"
                    ]
                },
                "AspectRatio": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Aspect Ratio",
                    "children": [
                        "aspectRatio"
                    ]
                },
                "HorizontalVerticalOffset": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Horizontal/Vertical Offset",
                    "children": [
                        "scrHoffset",
                        "scrVoffset"]
                },
                "FastIP": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Fast-IP",
                    "children": [
                        "fastIPEn"
                    ]
                },
                "TxAgoBcnEnable": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "AGO beacon broadcast",
                    "children": [
                        "txAgoBcnEnable"
                    ]
                }

            },
            "AllSettings": {
                /* Device Name Access - RADIO BUTTON */
                "RxNameAccess": {
                    "type": "radioButton",
                    "label": "DeviceNameAccess",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Device Name - INPUT TEXT */
                "RxName": {
                    "type": "input",
                    "value": "",
                    "label": "DeviceName",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (value.length <= 32) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value[0] === " " || value[value.length - 1] === " ") {
                            return "EmptyDeviceNameError";
                        }
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/]+$/.test(value)) {
                            return "InvalidDeviceNameError";
                        }
                    }
                },
                /* Manufacture Name - INPUT TEXT */
                "manufacName": {
                    "type": "input",
                    "value": "",
                    "label": "ManufactureName",
                    "info": "",
                    "display": "2"
                },
                /* Model Name - INPUT TEXT */
                "mdNameLong": {
                    "type": "input",
                    "value": "",
                    "label": "ModelName",
                    "info": "",
                    "display": "2"
                },
                /* Model Name Short - INPUT TEXT */
                "mdNameShort": {
                    "type": "input",
                    "value": "",
                    "label": "ModelNameShort",
                    "info": "",
                    "display": "2"
                },
                /* Hardware Version - INPUT TEXT */
                "hwVersion": {
                    "type": "input",
                    "value": "",
                    "label": "HardwareVersion",
                    "info": "",
                    "display": "2"
                },
                /* Firmware Version - INPUT TEXT */
                "fwVersion": {
                    "type": "input",
                    "value": "",
                    "label": "FirmwareVersion",
                    "info": "",
                    "display": "2"
                },
                /* Admin Username - INPUT TEXT */
                "webUserName": {
                    "type": "input",
                    "value": "Administrator",
                    "label": "AdminUsername",
                    "info": "",
                    "display": "0",
                    "checkbox": "LocalWebManagement",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyUsernameError";
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    }
                },
                /* Admin Password - INPUT PASSWORD*/
                "webPassword": {
                    "type": "password",
                    "value": "",
                    "label": "AdminPassword",
                    "info": "",
                    "display": "0",
                    "checkbox": "LocalWebManagement",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyPasswordError";
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidPasswordError";
                        }
                    }
                },
                /* Language - DROPDOWN */
                "language": {
                    "type": "dropdown",
                    "checkbox": "Language",
                    "value": "en",
                    "label": "Language",
                    "info": "",
                    "display": "0",
                    "options": {
                        "English": "en",
                        "简体中文": "zhcs",
                        "正體中文": "zhct",
                        "日本語": "ja",
                        "Français": "fr",
                        "Deutsch": "ge",
                        "Nederlands": "du",
                        "한국어": "ko",
                        "Español": "sp",
                        "Italiano": "it",
                        "Русский": "ru",
                    }
                },
                /* Host Name - INPUT TEXT */
                "RXHostname": {
                    "type": "input",
                    "value": "",
                    "label": "HostName",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (value.length <= 63) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyHostNameError";
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidHostName";
                        }
                        if (value[0] === "-" || value[value.length - 1] === "-") {
                            return "InvalidHostName1";
                        }
                    }
                },
                /* Time zone - DROPDOWN */
                "timezoneItem": {
                    "type": "dropdown",
                    "checkbox": "Timezone",
                    "value": "5",
                    "label": "Timezone",
                    "info": "",
                    "display": "0",
                    "options": {
                        "(UTC - 11:00) American Samoas": "0",
                        "(UTC - 10:00) Honolulu": "1",
                        "(UTC - 09:30) French Polynesia": "2",
                        "(UTC - 09:00) Anchorage": "3",
                        "(UTC - 08:00) Pacific Time": "4",
                        "(UTC - 07:00) Mountain Time": "5",
                        "(UTC - 06:00) Central Time": "6",
                        "(UTC - 05:00) Eastern Time": "7",
                        "(UTC - 04:30) Caracas": "8",
                        "(UTC - 04:00) Atlantic Time": "9",
                        "(UTC - 03:30) Newfoundland": "10",
                        "(UTC - 03:00) Buenos Aires": "11",
                        "(UTC - 02:00) South Sandwish Island": "12",
                        "(UTC - 01:00) Azores Islands": "13",
                        "(UTC + 00:00) Universal Time": "14",
                        "(UTC + 00:00) Greenwich": "15",
                        "(UTC + 01:00) Amsterdam": "16",
                        "(UTC + 02:00) Cairo": "17",
                        "(UTC + 03:00) Moscow": "18",
                        "(UTC + 03:30) Tehran": "19",
                        "(UTC + 04:00) Dubai": "20",
                        "(UTC + 04:30) Kabul": "21",
                        "(UTC + 05:00) Islamabad": "22",
                        "(UTC + 05:30) New Delhi": "23",
                        "(UTC + 05:45) Katmandu": "24",
                        "(UTC + 06:00) Dhaka": "25",
                        "(UTC + 06:30) Rangoon": "26",
                        "(UTC + 08:00) Beijing": "28",
                        "(UTC + 09:00) Tokyo": "29",
                        "(UTC + 09:30) Adelaide": "30",
                        "(UTC + 10:00) Sydney": "31",
                        "(UTC + 11:00) Solomon Island": "32",
                        "(UTC + 12:00) Auckland": "33",
                        "(UTC + 13:00) Samoa": "34",
                        "(UTC + 14:00) Kiritimati Island": "35"
                    }
                },
                /* Daylight - CHECKBOX */
                "dstTimeEnable": {
                    "type": "checkbox",
                    "value": "",
                    "label": "DaylightSavings",
                    "info": "",
                    "display": "0"
                },
                /* Wireless Display Mode Title - TITLE */
                "WirelessDisplayModeTitle": {
                    "type": "title",
                    "title": "WirelessDisplayModeTitle",
                    "display": "0"
                },
                /* Wireless Display Mode - DROPDOWN*/
                "WiFiOperateMode": {
                    "type": "dropdown",
                    "value": "5",
                    "label": "WirelessDisplayMode",
                    "info": "",
                    "display": "0",
                    "options": {
                        "CommanderMode": "1",
                        "StandardWirelessDisplay": "5"
                    },
                    "onChange": (_this) => {
                        var value = _this.state.settings.WiFiOperateMode.value;
                        if (value === "1") {
                            _this.setState({
                                "tabHeaders": [
                                    "DeviceConfiguration",
                                    "Features",
                                    "NetworkSettings",
                                    "ManagementAccess",
                                    "AdvancedSettings"
                                ]
                            });
                        }
                        else {
                            _this.setState({
                                "tabHeaders": [
                                    "DeviceConfiguration",
                                    "Features",
                                    "DigitalSignage",
                                    "NetworkSettings",
                                    "LocalWifi",
                                    "ManagementAccess",
                                    "AdvancedSettings"
                                ]
                            });
                        }
                        this.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                    }
                },
                /* Wireless Display over LAN Title - TITLE */
                "WirelessDisplayOverLANTitle": {
                    "type": "title",
                    "title": "WirelessDisplayOverLan",
                    "display": "0",
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* Windows 10 - RADIO BUTTON */
                "infracastEnable": {
                    "type": "radioButton",
                    "label": "Windows10",
                    "info": "Windows10Info",
                    "display": "0",
                    "checkbox": "Infracast",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* macOS and windows 7 - RADIO BUTTON */
                "MacInfracastEn": {
                    "type": "radioButton",
                    "label": "MacWin7",
                    "info": "MacWin7Info",
                    "display": "0",
                    "checkbox": "MacOSandWin7",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* macOS or iOS native screen mirroring - RADIO BUTTON */
                "airPlayEn": {
                    "type": "radioButton",
                    "label": "AirplayMode",
                    "info": "",
                    "display": "0",
                    "checkbox": "MacAirplay",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* iOS push mode - RADIO BUTTON */
                "AirplayMirroringOnlyEn": {
                    "type": "radioButton",
                    "label": "AirplayPushMode",
                    "info": "AirplayPushModeInfo",
                    "display": "0",
                    "checkbox": "iOSPushMode",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* ChromeOS/Chrome native screen mirroring - RADIO BUTTON */
                "GoogleCastEnable": {
                    "type": "radioButton",
                    "label": "GoogleCastMode",
                    "info": "GoogleCastModeInfo",
                    "display": "0",
                    "checkbox": "GoogleCastMode",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* P2P wireless setting title - TITLE */
                "P2PWirelessSettingTitle": {
                    "type": "title",
                    "title": "P2PWirlessSettings",
                    "display": "0"
                },
                /* P2P Operating Channel ----- NEED TO DO */
                /* Transmit Power - DROPDOWN */
                "txPowerItem": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "TransmitPower",
                    "info": "",
                    "display": "0",
                    "options": {
                        "High": "2",
                        "Medium": "1",
                        "Low": "0"
                    }
                },
                /* Security Setting title - TITLE */
                "SecuritySettingTitle": {
                    "type": "title",
                    "title": "SecuritySettings",
                    "display": "0"
                },
                /* Force PIN Pairing on First Connection - RADIO BUTTON*/
                "pinEnforceEnable": {
                    "type": "radioButton",
                    "label": "ForcePinPairing",
                    "info": "ForcePinPairingInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    },
                    "onStart": (settings, data) => {
                        if (settings["pinEnforceEnable"].value === "1") {
                            settings["pinEnforceEnable"].info = "ForcePinPairingInfo";
                        }
                        else {
                            settings["pinEnforceEnable"].info = "ForcePinPairingOffInfo";
                        }

                        return settings["pinEnforceEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        if (_this.state.settings["pinEnforceEnable"].value === "1") {
                            setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingInfo");
                        } else {
                            setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingOffInfo");
                        }
                    }
                },
                /* Require PIN on - RADIO BUTTON */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "pinEachConnEnable": {
                    "type": "radioButton",
                    "label": "RequirePinOn",
                    "info": "RequirePinOnInfo",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "EachConnection": "1",
                        "FirstConnection": "0"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onStart": (settings, data) => {
                        if (settings["pinEachConnEnable"].value === "1") {
                            settings["pinEachConnEnable"].info = "RequirePinOnEachConnInfo";
                        }
                        else {
                            settings["pinEachConnEnable"].info = "RequirePinOnInfo";
                        }

                        return settings["pinEachConnEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        if (_this.state.settings["pinEachConnEnable"].value === "1") {
                            setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnEachConnInfo");
                        } else {
                            setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnInfo");
                        }
                    }
                },
                /* PIN Length - DROPDOWN */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "fourDigitPINEn": {
                    "type": "dropdown",
                    "label": "PinLength",
                    "info": "PinLengthInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "8Digit": "0",
                        "4Digit": "1"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        var strtemp = "";
                        var strtempInfo = "";
                        var staticPinMaxLen = 4;
                        if (_this.state.settings["fourDigitPINEn"].value === "1") {
                            if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length < 4) {
                                strtemp = "StaticPin4Error";
                            }
                            strtempInfo = "StaticPin4Info";
                        }
                        else {
                            staticPinMaxLen = 7;
                            if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length !== 7) {
                                strtemp = "StaticPin8Error";
                            }
                            strtempInfo = "StaticPin8Info";
                        }
                        if (_this.state.settings["staticPinEnable"].value === "1") {
                            setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen,
                                () => {
                                    setSettingState(_this, "staticPinVal", "error", strtemp, () => {
                                        setSettingState(_this, "staticPinVal", "info", strtempInfo);
                                    });
                                });
                        } else {
                            setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen);
                        }
                    }
                },
                /* PIN Generation Method - RADIO BUTTON */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "staticPinEnable": {
                    "type": "radioButton",
                    "label": "PinGenerationMethod",
                    "info": "PinGenerationMethodInfo",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Static": "1",
                        "Random": "0"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onStart": (settings, data) => {
                        if (settings["staticPinEnable"].value === "1") {
                            settings["staticPinEnable"].info = "PinGenerationMethodStaticInfo";
                        }
                        else {
                            settings["staticPinEnable"].info = "PinGenerationMethodInfo";
                        }

                        return settings["staticPinEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        var strStaticPinEnInfo = "";
                        var strStaticPinValInfo = "";
                        if (_this.state.settings["staticPinEnable"].value === "1") {
                            strStaticPinEnInfo = "PinGenerationMethodStaticInfo";
                        }
                        else {
                            strStaticPinEnInfo = "PinGenerationMethodInfo";
                        }

                        if (_this.state.settings["fourDigitPINEn"].value === "1") {
                            strStaticPinValInfo = "StaticPin4Info";
                        }
                        else {
                            strStaticPinValInfo = "StaticPin8Info";
                        }
                        setSettingState(_this, "staticPinEnable", "info", strStaticPinEnInfo,
                            () => {
                                setSettingState(_this, "staticPinVal", "info", strStaticPinValInfo);
                            });
                    }
                },
                /* Static PIN Val - INPUT TEXT */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "staticPinVal": {
                    "type": "pin",
                    "value": "1234567",
                    "label": "StaticPin",
                    "info": "",
                    "pinLength": "fourDigitPINEn",
                    "display": "0",
                    "maxLength": "4",
                    "dependencies": ["pinEnforceEnable", "staticPinEnable"],
                    "dependencyMap": {
                        "10": "1",
                        "00": "1",
                        "01": "1"
                    },
                    "onStart": (settings, data) => {
                        // Initiate the maxLength if receiving data from CMS Service
                        if (data["fourDigitPINEn"] === "0")
                            settings["staticPinVal"].maxLength = "7";
                        return settings["staticPinVal"];
                    },
                    "preValidate": (value, _this) => {
                        if (/^[0-9]+$/.test(value) || value === "") {
                            /*if ( (_this.state.settings["fourDigitPINEn"].value === "1") && (value.length === 4)) {
                                 return true;
                            }else if ((_this.state.settings["fourDigitPINEn"].value === "0") && (value.length === 7)) {
                                 return true;
                            }*/
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        //Now we only need to send 7 digits to RX.
                        /*if (_this.state.settings["staticPinVal"].value.length == 7) {
                            var pin_code = _this.state.settings["staticPinVal"].value;
                            var pin = parseInt(pin_code) * 10;
                            var acccum = 0;
                            var result = "";

                            acccum = 3 * parseInt(((pin / 10000000) % 10), 10);
                            acccum += 1 * parseInt(((pin / 1000000) % 10), 10);
                            acccum += 3 * parseInt(((pin / 100000) % 10), 10);
                            acccum += 1 * parseInt(((pin / 10000) % 10), 10);
                            acccum += 3 * parseInt(((pin / 1000) % 10), 10);
                            acccum += 1 * parseInt(((pin / 100) % 10), 10);
                            acccum += 3 * parseInt(((pin / 10) % 10), 10);
                            result = ((10 - (acccum % 10)) % 10);
                            value = pin_code + result.toString();
                            _this.changeMap["staticPinVal"] = value;
                            setSettingState(_this, "staticPinVal", "value", value);
                        }*/
                        //if (_this.state.settings["fourDigitPINEn"].value === "0") {
                        //    setSettingState(_this,
                        //        "fourDigitPINEn",
                        //        "info",
                        //        "Enter the first 7 digits of the PIN - the 8th digit will be generated for you. Static PIN will not appear on the HDTV/Projector to enable protected mode.");
                        //}
                    },
                    "onSubmit": (_this) => {
                        // Slice the value if it is 4-digit PIN
                        if (_this.state.settings["fourDigitPINEn"].value == "1" && _this.state.settings["staticPinVal"].value.length > 4) {
                            _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value.slice(0, 4);
                        }
                    },
                    "validate": (value, _this) => {
                        
                        if ((_this.state.settings["staticPinEnable"].value == "1") && (_this.state.settings["fourDigitPINEn"].value === "1") && (value.length !== 4)) {
                            // In case, the length is 7 which means the default value, we will ignore it. Otherwise, it is an error
                            if (value.length != 7) return "StaticPin4Error"; 
                        }
                        if ((_this.state.settings["staticPinEnable"].value == "1") && (_this.state.settings["fourDigitPINEn"].value === "0") && (value.length !== 7)) {
                            return "StaticPin8Error";
                        }
                    }
                },
                /* PIN Display Period - INPUT TEXT */
                "PINDisplayPeriod": {
                    "type": "input",
                    "value": "60",
                    "label": "PinDisplayPeriod",
                    "info": "PinDisplayPeriodInfo",
                    "display": "0",
                    "preValidate": (value) => {
                        if (/^[0-9]+$/.test(value) || value === "") {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value > 120 || value < 30 || value === "") {
                            return "PinDisplayPeriodError";
                        }
                    }
                },
                /* Security Setting Title - TITLE */
                "SecuritySettingTitle": {
                    "type": "title",
                    "title": "SecuritySetting",
                    "display": "0"
                },
                /*Disable EAPOL Key Retries (for WPA2-PSK) - Radio Button*/
                "ApBsDisableEapolKeyRetries": {
                    "type": "radioButton",
                    "value": "0",
                    "label": "DisableEAPOLKeyRetries",
                    "info": "",
                    "display": "0",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    },
                    "onStart": (settings, data) => {
                        var tempInfo = "";
                        if (settings["ApBsDisableEapolKeyRetries"].value === "1") {
                            tempInfo = "DisableEAPOLKeyRetriesOnInfo";
                        }
                        else {
                            tempInfo = "DisableEAPOLKeyRetriesOffInfo";
                        }
                        settings["ApBsDisableEapolKeyRetries"].info = tempInfo;
                        return settings["ApBsDisableEapolKeyRetries"];
                    },
                    "onChange": (_this) => {
                        var tempInfo = "";
                        if (_this.state.settings["ApBsDisableEapolKeyRetries"].value === "1") {
                            tempInfo = "DisableEAPOLKeyRetriesOnInfo";
                        }
                        else {
                            tempInfo = "DisableEAPOLKeyRetriesOffInfo";
                        }
                        setSettingState(_this, "ApBsDisableEapolKeyRetries", "info", tempInfo);
                    }
                },
                /* Advanced Feature Title - TITLE */
                "AdvancedFeatureTitle": {
                    "type": "title",
                    "title": "AdvancedFeature",
                    "display": "0"
                },
                /*Enhanced Miracast P2P security - Radio Button*/
                "dynAgoPassphraseEn": {
                    "type": "radioButton",
                    "value": "0",
                    "label": "EnhancedMiracastP2Psecurity",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onStart": (settings, data) => {
                        var tempInfo = "";
                        if (settings["dynAgoPassphraseEn"].value === "1") {
                            tempInfo = "EnhancedMiracastP2PsecurityEnableInfo";
                        }
                        else {
                            tempInfo = "EnhancedMiracastP2PsecurityDisableInfo";
                        }
                        settings["dynAgoPassphraseEn"].info = tempInfo;
                        return settings["dynAgoPassphraseEn"];
                    },
                    "onChange": (_this) => {
                        var tempInfo = "";
                        if (_this.state.settings["dynAgoPassphraseEn"].value === "1") {
                            tempInfo = "EnhancedMiracastP2PsecurityEnableInfo";
                        }
                        else {
                            tempInfo = "EnhancedMiracastP2PsecurityDisableInfo";
                        }
                        setSettingState(_this, "dynAgoPassphraseEn", "info", tempInfo);
                    }
                },
                /* Display Setting Title - TITLE */
                "DisplaySettingTitle": {
                    "type": "title",
                    "title": "DisplaySetting",
                    "display": "0"
                },
                /*Display Sharing Mode - DROPDOWN*/
                "hijackingEn": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "HiJacking",
                    "info": "",
                    "display": "0",
                    "options": {
                        "HiJackingSingle": "0",
                        "HiJackingQuickSwitch": "1",
                        "HiJackingMultiView": "2"
                    },
                    "onStart": (settings, data) => {
                        if (settings["hijackingEn"].value === "0" || settings["hijackingEn"].value === "2") {
                            settings["hijackingEn"].info = "HiJackingSingleInfo";
                        } else {
                            settings["hijackingEn"].info = "HiJackingQuickSwitchInfo";
                        }
                        return settings["hijackingEn"];
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["hijackingEn"].value === "0" || _this.state.settings["hijackingEn"].value === "2") {
                            setSettingState(_this, "hijackingEn", "info", "HiJackingSingleInfo");
                        } else {
                            var strtmp = "";
                            if (_this.state.settings["showOverlayRxNameOpt"].value === "0") {
                                strtmp = "ShowRxNameAlwaysInfo";
                            }
                            else if (_this.state.settings["showOverlayRxNameOpt"].value === "1") {
                                strtmp = "ShowRxNameUponScanInfo";
                            } else {
                                strtmp = "ShowRxNameDonnotShowInfo";
                            }

                            setSettingState(_this, "hijackingEn", "info", "HiJackingQuickSwitchInfo",
                                () => {
                                    setSettingState(_this, "showOverlayRxNameOpt", "info", strtmp);
                                });
                        }
                    }
                },
                /*Show Receiver Name*/
                "showOverlayRxNameOpt": {
                    "type": "radioButton",
                    "value": "0",
                    "label": "ShowRxName",
                    "info": "",
                    "display": "0",
                    "checkbox": "ShowReceiverName",
                    "options": {
                        "ShowRxNameAlways": "0",
                        //"ShowRxNameUponScan": "1"
                        "ShowRxNameDonnotShow": "2"
                    },
                    "dependencies": ["hijackingEn"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1"
                    },
                    "onStart": (settings, data) => {
                        if (data["hijackingEn"] === "1") {
                            if (settings["showOverlayRxNameOpt"].value === "0") {
                                settings["showOverlayRxNameOpt"].info = "ShowRxNameAlwaysInfo";
                            }
                            else if (settings["showOverlayRxNameOpt"].value === "1") {
                                settings["showOverlayRxNameOpt"].info = "ShowRxNameUponScanInfo";
                            } else {
                                settings["showOverlayRxNameOpt"].info = "ShowRxNameDonnotShowInfo";
                            }
                        }
                        return settings["showOverlayRxNameOpt"];
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["showOverlayRxNameOpt"].value === "0") {
                            setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameAlwaysInfo");
                        } else if (_this.state.settings["showOverlayRxNameOpt"].value === "1") {
                            setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameUponScanInfo");
                        } else {
                            setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameDonnotShowInfo");
                        }
                    }
                },
                /* Receiver Name Placement - DROPDOWN */
                "overlayRxNamePlacement": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "ShowRxNamePlacement",
                    "info": "ShowRxNamePlacementInfo",
                    "display": "0",
                    "options": {
                        "ShowRxNamePlacementBL": "0",
                        "ShowRxNamePlacementBR": "1",
                        "ShowRxNamePlacementBCT": "2",
                        "ShowRxNamePlacementUL": "3",
                        "ShowRxNamePlacementUR": "4",
                        "ShowRxNamePlacementUCT": "5"
                    },
                    "dependencies": ["hijackingEn", "showOverlayRxNameOpt"],
                    "dependencyMap": {
                        "00": "1",
                        "01": "1",
                        "02": "1",
                        "12": "1",
                        "20": "1",
                        "21": "1",
                        "22": "1"
                    }
                },
                /* ScreenBeam Network Info - DROPDOWN */
                "showMoreInfoEnable": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "ScreenBeamNetworkInfo",
                    "info": "",
                    "display": "0",
                    "options": {
                        "DisplayAll": "1",
                        "DisplayMinimal": "2",
                        "DisplayNone": "0"
                    }
                },
                /* Connection Instruction - RADIO BUTTON */
                "hideReadyInfoEnable": {
                    "type": "radioButton",
                    "label": "ConnectionInstruction",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Show": "0",
                        "Hide": "1"
                    }
                },
                /* Show "Connect to WiFi" instructions */
                "showConnectWiFiEnable": {
                    "type": "radioButton",
                    "label": "ConnectionWiFiInstruction",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Show": "1",
                        "Hide": "0"
                    },
                    "dependencies": ["hideReadyInfoEnable"],
                    "dependencyMap": {
                        "0": "0",
                        "1": "1"
                    },
                },
                /* HDMI/VGA Port Power Management */
                "hdmiPortPowerManagement": {
                    "type": "dropdown",
                    "label": "HdmiVgaManagement",
                    "value": "01",
                    "display": "0",
                    "info": "HdmiVgaManagementInfo",
                    "isNotInOriginalData": true,
                    "options": {
                        "AlwaysOn": "01",
                        "ScreenSaver": "11",
                        "DisplayOff": "10"
                    },
                    "onStart": (settings, data) => {
                        let scrSaverEnable = data["scrSaverEnable"];
                        let scrHdmiOutEnable = data["scrHDMIOutEnable"];
                        settings["hdmiPortPowerManagement"].value = scrSaverEnable + scrHdmiOutEnable;
                        return settings["hdmiPortPowerManagement"];
                    },
                    "onChange": (_this) => {
                        let selection = _this.state.settings["hdmiPortPowerManagement"].value;
                        _this.changeMap["scrSaverEnable"] = selection[0];
                        _this.changeMap["scrHDMIOutEnable"] = selection[1];
                        _this.changeMap["scrSaverSecond"] = _this.state.settings["scrSaverSecond"].value;
                        delete _this.changeMap["hdmiPortPowerManagement"];
                        _this.setState({
                            settings: {
                                ..._this.state.settings,
                                "scrHDMIOutEnable": {
                                    ..._this.state.settings["scrHDMIOutEnable"],
                                    value: selection[1]
                                },
                                "scrSaverEnable": {
                                    ..._this.state.settings["scrSaverEnable"],
                                    value: selection[0]
                                }
                            }
                        });
                    }
                },
                "scrHDMIOutEnable": {
                    "value": "1"
                },
                "scrSaverEnable": {
                    "value": "0"
                },
                /* Wait - Triggered when HDMI/VGA selects Screensaver or Display Off - INPUT TEXT */
                "scrSaverSecond": {
                    "type": "input",
                    "label": "Wait",
                    "info": "WaitInfo",
                    "display": "1",
                    "value": "180",
                    "dependencies": ["hdmiPortPowerManagement"],
                    "dependencyMap": {
                        "01": "1",
                        "11": "0",
                        "10": "0"
                    },
                    "validate": (value) => {
                        if (value == "")
                            return "ScreenSaverWaitEmptyError"
                    }
                },
                /* Wake up - DROPDOWN */
                "wakedByScan": {
                    "type": "dropdown",
                    "label": "Wakeup",
                    "info": "",
                    "value": "0",
                    "options": {
                        "OnConnect": "0",
                        "OnScan": "1"
                    },
                    "display": "2",
                    "dependencies": ["hdmiPortPowerManagement"],
                    "dependencyMap": {
                        "01": "2",
                        "11": "0",
                        "10": "0"
                    }
                },
                /* Adjust TV Screen Size - DROPDOWN */
                "newOverScanVal": {
                    "type": "dropdown",
                    "value": "25",
                    "label": "AdjustTvScreenSize",
                    "info": "",
                    "display": "0",
                    "options": {
                        "0": "0",
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "14": "14",
                        "15": "15",
                        "16": "16",
                        "17": "17",
                        "18": "18",
                        "19": "19",
                        "20": "20",
                        "21": "21",
                        "22": "22",
                        "23": "23",
                        "24": "24",
                        "25": "25"
                    },
                    "onSubmit": (_this) => {
                        // Need to send rtOverscanEnable together
                        _this.changeMap["rtOverscanEnable"] = _this.state.settings["rtOverscanEnable"].value;
                    }
                },
                /* Allow source device to override overscan value */
                "rtOverscanEnable": {
                    "type": "checkbox",
                    "label": "OverrideOverScanVal",
                    "info": "",
                    "display": "0",
                    "value": "0"
                },
                /* Auto-reboot receiver when idle for - DROPDOWN */
                "InactivityRebootTime": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "AutoRebootReceiver",
                    "info": "",
                    "options": {
                        "Never": "0",
                        "6 hours": "1",
                        "8 hours": "2",
                        "10 hours": "3",
                        "12 hours": "4",
                        "24 hours": "5"
                    }
                },
                /* Receiver Logging - RADIO BUTTON */
                "RxCsvLogEn": {
                    "type": "radioButton",
                    "label": "ReceiverLogging",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /*Digital Signage Mode Title - TITLE*/
                "DigitalSignageModeTitle": {
                    "type": "title",
                    "title": "DigitalSignageMode",
                    "display": "0"
                },
                /*Digital Signage Mode SubTitle - SUBTITLE*/
                "DigitalSignageModeSubTitle": {
                    "type": "subtitle",
                    "title": "DigitalSignageModeSubTitle",
                    "display": "0"
                },
                /*Digital Signage*/
                "digitalSignEn": {
                    "type": "radioButton",
                    "label": "DigitalSignage",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onChange": (_this) => {
                        this.setDigitalSignageModeSettings(_this);
                    },
                },
                /*Display Mode*/
                "digitalSignFullScreenEn": {
                    "type": "radioButton",
                    "label": "SignageDisplayMode",
                    "info": "SignageDisplayModeInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "digitalSignFramed": "0",
                        "digitalSignFullscreen": "1"
                    },
                    "dependencies": ["digitalSignEn"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        this.setDigitalSignageModeSettings(_this);
                    }
                },
                /*Source URL*/
                "digitalSignUrl": {
                    "type": "input",
                    "label": "digitalSignSourceUrl",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["digitalSignEn"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        this.setDigitalSignageModeSettings(_this);
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["digitalSignEn"].value == "1" && value.length == 0) {
                            return "CannotBeEmpty"
                        }
                    }
                },
                /*Restart Delay(seconds)*/
                "digitalSignDelaySec": {
                    "type": "input",
                    "label": "digitalSignRestartDelay",
                    "info": "digitalSignRestartDelayInfo",
                    "display": "0",
                    "value": "10",
                    "dependencies": ["digitalSignEn"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "preValidate": (value) => {
                        if (/^[0-9]+$/.test(value) || value === "") {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value < 10 || value > 1000) {
                            return "digitalSignRestartDelayErr";
                        } else {
                            return "";
                        }
                    },
                    "onChange": (_this) => {
                        this.setDigitalSignageModeSettings(_this);
                    }
                },
                /*Digital Signage Audio Volume - DROPDOWN */
                "digitalSignAudioVol": {
                    "type": "dropdown",
                    "label": "digitalSignAudioVolume",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "dependencies": ["digitalSignEn"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "options": {
                        "Mute": "0",
                        "Low": "1",
                        "Medium": "2",
                        "High": "3"
                    },
                    "onChange": (_this) => {
                        this.setDigitalSignageModeSettings(_this);
                    }
                },
                /* Network Mode - DROPDOWN */
                "NetworkMode": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "NetworkMode",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Nat": "1",
                        "Bridge": "2",
                        "None": "0"
                    }
                },
                /*Block communication to devices on the LAN - Radio Button */
                "BlockLanIpEnable": {
                    "type": "radioButton",
                    "label": "BlockLanIpLabel",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["NetworkMode"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1"
                    }
                },
                /* Local Wifi Network Title - TITLE */
                "LocalWifiNetworkTitle": {
                    "type": "title",
                    "title": "LocalWifiNetwork",
                    "display": "0"
                },
                /* Interface Feature Assignment Title - TITLE */
                "InterfaceFeatureTitle": {
                    "type": "title",
                    "title": "InterfaceFeatureAssignment",
                    "display": "0"
                },
                /* CMS Interface - DROPDOWN */
                "cmsInterface": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "CmsInterface",
                    "info": "",
                    "display": "2",
                    "options": {
                        "Auto": "0"
                    },
                    "dependencies": ["NetworkMode"],
                    "dependencyMap": {
                        "0": "1"
                    }
                },
                /* Internet WAN Interface - DROPDOWN */
                "apBridgeInterface": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "InternetWanInterface",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Ethernet": "1",
                        "Wireless": "2"
                    },
                    "dependencies": ["NetworkMode"],
                    "dependencyMap": {
                        "0": "1"
                    }
                },
                /* Network Interface Settings Title - TITLE */
                "NetworkInterfaceTitle": {
                    "type": "title",
                    "title": "NetworkInterfaceSettings",
                    "display": "0"
                },
                /* Ethernet Interface Subtitle - SUBTITLE */
                "EthernetInterfaceSubtitle": {
                    "type": "subtitle",
                    "title": "EthernetInterface",
                    "display": "0"
                },
                /* Network Name - INPUT TEXT */
                "EthNetworkName": {
                    "type": "input",
                    "label": "NetworkName",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyNetworkNameError";
                        }
                        if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                            return "InvalidNetworkNameError";
                        }
                    }
                },
                /* Connection Settings Subtitle - SUBTITLE */
                "ConnectionSettingsSubtitle": {
                    "type": "subtitle",
                    "title": "ConnectionSettings",
                    "display": "0"
                },
                /* Ethernet Connection Authentication - DROPDOWN */
                "CurEthAuthentication": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "Authentication",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "PEAP-MSCHAPV2": "2",
                        "EAP-TLS": "3"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                            _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                /* Ethernet Connection Username - INPUT TEXT */
                // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
                "CurEthRadiusID": {
                    "type": "input",
                    "value": "",
                    "label": "Username",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "0",
                        "3": "0"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurEthAuthentication"].value == "2"
                            || _this.state.settings["CurEthAuthentication"].value == "3") {
                            if (value.length == 0) return "EmptyUsernameError";
                            if (!(/^[a-zA-Z0-9\s._@#$-]+$/.test(value))) {
                                return "InvalidUsernameError";
                            }
                        }
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                /* Ethernet Connection Password - INPUT PASSWORD */
                // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
                "CurEthRadiusPWD": {
                    "type": "password",
                    "value": "",
                    "label": "Password",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "0",
                        "3": "0"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurEthAuthentication"].value == "2"
                            || _this.state.settings["CurEthAuthentication"].value == "3") {
                            if (value.length == 0) return "EmptyPasswordError";
                            if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                                return "InvalidPasswordError";
                            }
                        }
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                // NEED TO ADD SYSTEM DATE & TIME, CA CERTIFICATE, USER CERTIFICATE, PRIVATE KEY, VALIDITY PERIOD
                /* System Date & Time */
                "EthCertDateTime": {
                    "type": "date",
                    "label": "SystemDateTime",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - CA Certificate URL - DISABLED INPUT TEXT */
                "EthRootCertURL": {
                    "type": "file",
                    "label": "CaCertificateUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                    //"type": "input",
                    //"label": "CA Certificate URL",
                    //"info": "",
                    //"disabled": true,
                    //"displayMode": { "controlItem": "CurEthAuthentication", "controlValue": ["0", "2"], "display": "1" }
                },
                /* Ethernet - User Certificate - DISABLED INPUT TEXT */
                "EthUserCertURL": {
                    "type": "file",
                    "label": "UserCertificateUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - Private Key URL - DISABLED INPUT TEXT */
                "EthUserKeyURL": {
                    "type": "file",
                    "label": "PrivateKeyUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - Validity Period - INPUT TEXT */
                "EthCertsValidity": {
                    "type": "input",
                    "label": "ValidityPeriod",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* TCP/IP Settings Subtitle - SUBTITLE */
                "TCPIPSettingsSubtitle": {
                    "type": "subtitle",
                    "title": "TcpIpSettings",
                    "display": "0"
                },
                /* Ethernet IP Assignment - RADIO BUTTON */
                "ethAutoIpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                            _this.changeMap["ethAutoDnsEnable"] = "0";
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;

                            _this.setState({
                                settings: {
                                    ..._this.state.settings,
                                    "ethAutoDnsEnable": {
                                        ..._this.state.settings["ethAutoDnsEnable"],
                                        value: "0"
                                    }
                                }
                            });
                        }

                    }
                },
                /* Ethernet IP Address - INPUT IP */
                "ethIpAddress": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }
                    }
                },
                /* Ethernet Subnet Mask - INPUT IP */
                "ethNetmask": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }
                    }
                },
                /* Default Gateway - INPUT IP */
                "ethGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }
                    }
                },
                /* DNS Assignment - RADIO BUTTON */
                "ethAutoDnsEnable": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            //_this.changeMap["ethAutoDnsEnable"] = "0";
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },
                /* Ethernet Primary DNS Server */
                "ethPrimaryDns": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["ethAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },
                /* Ethernet Secondary DNS Server */
                "ethSecondaryDns": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["ethAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },
                /* Wireless Interface Subtitle - SUBTITLE */
                "WirelessInterfaceSubtitle": {
                    "type": "subtitle",
                    "title": "WirelessInterface",
                    "display": "0"
                },
                /* Network Name - INPUT TEXT */
                "CurConnectApName": {
                    "type": "input",
                    "label": "WirelessNetworkName",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyNetworkNameError";
                        }
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];,\.\/]+$/.test(value)) {
                            return "InvalidNetworkNameError";
                        }
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    }
                },
                /* Security Type - DROPDOWN */
                "CurConnectApSecType": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "SecurityType",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "Shared": "1",
                        "WPA-PSK[TKIP]": "2",
                        "WPA2-PSK[AES]": "3",
                        "WPA-PSK[TKIP]+WPA2-PSK[AES]": "4",
                        "PEAP-MSCHAPV2": "5",
                        "EAP-TLS": "6"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    }
                },
                /* Username - INPUT TEXT */
                "CurConnectApRadiusId": {
                    "type": "input",
                    "value": "",
                    "label": "Username",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "0",
                        "6": "0"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurConnectApSecType"].value == "5" || _this.state.settings["CurConnectApSecType"].value == "6") {
                            if (value.length == 0) return "EmptyUsernameError";
                            if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                                return "InvalidUsernameError";
                            }
                        }
                    }
                },
                /* Password - INPUT PASSWORD */
                "CurConnectApPwd": {
                    "type": "password",
                    "value": "",
                    "label": "Password",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "0",
                        "2": "0",
                        "3": "0",
                        "4": "0",
                        "5": "0",
                        "6": "0"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurConnectApSecType"].value == "1"
                            || _this.state.settings["CurConnectApSecType"].value == "2"
                            || _this.state.settings["CurConnectApSecType"].value == "3"
                            || _this.state.settings["CurConnectApSecType"].value == "4"
                            || _this.state.settings["CurConnectApSecType"].value == "5"
                            || _this.state.settings["CurConnectApSecType"].value == "6") {
                            if (value.length == 0) return "EmptyPasswordError";
                            if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                                return "InvalidPasswordError";
                            }
                        }
                    }
                },
                /* Wireless - Date Time - DISABLED INPUT */
                "TLSCertDateTime": {
                    "type": "date",
                    "value": "",
                    "label": "SystemDateTime",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - CA Certificate URL - DISABLE INPUT */
                "TLSRootCertURL": {
                    "type": "file",
                    "value": "",
                    "label": "CaCertificateUrl",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - User Certificate URL - DISABLE INPUT */
                "TLSUserCertURL": {
                    "type": "file",
                    "value": "",
                    "label": "UserCertificateUrl",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - Private Key URL - DISABLE INPUT */
                "TLSUserKeyURL": {
                    "type": "file",
                    "value": "",
                    "label": "PrivateKeyUrl",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless IP Assignment - RADIO BUTTON */
                "staAutoIpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                            _this.changeMap["staAutoDnsEnable"] = "0";
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;

                            _this.setState({
                                settings: {
                                    ..._this.state.settings,
                                    "staAutoDnsEnable": {
                                        ..._this.state.settings["staAutoDnsEnable"],
                                        value: "0"
                                    }
                                }
                            });
                        }
                    }
                },
                /* Wireless IP Address - INPUT IP */
                "staIpAddress": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }

                },
                /* Wireless Subnet Mask - INPUT IP */
                "staNetmask": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless Default Gateway - INPUT IP */
                "staGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless DNS Assignment - RADIO BUTTON */
                "staAutoDnsEnable": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            //_this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Wireless Primary DNS Server - INPUT IP */
                "staPrimaryDns": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["staAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Wireless Secondary DNS Server - INPUT IP */
                "staSecondaryDns": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["staAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Local-Wifi Settings Title - TITLE */
                "LocalWifiSettingsTitle": {
                    "type": "title",
                    "title": "LocalWifiSettings",
                    "display": "0"
                },
                /* ScreenBeam Local Wi-Fi - RADIO BUTTON - For batch settings only*/
                "ApBsSSEnable": {
                    "type": "radioButton",
                    "label": "ScreenBeamLocalWifi",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                "ApBsSSIDIndex": { "value": "1" },
                /* ScreenBeam Local Wi-Fi - RADIO BUTTON */
                "ApBsS1Enable": {
                    "type": "radioButton",
                    "label": "ScreenBeamLocalWifi",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ApBsS1Enable"].value === "0") {
                            if (_this.state.settings["networkInterfaceAllowed"].value === "3") {
                                this.popupConfirmAlertDialog(/*_this, "ApBsS1Enable",*/ "DisableLocalWIFIWarning", () => { this.handleWarningClickedNo(_this, "ApBsS1Enable") }, null);
                            }
                        }
                        this.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                    },
                    "onSubmit": (_this) => {
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                        _this.changeMap["ApBsSSIDIndex"] = "1";
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                        delete _this.changeMap["ApBsS1Enable"];
                    }

                },
                /* Wireless Network Name - INPUT TEXT*/
                "ApBsS1Name": {
                    "type": "input",
                    "label": "WirelessNetworkName",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "dependencies": ["ApBsS1Enable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 32) {
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSName"] = _this.changeMap["ApBsS1Name"];
                        _this.changeMap["ApBsSSIDIndex"] = "1";
                        delete _this.changeMap["ApBsS1Name"];
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                        _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value;
                    },
                    "validate": (value) => {
                        if (value.length == 0) {
                            return "EmptyUsernameError";
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    },
                    "onSubmit": (_this) => {
                        _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value;
                        delete _this.changeMap["ApBsS1Name"]
                    }
                },
                /* Wireless Security Type - DROPDOWN */
                "ApBsS1SecurityType": {
                    "type": "dropdown",
                    "value": "3",
                    "label": "SecurityType",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "WPA2 PSK": "3"
                    },
                    "dependencies": ["ApBsS1Enable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSSecurityType"] = _this.changeMap["ApBsS1SecurityType"]
                        _this.changeMap["ApBsSSIDIndex"] = "1"
                        delete _this.changeMap["ApBsS1SecurityType"]
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                        _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value
                        _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                    },
                    "onSubmit": (_this) => {
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value
                        delete _this.changeMap["ApBsS1SecurityType"]
                    }
                },
                /* Password Phrase - INPUT PASSWORD */
                "ApBsS1Passwd": {
                    "type": "password",
                    "value": "",
                    "label": "PasswordPhrase",
                    "info": "",
                    "display": "0",
                    "dependencies": ["ApBsS1SecurityType", "ApBsS1Enable"],
                    "dependencyMap": {
                        "00": "1",
                        "01": "1",
                        "30": "2"
                    },
                    "preValidate": (value) => {
                        if (value.length < 64) {
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSPasswd"] = _this.changeMap["ApBsS1Passwd"];
                        _this.changeMap["ApBsSSIDIndex"] = "1";
                        delete _this.changeMap["ApBsS1Passwd"];
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                        _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value;
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyPasswordError";
                        }
                        if (value.length < 8 || value.length > 63) {
                            return "InvalidLocalWifiPasswordLengthError"
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidLocalWiFiAPPasswordErr";
                        }
                    },
                    "onSubmit": (_this) => {
                        _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value;
                        delete _this.changeMap["ApBsS1Passwd"]
                    }
                },
                /* Wireless Channel - DROPDOWN */
                "ApBsChannelNum": {
                    "type": "dropdown",
                    "value": "165",
                    "label": "WirelessChannel",
                    "info": "",
                    "display": "2",
                    "options": {
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                },
                /* Central Management System Settings - Title */
                "CentralMgtSystemTitle": {
                    "type": "title",
                    "title": "CentralManagementSystemSettings",
                    "display": "0"
                },
                /* CMS Server - INPUT TEXT */
                "mgtSrvFullAddr": {
                    "type": "input",
                    "value": "",
                    "label": "CmsServer",
                    "info": "CmsServerInfo",
                    "display": "2",
                    "validate": (value) => {
                        return check_cms_host(value);
                    }
                },
                /* CMS Communication Port - INPUT TEXT */
                "mgtSrvPort": {
                    "type": "input",
                    "value": "",
                    "label": "CmsCommunicationPort",
                    "info": "CmsCommunicationPortInfo",
                    "display": "2",
                    "preValidate": (value) => {
                        if (/^[0-9]*$/.test(value)) {
                            return true
                        }
                    },
                    "validate": (value) => {
                        return check_cms_port(value);
                    }
                },
                /* Web Communication Port - INPUT TEXT */
                "webServerPort": {
                    "type": "input",
                    "value": "",
                    "label": "WebCommunicationPort",
                    "info": "WebCommunicationPortInfo",
                    "display": "0",
                    "preValidate": (value) => {
                        if (/^[0-9]*$/.test(value)) {
                            return true
                        }
                    },
                    "validate": (value) => {
                        return check_web_port(value);
                    }
                },
                /* Local Management Wireless Access Settings Title - TITLE */
                "LocalMgtWirelessAccessTitle": {
                    "type": "title",
                    "title": "LocalManagementWirelessAccess",
                    "display": "0"
                },
                /* Local Management Interface Access - DROPDOWN */
                "webMgtOverWiFiEn": {
                    "type": "dropdown",
                    "label": "LocalManagementInterfaceAccess",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "2",
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onStart": (settings, data) => {
                        var tempInfo = "";
                        switch (settings["webMgtOverWiFiEn"].value) {
                            case "0":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessDisableInfo";
                                }
                                break;
                            case "1":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessEnableInfo";
                                }
                                break;
                            case "2":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessAutoInfo";
                                }
                                break;
                            default: break;
                        }
                        settings["webMgtOverWiFiEn"].info = tempInfo;
                        return settings["webMgtOverWiFiEn"];
                    },
                    "onChange": (_this) => {
                        var tempInfo = "";
                        switch (_this.state.settings["webMgtOverWiFiEn"].value) {
                            case "0":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessDisableInfo";
                                }
                                break;
                            case "1":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessEnableInfo";
                                }
                                break;
                            case "2":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessAutoInfo";
                                }
                                break;
                            default: break;
                        }
                        setSettingState(_this, "webMgtOverWiFiEn", "info", tempInfo);
                    }
                    //"type": "dropdown",
                    //"value": "",
                    //"label": "Local Management Interface Access",
                    //"info": "",
                    //"disabled": false,
                    //"options": [
                    //    { "value": "2", "label": "Auto" },
                    //    { "value": "1", "label": "Enable" },
                    //    { "value": "0", "label": "Disable" }
                    //]
                },
                /* Network Interface Allowed - DROPDOWN */
                "networkInterfaceAllowed": {
                    "type": "dropdown",
                    "label": "NetworkInterfaceAllowed",
                    "info": "NetworkInterfaceAllowedInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2",
                        "LocalWi-FiAP": "3",
                        "MiracastP2P": "4"
                    },
                    "dependencies": ["webMgtOverWiFiEn"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onStart": (settings, data) => {
                        this.ChangeLMIInterfaceAllowedList(null, settings, true, data);
                        return settings["networkInterfaceAllowed"];
                    },
                },
                /* Allow Force Disconnection - RADIO BUTTON */
                /*                "hijackingEn": {
                                    "type": "radioButton",
                                    "label": "AllowForceDisconnection",
                                    "info": "AllowForceDisconnectionInfo",
                                    "display": "0",
                                    "value": "0",
                                    "options": {
                                        "Enable": "1",
                                        "Disable": "0"
                                    }
                                },*/ //1100 change to drop menu
                /* PCR Synchronization - RADIO BUTTON */
                "PCRSyncEnable": {
                    "type": "radioButton",
                    "label": "PcrSync",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* HDCP Encryption - DROPDOWN */
                "HDCPEncrypMode": {
                    "type": "dropdown",
                    "label": "HdcpEncryption",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "0",
                        "Disable": "1",
                        "DemoMode": "2"
                    },
                },
                /* Aspect Ratio - DROPDOWN */
                "aspectRatio": {
                    "type": "dropdown",
                    "label": "AspectRatio",
                    "info": "",
                    "display": "2",
                    "value": "1",
                    "options": {
                        "4:3": "0",
                        "16:9": "1",
                        "16:10": "2"
                    }
                },
                /* Horizontal/Veritcal Offset - RATIO */
                "scrHoffset": {
                    "type": "ratio",
                    "value": "0",
                    "display": "2",
                    "horizontalName": "scrHoffset",
                    "verticalName": "scrVoffset",
                    "label": "HorizontalVerticalOffset",
                    "onChange": (_this) => {
                        _this.changeMap["scrVoffset"] = _this.state.settings["scrVoffset"].value;
                    }
                },
                "scrVoffset": {
                    "value": "0",
                    "onChange": (_this) => {
                        _this.changeMap["scrHoffset"] = _this.state.settings["scrHoffset"].value;
                    }
                },
                /* Debug View - RADIO BUTTON */
                "debugViewEnable": {
                    "type": "radioButton",
                    "label": "DebugView",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    }
                },
                /* P2P IP Address - INPUT IP */
                "p2pIpAddr": {
                    "type": "ip",
                    "label": "P2PIpAddress",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P Subnet Mask - INPUT IP */
                "p2pSubnet": {
                    "type": "ip",
                    "label": "P2PSubnetMask",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P DHCP IP Start - INPUT IP */
                "p2pDhcpIpStart": {
                    "type": "ip",
                    "label": "P2PDhcpIpStart",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P DHCP IP End - INPUT IP */
                "p2pDhcpIpEnd": {
                    "type": "ip",
                    "label": "P2PDhcpIpEnd",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;

                    }
                },
                /* P2P Wireless Network Name (SSID) - Special INPUT */
                "p2pSsid": {
                    "type": "postfixInput",
                    "label": "P2PWirelessNetworkName",
                    "prefix": "DIRECT-xy",
                    "info": "",
                    "display": "0",
                    "preValidate": (value, _this) => {
                        let prefixLength = _this.state.settings["p2pSsid"].prefix.length
                        if ((value.length - prefixLength) > 23) {
                            return false
                        }
                        return true
                    },
                    "validate": (value, _this) => {
                        if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                            return "InvalidNetworkNameError";
                        }
                    }
                },
                /* P2P Wireless Network Password - INPUT PASSWORD */
                "p2pPassword": {
                    "type": "password",
                    "label": "P2PWirelessPassword",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "preValidate": (value, _this) => {
                        if (value.length > 24) {
                            return false
                        }
                        return true
                    },
                    "validate": (value) => {
                        if (value.length < 8 || value.length > 24) {
                            return "InvalidP2PPasswordLengthError"
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidLocalWiFiAPPasswordErr";
                        }
                    }
                },
                /* Fast-IP - RADIO BUTTON */
                "fastIPEn": {
                    "type": "radioButton",
                    "label": "FastIp",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* AGO beacon broadcast - RADIO BUTTON */
                "txAgoBcnEnable": {
                    "type": "radioButton",
                    "label": "AGOBeaconBroadcast",
                    "info": "",
                    "display": "1",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* USB Local Access - DROPDOWN */
                //"usbLocalAccessCtrl": {
                //    "type": "dropdown",
                //    "value": "",
                //    "label": "UsbLocalAccess",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        { "value": "0", "label": "Disable" },
                //        { "value": "1", "label": "Only allow firmware update" },
                //        { "value": "2", "label": "Enable" }
                //    ]
                //},
                ///* User Input Back Channel (UIBC) - DROPDOWN */
                "UIBCEnable": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "Uibc",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Enable": "0",
                        "Disable": "1"
                    }
                },
                ///* USB Over IP (UoIP) - DROPDOWN */
                //"UOIPEnable": {
                //    "type": "dropdown",
                //    "value": "",
                //    "label": "UoIp",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        { "value": "0", "label": "Not implemented" },
                //        { "value": "1", "label": "Enable" },
                //        { "value": "2", "label": "Disable" }
                //    ]
                //},
                ///* Maximum Video Resolution - DROPDOWN */
                //"VideoMaxResolution": {
                //    "type": "dropdown",
                //    "value": "",
                //    "label": "MaximumVideoResolution",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        { "value": "0", "label": "Auto" },
                //        { "value": "1", "label": "1080" },
                //        { "value": "2", "label": "720" },
                //        { "value": "3", "label": "480" }
                //    ]
                //},
                ///* Audio Speaker - DROPDOWN */
                //"AudioSpeakerChs": {
                //    "type": "dropdown",
                //    "value": "",
                //    "label": "AudioSpeaker",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        { "value": "0", "label": "Auto" },
                //        { "value": "1", "label": "2 Channels" },
                //        { "value": "2", "label": "5.1 Channels" }
                //    ]
                //},
                ///* HDMI-CEC - RADIO BUTTON*/
                "CECEnable": {
                    "type": "radioButton",
                    "label": "HdmiCec",
                    "info": "",
                    "display": "0",
                    "disabled": false,
                    "options": {
                        "On": "1",
                        "Off": "0"
                    }
                },
                /* Firmware Upgrade - RADIO BUTON */
                "fwupdateAccess": {
                    "type": "radioButton",
                    "label": "FirmwareUpgradeUsb",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* DTLSSupport  - RADIO BUTTON */
                //"DTLSSupport": {
                //    "type": "radioButton",
                //    "label": "DTLSSupport",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        {
                //            "label": "Enable",
                //            "value": "1"
                //        },
                //        {
                //            "label": "Disable",
                //            "value": "0"
                //        }
                //    ]
                //},
                ///* Infracast IP Address - RADIO BUTTON*/
                //"infracastIPEnable": {
                //    "type": "radioButton",
                //    "label": "Infracast IP Address",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        {
                //            "label": "Enable",
                //            "value": "1"
                //        },
                //        {
                //            "label": "Disable",
                //            "value": "0"
                //        }
                //    ]
                //},
                ///* Wireless Network Name (SSID) - SB 960 - INPUT TEXT */
                //"ApName": {
                //    "type": "input",
                //    "label": "Wireless Network Name (SSID)",
                //    "value": "",
                //    "info": "",
                //    "disabled": false
                //},
                ///* Wireless Network Password - SB 960 - INPUT PASSWORD */
                //"ApPassword": {
                //    "type": "password",
                //    "label": "Wireless Network Password",
                //    "value": "",
                //    "info": "",
                //    "disabled": false
                //},
                ///* Broadcast Network Name - RADIO BUTTON */
                //"LMIBeaconEnable": {
                //    "type": "radioButton",
                //    "label": "Broadcast Network Name",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        {
                //            "label": "Enable",
                //            "value": "1"
                //        },
                //        {
                //            "label": "Disable",
                //            "value": "0"
                //        }
                //    ]
                //},
                ///* P2P Interface - RADIO BUTTON */
                //"p2pInterfaceEnable": {
                //    "type": "radioButton",
                //    "label": "P2P Interace",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        {
                //            "label": "Enable",
                //            "value": "1"
                //        },
                //        {
                //            "label": "Disable",
                //            "value": "0"
                //        }
                //    ]
                //},
                ///* Ethernet IP Assignment - SB 960 - RADIO BUTTON */
                //"ethDhcpEnable": {
                //    "type": "radioButton",
                //    "label": "IP Assignment",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        {
                //            "label": "Auto",
                //            "value": "1"
                //        },
                //        {
                //            "label": "Static",
                //            "value": "0"
                //        }
                //    ]
                //},
                ///* Ethernet IP Address - SB 960 - INPUT IP */
                //"ethIpaddr": {
                //    "type": "ip",
                //    "label": "IP Address",
                //    "info": "",
                //    "disabled": false
                //},
                ///* Ethernet Subnet Mask - SB 960 - INPUT IP */
                //"ethSubnet": {
                //    "type": "ip",
                //    "label": "Subnet Mask",
                //    "info": "",
                //    "disabled": false
                //},
                ///* Default Gateway - SB 960 - INPUT IP */
                //"defaultGateway": {
                //    "type": "ip",
                //    "label": "Default Gateway",
                //    "info": "",
                //    "disabled": false
                //},
                ///* DNS Assignment - SB 960 - RADIO BUTTON */
                //"DNSPolicy": {
                //    "type": "radioButton",
                //    "label": "DNS Assignment",
                //    "info": "",
                //    "disabled": false,
                //    "options": [
                //        {
                //            "label": "Auto",
                //            "value": "1"
                //        },
                //        {
                //            "label": "Static",
                //            "value": "0"
                //        }
                //    ]
                //},
                ///* Ethernet Primary DNS Server - SB 960 - INPUT IP*/
                //"primaryDNS": {
                //    "type": "ip",
                //    "label": "Primary DNS Server",
                //    "info": "",
                //    "disabled": false
                //},
                ///* Ethernet Secondary DNS Server - SB 960 - INPUT IP */
                //"secondDNS": {
                //    "type": "ip",
                //    "label": "Secondary DNS Server",
                //    "info": "",
                //    "disabled": false
                //},

                /* Miracast Connection - RADIO BUTTON */
                "miracastConnEnable": {
                    "type": "radioButton",
                    "label": "MiracastConnection",
                    "info": "MiracastConnectionEnInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onStart": (settings, data) => {
                        settings["miracastConnEnable"].info = "MiracastConnectionEnInfo";
                        if (settings["miracastConnEnable"].value === "0") {
                            settings["miracastConnEnable"].info = "MiracastConnectionDisInfo";
                        }
                        return settings["miracastConnEnable"];
                    },
                    "onChange": (_this) => {
                        var strInfo = "MiracastConnectionEnInfo";
                        if (_this.state.settings["miracastConnEnable"].value === "0") {
                            strInfo = "MiracastConnectionDisInfo";
                            if (_this.state.settings["networkInterfaceAllowed"].value === "4") {
                                this.popupConfirmAlertDialog(/*_this, "miracastConnEnable",*/ "DisableMiracastConnWarning", () => { this.handleWarningClickedNo(_this, "miracastConnEnable") }, null);
                            }
                        }
                        //this.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                        setSettingState(_this, "miracastConnEnable", "info", strInfo, () => {
                            this.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                        });
                    }
                },
                /* P2P Operating Channel - DROPDOWN */
                "autoGoChannel": {
                    "type": "dropdown",
                    "label": "P2POperatingChannel",
                    "info": "P2POperatingChannelInfo",
                    "value": "165",
                    "display": "0",
                    "options": {
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                    //"region": "clWiFiRegionCode",
                    //"country": "clWiFiCountryCode",
                    //"wifi20Channels": "clWiFi20Channels",
                    //"wifi40Channels": "clWiFi40Channels",
                    //"wifi80Channels": "clWiFi80Channels"
                },
                /* USB Auto Config - RADIO BUTTON */
                "usbConfigEn": {
                    "type": "radioButton",
                    "label": "UsbAutoConfigure",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                }
            }
        };
        return Settings_11_0;
    }

    getSettingsIn9dot6() {
        let Settings_9_6 = {
            "SingleRxSettings": {
                "TabHeader": [
                    "DeviceConfiguration",
                    "Features",
                    "NetworkSettings",
                    // "LocalWifi",
                    "ManagementAccess"
                    // "AdvancedSettings"
                ],
                "TabContent": {
                    "DeviceConfiguration": [
                        "RxNameAccess", // Device Name Access
                        "RxName", // Device Name
                        "manufacName", // Manufacture Name
                        "mdNameShort", // Model Name Short
                        "mdNameLong", // Model Name
                        "hwVersion", // Hardware Name
                        "fwVersion", // Firmware Version
                        //"webUserName", // Administrator Name
                        //"webPassword", // Administrator Password
                        "language", // Language
                        "RXHostname", // Host Name
                        "timezoneItem", // Time zone
                        "dstTimeEnable" // Daylight savings
                    ],
                    "Features": [
                        "SecuritySettingTitle", // Security Setting Title
                        "pinEnforceEnable", // Force PIN Pairing on First Connection
                        "pinEachConnEnable", // Require PIN on
                        "fourDigitPINEn", // PIN Length
                        "staticPinEnable", // PIN Generation Method
                        "staticPinVal", // Static PIN Val
                        "hdmiPortPowerManagement", // HDMI/VGA component
                        "scrSaverSecond", // Wait - Triggered when HDMI/VGA selects Screensaver or Display Off
                        "HDCPEncrypMode", // HDCP Encryption Mode
                        "usbLocalAccessCtrl", // USB local Access
                        "UIBCEnable", // User Input Back Channel (UIBC)
                        "UOIPEnable", // USB Over IP (UoIP)
                        "VideoMaxResolution", // Maximum Video Resolution
                        "AudioSpeakerChs", // Audio Speaker
                        "newOverScanVal", // Adjust TV Screen Size
                        "rtOverscanEnable", // Allow source device to override overscan value
                        "CECEnable", // HDMI-CEC
                        "fwupdateAccess",// Firmware Upgrade
                        "infracastEnable",// Infracast
                        "infracastPinEn",//Infracast Require PIN
                        "DTLSSupport",// DTLSSupport
                        "infracastIPEnable"// Infracast IP Address
                    ],
                    "NetworkSettings": [
                        "showMoreInfoEnable", // ScreenBeam Network Info
                        "hideReadyInfoEnable", // Connection Instruction
                        "ApName",      // Wireless Network Name
                        "ApPassword",   // Wireless Network Password
                        "LMIBeaconEnable",  // Broadcast Network Name
                        "P2PInterfaceSettingTitle",
                        "p2pInterfaceEnable",    // P2P Interface
                        "opChannelList",
                        "preOpChannelList"
                    ],
                    "ManagementAccess": [
                        "EthernetInterfaceSubtitle",
                        "ConnectionSettingsSubtitle",
                        "ethDhcpEnable",
                        "ethIpaddr",
                        "ethSubnet",
                        "defaultGateway",
                        "DNSPolicy",
                        "primaryDNS",
                        "secondDNS",
                        "CentralMgtSystemTitle",
                        "mgtSrvFullAddr",
                        "mgtSrvPort",
                        "webServerPort"
                    ]
                }
            },
            "MultipleRxSettings": {
                "DeviceConfigurationGroupTitle": {
                    "type": "title",
                    "title": "DeviceConfiguration"
                },
                "DeviceNameAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Device Name Access",
                    "children": [
                        "RxNameAccess"
                    ]
                },
                /*"LocalWebManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Web Management",
                    "children": [
                        "webUserName", // Administrator Name
                        "webPassword"  // Administrator Password
                    ]
                },*/
                "Language": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Language",
                    "children": [
                        "language" // Language
                    ]
                },
                "Timezone": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Time zone",
                    "children": [
                        "timezoneItem", // Time zone
                        "dstTimeEnable"
                    ]
                },
                /*"WirelessDisplayMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless display mode to support client devices",
                    "children": [
                        "WiFiOperateMode"
                    ]
                },
                "Infracast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Infracast",
                    "children": [
                        "infracastEnable"
                    ]
                },
                "MacOSandWin7": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "macOS and Windows 7",
                    "children": [
                        "MacInfracastEn"
                    ]
                },
                "MacAirplay": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Mac Airplay",
                    "children": [
                        "airPlayEn"
                    ]
                },
                "OperatingChannel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Operating Channel",
                    "children": [
                        "autoGoChannel"
                    ]
                },
                "TransmitPower": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Transmit Power",
                    "children": [
                        "txPowerItem"
                    ]
                },
                "AllowForceDisconnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Allow Force Disconnection",
                    "children": [
                        "hijackingEn"
                    ]
                },*/
                "FeaturesGroupTitle": {
                    "type": "title",
                    "title": "Features"
                },
                "PIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PIN",
                    "children": [
                        "pinEnforceEnable",
                        "pinEachConnEnable",
                        "staticPinEnable",
                        "staticPinVal"
                    ]
                },
                /*"PinDisplayPeriod": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Pin Display Period",
                    "children": [
                        "PINDisplayPeriod"
                    ]
                },*/
                "HdmiPortPowerManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI/VGA Port Power Management",
                    "children": [
                        "hdmiPortPowerManagement",
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ]
                },
                "HdcpEncryption": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDCP Encryption",
                    "children": [
                        "HDCPEncrypMode"
                    ]
                },
                "USBLocalAccessSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "USB Local Access Settings",
                    "children": [
                        "usbLocalAccessCtrl",
                        "UIBCEnable",
                        "UOIPEnable"
                    ]
                },
                "TVAudioVideoSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Audio/Video Settings",
                    "children": [
                        "VideoMaxResolution",
                        "AudioSpeakerChs"
                    ]
                },
                "TVScreenSize": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Screen Size",
                    "children": [
                        "newOverScanVal",
                        "rtOverscanEnable"
                    ]
                },
                "HDMI-CEC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI-CEC",
                    "children": [
                        "CECEnable"
                    ]
                },
                "FirmwareUpgrade": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Firmware Upgrade",
                    "children": [
                        "fwupdateAccess"
                    ]
                },
                "MiracastoverNetwork": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast over Network",
                    "children": [
                        "infracastEnable"
                    ]
                },
                "InfracastRequirePIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Infracast Require PIN",
                    "children": [
                        "infracastPinEn"
                    ]
                },
                "DTLSSupport": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "DTLSSupport",
                    "children": [
                        "DTLSSupport"
                    ]
                },
                "InfracastIP": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Infracast IP",
                    "children": [
                        "infracastIPEnable"
                    ]
                },
                "NetworkSettingsGroupTitle": {
                    "type": "title",
                    "title": "NetworkSettings"
                },
                "NetworkInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Information",
                    "children": [
                        "showMoreInfoEnable"
                    ]
                },
                "HideReadyInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Hide Ready Info",
                    "children": [
                        "hideReadyInfoEnable"
                    ]
                },
                "NetworkNameBroadcast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name Broadcast",
                    "children": [
                        "LMIBeaconEnable"
                    ]
                },
                "P2PInterface": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Interface",
                    "children": [
                        "p2pInterfaceEnable",
                        "be_channelPlan",
                        "preOpChannelList",
                        "opChannelList"
                    ],
                    "dataToSend": [
                        "p2pInterfaceEnable",
                        "preOpChannelList",
                        "opChannelList"
                    ]
                },
                "WebPort": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "WEB Communication Port",
                    "children": [
                        "webServerPort"

                    ]
                }
            },
            "MultipleRxSettingsForUSB": {
                "DeviceConfigurationGroupTitle": {
                    "type": "title",
                    "title": "DeviceConfiguration"
                },
                "DeviceNameAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Device Name",
                    "children": [
                        "RxNameAccess",
                        "RxName"
                    ]
                },

                "LocalWebManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Web Management",
                    "children": [
                        "webUserName", // Administrator Name
                        "webPassword"  // Administrator Password
                    ]
                },
                "Language": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Language",
                    "children": [
                        "language" // Language
                    ]
                },
                "HostName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Host Name",
                    "children": [
                        "RXHostname"
                    ]
                },
                "Timezone": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Time zone",
                    "children": [
                        "timezoneItem", // Time zone
                        "dstTimeEnable"
                    ]
                },
                "FeaturesGroupTitle": {
                    "type": "title",
                    "title": "Features"
                },
                "PIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PIN",
                    "children": [
                        "pinEnforceEnable",
                        "pinEachConnEnable",
                        "staticPinEnable",
                        "staticPinVal"
                    ]
                },
                "HdmiPortPowerManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI/VGA Port Power Management",
                    "children": [
                        "hdmiPortPowerManagement",
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ]
                },
                "HdcpEncryption": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDCP Encryption",
                    "children": [
                        "HDCPEncrypMode"
                    ]
                },
                "USBLocalAccessSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "USB Local Access Settings",
                    "children": [
                        "usbLocalAccessCtrl",
                        "UIBCEnable",
                        "UOIPEnable"
                    ]
                },
                "TVAudioVideoSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Audio/Video Settings",
                    "children": [
                        "VideoMaxResolution",
                        "AudioSpeakerChs"
                    ]
                },
                "TVScreenSize": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Screen Size",
                    "children": [
                        "newOverScanVal",
                        "rtOverscanEnable"
                    ]
                },
                "HDMI-CEC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI-CEC",
                    "children": [
                        "CECEnable"
                    ]
                },
                "FirmwareUpgrade": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Firmware Upgrade",
                    "children": [
                        "fwupdateAccess"
                    ]
                },
                "MiracastoverNetwork": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast over Network",
                    "children": [
                        "infracastEnable"
                    ]
                },
                "InfracastRequirePIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Infracast Require PIN",
                    "children": [
                        "infracastPinEn"
                    ]
                },
                "DTLSSupport": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "DTLSSupport",
                    "children": [
                        "DTLSSupport"
                    ]
                },
                "InfracastIP": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Infracast IP",
                    "children": [
                        "infracastIPEnable"
                    ]
                },
                "NetworkSettingsGroupTitle": {
                    "type": "title",
                    "title": "NetworkSettings"
                },
                "NetworkInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Information",
                    "children": [
                        "showMoreInfoEnable"
                    ]
                },
                "HideReadyInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Hide Ready Info",
                    "children": [
                        "hideReadyInfoEnable"
                    ]
                },
                "WirelessLocalManagementInterfaceSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless Local Management Interface Settings",
                    "children": [
                        "ApName",
                        "ApPassword"
                    ]
                },
                "NetworkNameBroadcast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name Broadcast",
                    "children": [
                        "LMIBeaconEnable"
                    ]
                },
                "P2PInterface": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Interface",
                    "children": [
                        "p2pInterfaceEnable",
                        "be_channelPlan",
                        "preOpChannelList",
                        "opChannelList"
                    ],
                    "dataToSend": [
                        "p2pInterfaceEnable",
                        "preOpChannelList",
                        "opChannelList"
                    ]
                },
                "ManagementAccessGroupTitle": {
                    "type": "title",
                    "title": "ManagementAccess"
                },
                "CMSSettingsforReceiver": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "CMS Settings for Receiver",
                    "children": [
                        "mgtSrvPort",
                        "mgtSrvFullAddr"

                    ]
                },
                "WebPort": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "WEB Communication Port",
                    "children": [
                        "webServerPort"

                    ]
                },
                "TCPIPSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TCP/IP Settings",
                    "children": [
                        "ethDhcpEnable",
                        "ethIpaddr",
                        "ethSubnet",
                        "defaultGateway",
                        "DNSPolicy",
                        "primaryDNS",
                        "secondDNS"
                    ]
                },
                "SetBackgroundGroupTitle": {
                    "type": "title",
                    "title": "SetBackground"
                },
                "SetBackground": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Set Background",
                    "children": [
                        "BackgroundCustom"
                    ]
                }
            },
            "AllSettings": {
                /* Device Name Access - RADIO BUTTON */
                "RxNameAccess": {
                    "type": "radioButton",
                    "label": "DeviceNameAccess",
                    "info": "",
                    //"disabled": false,
                    "value": "1",
                    "options": {
                        "Enable": "0",
                        "Disable": "1"
                    }
                },
                /* Device Name - INPUT TEXT */
                "RxName": {
                    "type": "input",
                    "value": "",
                    "label": "DeviceName",
                    "info": "",
                    "display": "0",
                    "dependencies": ["RxNameAccess"],
                    "dependencyMap": {
                        "0": "0",
                        "1": "2"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 32) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value[0] === " " || value[value.length - 1] === " ") {
                            return "EmptyDeviceNameError";
                        }
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/]+$/.test(value)) {
                            return "InvalidDeviceNameError";
                        }
                    }
                },
                /* Manufacture Name - INPUT TEXT */
                "manufacName": {
                    "type": "input",
                    "value": "",
                    "label": "ManufactureName",
                    "info": "",
                    "display": "2"
                },
                /* Model Name - INPUT TEXT */
                "mdNameLong": {
                    "type": "input",
                    "value": "",
                    "label": "ModelName",
                    "info": "",
                    "display": "2"
                },
                /* Model Name Short - INPUT TEXT */
                "mdNameShort": {
                    "type": "input",
                    "value": "",
                    "label": "ModelNameShort",
                    "info": "",
                    "display": "2"
                },
                /* Hardware Version - INPUT TEXT */
                "hwVersion": {
                    "type": "input",
                    "value": "",
                    "label": "HardwareVersion",
                    "info": "",
                    "display": "2"
                },
                /* Firmware Version - INPUT TEXT */
                "fwVersion": {
                    "type": "input",
                    "value": "",
                    "label": "FirmwareVersion",
                    "info": "",
                    "display": "2"
                },
                /* Admin Username - INPUT TEXT */
                "webUserName": {
                    "type": "input",
                    "value": "Administrator",
                    "label": "AdminUsername",
                    "info": "",
                    "display": "0",
                    "checkbox": "LocalWebManagement",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyUsernameError";
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    }
                },
                /* Admin Password - INPUT PASSWORD*/
                "webPassword": {
                    "type": "password",
                    "value": "",
                    "label": "AdminPassword",
                    "info": "",
                    "display": "0",
                    "checkbox": "LocalWebManagement",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyPasswordError";
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidPasswordError";
                        }
                    }
                },
                /* Language - DROPDOWN */
                "language": {
                    "type": "dropdown",
                    "checkbox": "Language",
                    "value": "en",
                    "label": "Language",
                    "info": "",
                    "display": "0",
                    "options": {
                        "English": "en",
                        "简体中文": "zhcs",
                        "正體中文": "zhct",
                        "日本語": "ja",
                        "Français": "fr",
                        "Deutsch": "ge",
                        "Nederlands": "du",
                        "한국어": "ko",
                        "Español": "sp",
                        "Italiano": "it",
                        "Русский": "ru",
                    }
                },
                /* Host Name - INPUT TEXT */
                "RXHostname": {
                    "type": "input",
                    "value": "",
                    "label": "HostName",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (value.length <= 63) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyHostNameError";
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidHostName";
                        }
                        if (value[0] === "-" || value[value.length - 1] === "-") {
                            return "InvalidHostName1";
                        }
                    }
                },
                /* Time zone - DROPDOWN */
                "timezoneItem": {
                    "type": "dropdown",
                    "checkbox": "Timezone",
                    "value": "5",
                    "label": "Timezone",
                    "info": "",
                    "display": "0",
                    "options": {
                        "(UTC - 12:00) International Date Line West": "0",
                        "(UTC - 11:00) American Samoas": "1",
                        "(UTC - 10:00) Honolulu": "2",
                        "(UTC - 09:30) French Polynesia": "3",
                        "(UTC - 09:00) Anchorage": "4",
                        "(UTC - 08:00) Pacific Time": "5",
                        "(UTC - 07:00) Mountain Time": "6",
                        "(UTC - 06:00) Central Time": "7",
                        "(UTC - 05:00) Eastern Time": "8",
                        "(UTC - 04:30) Caracas": "9",
                        "(UTC - 04:00) Atlantic Time": "10",
                        "(UTC - 03:30) Newfoundland": "11",
                        "(UTC - 03:00) Buenos Aires": "12",
                        "(UTC - 02:00) South Sandwish Island": "13",
                        "(UTC - 01:00) Azores Islands": "14",
                        "(UTC + 00:00) Greenwich": "15",
                        "(UTC + 01:00) Amsterdam": "16",
                        "(UTC + 02:00) Cairo": "17",
                        "(UTC + 03:00) Moscow": "18",
                        "(UTC + 03:30) Tehran": "19",
                        "(UTC + 04:00) Dubai": "20",
                        "(UTC + 04:30) Kabul": "21",
                        "(UTC + 05:00) Islamabad": "22",
                        "(UTC + 05:30) New Delhi": "23",
                        "(UTC + 05:45) Katmandu": "24",
                        "(UTC + 06:00) Dhaka": "25",
                        "(UTC + 06:30) Rangoon": "26",
                        "(UTC + 07:00) Bangkok": "27",
                        "(UTC + 08:00) Beijing": "28",
                        "(UTC + 09:00) Tokyo": "29",
                        "(UTC + 09:30) Adelaide": "30",
                        "(UTC + 10:00) Sydney": "31",
                        "(UTC + 11:00) Solomon Island": "32",
                        "(UTC + 12:00) Auckland": "33",
                        "(UTC + 13:00) Samoa": "34",
                        "(UTC + 14:00) Kiritimati Island": "35"
                    },
                    "onChange": (_this, value) => {
                        // To make timezone setting work, CMS Service needs to know this piece of information
                        _this.changeMap["dstTimeEnable"] = _this.state.settings["dstTimeEnable"].value;
                    }
                },
                /* Daylight - CHECKBOX */
                "dstTimeEnable": {
                    "type": "checkbox",
                    "value": "0",
                    "label": "DaylightSavings",
                    "info": "",
                    "display": "0",
                    "onChange": (_this, value) => {
                        // To make timezone setting work, CMS Service needs to know this piece of information
                        _this.changeMap["timezoneItem"] = _this.state.settings["timezoneItem"].value;
                    }
                },
                /* Wireless Display Mode Title - TITLE */
                "WirelessDisplayModeTitle": {
                    "type": "title",
                    "title": "WirelessDisplayModeTitle",
                    "display": "0"
                },
                /* Wireless Display Mode - DROPDOWN*/
                "WiFiOperateMode": {
                    "type": "dropdown",
                    "value": "5",
                    "label": "WirelessDisplayMode",
                    "info": "",
                    "display": "0",
                    "options": {
                        "CommanderMode": "1",
                        "StandardWirelessDisplay": "5"
                    },
                    "onChange": (_this) => {
                        var value = _this.state.settings.WiFiOperateMode.value;
                        if (value === "1") {
                            _this.setState({
                                "tabHeaders": [
                                    "DeviceConfiguration",
                                    "Features",
                                    "NetworkSettings",
                                    "ManagementAccess",
                                    "AdvancedSettings"
                                ]
                            });
                        }
                        else {
                            _this.setState({
                                "tabHeaders": [
                                    "DeviceConfiguration",
                                    "Features",
                                    "NetworkSettings",
                                    "LocalWifi",
                                    "ManagementAccess",
                                    "AdvancedSettings"
                                ]
                            });
                        }
                    }
                },
                /* Wireless Display over LAN Title - TITLE */
                "WirelessDisplayOverLANTitle": {
                    "type": "title",
                    "title": "WirelessDisplayOverLan",
                    "display": "0",
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* Windows 10 - RADIO BUTTON */
                "infracastEnable": {
                    "type": "radioButton",
                    "label": "MiracastoverNetwork",
                    "info": "Windows10Info",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* Infracast Require PIN  - RADIO BUTTON */
                "infracastPinEn": {
                    "type": "radioButton",
                    "label": "InfracastRequirePIN",
                    "info": "",
                    "display": "0",
                    "checkbox": "InfracastRequirePIN",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode", "infracastEnable"],
                    "dependencyMap": {
                        "11": "1",
                        "10": "1",
                        "51": "0",
                        "50": "2",
                    }
                },
                /* macOS and windows 7 - RADIO BUTTON */
                "MacInfracastEn": {
                    "type": "radioButton",
                    "label": "MacWin7",
                    "info": "MacWin7Info",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* macOS or iOS native screen mirroring - RADIO BUTTON */
                "airPlayEn": {
                    "type": "radioButton",
                    "label": "AirplayMode",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* P2P wireless setting title - TITLE */
                "P2PWirelessSettingTitle": {
                    "type": "title",
                    "title": "P2PWirelessSettings",
                    "display": "0"
                },
                /* P2P Operating Channel ----- NEED TO DO */
                /* Transmit Power - DROPDOWN */
                "txPowerItem": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "TransmitPower",
                    "info": "",
                    "display": "0",
                    "options": {
                        "High": "2",
                        "Medium": "1",
                        "Low": "0"
                    }
                },
                /* Security Setting title - TITLE */
                "SecuritySettingTitle": {
                    "type": "title",
                    "title": "SecuritySettings",
                    "display": "0"
                },
                /* Force PIN Pairing on First Connection - RADIO BUTTON*/
                "pinEnforceEnable": {
                    "type": "radioButton",
                    "label": "ForcePinPairing",
                    "info": "ForcePinPairingInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    },
                    "onStart": (settings, data) => {
                        if (settings["pinEnforceEnable"].value === "1") {
                            settings["pinEnforceEnable"].info = "ForcePinPairingInfo";
                        }
                        else {
                            settings["pinEnforceEnable"].info = "ForcePinPairingOffInfo";
                        }

                        return settings["pinEnforceEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        if (_this.state.settings["pinEnforceEnable"].value === "1") {
                            setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingInfo");
                        } else {
                            setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingOffInfo");
                        }
                    }
                },
                /* Require PIN on - RADIO BUTTON */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "pinEachConnEnable": {
                    "type": "radioButton",
                    "label": "RequirePinOn",
                    "info": "RequirePinOnInfo",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "EachConnection": "1",
                        "FirstConnection": "0"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onStart": (settings, data) => {
                        if (settings["pinEachConnEnable"].value === "1") {
                            settings["pinEachConnEnable"].info = "RequirePinOnEachConnInfo";
                        }
                        else {
                            settings["pinEachConnEnable"].info = "RequirePinOnInfo";
                        }

                        return settings["pinEachConnEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        if (_this.state.settings["pinEachConnEnable"].value === "1") {
                            setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnEachConnInfo");
                        } else {
                            setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnInfo");
                        }
                    }
                },
                /* PIN Length - DROPDOWN */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "fourDigitPINEn": {
                    "type": "dropdown",
                    "label": "PinLength",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "8Digit": "0",
                        "4Digit": "1"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        var strtemp = "";
                        var staticPinMaxLen = 4;
                        if (_this.state.settings["fourDigitPINEn"].value === "1") {
                            if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length < 4) {
                                strtemp = "StaticPin4Error";
                            }
                        }
                        else {
                            staticPinMaxLen = 7;
                            if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length !== 7) {
                                strtemp = "StaticPin8Error";
                            }
                        }
                        if (_this.state.settings["staticPinEnable"].value === "1") {
                            setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen,
                                () => {
                                    setSettingState(_this, "staticPinVal", "error", strtemp);
                                });
                        } else {
                            setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen);
                        }
                    }
                },
                /* PIN Generation Method - RADIO BUTTON */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "staticPinEnable": {
                    "type": "radioButton",
                    "label": "PinGenerationMethod",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Static": "1",
                        "Random": "0"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        /*if (_this.state.settings["staticPinEnable"].value === "1") {
                            setSettingState(_this, "staticPinEnable", "info", "PinGenerationMethodStaticInfo");
                        }
                        else {
                            setSettingState(_this, "staticPinEnable", "info", "PinGenerationMethodInfo");
                        }*/
                    }
                },
                /* Static PIN Val - INPUT TEXT */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "staticPinVal": {
                    "type": "pin",
                    "value": "1234567",
                    "label": "StaticPin",
                    "info": "StaticPin8Info",
                    "pinLength": "fourDigitPINEn",
                    "display": "0",
                    "maxLength": "7",
                    "dependencies": ["pinEnforceEnable", "staticPinEnable"],
                    "dependencyMap": {
                        "10": "1",
                        "00": "1",
                        "01": "1"
                    },
                    "onStart": (settings, data) => {
                        if (data["fourDigitPINEn"] === "0")
                            settings["staticPinVal"].maxLength = "7";
                        return settings["staticPinVal"];
                    },
                    "preValidate": (value, _this) => {
                        if (/^[0-9]+$/.test(value) || value === "") {
                            /*if (_this.state.settings["fourDigitPINEn"].value === "1" && value.length <= 4) {
                                return true;
                            }
                            if (_this.state.settings["fourDigitPINEn"].value === "0" && value.length <= 7) {
                                return true;
                            }*/
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        if (_this.state.settings["fourDigitPINEn"].value === "0") {
                            /*setSettingState(_this,
                                "fourDigitPINEn",
                                "info",
                                "Enter the first 7 digits of the PIN - the 8th digit will be generated for you. Static PIN will not appear on the HDTV/Projector to enable protected mode.");*/
                            if (_this.state.settings["staticPinVal"].value.length === 7) {
                                setSettingState(_this,
                                    "staticPinVal",
                                    "info", "StaticPin8Info");
                            }
                        }
                    },
                    "onSubmit": (_this) => {
                        // Slice the value if it is 4-digit PIN
                        if (_this.state.settings["fourDigitPINEn"].value == "1" && _this.state.settings["staticPinVal"].value.length > 4) {
                            _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value.slice(0, 4);
                        }
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["fourDigitPINEn"].value === "1" && value.length !== 4) {
                            return "StaticPin4Error";
                        }
                        if (_this.state.settings["fourDigitPINEn"].value === "0" && value.length !== 7) {
                            return "StaticPin8Error";
                        }
                    }
                },
                /* PIN Display Period - INPUT TEXT */
                "PINDisplayPeriod": {
                    "type": "input",
                    "value": "60",
                    "label": "PinDisplayPeriod",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (/^[0-9]+$/.test(value) || value === "") {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value > 120 || value < 30 || value === "") {
                            return "PinDisplayPeriodError";
                        }
                    }
                },
                /* Display Setting Title - TITLE */
                "DisplaySettingTitle": {
                    "type": "title",
                    "title": "DisplaySettings",
                    "display": "0"
                },
                /* ScreenBeam Network Info - DROPDOWN */
                "showMoreInfoEnable": {
                    "type": "radioButton",
                    "value": "1",
                    "label": "ScreenBeamNetworkInfo960",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Connection Instruction - RADIO BUTTON */
                "hideReadyInfoEnable": {
                    "type": "radioButton",
                    "label": "HideReadyInfo",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* HDMI/VGA Port Power Management */
                "hdmiPortPowerManagement": {
                    "type": "dropdown",
                    "label": "HdmiVgaManagement",
                    "value": "01",
                    "display": "0",
                    "info": "HdmiVgaManagementInfo",
                    "isNotInOriginalData": true,
                    "options": {
                        "AlwaysOn": "01",
                        "ScreenSaver": "11",
                        "DisplayOff": "10"
                    },
                    "onStart": (settings, data) => {
                        let scrSaverEnable = data["scrSaverEnable"];
                        let scrHdmiOutEnable = data["scrHDMIOutEnable"];
                        settings["hdmiPortPowerManagement"].value = scrSaverEnable + scrHdmiOutEnable;
                        //console.log(settings["hdmiPortPowerManagement"].value);
                        return settings["hdmiPortPowerManagement"];
                    },
                    "onChange": (_this) => {
                        let selection = _this.state.settings["hdmiPortPowerManagement"].value;

                        _this.changeMap["scrSaverEnable"] = selection[0];
                        _this.changeMap["scrHDMIOutEnable"] = selection[1];
                        _this.changeMap["scrSaverSecond"] = _this.state.settings["scrSaverSecond"].value;
                        delete _this.changeMap["hdmiPortPowerManagement"];
                        _this.setState({
                            settings: {
                                ..._this.state.settings,
                                "scrHDMIOutEnable": {
                                    ..._this.state.settings["scrHDMIOutEnable"],
                                    value: selection[1]
                                },
                                "scrSaverEnable": {
                                    ..._this.state.settings["scrSaverEnable"],
                                    value: selection[0]
                                }
                            }
                        });
                    }
                },
                "scrHDMIOutEnable": {
                    "value": "1"
                },
                "scrSaverEnable": {
                    "value": "0"
                },
                /* Wait - Triggered when HDMI/VGA selects Screensaver or Display Off - INPUT TEXT */
                "scrSaverSecond": {
                    "type": "input",
                    "label": "Wait",
                    "info": "WaitInfo",
                    "display": "1",
                    "value": "180",
                    "dependencies": ["hdmiPortPowerManagement"],
                    "dependencyMap": {
                        "01": "1",
                        "11": "0",
                        "10": "0"
                    },
                    "validate": (value) => {
                        if (value == "")
                            return "ScreenSaverWaitEmptyError"
                    }
                },
                /* Wake up - DROPDOWN */
                "wakedByScan": {
                    "type": "dropdown",
                    "label": "Wakeup",
                    "info": "",
                    "value": "0",
                    "options": {
                        "OnConnect": "0",
                        "OnScan": "1"
                    },
                    "display": "2",
                    "dependencies": ["hdmiPortPowerManagement"],
                    "dependencyMap": {
                        "01": "2",
                        "11": "0",
                        "10": "0"
                    }
                },
                /* Adjust TV Screen Size - DROPDOWN */
                "newOverScanVal": {
                    "type": "dropdown",
                    "value": "25",
                    "label": "AdjustTvScreenSize",
                    "info": "",
                    "display": "0",
                    "options": {
                        "0": "0",
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "14": "14",
                        "15": "15",
                        "16": "16",
                        "17": "17",
                        "18": "18",
                        "19": "19",
                        "20": "20",
                        "21": "21",
                        "22": "22",
                        "23": "23",
                        "24": "24",
                        "25": "25"
                    },
                    "onSubmit": (_this) => {
                        // Need to send rtOverscanEnable together
                        _this.changeMap["rtOverscanEnable"] = _this.state.settings["rtOverscanEnable"].value;
                    }
                },
                /* Allow source device to override overscan value */
                "rtOverscanEnable": {
                    "type": "checkbox",
                    "label": "OverrideOverScanVal",
                    "info": "",
                    "display": "0",
                    "value": "0"
                },
                /* Receiver Logging - RADIO BUTTON */
                "RxCsvLogEn": {
                    "type": "radioButton",
                    "label": "ReceiverLogging",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Network Mode - DROPDOWN */
                "NetworkMode": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "NetworkMode",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Nat": "1",
                        "Bridge": "2",
                        "None": "0"
                    }
                },
                /* Local Wifi Network Title - TITLE */
                "LocalWifiNetworkTitle": {
                    "type": "title",
                    "title": "LocalWifiNetwork",
                    "display": "0"
                },
                /* Interface Feature Assignment Title - TITLE */
                "InterfaceFeatureTitle": {
                    "type": "title",
                    "title": "InterfaceFeatureAssignment",
                    "display": "0"
                },
                /* CMS Interface - DROPDOWN */
                "cmsInterface": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "CmsInterface",
                    "info": "",
                    "display": "2",
                    "options": {
                        "Auto": "0"
                    },
                    "dependencies": ["NetworkMode"],
                    "dependencyMap": {
                        "0": "1"
                    }
                },
                /* Internet WAN Interface - DROPDOWN */
                "apBridgeInterface": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "InternetWanInterface",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Ethernet": "1",
                        "Wireless": "2"
                    }
                },
                /* Network Interface Settings Title - TITLE */
                "NetworkInterfaceTitle": {
                    "type": "title",
                    "title": "NetworkInterfaceSettings",
                    "display": "0"
                },
                /* Ethernet Interface Subtitle - SUBTITLE */
                "EthernetInterfaceSubtitle": {
                    "type": "subtitle",
                    "title": "EthernetInterface",
                    "display": "0"
                },
                /* Network Name - INPUT TEXT */
                "EthNetworkName": {
                    "type": "input",
                    "label": "NetworkName",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyNetworkNameError";
                        }
                        if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                            return "InvalidNetworkNameError";
                        }
                    }
                },
                /* Connection Settings Subtitle - SUBTITLE */
                "ConnectionSettingsSubtitle": {
                    "type": "subtitle",
                    "title": "ConnectionSettings",
                    "display": "0"
                },
                /* Ethernet Connection Authentication - DROPDOWN */
                "CurEthAuthentication": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "Authentication",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "PEAP-MSCHAPV2": "2",
                        "EAP-TLS": "3"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                            _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                /* Ethernet Connection Username - INPUT TEXT */
                // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
                "CurEthRadiusID": {
                    "type": "input",
                    "value": "",
                    "label": "Username",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "0",
                        "3": "0"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurEthAuthentication"].value == "2"
                            || _this.state.settings["CurEthAuthentication"].value == "3") {
                            if (value.length == 0) return "EmptyUsernameError";
                            if (!(/^[a-zA-Z0-9\s._@#$-]+$/.test(value))) {
                                return "InvalidUsernameError";
                            }
                        }
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                /* Ethernet Connection Password - INPUT PASSWORD */
                // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
                "CurEthRadiusPWD": {
                    "type": "password",
                    "value": "",
                    "label": "Password",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "0",
                        "3": "0"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurEthAuthentication"].value == "2"
                            || _this.state.settings["CurEthAuthentication"].value == "3") {
                            if (value.length == 0) return "EmptyPasswordError";
                            if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                                return "InvalidPasswordError";
                            }
                        }
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                // NEED TO ADD SYSTEM DATE & TIME, CA CERTIFICATE, USER CERTIFICATE, PRIVATE KEY, VALIDITY PERIOD
                /* System Date & Time */
                "EthCertDateTime": {
                    "type": "input",
                    "label": "SystemDateTime",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - CA Certificate URL - DISABLED INPUT TEXT */
                "EthRootCertURL": {
                    "type": "input",
                    "label": "CaCertificateUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                    //"type": "input",
                    //"label": "CA Certificate URL",
                    //"info": "",
                    //"disabled": true,
                    //"displayMode": { "controlItem": "CurEthAuthentication", "controlValue": ["0", "2"], "display": "1" }
                },
                /* Ethernet - User Certificate - DISABLED INPUT TEXT */
                "EthUserCertURL": {
                    "type": "input",
                    "label": "UserCertificateUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - Private Key URL - DISABLED INPUT TEXT */
                "EthUserKeyURL": {
                    "type": "input",
                    "label": "PrivateKeyUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - Validity Period - INPUT TEXT */
                "EthCertsValidity": {
                    "type": "input",
                    "label": "ValidityPeriod",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* TCP/IP Settings Subtitle - SUBTITLE */
                "TCPIPSettingsSubtitle": {
                    "type": "subtitle",
                    "title": "TcpIpSettings",
                    "display": "0"
                },
                /* Ethernet IP Assignment - RADIO BUTTON */
                "ethAutoIpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                            _this.changeMap["ethAutoDnsEnable"] = "0";
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;

                            _this.setState({
                                settings: {
                                    ..._this.state.settings,
                                    "ethAutoDnsEnable": {
                                        ..._this.state.settings["ethAutoDnsEnable"],
                                        value: "0"
                                    }
                                }
                            });
                        }

                    }
                },
                /* Ethernet IP Address - INPUT IP */
                "ethIpAddress": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }

                    }
                },
                /* Ethernet Subnet Mask - INPUT IP */
                "ethNetmask": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }

                    }
                },
                /* Default Gateway - INPUT IP */
                "ethGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }

                    }
                },
                /* DNS Assignment - RADIO BUTTON */
                "ethAutoDnsEnable": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            //_this.changeMap["ethAutoDnsEnable"] = "0";
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },
                /* Ethernet Primary DNS Server */
                "ethPrimaryDns": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["ethAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },
                /* Ethernet Secondary DNS Server */
                "ethSecondaryDns": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["ethAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },
                /* Wireless Interface Subtitle - SUBTITLE */
                "WirelessInterfaceSubtitle": {
                    "type": "subtitle",
                    "title": "WirelessInterface",
                    "display": "0"
                },
                /* Network Name - INPUT TEXT */
                "CurConnectApName": {
                    "type": "input",
                    "label": "NetworkName",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyNetworkNameError";
                        }
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];,\.\/]+$/.test(value)) {
                            return "InvalidNetworkNameError";
                        }
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    }
                },
                /* Security Type - DROPDOWN */
                "CurConnectApSecType": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "SecurityType",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "Shared": "1",
                        "WPA-PSK[TKIP]": "2",
                        "WPA2-PSK[AES]": "3",
                        "WPA-PSK[TKIP]+WPA2-PSK[AES]": "4",
                        "PEAP-MSCHAPV2": "5",
                        "EAP-TLS": "6"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    }
                },
                /* Username - INPUT TEXT */
                "CurConnectApRadiusId": {
                    "type": "input",
                    "value": "",
                    "label": "Username",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "0",
                        "6": "0"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurConnectApSecType"].value == "5" || _this.state.settings["CurConnectApSecType"].value == "6") {
                            if (value.length == 0) return "EmptyUsernameError";
                            if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                                return "InvalidUsernameError";
                            }
                        }
                    }
                },
                /* Password - INPUT PASSWORD */
                "CurConnectApPwd": {
                    "type": "password",
                    "value": "",
                    "label": "Password",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "0",
                        "2": "0",
                        "3": "0",
                        "4": "0",
                        "5": "0",
                        "6": "0"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurConnectApSecType"].value == "1"
                            || _this.state.settings["CurConnectApSecType"].value == "2"
                            || _this.state.settings["CurConnectApSecType"].value == "3"
                            || _this.state.settings["CurConnectApSecType"].value == "4"
                            || _this.state.settings["CurConnectApSecType"].value == "5"
                            || _this.state.settings["CurConnectApSecType"].value == "6") {
                            if (value.length == 0) return "EmptyPasswordError";
                            if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                                return "InvalidPasswordError";
                            }
                        }
                    }
                },
                /* Wireless - Date Time - DISABLED INPUT */
                "TLSCertDateTime": {
                    "type": "input",
                    "value": "",
                    "label": "SystemDateTime",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - CA Certificate URL - DISABLE INPUT */
                "TLSRootCertURL": {
                    "type": "input",
                    "value": "",
                    "label": "CaCertificateUrl",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - User Certificate URL - DISABLE INPUT */
                "TLSUserCertURL": {
                    "type": "input",
                    "value": "",
                    "label": "UserCertificateUrl",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - Private Key URL - DISABLE INPUT */
                "TLSUserKeyURL": {
                    "type": "input",
                    "value": "",
                    "label": "PrivateKeyUrl",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless IP Assignment - RADIO BUTTON */
                "staAutoIpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                            _this.changeMap["staAutoDnsEnable"] = "0";
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;

                            _this.setState({
                                settings: {
                                    ..._this.state.settings,
                                    "staAutoDnsEnable": {
                                        ..._this.state.settings["staAutoDnsEnable"],
                                        value: "0"
                                    }
                                }
                            });
                        }
                    }
                },
                /* Wireless IP Address - INPUT IP */
                "staIpAddress": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless Subnet Mask - INPUT IP */
                "staNetmask": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless Default Gateway - INPUT IP */
                "staGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless DNS Assignment - RADIO BUTTON */
                "staAutoDnsEnable": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            //_this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Wireless Primary DNS Server - INPUT IP */
                "staPrimaryDns": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["staAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Wireless Secondary DNS Server - INPUT IP */
                "staSecondaryDns": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["staAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Local-Wifi Settings Title - TITLE */
                "LocalWifiSettingsTitle": {
                    "type": "title",
                    "title": "LocalWifiSettings",
                    "display": "0"
                },
                /* ScreenBeam Local Wi-Fi - RADIO BUTTON */
                "ApBsS1Enable": {
                    "type": "radioButton",
                    "label": "ScreenBeamLocalWifi",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                        _this.changeMap["ApBsSSIDIndex"] = "1";
                        delete _this.changeMap["ApBsS1Enable"];
                    }
                },
                /* Wireless Network Name - INPUT TEXT*/
                "ApBsS1Name": {
                    "type": "input",
                    "label": "WirelessNetworkName",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "dependencies": ["ApBsS1Enable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 32) {
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSName"] = _this.changeMap["ApBsS1Name"]
                        _this.changeMap["ApBsSSIDIndex"] = "1"
                        delete _this.changeMap["ApBsS1Name"]
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value
                        _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                    },
                    "validate": (value) => {
                        if (value.length == 0) {
                            return "EmptyUsernameError"
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidUsernameError"
                        }
                    }
                },
                /* Wireless Security Type - DROPDOWN */
                "ApBsS1SecurityType": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "SecurityType",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "WPA2 PSK": "3"
                    },
                    "dependencies": ["ApBsS1Enable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSSecurityType"] = _this.changeMap["ApBsS1SecurityType"]
                        _this.changeMap["ApBsSSIDIndex"] = "1"
                        delete _this.changeMap["ApBsS1SecurityType"]
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                        _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value
                        _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                    }
                },
                /* Password Phrase - INPUT PASSWORD */
                "ApBsS1Passwd": {
                    "type": "password",
                    "value": "",
                    "label": "PasswordPhrase",
                    "info": "",
                    "display": "0",
                    "dependencies": ["ApBsS1SecurityType", "ApBsS1Enable"],
                    "dependencyMap": {
                        "00": "1",
                        "01": "1",
                        "30": "2"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSPasswd"] = _this.changeMap["ApBsS1Passwd"];
                        _this.changeMap["ApBsSSIDIndex"] = "1";
                        delete _this.changeMap["ApBsS1Passwd"];
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                        _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value;
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyPasswordError";
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidLocalWiFiAPPasswordErr";
                        }
                    }
                },
                /* Wireless Channel - DROPDOWN */
                "ApBsChannelNum": {
                    "type": "dropdown",
                    "value": "165",
                    "label": "WirelessChannel",
                    "info": "",
                    "display": "2",
                    "options": {
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                },
                /* Central Management System Settings - Title */
                "CentralMgtSystemTitle": {
                    "type": "title",
                    "title": "CentralManagementSystemSettings",
                    "display": "0"
                },
                /* CMS Server - INPUT TEXT */
                "mgtSrvFullAddr": {
                    "type": "input",
                    "value": "",
                    "label": "CmsServer",
                    "info": "",
                    "display": "2",
                    "validate": (value) => {
                        return check_cms_host(value);
                    }
                },
                /* CMS Communication Port - INPUT TEXT */
                "mgtSrvPort": {
                    "type": "input",
                    "value": "",
                    "label": "CmsCommunicationPort",
                    "info": "CmsCommunicationPortInfo",
                    "display": "2",
                    "preValidate": (value) => {
                        if (/^[0-9]*$/.test(value)) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        return check_cms_port(value);
                    }
                },
                /* Web Communication Port - INPUT TEXT */
                "webServerPort": {
                    "type": "input",
                    "value": "",
                    "label": "WebCommunicationPort",
                    "info": "WebCommunicationPortInfo",
                    "display": "0",
                    "preValidate": (value) => {
                        if (/^[0-9]*$/.test(value)) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        return check_web_port(value);
                    }
                },
                /* Local Management Wireless Access Settings Title - TITLE */
                "LocalMgtWirelessAccessTitle": {
                    "type": "title",
                    "title": "LocalManagementWirelessAccess",
                    "display": "0"
                },
                /* Local Management Interface Access - DROPDOWN */
                "webMgtOverWiFiEn": {
                    "type": "dropdown",
                    "label": "LocalManagementInterfaceAccess",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "2",
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Allow Force Disconnection - RADIO BUTTON */
                "hijackingEn": {
                    "type": "radioButton",
                    "label": "AllowForceDisconnection",
                    "info": "AllowForceDisconnectionInfo",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* PCR Synchronization - RADIO BUTTON */
                "PCRSyncEnable": {
                    "type": "radioButton",
                    "label": "PcrSync",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* HDCP Encryption - DROPDOWN */
                "HDCPEncrypMode": {
                    "type": "dropdown",
                    "label": "HdcpEncryption",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "0",
                        "Disable": "1",
                        "DemoMode": "2"
                    },
                },
                /* Aspect Ratio - DROPDOWN */
                "aspectRatio": {
                    "type": "dropdown",
                    "label": "AspectRatio",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "4:3": "0",
                        "16:9": "1",
                        "16:10": "2"
                    }
                },
                /* Horizontal/Veritcal Offset - RATIO */
                "scrHoffset": {
                    "type": "ratio",
                    "value": "0",
                    "display": "0",
                    "horizontalName": "scrHoffset",
                    "verticalName": "scrVoffset",
                    "label": "HorizontalVerticalOffset",
                    "onChange": (_this) => {
                        _this.changeMap["scrVoffset"] = _this.state.settings["scrVoffset"].value;
                    }
                },
                "scrVoffset": {
                    "value": "0",
                    "onChange": (_this) => {
                        _this.changeMap["scrHoffset"] = _this.state.settings["scrHoffset"].value;
                    }
                },
                /* Debug View - RADIO BUTTON */
                "debugViewEnable": {
                    "type": "radioButton",
                    "label": "DebugView",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    }
                },
                /* P2P IP Address - INPUT IP */
                "p2pIpAddr": {
                    "type": "ip",
                    "label": "P2PIpAddress",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P Subnet Mask - INPUT IP */
                "p2pSubnet": {
                    "type": "ip",
                    "label": "P2PSubnetMask",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P DHCP IP Start - INPUT IP */
                "p2pDhcpIpStart": {
                    "type": "ip",
                    "label": "P2PDhcpIpStart",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P DHCP IP End - INPUT IP */
                "p2pDhcpIpEnd": {
                    "type": "ip",
                    "label": "P2PDhcpIpEnd",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;

                    }
                },
                /* P2P Wireless Network Name (SSID) - Special INPUT */
                "p2pSsid": {
                    "type": "postfixInput",
                    "label": "P2PWirelessNetworkName",
                    "info": "",
                    "prefix": "DIRECT-xy",
                    "display": "0",
                    "preValidate": (value, _this) => {
                        let prefixLength = _this.state.settings["p2pSsid"].prefix.length
                        if ((value.length - prefixLength) > 23) {
                            return false
                        }
                        return true
                    },
                    "validate": (value, _this) => {
                        if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                            return "InvalidNetworkNameError";
                        }
                    }
                },
                /* P2P Wireless Network Password - INPUT PASSWORD */
                "p2pPassword": {
                    "type": "password",
                    "label": "P2PWirelessPassword",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "validate": (value) => {
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidLocalWiFiAPPasswordErr";
                        }
                    }
                },
                /* Fast-IP - RADIO BUTTON */
                "fastIPEn": {
                    "type": "radioButton",
                    "label": "FastIp",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* USB Local Access - DROPDOWN */
                "usbLocalAccessCtrl": {
                    "type": "dropdown",
                    "value": "2",
                    "label": "UsbLocalAccess",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Disable": "0",
                        "OnlyAllowFirmwareUpdate": "1",
                        "Enable": "2"
                    }
                },
                ///* User Input Back Channel (UIBC) - DROPDOWN */
                "UIBCEnable": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "Uibc",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Enable": "0",
                        "Disable": "1"
                    },
                    "dependencies": ["usbLocalAccessCtrl", "UOIPEnable"],
                    "dependencyMap": {
                        "00": "2",
                        "01": "2",
                        "02": "2",
                        "10": "2",
                        "11": "2",
                        "12": "2",
                        "20": "0",
                        "21": "2",
                        "22": "0"
                    },
                },
                /* USB Over IP (UoIP) - DROPDOWN */
                "UOIPEnable": {
                    "type": "dropdown",
                    "value": "2",
                    "label": "UoIp",
                    "info": "",
                    "display": "0",
                    "options": {
                        "NotImplemented": "0",
                        "Enable": "1",
                        "Disable": "2"
                    },
                    "dependencies": ["usbLocalAccessCtrl", "UIBCEnable"],
                    "dependencyMap": {
                        "00": "2",
                        "01": "2",
                        "10": "2",
                        "11": "2",
                        "20": "2",
                        "21": "0"
                    },
                },
                /* Maximum Video Resolution - DROPDOWN */
                "VideoMaxResolution": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "MaximumVideoResolution",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Auto": "0",
                        "1080": "1",
                        "720": "2",
                        "480": "3"
                    }
                },
                /* Audio Speaker - DROPDOWN */
                "AudioSpeakerChs": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "AudioSpeaker",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Auto": "0",
                        "2 Channels": "1",
                        "5.1 Channels": "2"
                    }
                },
                ///* HDMI-CEC - RADIO BUTTON*/
                "CECEnable": {
                    "type": "radioButton",
                    "label": "HdmiCec",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    }
                },
                /* Firmware Upgrade - RADIO BUTON */
                "fwupdateAccess": {
                    "type": "radioButton",
                    "label": "FirmwareUpgrade",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* DTLSSupport  - RADIO BUTTON */
                "DTLSSupport": {
                    "type": "radioButton",
                    "label": "DtlsSupport",
                    "info": "",
                    "value": "1",
                    "display": "0",
                    "dependencies": ["WiFiOperateMode", "infracastEnable", "infracastPinEn"],
                    "dependencyMap": {
                        "110": "1",
                        "111": "1",
                        "100": "1",
                        "101": "1",
                        "510": "0",
                        "511": "2",
                        "500": "2",
                        "501": "2"
                    },
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* Infracast IP Address - RADIO BUTTON*/
                "infracastIPEnable": {
                    "type": "radioButton",
                    "label": "InfracastIpAddress",
                    "info": "",
                    "value": "1",
                    "display": "0",
                    "dependencies": ["WiFiOperateMode", "infracastEnable"],
                    "dependencyMap": {
                        "10": "1",
                        "11": "1",
                        "50": "2",
                        "51": "0"
                    },
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* Wireless Network Name (SSID) - SB 960 - INPUT TEXT */
                "ApName": {
                    "type": "input",
                    "label": "WirelessNetworkName",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["ApPassword"] = _this.state.settings["ApPassword"].value;
                    }
                },
                /* Wireless Network Password - SB 960 - INPUT PASSWORD */
                "ApPassword": {
                    "type": "password",
                    "label": "WirelessNetworkPassword",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["ApName"] = _this.state.settings["ApName"].value;
                    }
                },
                /* Broadcast Network Name - RADIO BUTTON */
                "LMIBeaconEnable": {
                    "type": "radioButton",
                    "label": "BroadcastNetworkName",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* P2P Interface - RADIO BUTTON */
                "p2pInterfaceEnable": {
                    "type": "radioButton",
                    "label": "P2PInterface",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Ethernet IP Assignment - SB 960 - RADIO BUTTON */
                "ethDhcpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "value": "1",
                    "display": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, true);
                    }
                },
                /* Ethernet IP Address - SB 960 - INPUT IP */
                "ethIpaddr": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, false);
                    }
                },
                /* Ethernet Subnet Mask - SB 960 - INPUT IP */
                "ethSubnet": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, false);
                    }
                },
                /* Default Gateway - SB 960 - INPUT IP */
                "defaultGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, false);
                    }
                },
                /* DNS Assignment - SB 960 - RADIO BUTTON */
                "DNSPolicy": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetDNSAssignmentSettings(_this);
                    }
                },
                /* Ethernet Primary DNS Server - SB 960 - INPUT IP*/
                "primaryDNS": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["DNSPolicy"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetDNSAssignmentSettings(_this);
                    }
                },
                /* Ethernet Secondary DNS Server - SB 960 - INPUT IP */
                "secondDNS": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["DNSPolicy"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetDNSAssignmentSettings(_this);
                    }
                },
                /* P2P Operating Channel - DROPDOWN */
                "autoGoChannel": {
                    "type": "dropdown",
                    "label": "P2POperatingChannel",
                    "info": "P2POperatingChannelInfo",
                    "value": "165",
                    "display": "0",
                    "options": {
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                    //"region": "clWiFiRegionCode",
                    //"country": "clWiFiCountryCode",
                    //"wifi20Channels": "clWiFi20Channels",
                    //"wifi40Channels": "clWiFi40Channels",
                    //"wifi80Channels": "clWiFi80Channels"
                },
                /* USB Auto Config - RADIO BUTTON */
                "usbConfigEn": {
                    "type": "radioButton",
                    "label": "UsbAutoConfigure",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                "P2PInterfaceSettingTitle": {
                    "type": "title",
                    "title": "P2PInterface",
                    "display": "0"
                },
                "be_channelPlan": {
                    "type": "dropdown",
                    "value": "12",
                    "label": "CountryRegion",
                    "info": "",
                    "options": {
                        "China, Taiwan": "12",
                        "United States, Canada": "17",
                        "Europe Union, EUTA, Hong Kong, Japan": "18",
                        "Australia, Newzealand, Brazil": "127"
                    },
                    "onChange": (_this) => {
                        var opChannelList = getP2POperatingChannel(_this.state.settings["be_channelPlan"].value)
                        var channelList = opChannelList.channels
                        var arrayChannel = Object.keys(channelList);
                        var copyArray = []
                        for (var key of arrayChannel) {
                            copyArray.push(key)
                        }
                        setSettingState(_this, "opChannelList", "value", arrayChannel, () => {
                            setSettingState(_this, "preOpChannelList", "value", copyArray)
                        })
                    }
                },
                "opChannelList": {
                    "value": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "149", "153", "157", "161", "165"],
                    "onStart": (settings, data) => {
                        var value = data["opChannelList"]
                        var convertToArray = value.split(",")
                        settings["opChannelList"].value = convertToArray
                        return settings["opChannelList"]
                    },
                    "onSubmit": (_this) => {
                        var opChannelList = _this.state.settings["opChannelList"].value
                        _this.changeMap["opChannelList"] = opChannelList.toString()
                    }
                },
                "preOpChannelList": {
                    "type": "channel",
                    "opChannelList": "opChannelList",
                    "label": "OperatingChannel",
                    "info": "",
                    "value": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "149", "153", "157", "161", "165"],
                    "onStart": (settings, data) => {
                        var value = data["preOpChannelList"]
                        var convertToArray = value.split(",")
                        settings["preOpChannelList"].value = convertToArray
                        return settings["preOpChannelList"]
                    },
                    "onSubmit": (_this) => {
                        var preOpChannel = _this.state.settings["preOpChannelList"].value;
                        preOpChannel.sort(compareNumbers)
                        _this.changeMap["preOpChannelList"] = preOpChannel.toString();
                    }
                },
                "BackgroundCustom": {
                    "type": "file",
                    "label": "SetBackground",
                    "info": "BackgroundUrlInfo",
                    "value": ""
                }
            }
        };

        return Settings_9_6;
    }

    getSettingsIn9dot15() {
        let Settings_9_15 = {
            "SingleRxSettings": {
                "TabHeader": [
                    "DeviceConfiguration",
                    "Features",
                    "NetworkSettings",
                    // "LocalWifi",
                    "ManagementAccess",
                    "AdvancedSettings"
                ],
                "TabContent": {
                    "DeviceConfiguration": [
                        "RxNameAccess", // Device Name Access
                        "RxName", // Device Name
                        "manufacName", // Manufacture Name
                        "mdNameShort", // Model Name Short
                        "mdNameLong", // Model Name
                        "hwVersion", // Hardware Name
                        "fwVersion", // Firmware Version
                        "webUserName", // Administrator Name
                        "webPassword", // Administrator Password
                        "language", // Language
                        "RXHostname", // Host Name
                        "timezoneItem", // Time zone
                        "dstTimeEnable" // Daylight savings
                    ],
                    "Features": [
                        "WirelessDisplayOverLANTitle", // Wireless Display Over LAN
                        "infracastEnable", // Windows 10
                        "MacInfracastEn", // Windows 7 or Mac
                        "airPlayEn", // macOS or iOS native screen mirroring
                        "P2PWirelessSettingTitle", // P2P Wireless Setting Title
                        "be_autonomousGoEn",// P2P Connection Mode
                        "be_autoGoChannel", // P2P Operating Channel (MISSING)
                        "txPowerItem", // Transmit Power,
                        //"hijackingEn", // Allow Force Disconnection
                        "SecuritySettingTitle", // Security Setting Title
                        "pinEnforceEnable", // Force PIN Pairing on First Connection
                        "pinEachConnEnable", // Require PIN on
                        "fourDigitPINEn", // PIN Length
                        "staticPinEnable", // PIN Generation Method
                        "staticPinVal", // Static PIN Val
                        "DisplaySettingTitle", // Display Setting Title
                        "hijackingEn", //Display Sharing Mode
                        "showOverlayRxNameOpt", //Show Receiver Name
                        "overlayRxNamePlacement", //Receiver Name Placement
                        "hdmiPortPowerManagement", // HDMI/VGA component
                        "scrSaverSecond", // Wait - Triggered when HDMI/VGA selects Screensaver or Display Off
                        "wakedByScan", // Wake up
                        "VGACompMode",// VGA Compatility Mode
                        "VGADefaultFormat", // Default VGA Mode
                        "newOverScanVal", // Adjust TV Screen Size
                        "rtOverscanEnable", // Allow source device to override overscan value
                        "CECEnable", // HDMI-CEC
                        "usbConfigEn", // USB Auto Configure
                        "InactivityRebootTime", // Auto Reboot Receiver when idle for
                        "fwupdateAccess", // Firmware Upgrade via USB Autorun
                        "RxCsvLogEn" // Receiver Logging
                    ],
                    "NetworkSettings": [
                        "showMoreInfoEnable", // ScreenBeam Network Info
                        "hideReadyInfoEnable", // Connection Instruction
                        "LocalMgtInterfaceAccessTitle",
                        "webMgtOverWiFiEn", // Local Management Interface Access
                        "webMgtOverLanEn",// Accessible from LAN
                        "LocalMgtWirelessAccessTitle",
                        "ApName",
                        "ApPassword",
                        "LMIBeaconEnable"
                    ],
                    "ManagementAccess": [
                        "WirelessInterfaceSubtitle", //"EthernetInterfaceSubtitle",
                        "ConnectionSettingsSubtitle",
                        "CurConnectApName", // Network Name
                        "CurConnectApSecType", // Security Type
                        "CurConnectApRadiusId", //Username
                        "CurConnectApPwd", //Password
                        "TCPIPSettingsSubtitle",
                        "ethDhcpEnable",
                        "ethIpaddr",
                        "ethSubnet",
                        "defaultGateway",
                        "DNSPolicy",
                        "primaryDNS",
                        "secondDNS",
                        "CentralMgtSystemTitle",
                        "mgtSrvFullAddr",
                        "mgtSrvPort",
                        "webServerPort",
                        "remoteMgtUPnPEn"
                    ],
                    "AdvancedSettings": [
                        "debugBitmap", // Debug level
                        "NGOListenChan", // Listen Channel
                        "AdvancedFeatureTitle",
                        "dynAgoPassphraseEn", // Enhanced P2P Miracast Security
                        "undiscoverRebootEn", // Undiscoverable Reboot
                        "DisplaySettingTitle",
                        "PCRSyncEnable", // PCR Synchronization
                        "HDCPEncrypMode", // HDCP Encryption
                        "aspectRatio", // Aspect Ratio
                        "scrHoffset", // Horizontal
                        "scrVoffset", // Vertical
                        "WirelessLANSettingTitle",
                        "switchP2pDongle", // Connection Mode
                        "remoteMgtWifiBand", // Wireless Band
                        "ChannelBandWidth", // Channel Bandwidth
                        "P2PWirelessSettingTitle",
                        "be_p2pIpAddr",
                        "be_p2pSubnet",
                        "be_p2pDhcpIpStart",
                        "be_p2pDhcpIpEnd",
                        "p2pNewSsidUserDef",
                        "fastIPEn",
                        "P2PInterfaceSettingTitle",
                        "be_channelPlan",
                        "preOpChannelList",
                        "opChannelList",
                        "RemoteManagementTitle",
                        "RMIWatchdogEn",
                        "RMIWatchdogSec"
                    ]
                }
            },
            "MultipleRxSettings": {
                "DeviceConfigurationGroupTitle": {
                    "type": "title",
                    "title": "DeviceConfiguration"
                },
                "DeviceNameAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Device Name Access",
                    "children": [
                        "RxNameAccess"
                    ]
                },
                "LocalWebManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Web Management",
                    "children": [
                        "webUserName", // Administrator Name
                        "webPassword"  // Administrator Password
                    ]
                },
                "Language": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Language",
                    "children": [
                        "language" // Language
                    ]
                },
                "Timezone": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Time zone",
                    "children": [
                        "timezoneItem", // Time zone
                        "dstTimeEnable"
                    ]
                },
                "FeaturesGroupTitle": {
                    "type": "title",
                    "title": "Features"
                },
                /*"WirelessDisplayMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless display mode to support client devices",
                    "children": [
                        "WiFiOperateMode"
                    ]
                },*/
                "Infracast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast Over Network",
                    "children": [
                        "infracastEnable"
                    ]
                },
                "MacOSandWin7": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "macOS and Windows 7",
                    "children": [
                        "MacInfracastEn"
                    ]
                },
                "MacAirplay": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Mac Airplay",
                    "children": [
                        "airPlayEn"
                    ]
                },
                "P2PConnectionMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Connection Mode",
                    "children": [
                        "be_autonomousGoEn"
                    ]
                },
                "OperatingChannel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Operating Channel",
                    "children": [
                        "be_autoGoChannel"
                    ]
                },
                "TransmitPower": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Transmit Power",
                    "children": [
                        "txPowerItem"
                    ]
                },
                "PIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PIN",
                    "children": [
                        "pinEnforceEnable",
                        "pinEachConnEnable",
                        "fourDigitPINEn",
                        "staticPinEnable",
                        "staticPinVal"
                    ]
                },
                /*"PinDisplayPeriod": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Pin Display Period",
                    "children": [
                        "PINDisplayPeriod"
                    ]
                },*/
                "DisplaySharingMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Display Sharing Mode",
                    "children": [
                        "hijackingEn",
                        "showOverlayRxNameOpt",
                        "overlayRxNamePlacement"
                    ]
                },
                // "AllowForceDisconnection": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Allow Force Disconnection",
                //     "children": [
                //         "hijackingEn"
                //     ]
                // },
                // "ShowReceiverName": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Show Receiver Name",
                //     "children": [
                //         "showOverlayRxNameOpt"
                //     ]
                // },
                // "ShowReceiverNamePlacement": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Receiver Name Placement",
                //     "children": [
                //         "overlayRxNamePlacement"
                //     ]
                // },
                "HdmiPortPowerManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI/VGA Port Power Management",
                    "children": [
                        "hdmiPortPowerManagement",
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ]
                },
                "Wakeup": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wake Up",
                    "children": [
                        "wakedByScan"
                    ]
                },
                "VGACompatibility": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "VGA Compatibility (CEC Off Only)",
                    "children": [
                        "VGACompMode"
                    ]
                },
                "DefaultVGAformat": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Default VGA format",
                    "children": [
                        "VGADefaultFormat"
                    ]
                },
                "TVScreenSize": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Screen Size",
                    "children": [
                        "newOverScanVal",
                        "rtOverscanEnable"
                    ]
                },
                "HDMICEC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI-CEC",
                    "children": [
                        "CECEnable"
                    ]
                },
                /*"UIBC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "UIBC",
                    "children": [
                        "UIBCEnable"
                    ]
                },*/
                "USBAutoConfig": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "USB Auto Configure",
                    "children": [
                        "usbConfigEn"
                    ]
                },
                "InactivityRebootTime": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Inactivity Reboot Time",
                    "children": [
                        "InactivityRebootTime"
                    ]
                },
                "FirmwareUpgrade": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Firmware Upgrade via USB Autorun",
                    "children": [
                        "fwupdateAccess"
                    ]
                },
                "ReceiverLogging": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Receiver Logging",
                    "children": [
                        "RxCsvLogEn"
                    ]
                },
                // "DigitalSignageGroupTitle": {
                //     "type": "title",
                //     "title": "DigitalSignage"
                // },
                "NetworkSettingsGroupTitle": {
                    "type": "title",
                    "title": "NetworkSettings"
                },
                "NetworkInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Information",
                    "children": [
                        "showMoreInfoEnable"
                    ]
                },
                "ConnectInstruction": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Connect Instruction",
                    "children": [
                        "hideReadyInfoEnable"
                    ]
                },
                "LocalMgtAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Management Access Settings",
                    "children": [
                        "webMgtOverWiFiEn"
                    ]
                },
                "AccessiblefromLAN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Accessible from LAN",
                    "children": [
                        "webMgtOverLanEn"
                    ]
                },
                "NetworkNameBroadcast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name Broadcast",
                    "children": [
                        "LMIBeaconEnable"
                    ]
                },
                /*"LocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Network",
                    "children": [
                        "NetworkMode"
                    ]
                },
                "InterfaceFeature": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Interface Feature Assignment",
                    "children": [
                        "apBridgeInterface"
                    ]
                },
                "NetworkName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name",
                    "children": [
                        "EthNetworkName"
                    ]
                },
                "WiredConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wired Connection Settings",
                    "children": [
                        "CurEthAuthentication",
                        "CurEthRadiusID",
                        "CurEthRadiusPWD",
                        "EthCertDateTime",
                        "EthRootCertURL",
                        "EthUserCertURL",
                        "EthUserKeyURL"
                    ]
                },*/
                "ManagementAccessGroupTitle": {
                    "type": "title",
                    "title": "ManagementAccess"
                },
                "WirelessConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless Connection Settings",
                    "children": [
                        "CurConnectApName",
                        "CurConnectApSecType",
                        "CurConnectApRadiusId",
                        "CurConnectApPwd",
                        "TLSCertDateTime",
                        "TLSRootCertURL",
                        "TLSUserCertURL",
                        "TLSUserKeyURL"
                    ]
                },
                /*"SbLocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Settings",
                    "children": [
                        "ApBsS1Enable"

                    ]
                },*/
                "WebPort": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "WEB Communication Port",
                    "children": [
                        "webServerPort"

                    ]
                },
                "UPnPDiscovery": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "UPnP Discovery",
                    "children": [
                        "remoteMgtUPnPEn"
                    ]
                },

                // Advanced Settings
                "AdvancedSettingsGroupTitle": {
                    "type": "title",
                    "title": "AdvancedSettings"
                },
                "DebugLevel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Enable Debug Level",
                    "children": [
                        "debugBitmap"
                    ]
                },
                "ListenChannel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Listen Channel",
                    "children": [
                        "NGOListenChan"
                    ]
                },
                "EnhancedP2PMiracastSecurity": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Enhanced P2P Miracast Security",
                    "children": [
                        "dynAgoPassphraseEn"
                    ]
                },
                "UndiscoverReboot": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Undiscoverable Reboot",
                    "children": [
                        "undiscoverRebootEn"
                    ]
                },
                "PCRSync": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PCR Synchronization",
                    "children": [
                        "PCRSyncEnable"
                    ]
                },
                "HdcpEncryption": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDCP Encryption",
                    "children": [
                        "HDCPEncrypMode"
                    ]
                },
                "AspectRatio": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Aspect Ratio",
                    "children": [
                        "aspectRatio",
                        "scrHoffset",
                        "scrVoffset"
                    ]
                },
                "WirelessLANSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless LAN Settings",
                    "children": [
                        "switchP2pDongle",
                        "remoteMgtWifiBand"
                    ]
                },
                "ChannelBandwidth": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Channel Bandwidth",
                    "children": [
                        "ChannelBandWidth"
                    ]
                },
                "P2PWirelessNetworkSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Wireless Network Settings (AGO enabled only)",
                    "children": [
                        "be_p2pIpAddr",
                        "be_p2pSubnet",
                        "be_p2pDhcpIpStart",
                        "be_p2pDhcpIpEnd"
                    ]
                },
                "P2PInterface": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Interface",
                    "children": [
                        "be_channelPlan",
                        "preOpChannelList",
                        "opChannelList"
                    ]
                },
                "RMIWatchdog": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "RMI Watchdog",
                    "children": [
                        "RMIWatchdogEn",
                        "RMIWatchdogSec"
                    ]
                },
                "FastIP": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Fast-IP",
                    "children": [
                        "fastIPEn"
                    ]
                }

            },
            "MultipleRxSettingsForUSB": {
                "DeviceConfigurationGroupTitle": {
                    "type": "title",
                    "title": "DeviceConfiguration"
                },
                "DeviceNameAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Device Name Access",
                    "children": [
                        "RxNameAccess",
                        "RxName" // Device Name
                    ]
                },
                "LocalWebManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Web Management",
                    "children": [
                        "webUserName", // Administrator Name
                        "webPassword"  // Administrator Password
                    ]
                },
                "Language": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Language",
                    "children": [
                        "language" // Language
                    ]
                },
                "HostName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Host Name",
                    "children": [
                        "RXHostname" // Host Name
                    ]
                },
                "Timezone": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Time zone",
                    "children": [
                        "timezoneItem", // Time zone
                        "dstTimeEnable"
                    ]
                },
                "FeaturesGroupTitle": {
                    "type": "title",
                    "title": "Features"
                },
                /*"WirelessDisplayMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless display mode to support client devices",
                    "children": [
                        "WiFiOperateMode"
                    ]
                },*/
                "Infracast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Miracast Over Network",
                    "children": [
                        "infracastEnable"
                    ]
                },
                "MacOSandWin7": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "macOS and Windows 7",
                    "children": [
                        "MacInfracastEn"
                    ]
                },
                "MacAirplay": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Mac Airplay",
                    "children": [
                        "airPlayEn"
                    ]
                },
                "P2PConnectionMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Connection Mode",
                    "children": [
                        "be_autonomousGoEn"
                    ]
                },
                "OperatingChannel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Operating Channel",
                    "children": [
                        "be_autoGoChannel"
                    ]
                },
                "TransmitPower": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Transmit Power",
                    "children": [
                        "txPowerItem"
                    ]
                },
                "PIN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PIN",
                    "children": [
                        "pinEnforceEnable",
                        "pinEachConnEnable",
                        "fourDigitPINEn",
                        "staticPinEnable",
                        "staticPinVal"
                    ]
                },
                /*"PinDisplayPeriod": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Pin Display Period",
                    "children": [
                        "PINDisplayPeriod"
                    ]
                },*/
                "DisplaySharingMode": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Display Sharing Mode",
                    "children": [
                        "hijackingEn",
                        "showOverlayRxNameOpt",
                        "overlayRxNamePlacement"
                    ]
                },
                // "AllowForceDisconnection": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Allow Force Disconnection",
                //     "children": [
                //         "hijackingEn"
                //     ]
                // },
                // "ShowReceiverName": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Show Receiver Name",
                //     "children": [
                //         "showOverlayRxNameOpt"
                //     ]
                // },
                // "ShowReceiverNamePlacement": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Receiver Name Placement",
                //     "children": [
                //         "overlayRxNamePlacement"
                //     ]
                // },
                //Show "Connect to WiFi" instructions
                /*"ConnectToWiFiInstruction": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Show \"Connect to WiFi\" instructions",
                    "children": [
                        "showConnectWiFiEnable"
                    ]
                },*/
                "HdmiPortPowerManagement": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI/VGA Port Power Management",
                    "children": [
                        "hdmiPortPowerManagement",
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ],
                    "dataToSend": [
                        "scrSaverSecond",
                        "scrHDMIOutEnable",
                        "scrSaverEnable"
                    ]
                },
                "Wakeup": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wake Up",
                    "children": [
                        "wakedByScan"
                    ]
                },
                "VGACompatibility": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "VGA Compatibility (CEC Off Only)",
                    "children": [
                        "VGACompMode"
                    ]
                },
                "DefaultVGAformat": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Default VGA format",
                    "children": [
                        "VGADefaultFormat"
                    ]
                },
                "TVScreenSize": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TV Screen Size",
                    "children": [
                        "newOverScanVal",
                        "rtOverscanEnable"
                    ]
                },
                "HDMICEC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDMI-CEC",
                    "children": [
                        "CECEnable"
                    ]
                },
                // "DebugView": {
                //     "type": "checkbox",
                //     "selected": "0",
                //     "label": "Debug View",
                //     "children": [
                //         "debugViewEnable"
                //     ]
                // },
                /*"UIBC": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "UIBC",
                    "children": [
                        "UIBCEnable"
                    ]
                },*/
                "USBAutoConfig": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "USB Auto Configure",
                    "children": [
                        "usbConfigEn"
                    ]
                },
                "InactivityRebootTime": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Inactivity Reboot Time",
                    "children": [
                        "InactivityRebootTime"
                    ]
                },
                "FirmwareUpgrade": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Firmware Upgrade via USB Autorun",
                    "children": [
                        "fwupdateAccess"
                    ]
                },
                "ReceiverLogging": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Receiver Logging",
                    "children": [
                        "RxCsvLogEn"
                    ]
                },
                "NetworkSettingsGroupTitle": {
                    "type": "title",
                    "title": "NetworkSettings"
                },
                "NetworkInfo": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Information",
                    "children": [
                        "showMoreInfoEnable"
                    ]
                },
                "ConnectInstruction": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Connect Instruction",
                    "children": [
                        "hideReadyInfoEnable"
                    ]
                },
                "LocalMgtAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Management Access Settings",
                    "children": [
                        "webMgtOverWiFiEn"
                    ]
                },
                "AccessiblefromLAN": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Accessible from LAN",
                    "children": [
                        "webMgtOverLanEn"
                    ]
                },
                "LocalManagementInterfaceAccess": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless Local Management Interface Settings",
                    "children": [
                        "ApName",
                        "ApPassword"
                    ]
                },
                "NetworkNameBroadcast": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name Broadcast",
                    "children": [
                        "LMIBeaconEnable"
                    ]
                },
                /*"LocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Network",
                    "children": [
                        "NetworkMode"
                    ]
                },
                "InterfaceFeature": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Interface Feature Assignment",
                    "children": [
                        "apBridgeInterface"
                    ]
                },
                "NetworkName": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Network Name",
                    "children": [
                        "EthNetworkName"
                    ]
                },
                "WiredConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wired Connection Settings",
                    "children": [
                        "CurEthAuthentication",
                        "CurEthRadiusID",
                        "CurEthRadiusPWD",
                        "EthCertDateTime",
                        "EthRootCertURL",
                        "EthUserCertURL",
                        "EthUserKeyURL"
                    ]
                },*/
                "ManagementAccessGroupTitle": {
                    "type": "title",
                    "title": "ManagementAccess"
                },
                "WirelessConnection": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Wireless Connection Settings",
                    "children": [
                        "CurConnectApName",
                        "CurConnectApSecType",
                        "CurConnectApRadiusId",
                        "CurConnectApPwd",
                        "TLSCertDateTime",
                        "TLSRootCertURL",
                        "TLSUserCertURL",
                        "TLSUserKeyURL"
                    ]
                },
                /*"SbLocalWifi": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Local Wifi Settings",
                    "children": [
                        "ApBsS1Enable"

                    ]
                },*/
                "CentralManagementSystemSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Central Management System Settings",
                    "children": [
                        "mgtSrvFullAddr",
                        "mgtSrvPort"
                    ]
                },
                "WebPort": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "WEB Communication Port",
                    "children": [
                        "webServerPort"

                    ]
                },
                "EthernetTCPIPSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "TCP/IP Settings",
                    "children": [
                        "ethDhcpEnable",
                        "ethIpaddr",
                        "ethSubnet",
                        "defaultGateway",
                        "DNSPolicy",
                        "primaryDNS",
                        "secondDNS"
                    ]
                },
                /*"UPnPDiscovery": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "UPnP Discovery",
                    "children": [
                        "remoteMgtUPnPEn"
                    ]
                },*/
                "SetBackgroundGroupTitle": {
                    "type": "title",
                    "title": "SetBackground"
                },
                "SetBackground": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Set Background",
                    "children": [
                        "BackgroundCustom"
                    ]
                },
                // Advanced Settings
                "AdvancedSettingsGroupTitle": {
                    "type": "title",
                    "title": "AdvancedSettings"
                },
                "DebugLevel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Enable Debug Level",
                    "children": [
                        "debugBitmap"
                    ]
                },
                "ListenChannel": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Listen Channel",
                    "children": [
                        "NGOListenChan"
                    ]
                },
                "EnhancedP2PMiracastSecurity": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Enhanced P2P Miracast Security",
                    "children": [
                        "dynAgoPassphraseEn"
                    ]
                },
                "UndiscoverReboot": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Undiscoverable Reboot",
                    "children": [
                        "undiscoverRebootEn"
                    ]
                },
                "PCRSync": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "PCR Synchronization",
                    "children": [
                        "PCRSyncEnable"
                    ]
                },
                "HdcpEncryption": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "HDCP Encryption",
                    "children": [
                        "HDCPEncrypMode"
                    ]
                },
                "AspectRatio": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Aspect Ratio",
                    "children": [
                        "aspectRatio",
                        "scrHoffset",
                        "scrVoffset"
                    ]
                },
                "WirelessLANSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "WirelessLANSettings",
                    "children": [
                        "switchP2pDongle",
                        "remoteMgtWifiBand"
                    ]
                },
                "ChannelBandwidth": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Channel Bandwidth",
                    "children": [
                        "ChannelBandWidth"
                    ]
                },
                "P2PWirelessNetworkSettings": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Wireless Network Settings (AGO enabled only)",
                    "children": [
                        "be_p2pIpAddr",
                        "be_p2pSubnet",
                        "be_p2pDhcpIpStart",
                        "be_p2pDhcpIpEnd"
                    ]
                },
                "P2PInterface": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "P2P Interface",
                    "children": [
                        "be_channelPlan",
                        "preOpChannelList",
                        "opChannelList"
                    ]
                },
                "RMIWatchdog": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "RMI Watchdog",
                    "children": [
                        "RMIWatchdogEn",
                        "RMIWatchdogSec"
                    ]
                },
                "FastIP": {
                    "type": "checkbox",
                    "selected": "0",
                    "label": "Fast-IP",
                    "children": [
                        "fastIPEn"
                    ]
                }

            },
            "AllSettings": {
                /* Device Name Access - RADIO BUTTON */
                "RxNameAccess": {
                    "type": "radioButton",
                    "label": "DeviceNameAccess",
                    "info": "",
                    //"disabled": false,
                    "value": "0",
                    "options": {
                        "Enable": "0",
                        "Disable": "1"
                    }
                },
                /* Device Name - INPUT TEXT */
                "RxName": {
                    "type": "input",
                    "value": "",
                    "label": "DeviceName",
                    "info": "",
                    "display": "0",
                    "dependencies": ["RxNameAccess"],
                    "dependencyMap": {
                        "1": "2",
                        "0": "0"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 32) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value[0] === " " || value[value.length - 1] === " ") {
                            return "The first and last character of Device Name cannot be blank";
                        }
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/]+$/.test(value)) {
                            return "The Device Name supports numbers, letters, and symbols, except \` \" \\ < > and &";
                        }
                    }
                },
                /* Manufacture Name - INPUT TEXT */
                "manufacName": {
                    "type": "input",
                    "value": "",
                    "label": "ManufactureName",
                    "info": "",
                    "display": "2"
                },
                /* Model Name - INPUT TEXT */
                "mdNameLong": {
                    "type": "input",
                    "value": "",
                    "label": "ModelName",
                    "info": "",
                    "display": "2"
                },
                /* Model Name Short - INPUT TEXT */
                "mdNameShort": {
                    "type": "input",
                    "value": "",
                    "label": "ModelNameShort",
                    "info": "",
                    "display": "2"
                },
                /* Hardware Version - INPUT TEXT */
                "hwVersion": {
                    "type": "input",
                    "value": "",
                    "label": "HardwareVersion",
                    "info": "",
                    "display": "2"
                },
                /* Firmware Version - INPUT TEXT */
                "fwVersion": {
                    "type": "input",
                    "value": "",
                    "label": "FirmwareVersion",
                    "info": "",
                    "display": "2"
                },
                /* Admin Username - INPUT TEXT */
                "webUserName": {
                    "type": "input",
                    "value": "Administrator",
                    "label": "AdminUsername",
                    "info": "",
                    "display": "0",
                    "checkbox": "LocalWebManagement",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyUsernameError";
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    }
                },
                /* Admin Password - INPUT PASSWORD*/
                "webPassword": {
                    "type": "password",
                    "value": "",
                    "label": "AdminPassword",
                    "info": "",
                    "display": "0",
                    "checkbox": "LocalWebManagement",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyPasswordError";
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidPasswordError";
                        }
                    }
                },
                /* Language - DROPDOWN */
                "language": {
                    "type": "dropdown",
                    "checkbox": "Language",
                    "value": "en",
                    "label": "Language",
                    "info": "",
                    "display": "0",
                    "options": {
                        "English": "en",
                        "简体中文": "zhcs",
                        "正體中文": "zhct",
                        "日本語": "ja",
                        "Français": "fr",
                        "Deutsch": "ge",
                        "Nederlands": "du",
                        "한국어": "ko",
                        "Español": "sp",
                        "Italiano": "it",
                        "Русский": "ru",
                    }
                },
                /* Host Name - INPUT TEXT */
                "RXHostname": {
                    "type": "input",
                    "value": "",
                    "label": "HostName",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (value.length <= 63) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "Host Name must not be empty";
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "Username must consist of alphanumeric characters, space, or -";
                        }
                        if (value[0] === "-" || value[value.length - 1] === "-") {
                            return "The name cannot start or end with \"-\"";
                        }
                    }
                },
                /* Time zone - DROPDOWN */
                "timezoneItem": {
                    "type": "dropdown",
                    "checkbox": "Timezone",
                    "value": "5",
                    "label": "Timezone",
                    "info": "",
                    "display": "0",
                    "options": {
                        "(UTC - 12:00) International Date Line West": "0",
                        "(UTC - 11:00) American Samoas": "1",
                        "(UTC - 10:00) Honolulu": "2",
                        "(UTC - 09:30) French Polynesia": "3",
                        "(UTC - 09:00) Anchorage": "4",
                        "(UTC - 08:00) Pacific Time": "5",
                        "(UTC - 07:00) Mountain Time": "6",
                        "(UTC - 06:00) Central Time": "7",
                        "(UTC - 05:00) Eastern Time": "8",
                        "(UTC - 04:30) Caracas": "9",
                        "(UTC - 04:00) Atlantic Time": "10",
                        "(UTC - 03:30) Newfoundland": "11",
                        "(UTC - 03:00) Buenos Aires": "12",
                        "(UTC - 02:00) South Sandwish Island": "13",
                        "(UTC - 01:00) Azores Islands": "14",
                        "(UTC + 00:00) Greenwich": "15",
                        "(UTC + 01:00) Amsterdam": "16",
                        "(UTC + 02:00) Cairo": "17",
                        "(UTC + 03:00) Moscow": "18",
                        "(UTC + 03:30) Tehran": "19",
                        "(UTC + 04:00) Dubai": "20",
                        "(UTC + 04:30) Kabul": "21",
                        "(UTC + 05:00) Islamabad": "22",
                        "(UTC + 05:30) New Delhi": "23",
                        "(UTC + 05:45) Katmandu": "24",
                        "(UTC + 06:00) Dhaka": "25",
                        "(UTC + 06:30) Rangoon": "26",
                        "(UTC + 07:00) Bangkok": "27",
                        "(UTC + 08:00) Beijing": "28",
                        "(UTC + 09:00) Tokyo": "29",
                        "(UTC + 09:30) Adelaide": "30",
                        "(UTC + 10:00) Sydney": "31",
                        "(UTC + 11:00) Solomon Island": "32",
                        "(UTC + 12:00) Auckland": "33",
                        "(UTC + 13:00) Samoa": "34",
                        "(UTC + 14:00) Kiritimati Island": "35"
                    },
                    "onChange": (_this, value) => {
                        // To make timezone setting work, CMS Service needs to know this piece of information
                        _this.changeMap["dstTimeEnable"] = _this.state.settings["dstTimeEnable"].value;
                    }
                },
                /* Daylight - CHECKBOX */
                "dstTimeEnable": {
                    "type": "checkbox",
                    "value": "0",
                    "label": "DaylightSavings",
                    "info": "",
                    "display": "0",
                    "onChange": (_this, value) => {
                        // To make timezone setting work, CMS Service needs to know this piece of information
                        _this.changeMap["timezoneItem"] = _this.state.settings["timezoneItem"].value;
                    }
                },
                /* Wireless Display Mode Title - TITLE */
                "WirelessDisplayModeTitle": {
                    "type": "title",
                    "title": "WirelessDisplayModeTitle",
                    "display": "0"
                },
                /* Wireless Display Mode - DROPDOWN*/
                "WiFiOperateMode": {
                    "type": "dropdown",
                    "value": "5",
                    "label": "WirelessDisplayMode",
                    "info": "",
                    "display": "0",
                    "options": {
                        "CommanderMode": "1",
                        "StandardWirelessDisplay": "5"
                    },
                    "onChange": (_this) => {
                        var value = _this.state.settings.WiFiOperateMode.value;
                        if (value === "1") {
                            _this.setState({
                                "tabHeaders": [
                                    "DeviceConfiguration",
                                    "Features",
                                    "NetworkSettings",
                                    "ManagementAccess",
                                    "AdvancedSettings"
                                ]
                            });
                        }
                        else {
                            _this.setState({
                                "tabHeaders": [
                                    "DeviceConfiguration",
                                    "Features",
                                    "NetworkSettings",
                                    "LocalWifi",
                                    "ManagementAccess",
                                    "AdvancedSettings"
                                ]
                            });
                        }
                    }
                },
                /* Wireless Display over LAN Title - TITLE */
                "WirelessDisplayOverLANTitle": {
                    "type": "title",
                    "title": "WirelessDisplayOverLan",
                    "display": "0",
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* Windows 10 - RADIO BUTTON */
                "infracastEnable": {
                    "type": "radioButton",
                    "label": "Windows10",
                    "info": "Windows10Info",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* macOS and windows 7 - RADIO BUTTON */
                "MacInfracastEn": {
                    "type": "radioButton",
                    "label": "MacWin7",
                    "info": "MacWin7Info",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* macOS or iOS native screen mirroring - RADIO BUTTON */
                "airPlayEn": {
                    "type": "radioButton",
                    "label": "AirplayMode",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "dependencies": ["WiFiOperateMode"],
                    "dependencyMap": {
                        "1": "1"
                    }
                },
                /* P2P wireless setting title - TITLE */
                "P2PWirelessSettingTitle": {
                    "type": "title",
                    "title": "P2PWirelessSettings",
                    "display": "0"
                },
                /* P2P Operating Channel ----- NEED TO DO */
                /* Transmit Power - DROPDOWN */
                "txPowerItem": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "TransmitPower",
                    "info": "TransmitPowerInfo",
                    "display": "0",
                    "options": {
                        "High": "3",
                        "Medium": "0",
                        "Low": "100"
                    }
                },
                /* Security Setting title - TITLE */
                "SecuritySettingTitle": {
                    "type": "title",
                    "title": "SecuritySettings",
                    "display": "0"
                },
                /* Force PIN Pairing on First Connection - RADIO BUTTON*/
                "pinEnforceEnable": {
                    "type": "radioButton",
                    "label": "ForcePinPairing",
                    "info": "ForcePinPairingInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    },
                    "onStart": (settings, data) => {
                        if (settings["pinEnforceEnable"].value === "1") {
                            settings["pinEnforceEnable"].info = "ForcePinPairingInfo";
                        }
                        else {
                            settings["pinEnforceEnable"].info = "ForcePinPairingOffInfo";
                        }

                        return settings["pinEnforceEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        if (_this.state.settings["pinEnforceEnable"].value === "1") {
                            setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingInfo");
                        } else {
                            setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingOffInfo");
                        }
                    }
                },
                /* Require PIN on - RADIO BUTTON */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "pinEachConnEnable": {
                    "type": "radioButton",
                    "label": "RequirePinOn",
                    "info": "RequirePinOnInfo",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "EachConnection": "1",
                        "FirstConnection": "0"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onStart": (settings, data) => {
                        if (settings["pinEachConnEnable"].value === "1") {
                            settings["pinEachConnEnable"].info = "RequirePinOnEachConnInfo";
                        }
                        else {
                            settings["pinEachConnEnable"].info = "RequirePinOnInfo";
                        }

                        return settings["pinEachConnEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        if (_this.state.settings["pinEachConnEnable"].value === "1") {
                            setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnEachConnInfo");
                        } else {
                            setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnInfo");
                        }
                    }
                },
                /* PIN Length - DROPDOWN */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "fourDigitPINEn": {
                    "type": "dropdown",
                    "label": "PinLength",
                    "info": "PinLengthInfo",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "8Digit": "0",
                        "4Digit": "1"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        var strtemp = "";
                        var strtempInfo = "";
                        var staticPinMaxLen = 4;
                        if (_this.state.settings["fourDigitPINEn"].value === "1") {
                            if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length < 4) {
                                strtemp = "StaticPin4Error";
                            }
                            strtempInfo = "StaticPin4Info";
                        }
                        else {
                            staticPinMaxLen = 7;
                            if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length !== 7) {
                                strtemp = "StaticPin8Error";
                            }
                            strtempInfo = "StaticPin8Info";
                        }
                        if (_this.state.settings["staticPinEnable"].value === "1") {
                            setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen,
                                () => {
                                    setSettingState(_this, "staticPinVal", "error", strtemp, () => {
                                        setSettingState(_this, "staticPinVal", "info", strtempInfo);
                                    });
                                });
                        } else {
                            setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen);
                        }
                    }
                },
                /* PIN Generation Method - RADIO BUTTON */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "staticPinEnable": {
                    "type": "radioButton",
                    "label": "PinGenerationMethod",
                    "info": "PinGenerationMethodInfo",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Static": "1",
                        "Random": "0"
                    },
                    "dependencies": ["pinEnforceEnable"],
                    "dependencyMap": {
                        "0": "1"
                    },
                    "onStart": (settings, data) => {
                        if (settings["staticPinEnable"].value === "1") {
                            settings["staticPinEnable"].info = "PinGenerationMethodStaticInfo";
                        }
                        else {
                            settings["staticPinEnable"].info = "PinGenerationMethodInfo";
                        }

                        return settings["staticPinEnable"];
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        var strStaticPinEnInfo = "";
                        var strStaticPinValInfo = "";
                        if (_this.state.settings["staticPinEnable"].value === "1") {
                            strStaticPinEnInfo = "PinGenerationMethodStaticInfo";
                        }
                        else {
                            strStaticPinEnInfo = "PinGenerationMethodInfo";
                        }

                        if (_this.state.settings["fourDigitPINEn"].value === "1") {
                            strStaticPinValInfo = "StaticPin4Info";
                        }
                        else {
                            strStaticPinValInfo = "StaticPin8Info";
                        }
                        setSettingState(_this, "staticPinEnable", "info", strStaticPinEnInfo,
                            () => {
                                setSettingState(_this, "staticPinVal", "info", strStaticPinValInfo);
                            });
                    }
                },
                /* Static PIN Val - INPUT TEXT */
                // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
                // Hide the item when "pinEnforceEnable" = "0" (Off)
                "staticPinVal": {
                    "type": "pin",
                    "value": "1234567",
                    "label": "StaticPin",
                    "info": "",
                    "pinLength": "fourDigitPINEn",
                    "display": "0",
                    "maxLength": "4",
                    "dependencies": ["pinEnforceEnable", "staticPinEnable"],
                    "dependencyMap": {
                        "10": "1",
                        "00": "1",
                        "01": "1"
                    },
                    "onStart": (settings, data) => {
                        if (data["fourDigitPINEn"] === "0")
                            settings["staticPinVal"].maxLength = "7";
                        return settings["staticPinVal"];
                    },
                    "preValidate": (value, _this) => {
                        if (/^[0-9]+$/.test(value) || value === "") {
                            /*if (_this.state.settings["fourDigitPINEn"].value === "1" && value.length <= 4) {
                                return true;
                            }
                            if (_this.state.settings["fourDigitPINEn"].value === "0" && value.length <= 7) {
                                return true;
                            }*/
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        this.setChangeMapModifiedPinFea(_this);
                        //Now we only need to send 7 digits to RX.
                        /*if (_this.state.settings["staticPinVal"].value.length == 7) {
                            var pin_code = _this.state.settings["staticPinVal"].value;
                            var pin = parseInt(pin_code) * 10;
                            var acccum = 0;
                            var result = "";

                            acccum = 3 * parseInt(((pin / 10000000) % 10), 10);
                            acccum += 1 * parseInt(((pin / 1000000) % 10), 10);
                            acccum += 3 * parseInt(((pin / 100000) % 10), 10);
                            acccum += 1 * parseInt(((pin / 10000) % 10), 10);
                            acccum += 3 * parseInt(((pin / 1000) % 10), 10);
                            acccum += 1 * parseInt(((pin / 100) % 10), 10);
                            acccum += 3 * parseInt(((pin / 10) % 10), 10);
                            result = ((10 - (acccum % 10)) % 10);
                            value = pin_code + result.toString();
                            _this.changeMap["staticPinVal"] = value;
                            setSettingState(_this, "staticPinVal", "value", value);
                        }*/
                        //if (_this.state.settings["fourDigitPINEn"].value === "0") {
                        //    setSettingState(_this,
                        //        "fourDigitPINEn",
                        //        "info",
                        //        "Enter the first 7 digits of the PIN - the 8th digit will be generated for you. Static PIN will not appear on the HDTV/Projector to enable protected mode.");
                        //}
                    },
                    "onSubmit": (_this) => {
                        // Slice the value if it is 4-digit PIN
                        if (_this.state.settings["fourDigitPINEn"].value == "1" && _this.state.settings["staticPinVal"].value.length > 4) {
                            _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value.slice(0, 4);
                        }
                    },
                    "validate": (value, _this) => {
                        
                        if ((_this.state.settings["staticPinEnable"].value == "1") && (_this.state.settings["fourDigitPINEn"].value === "1") && (value.length !== 4)) {
                            // In case, the length is 7 which means the default value, we will ignore it. Otherwise, it is an error
                            if (value.length != 7) return "StaticPin4Error"; 
                        }
                        if ((_this.state.settings["staticPinEnable"].value == "1") && (_this.state.settings["fourDigitPINEn"].value === "0") && (value.length !== 7)) {
                            return "StaticPin8Error";
                        }
                    }
                },
                /* PIN Display Period - INPUT TEXT */
                "PINDisplayPeriod": {
                    "type": "input",
                    "value": "60",
                    "label": "PinDisplayPeriod",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (/^[0-9]+$/.test(value) || value === "") {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value > 120 || value < 30 || value === "") {
                            return "PinDisplayPeriodError";
                        }
                    }
                },
                /* Display Setting Title - TITLE */
                "DisplaySettingTitle": {
                    "type": "title",
                    "title": "DisplaySettings",
                    "display": "0"
                },
                /* ScreenBeam Network Info - DROPDOWN */
                "showMoreInfoEnable": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "ScreenBeamNetworkInfo960",
                    "info": "",
                    "display": "0",
                    "options": {
                        "FirmwareandNetworkInfo": "2",
                        "FirmwareInfo": "0",
                        "AllInfo": "1"
                    }
                },
                /* Connection Instruction - RADIO BUTTON */
                "hideReadyInfoEnable": {
                    "type": "radioButton",
                    "label": "HideReadyInfo",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* HDMI/VGA Port Power Management */
                "hdmiPortPowerManagement": {
                    "type": "dropdown",
                    "label": "HdmiVgaManagement",
                    "value": "01",
                    "display": "0",
                    "info": "HdmiVgaManagement",
                    "isNotInOriginalData": true,
                    "options": {
                        "AlwaysOn": "01",
                        "ScreenSaver": "11",
                        "DisplayOff": "10"
                    },
                    "onStart": (settings, data) => {
                        let scrSaverEnable = data["scrSaverEnable"];
                        let scrHdmiOutEnable = data["scrHDMIOutEnable"];
                        settings["hdmiPortPowerManagement"].value = scrSaverEnable + scrHdmiOutEnable;
                        return settings["hdmiPortPowerManagement"];
                    },
                    "onChange": (_this) => {
                        let selection = _this.state.settings["hdmiPortPowerManagement"].value;
                        _this.changeMap["scrSaverEnable"] = selection[0];
                        _this.changeMap["scrHDMIOutEnable"] = selection[1];
                        _this.changeMap["scrSaverSecond"] = _this.state.settings["scrSaverSecond"].value;
                        delete _this.changeMap["hdmiPortPowerManagement"];
                        _this.setState({
                            settings: {
                                ..._this.state.settings,
                                "scrHDMIOutEnable": {
                                    ..._this.state.settings["scrHDMIOutEnable"],
                                    value: selection[1]
                                },
                                "scrSaverEnable": {
                                    ..._this.state.settings["scrSaverEnable"],
                                    value: selection[0]
                                }
                            }
                        });
                    }
                },
                "scrHDMIOutEnable": {
                    "value": "1"
                },
                "scrSaverEnable": {
                    "value": "0"
                },
                /* Wait - Triggered when HDMI/VGA selects Screensaver or Display Off - INPUT TEXT */
                "scrSaverSecond": {
                    "type": "input",
                    "label": "Wait",
                    "info": "WaitInfo",
                    "display": "1",
                    "value": "180",
                    "dependencies": ["hdmiPortPowerManagement"],
                    "dependencyMap": {
                        "01": "1",
                        "11": "0",
                        "10": "0"
                    },
                    "validate": (value) => {
                        if (value == "")
                            return "ScreenSaverWaitEmptyError"
                    }
                },
                /* Wake up - DROPDOWN */
                "wakedByScan": {
                    "type": "dropdown",
                    "label": "Wakeup",
                    "info": "",
                    "value": "0",
                    "options": {
                        "OnConnect": "0",
                        "OnScan": "1"
                    },
                    "display": "2",
                    "dependencies": ["hdmiPortPowerManagement"],
                    "dependencyMap": {
                        "01": "2",
                        "11": "0",
                        "10": "0"
                    }
                },
                /* Adjust TV Screen Size - DROPDOWN */
                "newOverScanVal": {
                    "type": "dropdown",
                    "value": "25",
                    "label": "AdjustTvScreenSize",
                    "info": "",
                    "display": "0",
                    "options": {
                        "0": "0",
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "14": "14",
                        "15": "15",
                        "16": "16",
                        "17": "17",
                        "18": "18",
                        "19": "19",
                        "20": "20",
                        "21": "21",
                        "22": "22",
                        "23": "23",
                        "24": "24",
                        "25": "25"
                    },
                    "onSubmit": (_this) => {
                        // Need to send rtOverscanEnable together
                        _this.changeMap["rtOverscanEnable"] = _this.state.settings["rtOverscanEnable"].value;
                    }
                },
                /* Allow source device to override overscan value */
                "rtOverscanEnable": {
                    "type": "checkbox",
                    "label": "OverrideOverScanVal",
                    "info": "",
                    "display": "0",
                    "value": "0"
                },
                /* Receiver Logging - RADIO BUTTON */
                "RxCsvLogEn": {
                    "type": "radioButton",
                    "label": "ReceiverLogging",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Network Mode - DROPDOWN */
                "NetworkMode": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "NetworkMode",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Nat": "1",
                        "Bridge": "2",
                        "None": "0"
                    }
                },
                /* Local Wifi Network Title - TITLE */
                "LocalWifiNetworkTitle": {
                    "type": "title",
                    "title": "LocalWifiNetwork",
                    "display": "0"
                },
                /* Interface Feature Assignment Title - TITLE */
                "InterfaceFeatureTitle": {
                    "type": "title",
                    "title": "InterfaceFeatureAssignment",
                    "display": "0"
                },
                /* CMS Interface - DROPDOWN */
                "cmsInterface": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "CmsInterface",
                    "info": "",
                    "display": "2",
                    "options": {
                        "Auto": "0"
                    },
                    "dependencies": ["NetworkMode"],
                    "dependencyMap": {
                        "0": "1"
                    }
                },
                /* Internet WAN Interface - DROPDOWN */
                "apBridgeInterface": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "InternetWanInterface",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Ethernet": "1",
                        "Wireless": "2"
                    }
                },
                /* Network Interface Settings Title - TITLE */
                "NetworkInterfaceTitle": {
                    "type": "title",
                    "title": "NetworkInterfaceSettings",
                    "display": "0"
                },
                /* Ethernet Interface Subtitle - SUBTITLE */
                "EthernetInterfaceSubtitle": {
                    "type": "subtitle",
                    "title": "EthernetInterface",
                    "display": "0"
                },
                /* Network Name - INPUT TEXT */
                "EthNetworkName": {
                    "type": "input",
                    "label": "NetworkName",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "preValidate": (value) => {
                        if (value.length <= 16) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyNetworkNameError";
                        }
                        if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                            return "InvalidNetworkNameError";
                        }
                    }
                },
                /* Connection Settings Subtitle - SUBTITLE */
                "ConnectionSettingsSubtitle": {
                    "type": "subtitle",
                    "title": "ConnectionSettings",
                    "display": "0"
                },
                /* Ethernet Connection Authentication - DROPDOWN */
                "CurEthAuthentication": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "Authentication",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "PEAP-MSCHAPV2": "2",
                        "EAP-TLS": "3"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                            _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                /* Ethernet Connection Username - INPUT TEXT */
                // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
                "CurEthRadiusID": {
                    "type": "input",
                    "value": "",
                    "label": "Username",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "0",
                        "3": "0"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurEthAuthentication"].value == "2"
                            || _this.state.settings["CurEthAuthentication"].value == "3") {
                            if (value.length == 0) return "EmptyUsernameError";
                            if (!(/^[a-zA-Z0-9\s._@#$-]+$/.test(value))) {
                                return "InvalidUsernameError";
                            }
                        }
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                /* Ethernet Connection Password - INPUT PASSWORD */
                // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
                "CurEthRadiusPWD": {
                    "type": "password",
                    "value": "",
                    "label": "Password",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "0",
                        "3": "0"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurEthAuthentication"].value == "2"
                            || _this.state.settings["CurEthAuthentication"].value == "3") {
                            if (value.length == 0) return "EmptyPasswordError";
                            if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                                return "InvalidPasswordError";
                            }
                        }
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["CurEthAuthentication"] !== "0") {
                            _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                            _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                        }
                    }
                },
                // NEED TO ADD SYSTEM DATE & TIME, CA CERTIFICATE, USER CERTIFICATE, PRIVATE KEY, VALIDITY PERIOD
                /* System Date & Time */
                "EthCertDateTime": {
                    "type": "date",
                    "label": "SystemDateTime",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - CA Certificate URL - DISABLED INPUT TEXT */
                "EthRootCertURL": {
                    "type": "file",
                    "label": "CaCertificateUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                    //"type": "input",
                    //"label": "CA Certificate URL",
                    //"info": "",
                    //"disabled": true,
                    //"displayMode": { "controlItem": "CurEthAuthentication", "controlValue": ["0", "2"], "display": "1" }
                },
                /* Ethernet - User Certificate - DISABLED INPUT TEXT */
                "EthUserCertURL": {
                    "type": "file",
                    "label": "UserCertificateUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - Private Key URL - DISABLED INPUT TEXT */
                "EthUserKeyURL": {
                    "type": "file",
                    "label": "PrivateKeyUrl",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* Ethernet - Validity Period - INPUT TEXT */
                "EthCertsValidity": {
                    "type": "input",
                    "label": "ValidityPeriod",
                    "info": "",
                    "value": "",
                    "display": "2",
                    "dependencies": ["CurEthAuthentication"],
                    "dependencyMap": {
                        "0": "1",
                        "2": "1",
                        "3": "2"
                    }
                },
                /* TCP/IP Settings Subtitle - SUBTITLE */
                "TCPIPSettingsSubtitle": {
                    "type": "subtitle",
                    "title": "TcpIpSettings",
                    "display": "0"
                },
                /* Ethernet IP Assignment - RADIO BUTTON */
                "ethAutoIpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                            _this.changeMap["ethAutoDnsEnable"] = "0";
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;

                            _this.setState({
                                settings: {
                                    ..._this.state.settings,
                                    "ethAutoDnsEnable": {
                                        ..._this.state.settings["ethAutoDnsEnable"],
                                        value: "0"
                                    }
                                }
                            });
                        }

                    }
                },

                /* Ethernet IP Address - INPUT IP */
                "ethIpAddress": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }

                    }

                },

                /* Ethernet Subnet Mask - INPUT IP */
                "ethNetmask": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }

                    }
                },

                /* Default Gateway - INPUT IP */
                "ethGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                            _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                            _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                            _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                            _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        }

                    }
                },

                /* DNS Assignment - RADIO BUTTON */
                "ethAutoDnsEnable": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["ethAutoIpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            //_this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },

                /* Ethernet Primary DNS Server */
                "ethPrimaryDns": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["ethAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            //_this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },

                /* Ethernet Secondary DNS Server */
                "ethSecondaryDns": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["ethAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                            //_this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                            _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                            _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                        }

                    }
                },

                /* Wireless Interface Subtitle - SUBTITLE */
                "WirelessInterfaceSubtitle": {
                    "type": "subtitle",
                    "title": "WirelessInterface",
                    "display": "0"
                },
                /* Network Name - INPUT TEXT */
                "CurConnectApName": {
                    "type": "input",
                    "label": "NetworkName",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "EmptyNetworkNameError";
                        }
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];,\.\/]+$/.test(value)) {
                            return "InvalidNetworkNameError";
                        }
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    }
                },
                /* Security Type - DROPDOWN */
                "CurConnectApSecType": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "SecurityType",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "Shared": "1",
                        "WPA-PSK[TKIP]": "2",
                        "WPA2-PSK[AES]": "3",
                        "WPA-PSK[TKIP]+WPA2-PSK[AES]": "4",
                        "PEAP-MSCHAPV2": "5",
                        "EAP-TLS": "6"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    }

                },
                /* Username - INPUT TEXT */
                "CurConnectApRadiusId": {
                    "type": "input",
                    "value": "",
                    "label": "Username",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "0",
                        "6": "0"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurConnectApSecType"].value == "5" || _this.state.settings["CurConnectApSecType"].value == "6") {
                            if (value.length == 0) return "EmptyUsernameError";
                            if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                                return "InvalidUsernameError";
                            }
                        }
                    }
                },
                /* Password - INPUT PASSWORD */
                "CurConnectApPwd": {
                    "type": "password",
                    "value": "",
                    "label": "Password",
                    "info": "",
                    "display": "0",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "0",
                        "2": "0",
                        "3": "0",
                        "4": "0",
                        "5": "0",
                        "6": "0"
                    },
                    "onChange": (_this) => {
                        this.setWirelessInterfaceConnSettings(_this);
                    },
                    "validate": (value, _this) => {
                        if (_this.state.settings["CurConnectApSecType"].value == "1"
                            || _this.state.settings["CurConnectApSecType"].value == "2"
                            || _this.state.settings["CurConnectApSecType"].value == "3"
                            || _this.state.settings["CurConnectApSecType"].value == "4"
                            || _this.state.settings["CurConnectApSecType"].value == "5"
                            || _this.state.settings["CurConnectApSecType"].value == "6") {
                            if (value.length == 0) return "EmptyPasswordError";
                            if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                                return "InvalidPasswordError";
                            }
                        }
                    }
                },
                /* Wireless - Date Time - DISABLED INPUT */
                "TLSCertDateTime": {
                    "type": "date",
                    "value": "",
                    "label": "SystemDateTime",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - CA Certificate URL - DISABLE INPUT */
                "TLSRootCertURL": {
                    "type": "file",
                    "value": "",
                    "label": "CaCertificateUrl",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - User Certificate URL - DISABLE INPUT */
                "TLSUserCertURL": {
                    "type": "file",
                    "value": "",
                    "label": "UserCertificateUrl",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless - Private Key URL - DISABLE INPUT */
                "TLSUserKeyURL": {
                    "type": "file",
                    "value": "",
                    "label": "PrivateKeyUrl",
                    "info": "",
                    "display": "1",
                    "dependencies": ["CurConnectApSecType"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "1",
                        "2": "1",
                        "3": "1",
                        "4": "1",
                        "5": "1",
                        "6": "2"
                    }
                },
                /* Wireless IP Assignment - RADIO BUTTON */
                "staAutoIpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                            _this.changeMap["staAutoDnsEnable"] = "0";
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;

                            _this.setState({
                                settings: {
                                    ..._this.state.settings,
                                    "staAutoDnsEnable": {
                                        ..._this.state.settings["staAutoDnsEnable"],
                                        value: "0"
                                    }
                                }
                            });
                        }
                    }
                },
                /* Wireless IP Address - INPUT IP */
                "staIpAddress": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless Subnet Mask - INPUT IP */
                "staNetmask": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless Default Gateway - INPUT IP */
                "staGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "display": "0",
                    "value": "0.0.0.0",
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoIpEnable"].value === "0") {
                            _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                            _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                            _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                            _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        }
                    }
                },
                /* Wireless DNS Assignment - RADIO BUTTON */
                "staAutoDnsEnable": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["staAutoIpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            //_this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Wireless Primary DNS Server - INPUT IP */
                "staPrimaryDns": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["staAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Wireless Secondary DNS Server - INPUT IP */
                "staSecondaryDns": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "display": "0",
                    "value": "",
                    "dependencies": ["staAutoDnsEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                            _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                            _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                            _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                        }
                    }
                },
                /* Local-Wifi Settings Title - TITLE */
                "LocalWifiSettingsTitle": {
                    "type": "title",
                    "title": "LocalWifiSettings",
                    "display": "0"
                },
                /* ScreenBeam Local Wi-Fi - RADIO BUTTON */
                "ApBsS1Enable": {
                    "type": "radioButton",
                    "label": "ScreenBeamLocalWifi",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                        _this.changeMap["ApBsSSIDIndex"] = "1";
                        delete _this.changeMap["ApBsS1Enable"];
                    }
                },
                /* Wireless Network Name - INPUT TEXT*/
                "ApBsS1Name": {
                    "type": "input",
                    "label": "WirelessNetworkName",
                    "value": "",
                    "info": "",
                    "display": "0",
                    "dependencies": ["ApBsS1Enable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 32) {
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSName"] = _this.changeMap["ApBsS1Name"]
                        _this.changeMap["ApBsSSIDIndex"] = "1"
                        delete _this.changeMap["ApBsS1Name"]
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value
                        _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                    },
                    "validate": (value) => {
                        if (value.length == 0) {
                            return "EmptyUsernameError"
                        }
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "ValidUsernameError"
                        }
                    }
                },
                /* Wireless Security Type - DROPDOWN */
                "ApBsS1SecurityType": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "SecurityType",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Open": "0",
                        "WPA2 PSK": "3"
                    },
                    "dependencies": ["ApBsS1Enable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSSecurityType"] = _this.changeMap["ApBsS1SecurityType"]
                        _this.changeMap["ApBsSSIDIndex"] = "1"
                        delete _this.changeMap["ApBsS1SecurityType"]
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                        _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value
                        _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                    }
                },
                /* Password Phrase - INPUT PASSWORD */
                "ApBsS1Passwd": {
                    "type": "password",
                    "value": "",
                    "label": "PasswordPhrase",
                    "info": "",
                    "display": "0",
                    "dependencies": ["ApBsS1SecurityType", "ApBsS1Enable"],
                    "dependencyMap": {
                        "00": "1",
                        "01": "1",
                        "30": "2"
                    },
                    "preValidate": (value) => {
                        if (value.length <= 64) {
                            return true;
                        }
                    },
                    "onChange": (_this) => {
                        _this.changeMap["ApBsSSPasswd"] = _this.changeMap["ApBsS1Passwd"];
                        _this.changeMap["ApBsSSIDIndex"] = "1";
                        delete _this.changeMap["ApBsS1Passwd"];
                        _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                        _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value;
                        _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                    },
                    "validate": (value) => {
                        if (value.length === 0) {
                            return "The password must not be empty";
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "Password must consist of alphanumeric characters or -";
                        }
                    }
                },
                /* Wireless Channel - DROPDOWN */
                "ApBsChannelNum": {
                    "type": "dropdown",
                    "value": "165",
                    "label": "WirelessChannel",
                    "info": "",
                    "display": "2",
                    "options": {
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                },
                /* Central Management System Settings - Title */
                "CentralMgtSystemTitle": {
                    "type": "title",
                    "title": "CentralManagementSystemSettings",
                    "display": "0"
                },
                /* CMS Server - INPUT TEXT */
                "mgtSrvFullAddr": {
                    "type": "input",
                    "value": "",
                    "label": "CmsServer",
                    "info": "",
                    "display": "2",
                    "validate": (value) => {
                        return check_cms_host(value);
                    }
                },
                /* CMS Communication Port - INPUT TEXT */
                "mgtSrvPort": {
                    "type": "input",
                    "value": "",
                    "label": "CmsCommunicationPort",
                    "info": "CmsCommunicationPortInfo",
                    "display": "2",
                    "preValidate": (value) => {
                        if (/^[0-9]*$/.test(value)) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        return check_cms_port(value);
                    }
                },
                /* Web Communication Port - INPUT TEXT */
                "webServerPort": {
                    "type": "input",
                    "value": "",
                    "label": "WebCommunicationPort",
                    "info": "WebCommunicationPortInfo",
                    "display": "0",
                    "preValidate": (value) => {
                        if (/^[0-9]*$/.test(value)) {
                            return true;
                        }
                    },
                    "validate": (value) => {
                        return check_web_port(value);
                    }
                },
                /* UPnP Discovery - RADIO BUTTON */
                "remoteMgtUPnPEn": {
                    "type": "radioButton",
                    "label": "UPnPDiscovery",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Local Management Wireless Access Settings Title - TITLE */
                "LocalMgtWirelessAccessTitle": {
                    "type": "title",
                    "title": "LocalManagementWirelessAccess",
                    "display": "0"
                },
                /* Local Management Interface Access - DROPDOWN */
                "webMgtOverWiFiEn": {
                    "type": "dropdown",
                    "label": "LocalManagementInterfaceAccess",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "2",
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onStart": (settings, data) => {
                        var tempInfo = "";
                        switch (settings["webMgtOverWiFiEn"].value) {
                            case "0":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessDisableInfo";
                                }
                                break;
                            case "1":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessEnableInfo";
                                }
                                break;
                            case "2":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessAutoInfo";
                                }
                                break;
                            default: break;
                        }
                        settings["webMgtOverWiFiEn"].info = tempInfo;
                        return settings["webMgtOverWiFiEn"];
                    },
                    "onChange": (_this) => {
                        var tempInfo = "";
                        switch (_this.state.settings["webMgtOverWiFiEn"].value) {
                            case "0":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessDisableInfo";
                                }
                                break;
                            case "1":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessEnableInfo";
                                }
                                break;
                            case "2":
                                {
                                    tempInfo = "LocalManagementInterfaceAccessAutoInfo";
                                }
                                break;
                            default: break;
                        }
                        setSettingState(_this, "webMgtOverWiFiEn", "info", tempInfo);
                    }
                },
                /*Allow access via LAN - DROPDOWN */
                "webMgtOverLanEn": {
                    "type": "dropdown",
                    "label": "AllowAccessViaLAN",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Display Setting Title - TITLE */
                "DisplaySettingTitle": {
                    "type": "title",
                    "title": "DisplaySetting",
                    "display": "0"
                },
                /*Display Sharing Mode - DROPDOWN*/
                "hijackingEn": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "HiJacking",
                    "info": "",
                    "display": "0",
                    "options": {
                        "HiJackingSingle": "0",
                        "HiJackingQuickSwitch": "1"
                    },
                    "dependencies": ["be_autonomousGoEn"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "0"
                    },
                    "onStart": (settings, data) => {
                        if (settings["hijackingEn"].value === "0") {
                            settings["hijackingEn"].info = "HiJackingSingleInfo";
                        } else {
                            settings["hijackingEn"].info = "HiJackingQuickSwitchInfo";
                        }
                        return settings["hijackingEn"];
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["hijackingEn"].value === "0") {
                            setSettingState(_this, "hijackingEn", "info", "HiJackingSingleInfo");
                        } else {
                            var strtmp = "";
                            if (_this.state.settings["showOverlayRxNameOpt"].value === "0") {
                                strtmp = "ShowRxNameAlwaysInfo";
                            }
                            else if (_this.state.settings["showOverlayRxNameOpt"].value === "1") {
                                strtmp = "ShowRxNameUponScanInfo";
                            } else {
                                strtmp = "ShowRxNameDonnotShowInfo";
                            }

                            setSettingState(_this, "hijackingEn", "info", "HiJackingQuickSwitchInfo",
                                () => {
                                    setSettingState(_this, "showOverlayRxNameOpt", "info", strtmp);
                                });
                        }
                    }
                },
                /*Show Receiver Name*/
                "showOverlayRxNameOpt": {
                    "type": "radioButton",
                    "value": "0",
                    "label": "ShowRxName",
                    "info": "",
                    "display": "0",
                    "checkbox": "ShowReceiverName",
                    "options": {
                        "ShowRxNameAlways": "0",
                        //"ShowRxNameUponScan": "1"
                        "ShowRxNameDonnotShow": "2"
                    },
                    "dependencies": ["be_autonomousGoEn", "hijackingEn"],
                    "dependencyMap": {
                        "00": "1",
                        "01": "1",
                        "02": "1",
                        "10": "1",
                        "11": "0",
                        "12": "0",
                    },
                    "onStart": (settings, data) => {
                        if (data["hijackingEn"] === "1") {
                            if (settings["showOverlayRxNameOpt"].value === "0") {
                                settings["showOverlayRxNameOpt"].info = "ShowRxNameAlwaysInfo";
                            }
                            else if (settings["showOverlayRxNameOpt"].value === "1") {
                                settings["showOverlayRxNameOpt"].info = "ShowRxNameUponScanInfo";
                            } else {
                                settings["showOverlayRxNameOpt"].info = "ShowRxNameDonnotShowInfo";
                            }
                        }
                        return settings["showOverlayRxNameOpt"];
                    },
                    "onChange": (_this) => {
                        if (_this.state.settings["showOverlayRxNameOpt"].value === "0") {
                            setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameAlwaysInfo");
                        } else if (_this.state.settings["showOverlayRxNameOpt"].value === "1") {
                            setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameUponScanInfo");
                        } else {
                            setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameDonnotShowInfo");
                        }
                    }
                },
                /* Receiver Name Placement - DROPDOWN */
                "overlayRxNamePlacement": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "ShowRxNamePlacement",
                    "info": "ShowRxNamePlacementInfo",
                    "display": "0",
                    "options": {
                        "ShowRxNamePlacementBL": "0",
                        "ShowRxNamePlacementBR": "1",
                        "ShowRxNamePlacementBCT": "2",
                        "ShowRxNamePlacementUL": "3",
                        "ShowRxNamePlacementUR": "4",
                        "ShowRxNamePlacementUCT": "5"
                    },
                    "dependencies": ["be_autonomousGoEn", "hijackingEn", "showOverlayRxNameOpt"],
                    "dependencyMap": {
                        "000": "1",
                        "001": "1",
                        "002": "1",
                        "010": "1",
                        "011": "1",
                        "012": "1",
                        "020": "1",
                        "021": "1",
                        "022": "1",
                        "100": "1",
                        "101": "1",
                        "102": "1",
                        "110": "0",
                        "111": "0",
                        "112": "1",
                        "120": "0",
                        "121": "0",
                        "122": "1"
                    }
                },
                /* PCR Synchronization - RADIO BUTTON */
                "PCRSyncEnable": {
                    "type": "radioButton",
                    "label": "PcrSync",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* HDCP Encryption - DROPDOWN */
                "HDCPEncrypMode": {
                    "type": "dropdown",
                    "label": "HdcpEncryption",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "0",
                        "Disable": "1",
                        "DemoMode": "2"
                    },
                },
                /* Aspect Ratio - DROPDOWN */
                "aspectRatio": {
                    "type": "dropdown",
                    "label": "AspectRatio",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "4:3": "0",
                        "16:9": "1",
                        "16:10": "2"
                    }
                },
                /* Horizontal/Veritcal Offset - RATIO */
                "scrHoffset": {
                    "type": "ratio",
                    "value": "0",
                    "display": "0",
                    "horizontalName": "scrHoffset",
                    "verticalName": "scrVoffset",
                    "label": "HorizontalVerticalOffset",
                    "onChange": (_this) => {
                        _this.changeMap["scrVoffset"] = _this.state.settings["scrVoffset"].value;
                    }
                },
                "scrVoffset": {
                    "value": "0",
                    "onChange": (_this) => {
                        _this.changeMap["scrHoffset"] = _this.state.settings["scrHoffset"].value;
                    }
                },
                /* Debug View - RADIO BUTTON */
                "debugViewEnable": {
                    "type": "radioButton",
                    "label": "DebugView",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    }
                },
                /* P2P IP Address - INPUT IP */
                "p2pIpAddr": {
                    "type": "ip",
                    "label": "P2PIpAddress",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                "be_p2pIpAddr": {
                    "type": "ip",
                    "label": "P2PIpAddress",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["be_p2pSubnet"] = _this.state.settings["be_p2pSubnet"].value;
                        _this.changeMap["be_p2pDhcpIpStart"] = _this.state.settings["be_p2pDhcpIpStart"].value;
                        _this.changeMap["be_p2pDhcpIpEnd"] = _this.state.settings["be_p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P Subnet Mask - INPUT IP */
                "p2pSubnet": {
                    "type": "ip",
                    "label": "P2PSubnetMask",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                "be_p2pSubnet": {
                    "type": "ip",
                    "label": "P2PSubnetMask",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["be_p2pIpAddr"] = _this.state.settings["be_p2pIpAddr"].value;
                        _this.changeMap["be_p2pDhcpIpStart"] = _this.state.settings["be_p2pDhcpIpStart"].value;
                        _this.changeMap["be_p2pDhcpIpEnd"] = _this.state.settings["be_p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P DHCP IP Start - INPUT IP */
                "p2pDhcpIpStart": {
                    "type": "ip",
                    "label": "P2PDhcpIpStart",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                    }
                },
                "be_p2pDhcpIpStart": {
                    "type": "ip",
                    "label": "P2PDhcpIpStart",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["be_p2pIpAddr"] = _this.state.settings["be_p2pIpAddr"].value;
                        _this.changeMap["be_p2pSubnet"] = _this.state.settings["be_p2pSubnet"].value;
                        _this.changeMap["be_p2pDhcpIpEnd"] = _this.state.settings["be_p2pDhcpIpEnd"].value;

                    }
                },
                /* P2P DHCP IP End - INPUT IP */
                "p2pDhcpIpEnd": {
                    "type": "ip",
                    "label": "P2PDhcpIpEnd",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                        _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                        _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;

                    }
                },
                "be_p2pDhcpIpEnd": {
                    "type": "ip",
                    "label": "P2PDhcpIpEnd",
                    "info": "",
                    "value": "0.0.0.0",
                    "display": "0",
                    "onChange": (_this) => {
                        _this.changeMap["be_p2pIpAddr"] = _this.state.settings["be_p2pIpAddr"].value;
                        _this.changeMap["be_p2pSubnet"] = _this.state.settings["be_p2pSubnet"].value;
                        _this.changeMap["be_p2pDhcpIpStart"] = _this.state.settings["be_p2pDhcpIpStart"].value;

                    }
                },
                /* P2P Wireless Network Name (SSID) - Special INPUT */
                "p2pSsid": {
                    "type": "postfixInput",
                    "label": "P2PWirelessNetworkName",
                    "info": "",
                    "display": "0",

                },
                "p2pNewSsidUserDef": {
                    "type": "postfixInput",
                    "label": "P2PWirelessNetworkName",
                    "info": "",
                    "prefix": "p2pNewSsidPrefix",
                    "display": "0",
                    "preValidate": (value, _this) => {
                        let prefixLength = _this.state.settings["p2pNewSsidPrefix"].value.length
                        if ((value.length - prefixLength) > 23) {
                            return false
                        }
                        return true
                    },
                    "onSubmit": (_this) => {
                        let value = _this.state.settings["p2pNewSsidUserDef"].value
                        let prefix = _this.state.settings["p2pNewSsidPrefix"].value
                        let result = value.split(prefix)[1]
                        _this.changeMap["p2pNewSsidPrefix"] = prefix
                        _this.changeMap["p2pNewSsidUserDef"] = result
                    }
                },
                "p2pNewSsidPrefix": {
                    "value": ""
                },
                /* P2P Wireless Network Password - INPUT PASSWORD */
                "p2pPassword": {
                    "type": "password",
                    "label": "P2PWirelessPassword",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "validate": (value) => {
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidLocalWiFiAPPasswordErr";
                        }
                    }
                },
                /* Fast-IP - RADIO BUTTON */
                "fastIPEn": {
                    "type": "radioButton",
                    "label": "FastIp",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* USB Local Access - DROPDOWN */
                "usbLocalAccessCtrl": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "UsbLocalAccess",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Disable": "0",
                        "OnlyAllowFirmwareUpdate": "1",
                        "Enable": "2"
                    }
                },
                ///* User Input Back Channel (UIBC) - DROPDOWN */
                "UIBCEnable": {
                    "type": "dropdown",
                    "value": "1",
                    "label": "Uibc",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Enable": "0",
                        "Disable": "1"
                    }
                },
                /* USB Over IP (UoIP) - DROPDOWN */
                "UOIPEnable": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "UoIp",
                    "info": "",
                    "display": "0",
                    "options": {
                        "NotImplemented": "0",
                        "Enable": "1",
                        "Disable": "2"
                    }
                },
                /* Maximum Video Resolution - DROPDOWN */
                "VideoMaxResolution": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "MaximumVideoResolution",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Auto": "0",
                        "1080": "1",
                        "720": "2",
                        "480": "3"
                    }
                },
                /* Audio Speaker - DROPDOWN */
                "AudioSpeakerChs": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "AudioSpeaker",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Auto": "0",
                        "2 Channels": "1",
                        "5.1 Channels": "2"
                    }
                },
                ///* HDMI-CEC - RADIO BUTTON*/
                "CECEnable": {
                    "type": "radioButton",
                    "label": "HdmiCec",
                    "info": "",
                    "display": "0",
                    "value": "0",
                    "options": {
                        "On": "1",
                        "Off": "0"
                    }
                },
                /* Firmware Upgrade - RADIO BUTON */
                "fwupdateAccess": {
                    "type": "radioButton",
                    "label": "FirmwareUpgradeViaUsb",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* DTLSSupport  - RADIO BUTTON */
                "DTLSSupport": {
                    "type": "radioButton",
                    "label": "DtlsSupport",
                    "info": "",
                    "value": "1",
                    "display": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                ///* Infracast IP Address - RADIO BUTTON*/
                "infracastIPEnable": {
                    "type": "radioButton",
                    "label": "InfracastIpAddress",
                    "info": "",
                    "value": "1",
                    "display": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                "LocalMgtInterfaceAccessTitle": {
                    "type": "title",
                    "title": "LocalManagementInterfaceAccess",
                    "display": "0"
                },
                ///* Wireless Network Name (SSID) - SB 960 - INPUT TEXT */
                "ApName": {
                    "type": "input",
                    "label": "WirelessNetworkName",
                    "value": "",
                    "info": "",
                    "display": "0"
                },
                /* Wireless Network Password - SB 960 - INPUT PASSWORD */
                "ApPassword": {
                    "type": "password",
                    "label": "WirelessNetworkPassword",
                    "value": "",
                    "info": "",
                    "display": "0"
                },
                /* Broadcast Network Name - RADIO BUTTON */
                "LMIBeaconEnable": {
                    "type": "radioButton",
                    "label": "BroadcastNetworkName",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* P2P Interface - RADIO BUTTON */
                "p2pInterfaceEnable": {
                    "type": "radioButton",
                    "label": "P2PInterace",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* Ethernet IP Assignment - SB 960 - RADIO BUTTON */
                "ethDhcpEnable": {
                    "type": "radioButton",
                    "label": "IpAssignment",
                    "info": "",
                    "value": "1",
                    "display": "0",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, true);
                    }
                },
                /* Ethernet IP Address - SB 960 - INPUT IP */
                "ethIpaddr": {
                    "type": "ip",
                    "label": "IpAddress",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, false);
                    }
                },
                /* Ethernet Subnet Mask - SB 960 - INPUT IP */
                "ethSubnet": {
                    "type": "ip",
                    "label": "SubnetMask",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, false);
                    }
                },
                /* Default Gateway - SB 960 - INPUT IP */
                "defaultGateway": {
                    "type": "ip",
                    "label": "DefaultGateway",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetTCPIPSettings(_this, false);
                    }
                },
                /* DNS Assignment - SB 960 - RADIO BUTTON */
                "DNSPolicy": {
                    "type": "radioButton",
                    "label": "DnsAssignment",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Auto": "1",
                        "Static": "0"
                    },
                    "dependencies": ["ethDhcpEnable"],
                    "dependencyMap": {
                        "0": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetDNSAssignmentSettings(_this);
                    }
                },
                /* Ethernet Primary DNS Server - SB 960 - INPUT IP*/
                "primaryDNS": {
                    "type": "ip",
                    "label": "PrimaryDnsServer",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["DNSPolicy"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetDNSAssignmentSettings(_this);
                    }
                },
                /* Ethernet Secondary DNS Server - SB 960 - INPUT IP */
                "secondDNS": {
                    "type": "ip",
                    "label": "SecondaryDnsServer",
                    "info": "",
                    "value": "",
                    "display": "0",
                    "dependencies": ["DNSPolicy"],
                    "dependencyMap": {
                        "1": "2"
                    },
                    "onChange": (_this) => {
                        this.setEthernetDNSAssignmentSettings(_this);
                    }
                },
                /* P2P Operating Channel - DROPDOWN */
                "be_autoGoChannel": {
                    "type": "dropdown",
                    "label": "P2POperatingChannel",
                    "info": "",
                    "value": "165",
                    "display": "0",
                    "dependencies": ["be_autonomousGoEn"],
                    "dependencyMap": {
                        "0": "1",
                        "1": "0"
                    },
                    "options": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    },
                    "onStart": (settings, data) => {
                        settings["be_autoGoChannel"].options = getP2POperatingChannel(data["be_channelPlan"]).channels;
                        settings["be_autoGoChannel"].info = getP2POperatingChannel(data["be_channelPlan"]).des;
                        return settings["be_autoGoChannel"];
                    }
                },
                /* USB Auto Config - RADIO BUTTON */
                "usbConfigEn": {
                    "type": "radioButton",
                    "label": "UsbAutoConfigure",
                    "info": "",
                    "display": "0",
                    "value": "1",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                /* P2P Connection Mode - RADIO BUTTON */
                "be_autonomousGoEn": {
                    "type": "radioButton",
                    "label": "P2PConnectionMode",
                    "info": "P2PConnectionModeInfo",
                    "value": "1",
                    "options": {
                        "NGO": "0",
                        "AGO": "1"
                    },
                    "onChange": (_this) => {
                        _this.changeMap["autonomousGoEn"] = _this.state.settings["be_autonomousGoEn"].value

                    }
                },
                /* VGA Compatibility Mode - DROPDOWN */
                "VGACompMode": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "VgaCompatibilityMode",
                    "info": "VgaCompatibilityModeInfo",
                    "options": {
                        "Disable": "0",
                        "1080": "1",
                        "720": "2",
                        "480": "3"
                    }
                },
                /* Default VGA Mode - DROPDOWN */
                "VGADefaultFormat": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "DefaultVgaMode",
                    "info": "DefaultVgaModeInfo",
                    "options": {
                        "Auto": "0",
                        "800x600": "1",
                        "1024x768": "2",
                        "1280x800": "3",
                        "1280x1024": "4",
                        "1366x768": "5"
                    }
                },
                /* Auto-reboot receiver when idle for - DROPDOWN */
                "InactivityRebootTime": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "AutoRebootReceiver",
                    "info": "",
                    "options": {
                        "Never": "0",
                        "6 hours": "1",
                        "8 hours": "2",
                        "10 hours": "3",
                        "12 hours": "4",
                        "24 hours": "5"
                    }
                },
                /* Undiscoverable Reboot */
                "undiscoverRebootEn": {
                    "type": "radioButton",
                    "value": "1",
                    "label": "UndiscoverRebootEnable",
                    "info": "",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                "switchP2pDongle": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "ConnectionMode",
                    "info": "",
                    "options": {
                        "Normal": "0",
                        "High": "1"
                    }
                },
                "remoteMgtWifiBand": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "WirelessBand",
                    "info": "",
                    "options": {
                        "Auto 2.4/5GHz": "0",
                        "Preferred 5GHz": "1"
                    }
                },
                "ChannelBandWidth": {
                    "type": "dropdown",
                    "value": "0",
                    "label": "ChannelBandwidth",
                    "info": "",
                    "options": {
                        "20MHz": "0",
                        "40MHz": "1"
                    }
                },
                "AdvancedFeatureTitle": {
                    "type": "title",
                    "title": "AdvancedFeature",
                    "display": "0"
                },
                "RemoteManagementTitle": {
                    "type": "title",
                    "title": "RemoteManagement",
                    "display": "0"
                },
                "WirelessLANSettingTitle": {
                    "type": "title",
                    "title": "WirelessLANSetting",
                    "display": "0"
                },
                "RMIWatchdogEn": {
                    "type": "radioButton",
                    "value": "0",
                    "label": "RMIWatchdog",
                    "info": "",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    }
                },
                "RMIWatchdogSec": {
                    "type": "input",
                    "value": "",
                    "label": "RMIWatchdogTimer",
                    "info": "RMIWatchdogTimerInfo",
                    "dependencies": ["RMIWatchdogEn"],
                    "dependencyMap": {
                        "0": "2",
                        "1": "0"
                    },
                    "onStart": (settings, data) => {
                        settings["RMIWatchdogSec"].value = data["RMIWatchdogSec"] / 60
                        return settings["RMIWatchdogSec"]
                    },
                    "onSubmit": (_this) => {
                        _this.changeMap["RMIWatchdogSec"] = _this.state.settings["RMIWatchdogSec"].value * 60
                    }
                },
                /*Enhanced Miracast P2P security - Radio Button*/
                "dynAgoPassphraseEn": {
                    "type": "radioButton",
                    "value": "0",
                    "label": "EnhancedMiracastP2Psecurity",
                    "info": "",
                    "display": "0",
                    "options": {
                        "Enable": "1",
                        "Disable": "0"
                    },
                    "onStart": (settings, data) => {
                        var tempInfo = "";
                        if (settings["dynAgoPassphraseEn"].value === "1") {
                            tempInfo = "EnhancedMiracastP2PsecurityEnableInfo";
                        }
                        else {
                            tempInfo = "EnhancedMiracastP2PsecurityDisableInfo";
                        }
                        settings["dynAgoPassphraseEn"].info = tempInfo;
                        return settings["dynAgoPassphraseEn"];
                    },
                    "onChange": (_this) => {
                        var tempInfo = "";
                        if (_this.state.settings["dynAgoPassphraseEn"].value === "1") {
                            tempInfo = "EnhancedMiracastP2PsecurityEnableInfo";
                        }
                        else {
                            tempInfo = "EnhancedMiracastP2PsecurityDisableInfo";
                        }
                        setSettingState(_this, "dynAgoPassphraseEn", "info", tempInfo);
                    }
                },
                "NGOListenChan": {
                    "type": "dropdown",
                    "value": "11",
                    "label": "ListenChannel",
                    "info": "",
                    "options": {
                        "1": "1",
                        "6": "6",
                        "11": "11"
                    }
                },
                "debugBitmap": {
                    "type": "input",
                    "value": "0",
                    "label": "DebugLevel",
                    "info": "0b",
                    "preValidate": (value, _this) => {
                        if (value.length == 0) {
                            return false;
                        }
                        if (value.length > 8) {
                            return false;
                        }
                        if (!(/[01]+$/.test(value))) {
                            return false;
                        }
                        return true
                    }
                },
                "P2PInterfaceSettingTitle": {
                    "type": "title",
                    "title": "P2PInterface",
                    "display": "0"
                },
                "be_channelPlan": {
                    "type": "dropdown",
                    "value": "67",
                    "label": "CountryRegion",
                    "info": "",
                    "options": {
                        "China": "50",
                        "United States, Canada, Taiwan, India, Colombia, Peru": "67",
                        "EuropeJapan": "66",
                        "AustraliaNewzealand": "41"
                    },
                    "onChange": (_this) => {
                        var opChannelList = getP2POperatingChannel(_this.state.settings["be_channelPlan"].value)
                        var channelList = opChannelList.channels
                        var arrayChannel = Object.keys(channelList);
                        var copyArray = []
                        for (var key of arrayChannel) {
                            copyArray.push(key)
                        }
                        setSettingState(_this, "opChannelList", "value", arrayChannel, () => {
                            setSettingState(_this, "preOpChannelList", "value", copyArray)
                        })
                    }
                },
                "opChannelList": {
                    "value": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "36", "40", "44", "48", "149", "153", "157", "161", "165"],
                    "onStart": (settings, data) => {
                        var value = data["opChannelList"]
                        var convertToArray = value.split(",")
                        settings["opChannelList"].value = convertToArray
                        return settings["opChannelList"]
                    },
                    "onSubmit": (_this) => {
                        var opChannelList = _this.state.settings["opChannelList"].value
                        _this.changeMap["opChannelList"] = opChannelList.toString()
                    }
                },
                "preOpChannelList": {
                    "type": "channel",
                    "opChannelList": "opChannelList",
                    "label": "OperatingChannel",
                    "info": "",
                    "value": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "36", "40", "44", "48", "149", "153", "157", "161", "165"],
                    "onStart": (settings, data) => {
                        var value = data["preOpChannelList"]
                        var convertToArray = value.split(",")
                        settings["preOpChannelList"].value = convertToArray
                        return settings["preOpChannelList"]
                    },
                    "onSubmit": (_this) => {
                        var preOpChannel = _this.state.settings["preOpChannelList"].value;
                        preOpChannel.sort(compareNumbers)
                        _this.changeMap["preOpChannelList"] = preOpChannel.toString();
                    }
                },
                "BackgroundCustom": {
                    "type": "file",
                    "label": "SetBackground",
                    "info": "BackgroundUrlInfo",
                    "value": ""
                }
            }
        };

        return Settings_9_15;
    }
}
function compareNumbers(a, b) {
    return a - b
}

export default AllSettings;