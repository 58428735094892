import SettingHelpers from './SettingHelpers'

class Settings_In_9_6 {
    static getSingleRxSettings = () => {
        return {
            "TabHeader": [
                "DeviceConfiguration",
                "Features",
                "NetworkSettings",
                "ManagementAccess"
            ],
            "TabContent": {
                "DeviceConfiguration": [
                    "RxNameAccess", // Device Name Access
                    "RxName", // Device Name
                    "manufacName", // Manufacture Name
                    "mdNameShort", // Model Name Short
                    "mdNameLong", // Model Name
                    "hwVersion", // Hardware Name
                    "fwVersion", // Firmware Version
                    "language", // Language
                    "RXHostname", // Host Name
                    "timezoneItem", // Time zone
                    "dstTimeEnable" // Daylight savings
                ],
                "Features": [
                    "SecuritySettingTitle", // Security Setting Title
                    "pinEnforceEnable", // Force PIN Pairing on First Connection
                    "pinEachConnEnable", // Require PIN on
                    "fourDigitPINEn", // PIN Length
                    "staticPinEnable", // PIN Generation Method
                    "staticPinVal", // Static PIN Val
                    "hdmiPortPowerManagement", // HDMI/VGA component
                    "scrSaverSecond", // Wait - Triggered when HDMI/VGA selects Screensaver or Display Off
                    "HDCPEncrypMode", // HDCP Encryption Mode
                    "usbLocalAccessCtrl", // USB local Access
                    "UIBCEnable", // User Input Back Channel (UIBC)
                    "UOIPEnable", // USB Over IP (UoIP)
                    "VideoMaxResolution", // Maximum Video Resolution
                    "AudioSpeakerChs", // Audio Speaker
                    "newOverScanVal", // Adjust TV Screen Size
                    "rtOverscanEnable", // Allow source device to override overscan value
                    "CECEnable", // HDMI-CEC
                    "fwupdateAccess",// Firmware Upgrade
                    "infracastEnable",// Infracast
                    "infracastPinEn",//Infracast Require PIN
                    "DTLSSupport",// DTLSSupport
                    "infracastIPEnable"// Infracast IP Address
                ],
                "NetworkSettings": [
                    "showMoreInfoEnable", // ScreenBeam Network Info
                    "hideReadyInfoEnable", // Connection Instruction
                    "ApName",      // Wireless Network Name
                    "ApPassword",   // Wireless Network Password
                    "LMIBeaconEnable",  // Broadcast Network Name
                    "P2PInterfaceSettingTitle",
                    "p2pInterfaceEnable",    // P2P Interface
                    "opChannelList",
                    "preOpChannelList"
                ],
                "ManagementAccess": [
                    "EthernetInterfaceSubtitle",
                    "ConnectionSettingsSubtitle",
                    "ethDhcpEnable",
                    "ethIpaddr",
                    "ethSubnet",
                    "defaultGateway",
                    "DNSPolicy",
                    "primaryDNS",
                    "secondDNS",
                    "CentralMgtSystemTitle",
                    "mgtSrvFullAddr",
                    "mgtSrvPort",
                    "webServerPort"
                ]
            }
        }
    }

    static getMultipleRxSettings = () => {
        return {
            "DeviceConfigurationGroupTitle": {
                "type": "title",
                "title": "DeviceConfiguration"
            },
            "DeviceNameAccess": {
                "type": "checkbox",
                "selected": "0",
                "label": "Device Name Access",
                "children": [
                    "RxNameAccess"
                ]
            },
            "Language": {
                "type": "checkbox",
                "selected": "0",
                "label": "Language",
                "children": [
                    "language" // Language
                ]
            },
            "Timezone": {
                "type": "checkbox",
                "selected": "0",
                "label": "Time zone",
                "children": [
                    "timezoneItem", // Time zone
                    "dstTimeEnable"
                ]
            },
            "FeaturesGroupTitle": {
                "type": "title",
                "title": "Features"
            },
            "PIN": {
                "type": "checkbox",
                "selected": "0",
                "label": "PIN",
                "children": [
                    "pinEnforceEnable",
                    "pinEachConnEnable",
                    "staticPinEnable",
                    "staticPinVal"
                ]
            },
            "HdmiPortPowerManagement": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI/VGA Port Power Management",
                "children": [
                    "hdmiPortPowerManagement",
                    "scrSaverSecond",
                    "scrHDMIOutEnable",
                    "scrSaverEnable"
                ]
            },
            "HdcpEncryption": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDCP Encryption",
                "children": [
                    "HDCPEncrypMode"
                ]
            },
            "USBLocalAccessSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "USB Local Access Settings",
                "children": [
                    "usbLocalAccessCtrl",
                    "UIBCEnable",
                    "UOIPEnable"
                ]
            },
            "TVAudioVideoSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "TV Audio/Video Settings",
                "children": [
                    "VideoMaxResolution",
                    "AudioSpeakerChs"
                ]
            },
            "TVScreenSize": {
                "type": "checkbox",
                "selected": "0",
                "label": "TV Screen Size",
                "children": [
                    "newOverScanVal",
                    "rtOverscanEnable"
                ]
            },
            "HDMI-CEC": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI-CEC",
                "children": [
                    "CECEnable"
                ]
            },
            "FirmwareUpgrade": {
                "type": "checkbox",
                "selected": "0",
                "label": "Firmware Upgrade",
                "children": [
                    "fwupdateAccess"
                ]
            },
            "MiracastoverNetwork": {
                "type": "checkbox",
                "selected": "0",
                "label": "Miracast over Network",
                "children": [
                    "infracastEnable"
                ]
            },
            "InfracastRequirePIN": {
                "type": "checkbox",
                "selected": "0",
                "label": "Infracast Require PIN",
                "children": [
                    "infracastPinEn"
                ]
            },
            "DTLSSupport": {
                "type": "checkbox",
                "selected": "0",
                "label": "DTLSSupport",
                "children": [
                    "DTLSSupport"
                ]
            },
            "InfracastIP": {
                "type": "checkbox",
                "selected": "0",
                "label": "Infracast IP",
                "children": [
                    "infracastIPEnable"
                ]
            },
            "NetworkSettingsGroupTitle": {
                "type": "title",
                "title": "NetworkSettings"
            },
            "NetworkInfo": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Information",
                "children": [
                    "showMoreInfoEnable"
                ]
            },
            "HideReadyInfo": {
                "type": "checkbox",
                "selected": "0",
                "label": "Hide Ready Info",
                "children": [
                    "hideReadyInfoEnable"
                ]
            },
            "NetworkNameBroadcast": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Name Broadcast",
                "children": [
                    "LMIBeaconEnable"
                ]
            },
            "P2PInterface": {
                "type": "checkbox",
                "selected": "0",
                "label": "P2P Interface",
                "children": [
                    "p2pInterfaceEnable",
                    "be_channelPlan",
                    "preOpChannelList",
                    "opChannelList"
                ],
                "dataToSend": [
                    "p2pInterfaceEnable",
                    "preOpChannelList",
                    "opChannelList"
                ]
            },
            "WebPort": {
                "type": "checkbox",
                "selected": "0",
                "label": "WEB Communication Port",
                "children": [
                    "webServerPort"

                ]
            }
        }
    }

    static getUSBProvisioningSettings = () => {
        return {
            "DeviceConfigurationGroupTitle": {
                "type": "title",
                "title": "DeviceConfiguration"
            },
            "DeviceNameAccess": {
                "type": "checkbox",
                "selected": "0",
                "label": "Device Name",
                "children": [
                    "RxNameAccess",
                    "RxName"
                ]
            },

            "LocalWebManagement": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Web Management",
                "children": [
                    "webUserName", // Administrator Name
                    "webPassword"  // Administrator Password
                ]
            },
            "Language": {
                "type": "checkbox",
                "selected": "0",
                "label": "Language",
                "children": [
                    "language" // Language
                ]
            },
            "HostName": {
                "type": "checkbox",
                "selected": "0",
                "label": "Host Name",
                "children": [
                    "RXHostname"
                ]
            },
            "Timezone": {
                "type": "checkbox",
                "selected": "0",
                "label": "Time zone",
                "children": [
                    "timezoneItem", // Time zone
                    "dstTimeEnable"
                ]
            },
            "FeaturesGroupTitle": {
                "type": "title",
                "title": "Features"
            },
            "PIN": {
                "type": "checkbox",
                "selected": "0",
                "label": "PIN",
                "children": [
                    "pinEnforceEnable",
                    "pinEachConnEnable",
                    "staticPinEnable",
                    "staticPinVal"
                ]
            },
            "HdmiPortPowerManagement": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI/VGA Port Power Management",
                "children": [
                    "hdmiPortPowerManagement",
                    "scrSaverSecond",
                    "scrHDMIOutEnable",
                    "scrSaverEnable"
                ]
            },
            "HdcpEncryption": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDCP Encryption",
                "children": [
                    "HDCPEncrypMode"
                ]
            },
            "USBLocalAccessSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "USB Local Access Settings",
                "children": [
                    "usbLocalAccessCtrl",
                    "UIBCEnable",
                    "UOIPEnable"
                ]
            },
            "TVAudioVideoSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "TV Audio/Video Settings",
                "children": [
                    "VideoMaxResolution",
                    "AudioSpeakerChs"
                ]
            },
            "TVScreenSize": {
                "type": "checkbox",
                "selected": "0",
                "label": "TV Screen Size",
                "children": [
                    "newOverScanVal",
                    "rtOverscanEnable"
                ]
            },
            "HDMI-CEC": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI-CEC",
                "children": [
                    "CECEnable"
                ]
            },
            "FirmwareUpgrade": {
                "type": "checkbox",
                "selected": "0",
                "label": "Firmware Upgrade",
                "children": [
                    "fwupdateAccess"
                ]
            },
            "MiracastoverNetwork": {
                "type": "checkbox",
                "selected": "0",
                "label": "Miracast over Network",
                "children": [
                    "infracastEnable"
                ]
            },
            "InfracastRequirePIN": {
                "type": "checkbox",
                "selected": "0",
                "label": "Infracast Require PIN",
                "children": [
                    "infracastPinEn"
                ]
            },
            "DTLSSupport": {
                "type": "checkbox",
                "selected": "0",
                "label": "DTLSSupport",
                "children": [
                    "DTLSSupport"
                ]
            },
            "InfracastIP": {
                "type": "checkbox",
                "selected": "0",
                "label": "Infracast IP",
                "children": [
                    "infracastIPEnable"
                ]
            },
            "NetworkSettingsGroupTitle": {
                "type": "title",
                "title": "NetworkSettings"
            },
            "NetworkInfo": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Information",
                "children": [
                    "showMoreInfoEnable"
                ]
            },
            "HideReadyInfo": {
                "type": "checkbox",
                "selected": "0",
                "label": "Hide Ready Info",
                "children": [
                    "hideReadyInfoEnable"
                ]
            },
            "WirelessLocalManagementInterfaceSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wireless Local Management Interface Settings",
                "children": [
                    "ApName",
                    "ApPassword"
                ]
            },
            "NetworkNameBroadcast": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Name Broadcast",
                "children": [
                    "LMIBeaconEnable"
                ]
            },
            "P2PInterface": {
                "type": "checkbox",
                "selected": "0",
                "label": "P2P Interface",
                "children": [
                    "p2pInterfaceEnable",
                    "be_channelPlan",
                    "preOpChannelList",
                    "opChannelList"
                ],
                "dataToSend": [
                    "p2pInterfaceEnable",
                    "preOpChannelList",
                    "opChannelList"
                ]
            },
            "ManagementAccessGroupTitle": {
                "type": "title",
                "title": "ManagementAccess"
            },
            "CMSSettingsforReceiver": {
                "type": "checkbox",
                "selected": "0",
                "label": "CMS Settings for Receiver",
                "children": [
                    "mgtSrvPort",
                    "mgtSrvFullAddr"

                ]
            },
            "WebPort": {
                "type": "checkbox",
                "selected": "0",
                "label": "WEB Communication Port",
                "children": [
                    "webServerPort"

                ]
            },
            "TCPIPSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "TCP/IP Settings",
                "children": [
                    "ethDhcpEnable",
                    "ethIpaddr",
                    "ethSubnet",
                    "defaultGateway",
                    "DNSPolicy",
                    "primaryDNS",
                    "secondDNS"
                ]
            },
            "SetBackgroundGroupTitle": {
                "type": "title",
                "title": "SetBackground"
            },
            "SetBackground": {
                "type": "checkbox",
                "selected": "0",
                "label": "Set Background",
                "children": [
                    "BackgroundCustom"
                ]
            }
        }
    }

    static getAllConfigParameters = () => {
        return {
            /* Device Name Access - RADIO BUTTON */
            "RxNameAccess": {
                "type": "radioButton",
                "label": "DeviceNameAccess",
                "info": "",
                //"disabled": false,
                "value": "1",
                "options": {
                    "Enable": "0",
                    "Disable": "1"
                }
            },
            /* Device Name - INPUT TEXT */
            "RxName": {
                "type": "input",
                "value": "",
                "label": "DeviceName",
                "info": "",
                "display": "0",
                "dependencies": ["RxNameAccess"],
                "dependencyMap": {
                    "0": "0",
                    "1": "2"
                },
                "preValidate": (value) => {
                    if (value.length <= 32) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    // if (value[0] === " " || valu  e[value.length - 1] === " ") {
                    //     return "EmptyDeviceNameError";
                    // }
                    // if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/]+$/.test(value)) {
                    //     return "InvalidDeviceNameError";
                    // }
                    return SettingHelpers.check_device_name(value, true);
                }
            },
            /* Manufacture Name - INPUT TEXT */
            "manufacName": {
                "type": "input",
                "value": "",
                "label": "ManufactureName",
                "info": "",
                "display": "2"
            },
            /* Model Name - INPUT TEXT */
            "mdNameLong": {
                "type": "input",
                "value": "",
                "label": "ModelName",
                "info": "",
                "display": "2"
            },
            /* Model Name Short - INPUT TEXT */
            "mdNameShort": {
                "type": "input",
                "value": "",
                "label": "ModelNameShort",
                "info": "",
                "display": "2"
            },
            /* Hardware Version - INPUT TEXT */
            "hwVersion": {
                "type": "input",
                "value": "",
                "label": "HardwareVersion",
                "info": "",
                "display": "2"
            },
            /* Firmware Version - INPUT TEXT */
            "fwVersion": {
                "type": "input",
                "value": "",
                "label": "FirmwareVersion",
                "info": "",
                "display": "2"
            },
            /* Admin Username - INPUT TEXT */
            "webUserName": {
                "type": "input",
                "value": "Administrator",
                "label": "AdminUsername",
                "info": "",
                "display": "0",
                "checkbox": "LocalWebManagement",
                "preValidate": (value) => {
                    if (value.length <= 16) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (value.length === 0) {
                        return "EmptyUsernameError";
                    }
                    if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                        return "InvalidUsernameError";
                    }
                }
            },
            /* Admin Password - INPUT PASSWORD*/
            "webPassword": {
                "type": "password",
                "value": "",
                "label": "AdminPassword",
                "info": "",
                "display": "0",
                "checkbox": "LocalWebManagement",
                "preValidate": (value) => {
                    if (value.length <= 16) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (value.length === 0) {
                        return "EmptyPasswordError";
                    }
                    if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                        return "InvalidPasswordError";
                    }
                }
            },
            /* Language - DROPDOWN */
            "language": {
                "type": "dropdown",
                "checkbox": "Language",
                "value": "en",
                "label": "Language",
                "info": "",
                "display": "0",
                "options": {
                    "English": "en",
                    "简体中文": "zhcs",
                    "正體中文": "zhct",
                    "日本語": "ja",
                    "Français": "fr",
                    "Deutsch": "ge",
                    "Nederlands": "du",
                    "한국어": "ko",
                    "Español": "sp",
                    "Italiano": "it",
                    "Русский": "ru",
                }
            },
            /* Host Name - INPUT TEXT */
            "RXHostname": {
                "type": "input",
                "value": "",
                "label": "HostName",
                "info": "",
                "display": "0",
                "preValidate": (value) => {
                    if (value.length <= 63) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (value.length === 0) {
                        return "EmptyHostNameError";
                    }
                    if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                        return "InvalidHostName";
                    }
                    if (value[0] === "-" || value[value.length - 1] === "-") {
                        return "InvalidHostName1";
                    }
                }
            },
            /* Time zone - DROPDOWN */
            "timezoneItem": {
                "type": "dropdown",
                "checkbox": "Timezone",
                "value": "5",
                "label": "Timezone",
                "info": "",
                "display": "0",
                "options": SettingHelpers.getTimezoneList("9.15"),
                "onChange": (_this, value) => {
                    // To make timezone setting work, CMS Service needs to know this piece of information
                    _this.changeMap["dstTimeEnable"] = _this.state.settings["dstTimeEnable"].value;
                }
            },
            /* Daylight - CHECKBOX */
            "dstTimeEnable": {
                "type": "checkbox",
                "value": "0",
                "label": "DaylightSavings",
                "info": "",
                "display": "0",
                "onChange": (_this, value) => {
                    // To make timezone setting work, CMS Service needs to know this piece of information
                    _this.changeMap["timezoneItem"] = _this.state.settings["timezoneItem"].value;
                }
            },
            /* Wireless Display Mode Title - TITLE */
            "WirelessDisplayModeTitle": {
                "type": "title",
                "title": "WirelessDisplayModeTitle",
                "display": "0"
            },
            /* Wireless Display Mode - DROPDOWN*/
            "WiFiOperateMode": {
                "type": "dropdown",
                "value": "5",
                "label": "WirelessDisplayMode",
                "info": "",
                "display": "0",
                "options": {
                    "CommanderMode": "1",
                    "StandardWirelessDisplay": "5"
                },
                "onChange": (_this) => {
                    var value = _this.state.settings.WiFiOperateMode.value;
                    if (value === "1") {
                        _this.setState({
                            "tabHeaders": [
                                "DeviceConfiguration",
                                "Features",
                                "NetworkSettings",
                                "ManagementAccess",
                                "AdvancedSettings"
                            ]
                        });
                    }
                    else {
                        _this.setState({
                            "tabHeaders": [
                                "DeviceConfiguration",
                                "Features",
                                "NetworkSettings",
                                "LocalWifi",
                                "ManagementAccess",
                                "AdvancedSettings"
                            ]
                        });
                    }
                }
            },
            /* Wireless Display over LAN Title - TITLE */
            "WirelessDisplayOverLANTitle": {
                "type": "title",
                "title": "WirelessDisplayOverLan",
                "display": "0",
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* Windows 10 - RADIO BUTTON */
            "infracastEnable": {
                "type": "radioButton",
                "label": "MiracastoverNetwork",
                "info": "Windows10Info",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* Infracast Require PIN  - RADIO BUTTON */
            "infracastPinEn": {
                "type": "radioButton",
                "label": "InfracastRequirePIN",
                "info": "",
                "display": "0",
                "checkbox": "InfracastRequirePIN",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode", "infracastEnable"],
                "dependencyMap": {
                    "11": "1",
                    "10": "1",
                    "51": "0",
                    "50": "2",
                }
            },
            /* macOS and windows 7 - RADIO BUTTON */
            "MacInfracastEn": {
                "type": "radioButton",
                "label": "MacWin7",
                "info": "MacWin7Info",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* macOS or iOS native screen mirroring - RADIO BUTTON */
            "airPlayEn": {
                "type": "radioButton",
                "label": "AirplayMode",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* P2P wireless setting title - TITLE */
            "P2PWirelessSettingTitle": {
                "type": "title",
                "title": "P2PWirelessSettings",
                "display": "0"
            },
            /* P2P Operating Channel ----- NEED TO DO */
            /* Transmit Power - DROPDOWN */
            "txPowerItem": {
                "type": "dropdown",
                "value": "1",
                "label": "TransmitPower",
                "info": "",
                "display": "0",
                "options": {
                    "High": "2",
                    "Medium": "1",
                    "Low": "0"
                }
            },
            /* Security Setting title - TITLE */
            "SecuritySettingTitle": {
                "type": "title",
                "title": "SecuritySettings",
                "display": "0"
            },
            /* Force PIN Pairing on First Connection - RADIO BUTTON*/
            "pinEnforceEnable": {
                "type": "radioButton",
                "label": "ForcePinPairing",
                "info": "ForcePinPairingInfo",
                "display": "0",
                "value": "1",
                "options": {
                    "On": "1",
                    "Off": "0"
                },
                "onStart": (settings, data) => {
                    if (settings["pinEnforceEnable"].value === "1") {
                        settings["pinEnforceEnable"].info = "ForcePinPairingInfo";
                    }
                    else {
                        settings["pinEnforceEnable"].info = "ForcePinPairingOffInfo";
                    }

                    return settings["pinEnforceEnable"];
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    if (_this.state.settings["pinEnforceEnable"].value === "1") {
                        SettingHelpers.setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingInfo");
                    } else {
                        SettingHelpers.setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingOffInfo");
                    }
                }
            },
            /* Require PIN on - RADIO BUTTON */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "pinEachConnEnable": {
                "type": "radioButton",
                "label": "RequirePinOn",
                "info": "RequirePinOnInfo",
                "display": "0",
                "value": "0",
                "options": {
                    "EachConnection": "1",
                    "FirstConnection": "0"
                },
                "dependencies": ["pinEnforceEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "onStart": (settings, data) => {
                    if (settings["pinEachConnEnable"].value == "-1") {
                        settings["pinEachConnEnable"].value = "1"
                    }
                    if (settings["pinEachConnEnable"].value === "1") {
                        settings["pinEachConnEnable"].info = "RequirePinOnEachConnInfo";
                    }
                    else {
                        settings["pinEachConnEnable"].info = "RequirePinOnInfo";
                    }

                    return settings["pinEachConnEnable"];
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    if (_this.state.settings["pinEachConnEnable"].value === "1") {
                        SettingHelpers.setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnEachConnInfo");
                    } else {
                        SettingHelpers.setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnInfo");
                    }
                }
            },
            /* PIN Length - DROPDOWN */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "fourDigitPINEn": {
                "type": "dropdown",
                "label": "PinLength",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "8Digit": "0",
                    "4Digit": "1"
                },
                "dependencies": ["pinEnforceEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    var strtemp = "";
                    var staticPinMaxLen = 4;
                    if (_this.state.settings["fourDigitPINEn"].value === "1") {
                        if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length < 4) {
                            strtemp = "StaticPin4Error";
                        }
                    }
                    else {
                        staticPinMaxLen = 7;
                        if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length !== 7) {
                            strtemp = "StaticPin8Error";
                        }
                    }
                    if (_this.state.settings["staticPinEnable"].value === "1") {
                        SettingHelpers.setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen,
                            () => {
                                SettingHelpers.setSettingState(_this, "staticPinVal", "error", strtemp);
                            });
                    } else {
                        SettingHelpers.setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen);
                    }
                }
            },
            /* PIN Generation Method - RADIO BUTTON */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "staticPinEnable": {
                "type": "radioButton",
                "label": "PinGenerationMethod",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Static": "1",
                    "Random": "0"
                },
                "dependencies": ["pinEnforceEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    /*if (_this.state.settings["staticPinEnable"].value === "1") {
                        setSettingState(_this, "staticPinEnable", "info", "PinGenerationMethodStaticInfo");
                    }
                    else {
                        setSettingState(_this, "staticPinEnable", "info", "PinGenerationMethodInfo");
                    }*/
                }
            },
            /* Static PIN Val - INPUT TEXT */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "staticPinVal": {
                "type": "pin",
                "value": "1234567",
                "label": "StaticPin",
                "info": "StaticPin8Info",
                "pinLength": "fourDigitPINEn",
                "display": "0",
                "maxLength": "7",
                "dependencies": ["pinEnforceEnable", "staticPinEnable"],
                "dependencyMap": {
                    "10": "1",
                    "00": "1",
                    "01": "1"
                },
                "onStart": (settings, data) => {
                    if (data["fourDigitPINEn"] === "0")
                        settings["staticPinVal"].maxLength = "7";
                    return settings["staticPinVal"];
                },
                "preValidate": (value, _this) => {
                    if (/^[0-9]+$/.test(value) || value === "") {
                        /*if (_this.state.settings["fourDigitPINEn"].value === "1" && value.length <= 4) {
                            return true;
                        }
                        if (_this.state.settings["fourDigitPINEn"].value === "0" && value.length <= 7) {
                            return true;
                        }*/
                        return true;
                    }
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    if (_this.state.settings["fourDigitPINEn"].value === "0") {
                        /*setSettingState(_this,
                            "fourDigitPINEn",
                            "info",
                            "Enter the first 7 digits of the PIN - the 8th digit will be generated for you. Static PIN will not appear on the HDTV/Projector to enable protected mode.");*/
                        if (_this.state.settings["staticPinVal"].value.length === 7) {
                            SettingHelpers.setSettingState(_this,
                                "staticPinVal",
                                "info", "StaticPin8Info");
                        }
                    }
                },
                "onSubmit": (_this) => {
                    // Slice the value if it is 4-digit PIN
                    if (_this.state.settings["fourDigitPINEn"].value == "1" && _this.state.settings["staticPinVal"].value.length > 4) {
                        _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value.slice(0, 4);
                    }  else {
                        _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value;                        
                    }
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["fourDigitPINEn"].value === "1" && value.length !== 4) {
                        return "StaticPin4Error";
                    }
                    if (_this.state.settings["fourDigitPINEn"].value === "0" && value.length !== 7) {
                        return "StaticPin8Error";
                    }
                }
            },
            /* PIN Display Period - INPUT TEXT */
            "PINDisplayPeriod": {
                "type": "input",
                "value": "60",
                "label": "PinDisplayPeriod",
                "info": "",
                "display": "0",
                "preValidate": (value) => {
                    if (/^[0-9]+$/.test(value) || value === "") {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (value > 120 || value < 30 || value === "") {
                        return "PinDisplayPeriodError";
                    }
                }
            },
            /* Display Setting Title - TITLE */
            "DisplaySettingTitle": {
                "type": "title",
                "title": "DisplaySettings",
                "display": "0"
            },
            /* ScreenBeam Network Info - DROPDOWN */
            "showMoreInfoEnable": {
                "type": "radioButton",
                "value": "1",
                "label": "ScreenBeamNetworkInfo960",
                "info": "",
                "display": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Connection Instruction - RADIO BUTTON */
            "hideReadyInfoEnable": {
                "type": "radioButton",
                "label": "HideReadyInfo",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* HDMI/VGA Port Power Management */
            "hdmiPortPowerManagement": {
                "type": "dropdown",
                "label": "HdmiVgaManagement",
                "value": "01",
                "display": "0",
                "info": "HdmiVgaManagementInfo",
                "isNotInOriginalData": true,
                "options": {
                    "AlwaysOn": "01",
                    "ScreenSaver": "11",
                    "DisplayOff": "10"
                },
                "onStart": (settings, data) => {
                    if (data["scrSaverEnable"] &&  data["scrHDMIOutEnable"]) {
                        let scrSaverEnable = data["scrSaverEnable"];
                        let scrHdmiOutEnable = data["scrHDMIOutEnable"];
                        settings["hdmiPortPowerManagement"].value = scrSaverEnable + scrHdmiOutEnable;
                    }
                    return settings["hdmiPortPowerManagement"];
                },
                "onChange": (_this, value, cb) => {
                    let selection = _this.state.settings["hdmiPortPowerManagement"].value;
                    _this.changeMap["scrSaverEnable"] = selection[0];
                    _this.changeMap["scrHDMIOutEnable"] = selection[1];
                    _this.changeMap["scrSaverSecond"] = _this.state.settings["scrSaverSecond"].value;
                    delete _this.changeMap["hdmiPortPowerManagement"];
                    _this.setState({
                        settings: {
                            ..._this.state.settings,
                            "scrHDMIOutEnable": {
                                ..._this.state.settings["scrHDMIOutEnable"],
                                value: selection[1]
                            },
                            "scrSaverEnable": {
                                ..._this.state.settings["scrSaverEnable"],
                                value: selection[0]
                            }
                        }
                    }, cb);
                }
            },
            "scrHDMIOutEnable": {
                "value": "1"
            },
            "scrSaverEnable": {
                "value": "0"
            },
            /* Wait - Triggered when HDMI/VGA selects Screensaver or Display Off - INPUT TEXT */
            "scrSaverSecond": {
                "type": "input",
                "label": "Wait",
                "info": "WaitInfo",
                "display": "1",
                "value": "180",
                "dependencies": ["hdmiPortPowerManagement"],
                "dependencyMap": {
                    "01": "1",
                    "11": "0",
                    "10": "0"
                },
                "preValidate": (value) => {
                    var result = SettingHelpers.check_screen_saver_second(value)
                    if (!result) return true
                    else return false
                },
                "validate": (value, _this) => {
                    if (value == "") {
                        return "ScreenSaverWaitEmptyError"
                    }

                    if (value < 1 || value > 9999) {
                        return "InvalidScreenSaverSecond"
                    }
                }
            },
            /* Wake up - DROPDOWN */
            "wakedByScan": {
                "type": "dropdown",
                "label": "Wakeup",
                "info": "",
                "value": "0",
                "options": {
                    "OnConnect": "0",
                    "OnScan": "1"
                },
                "display": "2",
                "dependencies": ["hdmiPortPowerManagement"],
                "dependencyMap": {
                    "01": "2",
                    "11": "0",
                    "10": "0"
                }
            },
            /* Adjust TV Screen Size - DROPDOWN */
            "newOverScanVal": {
                "type": "dropdown",
                "value": "25",
                "label": "AdjustTvScreenSize",
                "info": "",
                "display": "0",
                "options": {
                    "0": "0",
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "12": "12",
                    "13": "13",
                    "14": "14",
                    "15": "15",
                    "16": "16",
                    "17": "17",
                    "18": "18",
                    "19": "19",
                    "20": "20",
                    "21": "21",
                    "22": "22",
                    "23": "23",
                    "24": "24",
                    "25": "25"
                },
                "onSubmit": (_this) => {
                    // Need to send rtOverscanEnable together
                    _this.changeMap["newOverScanVal"] = _this.state.settings["newOverScanVal"].value;                                        
                    _this.changeMap["rtOverscanEnable"] = _this.state.settings["rtOverscanEnable"].value;
                }
            },
            /* Allow source device to override overscan value */
            "rtOverscanEnable": {
                "type": "checkbox",
                "label": "OverrideOverScanVal",
                "info": "",
                "display": "0",
                "value": "0"
            },
            /* Receiver Logging - RADIO BUTTON */
            "RxCsvLogEn": {
                "type": "radioButton",
                "label": "ReceiverLogging",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Network Mode - DROPDOWN */
            "NetworkMode": {
                "type": "dropdown",
                "value": "1",
                "label": "NetworkMode",
                "info": "",
                "display": "0",
                "options": {
                    "Nat": "1",
                    "Bridge": "2",
                    "None": "0"
                }
            },
            /* Local Wifi Network Title - TITLE */
            "LocalWifiNetworkTitle": {
                "type": "title",
                "title": "LocalWifiNetwork",
                "display": "0"
            },
            /* Interface Feature Assignment Title - TITLE */
            "InterfaceFeatureTitle": {
                "type": "title",
                "title": "InterfaceFeatureAssignment",
                "display": "0"
            },
            /* CMS Interface - DROPDOWN */
            "cmsInterface": {
                "type": "dropdown",
                "value": "0",
                "label": "CmsInterface",
                "info": "",
                "display": "2",
                "options": {
                    "Auto": "0"
                },
                "dependencies": ["NetworkMode"],
                "dependencyMap": {
                    "0": "1"
                }
            },
            /* Internet WAN Interface - DROPDOWN */
            "apBridgeInterface": {
                "type": "dropdown",
                "value": "1",
                "label": "InternetWanInterface",
                "info": "",
                "display": "0",
                "options": {
                    "Ethernet": "1",
                    "Wireless": "2"
                }
            },
            /* Network Interface Settings Title - TITLE */
            "NetworkInterfaceTitle": {
                "type": "title",
                "title": "NetworkInterfaceSettings",
                "display": "0"
            },
            /* Ethernet Interface Subtitle - SUBTITLE */
            "EthernetInterfaceSubtitle": {
                "type": "subtitle",
                "title": "EthernetInterface",
                "display": "0"
            },
            /* Network Name - INPUT TEXT */
            "EthNetworkName": {
                "type": "input",
                "label": "NetworkName",
                "info": "",
                "display": "0",
                "value": "",
                "preValidate": (value) => {
                    if (value.length <= 16) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (value.length === 0) {
                        return "EmptyNetworkNameError";
                    }
                    if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                        return "InvalidNetworkNameError";
                    }
                }
            },
            /* Connection Settings Subtitle - SUBTITLE */
            "ConnectionSettingsSubtitle": {
                "type": "subtitle",
                "title": "ConnectionSettings",
                "display": "0"
            },
            /* Ethernet Connection Authentication - DROPDOWN */
            "CurEthAuthentication": {
                "type": "dropdown",
                "value": "0",
                "label": "Authentication",
                "info": "",
                "display": "0",
                "options": {
                    "Open": "0",
                    "PEAP-MSCHAPV2": "2",
                    "EAP-TLS": "3"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["CurEthAuthentication"] !== "0") {
                        _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                        _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                        _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                    }
                }
            },
            /* Ethernet Connection Username - INPUT TEXT */
            // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
            "CurEthRadiusID": {
                "type": "input",
                "value": "",
                "label": "Username",
                "info": "",
                "display": "0",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "0",
                    "3": "0"
                },
                "preValidate": (value) => {
                    if (value.length <= 64) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurEthAuthentication"].value == "2"
                        || _this.state.settings["CurEthAuthentication"].value == "3") {
                        if (value.length == 0) return "EmptyUsernameError";
                        if (!(/^[a-zA-Z0-9\s._@#$-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    }
                },
                "onChange": (_this) => {
                    if (_this.state.settings["CurEthAuthentication"] !== "0") {
                        _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                        _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                    }
                }
            },
            /* Ethernet Connection Password - INPUT PASSWORD */
            // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
            "CurEthRadiusPWD": {
                "type": "password",
                "value": "",
                "label": "Password",
                "info": "",
                "display": "0",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "0",
                    "3": "0"
                },
                "preValidate": (value) => {
                    if (value.length <= 64) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurEthAuthentication"].value == "2"
                        || _this.state.settings["CurEthAuthentication"].value == "3") {
                        if (value.length == 0) return "EmptyPasswordError";
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                            return "InvalidPasswordError";
                        }
                    }
                },
                "onChange": (_this) => {
                    if (_this.state.settings["CurEthAuthentication"] !== "0") {
                        _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                        _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                    }
                }
            },
            // NEED TO ADD SYSTEM DATE & TIME, CA CERTIFICATE, USER CERTIFICATE, PRIVATE KEY, VALIDITY PERIOD
            /* System Date & Time */
            "EthCertDateTime": {
                "type": "input",
                "label": "SystemDateTime",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                }
            },
            /* Ethernet - CA Certificate URL - DISABLED INPUT TEXT */
            "EthRootCertURL": {
                "type": "input",
                "label": "CaCertificateUrl",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                }
                //"type": "input",
                //"label": "CA Certificate URL",
                //"info": "",
                //"disabled": true,
                //"displayMode": { "controlItem": "CurEthAuthentication", "controlValue": ["0", "2"], "display": "1" }
            },
            /* Ethernet - User Certificate - DISABLED INPUT TEXT */
            "EthUserCertURL": {
                "type": "input",
                "label": "UserCertificateUrl",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                }
            },
            /* Ethernet - Private Key URL - DISABLED INPUT TEXT */
            "EthUserKeyURL": {
                "type": "input",
                "label": "PrivateKeyUrl",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                }
            },
            /* Ethernet - Validity Period - INPUT TEXT */
            "EthCertsValidity": {
                "type": "input",
                "label": "ValidityPeriod",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                }
            },
            /* TCP/IP Settings Subtitle - SUBTITLE */
            "TCPIPSettingsSubtitle": {
                "type": "subtitle",
                "title": "TcpIpSettings",
                "display": "0"
            },
            /* Ethernet IP Assignment - RADIO BUTTON */
            "ethAutoIpEnable": {
                "type": "radioButton",
                "label": "IpAssignment",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        _this.changeMap["ethAutoDnsEnable"] = "0";
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;

                        _this.setState({
                            settings: {
                                ..._this.state.settings,
                                "ethAutoDnsEnable": {
                                    ..._this.state.settings["ethAutoDnsEnable"],
                                    value: "0"
                                }
                            }
                        });
                    }

                }
            },
            /* Ethernet IP Address - INPUT IP */
            "ethIpAddress": {
                "type": "ip",
                "label": "IpAddress",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                    }

                }
            },
            /* Ethernet Subnet Mask - INPUT IP */
            "ethNetmask": {
                "type": "ip",
                "label": "SubnetMask",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                    }

                }
            },
            /* Default Gateway - INPUT IP */
            "ethGateway": {
                "type": "ip",
                "label": "DefaultGateway",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                    }

                }
            },
            /* DNS Assignment - RADIO BUTTON */
            "ethAutoDnsEnable": {
                "type": "radioButton",
                "label": "DnsAssignment",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                        //_this.changeMap["ethAutoDnsEnable"] = "0";
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                    }

                }
            },
            /* Ethernet Primary DNS Server */
            "ethPrimaryDns": {
                "type": "ip",
                "label": "PrimaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["ethAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                        _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                    }

                }
            },
            /* Ethernet Secondary DNS Server */
            "ethSecondaryDns": {
                "type": "ip",
                "label": "SecondaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["ethAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                        _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                    }

                }
            },
            /* Wireless Interface Subtitle - SUBTITLE */
            "WirelessInterfaceSubtitle": {
                "type": "subtitle",
                "title": "WirelessInterface",
                "display": "0"
            },
            /* Network Name - INPUT TEXT */
            "CurConnectApName": {
                "type": "input",
                "label": "NetworkName",
                "value": "",
                "info": "",
                "display": "0",
                "preValidate": (value) => {
                    if (value.length <= 64) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (value.length === 0) {
                        return "EmptyNetworkNameError";
                    }
                    if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];,\.\/]+$/.test(value)) {
                        return "InvalidNetworkNameError";
                    }
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                }
            },
            /* Security Type - DROPDOWN */
            "CurConnectApSecType": {
                "type": "dropdown",
                "value": "0",
                "label": "SecurityType",
                "info": "",
                "display": "0",
                "options": {
                    "Open": "0",
                    "Shared": "1",
                    "WPA-PSK[TKIP]": "2",
                    "WPA2-PSK[AES]": "3",
                    "WPA-PSK[TKIP]+WPA2-PSK[AES]": "4",
                    "PEAP-MSCHAPV2": "5",
                    "EAP-TLS": "6"
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                }
            },
            /* Username - INPUT TEXT */
            "CurConnectApRadiusId": {
                "type": "input",
                "value": "",
                "label": "Username",
                "info": "",
                "display": "0",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "0",
                    "6": "0"
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurConnectApSecType"].value == "5" || _this.state.settings["CurConnectApSecType"].value == "6") {
                        if (value.length == 0) return "EmptyUsernameError";
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    }
                }
            },
            /* Password - INPUT PASSWORD */
            "CurConnectApPwd": {
                "type": "password",
                "value": "",
                "label": "Password",
                "info": "",
                "display": "0",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "0",
                    "2": "0",
                    "3": "0",
                    "4": "0",
                    "5": "0",
                    "6": "0"
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurConnectApSecType"].value == "1"
                        || _this.state.settings["CurConnectApSecType"].value == "2"
                        || _this.state.settings["CurConnectApSecType"].value == "3"
                        || _this.state.settings["CurConnectApSecType"].value == "4"
                        || _this.state.settings["CurConnectApSecType"].value == "5"
                        || _this.state.settings["CurConnectApSecType"].value == "6") {
                        if (value.length == 0) return "EmptyPasswordError";
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                            return "InvalidPasswordError";
                        }
                    }
                }
            },
            /* Wireless - Date Time - DISABLED INPUT */
            "TLSCertDateTime": {
                "type": "input",
                "value": "",
                "label": "SystemDateTime",
                "info": "",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless - CA Certificate URL - DISABLE INPUT */
            "TLSRootCertURL": {
                "type": "input",
                "value": "",
                "label": "CaCertificateUrl",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless - User Certificate URL - DISABLE INPUT */
            "TLSUserCertURL": {
                "type": "input",
                "value": "",
                "label": "UserCertificateUrl",
                "info": "",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless - Private Key URL - DISABLE INPUT */
            "TLSUserKeyURL": {
                "type": "input",
                "value": "",
                "label": "PrivateKeyUrl",
                "info": "",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless IP Assignment - RADIO BUTTON */
            "staAutoIpEnable": {
                "type": "radioButton",
                "label": "IpAssignment",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        _this.changeMap["staAutoDnsEnable"] = "0";
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;

                        _this.setState({
                            settings: {
                                ..._this.state.settings,
                                "staAutoDnsEnable": {
                                    ..._this.state.settings["staAutoDnsEnable"],
                                    value: "0"
                                }
                            }
                        });
                    }
                }
            },
            /* Wireless IP Address - INPUT IP */
            "staIpAddress": {
                "type": "ip",
                "label": "IpAddress",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                    }
                }
            },
            /* Wireless Subnet Mask - INPUT IP */
            "staNetmask": {
                "type": "ip",
                "label": "SubnetMask",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                    }
                }
            },
            /* Wireless Default Gateway - INPUT IP */
            "staGateway": {
                "type": "ip",
                "label": "DefaultGateway",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                    }
                }
            },
            /* Wireless DNS Assignment - RADIO BUTTON */
            "staAutoDnsEnable": {
                "type": "radioButton",
                "label": "DnsAssignment",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                        //_this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                    }
                }
            },
            /* Wireless Primary DNS Server - INPUT IP */
            "staPrimaryDns": {
                "type": "ip",
                "label": "PrimaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["staAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                        _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                    }
                }
            },
            /* Wireless Secondary DNS Server - INPUT IP */
            "staSecondaryDns": {
                "type": "ip",
                "label": "SecondaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["staAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                        _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                    }
                }
            },
            /* Local-Wifi Settings Title - TITLE */
            "LocalWifiSettingsTitle": {
                "type": "title",
                "title": "LocalWifiSettings",
                "display": "0"
            },
            /* ScreenBeam Local Wi-Fi - RADIO BUTTON */
            "ApBsS1Enable": {
                "type": "radioButton",
                "label": "ScreenBeamLocalWifi",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "onChange": (_this) => {
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                    _this.changeMap["ApBsSSIDIndex"] = "1";
                    delete _this.changeMap["ApBsS1Enable"];
                }
            },
            /* Wireless Network Name - INPUT TEXT*/
            "ApBsS1Name": {
                "type": "input",
                "label": "WirelessNetworkName",
                "value": "",
                "info": "",
                "display": "0",
                "dependencies": ["ApBsS1Enable"],
                "dependencyMap": {
                    "0": "2"
                },
                "preValidate": (value) => {
                    if (value.length <= 32) {
                        return true;
                    }
                },
                "onChange": (_this) => {
                    _this.changeMap["ApBsSSName"] = _this.changeMap["ApBsS1Name"]
                    _this.changeMap["ApBsSSIDIndex"] = "1"
                    delete _this.changeMap["ApBsS1Name"]
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                    _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value
                    _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                },
                "validate": (value, _this) => {
                    if (value.length == 0) {
                        return "EmptyUsernameError"
                    }
                    if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                        return "InvalidUsernameError"
                    }
                }
            },
            /* Wireless Security Type - DROPDOWN */
            "ApBsS1SecurityType": {
                "type": "dropdown",
                "value": "0",
                "label": "SecurityType",
                "info": "",
                "display": "0",
                "options": {
                    "Open": "0",
                    "WPA2 PSK": "3"
                },
                "dependencies": ["ApBsS1Enable"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    _this.changeMap["ApBsSSSecurityType"] = _this.changeMap["ApBsS1SecurityType"]
                    _this.changeMap["ApBsSSIDIndex"] = "1"
                    delete _this.changeMap["ApBsS1SecurityType"]
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                    _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value
                    _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                }
            },
            /* Password Phrase - INPUT PASSWORD */
            "ApBsS1Passwd": {
                "type": "password",
                "value": "",
                "label": "PasswordPhrase",
                "info": "",
                "display": "0",
                "dependencies": ["ApBsS1SecurityType", "ApBsS1Enable"],
                "dependencyMap": {
                    "00": "1",
                    "01": "1",
                    "30": "2"
                },
                "preValidate": (value) => {
                    if (value.length <= 64) {
                        return true;
                    }
                },
                "onChange": (_this) => {
                    _this.changeMap["ApBsSSPasswd"] = _this.changeMap["ApBsS1Passwd"];
                    _this.changeMap["ApBsSSIDIndex"] = "1";
                    delete _this.changeMap["ApBsS1Passwd"];
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                    _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value;
                    _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                },
                "validate": (value, _this) => {
                    if (value.length === 0) {
                        return "EmptyPasswordError";
                    }
                    if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                        return "InvalidLocalWiFiAPPasswordErr";
                    }
                }
            },
            /* Wireless Channel - DROPDOWN */
            "ApBsChannelNum": {
                "type": "dropdown",
                "value": "165",
                "label": "WirelessChannel",
                "info": "",
                "display": "2",
                "options": {
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            },
            /* Central Management System Settings - Title */
            "CentralMgtSystemTitle": {
                "type": "title",
                "title": "CentralManagementSystemSettings",
                "display": "0"
            },
            /* CMS Server - INPUT TEXT */
            "mgtSrvFullAddr": {
                "type": "input",
                "value": "",
                "label": "CmsServer",
                "info": "",
                "display": "2",
                "validate": (value, _this) => {
                    return SettingHelpers.check_cms_host(value);
                }
            },
            /* CMS Communication Port - INPUT TEXT */
            "mgtSrvPort": {
                "type": "input",
                "value": "",
                "label": "CmsCommunicationPort",
                "info": "CmsCommunicationPortInfo",
                "display": "2",
                "preValidate": (value) => {
                    if (/^[0-9]*$/.test(value)) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    return SettingHelpers.check_cms_port(value);
                }
            },
            /* Web Communication Port - INPUT TEXT */
            "webServerPort": {
                "type": "input",
                "value": "",
                "label": "WebCommunicationPort",
                "info": "WebCommunicationPortInfo",
                "display": "0",
                "preValidate": (value) => {
                    if (/^[0-9]*$/.test(value)) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    return SettingHelpers.check_web_port(value);
                }
            },
            /* Local Management Wireless Access Settings Title - TITLE */
            "LocalMgtWirelessAccessTitle": {
                "type": "title",
                "title": "LocalManagementWirelessAccess",
                "display": "0"
            },
            /* Local Management Interface Access - DROPDOWN */
            "webMgtOverWiFiEn": {
                "type": "dropdown",
                "label": "LocalManagementInterfaceAccess",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "2",
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Allow Force Disconnection - RADIO BUTTON */
            "hijackingEn": {
                "type": "radioButton",
                "label": "AllowForceDisconnection",
                "info": "AllowForceDisconnectionInfo",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* PCR Synchronization - RADIO BUTTON */
            "PCRSyncEnable": {
                "type": "radioButton",
                "label": "PcrSync",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* HDCP Encryption - DROPDOWN */
            "HDCPEncrypMode": {
                "type": "dropdown",
                "label": "HdcpEncryption",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "0",
                    "Disable": "1",
                    "DemoMode": "2"
                },
            },
            /* Aspect Ratio - DROPDOWN */
            "aspectRatio": {
                "type": "dropdown",
                "label": "AspectRatio",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "4:3": "0",
                    "16:9": "1",
                    "16:10": "2"
                }
            },
            /* Horizontal/Veritcal Offset - RATIO */
            "scrHoffset": {
                "type": "ratio",
                "value": "0",
                "display": "0",
                "horizontalName": "scrHoffset",
                "verticalName": "scrVoffset",
                "label": "HorizontalVerticalOffset",
                "onChange": (_this) => {
                    _this.changeMap["scrVoffset"] = _this.state.settings["scrVoffset"].value;
                }
            },
            "scrVoffset": {
                "value": "0",
                "onChange": (_this) => {
                    _this.changeMap["scrHoffset"] = _this.state.settings["scrHoffset"].value;
                }
            },
            /* Debug View - RADIO BUTTON */
            "debugViewEnable": {
                "type": "radioButton",
                "label": "DebugView",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "On": "1",
                    "Off": "0"
                }
            },
            /* P2P IP Address - INPUT IP */
            "p2pIpAddr": {
                "type": "ip",
                "label": "P2PIpAddress",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                    _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                    _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                }
            },
            /* P2P Subnet Mask - INPUT IP */
            "p2pSubnet": {
                "type": "ip",
                "label": "P2PSubnetMask",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                    _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                    _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                }
            },
            /* P2P DHCP IP Start - INPUT IP */
            "p2pDhcpIpStart": {
                "type": "ip",
                "label": "P2PDhcpIpStart",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                    _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                    _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                }
            },
            /* P2P DHCP IP End - INPUT IP */
            "p2pDhcpIpEnd": {
                "type": "ip",
                "label": "P2PDhcpIpEnd",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                    _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                    _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;

                }
            },
            /* P2P Wireless Network Name (SSID) - Special INPUT */
            "p2pSsid": {
                "type": "postfixInput",
                "label": "P2PWirelessNetworkName",
                "info": "",
                "prefix": "DIRECT-xy",
                "display": "0",
                "preValidate": (value, _this) => {
                    let prefixLength = _this.state.settings["p2pSsid"].prefix.length
                    if ((value.length - prefixLength) > 23) {
                        return false
                    }
                    return true
                },
                "validate": (value, _this) => {
                    if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                        return "InvalidNetworkNameError";
                    }
                }
            },
            /* P2P Wireless Network Password - INPUT PASSWORD */
            "p2pPassword": {
                "type": "password",
                "label": "P2PWirelessPassword",
                "info": "",
                "value": "",
                "display": "0",
                "validate": (value, _this) => {
                    if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                        return "InvalidLocalWiFiAPPasswordErr";
                    }
                }
            },
            /* Fast-IP - RADIO BUTTON */
            "fastIPEn": {
                "type": "radioButton",
                "label": "FastIp",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            ///* USB Local Access - DROPDOWN */
            "usbLocalAccessCtrl": {
                "type": "dropdown",
                "value": "2",
                "label": "UsbLocalAccess",
                "info": "",
                "display": "0",
                "options": {
                    "Disable": "0",
                    "OnlyAllowFirmwareUpdate": "1",
                    "Enable": "2"
                }
            },
            ///* User Input Back Channel (UIBC) - DROPDOWN */
            "UIBCEnable": {
                "type": "dropdown",
                "value": "0",
                "label": "Uibc",
                "info": "",
                "display": "0",
                "options": {
                    "Enable": "0",
                    "Disable": "1"
                },
                "dependencies": ["usbLocalAccessCtrl", "UOIPEnable"],
                "dependencyMap": {
                    "00": "2",
                    "01": "2",
                    "02": "2",
                    "10": "2",
                    "11": "2",
                    "12": "2",
                    "20": "0",
                    "21": "2",
                    "22": "0"
                },
            },
            /* USB Over IP (UoIP) - DROPDOWN */
            "UOIPEnable": {
                "type": "dropdown",
                "value": "2",
                "label": "UoIp",
                "info": "",
                "display": "0",
                "options": {
                    "NotImplemented": "0",
                    "Enable": "1",
                    "Disable": "2"
                },
                "dependencies": ["usbLocalAccessCtrl", "UIBCEnable"],
                "dependencyMap": {
                    "00": "2",
                    "01": "2",
                    "10": "2",
                    "11": "2",
                    "20": "2",
                    "21": "0"
                },
            },
            /* Maximum Video Resolution - DROPDOWN */
            "VideoMaxResolution": {
                "type": "dropdown",
                "value": "0",
                "label": "MaximumVideoResolution",
                "info": "",
                "display": "0",
                "options": {
                    "Auto": "0",
                    "1080": "1",
                    "720": "2",
                    "480": "3"
                }
            },
            /* Audio Speaker - DROPDOWN */
            "AudioSpeakerChs": {
                "type": "dropdown",
                "value": "0",
                "label": "AudioSpeaker",
                "info": "",
                "display": "0",
                "options": {
                    "Auto": "0",
                    "2 Channels": "1",
                    "5.1 Channels": "2"
                }
            },
            ///* HDMI-CEC - RADIO BUTTON*/
            "CECEnable": {
                "type": "radioButton",
                "label": "HdmiCec",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "On": "1",
                    "Off": "0"
                }
            },
            /* Firmware Upgrade - RADIO BUTON */
            "fwupdateAccess": {
                "type": "radioButton",
                "label": "FirmwareUpgrade",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            ///* DTLSSupport  - RADIO BUTTON */
            "DTLSSupport": {
                "type": "radioButton",
                "label": "DtlsSupport",
                "info": "",
                "value": "1",
                "display": "0",
                "dependencies": ["WiFiOperateMode", "infracastEnable", "infracastPinEn"],
                "dependencyMap": {
                    "110": "1",
                    "111": "1",
                    "100": "1",
                    "101": "1",
                    "510": "0",
                    "511": "2",
                    "500": "2",
                    "501": "2"
                },
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            ///* Infracast IP Address - RADIO BUTTON*/
            "infracastIPEnable": {
                "type": "radioButton",
                "label": "InfracastIpAddress",
                "info": "",
                "value": "1",
                "display": "0",
                "dependencies": ["WiFiOperateMode", "infracastEnable"],
                "dependencyMap": {
                    "10": "1",
                    "11": "1",
                    "50": "2",
                    "51": "0"
                },
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            ///* Wireless Network Name (SSID) - SB 960 - INPUT TEXT */
            "ApName": {
                "type": "input",
                "label": "WirelessNetworkName",
                "value": "",
                "info": "",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["ApPassword"] = _this.state.settings["ApPassword"].value;
                }
            },
            /* Wireless Network Password - SB 960 - INPUT PASSWORD */
            "ApPassword": {
                "type": "password",
                "label": "WirelessNetworkPassword",
                "value": "",
                "info": "",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["ApName"] = _this.state.settings["ApName"].value;
                }
            },
            /* Broadcast Network Name - RADIO BUTTON */
            "LMIBeaconEnable": {
                "type": "radioButton",
                "label": "BroadcastNetworkName",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* P2P Interface - RADIO BUTTON */
            "p2pInterfaceEnable": {
                "type": "radioButton",
                "label": "P2PInterface",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Ethernet IP Assignment - SB 960 - RADIO BUTTON */
            "ethDhcpEnable": {
                "type": "radioButton",
                "label": "IpAssignment",
                "info": "",
                "value": "1",
                "display": "0",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "onChange": (_this) => {
                    SettingHelpers.setEthernetTCPIPSettings(_this, true);
                }
            },
            /* Ethernet IP Address - SB 960 - INPUT IP */
            "ethIpaddr": {
                "type": "ip",
                "label": "IpAddress",
                "info": "",
                "value": "",
                "display": "0",
                "dependencies": ["ethDhcpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setEthernetTCPIPSettings(_this, false);
                }
            },
            /* Ethernet Subnet Mask - SB 960 - INPUT IP */
            "ethSubnet": {
                "type": "ip",
                "label": "SubnetMask",
                "info": "",
                "value": "",
                "display": "0",
                "dependencies": ["ethDhcpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setEthernetTCPIPSettings(_this, false);
                }
            },
            /* Default Gateway - SB 960 - INPUT IP */
            "defaultGateway": {
                "type": "ip",
                "label": "DefaultGateway",
                "info": "",
                "value": "",
                "display": "0",
                "dependencies": ["ethDhcpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setEthernetTCPIPSettings(_this, false);
                }
            },
            /* DNS Assignment - SB 960 - RADIO BUTTON */
            "DNSPolicy": {
                "type": "radioButton",
                "label": "DnsAssignment",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "dependencies": ["ethDhcpEnable"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setEthernetDNSAssignmentSettings(_this);
                }
            },
            /* Ethernet Primary DNS Server - SB 960 - INPUT IP*/
            "primaryDNS": {
                "type": "ip",
                "label": "PrimaryDnsServer",
                "info": "",
                "value": "",
                "display": "0",
                "dependencies": ["DNSPolicy"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setEthernetDNSAssignmentSettings(_this);
                }
            },
            /* Ethernet Secondary DNS Server - SB 960 - INPUT IP */
            "secondDNS": {
                "type": "ip",
                "label": "SecondaryDnsServer",
                "info": "",
                "value": "",
                "display": "0",
                "dependencies": ["DNSPolicy"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setEthernetDNSAssignmentSettings(_this);
                }
            },
            /* P2P Operating Channel - DROPDOWN */
            "autoGoChannel": {
                "type": "dropdown",
                "label": "P2POperatingChannel",
                "info": "P2POperatingChannelInfo",
                "value": "165",
                "display": "0",
                "options": {
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
                //"region": "clWiFiRegionCode",
                //"country": "clWiFiCountryCode",
                //"wifi20Channels": "clWiFi20Channels",
                //"wifi40Channels": "clWiFi40Channels",
                //"wifi80Channels": "clWiFi80Channels"
            },
            /* USB Auto Config - RADIO BUTTON */
            "usbConfigEn": {
                "type": "radioButton",
                "label": "UsbAutoConfigure",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            "P2PInterfaceSettingTitle": {
                "type": "title",
                "title": "P2PInterface",
                "display": "0"
            },
            "be_channelPlan": {
                "type": "dropdown",
                "value": "12",
                "label": "CountryRegion",
                "info": "",
                "options": {
                    "China, Taiwan": "12",
                    "United States, Canada": "17",
                    "Europe Union, EUTA, Hong Kong, Japan": "18",
                    "Australia, Newzealand, Brazil": "127"
                },
                "onChange": (_this) => {
                    var opChannelList = SettingHelpers.getP2POperatingChannel(_this.state.settings["be_channelPlan"].value)
                    var channelList = opChannelList.channels
                    var arrayChannel = Object.keys(channelList);
                    var copyArray = []
                    for (var key of arrayChannel) {
                        copyArray.push(key)
                    }
                    SettingHelpers.setSettingState(_this, "opChannelList", "value", arrayChannel, () => {
                        SettingHelpers.setSettingState(_this, "preOpChannelList", "value", copyArray)
                    })
                }
            },
            "opChannelList": {
                "value": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "149", "153", "157", "161", "165"],
                "onStart": (settings, data) => {
                    if (data["opChannelList"]) {
                        var value = data["opChannelList"]
                        var convertToArray = value.split(",")
                        settings["opChannelList"].value = convertToArray
                    }
                    return settings["opChannelList"]                               
                },
                "onSubmit": (_this) => {
                    var opChannelList = _this.state.settings["opChannelList"].value
                    _this.changeMap["opChannelList"] = opChannelList.toString()
                }
            },
            "preOpChannelList": {
                "type": "channel",
                "opChannelList": "opChannelList",
                "label": "OperatingChannel",
                "info": "",
                "value": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "149", "153", "157", "161", "165"],
                "onStart": (settings, data) => {
                    if (data["preOpChannelList"]) {
                        var value = data["preOpChannelList"]
                        var convertToArray = value.split(",")
                        settings["preOpChannelList"].value = convertToArray
                    }
                    return settings["preOpChannelList"]
                },
                "onSubmit": (_this) => {
                    var preOpChannel = _this.state.settings["preOpChannelList"].value;
                    preOpChannel.sort(SettingHelpers.compareNumbers)
                    _this.changeMap["preOpChannelList"] = preOpChannel.toString();
                }
            },
            "BackgroundCustom": {
                "type": "file",
                "acceptFile": "image/png, image/jpeg, image/jpg",
                "label": "SetBackground",
                "info": "BackgroundUrlInfo",
                "value": ""
            }
        }
    }

    static getAllSettings = () => {
        var settings = {}
        settings["SingleRxSettings"] = this.getSingleRxSettings()
        settings["MultipleRxSettings"] = this.getMultipleRxSettings()
        settings["MultipleRxSettingsForUSB"] = this.getUSBProvisioningSettings()
        settings["AllSettings"] = this.getAllConfigParameters()
        return settings
    }
}

export default Settings_In_9_6