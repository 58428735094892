/*
 * Created Date: 04/02/2019
 * Description: Display General SB info form like SB Product, firmware version
 *              Triggered from MultipleRxSettingsForm
*/

import React from 'react'
import { Translate } from 'react-localize-redux'

class ScreenBeamProduct extends React.Component {

    horizontalDisplay = () => {
        return (
            <div>
                <div className="col-md-3">
                    <select id="sbmodel" className="form-control" name="selectedModel" value={this.props.selectedModel} onChange={this.props.onSBModelChange}>
                        <Translate>
                            {({ translate }) => {
                                return (Object.keys(this.props.ModelFirmwareDict).map((model, key) => (
                                    <option value={model}>{translate("ScreenBeamModel" + model)}</option>
                                )))
                            }}
                        </Translate>
                    </select>
                </div>

                <div className="col-md-3">
                    <select id="versionChoice" className="col-md-6 form-control" name="selectedVersion" value={this.props.selectedVersion} onChange={this.props.onVersionChange}>
                        <Translate>
                            {({ translate }) => {
                                return (
                                    this.props.ModelFirmwareDict[this.props.selectedModel].map((fw, key) => (
                                        <option value={fw}>{translate(fw)}</option>
                                    ))
                                )
                            }
                            }
                        </Translate>
                    </select>
                </div>
            </div>
        );
    }

    verticalDisplay = () => {
        return (
            <div className="usb-row">
                <p id="product"><Translate id="SelectScreenBeamProduct" /></p>
                <div className="ScreenBeamProduct">
                    <select id="sbmodel" className="col-md-6 form-control" name="selectedModel" value={this.props.selectedModel} onChange={this.props.onSBModelChange}>
                        <Translate>
                            {({ translate }) => {
                                return (Object.keys(this.props.ModelFirmwareDict).map((model, key) => (
                                    <option value={model}>{translate("ScreenBeamModel" + model)}</option>
                                )))
                            }}
                        </Translate>
                    </select>
                </div>
                { /*select version*/
                }
                <div>
                    <select className="col-md-6 form-control" name="selectedVersion" value={this.props.selectedVersion} onChange={this.props.onVersionChange}>
                        <Translate>
                            {({ translate }) => {
                                return (
                                    this.props.ModelFirmwareDict[this.props.selectedModel].map((fw, key) => (
                                        <option value={fw}>{translate(fw)}</option>
                                    ))
                                )
                            }
                            }
                        </Translate>
                    </select>
                </div>
                { /*Note:*/
                }
                <div className="row ">
                    <div className="col-md-6">
                        <label className="col-form-label model-note"><Translate id="SelectScreenBeamProductInfo" /></label>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        var markup = null;
        if (this.props.verticalDisplay) {
            if (this.props.currentStep !== 1) {
                return (null);
            }
            markup = this.verticalDisplay();
            // The markup for the ScreenBeamProduct UI
        }
        if (this.props.horizontalDisplay) {
            markup = this.horizontalDisplay();
        }
        return markup;

    }
}

export default ScreenBeamProduct