import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as signalrActions } from '../../store/SignalR';
import { actionCreators as userActions } from '../../store/User'
import { actionCreators as receiverActions } from '../../store/Receiver'
import './DashBoard.css';
import '../../Styles-Shared.css';
import { Translate } from 'react-localize-redux';

var pollingInterval = 2000 //Time in ms


class Dashboard extends React.Component {
    cardPollTimer = null; //Will store the timer used for polling for idle,busy,offline counts
    cardPollHandler = () => {
        this.props.updateCounts(() => {
            this.cardPollTimer = setTimeout(this.cardPollHandler, pollingInterval)
        }, () => {
            this.cardPollTimer = setTimeout(this.cardPollHandler, pollingInterval)
        })
    }
    componentDidMount() {
        this.props.validate();
        this.cardPollTimer = setTimeout(this.cardPollHandler, pollingInterval)
    }

    componentWillUnmount() {
        clearTimeout(this.cardPollTimer)
    }

    render() {
        if (this.props.user.username === "Administrator") {
            return (
                <div id="receiverSummary" className="row no-gutters">
                    <div className="col-12 col-md-12">
                        <div className="card-wrapper">
                            <div className="card card-with-label offlineRx">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="card-text card-title left-align">This admin account should only be used for initial setup only. Please use member managment to create a user that can be used to manage sites and receivers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else
            return (
                <div>
                    <div id="receiverSummary" className="row no-gutters">
                        <div className="col-12 col-md-3">
                            <div className="card-wrapper">
                                <div className="card card-with-label totalRx">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="card-text card-title left-align">TOTAL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="card-text h2 text-center">{this.props.receiver.globalRxCount.total}</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="card-wrapper">
                                <div className="card card-with-label IdleRx">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="card-text card-title left-align">IDLE</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="card-text h2 text-center">{this.props.receiver.globalRxCount.idle}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="card-wrapper">
                                <div className="card card-with-label BusyRx">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="card-text card-title left-align">BUSY</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="card-text h2 text-center">{this.props.receiver.globalRxCount.busy}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="card-wrapper">
                                <div className="card card-with-label offlineRx">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="card-text card-title left-align">OFFLINE</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="card-text h2 text-center">{this.props.receiver.globalRxCount.offline}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...signalrActions, ...userActions, ...receiverActions }, dispatch)
)(Dashboard);
