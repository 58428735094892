import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions} from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalPasswordInput from '../ModalInputs/ModalPasswordInput'
import ModalSelect from '../ModalInputs/ModalSelect'
import {Translate} from 'react-localize-redux'

class SwitchCMS extends React.Component {
    state={
        ip: "",
        port: "7237"
    }

    onInputChange = (event,key) => {
        this.setState({[key]: event.target.value})
    }

    render() {
        return (     
            <div>
                <div className='modal-body'>
                    <ModalTextInput index="0" onChange={(event)=>{this.onInputChange(event,"ip")}} label="CmsServerIpHostname" value={this.state.ip}/>
                    <ModalTextInput index="1" onChange={(event)=>{this.onInputChange(event,"port")}} label="CmsCommunicationPort" value={this.state.port}/>
                    <p className="modal-text-small"><Translate id="CmsCommunicationPortInfo"/></p>
                </div>
                <ModalFooter button2Submit={()=>{this.props.switchCMS(this.state.ip,this.state.port);this.props.hideModal()}}/>
            </div>
        );
    }
}


export default connect(
    state => state,
    dispatch => bindActionCreators({...modalActions,...signalRActions}, dispatch)
  )(SwitchCMS);
