import React from 'react';
import { Route, Switch } from 'react-router';
import HomeContainer from './HomeContainer'
import Login from './Pages/Login/Login';
import './Google Icons/google.icons.css';
import './Google Fonts/google.fonts.css';
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import englishTranslation from "./localization/en.translations.json";
import simplifiedChineseTranslation from "./localization/zhcn.translations.json";
import traditionalChineseTranslation from "./localization/zhtw.translations.json";
import japaneseTranslation from "./localization/ja.translations.json";


// const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

class App extends React.Component {
    constructor(props){
      super(props);

      const languages = ["en", "zhcn", "zhtw", "ja"];
      const defaultLanguage = localStorage.getItem("languageCode") || languages[0];
      this.props.initialize({
        languages,
        options: {renderToStaticMarkup,
          defaultLanguage}
        
      });
      this.props.addTranslationForLanguage(englishTranslation, "en");
      this.props.addTranslationForLanguage(simplifiedChineseTranslation, "zhcn");
      this.props.addTranslationForLanguage(traditionalChineseTranslation, "zhtw");
      this.props.addTranslationForLanguage(japaneseTranslation, "ja");      
      
    }

    // Persisting the user's active language after a refresh
    componentDidUpdate(prevProps) {
      const prevLangCode =
        prevProps.activeLanguage && prevProps.activeLanguage.code;
      const curLangCode =
        this.props.activeLanguage && this.props.activeLanguage.code;
  
      const hasLanguageChanged = prevLangCode !== curLangCode;
  
      if (hasLanguageChanged) {
        localStorage.setItem("languageCode", curLangCode);
      }
    }
  

    render() {
      return(
        <Switch>
        <Route exact path ='/Login' component={Login} />
        <Route component={HomeContainer} />
      </Switch>
      );
    }
}

export default withLocalize(App);

// export default () => (
//     <Switch>
//       <Route exact path ='/Login' component={Login} />
//       <Route component={HomeContainer} />
//     </Switch>
// );

