import React from 'react';
import './ReceiverPageContentStyle.css'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userActions} from '../../store/User'
import { actionCreators as siteActions } from '../../store/Sites'
import { actionCreators as receiverActions} from '../../store/Receiver'
import TabbedCardWrapper from '../../components/Wrappers/TabbedCardWrapper'
import TabbedTableWrapper from '../../components/Wrappers/TabbedTableWrapper'

class ReceiverPage extends React.Component {
    state = {
        siteGroupTabIndex: 0,
        tableTabIndex: 0
    }

    componentDidMount() {
        this.props.validate();
    }
    onChangeTab = (tabIndex, target) => {
        this.setState({ [target]: tabIndex })
    }
    render() {
        return (
            <div className="row no-gutters deselect-trigger" >
                <div className="col-md-3 deselect-trigger">
                    <TabbedCardWrapper isScheduler={this.props.isScheduler ? this.props.isScheduler : false} tabIndex={this.state.siteGroupTabIndex} onChangeTab={(tabIndex)=>{this.onChangeTab(tabIndex,"siteGroupTabIndex")}} />
                </div>

                <div className="col-md-9 deselect-trigger">
                    <TabbedTableWrapper isScheduler={this.props.isScheduler ? this.props.isScheduler : false} tabIndex={this.state.tableTabIndex} onChangeTab={(tabIndex) => { this.onChangeTab(tabIndex, "tableTabIndex") }} />
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    dispatch => bindActionCreators({...userActions,...siteActions,...receiverActions}, dispatch)
  )(ReceiverPage);
  
