
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Modal';
import { Translate } from 'react-localize-redux';
import { actionCreators as userActions } from '../../store/User'


class ModalFooter extends React.Component {
    buttonOneSubmitAction = () => {
        this.props.isTokenValid(
            () => {
                this.props.button1Submit();
            },
            () => {
                this.props.hideModal();
                this.props.logout();
            }
        )
    }

    buttonTwoSubmitAction = () => {
        this.props.isTokenValid(
            () => {
                this.props.button2Submit();
            },
            () => {
                this.props.hideModal();
                this.props.logout();
            }
        )
    }

    render() {
        return (
            <div className="modal-footer">
                {(this.props.button1Text || this.props.button1Submit) && <button type="button" className="btn btn-light" onClick={this.buttonOneSubmitAction}>{this.props.button1Text || "Cancel"}</button>}
                {/* {(this.props.button2Text || this.props.button2Submit) && <button type="button" className="btn btn-primary" onClick={this.props.button2Submit}>{this.props.button2Text || "Submit"}</button>} */}
                {(this.props.button2Text || this.props.button2Submit) && <button type="button" className="btn btn-primary" onClick={this.buttonTwoSubmitAction}><Translate id="Proceed" /></button>}

            </div>
        )
    }
}

export default connect(
    state => state.modal,
    dispatch => bindActionCreators({ ...actionCreators, ...userActions }, dispatch)
)(ModalFooter);
