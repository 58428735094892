import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';

const createNotificationType = 'CREATE_NOTIFICATION';
const initialState = {};
PNotify.defaults.styling = 'bootstrap4';
PNotify.defaults.delay = 5000;
PNotify.defaults.icons = 'fontawesome4'
PNotify.defaultStack = {
    dir1: 'up',
    dir2: 'left',
    push: 'top',
    firstpos1: 25,
    firstpos2: 25,
}

export const notificationTypes = {
    error: "error",
    warning: "notice",
    success: "success"
}

export const notifications = {
    errorMessages:{
        sitenNameLengthError: "Site name must be between 2 and 36 characters.",
        taskEditError: "Unable to edit task.",
        rxConnectionError: "Error connecting to receiver(s).",
        hubConnectionError: "Error connecting to CMS Hub.",
        signalRLostConnectionError: "Lost connection with server.",
        attemptingSignalRReconnect: "Attempting to reconnect to server.",
        websocketClosed: "Could not establish connection to CMS Hub.",
        onlyOfflineDelete: "Only offline receivers may be deleted.",
        onlyOfflineAction: "At least one selected receiver must be online.",
        genericFail: "Unable to complete request",
        deleteRxFail: "Unable to delete receivers.",
        selectReceiver: "Please select one or more receiver(s)",
        selectTask: "Please select one or more task(s)",
        noReceivers: "There are no receivers.",
        noReceiversSelected: "No receivers are selected.",
        noLogs: "There are no logs.",
        serverBusy: "Another user is currently importing logs. Please try again later.",
        duplicateName: (name) => {
            return "The site name " + name + " already exists."
        },
        emptyReceivers: "Please select one or more receiver(s)",
        sessionExpired: "Your session has expired. Please sign in again."
    },
    successMessages:{
        canceledRxLogUpload: "Successfully cancelled Receiver Log Upload.",
        deleteTask: "Successfully deleted task(s).",
        websocketOpened: "Successfully established connection to CMS Hub.",
        signalRReconnect: "Successfully reconnected to server.",
        deleteRxSuccess: (requestedCount,rowsAffected, timeElapsed)=>{
            return "Successfully deleted " + rowsAffected + " receiver" + (rowsAffected>1 ? "s" : "") + " out of " + requestedCount + " requested. Completed in " + timeElapsed + " seconds."
        },
        joinReceiverToSite: (site, timeElapsed) => {
            return "Successfully joined receiver(s) to " + site + ". Completed in " + timeElapsed + " seconds."  
        },
        releaseReceiverFromSite: (timeElapsed) => {
            return "Successfully released receivers. Completed in " + timeElapsed + " seconds."
        },
        joinReceiverToTask: (timeElapsed) => {
            return "Successfully joined receiver(s) to Task. Completed in " + timeElapsed + " seconds."  
        },
        releaseReceiverFromTask: (timeElapsed) => {
            return "Successfully released selected receiver(s) from Task. Completed in " + timeElapsed + " seconds."  
        },
        getRxLogSuccess: (timeElapsed) => {
            return "Successfully obtained receiver logs. Completed in " + timeElapsed + " seconds."
        },
        getSchedulerLogSuccess: (timeElapsed) => {
            return "Successfully obtained scheduler logs. Completed in " + timeElapsed + " seconds."
        }
    },
    warningMessages: {
        noneDeleted: "No receivers were removed.",
        deleteRxReceivedFail: "Request received, however receivers are now online and cannot be deleted. Check the CMS Hub's connection.",
        featureDenied: (model, firmware) => {
            if(model && firmware){
                return "Receiver " + model + " " + firmware + " is disabled for this license of the CMS."
            }
            else{
                return "Unable to retrieve receiver model or firmware."
            }
        },
        largeDataInReceiverLog: "If you have a large number of record in the database, this process may take a while."
    }
}

export const actionCreators = {
    createNotification: (type,text) => async(dispatch,getState) => {
        PNotify.closeAll()
        PNotify.alert({
            text: text,
            type: type,
            addClass: 'custom nonblock',
            stack: PNotify.defaultStack
        })
    },
}


export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;
    
    //Return copy of state with edited state
    if (action.type === createNotificationType) {
        return { ...state, show: action.show, title: action.title, arg1: action.arg1};
    }
    return state;
};
      