import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions} from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import ModalTextInput from '../ModalInputs/ModalTextInput'

class Reboot extends React.Component {
    state = {
        // ipAddress: "127.0.0.1",
        // webServerPort: "7280",
        CMSCommunicationPort: "7237",
        // logUploadPort: "7238",
        // logCSV: false
    }

    onChange = (event,target) => {
        const value = event.target.type ==='checkbox' ? event.target.checked : event.target.value
        this.setState({[target]: value})
    }

    render() {
        return (     
            <div>
                <div className='modal-body'>
                    {/* <ModalTextInput onChange={(event)=>{this.onChange(event, "CMSCommunicationPort")}} label="CmsCommunicationPort" value={this.state.CMSCommunicationPort} info="CmsCommunicationPortWarning"/>
                    <ModalTextInput onChange={(event)=>{this.onChange(event, "logUploadPort")}} label="LogUploadPort" value={this.state.logUploadPort}/>  */}
                </div>
                <ModalFooter button2Submit={()=>{this.props.configureCMS(this.state.ipAddress,this.state.webServerPort,this.state.CMSCommunicationPort,this.state.logUploadPort,this.state.logCSV);this.props.hideModal()}}/>
            </div>
        );
    }
}


export default connect(
    state => state,
    dispatch => bindActionCreators({...modalActions,...signalRActions}, dispatch)
  )(Reboot);
