import React from 'react'
import { Translate } from 'react-localize-redux'

class ModalPostfixInput extends React.Component {
    defaultPrefix = "DIRECT-xy";
    handleChange = (event) => {
        let prefix = this.props.prefix == "" ? this.defaultPrefix : this.props.prefix
        let val = prefix + event.target.value;

        let newEvent = {
            target: {
                name: this.props.name,
                value: val
            }
        };
        this.props.onChange(newEvent);
    };

    render() {
        let prefix = this.props.prefix == "" ? this.defaultPrefix : this.props.prefix
        let val = this.props.value ? this.props.value.split(prefix)[1] : ""

        let errorDiv;

        if (this.props.error && this.props.error != "")
            errorDiv = <div className="col-sm-2 error"><Translate id={this.props.error} /></div>
        else
            errorDiv = <div></div>
        return (
            <div className="row settings-row">
                <label className="col-sm-4 col-form-label"><Translate id={this.props.label} /></label>
                <div className="col-sm-3">
                    <input type="text" className="form-control" disabled value={prefix} onChange={this.handleChange} />
                </div>
                <div className="col-sm-3">
                    <input type="text" className="form-control" name={this.props.label} value={val} onChange={this.handleChange} />
                </div>
                {errorDiv}
            </div>
        );
    }
}

export default ModalPostfixInput