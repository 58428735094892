import API from '../../REST/RestApi'

const Validation = () => {
    // Check if URL is reachable
    var validateUrl = async (url, context) => {
        var response = await API.validateURL(url);
        // console.log(response)
        if (response.ok) {
            context.isUrlValid = true;
        } else {
            context.isUrlValid = false;            
        }
    }
    // Check the background url format (ending with .PNG/.JPG/JPEG)
    var validateBackgroundUrlFormat = (url) => {
        
    }
    return {
        validateUrl: validateUrl
    }
}

export default Validation();