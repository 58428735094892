/*
 * Created Date: 04/02/2019
 * Descriptions: Display CMS Setting Form in USBAutoConfigRxForm 
*/
import React from 'react'
import { Translate } from 'react-localize-redux'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as usbProvisioningActions } from '../../store/UsbProvisioning'
import ModalTextInput from '../Modals/ModalInputs/ModalTextInput'

class RxCmsSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mgtSrvFullAddr: "", //Default
            mgtSrvFullAddrError: "CannotBeEmpty",
            mgtSrvPort: "7237", //Default
            mgtSrvPortError: ""
        };
        this.data = {
            mgtSrvFullAddr: "", //Default
            mgtSrvPort: "7237" //Default
        }

    }

    componentDidMount = () => {
        this.props.updateRxCmsSettings(this.data, false)
    }

    onChange(event) {
        var validation = false;
        let { name, value } = event.target;
        this.data[name] = value;
        var errorType = name + "Error"        
        if (value != "") {
            validation = true;
            this.setState({[name]: value, [errorType]: ""});        
        } else {
            this.setState({[name]: value, [errorType]: "CannotBeEmpty"})
        }
        this.props.updateRxCmsSettings(this.data, validation);
    }

    render() {

        return (
            <div>
                <ModalTextInput label="CmsServerIpHostname" name="mgtSrvFullAddr" value={this.state.mgtSrvFullAddr} onChange={(event, key) => this.onChange(event)} error={this.state.mgtSrvFullAddrError}/>
                <ModalTextInput label="CmsCommunicationPort" name="mgtSrvPort" value={this.state.mgtSrvPort} onChange={(event, key) => this.onChange(event)} error={this.state.mgtSrvPortError}/>
                {/*Note:*/}
                <div className="row settings-row" >
                    <div className="col-md-6">
                        <label className="col-form-label model-note"><Translate id="CmsCommunicationPortInfo" /></label>
                    </div>
                </div>
            </div>);
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...usbProvisioningActions }, dispatch)
)(RxCmsSettings);