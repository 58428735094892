/*
    This store wil save the feature list. Other component needs to subscribe this store in order to access the feature list. 
*/
import API from "../REST/RestApi";


const featureType = "FEATURE_TOGGLE"

const initialState = {
    state:{
        "SBWD960A": {
            "FW9_6": {
                "Supported": true,
                "Enabled": false,
                "FeatureList": {
                    "PlaceHolderFeatureEnabled": false
                }
            },
        }
    }
}

const ScreenBeamProductList = {
    "SBWD950A": {'FW9_6': 'EnterpriseEdition950A'},
    "SBWD960A": {'FW9_15': 'ScreenBeam960Firmware9dot15', 'FW9_6':'ScreenBeam960Firmware9dot6'},
    "SBWD1100": {'FW11_0':'ScreenBeam1100'},
    "SBWD1000EDU": {'FW11_0': 'ScreenBeam1000'},
    "SBWD1100P": {'FW11_1': 'ScreenBeam1100Plus'},
    "SBWD960B": {'FW9_15': 'ScreenBeam960Firmware9dot15', 'FW9_6':'ScreenBeam960Firmware9dot6'}
}

export const actionCreators = {
    getFeatureToggle: () => async(dispatch, getState) => {
        API.getFeatureToggle((res)=>{
            dispatch({type: featureType, state: res})
        },
        (err)=>{
            console.log("Unable to obtain feature toggle info.")
            console.log(err)
        })
    },
    getScreenBeamProductList: (cb) => async (dispatch, getState) => {
        var state = getState().featureToggle.state
        var productList = {}
        Object.keys(state).map((model, index) => {
            var firmwareList = state[model]
            Object.keys(firmwareList).map((fw, index) => {
                var isEnabled = firmwareList[fw].Enabled      
                if (isEnabled) {
                    if (!productList[model]) productList[model] = []
                    productList[model].push(ScreenBeamProductList[model][fw])
                }
            })
        })
        cb(productList)
    }
}

export const helpers = {
    firmwareVersionToFeatureMapKey(firmware){
        //Might want to remove this and create a constant mapping so this doesn't have to be run on every receiver
        var splitFirmware = firmware.split(".")
        var key = "FW" + splitFirmware[0] + "_" + splitFirmware[1]
        return key
    },
    checkRxAllowed(rx,featureMap){
        var rowAllowed = false
        if(rx.ModelName in featureMap){
            var model = featureMap[rx.ModelName]
            var fwVersionKey = this.firmwareVersionToFeatureMapKey(rx.RXVersion)
            if(fwVersionKey in model){
                rowAllowed = model[fwVersionKey].Enabled 
            }
        }
        return rowAllowed
    }
}

export const reducer = (state, action) => {
     //Initialize state
     state = state || initialState;
    if (action.type == featureType) {
        return {...state, state: action.state}
    }     
    return state
}