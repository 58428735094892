
import React from 'react';

class ProgressBar extends React.Component {
    render(){
        return(
            <div>
                <div className="modal-text">Upload Progress: {parseFloat(this.props.progress).toFixed(2)}%</div>
                <div className="progress">
                    <div className="progress-bar" style={{width: parseInt(this.props.progress)+"%"}}/>
                </div>   
            </div>
        )
    }
}


export default ProgressBar