import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as receiverActions} from '../../../store/Receiver'
import ModalFooter from '../ModalFooter'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalPasswordInput from '../ModalInputs/ModalPasswordInput'
import ModalSelect from '../ModalInputs/ModalSelect'
import API from '../../../REST/RestApi';

class ReceiverDescription extends React.Component {
    state={
        description: ""
    }

    onInputChange = (event,key) => {
        this.setState({[key]: event.target.value})
    }

    onSubmit = () => {
        this.props.setDescription(this.state.description)
        this.props.hideModal()
    }
    
    render() {
        return (     
            <div>
                <div className='modal-body'>
                    <ModalTextInput onChange={(event)=>{this.onInputChange(event,"description")}} index="0" label="Description" value={this.state.description}/>
                </div>
                <ModalFooter button2Submit={this.onSubmit}/>
            </div>
        );
    }
}


export default connect(
    state => null,
    dispatch => bindActionCreators({...modalActions,...receiverActions}, dispatch)
  )(ReceiverDescription);
