const actionTypes = {
    "Settings": 1,
    "UpdateFirmware": 2,
    "SetScreenSaver" : 12,
    "SetBackground" : 16,
    "Reboot": 3,
    "ResetToDefault" : 4,
    "UploadLogs": 14,
}

export default actionTypes