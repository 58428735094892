import React from 'react';
import { Translate } from 'react-localize-redux'

class ModalCheckbox extends React.Component {
    handleChange = (e) => {
        let val = e.target.checked ? 1 : 0
        let event = {
            target: {
                value: val,
                name: e.target.name
            }
        };
        this.props.onChange(event);
    }
    render() {
        return (
            <div className={"col-sm-4" + (this.props.nospacing ? "" : " offset-sm-4")} >
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" name={this.props.name ? this.props.name : ""} disabled={this.props.disabled} className="custom-control-input" id={"checkboxType" + this.props.label} value={this.props.value} checked={this.props.value == "1" || this.props.value == 1 || this.props.checked} onChange={this.handleChange} />
                    <label className="custom-control-label" htmlFor={"checkboxType" + this.props.label}><Translate id={this.props.label} /></label>
                </div>
            </div>
        );
    }
}


export default ModalCheckbox
