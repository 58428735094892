import React from 'react'
import './Avatar.css'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as UserActions } from '../../store/User';
import { Translate } from 'react-localize-redux'

const avatar = require("../../images/avatar_gray.png")
class Avatar extends React.Component {
    render() {
        return (
            <div>
                {/* <img src={avatar} alt="Avatar" className="avatar center" /> */}
                <p className="welcome"><Translate id="Welcome" /></p>
                <div className="currentUser">
                    <p className="user-label">
                        {this.props.user.username ? this.props.user.username : localStorage.getItem('username')}
                    </p>
                </div>
            </div>
        );
    }
}

export default connect(
    state => (state),
    dispatch => bindActionCreators({ ...UserActions }, dispatch)
)(Avatar);

// export default Avatar