/*
 * Created Date: 02/08/2019
 * Description: - Handle show/hide the detail of each setting in SettingMultiplesForm
 *
 */
import React from 'react'

class ModalMultipleCheckbox extends React.Component {
    constructor(props) {
        super(props);
  
        // Set the state
        this.state = {
            CheckboxName: this.props.CheckboxName + "Checkbox",
            CheckboxLabel: this.props.CheckboxLabel,
            IsChecked: this.props.IsChecked,
            ContentId: this.props.CheckboxName + "Div",
            LabelId: this.props.CheckboxName + "Label",
            CheckboxChildrenList: this.props.CheckboxChildrenList,
            stateSettings: this.props.stateSettings
        };

        // Bind function
        this.handleCheckboxChanges = this.handleCheckboxChanges.bind(this);
    }

    componentDidMount() {
        this.showHideDetail();
    }

    componentDidUpdate() {
        this.showHideDetail();
    }

    showHideDetail() {
        if (this.props.IsChecked === "0") {
            this.Content.style.display = 'none'
            // $("#" + this.state.ContentId).hide();
        } else
            this.Content.style.display = 'block'

        // $("#" + this.state.ContentId).show();
        //Fix Bug #8819 In "Batch Settings" and "USB Configuration", when �CC mode� is selected, items such as Infracast, macOS, Mac Airplay, etc.cannot be checked.
        var noChildrenDisplay = true;
        if (this.props.CheckboxChildrenList) {
            this.props.CheckboxChildrenList.map((key, index) => {     
                var setting = this.props.stateSettings[key];
                if (setting) {
    
                    var display = setting.display;
                    //If there are dependencies loop through them
                    if (setting && setting.dependencies) {
                        var dependencyKey = ""; //Build a string based on the values of the dependencies\
                        //!!!!!!!!!!!This algorithm may cause issues if feature values become longer than 1 digit!!!!!!!!!!!!!!!!!
                        setting.dependencies.forEach((dependency) => {
                            dependencyKey += this.props.stateSettings[dependency].value;
                        });
                        display = setting.dependencyMap[dependencyKey] || display;
                    }
                    if (display != "1") {
                        noChildrenDisplay = false;
                    }
                }
            });
            if (noChildrenDisplay) {
                this.checkbox.style.display = 'none';
            } else {
                this.checkbox.style.display = 'block';
            }
        }
    }

    handleCheckboxChanges(event) {
        // Update checkbox
        var newStatus = this.props.IsChecked === "1" ? "0" : "1";
        this.props.onCheckboxClicked(this.props.CheckboxName, newStatus);
    }

    render() {
        return (
            <div>
                <div className="custom-control custom-checkbox show" ref={(el) => this.checkbox = el}>
                    <input type="checkbox" id={this.props.CheckboxName + "Checkbox"} name={this.props.CheckboxName} value={this.props.IsChecked} checked={this.props.IsChecked === "1"} className="custom-control-input" onChange={this.handleCheckboxChanges} />
                    <label className="custom-control-label" htmlFor={this.props.CheckboxName + "Checkbox"}>{this.props.CheckboxLabel}</label>
                </div>
                <div className="hide" id={this.props.CheckboxName + "Div" } ref={(el) => this.Content = el}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ModalMultipleCheckbox
