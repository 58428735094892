import React from 'react'
import Dropdown from '../Dropdown/Dropdown';
import DropdownItem from '../Dropdown/DropdownItem'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../store/Modal'
import { actionCreators as contextActions } from '../../store/ContextMenu'
import { actionCreators as receiverActions } from '../../store/Receiver'
import { actionCreators as taskActions } from '../../store/Tasks'
import { actionCreators as siteActions } from '../../store/Sites'
import { actionCreators as notificationActions, notifications, notificationTypes } from '../../store/Notifications'
import SchedulerContext from "../ContextMenu/SchedulerContext"
import API from '../../REST/RestApi'
import { Translate } from 'react-localize-redux'
import { showMenu, hideMenu } from 'react-contextmenu/modules/actions'
import codeConverter from './CodeConverter'
import tableConfig from './TableConfig'
import moment from 'moment'
import weekDayTypes from '../../Utils/weekDayTypes'

///datatable imports
import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-scroller'
import 'datatables.net-select'



class SchedulerTable extends React.Component {
    state = {
        searchParam: "",
        headerVisibility: {},
    }
    headerMap = tableConfig.getHeaderMap()
    contextTargetIndex = -1
    pollInterval = 5000
    pollTimer = null
    tooltipHandler = null
    lastSelected = null
    lastSelectedIndex = -1

    getTriggerToolTip = (row) => {
        if(!row){
            return ""
        }
        var tooltipText = ""
        switch (row["sche_Trigger_Type"]){
            case 0: //Once
                tooltipText = ""
                break
            case 1: //Daily
                if(row["sche_Recur_Count"] > 1){
                    tooltipText = "Every " + row["sche_Recur_Count"] + " days."
                }
                else{
                    tooltipText = "Every " + "day."
                }
                break
            case 2: //Weekly
                var days = []
                for(var i = 0; i<7; i++){
                    if(row["sche_Recur_Days_"+i]){
                        days.push(i)
                    }
                }
                var daysTextArray = days.map((dayId)=>{return weekDayTypes[dayId]})
                var daysText = daysTextArray.join(", ")
                tooltipText = "Every " + daysText + "."
                break
            case 3: //Monthly
                tooltipText = "The " + row["sche_Day_Of_Month"] + " of each month."
                break
            case 4: //Minutes
                tooltipText = "Every " + row["sche_Minutes"] + " minutes."
                break
        }
        return tooltipText
    }

    addJQueryEvents = () => {
        var _SchedulerTable = this
        //Reattach jquery events
        $('tr').contextmenu(function (event) {
            var row = _SchedulerTable.dataTable.row(this)
            if(!row){
                return
            }
            var data = row.data()
            if(data){
                _SchedulerTable.contextTargetId = data.sche_ID
                _SchedulerTable.contextTargetIndex = row.index()
                $(this).addClass("context-target")
            }
        })

        $('tr').on('click', function (event){
            var row = _SchedulerTable.dataTable.row(this)
            if(!row){
                return
            }
            var data = row.data()
            if(data){
                $('tr').removeClass('last-selected')
                if(data.sche_ID != _SchedulerTable.props.lastSelected || Object.keys(_SchedulerTable.props.selectedTasks).length > 1){
                    $(this).addClass('last-selected')
                    _SchedulerTable.props.updateLastSelected(data.sche_ID,row.index())
                }
                else{
                    _SchedulerTable.props.updateLastSelected(null,null)
                }
            }
        })

        $('.toggle-status').on('click', function (event) {
            _SchedulerTable.toggleStatusHandler(this, event)
        })

        $(".tooltip-trigger").on('mousemove', function(e){
            var cellData = _SchedulerTable.dataTable.cell(this).data()
            var rowData = _SchedulerTable.dataTable.row($(this).closest('tr')).data()
            if(!rowData){
                return
            }
            if($(this).hasClass('sche-trigger')){
                var cellData = _SchedulerTable.getTriggerToolTip(rowData)
            }
            $("#tooltip").text(cellData).css({ left: e.pageX, top: e.pageY + 20 }, 1)

            if(_SchedulerTable.tooltipHandler === null){
                _SchedulerTable.tooltipHandler = setTimeout(()=>{
                    if(cellData && cellData.length > 1){
                        if (!$("#tooltip").is(':visible')) $("#tooltip").show()
                    }      
                },750)
            }
        })

        $(".tooltip-trigger").on('mouseleave', function(e) {
            if(_SchedulerTable.tooltipHandler){
                clearTimeout(_SchedulerTable.tooltipHandler)
                _SchedulerTable.tooltipHandler = null
            }
            $("#tooltip").hide()
        })  
    }

    columnDefs = [
        {targets: [0], className: "dt-nowrap no-text-select toggle-status", render: (data,type,row,meta) => {
            var checked = data ? "checked" : ""
            var bool = true
            if(this.props.isSystemAdmin !== "true" && this.props.isSystemAdmin !== true && row["sche_Author"] != this.props.username ){
                bool = false
            }
            return '<label class="'+ (bool ? 'switch' : 'switch switch-disabled ' + checked) + '"><input type="checkbox" ' + checked + '><span class="slider round"></span></label>'
        }},
        {targets: [1,8], render: $.fn.dataTable.render.text(), className: "dt-nowrap no-text-select tooltip-trigger"},
        {targets: [2], className: "dt-nowrap no-text-select tooltip-trigger sche-trigger"},
        {targets: [3], render: ( data,type,row,meta) => {
            var utc = moment.utc(data).toDate()
            var day = moment(utc).local()
            if(row["sche_Stat"] == 0){
                return ""
            }
            else{
                if(day.isAfter(moment("12-31-9990 23:59", "M-D-YYYY HH:mm" ))|| day.isBefore(moment("1-1-1910 00:00", "M-D-YYYY HH:mm" ))){
                    return ""
                }
                return day.format("M-D-YYYY HH:mm")
            }
        }},
        {targets: [4,9,10,11], render: (data,type,row,meta) => {
            var utc = moment.utc(data).toDate()
            var day = moment(utc).local()
            if(day.isAfter(moment("12-31-9990 23:59", "M-D-YYYY HH:mm" ))|| day.isBefore(moment("1-1-1910 00:00", "M-D-YYYY HH:mm" ))){
                return ""
            }
            return day.format("M-D-YYYY HH:mm")
        }},
        {targets: [5], className: "dt-nowrap no-text-select tooltip-trigger"},
        { targets: '_all', className: "dt-nowrap no-text-select" },
    ]

    addClasses = (dataTable) => {
        var selected = this.props.selectedTasks
        var selectedIndexes = []
        var lastSelectedRow = null
        dataTable.rows().every((index) => {
            var row = dataTable.row(index)
            var data = row.data()
            if (data.sche_ID in selected){
                selectedIndexes.push(index)
            }
            if(this.props.isSystemAdmin !== "true" && this.props.isSystemAdmin !== true){
                if (data.sche_Author != this.props.username) {
                    $(row.node()).addClass("unowned-site")
                }
                else {
                    $(row.node()).removeClass("unowned-site")
                }
            }
            if (data.sche_ID == this.props.lastSelected){
                lastSelectedRow = row
            }
            if (data.sche_ID == this.contextTargetId){
                $(row.node()).addClass("context-target")
            }
        })
        dataTable.rows(selectedIndexes).select()
        if(lastSelectedRow){
            $(lastSelectedRow.node()).addClass("last-selected")
        }
    }

    pollHandler = () => {
        var scrollPosition = $("#scheduler-table_wrapper div.dataTables_scrollBody").scrollTop();
        // $('#scheduler-table_wrapper th:eq(1)').width(400);
        this.redrawTable(scrollPosition)
    }
    
    contextTargetId = "";
    dataTable = null;

    returnVisible = (headerkey) => {
        if (this.state.headerVisibility[headerkey]) {
            return "fa fa-check"
        }
        else {
            return ""
        }
    }

    toggleStatusHandler = (el, event) => {
        event.preventDefault()
        event.stopPropagation()
        
        if(!this.dataTable.cell(el).index()){
            return
        }
        var rowIdx = this.dataTable.cell(el).index().row;
        var row = this.dataTable.row(rowIdx)
        var data = row.data()
        if(this.props.isSystemAdmin !== "true" && this.props.isSystemAdmin !== true){
            if(data.sche_Author != this.props.username){
                return
            }
        }
        var sche_Stat = 0
        var inputEl = $(el).find('input')
        var state = inputEl.attr('checked')

        if (!state) {
            inputEl.attr('checked', true)
            sche_Stat = 1
        }
        else {
            inputEl.removeAttr('checked')
        }
        var id = row.data().sche_ID
        var req = { ...row.data() }
        req.sche_Stat = sche_Stat
        API.toggleTask(id, req, ()=>{
            this.redrawTable()
        }, (err) => {
            this.redrawTable()
            this.props.createNotification(notificationTypes.error, notifications.errorMessages.taskEditError)
        })
    }

    onSearchChange = (event) => {
        this.setState({ searchParam: event.target.value }, () => {
            //Perform search through datatables API
            this.dataTable.search(this.state.searchParam)
            this.dataTable.draw()
        })
    }

    onDeleteSelected = () => {
        var taskIds = []
        var _SchedulerTable = this
        this.dataTable.rows({ selected: true }).every(function () {
            var data = this.data()
            if(data.sche_Author == _SchedulerTable.props.username || _SchedulerTable.props.isSystemAdmin === "true" || _SchedulerTable.props.isSystemAdmin === true){
                taskIds.push(data.sche_ID)
            }
        })
        //Pass the onDelete function onto the modal. 
        //When the API request is deleted we need access to the callback to update the table
        if(taskIds.length > 0){
            this.openModal("Delete Task", { onSubmit: () => { this.deleteSelected(taskIds) } })
        }
        else{
            this.props.createNotification(notificationTypes.error,notifications.errorMessages.selectTask)
        }
    }

    onDeleteSingle = () => {
        var taskId = [this.contextTargetId]
        this.openModal("Delete Task", { onSubmit: () => { this.deleteSelected(taskId) } })
    }


    deleteSelected = (taskIds) => {
        var startTime = new Date()
        API.deleteTask(taskIds, () => {
            this.props.createNotification(notificationTypes.success,notifications.successMessages.deleteTask)
            this.props.hideModal()
            this.redrawTable()
        } , ()=>{
            this.props.createNotification(notificationTypes.error,notifications.errorMessages.genericFail)
            this.props.hideModal()
        })
    }

    redrawTable = (scrollPosition = 0) => {
        API.getTasks((res) => {
            this.dataTable.clear();
            var data = []
            if (res.status != 404) {
                data = res.data.data
            }
            var storedLanguage = localStorage.getItem("languageCode") || "en";
            for (var i = 0; i < data.length; i++) {
                data[i]["translatedTrigger"] = codeConverter("trigger", data[i].sche_Trigger_Type, storedLanguage)
                data[i]["translatedPolicy"] = codeConverter("policy", data[i].sche_Policy_Command, storedLanguage)
                data[i]["translatedResult"] = codeConverter("result",data[i].sche_Last_Run_Result, storedLanguage)
            }
            this.dataTable.rows.add(data);
            this.dataTable.draw();

        //     //Reattach jquery events
            this.addJQueryEvents()
            this.addClasses(this.dataTable)


            clearTimeout(this.pollTimer)
            this.pollTimer = setTimeout(this.pollHandler,this.pollInterval)
            $("#scheduler-table_wrapper div.dataTables_scrollBody").scrollTop(scrollPosition);


        },
        ()=>{
            clearTimeout(this.pollTimer)
            this.pollTimer = setTimeout(this.pollHandler,this.pollInterval)
        })
    }

    showContextMenu = (event) => {
        //Datatables.net uses jquery to modify DOM elements so the context event handler is placed on the table
        //Then we check if the event is targeting a <tr>
        
        var data = this.dataTable.row(this.contextTargetIndex).data()
        if(data.sche_Author == this.props.username || this.props.isSystemAdmin === "true" || this.props.isSystemAdmin === true){
            if (event.target.tagName.toUpperCase() == "TR" || event.target.tagName.toUpperCase() == "TD") {
                event.preventDefault()
                showMenu({
                    position: { x: event.clientX, y: event.clientY },
                    id: "schedulerContext"
                })
            }
        }
        else{
            if (event.target.tagName.toUpperCase() == "TR" || event.target.tagName.toUpperCase() == "TD") {
                event.preventDefault()
                showMenu({
                    position: { x: event.clientX, y: event.clientY },
                    id: "schedulerContextDisabled"
                })
            } 
        }
    }

    editTask = (event) => {
        API.getTask(this.contextTargetId, (res) => {
            this.openModal("Schedule Task", { successCb: this.redrawTable, isEdit: true, data: res.data, taskId: this.contextTargetId, 
                errorCb: (err)=>{
                    this.props.createNotification(notificationTypes.error,err)
                }
            })
        })
    }

    addRx = () => {
        var macs = Object.keys(this.props.selected.macs)
        var startTime = new Date()
        if(macs.length == 0){
            this.props.createNotification(notificationTypes.error, notifications.errorMessages.noReceiversSelected)
            return
        }
        API.joinTaskReceivers(this.contextTargetId, macs, (res) => {
            var timeElapsed = (new Date() - startTime) / 1000
            this.props.createNotification(notificationTypes.success,notifications.successMessages.joinReceiverToTask(timeElapsed))
            this.redrawTable()
        },()=>{
            this.props.createNotification(notificationTypes.error,notifications.errorMessages.genericFail)
        })
    }

    releaseRx = () => {
        var macs = Object.keys(this.props.selected.macs)
        var startTime = new Date()
        if(macs.length <= 0){
            this.props.createNotification(notificationTypes.warning,notifications.errorMessages.noReceiversSelected)
            return
        }
        API.releaseTaskReceivers(this.contextTargetId, macs, (res) => {
            if(res.status == 400){
                this.props.createNotification(notificationTypes.warning,notifications.warningMessages.noneDeleted)
            }
            else{
                var timeElapsed = (new Date() - startTime) / 1000
                this.props.createNotification(notificationTypes.success,notifications.successMessages.releaseReceiverFromTask(timeElapsed))
            }
            this.redrawTable()
        },()=>{
            this.props.createNotification(notificationTypes.error,notifications.errorMessages.genericFail)
        })
    }

    scheduleTask = (event) => {
        

        //If the user is not a system admin and is not an admin of at least one site prevent him from creating a task
        var atLeastOneAdmin = Object.keys(this.props.rolesMap).some((key)=>{
            if(this.props.rolesMap[key] == "ScreenBeamAdmin"){
                return true
            }
            return false
        })

        if((this.props.isSystemAdmin == false || this.props.isSystemAdmin == "false") && !atLeastOneAdmin){
            this.openModal("Scheduler Warning")
        }
        else{
            //Second argument is a callback function on successful scheduling
            this.openModal("Schedule Task", { successCb: this.redrawTable,
                errorCb: (err)=>{
                    this.props.createNotification(notificationTypes.error,err)
                }
            })
        }
    }

    openModal = (name, arg1) => {
        this.props.createModal(name, arg1)
    }

    componentWillUnmount() {
        clearTimeout(this.pollTimer)
        this.props.deselectSites()
        this.props.clearTaskSelection()
    }

    componentDidMount() {
        var _SchedulerTable = this

        //Initialize header visibility state as true for all
        var headerVisibility = {}
        Object.keys(this.headerMap).forEach((key) => {
            if(this.headerMap[key].visible == true){
                headerVisibility[key] = true
            }
            else{
                headerVisibility[key] = false
            }
        })
        this.setState({ headerVisibility: headerVisibility })

        //Jquery is necessary to stop the propagation of Bootstrap's close dropdown on click
        $(".dropdown-task-header").on('click', function (event) {
            event.stopPropagation()
            var data = JSON.parse($(this).attr("data"))
            var column = _SchedulerTable.dataTable.column(data["index"])
            column.visible(!column.visible())
            sessionStorage.setItem("schedulerTable"+[data["key"]],column.visible())
            _SchedulerTable.setState({ headerVisibility: { ..._SchedulerTable.state.headerVisibility, [data["key"]]: !_SchedulerTable.state.headerVisibility[data["key"]] } })
        })

        API.getTasks((res) => {
            //Instantiate datatable
            var config = tableConfig.getConfig()
            var data = res.data.data
            var storedLanguage = localStorage.getItem("languageCode") || "en";
            for (var i = 0; i < data.length; i++) {
                data[i]["translatedTrigger"] = codeConverter("trigger", data[i].sche_Trigger_Type, storedLanguage)
                data[i]["translatedPolicy"] = codeConverter("policy", data[i].sche_Policy_Command, storedLanguage)
                data[i]["translatedResult"] = codeConverter("result",data[i].sche_Last_Run_Result, storedLanguage)
            }
            config["data"] = data
            config["columnDefs"] = this.columnDefs
            this.dataTable = $(this.dataTableRef).DataTable(config);
            this.props.setTasksTable(this.dataTable)

            $('.deselect-trigger').on('click', function(event){
                if(event.target.className.includes("deselect-trigger")){
                    _SchedulerTable.dataTable.rows().deselect()
                }
            })
            this.addJQueryEvents()

            this.addClasses(this.dataTable,[])

            this.dataTable.on('select', (e, dt, type, indexes) => {
                var deselectIndexes = []
                this.dataTable.rows(indexes).every((index)=>{
                    var row = this.dataTable.row(index)
                    var data = row.data()
                    if(this.props.isSystemAdmin !== "true" && this.props.isSystemAdmin !== true){
                        if(data.sche_Author != this.props.username){
                            deselectIndexes.push(index)
                        }
                    }
                })
                var updatedTasks = this.dataTable.rows({ selected: true }).data()
                this.props.updateTaskSelection(updatedTasks)
                this.dataTable.rows(deselectIndexes).deselect()
            })
            this.dataTable.on('deselect', (e, dt, type, indexes) => {
                var updatedTasks = this.dataTable.rows({ selected: true }).data()
                this.props.updateTaskSelection(updatedTasks)
            })
            clearTimeout(this.pollTimer)
            this.pollTimer = setTimeout(this.pollHandler,this.pollInterval)
        }, () => {
            var config = tableConfig.getConfig()
            config["data"] = []
            clearTimeout(this.pollTimer)
            this.pollTimer = setTimeout(this.pollHandler,this.pollInterval)
        })
    }
    onContextHide = (event) => {
        $('tr').removeClass("context-target")
        this.contextTargetIndex = -1
        this.contextTargetId = ""
    }

    render() {
        return (
            <div>
                <div id="tooltip"></div>
                <div className="row justify-content-between align-items-center deselect-trigger">
                    <div className="col-md-6 deselect-trigger">
                        <div className="btn-toolbar deselect-trigger">
                            <div className="btn-group mr-1 deselect-trigger">
                                <Dropdown title="ShowHideColumns">
                                    {Object.keys(this.headerMap).map((key, index) => {
                                        return (<DropdownItem key={key} className="dropdown-task-header" data={JSON.stringify({ key: key, index: index })} index={index} icon={this.returnVisible(key)} onClick={this.toggleHeader}><Translate id={key} /></DropdownItem>)
                                    })}
                                </Dropdown>
                            </div>
                            <div className="btn-group mr-1 deselect-trigger">
                                <button className="btn btn-secondary btn-outline-secondary" onClick={this.scheduleTask}>Schedule Task</button>
                            </div>
                            <div className="btn-group mr-1 deselect-trigger">
                                <button className="btn btn-secondary btn-outline-secondary" onClick={this.onDeleteSelected}>Delete Task</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end deselect-trigger">
                        <div className="btn-toolbar">
                            <div className="btn-group mr-1">
                                <i className="search-icon material-icons">search</i>
                                <Translate>
                                    {({ translate }) =>
                                        <input type="text" className="table-search-input form-control" value={this.state.searchParam} placeholder={translate("Search")} onChange={this.onSearchChange}></input>
                                    }
                                </Translate>
                            </div>
                        </div>
                    </div>
                </div>
                <table onContextMenu={this.showContextMenu} ref={(el) => { this.dataTableRef = el }} id="scheduler-table" className="table table-bordered" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            {Object.keys(this.headerMap).map((header, index) => {
                                return (<th key={"header" + index}><Translate id={header} /></th>)
                            })}
                        </tr>
                    </thead>
                </table>
                <SchedulerContext
                    id={"schedulerContext"}
                    onDelete={this.onDeleteSingle}
                    onEdit={this.editTask}
                    onAddRx={this.addRx}
                    onReleaseRx={this.releaseRx}
                    onHide={this.onContextHide}
                />
                <SchedulerContext
                    id={"schedulerContextDisabled"}
                    onDelete={this.onDeleteSingle}
                    onEdit={this.editTask}
                    onAddRx={this.addRx}
                    onReleaseRx={this.releaseRx}
                    onHide={this.onContextHide}
                    disabled={true}
                />
            </div>
        )
    }
}

export default connect(
    state => ({...state.receiver,...state.user,...state.tasks, ...state.sites}),
    dispatch => bindActionCreators({...taskActions, ...modalActions, ...contextActions, ...receiverActions, ...notificationActions, ...siteActions }, dispatch)
)(SchedulerTable);

