/*
 * Created Date: 04/22/2019
 * Description: Display Multiple Receivers Settings Modal
*/
import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import { actionCreators as receiverActions } from '../../../store/Receiver';
import { actionCreators as settingsActions } from '../../../store/Settings';
import { actionCreators as featureToggleActions } from '../../../store/FeatureToggle';
import { Translate } from 'react-localize-redux'
import { confirmAlert } from 'react-confirm-alert';
import ModalAlert from '../ModalInputs/ModalAlert'

import ScreenBeamProduct from './ScreenBeamProduct'
import Settings from './Settings'

import API from "../../../REST/RestApi";


var AeiConfigMapping = require('../../UsbProvisioning/AeiConfigMapping')
var AeiConfig = AeiConfigMapping.AeiConfig;

class MultipleRxSettings extends React.Component {
    constructor(props) {
        super(props);

        let defaultStep = 0;
        let defaultModel = ""
        let defaultVersion = ""
        // Default ScreenBeam Product list
        this.ScreenBeamProductList = {}

        // Get ScreenBeamProduct List from feature toggle store
        this.props.getScreenBeamProductList((list) => {
            this.DefaultScreenBeamProductList = list
        })
        // Get firmware list from parents
        this.macs = this.props.receiver.selected.macs;
        this.FirmwareList = this.getFirmwareList(this.macs);

        // Display ScreenBeam Product List if there are more than 1 SB model receivers
        if (this.FirmwareList.length > 1) {
            defaultStep = 1;
            // Get the default model and version of ScreenBeam Product
            this.ScreenBeamProductList = this.getScreenBeamProductListBySelectedReceivers(this.FirmwareList)
            defaultModel = Object.keys(this.ScreenBeamProductList)[0];
            defaultVersion = this.ScreenBeamProductList[defaultModel][0];
        } else {
            defaultVersion = this.FirmwareList[0];
        }
        // Set the initial input values
        this.state = {
            currentStep: defaultStep,  // Default is Step 1  
            selectedModel: defaultModel,
            selectedVersion: defaultVersion,
            isSubmit: false
        };


        // Bind functions
        this._next = this._next.bind(this);
        this._save = this._save.bind(this);
    }

    getFirmwareList = (macs) => {
        let firmwareList = [];

        for (var key in macs) {
            let fw = macs[key].RXVersion;
            let fwArray = fw.split(".")
            let version = fwArray[0] + "." + fwArray[1]
            if (!firmwareList.includes(version)) firmwareList.push(version)
        }
        return firmwareList
    }

    getScreenBeamProductListBySelectedReceivers(firmwareList) {

        var dropdownList = {};
        for (var i = 0; i < firmwareList.length; i++) {
            let fw = firmwareList[i];
            if (fw.startsWith("9.6")) {
                if (this.DefaultScreenBeamProductList["SBWD950A"]) dropdownList["SBWD950A"] = this.DefaultScreenBeamProductList["SBWD950A"]

                if (this.DefaultScreenBeamProductList["SBWD960A"]) dropdownList["SBWD960A"] = this.DefaultScreenBeamProductList["SBWD960A"];
            }
            else if (fw.startsWith("9.15") && this.DefaultScreenBeamProductList["SBWD960A"]) {
                dropdownList["SBWD960A"] = this.DefaultScreenBeamProductList["SBWD960A"];
            }
            else if (fw.startsWith("11.0" && this.DefaultScreenBeamProductList["SBWD1100"])) {
                dropdownList["SBWD1100"] = this.DefaultScreenBeamProductList["SBWD1100"];
                dropdownList["SBWD1000EDU"] = this.DefaultScreenBeamProductList["SBWD1000EDU"];
                dropdownList["SBWD1100P"] = this.DefaultScreenBeamProductList["SBWD1100P"];

            } else if (fw.startsWith("11.1" && this.DefaultScreenBeamProductList["SBWD1100P"])) {
                dropdownList["SBWD1100P"] = this.DefaultScreenBeamProductList["SBWD1100P"];
            }
            else {
                dropdownList = this.DefaultScreenBeamProductList
            }
        }
        return dropdownList;
    }

    get ScreenBeamProductSelection() {
        if (this.state.currentStep === 1) {
            return (
                <ScreenBeamProduct
                    verticalDisplay={true}
                    currentStep={this.state.currentStep}
                    ModelFirmwareDict={this.ScreenBeamProductList}
                    selectedModel={this.state.selectedModel}
                    selectedVersion={this.state.selectedVersion}
                    onSBModelChange={this.onSBModelChange.bind(this)}
                    onVersionChange={this.onVersionChange.bind(this)}
                    data={this.props.data} />
            );
        }
    }

    get MultipleRxSettings() {
        if (this.state.currentStep === 0 || this.state.currentStep === 2) {
            let markup = null;
            let selectedVersion = this.state.selectedVersion;
            markup =
                <Settings version={this.state.selectedVersion} type="Batch Config" handleSaveZipFile={this.handleSaveZipFile} />;
            return markup;
        }
        return null;
    }

    get downloadOption() {
        if (this.state.currentStep != 3) return null;
        var markup = <div><p>Do you want to download setting file?</p></div>
        return markup;
    }

    _next() {
        let currentStep = this.state.currentStep;
        currentStep = currentStep >= 1 ? 2 : currentStep + 1;
        this.setState({
            currentStep: currentStep
        });
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 2, then render the "next" button
        if (currentStep === 0) return null;
        if (currentStep < 2) {
            return (
                <button className="btn btn-primary" type="button" onClick={this._next}>
                    Next
                </button>
            );
        }
        // ... else return nothing
        return null;
    }

    _save() {
        this.props.validateData((isDataValid, data) => {
            if (!isDataValid) {
                return
            } else {
                // Send request to Hub
                this.props.updateRxSettings(data);
                this.props.hideModal();
            }
        })
    }

    get saveButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1 && currentStep !== 3) {
            return (
                <button className="btn btn-primary" type="button" onClick={this._save}>
                    Proceed
                </button>
            );
        }
    }

    handleSaveZipFile = () => {
        // Convert data to aeiconfig parameters
        this.props.validateData((isDataValid, data) => {
            if (!isDataValid) {
                return
            } else {
                // Get batch_settings.zip file ready on server
                let convertedData = this.convertDataToAeiConfig(data);
                API.getBatchSettingsFileReadyOnServer(convertedData, () => {
                    // Download link
                    const downloadLink = "/api/settings/download/batchconfig/" + (localStorage.getItem("username") || (sessionStorage.getItem("username")))
                    const addDialog = ({ onClose }) => {
                        let closeAlert = () => {
                            onClose();
                        }
                        return (
                            <div>
                                <ModalAlert closeAlert={closeAlert} downloadLink={downloadLink} />
                            </div>
                        )
                    };
                    confirmAlert({ customUI: addDialog });
                });

            }
        })

    }

    getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
    }

    // Convert data to aei_config data
    convertDataToAeiConfig = (configData) => {
        let aeiConfigMessage = "";
        const newLine = "\r\n";
        for (let key in configData) {
            if (key == "be_channelPlan" || key == "opChannelList") continue // Ignore these information in P2P Interface
            else {
                let aeiConfigString = AeiConfig[key] + "=" + configData[key];
                // Add value to aeiConfigData
                aeiConfigMessage += aeiConfigString;
                aeiConfigMessage += newLine;
            }
        }
        /* Generate random number
        * “aei_config_RandomNum” is a random code in eight digits. 
        * It must be re - generated after you have configured the receiver once, in this way to prevent repeated configuration. 
        */
        let randomNum = this.getRandomIntInclusive(10000000, 99999999);
        let aei_config_randomNum = "aei_config_RandomNum=" + randomNum; // This random number should be different for each time doing usbAutoconfig.

        // Form the data to send to Web Service
        let usbConfig = aei_config_randomNum + newLine + aeiConfigMessage;
        return usbConfig
    }


    onSBModelChange(event) {
        var model = event.target.value;
        let version = this.ScreenBeamProductList[model][0];
        this.setState({
            selectedModel: event.target.value,
            selectedVersion: version
        });
    }

    onVersionChange(event) {
        var version = event.target.value;
        this.setState({ selectedVersion: event.target.value });
    }

    render() {
        return (
            <div>
                <div className="modal-body">
                    {this.ScreenBeamProductSelection}
                    {this.MultipleRxSettings}
                    {this.downloadOption}
                </div>
                <div className="modal-footer">
                    {this.previousButton}
                    {this.saveButton}
                    {this.nextButton}
                </div>
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...modalActions, ...receiverActions, ...signalRActions, ...settingsActions, ...featureToggleActions }, dispatch)
)(MultipleRxSettings);