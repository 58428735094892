import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as settingActions } from '../../../store/Settings';
import ModalRadio from '../ModalInputs/ModalRadio'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalPasswordInput from '../ModalInputs/ModalPasswordInput'
import ModalSelect from '../ModalInputs/ModalSelect'
import ModalTitle from '../ModalInputs/ModalTitle'
import ModalCheckbox from '../ModalInputs/ModalCheckbox'
import ModalSubtitle from '../ModalInputs/ModalSubtitle'
import ModalPinInput from '../ModalInputs/ModalPinInput'
import ModalRatioInput from '../ModalInputs/ModalRatioInput'
import ModalPostfixInput from '../ModalInputs/ModalPostfixInput'
import ModalFileInput from '../ModalInputs/ModalFileInput'
import ModalDate from '../ModalInputs/ModalDate'
import ModalOperatingChannel from '../ModalInputs/ModalOperatingChannel'
import { confirmAlert } from 'react-confirm-alert';
import ModalAlert from '../ModalInputs/ModalAlert'

// import Settings1100 from './Settings1100'
import ModalIp from '../ModalInputs/ModalIp'
import AllSettings from './AllSettings.js'
import ModalMultipleCheckbox from '../ModalInputs/ModalMultipleCheckbox'
import ScreenBeamProduct from './ScreenBeamProduct'
import BrowseAeiConfigFile from '../../UsbProvisioning/BrowseAeiConfigFile'
import API from "../../../REST/RestApi";
import { Translate } from 'react-localize-redux'
import { actionCreators as featureToggleActions } from '../../../store/FeatureToggle';

import SettingHelpers from '../../Settings/SettingHelpers'

var AeiConfigMapping = require('../../UsbProvisioning/AeiConfigMapping')
var AeiConfig = AeiConfigMapping.AeiConfig;
/* 
 * Created Date: 06/19/2019
 * Description: Display Multiple Receivers Settings based on firmware version
 * Triggered from MultipleRxSettings
*/

class Settings extends React.Component {
    constructor(props) {
        super(props);

        /*************Initialize variables**************/
        // this.changeMap stores data that users select/modify/input
        this.changeMap = {};
        /* this.FirmwareList contains the ScreenBeam Model as the key and an array of firmware as the value.
         This is used for ScreenBeamProduct dropdown UI
         */
        this.FirmwareList = {}
        // defaultModel and defaultVersion are used in Create/Edit Task where the ScreenBeam Product dropdown list is displayed
        let defaultModel = ""
        let defaultVersion = ""

        /*******************Get ScreenBeam Product List in Create/Edit Task (Scheduler)********************** */
        // Check if the component needs to display ScreenBeam product list (Only in Scheduler)
        if (this.props.displayScreenBeamProduct) {
            // Get ScreenBeamProduct List from FeatureToggle.js store
            this.props.getScreenBeamProductList((list) => {
                this.FirmwareList = list
            })
            // Set the initial input values
            defaultModel = Object.keys(this.FirmwareList)[0];
            defaultVersion = this.FirmwareList[defaultModel][0];
        } else {
            defaultVersion = this.props.version ? this.props.version : "11.0";
        }

        // Get Settings based on firmware
        this.Settings = SettingHelpers.getSettingsBasedOnFirmware(defaultVersion)

        // Default is Multiple Rx Settings
        this.selectedCheckboxDict = this.Settings.MultipleRxSettings;

        // Check if the settings is called in USB Provisioning. If it is, display checkbox from MultipleRxSettingsForUSB
        // Since there are some settings that don't support MultipleRxSettings action but support USB Provisioning, we need to check the type and consider them separately
        this.isUsbProvisioning = false;
        if (this.props.type) {
            if (this.props.type.startsWith("UsbProvisioning")) {
                this.selectedCheckboxDict = this.Settings.MultipleRxSettingsForUSB;
                this.isUsbProvisioning = true;
            }
        }

        // Get all feature properties from AllSettings
        let allSettings = this.Settings.AllSettings;
        this.state = {
            settings: allSettings,
            showHideComponent: "0",
            selectedCheckbox: this.selectedCheckboxDict,
            selectedModel: defaultModel,
            selectedVersion: defaultVersion,
            checkboxList: this.Settings.MultipleRxSettings,
            file: ""
        };

        this.validationState = false;
    }

    componentDidMount() {
        // Check if store has any data inside it(It should only have data when editing a task)
        if (this.props.data != null) {
            // Determine which checkboxes and which firmware version should be selected based on what settings are in data
            // Get firmware options and settings display based on model and firmware version
            let version = this.props.fwVersion["fwVersion"]
            if (this.state.selectedVersion != version) {
                let settings = SettingHelpers.getSettingsBasedOnFirmware(version)
                this.selectedCheckboxDict = this.isUsbProvisioning ? settings.MultipleRxSettingsForUSB : settings.MultipleRxSettings
                this.state.selectedCheckbox = this.selectedCheckboxDict
                this.state.checkboxList = this.isUsbProvisioning ? settings.MultipleRxSettingsForUSB : settings.MultipleRxSettings
                this.state.settings = settings.AllSettings
            }

            // Load saved data
            this.props.loadData(this.state, this.selectedCheckboxDict, () => {
                this.setState({ ...this.props.state, selectedVersion: this.props.fwVersion["fwVersion"] || this.state.selectedVersion, selectedModel: this.props.fwVersion["model"] || this.state.selectedModel })
            })
        } else {
            // Save the initial state to Settings store
            this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
        }
    }

    // Called in MultipleRxSettingsModal
    getData() {
        let data = {};
        for (let checkbox in this.state.selectedCheckbox) {
            let checkboxProperties = this.state.selectedCheckbox[checkbox];
            if (checkboxProperties.selected === "1") {
                let childrenData = checkboxProperties.children;
                // Get data from children in selected checkbox 
                for (let i = 0; i < childrenData.length; i++) {
                    let name = childrenData[i];
                    if (this.state.settings[name]) {
                        // In case: Hdmi/Vga Mode
                        if (this.state.settings[name].isNotInOriginalData) {
                            continue;
                        }
                        // In case: Local Wifi Settings. The field names we need to send are different from the one that we receive from CMS Service
                        else if (checkboxProperties.dataToSend && this.state.settings[name].onSubmit) {
                            this.state.settings[name].onSubmit(this);
                            for (let j = 0; j < checkboxProperties.dataToSend.length; j++) {
                                let key = checkboxProperties.dataToSend[j];
                                data[key] = this.changeMap[key];
                            }
                            continue;
                        }
                        else if (this.state.settings[name].onSubmit) {
                            this.state.settings[name].onSubmit(this);
                            // console.log(name, this.changeMap[name])
                            data[name] = this.changeMap[name];
                        }
                        else {
                            data[name] = this.state.settings[name].value;
                        }
                    }
                }

            }
        }
        // console.log(data)
        return data;
    }

    handleSelectAllBtnClick = () => {
        // Update all selected Checkbox
        let temp = this.state.selectedCheckbox;
        Object.keys(temp).map((key, index) => {
            if (temp[key].type == "checkbox") {
                temp[key].selected = "1"
                temp[key].children.forEach((child) => {
                    this.changeMap[child] = this.state.settings[child].value
                })
            }
        });

        // Check if the parent has onActionDataChange function, then update parent. Currently only CreateNewTask(parent) has this function.
        if (this.props.onActionDataChange) {
            let data = this.state;
            data["selectedCheckbox"] = temp;
            this.props.onActionDataChange(data);
        }
        this.setState({ selectedCheckbox: temp }, () => {
            this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
        });
    }

    handleDeselectAllBtnClick = () => {
        let temp = this.state.selectedCheckbox;
        Object.keys(temp).map((key, index) => {
            if (temp[key].type == "checkbox") {
                temp[key].selected = "0"
            }
        });
        // Check if the parent has onActionDataChange function, then update parent. Currently only CreateNewTask(parent) has this function.
        if (this.props.onActionDataChange) {
            let data = this.state;
            data["selectedCheckbox"] = temp;
            this.props.onActionDataChange(data);
        }
        this.changeMap = {} //Reset all selected values when unchecked
        this.setState({ selectedCheckbox: temp }, () => {
            this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
        })
    }

    onCheckboxClicked = (checkboxName, isChecked) => {
        let temp = this.state.selectedCheckbox;
        temp[checkboxName].selected = isChecked;
        let checkBoxList = this.selectedCheckboxDict[checkboxName]
        var children = checkBoxList.children;

        if (isChecked == "1") {
            //Return values to output
            children.forEach((child, index) => {
                this.changeMap[child] = this.state.settings[child].value
            })
        }

        else {
            //Return values to output
            children.forEach((child) => {
                delete this.changeMap[child]
            })
        }

        // Check if the parent has onActionDataChange function, then update parent. Currently only CreateNewTask(parent) has this function.
        if (this.props.onActionDataChange) {
            let data = this.state;
            data["selectedCheckbox"] = temp;
            this.props.onActionDataChange(data);
        }
        this.setState({ selectedCheckbox: temp }, () => {
            this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
        });
    }

    // New design
    onChange = (event, key) => {
        var setting = this.state.settings[key];
        var value = ""
        if (setting.type == "file") {
            value = event.target.files[0]
            if (value == undefined) value = this.state.settings[key].value;
        } else if (setting.type == "date") {
            value = event;
        } else {
            value = event.target.value;
        }
        //Prevalidate: prevent input if the new string does not pass the test
        if (setting.preValidate && !setting.preValidate(value, this)) {
            return;
        }
        SettingHelpers.setSettingState(this,
            key,
            "value",
            value,
            () => {
                var cb = () => {
                    var endTime = new Date()
                    // Save the latest data to store
                    if (!this.props.isModal) {
                        this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
                    }
                }
                //Log change on changeMap
                this.changeMap[key] = value;

                // Call onChange method on object. Used mainly for unique cases
                if (setting.onChange) {
                    setting.onChange(this, value, () => {
                        this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
                    });

                }
                //Validate: If entry is not valid update state with error message
                if (setting.validate) {
                    var error_message = setting.validate(value, this);
                    if (error_message) {
                        SettingHelpers.setSettingState(this, key, "error", error_message, cb);
                    } else {
                        SettingHelpers.setSettingState(this, key, "error", "", cb);
                    }
                }
                else {
                    if (!this.props.isModal) {
                        this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
                    }
                }
            }
        );
    }

    onSBModelChange = (event) => {
        var model = event.target.value;
        let version = this.FirmwareList[model][0];
        var settings = SettingHelpers.getSettingsBasedOnFirmware(version)
        this.selectedCheckboxDict = settings.MultipleRxSettings
        // Reset stored data
        this.changeMap = {}
        this.setState({
            settings: settings.AllSettings,
            selectedModel: event.target.value,
            selectedVersion: version,
            selectedCheckbox: settings.MultipleRxSettings,
            checkboxList: settings.MultipleRxSettings
        }, () => {
            this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
        });
    }

    onVersionChange = (event) => {
        var version = event.target.value;
        var settings = SettingHelpers.getSettingsBasedOnFirmware(version)
        this.selectedCheckboxDict = settings.MultipleRxSettings
        // Reset stored data
        this.changeMap = {}
        this.setState({
            settings: settings.AllSettings,
            selectedVersion: event.target.value,
            selectedCheckbox: settings.MultipleRxSettings,
            checkboxList: settings.MultipleRxSettings
        }, () => {
            this.props.saveState(this.state, this.getData(), { fwVersion: this.state.selectedVersion, model: this.state.selectedModel })
        });
    }

    onFileChange = (event) => {
        var val = event.target.files[0]
        if (val != undefined) {
            this.setState({
                file: val
            }, () => {
                this.props.saveZipFile(this.state.file)
            }
            )
        }
    }

    readAeiConfigFile = () => {
        if (this.state.file != null) {
            API.retrieveAeiConfigFile(this.state.file, (data) => {
                if (data) {
                    var result = this.convertAeiConfigToUIParameters(data.settings)
                    // console.log(result)
                    // Load saved data
                    this.props.saveData(result, { fwVersion: this.state.selectedVersion, model: this.state.selectedModel }, () => {
                        this.props.loadData(this.state, this.selectedCheckboxDict, () => {
                            this.setState({ ...this.props.state, selectedVersion: this.state.selectedVersion, selectedModel: this.state.selectedModel })
                        })
                    })
                } else {
                    console.log("Error when retrieving data")
                }
            })
        }
    }

    // Convert aei_config data to UI parameters
    convertAeiConfigToUIParameters = (data) => {
        var invertedObject = {}
        Object.keys(AeiConfig).map((key, index) => {
            invertedObject[AeiConfig[key]] = key
        })


        // Convert string to object
        var dataArray = data.split("\n")
        var result = {}
        for (var i of dataArray) {
            let subArray = i.split("=")
            let key = subArray[0]
            let name = invertedObject[key]
            if (name) {
                result[name] = subArray[1]
            }
        }
        return result
    }


    get AllSettings() {
        //Load content based on current tab
        var checkboxes = this.state.selectedCheckbox;
        return (
            <div className={this.state.selectedVersion}>
                {Object.keys(checkboxes).map((checkbox, index) => {
                    if (checkboxes[checkbox].type == "title") {
                        return (<ModalTitle key={this.state.selectedVersion + "-" + checkboxes[checkbox].title} title={checkboxes[checkbox].title} />)
                    }
                    return (
                        <ModalMultipleCheckbox CheckboxName={checkbox}
                            key={"multiCheckbox" + "-" + this.state.selectedVersion + checkbox}
                            CheckboxLabel={checkboxes[checkbox].label}
                            IsChecked={this.state.selectedCheckbox[checkbox].selected}
                            onCheckboxClicked={this.onCheckboxClicked}
                            CheckboxChildrenList={checkboxes[checkbox].children}
                            stateSettings={this.state.settings}>
                            {checkboxes[checkbox].children.map((key, index) => {
                                //Map through each object based on tabContent
                                var setting = this.state.settings[key];
                                if (!setting) {
                                    return null;
                                }
                                //USB Auto Config: CMS Server/CMS Communication Port canbe configured
                                if ((key === "mgtSrvFullAddr") || (key === "mgtSrvPort")) {
                                    setting.display = "0";
                                }
                                // Don't display Datetime/CaRoot/UserURL/UserKey in multiple settings (only in USB Provisioning)
                                if (!this.isUsbProvisioning && (setting.type == "date" || setting.type == "file")) { return null }
                                var display = setting.display;

                                //If there are dependencies loop through them
                                if (setting.dependencies) {
                                    var dependencyKey = ""; //Build a string based on the values of the dependencies\
                                    //!!!!!!!!!!!This algorithm may cause issues if feature values become longer than 1 digit!!!!!!!!!!!!!!!!!
                                    setting.dependencies.forEach((dependency) => {
                                        dependencyKey += this.state.settings[dependency].value;
                                    });
                                    display = setting.dependencyMap[dependencyKey] || display;
                                }
                                if (display === "1") {
                                    return null;
                                } //1: Don't show

                                //Switch case for all setting component types
                                if (setting.type === "radioButton") {
                                    return (
                                        <ModalRadio
                                            name={key}
                                            key={"radio" + index}
                                            index={index}
                                            label={setting.label}
                                            info={setting.info}
                                            disabled={display == "2" ? true : false}
                                            options={setting.options}
                                            checked={setting.value}
                                            onChange={(event) => { this.onChange(event, key) }}
                                        />
                                    );
                                } else if (setting.type === "input") {
                                    return (
                                        <ModalTextInput
                                            key={"input" + index}
                                            index={index}
                                            label={setting.label}
                                            value={setting.value}
                                            info={setting.info}
                                            disabled={display == "2" ? true : false}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            error={setting.error}
                                        />
                                    );
                                } else if (setting.type === "password") {
                                    return (
                                        <ModalPasswordInput
                                            key={"password" + index}
                                            index={index}
                                            label={setting.label}
                                            value={setting.value}
                                            info={setting.info}
                                            disabled={display == "2" ? true : false}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            error={setting.error}
                                        />
                                    )
                                } else if (setting.type === "dropdown") {
                                    return (
                                        <ModalSelect
                                            key={"select" + index}
                                            index={index}
                                            label={setting.label}
                                            value={setting.value}
                                            info={setting.info}
                                            disabled={display == "2" ? true : false}
                                            options={setting.options}
                                            onChange={(event) => { this.onChange(event, key) }}
                                        />
                                    );
                                }
                                else if (setting.type === "ip") {
                                    return (
                                        <ModalIp
                                            key={"Ip" + index}
                                            index={index}
                                            label={setting.label}
                                            value={setting.value}
                                            info={setting.info}
                                            disabled={display == "2" ? true : false}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            error={setting.error}
                                        />
                                    );
                                } else if (setting.type === "subtitle") {
                                    return (
                                        <ModalSubtitle
                                            key={"Subtitle" + index}
                                            title={setting.title}
                                        />
                                    );
                                } else if (setting.type === "title") {
                                    return (
                                        <ModalTitle
                                            key={"Title" + index}
                                            title={setting.title}
                                        />
                                    );
                                } else if (setting.type === "prefixInput") {
                                    return (
                                        <ModalPostfixInput
                                            index={index}
                                            label={setting.label}
                                            value={setting.value}
                                            info={setting.info}
                                            disabled={display === "2" ? true : false}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            error={setting.error} />
                                    );
                                } else if (setting.type === "ratio") {
                                    return (
                                        <ModalRatioInput
                                            index={index}
                                            label={setting.label}
                                            value={setting.value}
                                            horizontalName={key}
                                            verticalName={setting.verticalName}
                                            verticalValue={this.state.settings[setting.verticalName].value}
                                            info={setting.info}
                                            disabled={display === "2" ? true : false}
                                            onChange={(event, specialKey) => { this.onChange(event, specialKey) }}
                                            error={setting.error} />
                                    );
                                } else if (setting.type === "checkbox") {
                                    return (
                                        <ModalCheckbox
                                            index={index}
                                            key={checkbox + index}
                                            label={setting.label}
                                            id={key}
                                            value={setting.value}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            disabled={display === "2" ? true : false} />
                                    );
                                } else if (setting.type === "pin") {
                                    return (
                                        <ModalPinInput
                                            index={index}
                                            label={setting.label}
                                            id={key}
                                            value={setting.value}
                                            maxLength={setting.maxLength}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            disabled={display === "2" ? true : false}
                                            error={setting.error}
                                            info={setting.info} />
                                    );
                                } else if (setting.type === "file") {
                                    return (
                                        <ModalFileInput
                                            name={key}
                                            acceptFile={setting.acceptFile}
                                            fileName={setting.fileName}
                                            value={setting.value}
                                            label={setting.label}
                                            value={setting.value}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            disabled={this.isUsbProvisioning ? false : true}
                                            info={setting.info}
                                            error={setting.error} />
                                    )
                                } else if (setting.type === "date") {
                                    return (
                                        <ModalDate
                                            name={key}
                                            label={setting.label}
                                            onChange={(event) => { this.onChange(event, key) }}
                                            disabled={this.isUsbProvisioning ? false : true}
                                            value={setting.value}
                                            info={setting.info}
                                            error={setting.error} />
                                    )
                                } else if (setting.type === "channel") {
                                    return (
                                        <ModalOperatingChannel
                                            value={setting.value}
                                            opChannelList={this.state.settings[setting.opChannelList].value}
                                            onChange={(event) => { this.onChange(event, key) }}
                                        />
                                    )
                                }
                            })}
                        </ModalMultipleCheckbox>
                    );
                })}
            </div>
        )
    }

    get MultipleSelect() {
        if (this.props.displayScreenBeamProduct) {
            return (
                <div className="row display-screenbeam-product">
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-5 px-1">
                                <select className="form-control" name="selectedModel" value={this.state.selectedModel} onChange={this.onSBModelChange}>
                                    <Translate>
                                        {({ translate }) => {
                                            return (Object.keys(this.FirmwareList).map((model, key) => (
                                                <option key={key} value={model}>{translate("ScreenBeamModel" + model)}</option>
                                            )))
                                        }}
                                    </Translate>
                                </select>
                            </div>

                            <div className="col-sm-7 px-1">
                                <select className="form-control" name="selectedVersion" value={this.state.selectedVersion} onChange={this.onVersionChange}>
                                    <Translate>
                                        {({ translate }) => {
                                            return (
                                                this.FirmwareList[this.state.selectedModel].map((fw, key) => (
                                                    <option key={key} value={fw}>{translate(fw)}</option>
                                                ))
                                            )
                                        }
                                        }
                                    </Translate>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="row">
                            <div className="col-sm-5 px-0">
                                <button type="button" id="SelecteAll" className="btn modal-button px-2" onClick={this.handleSelectAllBtnClick}><Translate id="SelectAll" /></button>
                            </div>
                            <div className="col-sm-7 px-0">
                                <button type="button" className="btn modal-button px-2" onClick={this.handleDeselectAllBtnClick}><Translate id="DeselectAll" /></button>
                            </div>
                        </div>
                    </div>
                </div >
            )
        } else {
            if (this.isUsbProvisioning) {
                return (
                    <div className="row" style={{"margin-top": "-15px", "margin-bottom": "-15px"}}>
                        <div className="col-sm-12">
                            <button type="button" className="btn modal-button" style={{ "float": "right" }} onClick={this.handleDeselectAllBtnClick}><Translate id="DeselectAll" /></button>
                        </div>
                    </div>
                )
            }
            return (
                <div className="row" style={{"margin-top": "-15px", "margin-bottom": "-15px"}}>
                    <div className="col-sm-3 offset-sm-6" style={{ "margin-left": "434px" }}>
                        <button type="button" className="btn modal-button" style={{ "margin-left": "50px" }} onClick={this.handleDeselectAllBtnClick}><Translate id="DeselectAll" /></button>
                    </div>
                    <div className="col-sm-3" style={{ "margin-left": "-60px" }}>
                        <button type="button" className="btn modal-button" style={{ "float": "right" }} onClick={this.props.handleSaveZipFile}><Translate id="SaveAsZipFile" /></button>
                    </div>
                </div >
            )
        }
    }

    get InputAeiConfigFile() {
        return (
            <div className="row" style={{ "margin-top": "10px" }}>
                <div className="col-sm-9">
                    <BrowseAeiConfigFile
                        name="aeiConfigFile"
                        onChange={this.onFileChange}
                        value={this.state.file}
                        info={this.isUsbProvisioning ? "NoteReadAeiConfigFile" : "NoteReadBatchConfigFile"} />
                </div>
                <div className="col-sm-3">
                    <button type="button" className="btn modal-button" onClick={this.readAeiConfigFile} style={{ "float": "right" }}><Translate id="Apply" /></button>
                </div>
            </div >
        )
    }

    render() {
        return (
            <React.Fragment >
                <div className="col-12">
                    {this.props.displayScreenBeamProduct ? null : this.InputAeiConfigFile}
                    {this.MultipleSelect}
                    <br />
                    <div>
                        {this.AllSettings}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    state => state.settings,
    dispatch => bindActionCreators({ ...settingActions, ...featureToggleActions }, dispatch)
)(Settings);
