import React from 'react'
import { Translate } from 'react-localize-redux'

import ModalFileInput from '../Modals/ModalInputs/ModalFileInput'

class BrowseAeiConfigFile extends React.Component {
    render() {
        var fileName = "No file chosen"
        if (this.props.value != "") {
            fileName = this.props.value.name;
        }
        return (
            <div className="row">
                <input disabled={this.props.disabled} type="file" className="inputFile modal-file-input" accept=".zip" id={"formInput" + this.props.name} onChange={(event) => { this.props.onChange(event) }} />
                <label htmlFor={"formInput" + this.props.name} className="inputFile2 col-sm-3">
                    <i className="material-icons large file-icon">publish</i>
                    <span className="file-title">Choose file</span>
                </label>
                <div class="file-path-wrapper col-sm-8">
                    <input disabled class="file-path" type="text" placeholder={fileName} />
                </div>
                <p className="col-sm-12 file-note"><Translate id={this.props.info}/></p> 
            </div >
        )
    }
}

export default BrowseAeiConfigFile