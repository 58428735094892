import React from 'react'
import { actionCreators as siteActions } from '../../store/Sites'
// import { actionCreators as signalRActions} from '../../../store/SignalR'
import { actionCreators as modalActions} from '../../store/Modal'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {MenuItem} from "react-contextmenu"


class SiteContext extends React.Component{
    deleteSite = () => {
        this.props.createModal("Delete Site",{...this.props.contextTargetSite})
    }

    addUsers = () => {
        this.props.createModal("Add Users to Site", "addUser", {...this.props.contextTargetSite}, this.props.contextTargetSite.name) //AddUser argument to bypass and hide first page of modal
    }

    rename = () => {
        this.props.targetRenameSite({...this.props.contextTargetSite})
    }

    joinReceivers = () => {
        this.props.joinReceivers({...this.props.contextTargetSite})
    }

    releaseReceivers = () => {
        this.props.releaseReceivers({...this.props.contextTargetSite})
    }

    render(){
        var disabled = false
        //Prevent actions on "Unassigned and All Receiver sites"
        if(!(this.props.isSystemAdmin == true || this.props.isSystemAdmin == "true") || ( this.props.contextTargetSite && (this.props.contextTargetSite.id == "-1" || this.props.contextTargetSite.id == "-2"))){
            disabled = true
        }
        if(!disabled){
            return(
                <div className="context-menu">
                    <MenuItem onClick={this.rename}><div className={"context-menu-item"}>Rename</div></MenuItem>
                    <MenuItem onClick={this.addUsers}><div className={"context-menu-item"}>Add Users</div></MenuItem>
                    <MenuItem onClick={this.joinReceivers}><div className={"context-menu-item"}>Join Receivers</div></MenuItem>
                    <MenuItem onClick={this.releaseReceivers}><div className={"context-menu-item"}>Release Receivers</div></MenuItem>
                    <MenuItem onClick={this.deleteSite}><div className={"context-menu-item"}>Delete Site</div></MenuItem>
                </div>
            )
        }
        else{
            return(
                <div className="context-menu">
                    <MenuItem><div className={"context-menu-item context-disabled"}>Rename</div></MenuItem>
                    <MenuItem><div className={"context-menu-item context-disabled"}>Add Users</div></MenuItem>
                    <MenuItem><div className={"context-menu-item context-disabled"}>Join Receivers</div></MenuItem>
                    <MenuItem><div className={"context-menu-item context-disabled"}>Release Receivers</div></MenuItem>
                    <MenuItem><div className={"context-menu-item context-disabled"}>Delete Site</div></MenuItem>
                </div>
            )
        }

    }
}

export default connect(
    state => ({...state.user, ...state.sites}),
    dispatch => bindActionCreators({...modalActions,...siteActions},  dispatch)
  )(SiteContext);
  