//This module is currently used for building data structures for sites/receivers and for selection logic

export const RESTUtils = {
    timeoutWrapper: (ms, promise) => {
        return new Promise(function(resolve, reject){
            setTimeout(function(){
                reject(new Error('Request timeout.'))
            }, ms)
            promise.then(resolve,reject)
        })
    }
}


var Utils = {
    select: (items, prevSelected, offset, target, selectKeyName, itemKeyName, force=false, filter=null) => {
        var selected = {
            start: -1,
            end: -1,
            direction: "UP",
            [selectKeyName]: {}
        }
        var targetKey = items[target][itemKeyName]
        if(force){
            selected.start= target+offset
            selected.end = target+offset
            if(filter){
                if(filter(items[target])){
                    selected[selectKeyName][targetKey] = items[target]
                }
            }
            else{
                selected[selectKeyName][targetKey] = items[target]
            }
        }
        else if(target!= prevSelected.start){
            selected.start= target+offset
            selected.end = target+offset
            if(filter){
                if(filter(items[target])){
                    selected[selectKeyName][targetKey] = items[target]
                }
            }
            else{
                selected[selectKeyName][targetKey] = items[target]
            }
        }
        return selected
    },

    selectAdd: (items,selected, offset, target,selectKeyName, itemKeyName, filter=null)=>{
        var targetKey = items[target][itemKeyName]
        if(!(targetKey in selected[selectKeyName])){
            selected.start = target+offset
            selected.end = target+offset
            if(filter){
                if(filter(items[target])){
                    selected[selectKeyName][targetKey] = items[target]
                }
            }
            else{
                selected[selectKeyName][targetKey] = items[target]
            }
        }
        else{
            delete selected[selectKeyName][targetKey]
        }
        return Object.assign({},selected)
    },
    
    selectMultiple: (items,selected, target,selectKeyName, itemKeyName, filter=null) => {
        var prevStart = selected.start
        var prevEnd = selected.end
        if(target < selected.start){
            selected.start = target
            selected.direction = "UP"
        }
        else if(target > selected.end){
            selected.end = target
            selected.direction = "DOWN"
        }
        else{
            if(selected.direction == "UP"){
                selected.start = target
            }
            else{
                selected.end = target
            }
        }
        for(var i=prevStart; i<selected.start;i++){
            //Delete all keys from 
            delete selected[selectKeyName][items[i][itemKeyName]]
        }
        for(var i=selected.end+1; i<=prevEnd;i++){
            delete selected[selectKeyName][items[i][itemKeyName]]
        }
        for(var i = selected.start; i<=selected.end; i++){
            if(filter){
                if(filter(items[i])){
                    selected[selectKeyName][items[i][itemKeyName]] = items[i]
                }
            }
            else{
                selected[selectKeyName][items[i][itemKeyName]] = items[i]
            }
        }
        return Object.assign({},selected)
    },

    selectMultipleMacs: (target, receivers,selected, filter) => {
        var prevStart = selected.start
        var prevEnd = selected.end
        if( target< selected.start){
            selected.start = target
            selected.direction = "UP"
        }
        else if(target > selected.end){
            selected.end = target
            selected.direction = "DOWN"
        }
        else{
            if(selected.direction == "UP"){
                selected.start = target
            }
            else{
                selected.end = target
            }
        }
        if(prevStart < selected.start){
            for(var i = 0;i<receivers.length-1;i++){
                delete selected.macs[receivers[i].RXMAC]
            }
        }
        else if(prevEnd > selected.end){
            for(var i = 1;i<receivers.length;i++){
                delete selected.macs[receivers[i].RXMAC]
            }
        }
        else{
            receivers.forEach((receiver)=>{
                if(filter){
                    if(filter(receiver)){
                        selected.macs[receiver.RXMAC] = receiver
                    }
                }
                else{
                    selected.macs[receiver.RXMAC] = receiver
                }
            })
        }
        return selected
    },
    orderedSitesFromNested: function(sites, res = [], nestLevel = 0){
        sites.forEach((site)=>{
            res.push({key: site.id, value: site.name, nestLevel: nestLevel})
            if(site.children && site.children.length){
                this.orderedSitesFromNested(site.children,res,nestLevel+1)
            }
        })
        return res
    },
    buildNestedData: function(data,roles,rolesMap,sortState){
        var treeList = []
        var lookup = {}
        data.forEach(function(obj){
            lookup[obj.id] = obj
            obj.children = []
        })
        Object.keys(roles).forEach((key)=>{
            var role = roles[key]
            if(role.siteId in lookup){
                lookup[role.siteId].role = role.roleId
                rolesMap[role.siteId] = role.roleId
            }
        })
        data.forEach(function(obj){
            if(obj.parentId && lookup[obj.parentId]){
                lookup[obj.parentId].children.push(obj)
                if(lookup[obj.parentId].role && !obj.role){
                    //If the child object does not have a role inherit its parent's role
                    obj.role = lookup[obj.parentId].role
                    rolesMap[obj.id] = obj.role
                }
            }
            else{
                treeList.push(obj)
            }
        })

        //Move all other sites under the "AllReceivers" site (index 0)
        var children = treeList.splice(1)
        treeList[0].children = treeList[0].children.concat(children)
        this.treeSort(treeList,sortState)
        
        return treeList
    },
    treeSort: function(treeList,sortState){
        if(treeList.length == 0){return []}
        if(sortState == 1){
            treeList.sort((data1,data2)=>{
                if(data1.id == "-1"){
                    return -1
                }
                if(data1.id == "-2"){
                    return 1
                }
                return data1.name.localeCompare(data2.name)
            })
        }
        else if(sortState == 2){
            treeList.sort((data1,data2)=>{
                if(data1.id == "-1" || data2.id == "-2"){
                    return -1
                }
                if(data1.id == "-2" || data2.id == "-1"){
                    return 1
                }
                return data2.name.localeCompare(data1.name)
            })  
        }
        treeList.forEach((tree)=>{
            this.treeSort(tree.children,sortState)
        })
        return treeList
    }
}

export default Utils