import React from 'react';
import ModalFooter from '../ModalFooter'
import ModalRadio from '../ModalInputs/ModalRadio'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalDateInput from '../ModalInputs/ModalDateInput'
import ModalPasswordInput from '../ModalInputs/ModalPasswordInput'
import ModalSelect from'../ModalInputs/ModalSelect'
import ModalTitle from '../ModalInputs/ModalTitle'
import ModalCheckboxGroup from '../ModalInputs/ModalCheckBoxGroup'
import ModalCheckbox from '../ModalInputs/ModalCheckbox'
import ModalSubtitle from '../ModalInputs/ModalSubtitle'
import ModalIp from '../ModalInputs/ModalIp'
import triggerTypes from '../../../Utils/triggerTypes'
import weekdayTypes from '../../../Utils/weekDayTypes'

var recurrenceOptions = {
    ...triggerTypes
}

const weekdayMap = {
    ...weekdayTypes
}

class TriggerScheduleTab extends React.Component {
    renderRecurrenceSubOptions(){
        if(this.props.values.sche_Trigger_Type == triggerTypes.Once){
            return
        }
        else if(this.props.values.sche_Trigger_Type == triggerTypes.Daily){
            return <ModalTextInput onChange={(event)=>{this.props.onChange(event,"sche_Recur_Count")}} value={this.props.values.sche_Recur_Count} label="DaysInBetweenRuns" error={this.props.warnings.sche_Recur_Count}/>
        }
        else if(this.props.values.sche_Trigger_Type == triggerTypes.Weekly){
            return(
                <div>
                    <ModalCheckboxGroup label={"Days of the week"} nospacing={true} warningActive={this.props.warningActive} error={this.props.warnings["sche_Recur_Days"]}>
                        {
                            [0,1,2,3,4,5,6].map((n,index)=>{
                                return(<ModalCheckbox key={"checkbox" + index} nospacing={true} index={index} onChange={(event)=>{this.props.onChange(event,"sche_Recur_Days_"+index)}} checked={this.props.values["sche_Recur_Days_"+index]} label={weekdayMap[index]} />)
                            })
                        }
                    </ModalCheckboxGroup>
                </div>
            )
        }
        else if(this.props.values.sche_Trigger_Type == triggerTypes.Monthly){
            return <ModalTextInput onChange={(event)=>{this.props.onChange(event,"sche_Day_Of_Month")}} value={this.props.values.sche_Day_Of_Month} label="TheDayOfMonth" error={this.props.warnings.sche_Day_Of_Month}/>
        }
        else if(this.props.values.sche_Trigger_Type == triggerTypes.Minutes){
            return <ModalSelect onChange={(event)=>{this.props.onChange(event,"sche_Minutes")}} value={this.props.values.sche_Minutes} label="Minutes" error={this.props.warnings.sche_Minutes} options={[5,10,15,20,25,30,35,40,45,50,55,60]}/>
        }
    }

    onEndDateTypeChange = (event) => {
        var endDateChangeEvent = {target: {value: new Date()}}
        this.props.onChange(endDateChangeEvent,"sche_End_Date")
        this.props.onChange(event,"sche_End_Type_Date")
    }

    render() {
        return (     
            <div style={{minHeight: "40vh"}}>
                <ModalDateInput onChange={(event)=>{this.props.onChange(event,"sche_Start_Time")}} value={this.props.values.sche_Start_Time} label="StartDate" showTimeSelect warningActive={this.props.warningActive} error={this.props.warnings["sche_Start_Time"]}/>
                <ModalSelect onChange={(event)=>{this.props.onChange(event,"sche_Trigger_Type")}} value={this.props.values.sche_Trigger_Type} label="Recurrence" options={recurrenceOptions} />
                {this.renderRecurrenceSubOptions()}
                {this.props.values.sche_Trigger_Type!=triggerTypes.Once && 
                    <div>
                        <ModalDateInput 
                            disabled={this.props.values.sche_End_Type_Date != 1} 
                            label="EndDate"
                            onChange={(event)=>{this.props.onChange(event,"sche_End_Date")}} 
                            value={this.props.values.sche_End_Date}
                            className="form-check-input" onChange={(event)=>{this.props.onChange(event,"sche_End_Date")}}
                            showTimeSelect
                        /> 
                        <ModalCheckbox label="EndDate" checked={this.props.values.sche_End_Type_Date == 1} onChange={this.onEndDateTypeChange}/>
                        {/* <input type="checkbox" checked={this.props.values.sche_End_Type_Date} onChange={(event)=>{this.props.onChange({target:{value: event.target.checked}},"sche_End_Type_Date")}} style={{position:"relative",top:"40px"}} /> */}
                    </div>
                }
            </div>
        );
    }
}


export default TriggerScheduleTab;
