import React from 'react'

// Shows general info about the table of receivers
class ReceiverTableInfo extends React.Component {
    render() {
        return (
            <div className="table--info--light">
                Currently Viewing | Total Receivers: {this.props.total} | Idle: {this.props.idle} | Busy: {this.props.busy} | Offline: {this.props.offline} | Selected: {this.props.selected}
            </div>
        );
    }
}

export default ReceiverTableInfo

// Selected: {this.props.selected}
  