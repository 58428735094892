import React from 'react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Translate } from 'react-localize-redux'


class ModalDate extends React.Component {

    render() {
        var selectedDate = this.props.value != "" ? this.props.value : new Date()

        return (
            <div className="form-group row settings-row">
                <label className="modal-label col-sm-4 col-form-label"><Translate id={this.props.label} /></label>
                <div className="col-sm-4 modal-date-wrapper" >
                    <DatePicker disabled={this.props.disabled} className="modal-date-input" dateFormat="MM/dd/yyyy" selected={selectedDate} onChange={date => this.props.onChange(date)} />
                </div>
            </div>
        )
    }
}


export default ModalDate