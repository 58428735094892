import React from 'react';
import { Translate } from 'react-localize-redux'

class ModalIp extends React.Component {

    handleChange = (index, event) => {
        var ipVal = this.props.value.split(".")
        var ip = ipVal.slice()

        ip[index] = event.target.value
        var joinedIp = {
            target: {
                value: ip.join(".")
            }
        }
        this.props.onChange(joinedIp)
    }

    render() {
        var ipVal = this.props.value.split(".")
        return (
            <div className="row settings-row">
                <label className="col-sm-4 col-form-label modal-label"><Translate id={this.props.label} /></label>
                <div className="col-sm-1" disabled={this.props.disabled}><input type="text" className="ip-input" disabled={this.props.disabled} maxLength="3" value={ipVal[0]} onChange={(event) => { this.handleChange(0, event) }} /></div>
                <div className="col-sm-s1">.</div>
                <div className="col-sm-1" disabled={this.props.disabled}><input type="text" disabled={this.props.disabled} className="ip-input" maxLength="3" value={ipVal[1]} onChange={(event) => { this.handleChange(1, event) }} /></div>
                <div className="col-sm-s1">.</div>
                <div className="col-sm-1" disabled={this.props.disabled}><input type="text" disabled={this.props.disabled} className="ip-input" maxLength="3" value={ipVal[2]} onChange={(event) => { this.handleChange(2, event) }} /></div>
                <div className="col-sm-s1">.</div>
                <div className="col-sm-1" disabled={this.props.disabled}><input type="text" disabled={this.props.disabled} className="ip-input" maxLength="3" value={ipVal[3]} onChange={(event) => { this.handleChange(3, event) }} /></div>
                {this.props.error &&
                    <div className="col-sm-3 error"><Translate id={this.props.error} /></div>
                }
                {(!this.props.error) &&
                    <div className="col-sm-3 info"><Translate id={this.props.info} /></div>
                }
            </div>
        );
    }
}

export default ModalIp