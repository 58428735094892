import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import * as User from './User';
import * as SignalR from './SignalR';
import * as Receiver from './Receiver'
import * as ReceiverLog from './ReceiverLog'
import * as Modal from './Modal'
import * as Groups from './Groups'
import * as Context from './ContextMenu'
import * as Notification from './Notifications'
import * as Sites from './Sites'
import * as Settings from './Settings'
import * as UsbProvisioning from './UsbProvisioning'
import * as Tasks from './Tasks'
import * as SchedulerLog from './SchedulerLog'
import * as FeatureToggle from './FeatureToggle'

export default function configureStore (history, initialState) {
  const reducers = {
    user: User.reducer,
    signalr: SignalR.reducer,
    receiver: Receiver.reducer,
    receiverLog: ReceiverLog.reducer,
    modal: Modal.reducer,
    groups: Groups.reducer,
    context: Context.reducer,
    notification: Notification.reducer,
    sites: Sites.reducer,
    settings: Settings.reducer,
    usbProvisioning: UsbProvisioning.reducer,
    tasks: Tasks.reducer,
    schedulerLog: SchedulerLog.reducer,
    featureToggle: FeatureToggle.reducer
  };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }
    const persistConfig = {
        key: 'root',
        storage
        //stoArage: AsyncStorage
        //whitelist: [
        //    'user',
        //]
        //,
        //blacklist: [
        //    'counterReducer',
        //],
    };


    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    const persistedReducer = persistReducer(persistConfig, rootReducer)


    var store = createStore(
        rootReducer,
        // persistedReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
    let persistor = persistStore(store)
    //store.dispatch({ type: "DELETE_USER", UserId: "4" });
    return { store };
}
