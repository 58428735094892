import {actionCreators as Modal} from './Modal'
import API from "../REST/RestApi";
import {actionCreators as Notification, notifications, notificationTypes} from './Notifications'
import {actionCreators as siteActions } from './Sites'

const updateType = 'UPDATE_SCHEDULER_LOGS';
const getTableType = "GET_TABLE_SCHEDULER_LOGS"
const setTableType = "SET_TABLE_SCHEDULER_LOGS"
const initialState = { 
    schedulerLogs: [],
    count: 0,
    dataTables: null,
    table:[]
 };

export const actionCreators = {
    setDataTable: (dataTables) => async(dispatch,getState) => {
        dispatch({type: setTableType, dataTables})
    },

    getAllSchedulerLogs: (cb, errorCb) => async(dispatch,getState) => {
        var startTime = new Date()
        API.getAllSchedulerLogs((res)=>{
            var count = res.total
            var schedulerLogs = res.data
            dispatch({type: getTableType, schedulerLogs: schedulerLogs, count: count})
            var timeElapsed = (new Date() - startTime)/1000
            dispatch(Notification.createNotification(notificationTypes.success,notifications.successMessages.getRxLogSuccess(timeElapsed)))
            if(cb){
                cb() 
            }
        },()=>{
            if(errorCb){
                dispatch(Notification.createNotification(notificationTypes.error,notifications.errorMessages.genericFail))
                errorCb()
            }
        })
    },

    getAllSchedulerLogsBySearchColumn: (keyword, column, startDate, endDate , sortColumn, sortAsc, start, length, dataTableCallback, errorCallback) => async(dispatch, getState) => {
        var startTime = new Date()
        API.getAllSchedulerLogsBySearchColumn(keyword, column, startDate, endDate,  sortColumn, sortAsc, start, length, (res) => {
            dispatch({type: updateType, schedulerLogs: res.data, count: res.total})
            var timeElapsed = (new Date() - startTime)/1000
            dispatch(Notification.createNotification(notificationTypes.success,notifications.successMessages.getRxLogSuccess(timeElapsed)))
            if (dataTableCallback) {
                dataTableCallback()
            }
        }, ()=> {
            if (errorCallback) {
                dispatch(Notification.createNotification(notificationTypes.error,notifications.errorMessages.genericFail))
                errorCallback()
            }
        })
    }
};

export const reducer = (state, action) => {
  //Initialize state
  state = state || initialState;

  //Return copy of state with edited state
  if (action.type === updateType) {
    return { ...state, schedulerLogs: action.schedulerLogs, count: action.count};
  }

  else if (action.type === setTableType){
      return {...state, table: action.schedulerLogs}
  }
  return state;
};
