import React from 'react'
import {Translate} from 'react-localize-redux'

class Dropdown extends React.Component {
    render(){
        return(
            <div className={"dropdown " + this.props.className} >
                <button className="table-button btn btn-outline-secondary buttons-collection dropdown-toggle btn-secondary" type="button" data-toggle="dropdown">
                    <span><Translate id={this.props.title}/></span>
                </button>
                <div className="dropdown-menu" >
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Dropdown;