import React from 'react'

class DropdownItem extends React.Component {
    render(){
        return(
            <a data={this.props.data} href={this.props.href} onClick={(event)=>{if(this.props.onClick){this.props.onClick(this.props.value)}}} className={this.props.className ? this.props.className + ' dropdown-item' : 'dropdown-item'}><i className={this.props.icon}/>{this.props.children}</a>
        )
    }
}

export default DropdownItem;