import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as schedulerLogActions } from '../../store/SchedulerLog';
import { actionCreators as modalActions } from '../../store/Modal'
import { actionCreators as notificationActions, notifications, notificationTypes } from '../../store/Notifications'
import { Translate } from 'react-localize-redux'
import Dropdown from '../Dropdown/Dropdown';
import DropdownItem from '../Dropdown/DropdownItem'

// Date picker
import DatePicker from 'react-datepicker'
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

///datatable imports
import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-dt'
import 'datatables.net-scroller'

// Get current active language
var tableInfo = require('./TableConfig')
var scopeList = tableInfo.scopeList;
var pollingInterval = 2000 //Time in ms

class SchedulerLogTable extends React.Component {
    state = {
        searchKeyword: "",
        searchColumn: Object.keys(scopeList)[0],
        headerVisibility: {},
        startDate: new Date(),
        endDate: new Date(),
        isSearchedByDateTime: false,
        isSearchBtnClicked: false
    }

    tooltipHandler = null

    headerMap = tableInfo.getHeaderMap()

    dataTableRef = null
    dataTable = null

    //Select Parameters
    prevTarget = -1
    target = -1
    offset = 0
    dataTable = null

    //Parameters used for querying tasks
    sortParam = "Name";
    asc = true;
    data = null;


    columnDefs = [
        { targets: [1], className: "dt-drag"},
        { targets: [2], render: $.fn.dataTable.render.text(), className: "dt-nowrap tooltip-trigger" },
        // {targets: [4], render: (data,type,row,meta) => {
        //     var utc = moment.utc(data).toDate()
        //     var day = moment(utc).local()
        //     var res = day.format("M-D-YYYY HH:mm")
        //     return res
        // }},
        // {targets: [5], render: (data,type,row,meta) => {
        //     var utc = moment.utc(data)
        //     var res = utc.format("M-D-YYYY HH:mm")
        //     return res + " UTC"
        // }},
        { targets: '_all', className: "dt-nowrap tooltip-trigger" },
    ]

    pollTimer = null; //Will Store the timer used for polling
    pollHandler = () => { //Callback when pollTimer triggers
        if (this.dataTable) {
            this.dataTable.rows().invalidate()
            this.dataTable.draw('page')
        }
    }

    addJQueryEvents = () => {
        var _SchedulerLogTable = this
        //Reattach jquery events
        $(".tooltip-trigger").on('mousemove', function(e){
            var cellData = _SchedulerLogTable.dataTable.cell(this).data()
            var rowData = _SchedulerLogTable.dataTable.row($(this).closest('tr')).data()
            if(!rowData){
                return
            }
            // if($(this).hasClass('sche-trigger')){
            //     var cellData = _SchedulerTable.getTriggerToolTip(rowData)
            // }
            $("#tooltip").text(cellData).css({ left: e.pageX, top: e.pageY + 20 }, 1)

            if(_SchedulerLogTable.tooltipHandler === null){
                _SchedulerLogTable.tooltipHandler = setTimeout(()=>{
                    if(cellData && cellData.length > 1){
                        if (!$("#tooltip").is(':visible')) $("#tooltip").show()
                    }      
                },750)
            }
        })

        $(".tooltip-trigger").on('mouseleave', function(e) {
            if(_SchedulerLogTable.tooltipHandler){
                clearTimeout(_SchedulerLogTable.tooltipHandler)
                _SchedulerLogTable.tooltipHandler = null
            }
            $("#tooltip").hide()
        })  
    }

    componentWillUnmount() {
        clearTimeout(this.pollTimer)
        this.dataTable.destroy(true)
        this.dataTable = null
    }
    componentDidMount() {
        // //Create reference to for use with jQuery
        var _SchedulerLogTable = this

        //Jquery is necessary to stop the propagation of Bootstrap's close dropdown on click
        $(".dropdown-scheduler-log-header").on('click', function (event) {
            event.stopPropagation()
            var data = JSON.parse($(this).attr("data"))
            var column = _SchedulerLogTable.dataTable.column(data["index"])
            column.visible(!column.visible())
            sessionStorage.setItem("schelog" + [data["key"]], column.visible())
            _SchedulerLogTable.setState({ headerVisibility: { ..._SchedulerLogTable.state.headerVisibility, [data["key"]]: !_SchedulerLogTable.state.headerVisibility[data["key"]] } })
        })


        //Initialize header visibility state
        var headerVisibility = {}
        Object.keys(this.headerMap).forEach((key) => {
            if (this.headerMap[key].visible == true) {
                headerVisibility[key] = true
            }
            else {
                headerVisibility[key] = false
            }
        })

        this.setState({ headerVisibility: headerVisibility })
        var config = tableInfo.getConfig()

        


        //Configure datatable
        config["ajax"] = (data, cb, settings) => {
            if (this.dataTable) {
                this.data = data //Set this.data to data so that it is accessible by react methods
                //data.start, data.length, data.draw
                //API query here and call cb when received
                var changed = this.setParams(this.dataTable, data)
                if (changed) {
                    $('div.dataTables_scrollBody').scrollTop(0)
                }

                let column = scopeList[this.state.searchColumn];
                // var column = ""
                let keyword = this.state.searchKeyword;


                this.props.getAllSchedulerLogsBySearchColumn(keyword, column, this.state.startDate, this.state.endDate, this.sortParam, this.asc, data.start, data.length, () => {
                    var result = [...this.props.schedulerLog.schedulerLogs]
                    cb({ draw: data.draw, data: result, recordsFiltered: this.props.schedulerLog.count })
                    this.addJQueryEvents()
                    // clearTimeout(this.pollTimer)
                    // this.pollTimer = setTimeout(this.pollHandler, pollingInterval)
                }, () => {
                    // clearTimeout(this.pollTimer)
                    // this.pollTimer = setTimeout(this.pollHandler, pollingInterval)
                })

            }
        }

        //Instantiate datatable
        config["columnDefs"] = this.columnDefs
        this.dataTable = $(this.dataTableRef).DataTable(config);
        this.dataTable.resetHeight = $('#scheduler-log-table div.dataTables_scrollBody').scrollTop(0)
        // this.props.setDataTable(this.dataTable) //Set table to store so it may be called from other react components
        this.pollHandler() //Instantiate first polling Timer
        $.fn.dataTable.ext.errMode = 'none'; //Suppress error messages
    }

    onSearchKeywordChange = (event) => {
        this.setState({ searchKeyword: event.target.value }, () => {
            this.pollHandler()
        })
        // $('div.dataTables_scrollBody').scrollTop(0)
    }

    onSearchColumnChange = (value) => {
        let isDateTime = false;
        let isSearchBtnClicked = this.state.isSearchBtnClicked;
        if (value == "DateTime") isDateTime = true;
        if (!isDateTime) isSearchBtnClicked = false;
        this.setState({ searchColumn: value, isSearchedByDateTime: isDateTime, isSearchBtnClicked: isSearchBtnClicked }, () => {
            this.pollHandler()
        });
    }

    setParams(dataTable, data) {
        var changed = false
        if (dataTable) {
            //Retrieve sort column
            var sortParam = dataTable.column(data.order[0].column).dataSrc() //Map header names back into db column names

            //retrieve ascending/descending
            var asc
            if (data.order[0].dir != "asc") {
                asc = false
            }
            else {
                asc = true
            }

            if (this.sortParam != sortParam || this.asc != asc) {
                this.sortParam = sortParam
                this.asc = asc
                changed = true
            }
        }
        return changed
    }

    onDateChange = (date, key) => {
        this.setState({ [key]: date }, () => {
            this.dataTable.rows().invalidate()
            this.dataTable.draw('page')
        })
    }

    returnVisible = (headerkey) => {
        if (this.state.headerVisibility[headerkey]) {
            return "fa fa-check"
        }
        else {
            return ""
        }
    }

    handleSearchBtnClick = () => {
        this.setState({ isSearchBtnClicked: true });
    }


    get SearchBox() {
        if (this.state.searchColumn != "DateTime") {
            return (
                <div className="col-md-auto px-0 mx-3 dropdown">
                    <i class="search-icon material-icons">search</i>

                    <Translate>
                        {({ translate }) =>
                            <input type="text" className="table-search-input form-control" value={this.state.searchKeyword} placeholder={translate("Search")} onChange={this.onSearchKeywordChange}></input>
                        }
                    </Translate>
                </div>
            )
        }
    }

    raiseNoDataNotification = (event) => {
        event.preventDefault()
        this.props.createNotification(notificationTypes.error, notifications.errorMessages.noLogs)
    }


    render() {
        return (
            <div className="table-wrapper">
                <div id="tooltip"></div>
                <div className="table-btns ">
                    <div className="row">
                        <div className="col-md-9 justify-content-start">
                            <div className="row">

                            
                                <Dropdown title="ShowHideColumns" className="col-md-auto pr-md-0 mr-1">
                                    {Object.keys(this.headerMap).map((key, index) => {
                                        return (<DropdownItem key={key} className="dropdown-scheduler-log-header" data={JSON.stringify({ key: key, index: index })} index={index} icon={this.returnVisible(key)}><Translate id={key} /></DropdownItem>)
                                    })}
                                </Dropdown>

                                <Dropdown title={this.state.searchColumn} className="col-md-auto px-md-0 mr-1">
                                    {Object.keys(scopeList).map((key, index) => {
                                        return (<DropdownItem key={"searchHeader" + index} onClick={this.onSearchColumnChange} value={key}><Translate id={key} /></DropdownItem>)
                                    })}
                                </Dropdown>
                                {this.state.isSearchedByDateTime &&
                                <div className="col mt-2" >
                                    <div className="row">
                                        <div className="col col-md-auto">
                                            <label className="datepicker-label"><Translate id="StartDate" /></label>
                                            <DatePicker dateFormat="MM/dd/yyyy" selected={this.state.startDate} onChange={date => this.onDateChange(date, "startDate")} />
                                        </div>
                                        <div className="col col-md-auto">
                                            <label className="datepicker-label"><Translate id="EndDate" /></label>
                                            <DatePicker dateFormat="MM/dd/yyyy" selected={this.state.endDate} onChange={date => this.onDateChange(date, "endDate")} />
                                        </div>
                                    </div>
                                    {/* <label className="mr-1"><Translate id="EndDate" /></label>
                                    <DatePicker className="mr-1" dateFormat="MM/dd/yyyy" selected={this.state.endDate} onChange={date => this.onDateChange(date, "endDate")} /> */}
                                </div>
                                }
                                

                                {/* {this.state.isSearchedByDateTime && <div className="col-md-1"><div className="mr-1 dropdown">
                                    <button id={this.state.searchColumn == "DateTime" ? "search-btn" : ""} className="btn btn-secondary" type="button" onClick={this.handleSearchBtnClick}><Translate id="Search" /></button>
                                </div></div>} */}
                                {this.SearchBox}
                            </div>
                        </div>
                        <div className="col-md-3 d-flex justify-content-md-end dropdown">
                            <a className="table-button-wrapper" href="#" onClick={(event) => { event.preventDefault(); this.pollHandler() }}>
                                <button className="btn btn-secondary table-button mr-1">
                                <Translate id="Refresh" />
                                </button>
                            </a>
                            {/* <a className="btn btn-secondary" href={"/api/scheduler/table/" + this.props.username} onClick={() => { }}><Translate id="ExportAsCsv" /></a> */}

                            {(this.props.schedulerLog.schedulerLogs && this.props.schedulerLog.schedulerLogs.length > 0) ?
                                <a className="table-button-wrapper" href={"/api/scheduler/table/" + (localStorage.getItem("jwtoken") || sessionStorage.getItem("jwtoken"))} onClick={() => { }}><button className="btn btn-secondary table-button"><Translate id="ExportAsCsv" /></button></a>
                                : <a className="table-button-wrapper" href="#" onClick={this.raiseNoDataNotification}><button className="btn btn-secondary table-button"><Translate id="ExportAsCsv" /></button></a>
                            }
                        </div>
                    </div>
                    <table ref={(el) => { this.dataTableRef = el }} id="scheduler-log-table" className="table table-bordered" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                {Object.keys(this.headerMap).map((header, index) => {
                                    return (<th key={"header" + index}><Translate id={header} /></th>)
                                })}
                            </tr>
                        </thead>
                    </table>
                </div>
            </div >
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...schedulerLogActions, ...modalActions, ...notificationActions }, dispatch)
)(SchedulerLogTable);