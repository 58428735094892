import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import { actionCreators as usbProvisioningActions } from '../../../store/UsbProvisioning'
import { actionCreators as settingsActions } from '../../../store/Settings';
import { actionCreators as featuresToggleActions } from '../../../store/FeatureToggle';
import ModalFooter from '../ModalFooter'
import ModalSelect from '../ModalInputs/ModalSelect'
import ModalTitle from '../ModalInputs/ModalTitle'
import Settings from './Settings'
import RxCmsSettings from '../../UsbProvisioning/RxCmsSettings'
import RemoteManagement from '../../UsbProvisioning/RxCmsSettingsAndRemoteMgt'
import BrowseAeiConfigFile from '../../UsbProvisioning/BrowseAeiConfigFile'
import ScreenBeamProduct from './ScreenBeamProduct'
import { Translate } from 'react-localize-redux'
import API from "../../../REST/RestApi";

var AeiConfigMapping = require('../../UsbProvisioning/AeiConfigMapping')
var AeiConfig = AeiConfigMapping.AeiConfig;

class NewUsbProvisioning extends React.Component {
    constructor(props) {
        super(props);

        // Get ScreenBeamProduct List from feature toggle store
        this.props.getScreenBeamProductList((list) => {
            this.ScreenBeamProductList = list
        })

        let defaultModel = Object.keys(this.ScreenBeamProductList)[0];
        let defaultVersion = this.ScreenBeamProductList[defaultModel][0];
        this.isEthCertificate = false;
        this.EthCerts = {}
        this.isTlsCertificate = false;
        this.TlsCerts = {}
        this.isBackgroundCustom = false;
        this.Background = {}
        this.isScreensaverCustom = false;
        this.ScreenSaver = {}
        this.state = {
            currentStep: 1, // Default is Step 1
            selectedModel: defaultModel,
            selectedVersion: defaultVersion, // Default Version
            isRxSettingsSubmitted: false,
            isFileReady: false,
            fileStatus: "Getting file ready ..."
        };
    }

    get previousButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "previous" button
        if (currentStep == 2) {
            return (
                <button className="btn btn-secondary" type="button" onClick={this._prev}>
                    <Translate id="Previous" />
                </button>
            );
        }
        // ... else return nothing
        return null;
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 2, then render the "next" button
        if (currentStep < 3) {
            return (
                <button className="btn btn-primary" type="button" onClick={this._next}>
                    <Translate id="Next" />
                </button>
            )
        }
        // ... else return nothing
        return null;
    }

    get downloadButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "previous" button
        if (currentStep == 3) {
            return (
                <a className={!this.state.isFileReady ? "btn btn-primary-disabled a-tag-disabled" : "btn btn-primary"} type="button" href={"/api/settings/download/aeiconfig/" + (localStorage.getItem("username") || (sessionStorage.getItem("username")))} onClick={() => { this.props.hideModal() }}>
                    <Translate id="Download" />
                </a>
            );
        }
        // ... else return nothing
        return null;
    }

    get error() {
        let nameField = this.state.errorMessage;
        if (this.state.isError) {
            return (
                <div>
                    {nameField} can't be empty.
                </div>
            );
        }
    }

    get settings() {
        if (this.state.currentStep !== 2) {
            return null;
        }
        let markup = null;
        let selectedVersion = this.state.selectedVersion;
        let version = "";
        if (selectedVersion == "EnterpriseEdition950A")
            version = "9.6"
        else if (selectedVersion == "ScreenBeam960Firmware9dot15")
            version = "9.15"
        else if (selectedVersion == "ScreenBeam960Firmware9dot6")
            version = "9.6"
        else if (selectedVersion == "ScreenBeam1100Plus")
            version = "11.1"
        else
            version = "11.0"
        markup =
            <Settings version={version} type="UsbProvisioning" />;
        return markup;
    }

    get readyToDownloadNotification() {
        if (this.state.currentStep != 3) return null;
        var markup = <div><p>{this.state.fileStatus}</p></div>
        return markup;
    }

    // _next and _prev functions will be called on button clicked
    _next = () => {
        let currentStep = this.state.currentStep;
        let temp = currentStep;

        currentStep = currentStep >= 2 ? 3 : currentStep + 1;
        // if (currentStep == 2 && this.state.file != null) {
        //     this.retrieveAeiConfigFile(currentStep)
        // }
        if (currentStep == 3) {
            this.onRxSettingsSubmit()
        } else {
            this.setState({
                currentStep: currentStep
            });
        }
    }

    _prev = () => {
        let currentStep = this.state.currentStep;
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        if (currentStep == 1) this.props.resetState()
        this.setState({
            currentStep: currentStep
        });
    }

    onSettingOptionChange = (event) => {
        this.setState({ settingOption: event.target.value })
    }

    onVersionChange = (event) => {
        var version = event.target.value;
        this.setState({ selectedVersion: event.target.value });
    }

    onSBModelChange = (event) => {
        var model = event.target.value;
        let version = this.ScreenBeamProductList[model][0];
        this.setState({
            selectedModel: event.target.value,
            selectedVersion: version
        });
    }


    /* Special case - Receiver Settings: when user clicks Download, 
    Settings component will call onRxSettingsSubmit function to send data
    */
    onRxSettingsSubmit = () => {
        // Get data
        this.props.validateData((isDataValid, data) => {
            if (!isDataValid) {
                return
            } else {
                // console.log(data)
                var convertedData = this.convertDataToAeiConfig(data);
                this.getFileReadyOnServer(convertedData);
            }
        })
    }

    getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
    }
    // Convert data to aei_config data
    convertDataToAeiConfig = (configData) => {
        let aeiConfigMessage = "";
        const newLine = "\r\n";
        for (let key in configData) {
            if (key == "CurConnectApSecType" || key == "CurEthAuthentication") {
                if (configData["CurEthAuthentication"] == "3") this.isEthCertificate = true
                if (configData["CurConnectApSecType"] == "6") this.isTlsCertificate = true

            }
            if (key == "EthRootCertURL" || key == "EthUserCertURL" || key == "EthUserKeyURL" || key == "EthCertDateTime") {
                this.isEthCertificate = true;
                if (key == "EthCertDateTime") {
                    var date = new Date()
                    if (configData[key] != "") date = new Date(configData[key])
                    var convertedDate = this.convertDateTime(date)
                    this.EthCerts[key] = convertedDate
                } else {
                    this.EthCerts[key] = configData[key]
                }
            } else if (key == "TLSRootCertURL" || key == "TLSUserCertURL" || key == "TLSUserKeyURL" || key == "TLSCertDateTime") {
                this.isTlsCertificate = true;
                if (key == "TLSCertDateTime") {
                    var date = new Date()
                    if (configData[key] != "") date = new Date(configData[key])
                    var convertedDate = this.convertDateTime(date)
                    this.TlsCerts[key] = convertedDate
                } else {
                    this.TlsCerts[key] = configData[key]
                }
            } else if (key == "BackgroundCustom") {
                this.isBackgroundCustom = true
                this.Background = configData[key]
                let aeiConfigString = AeiConfig[key] + "=" + "1";
                // Add value to aeiConfigData
                aeiConfigMessage += aeiConfigString;
                aeiConfigMessage += newLine;
            } else if (key == "ScreensaverCustom") {
                this.isScreensaverCustom = true
                this.ScreenSaver = configData[key]
                let aeiConfigString = AeiConfig[key] + "=" + "1";
                // Add value to aeiConfigData
                aeiConfigMessage += aeiConfigString;
                aeiConfigMessage += newLine;
            } else if (key == "be_channelPlan" || key == "opChannelList") continue // Ignore these information in P2P Interface
            else {
                let aeiConfigString = AeiConfig[key] + "=" + configData[key];
                // Add value to aeiConfigData
                aeiConfigMessage += aeiConfigString;
                aeiConfigMessage += newLine;
            }
        }

        /* Generate random number
        * “aei_config_RandomNum” is a random code in eight digits. 
        * It must be re - generated after you have configured the receiver once, in this way to prevent repeated configuration. 
        */
        let randomNum = this.getRandomIntInclusive(10000000, 99999999);
        let aei_config_randomNum = "aei_config_RandomNum=" + randomNum; // This random number should be different for each time doing usbAutoconfig.

        // Form the data to send to Web Service
        let usbConfig = aei_config_randomNum + newLine + aeiConfigMessage;
        return usbConfig
    }

    convertDateTime = (dateTime) => {
        // Group date time into this format: 0614hhmm2019
        var result = ""
        var month = dateTime.getMonth() + 1;
        result += month < 10 ? "0" + month : month

        var date = dateTime.getDate();
        result += date < 10 ? "0" + date : date

        var hours = dateTime.getHours();
        result += hours < 10 ? "0" + hours : hours

        var mins = dateTime.getMinutes();
        result += mins < 10 ? "0" + mins : mins

        var year = dateTime.getFullYear();
        result += year

        return result
    }

    getFileReadyOnServer = (usbConfig) => {
        let that = this;
        let encryptedData = "";
        let zipFile = this.props.settings.zipFile
        API.getAeiConfigFileReadyOnServer(zipFile, usbConfig, this.isEthCertificate, this.EthCerts, this.isTlsCertificate, this.TlsCerts, this.isBackgroundCustom, this.Background, this.isScreensaverCustom, this.ScreenSaver, () => {
            this.setState({ currentStep: 3 }, this.checkFileStatus()); // Proceed to download step            
        }, () => {
            that.props.hideModal();
        })
    }

    checkFileStatus = () => {
        API.checkAeiConfigFileStatus((isReady) => {
            let ready = isReady
            if (!isReady) this.checkFileStatus()
            else this.setState({ isFileReady: ready, fileStatus: "The aei_config.zip file is ready to download" })
        })
    }

    render() {
        return (
            <div className="modal-body-settings" >
                <div className="modal-body">
                    <ScreenBeamProduct
                        hideTitle={true}
                        currentStep={this.state.currentStep}
                        ModelFirmwareDict={this.ScreenBeamProductList}
                        selectedModel={this.state.selectedModel}
                        selectedVersion={this.state.selectedVersion}
                        onSBModelChange={this.onSBModelChange}
                        onVersionChange={this.onVersionChange}
                        verticalDisplay={true}
                        data={this.props.data} />
                    {this.settings}
                    {this.readyToDownloadNotification}
                </div>
                <div className="modal-footer">
                    {this.previousButton}
                    {this.downloadButton}
                    {this.nextButton}
                </div>
            </div>

        );
    }
}


export default connect(
    state => state,
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions, ...usbProvisioningActions, ...settingsActions, ...featuresToggleActions }, dispatch)
)(NewUsbProvisioning);
