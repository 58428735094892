import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import { actionCreators as UserActions } from '../../../store/User';
import ModalFooter from '../ModalFooter'
import ModalRadio from '../ModalInputs/ModalRadio'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalPasswordInput from '../ModalInputs/ModalPasswordInput'
import ModalSelect from '../ModalInputs/ModalSelect'
import ModalTitle from '../ModalInputs/ModalTitle'
import ModalCheckbox from '../ModalInputs/ModalCheckbox'
import ModalSubtitle from '../ModalInputs/ModalSubtitle'
import ModalPinInput from '../ModalInputs/ModalPinInput'
import ModalRatioInput from '../ModalInputs/ModalRatioInput'
import ModalPostfixInput from '../ModalInputs/ModalPostfixInput'
import ModalOperatingChannel from '../ModalInputs/ModalOperatingChannel'

// import Settings1100 from './Settings1100'
import ModalIp from '../ModalInputs/ModalIp'
import AllSettings from './AllSettings.js'
import { Translate } from 'react-localize-redux'
import { actionCreators as notificationActions, notifications, notificationTypes } from '../../../store/Notifications'
import englishTranslation from "../../../localization/en.translations.json";
import SettingHelpers from '../../Settings/SettingHelpers'


class SingleRxSettings extends React.Component {
    constructor(props) {
        super(props);
        this.changeMap = {};
        let data = this.props.arg1;
        // console.log(data)
        // Get the Settings based on Firmware version
        let firmware = data ? data.fwVersion : "11.0";
        this.Settings = SettingHelpers.getSettingsBasedOnFirmware(firmware)

        // Parse data to state
        let allSettings = this.parseData(this.Settings.AllSettings);
        // Get the list of tab headers based on received data
        let allHeaders = this.parseHeaders(allSettings, data);
        this.state = {
            settings: allSettings,
            tab: "DeviceConfiguration",
            tabHeaders: allHeaders
        };

        this.onChange = this.onChange.bind(this);
    }

    modalBody = React.createRef()

    parseHeaders = (allSettings, data) => {
        var headers = []
        // Get tab content
        var defaultHeaders = this.Settings.SingleRxSettings.TabContent;
        // Iterate through each tab header and check if received data contains any item in that tab.
        // If it does, display the tab.
        // Otherwise, don't display.
        for (var header in defaultHeaders) {
            var contentItems = defaultHeaders[header]
            for (var i = 0; i < contentItems.length; i++) {
                if (data[contentItems[i]]) {
                    headers.push(header)
                    break;
                }
            }
        }
        return headers
    }

    parseData = (settings) => {
        var data = this.props.arg1;
        for (let key in settings) {
            if (data[key]) {
                settings[key].value = data[key];
            }
            // Call onStart method on Object. Used when one feature depends on other feature.
            if (settings[key].onStart) {
                let temp = settings[key].onStart(settings, data);
                settings[key] = temp;
            }
        }
        return settings;
    }

    onChange = (event, key) => {
        var setting = this.state.settings[key];
        var value = event.target.value;
        //Prevalidate: prevent input if the new string does not pass the test
        if (setting.preValidate && !setting.preValidate(value, this)) {
            return;
        }
        SettingHelpers.setSettingState(this,
            key,
            "value",
            value,
            () => {
                //Log change on changeMap
                //this.changeMap[key] = value;
                this.changeMap[key] = value;
                //Call onChange method on object. Used mainly for unique cases
                if (setting.onChange) {
                    setting.onChange(this, value);
                }
                //Validate: If entry is not valid update state with error message
                if (setting.validate) {
                    var error_message = setting.validate(value, this);
                    if (error_message) {
                        SettingHelpers.setSettingState(this, key, "error", error_message);
                    } else {
                        SettingHelpers.setSettingState(this, key, "error", "");
                    }
                }
            });
    }

    changeTab = (tabName) => {
        this.setState({
            tab: tabName
        })
        // this.modalBody.current.scrollTo(0, 0)
    }

    onSubmit = () => {
        // Call onSubmit to check if any feature needs extra feature info to send to CMS Service
        var haveValueChanged = false;
        for (let key in this.changeMap) {
            switch (key) {
                case "ApBsSSName":
                    {
                        key = "ApBsS1Name";
                    }
                    break;
                case "ApBsSSPasswd":
                    {
                        key = "ApBsS1Passwd";
                    }
                    break;
                case "ApBsSSEnable":
                    {
                        key = "ApBsS1Enable";
                    }
                    break;
                case "ApBsSSSecurityType":
                    {
                        key = "ApBsS1SecurityType";
                    }
                    break;
                case "autonomousGoEn":
                    {
                        key = "be_autonomousGoEn";
                    }
                    break;
                default:
                    break;
            }
            if (!this.state.settings[key]) {
                continue;
            }
            if (this.state.settings[key].value !== this.props.arg1[key]) {
                haveValueChanged = true;
            }

            let setting = this.state.settings[key];
            if (setting && setting.onSubmit) {
                setting.onSubmit(this);
            }
            if (this.state.settings[key].error && this.state.settings[key].error !== "") {
                this.props.createNotification(notificationTypes.error, englishTranslation[this.state.settings[key].label] + ": " + englishTranslation[this.state.settings[key].error]);
                return false;
            }
        }

        if (this.state.settings["miracastConnEnable"] && (this.changeMap["miracastConnEnable"] || this.changeMap["infracastEnable"])) {
            var warningInfo = "";
            if (this.state.settings["miracastConnEnable"].value === "1") { //Enable
                if (this.state.settings["infracastEnable"].value === "1") {
                    warningInfo = "MiracastEnaInfracastEnaWarning";
                } else {
                    warningInfo = "MiracastEnaInfracastDisWarning";
                }
            } else {
                if (this.state.settings["infracastEnable"].value === "1") {
                    warningInfo = "MiracastDisInfracastEnaWarning";
                } else {
                    warningInfo = "MiracastDisInfracastDisWarning";
                }
            }
            SettingHelpers.popupConfirmAlertDialog(warningInfo, null, () => { this.props.updateRxSettings(this.changeMap); this.props.hideModal(); });
            return false;
        }

        if (!haveValueChanged) {
            this.props.createNotification(notificationTypes.error, "No settings have been changed.");
            return false;
        }
        // console.log(this.changeMap);

        this.props.updateRxSettings(this.changeMap);
        this.props.hideModal();

    }

    // componentDidMount = () => {
    //     Object.keys(this.props.arg1).forEach((key) => {
    //         if (key in this.state.settings) {
    //             this.state.settings[key]["value"] = this.props.arg1[key]
    //         }
    //     })
    //     this.setState({})
    //     //Bad practice, should not modify state directly but using it temporarily
    // }

    TabContent() {
        //Load content based on current tab
        var tabContent = this.Settings.SingleRxSettings.TabContent[this.state.tab];
        return (
            <div>
                {tabContent.map((key, index) => {
                    // Check if the data includes the parameter, then display it
                    var needToDisplay = false;
                    if (this.state.settings[key].type === "title"
                        || this.state.settings[key].type === "subtitle"
                        || key === "hdmiPortPowerManagement") {
                        needToDisplay = true;
                    }
                    if (key in this.props.arg1 || needToDisplay) {
                        //Map through each object based on tabContent
                        var setting = this.state.settings[key]
                        if (!setting) { return null }
                        var display = setting.display

                        //If there are dependencies loop through them
                        if (setting.dependencies) {
                            var dependencyKey = "" //Build a string based on the values of the dependencies\
                            //!!!!!!!!!!!This algorithm may cause issues if feature values become longer than 1 digit!!!!!!!!!!!!!!!!!
                            setting.dependencies.forEach((dependency) => {
                                dependencyKey += this.state.settings[dependency].value
                            })
                            display = setting.dependencyMap[dependencyKey] || display
                        }
                        if (display == "1") { return null } //1: Don't show

                        //Switch case for all setting component types
                        if (setting.type == "radioButton") {
                            return (
                                <ModalRadio
                                    name={key}
                                    index={index}
                                    label={setting.label}
                                    info={setting.info}
                                    disabled={display == "2" ? true : false}
                                    options={setting.options}
                                    checked={setting.value}
                                    onChange={(event) => { this.onChange(event, key) }}
                                />
                            )
                        }
                        else if (setting.type == "input") {
                            return (
                                <ModalTextInput
                                    key={"input" + index}
                                    index={index}
                                    label={setting.label}
                                    value={setting.value}
                                    info={setting.info}
                                    disabled={display == "2" ? true : false}
                                    onChange={(event) => { this.onChange(event, key) }}
                                    error={setting.error}
                                />
                            )
                        }
                        else if (setting.type == "password") {
                            return (
                                <ModalPasswordInput
                                    key={"password" + index}
                                    index={index}
                                    label={setting.label}
                                    value={setting.value}
                                    info={setting.info}
                                    disabled={display == "2" ? true : false}
                                    onChange={(event) => { this.onChange(event, key) }}
                                    error={setting.error}
                                />
                            )
                        }
                        else if (setting.type == "dropdown") {
                            return (
                                <ModalSelect
                                    key={"select" + index}
                                    index={index}
                                    label={setting.label}
                                    value={setting.value}
                                    info={setting.info}
                                    disabled={display == "2" ? true : false}
                                    options={setting.options}
                                    onChange={(event) => { this.onChange(event, key) }}
                                />
                            )
                        }
                        else if (setting.type === "checkbox") {
                            return (
                                <ModalCheckbox
                                    index={index}
                                    label={setting.label}
                                    id={key}
                                    value={setting.value}
                                    onChange={(event) => { this.onChange(event, key) }}
                                    disabled={display === "2" ? true : false} />
                            );
                        }
                        else if (setting.type == "ip") {
                            return (
                                <ModalIp
                                    key={"Ip" + index}
                                    index={index}
                                    label={setting.label}
                                    value={setting.value}
                                    info={setting.info}
                                    disabled={display == "2" ? true : false}
                                    onChange={(event) => { this.onChange(event, key) }}
                                    error={setting.error}
                                />
                            )
                        }
                        else if (setting.type == "subtitle") {
                            return (
                                <ModalSubtitle
                                    key={"Subtitle" + index}
                                    title={setting.title}
                                />
                            )
                        }
                        else if (setting.type == "title") {
                            return (
                                <ModalTitle
                                    key={"Title" + index}
                                    title={setting.title}
                                />
                            )
                        }
                        else if (setting.type === "pin") {
                            return (
                                <ModalPinInput
                                    index={index}
                                    label={setting.label}
                                    id={key}
                                    value={setting.value}
                                    maxLength={setting.maxLength}
                                    onChange={(event) => { this.onChange(event, key) }}
                                    disabled={display === "2" ? true : false}
                                    error={setting.error}
                                    info={setting.info} />
                            );
                        }
                        else if (setting.type === "ratio") {
                            return (
                                <ModalRatioInput
                                    index={index}
                                    label={setting.label}
                                    value={setting.value}
                                    horizontalName={key}
                                    verticalName={setting.verticalName}
                                    verticalValue={this.state.settings[setting.verticalName].value}
                                    info={setting.info}
                                    disabled={display === "2" ? true : false}
                                    onChange={(event, specialKey) => { this.onChange(event, specialKey) }}
                                    error={setting.error} />
                            );
                        }
                        else if (setting.type === "postfixInput") {
                            return (
                                <ModalPostfixInput
                                    index={index}
                                    label={setting.label}
                                    value={setting.value}
                                    info={setting.info}
                                    prefix={this.state.settings[setting.prefix] ? this.state.settings[setting.prefix].value : ""}
                                    disabled={display === "2" ? true : false}
                                    onChange={(event) => { this.onChange(event, key) }}
                                    error={setting.error} />
                            );
                        } else if (setting.type === "channel") {
                            return (
                                <ModalOperatingChannel
                                    value={setting.value}
                                    opChannelList={this.state.settings[setting.opChannelList].value}
                                    onChange={(event) => { this.onChange(event, key) }}
                                />
                            )
                        }
                    }
                })}
            </div>
        )
    }

    render() {
        return (
            <div>
                <ul className="nav nav-tabs">
                    {(this.state.tabHeaders).map((key, index) => {
                        if (key == "AdvancedSettings") {
                            return (this.props.user.isSystemAdmin === "true" && <li key={"tab" + key} className="nav-item ">
                                <div className={key == this.state.tab ? "active nav-link" : "nav-link"} onClick={() => { this.changeTab(key) }}><Translate id={key} /></div>
                            </li>)
                        } else {
                            return (
                                <li key={"tab" + key} className="nav-item ">
                                    <div className={key == this.state.tab ? "active nav-link" : "nav-link"} onClick={() => { this.changeTab(key) }}><Translate id={key} /></div>
                                </li>
                            )
                        }
                    })}
                </ul>
                <div className='modal-body' ref={this.modalBody}>
                    {this.TabContent()}
                </div>
                <ModalFooter button2Submit={() => { this.onSubmit() }} />
            </div>
        );
    }
}


export default connect(
    state => (state),
    dispatch => bindActionCreators({ ...UserActions, ...modalActions, ...signalRActions, ...notificationActions }, dispatch)
)(SingleRxSettings);
