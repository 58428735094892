import SettingHelpers from './SettingHelpers'

class Settings_In_11_1 {
    static getSingleRxSettings = () => {
        return {
            "TabHeader": [
                "DeviceConfiguration",
                "Features",
                "DigitalSignage",
                "NetworkSettings",
                "LocalWifi",
                "ManagementAccess",
                "AdvancedSettings"
            ],
            "TabHeaderCCMode": [
                "DeviceConfiguration",
                "Features",
                "NetworkSettings",
                "ManagementAccess",
                "AdvancedSettings"
            ],
            "TabContent": {
                "DeviceConfiguration": [
                    "RxName", // Device Name
                    "manufacName", // Manufacture Name
                    "mdNameShort", // Model Name Short
                    "mdNameLong", // Model Name
                    "hwVersion", // Hardware Name
                    "fwVersion", // Firmware Version
                    "webUserName", // Administrator Name
                    "webPassword", // Administrator Password
                    "language", // Language
                    "RXHostname", // Host Name
                    "timezoneItem", // Time zone
                    "NTPServerConfigUrl" // NTP Time Server
                ],
                "Features": [
                    "WirelessDisplayModeTitle", // Wireless Display Mode Title
                    "WiFiOperateMode", // Wireless Display Mode
                    "ccChromeEnable", // Commander Mode
                    "WirelessDisplayOverLANTitle", // Wireless Display Over LAN
                    "infracastEnable", // Windows 10
                    "MacInfracastEn", // Windows 7 or Mac
                    "airPlayEn", // macOS or iOS
                    "AirplayMirroringOnlyEn", //iOS push mode
                    "GoogleCastEnable", //ChromeOS/Chrome native screen mirroring
                    "P2PWirelessSettingTitle", // P2P Wireless Setting Title
                    "miracastConnEnable", //Miracast Connection
                    "autoGoChannel", // Need to do P2P Operating Channel -- Need to create a new component
                    "txPowerItem", // Transmit Power
                    "SecuritySettingTitle", // Security Setting Title
                    "pinEnforceEnable", // Force PIN Pairing on First Connection
                    "pinEachConnEnable", // Require PIN on
                    "fourDigitPINEn", // PIN Length
                    "staticPinEnable", // PIN Generation Method
                    "staticPinVal", // Static PIN Val
                    "PINDisplayPeriod", // PIN Display Period
                    "DisplaySettingTitle", // Display Setting Title
                    "hijackingEn", //Display Sharing Mode
                    "showOverlayRxNameOpt", //Show Receiver Name
                    "overlayRxNamePlacement", //Receiver Name Placement
                    "showMoreInfoEnable", // ScreenBeam Network Info
                    "hideReadyInfoEnable", // Connection Instruction
                    "showConnectWiFiEnable", //Show "Connect to WiFi" instructions
                    "showHelpUrlEnable", // Show "Help URL" instructions
                    "hdmiPortPowerManagement", // HDMI/VGA component
                    "scrSaverSecond", // Wait - Triggered when HDMI/VGA selects Screensaver or Display Off
                    "wakedByScan", // Wake up
                    "newOverScanVal", // Adjust TV Screen Size
                    "rtOverscanEnable", // Allow source device to override overscan value
                    "CECEnable", // HDMI-CEC
                    "UIBCEnable", // UIBC
                    "usbConfigEn", // USB Auto Configure
                    "fwupdateAccess", // Firmware Upgrade via USB Autorun
                    "InactivityRebootTime",
                    "RxCsvLogEn", // Receiver Logging
                    "PushButtonEn", // Push Button
                    "BluetoothSettingTitle", // Bluetooth Setting Title
                    "iBeaconEnable" // Airplay discovery broadcast
                ],
                "DigitalSignage": [
                    "DigitalSignageModeTitle",
                    "DigitalSignageModeSubTitle",
                    "digitalSignEn", //Digital Signage
                    "digitalSignFullScreenEn", //Display Mode
                    "digitalSignUrl", //Source URL
                    "digitalSignDelaySec", //Restart Delay(seconds)
                    "digitalSignAudioVol" //Digital Signage Audio Volume
                ],
                "NetworkSettings": [
                    "LocalWifiNetworkTitle",
                    "NetworkMode", // Network Mode
                    "BlockLanIpEnable", //Block communication to devices on the LAN
                    "InterfaceFeatureTitle",
                    "cmsInterface",
                    "apBridgeInterface",
                    "NetworkInterfaceTitle",
                    "EthernetInterfaceSubtitle",
                    "EthNetworkName", // Network Name
                    "ConnectionSettingsSubtitle",
                    "CurEthAuthentication",
                    "CurEthRadiusID",
                    "CurEthRadiusPWD",
                    "EthCertDateTime",
                    "EthRootCertURL",
                    "EthUserCertURL",
                    "EthUserKeyURL",
                    "EthCertsValidity",
                    "TCPIPSettingsSubtitle",
                    "ethAutoIpEnable",
                    "ethIpAddress",
                    "ethNetmask",
                    "ethGateway",
                    "ethAutoDnsEnable",
                    "ethPrimaryDns",
                    "ethSecondaryDns",
                    "WirelessInterfaceSubtitle",
                    "ConnectionSettingsSubtitle",
                    "CurConnectApName",
                    "CurConnectApSecType",
                    "CurConnectApRadiusId",
                    "CurConnectApPwd",
                    "TLSCertDateTime",
                    "TLSRootCertURL",
                    "TLSUserCertURL",
                    "TLSUserKeyURL",
                    "TLSCertsValidity",
                    "staAutoIpEnable",
                    "staIpAddress",
                    "staNetmask",
                    "staGateway",
                    "staAutoDnsEnable",
                    "staPrimaryDns",
                    "staSecondaryDns"
                ],
                "LocalWifi": [
                    "LocalWifiSettingsTitle",
                    "ApBsS1Enable",
                    "ApBsS1Name",
                    "ApBsS1SecurityType",
                    "ApBsS1Passwd",
                    "ApBsChannelNum",
                    "showWiFiQREnable"
                ],
                "ManagementAccess": [
                    "CentralMgtSystemTitle",
                    "mgtSrvFullAddr",
                    "mgtSrvPort",
                    "webServerPort",
                    "LocalMgtWirelessAccessTitle",
                    "webMgtOverWiFiEn",
                    "networkInterfaceAllowed"
                ],
                "AdvancedSettings": [
                    "DebugSettingTitle",
                    "debugLogOutputEnable",
                    "DebuggingTitle",
                    "sshServerEnable", // SSH Debug Console 
                    "SSHComPort", // SSH Communication Port
                    "RxMacAddr", // ScreenBeam MAC Address
                    "SecuritySettingTitle", //Security Setting
                    "ApBsDisableEapolKeyRetries",
                    "AdvancedFeatureTitle",//Advanced Feature
                    "dynAgoPassphraseEn",
                    "ghostInkEnable", // Ghost Inking
                    "ghostInkOpacity", // Ghost Inking opacity level
                    "DisplaySettingTitle",
                    "PCRSyncEnable",
                    "HDCPEncrypMode",
                    "aspectRatio",
                    "scrHoffset",
                    "debugViewEnable",
                    "showSBLogoEnable", // Screenbeam logo
                    "P2PWirelessSettingTitle",
                    "p2pIpAddr",
                    "p2pSubnet",
                    "p2pDhcpIpStart",
                    "p2pDhcpIpEnd",
                    "p2pSsid",
                    "p2pPassword",
                    "fastIPEn",
                    "txAgoBcnEnable"
                ]
            }
        }
    }
    static getMultipleRxSettings = () => {
        return {
            "DeviceConfigurationGroupTitle": {
                "type": "title",
                "title": "DeviceConfiguration"
            },
            "LocalWebManagement": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Web Management",
                "children": [
                    "webUserName", // Administrator Name
                    "webPassword"  // Administrator Password
                ]
            },
            "Language": {
                "type": "checkbox",
                "selected": "0",
                "label": "Language",
                "children": [
                    "language" // Language
                ]
            },
            "Timezone": {
                "type": "checkbox",
                "selected": "0",
                "label": "Time zone",
                "children": [
                    "timezoneItem" // Time zone
                ]
            },
            "NTPTimeServer": {
                "type": "checkbox",
                "selected": "0",
                "label": "NTP Time Server",
                "children": [
                    "NTPServerConfigUrl" // NTP Time Server
                ]

            },
            "FeaturesGroupTitle": {
                "type": "title",
                "title": "Features"
            },
            "WirelessDisplayMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wireless display mode to support client devices",
                "children": [
                    "WiFiOperateMode"
                ]
            },
            "CommanderMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Commander Mode",
                "children": [
                    "ccChromeEnable"
                ]
            },
            "Infracast": {
                "type": "checkbox",
                "selected": "0",
                "label": "Miracast Over Network",
                "children": [
                    "infracastEnable"
                ]
            },
            // Remove this feature in 11.0.8.x
            // "MacOSandWin7": {
            //     "type": "checkbox",
            //     "selected": "0",
            //     "label": "Project over infrastructure for macOS/Win7",
            //     "children": [
            //         "MacInfracastEn"
            //     ]
            // },
            "MacAirplay": {
                "type": "checkbox",
                "selected": "0",
                "label": "Mac Airplay",
                "children": [
                    "airPlayEn"
                ]
            },
            "iOSPushMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "iOS push mode",
                "children": [
                    "AirplayMirroringOnlyEn"
                ]
            },
            "GoogleCastMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Google Cast",
                "children": [
                    "GoogleCastEnable"
                ]
            },
            "MiracastConnection": {
                "type": "checkbox",
                "selected": "0",
                "label": "Miracast Connection",
                "children": [
                    "miracastConnEnable"
                ]
            },
            "OperatingChannel": {
                "type": "checkbox",
                "selected": "0",
                "label": "Operating Channel",
                "children": [
                    "autoGoChannel"
                ]
            },
            "TransmitPower": {
                "type": "checkbox",
                "selected": "0",
                "label": "Transmit Power",
                "children": [
                    "txPowerItem"
                ]
            },
            "PIN": {
                "type": "checkbox",
                "selected": "0",
                "label": "PIN",
                "children": [
                    "pinEnforceEnable",
                    "pinEachConnEnable",
                    "fourDigitPINEn",
                    "staticPinEnable",
                    "staticPinVal"
                ]
            },
            "PinDisplayPeriod": {
                "type": "checkbox",
                "selected": "0",
                "label": "Pin Display Period",
                "children": [
                    "PINDisplayPeriod"
                ]
            },
            "NetworkInfo": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Information",
                "children": [
                    "showMoreInfoEnable"
                ]
            },
            "ConnectInstruction": {
                "type": "checkbox",
                "selected": "0",
                "label": "Connect Instruction",
                "children": [
                    "hideReadyInfoEnable"
                ]
            },
            //Show "Connect to WiFi" instructions
            "ConnectToWiFiInstruction": {
                "type": "checkbox",
                "selected": "0",
                "label": "Show \"Connect to WiFi\" instructions",
                "children": [
                    "showConnectWiFiEnable"
                ]
            },
            "ShowHelpUrlInstructions": {
                "type": "checkbox",
                "selected": "0",
                "label": "Show \"Help URL\" instructions",
                "children": [
                    "showHelpUrlEnable"
                ]
            },
            "DisplaySharingMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Display Sharing Mode",
                "children": [
                    "hijackingEn",
                    "showOverlayRxNameOpt",
                    "overlayRxNamePlacement"
                ]
            },
            "HdmiPortPowerManagement": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI/VGA Port Power Management",
                "children": [
                    "hdmiPortPowerManagement",
                    "scrSaverSecond",
                    "scrHDMIOutEnable",
                    "scrSaverEnable"
                ],
                "dataToSend": [
                    "scrSaverSecond",
                    "scrHDMIOutEnable",
                    "scrSaverEnable"
                ]
            },
            "Wakeup": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wake Up",
                "children": [
                    "wakedByScan"
                ]
            },
            "TVScreenSize": {
                "type": "checkbox",
                "selected": "0",
                "label": "TV Screen Size",
                "children": [
                    "newOverScanVal",
                    "rtOverscanEnable"
                ]
            },
            "HDMICEC": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI-CEC",
                "children": [
                    "CECEnable"
                ]
            },
            // "UIBC": {
            //     "type": "checkbox",
            //     "selected": "0",
            //     "label": "UIBC",
            //     "children": [
            //         "UIBCEnable"
            //     ]
            // },
            // "USBAutoConfig": {
            //     "type": "checkbox",
            //     "selected": "0",
            //     "label": "USB Auto Configure",
            //     "children": [
            //         "usbConfigEn"
            //     ]
            // },
            "FirmwareUpgrade": {
                "type": "checkbox",
                "selected": "0",
                "label": "Firmware Upgrade via USB Autorun",
                "children": [
                    "fwupdateAccess"
                ]
            },
            "InactivityRebootTime": {
                "type": "checkbox",
                "selected": "0",
                "label": "Inactivity Reboot Time",
                "children": [
                    "InactivityRebootTime"
                ]
            },
            "ReceiverLogging": {
                "type": "checkbox",
                "selected": "0",
                "label": "Receiver Logging",
                "children": [
                    "RxCsvLogEn"
                ]
            },
            "PushButton": {
                "type": "checkbox",
                "selected": "0",
                "label": "Push Button",
                "children": [
                    "PushButtonEn"
                ]
            },
            "AirplayBroadcast": {
                "type": "checkbox",
                "selected": "0",
                "label": "AirPlay Discovery Broadcast",
                "children": [
                    "iBeaconEnable"
                ]
            },
            "DigitalSignage": {
                "type": "title",
                "title": "DigitalSignage"
            },
            "DigitalSignageMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Digital Signage Mode",
                "children": [
                    "DigitalSignageModeSubTitle",
                    "digitalSignEn", //Digital Signage
                    "digitalSignFullScreenEn", //Display Mode
                    "digitalSignUrl", //Source URL
                    "digitalSignDelaySec", //Restart Delay(seconds)
                    "digitalSignAudioVol" //Digital Signage Audio Volume
                ]
            },
            "NetworkSettingsGroupTitle": {
                "type": "title",
                "title": "NetworkSettings"
            },
            "LocalWifi": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Wifi Network",
                "children": [
                    "NetworkMode"
                ]
            },
            "BlockcommunicationontheLAN": {
                "type": "checkbox",
                "selected": "0",
                "label": "Block communication on the LAN",
                "children": [
                    "BlockLanIpEnable"
                ]
            },
            "InterfaceFeature": {
                "type": "checkbox",
                "selected": "0",
                "label": "Interface Feature Assignment",
                "children": [
                    "cmsInterface",
                    "apBridgeInterface"
                ]
            },
            "NetworkName": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Name",
                "children": [
                    "EthNetworkName"
                ]
            },
            "WiredConnection": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wired Connection Settings",
                "children": [
                    "CurEthAuthentication",
                    "CurEthInnerAuth",
                    "CurEthRadiusID",
                    "CurEthRadiusPWD",
                    "EthCertDateTime",
                    "EthRootCertURL",
                    "EthUserCertURL",
                    "EthUserKeyURL"
                ]
            },
            "WirelessConnection": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wireless Connection Settings",
                "children": [
                    "CurConnectApName",
                    "CurConnectApSecType",
                    "CurConnectApRadiusId",
                    "CurConnectApPwd",
                    "TLSCertDateTime",
                    "TLSRootCertURL",
                    "TLSUserCertURL",
                    "TLSUserKeyURL"
                ]
            },
            "LocalWifiGroupTitle": {
                "type": "title",
                "title": "LocalWifi"
            },
            "SbLocalWifi": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Wifi Settings",
                "children": [
                    "ApBsSSEnable",
                    "ApBsSSIDIndex",
                    "showWiFiQREnable"
                ]
            },
            "ManagementAccessGroupTitle": {
                "type": "title",
                "title": "ManagementAccess"
            },
            "WebPort": {
                "type": "checkbox",
                "selected": "0",
                "label": "WEB Communication Port",
                "children": [
                    "webServerPort"

                ]
            },
            "LocalMgtAccess": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Management Access Settings",
                "children": [
                    "webMgtOverWiFiEn"
                ]
            },
            "NetworkInterfaceAllowed": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Interface Allowed",
                "children": [
                    "networkInterfaceAllowed"
                ]
            },
            // Advanced Settings
            "AdvancedSettingsGroupTitle": {
                "type": "title",
                "title": "AdvancedSettings"
            },
            "DebugLogOutput": {
                "type": "checkbox",
                "selected": "0",
                "label": "Debug Log output",
                "children": [
                    "debugLogOutputEnable"
                ]
            },
            "DebuggingSetting": {
                "type": "checkbox",
                "selected": "0",
                "label": "Debugging",
                "children": [
                    "sshServerEnable",
                    "SSHComPort"
                ]
            },
            "DebugView": {
                "type": "checkbox",
                "selected": "0",
                "label": "Debug View",
                "children": [
                    "debugViewEnable"
                ]
            },
            "ScreenBeamLogo": {
                "type": "checkbox",
                "selected": "0",
                "label": "ScreenBeam logo",
                "children": [
                    "showSBLogoEnable"
                ]
            },
            "DisableEapolKeyRetries": {
                "type": "checkbox",
                "selected": "0",
                "label": " Disable EAPOL Key Retries (for WPA2-PSK)",
                "children": [
                    "ApBsDisableEapolKeyRetries"
                ]
            },
            "GhostInking": {
                "type": "checkbox",
                "selected": "0",
                "label": "Ghost Inking",
                "children": [
                    "ghostInkEnable",
                    "ghostInkOpacity"
                ]
            },
            "PCRSync": {
                "type": "checkbox",
                "selected": "0",
                "label": "PCR Synchronization",
                "children": [
                    "PCRSyncEnable"
                ]
            },
            "HdcpEncryption": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDCP Encryption",
                "children": [
                    "HDCPEncrypMode"
                ]
            },
            "AspectRatio": {
                "type": "checkbox",
                "selected": "0",
                "label": "Aspect Ratio",
                "children": [
                    "aspectRatio"
                ]
            },
            "HorizontalVerticalOffset": {
                "type": "checkbox",
                "selected": "0",
                "label": "Horizontal/Vertical Offset",
                "children": [
                    "scrHoffset",
                    "scrVoffset"]
            },
            "P2PWirelessNetworkSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "P2P Wireless Network Settings (AGO enabled only)",
                "children": [
                    "p2pIpAddr",
                    "p2pSubnet",
                    "p2pDhcpIpStart",
                    "p2pDhcpIpEnd"
                ]
            },
            "FastIP": {
                "type": "checkbox",
                "selected": "0",
                "label": "Fast-IP",
                "children": [
                    "fastIPEn"
                ]
            },
            "TxAgoBcnEnable": {
                "type": "checkbox",
                "selected": "0",
                "label": "AGO beacon broadcast",
                "children": [
                    "txAgoBcnEnable"
                ]
            }
        }
    }
    static getUSBProvisioningSettings = () => {
        return {
            "DeviceConfigurationGroupTitle": {
                "type": "title",
                "title": "DeviceConfiguration"
            },
            "DeviceName": {
                "type": "checkbox",
                "selected": "0",
                "label": "Device Name",
                "children": [
                    "RxName" // Device Name
                ]
            },
            "LocalWebManagement": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Web Management",
                "children": [
                    "webUserName", // Administrator Name
                    "webPassword"  // Administrator Password
                ]
            },
            "Language": {
                "type": "checkbox",
                "selected": "0",
                "label": "Language",
                "children": [
                    "language" // Language
                ]
            },
            "HostName": {
                "type": "checkbox",
                "selected": "0",
                "label": "Host Name",
                "children": [
                    "RXHostname" // Host Name
                ]
            },
            "Timezone": {
                "type": "checkbox",
                "selected": "0",
                "label": "Time zone",
                "children": [
                    "timezoneItem" // Time zone
                ]
            },
            "NTPTimeServer": {
                "type": "checkbox",
                "selected": "0",
                "label": "NTP Time Server",
                "children": [
                    "NTPServerConfigUrl" // NTP Time Server
                ]

            },
            "FeaturesGroupTitle": {
                "type": "title",
                "title": "Features"
            },
            "WirelessDisplayMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wireless display mode to support client devices",
                "children": [
                    "WiFiOperateMode"
                ]
            },
            "CommanderMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Commander Mode",
                "children": [
                    "ccChromeEnable"
                ]
            },
            "Infracast": {
                "type": "checkbox",
                "selected": "0",
                "label": "Miracast Over Network",
                "children": [
                    "infracastEnable"
                ]
            },
            // "MacOSandWin7": {
            //     "type": "checkbox",
            //     "selected": "0",
            //     "label": "Project over infrastructure for macOS/Win7",
            //     "children": [
            //         "MacInfracastEn"
            //     ]
            // },
            "MacAirplay": {
                "type": "checkbox",
                "selected": "0",
                "label": "Mac Airplay",
                "children": [
                    "airPlayEn"
                ]
            },
            "iOSPushMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "iOS push mode",
                "children": [
                    "AirplayMirroringOnlyEn"
                ]
            },
            "GoogleCastMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Google Cast",
                "children": [
                    "GoogleCastEnable"
                ]
            },
            "MiracastConnection": {
                "type": "checkbox",
                "selected": "0",
                "label": "Miracast Connection",
                "children": [
                    "miracastConnEnable"
                ]
            },
            "OperatingChannel": {
                "type": "checkbox",
                "selected": "0",
                "label": "Operating Channel",
                "children": [
                    "autoGoChannel"
                ]
            },
            "TransmitPower": {
                "type": "checkbox",
                "selected": "0",
                "label": "Transmit Power",
                "children": [
                    "txPowerItem"
                ]
            },
            "PIN": {
                "type": "checkbox",
                "selected": "0",
                "label": "PIN",
                "children": [
                    "pinEnforceEnable",
                    "pinEachConnEnable",
                    "fourDigitPINEn",
                    "staticPinEnable",
                    "staticPinVal"
                ]
            },
            "PinDisplayPeriod": {
                "type": "checkbox",
                "selected": "0",
                "label": "Pin Display Period",
                "children": [
                    "PINDisplayPeriod"
                ]
            },
            "NetworkInfo": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Information",
                "children": [
                    "showMoreInfoEnable"
                ]
            },
            "ConnectInstruction": {
                "type": "checkbox",
                "selected": "0",
                "label": "Connect Instruction",
                "children": [
                    "hideReadyInfoEnable"
                ]
            },
            //Show "Connect to WiFi" instructions
            "ConnectToWiFiInstruction": {
                "type": "checkbox",
                "selected": "0",
                "label": "Show \"Connect to WiFi\" instructions",
                "children": [
                    "showConnectWiFiEnable"
                ]
            },
            "ShowHelpUrlInstructions": {
                "type": "checkbox",
                "selected": "0",
                "label": "Show \"Help URL\" instructions",
                "children": [
                    "showHelpUrlEnable"
                ]
            },
            "DisplaySharingMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Display Sharing Mode",
                "children": [
                    "hijackingEn",
                    "showOverlayRxNameOpt",
                    "overlayRxNamePlacement"
                ]
            },
            "HdmiPortPowerManagement": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI/VGA Port Power Management",
                "children": [
                    "hdmiPortPowerManagement",
                    "scrSaverSecond",
                    "scrHDMIOutEnable",
                    "scrSaverEnable"
                ],
                "dataToSend": [
                    "scrSaverSecond",
                    "scrHDMIOutEnable",
                    "scrSaverEnable"
                ]
            },
            "Wakeup": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wake Up",
                "children": [
                    "wakedByScan"
                ]
            },
            "TVScreenSize": {
                "type": "checkbox",
                "selected": "0",
                "label": "TV Screen Size",
                "children": [
                    "newOverScanVal",
                    "rtOverscanEnable"
                ]
            },
            "HDMICEC": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDMI-CEC",
                "children": [
                    "CECEnable"
                ]
            },
            // "UIBC": {
            //     "type": "checkbox",
            //     "selected": "0",
            //     "label": "UIBC",
            //     "children": [
            //         "UIBCEnable"
            //     ]
            // },
            // "USBAutoConfig": {
            //     "type": "checkbox",
            //     "selected": "0",
            //     "label": "USB Auto Configure",
            //     "children": [
            //         "usbConfigEn"
            //     ]
            // },
            "FirmwareUpgrade": {
                "type": "checkbox",
                "selected": "0",
                "label": "Firmware Upgrade via USB Autorun",
                "children": [
                    "fwupdateAccess"
                ]
            },
            "InactivityRebootTime": {
                "type": "checkbox",
                "selected": "0",
                "label": "Inactivity Reboot Time",
                "children": [
                    "InactivityRebootTime"
                ]
            },
            "ReceiverLogging": {
                "type": "checkbox",
                "selected": "0",
                "label": "Receiver Logging",
                "children": [
                    "RxCsvLogEn"
                ]
            },
            "PushButton": {
                "type": "checkbox",
                "selected": "0",
                "label": "Push Button",
                "children": [
                    "PushButtonEn"
                ]
            },
            "AirplayBroadcast": {
                "type": "checkbox",
                "selected": "0",
                "label": "AirPlay Discovery Broadcast",
                "children": [
                    "iBeaconEnable"
                ]
            },
            "SetBackgroundGroupTitle": {
                "type": "title",
                "title": "SetBackground"
            },
            "SetBackground": {
                "type": "checkbox",
                "selected": "0",
                "label": "Set Background",
                "children": [
                    "BackgroundCustom"
                ]
            },
            "SetScreenSaverGroupTitle": {
                "type": "title",
                "title": "SetScreenSaver"
            },
            "SetScreenSaver": {
                "type": "checkbox",
                "selected": "0",
                "label": "Set Screen Saver",
                "children": [
                    "ScreensaverCustom"
                ]
            },
            "DigitalSignageGroupTitle": {
                "type": "title",
                "title": "DigitalSignage"
            },
            "DigitalSignageMode": {
                "type": "checkbox",
                "selected": "0",
                "label": "Digital Signage Mode",
                "children": [
                    "DigitalSignageModeSubTitle",
                    "digitalSignEn", //Digital Signage
                    "digitalSignFullScreenEn", //Display Mode
                    "digitalSignUrl", //Source URL
                    "digitalSignDelaySec", //Restart Delay(seconds)
                    "digitalSignAudioVol" //Digital Signage Audio Volume
                ]
            },
            "LocalWifiGroupTitle": {
                "type": "title",
                "title": "LocalWifi"
            },
            "LocalWifi": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Wifi Network",
                "children": [
                    "NetworkMode",
                ]
            },
            "ManagementAccessGroupTitle": {
                "type": "title",
                "title": "ManagementAccess"
            },
            "BlockcommunicationontheLAN": {
                "type": "checkbox",
                "selected": "0",
                "label": "Block communication on the LAN",
                "children": [
                    "BlockLanIpEnable"
                ]
            },
            "InterfaceFeature": {
                "type": "checkbox",
                "selected": "0",
                "label": "Interface Feature Assignment",
                "children": [
                    "cmsInterface",
                    "apBridgeInterface"
                ]
            },
            "NetworkName": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Name",
                "children": [
                    "EthNetworkName"
                ]
            },
            "EthernetTCPIPSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "Ethernet TCP/IP Settings",
                "children": [
                    "ethAutoIpEnable",
                    "ethIpAddress",
                    "ethNetmask",
                    "ethGateway",
                    "ethAutoDnsEnable",
                    "ethPrimaryDns",
                    "ethSecondaryDns"
                ]
            },
            "WirelessTCPIPSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wireless TCP/IP Settings",
                "children": [
                    "staAutoIpEnable",
                    "staIpAddress",
                    "staNetmask",
                    "staGateway",
                    "staAutoDnsEnable",
                    "staPrimaryDns",
                    "staSecondaryDns"
                ]
            },
            "SbLocalWifi": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Wifi Settings",
                "children": [
                    "ApBsSSEnable",
                    "ApBsSSIDIndex",
                    "ApBsSSSecurityType",
                    "ApBsSSName",
                    "ApBsSSPasswd",
                    "showWiFiQREnable"
                ]
            },
            "WiredConnection": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wired Connection Settings",
                "children": [
                    "CurEthAuthentication",
                    "CurEthRadiusID",
                    "CurEthRadiusPWD",
                    "EthCertDateTime",
                    "EthRootCertURL",
                    "EthUserCertURL",
                    "EthUserKeyURL"
                ]
            },
            "WirelessConnection": {
                "type": "checkbox",
                "selected": "0",
                "label": "Wireless Connection Settings",
                "children": [
                    "CurConnectApName",
                    "CurConnectApSecType",
                    "CurConnectApRadiusId",
                    "CurConnectApPwd",
                    "TLSCertDateTime",
                    "TLSRootCertURL",
                    "TLSUserCertURL",
                    "TLSUserKeyURL"
                ]
            },
            "CentralManagementSystemSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "Central Management System Settings",
                "children": [
                    "mgtSrvFullAddr",
                    "mgtSrvPort"
                ]
            },
            "WebPort": {
                "type": "checkbox",
                "selected": "0",
                "label": "WEB Communication Port",
                "children": [
                    "webServerPort"

                ]
            },
            "LocalMgtAccess": {
                "type": "checkbox",
                "selected": "0",
                "label": "Local Management Access Settings",
                "children": [
                    "webMgtOverWiFiEn"
                ]
            },
            "NetworkInterfaceAllowed": {
                "type": "checkbox",
                "selected": "0",
                "label": "Network Interface Allowed",
                "children": [
                    "networkInterfaceAllowed"
                ]
            },
            // Advanced Settings
            "AdvancedSettingsGroupTitle": {
                "type": "title",
                "title": "AdvancedSettings"
            },
            "DebugLogOutput": {
                "type": "checkbox",
                "selected": "0",
                "label": "Debug Log output",
                "children": [
                    "debugLogOutputEnable"
                ]
            },
            "DebuggingSetting": {
                "type": "checkbox",
                "selected": "0",
                "label": "Debugging",
                "children": [
                    "sshServerEnable",
                    "SSHComPort"
                ]
            },
            "DebugView": {
                "type": "checkbox",
                "selected": "0",
                "label": "Debug View",
                "children": [
                    "debugViewEnable"
                ]
            },
            "ScreenBeamLogo": {
                "type": "checkbox",
                "selected": "0",
                "label": "ScreenBeam logo",
                "children": [
                    "showSBLogoEnable"
                ]
            },
            "DisableEapolKeyRetries": {
                "type": "checkbox",
                "selected": "0",
                "label": " Disable EAPOL Key Retries (for WPA2-PSK)",
                "children": [
                    "ApBsDisableEapolKeyRetries"
                ]
            },
            "GhostInking": {
                "type": "checkbox",
                "selected": "0",
                "label": "Ghost Inking",
                "children": [
                    "ghostInkEnable",
                    "ghostInkOpacity"
                ]
            },
            "PCRSync": {
                "type": "checkbox",
                "selected": "0",
                "label": "PCR Synchronization",
                "children": [
                    "PCRSyncEnable"
                ]
            },
            "HdcpEncryption": {
                "type": "checkbox",
                "selected": "0",
                "label": "HDCP Encryption",
                "children": [
                    "HDCPEncrypMode"
                ]
            },
            "AspectRatio": {
                "type": "checkbox",
                "selected": "0",
                "label": "Aspect Ratio",
                "children": [
                    "aspectRatio"
                ]
            },
            "HorizontalVerticalOffset": {
                "type": "checkbox",
                "selected": "0",
                "label": "Horizontal/Vertical Offset",
                "children": [
                    "scrHoffset",
                    "scrVoffset"]
            },
            "P2PWirelessNetworkSettings": {
                "type": "checkbox",
                "selected": "0",
                "label": "P2P Wireless Network Settings (AGO enabled only)",
                "children": [
                    "p2pIpAddr",
                    "p2pSubnet",
                    "p2pDhcpIpStart",
                    "p2pDhcpIpEnd"
                ]
            },
            "FastIP": {
                "type": "checkbox",
                "selected": "0",
                "label": "Fast-IP",
                "children": [
                    "fastIPEn"
                ]
            },
            "TxAgoBcnEnable": {
                "type": "checkbox",
                "selected": "0",
                "label": "AGO beacon broadcast",
                "children": [
                    "txAgoBcnEnable"
                ]
            }
        }
    }
    static getAllConfigParameters = () => {
        return {
            /* Device Name Access - RADIO BUTTON */
            "RxNameAccess": {
                "type": "radioButton",
                "label": "DeviceNameAccess",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Device Name - INPUT TEXT */
            "RxName": {
                "type": "input",
                "value": "",
                "label": "DeviceName",
                "info": "",
                "display": "0",
                "preValidate": (value) => {
                    if (value.length <= 32) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    // if (value[0] === " " || value[value.length - 1] === " ") {
                    //     return "EmptyDeviceNameError";
                    // }
                    // if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/]+$/.test(value)) {
                    //     return "InvalidDeviceNameError";
                    // }
                    return SettingHelpers.check_device_name(value, true);
                }
            },
            /* Manufacture Name - INPUT TEXT */
            "manufacName": {
                "type": "input",
                "value": "",
                "label": "ManufactureName",
                "info": "",
                "display": "2"
            },
            /* Model Name - INPUT TEXT */
            "mdNameLong": {
                "type": "input",
                "value": "",
                "label": "ModelName",
                "info": "",
                "display": "2"
            },
            /* Model Name Short - INPUT TEXT */
            "mdNameShort": {
                "type": "input",
                "value": "",
                "label": "ModelNameShort",
                "info": "",
                "display": "2"
            },
            /* Hardware Version - INPUT TEXT */
            "hwVersion": {
                "type": "input",
                "value": "",
                "label": "HardwareVersion",
                "info": "",
                "display": "2"
            },
            /* Firmware Version - INPUT TEXT */
            "fwVersion": {
                "type": "input",
                "value": "",
                "label": "FirmwareVersion",
                "info": "",
                "display": "2"
            },
            /* Admin Username - INPUT TEXT */
            "webUserName": {
                "type": "input",
                "value": "Administrator",
                "label": "AdminUsername",
                "info": "",
                "display": "0",
                "checkbox": "LocalWebManagement",
                "preValidate": (value) => {
                    if (value.length <= 16) {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value.length === 0) {
                        return "EmptyUsernameError";
                    }
                    if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                        return "InvalidUsernameError";
                    }
                }
            },
            /* Admin Password - INPUT PASSWORD*/
            "webPassword": {
                "type": "password",
                "value": "",
                "label": "AdminPassword",
                "info": "",
                "display": "0",
                "checkbox": "LocalWebManagement",
                "preValidate": (value) => {
                    if (value.length <= 16) {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value.length === 0) {
                        return "EmptyPasswordError";
                    }
                    if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                        return "InvalidPasswordError";
                    }
                }
            },
            /* Language - DROPDOWN */
            "language": {
                "type": "dropdown",
                "checkbox": "Language",
                "value": "en",
                "label": "Language",
                "info": "",
                "display": "0",
                "options": {
                    "English": "en",
                    "简体中文": "zhcs",
                    "正體中文": "zhct",
                    "日本語": "ja",
                    "Français": "fr",
                    "Deutsch": "ge",
                    "Nederlands": "du",
                    "한국어": "ko",
                    "Español": "sp",
                    "Italiano": "it",
                    "Русский": "ru",
                }
            },
            /* Host Name - INPUT TEXT */
            "RXHostname": {
                "type": "input",
                "value": "",
                "label": "HostName",
                "info": "",
                "display": "0",
                "preValidate": (value) => {
                    if (value.length <= 63) {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value.length === 0) {
                        return "EmptyHostNameError";
                    }
                    if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                        return "InvalidHostName";
                    }
                    if (value[0] === "-" || value[value.length - 1] === "-") {
                        return "InvalidHostName1";
                    }
                }
            },
            /* Time zone - DROPDOWN */
            "timezoneItem": {
                "type": "dropdown",
                "checkbox": "Timezone",
                "value": "5",
                "label": "Timezone",
                "info": "",
                "display": "0",
                "options": SettingHelpers.getTimezoneList()
            },
            "NTPServerConfigUrl": {
                "type": "input",
                "value": "pool.ntp.org",
                "label": "NtpTimeServer",
                "info": "",
                "display": "0",
            },
            /* Daylight - CHECKBOX */
            "dstTimeEnable": {
                "type": "checkbox",
                "value": "",
                "label": "DaylightSavings",
                "info": "",
                "display": "0"
            },
            /* Wireless Display Mode Title - TITLE */
            "WirelessDisplayModeTitle": {
                "type": "title",
                "title": "WirelessDisplayModeTitle",
                "display": "0"
            },
            /* Wireless Display Mode - DROPDOWN*/
            "WiFiOperateMode": {
                "type": "dropdown",
                "value": "5",
                "label": "WirelessDisplayMode",
                "info": "",
                "display": "0",
                "options": {
                    "CommanderMode": "1",
                    "StandardWirelessDisplay": "5"
                },
                "onChange": (_this) => {
                    var value = _this.state.settings.WiFiOperateMode.value;
                    if (value === "1") {
                        _this.setState({
                            "tabHeaders": [
                                "DeviceConfiguration",
                                "Features",
                                "NetworkSettings",
                                "ManagementAccess",
                                "AdvancedSettings"
                            ]
                        });
                    }
                    else {
                        _this.setState({
                            "tabHeaders": [
                                "DeviceConfiguration",
                                "Features",
                                "DigitalSignage",
                                "NetworkSettings",
                                "LocalWifi",
                                "ManagementAccess",
                                "AdvancedSettings"
                            ]
                        });
                    }
                    SettingHelpers.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                }
            },
            /* Wireless Display over LAN Title - TITLE */
            "WirelessDisplayOverLANTitle": {
                "type": "title",
                "title": "WirelessDisplayOverLan",
                "display": "0",
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* Windows 10 - RADIO BUTTON */
            "infracastEnable": {
                "type": "radioButton",
                "label": "Windows10",
                "info": "Windows10Info",
                "display": "0",
                "checkbox": "Infracast",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* macOS and windows 7 - RADIO BUTTON */
            "MacInfracastEn": {
                "type": "radioButton",
                "label": "MacWin7",
                "info": "MacWin7Info",
                "display": "0",
                "checkbox": "MacOSandWin7",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* macOS or iOS native screen mirroring - RADIO BUTTON */
            "airPlayEn": {
                "type": "radioButton",
                "label": "AirplayMode",
                "info": "",
                "display": "0",
                "checkbox": "MacAirplay",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* iOS push mode - RADIO BUTTON */
            "AirplayMirroringOnlyEn": {
                "type": "radioButton",
                "label": "AirplayPushMode",
                "info": "AirplayPushModeInfo",
                "display": "0",
                "checkbox": "iOSPushMode",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* ChromeOS/Chrome native screen mirroring - RADIO BUTTON */
            "GoogleCastEnable": {
                "type": "radioButton",
                "label": "GoogleCastMode",
                "info": "GoogleCastModeInfo",
                "display": "0",
                "checkbox": "GoogleCastMode",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "1": "1"
                }
            },
            /* P2P wireless setting title - TITLE */
            "P2PWirelessSettingTitle": {
                "type": "title",
                "title": "P2PWirlessSettings",
                "display": "0"
            },
            /* P2P Operating Channel ----- NEED TO DO */
            /* Transmit Power - DROPDOWN */
            "txPowerItem": {
                "type": "dropdown",
                "value": "1",
                "label": "TransmitPower",
                "info": "",
                "display": "0",
                "options": {
                    "High": "2",
                    "Medium": "1",
                    "Low": "0"
                }
            },
            /* Security Setting title - TITLE */
            "SecuritySettingTitle": {
                "type": "title",
                "title": "SecuritySettings",
                "display": "0"
            },
            /* Force PIN Pairing on First Connection - RADIO BUTTON*/
            "pinEnforceEnable": {
                "type": "radioButton",
                "label": "ForcePinPairing",
                "info": "ForcePinPairingInfo",
                "display": "0",
                "value": "1",
                "options": {
                    "On": "1",
                    "Off": "0"
                },
                "onStart": (settings, data) => {
                    if (settings["pinEnforceEnable"].value === "1") {
                        settings["pinEnforceEnable"].info = "ForcePinPairingInfo";
                    }
                    else {
                        settings["pinEnforceEnable"].info = "ForcePinPairingOffInfo";
                    }

                    return settings["pinEnforceEnable"];
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    if (_this.state.settings["pinEnforceEnable"].value === "1") {
                        SettingHelpers.setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingInfo");
                    } else {
                        SettingHelpers.setSettingState(_this, "pinEnforceEnable", "info", "ForcePinPairingOffInfo");
                    }
                }
            },
            /* Require PIN on - RADIO BUTTON */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "pinEachConnEnable": {
                "type": "radioButton",
                "label": "RequirePinOn",
                "info": "RequirePinOnInfo",
                "display": "0",
                "value": "0",
                "options": {
                    "EachConnection": "1",
                    "FirstConnection": "0"
                },
                "dependencies": ["pinEnforceEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "onStart": (settings, data) => {
                    if (settings["pinEachConnEnable"].value === "1") {
                        settings["pinEachConnEnable"].info = "RequirePinOnEachConnInfo";
                    }
                    else {
                        settings["pinEachConnEnable"].info = "RequirePinOnInfo";
                    }

                    return settings["pinEachConnEnable"];
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    if (_this.state.settings["pinEachConnEnable"].value === "1") {
                        SettingHelpers.setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnEachConnInfo");
                    } else {
                        SettingHelpers.setSettingState(_this, "pinEachConnEnable", "info", "RequirePinOnInfo");
                    }
                }
            },
            /* PIN Length - DROPDOWN */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "fourDigitPINEn": {
                "type": "dropdown",
                "label": "PinLength",
                "info": "PinLengthInfo",
                "display": "0",
                "value": "1",
                "options": {
                    "8Digit": "0",
                    "4Digit": "1"
                },
                "dependencies": ["pinEnforceEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    var strtemp = "";
                    var strtempInfo = "";
                    var staticPinMaxLen = 4;
                    if (_this.state.settings["fourDigitPINEn"].value === "1") {
                        if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length < 4) {
                            strtemp = "StaticPin4Error";
                        }
                        strtempInfo = "StaticPin4Info";
                    }
                    else {
                        staticPinMaxLen = 7;
                        if (_this.state.settings["staticPinEnable"].value === "1" && _this.state.settings["staticPinVal"].value.length !== 7) {
                            strtemp = "StaticPin8Error";
                        }
                        strtempInfo = "StaticPin8Info";
                    }
                    if (_this.state.settings["staticPinEnable"].value === "1") {
                        SettingHelpers.setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen,
                            () => {
                                SettingHelpers.setSettingState(_this, "staticPinVal", "error", strtemp, () => {
                                    SettingHelpers.setSettingState(_this, "staticPinVal", "info", strtempInfo);
                                });
                            });
                    } else {
                        SettingHelpers.setSettingState(_this, "staticPinVal", "maxLength", staticPinMaxLen);
                    }
                }
            },
            /* PIN Generation Method - RADIO BUTTON */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "staticPinEnable": {
                "type": "radioButton",
                "label": "PinGenerationMethod",
                "info": "PinGenerationMethodInfo",
                "display": "0",
                "value": "0",
                "options": {
                    "Static": "1",
                    "Random": "0"
                },
                "dependencies": ["pinEnforceEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "onStart": (settings, data) => {
                    if (settings["staticPinEnable"].value === "1") {
                        settings["staticPinEnable"].info = "PinGenerationMethodStaticInfo";
                    }
                    else {
                        settings["staticPinEnable"].info = "PinGenerationMethodInfo";
                    }

                    return settings["staticPinEnable"];
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    var strStaticPinEnInfo = "";
                    var strStaticPinValInfo = "";
                    if (_this.state.settings["staticPinEnable"].value === "1") {
                        strStaticPinEnInfo = "PinGenerationMethodStaticInfo";
                    }
                    else {
                        strStaticPinEnInfo = "PinGenerationMethodInfo";
                    }

                    if (_this.state.settings["fourDigitPINEn"].value === "1") {
                        strStaticPinValInfo = "StaticPin4Info";
                    }
                    else {
                        strStaticPinValInfo = "StaticPin8Info";
                    }
                    SettingHelpers.setSettingState(_this, "staticPinEnable", "info", strStaticPinEnInfo,
                        () => {
                            SettingHelpers.setSettingState(_this, "staticPinVal", "info", strStaticPinValInfo);
                        });
                }
            },
            /* Static PIN Val - INPUT TEXT */
            // This item is controlled by "pinEnforceEnable" - Force PIN Pairing
            // Hide the item when "pinEnforceEnable" = "0" (Off)
            "staticPinVal": {
                "type": "pin",
                "value": "1234567",
                "label": "StaticPin",
                "info": "",
                "pinLength": "fourDigitPINEn",
                "display": "0",
                "maxLength": "4",
                "dependencies": ["pinEnforceEnable", "staticPinEnable"],
                "dependencyMap": {
                    "10": "1",
                    "00": "1",
                    "01": "1"
                },
                "onStart": (settings, data) => {
                    // Initiate the maxLength if receiving data from CMS Service
                    if (data["fourDigitPINEn"] === "0")
                        settings["staticPinVal"].maxLength = "7";
                    return settings["staticPinVal"];
                },
                "preValidate": (value, _this) => {
                    if (/^[0-9]+$/.test(value) || value === "") {
                        /*if ( (_this.state.settings["fourDigitPINEn"].value === "1") && (value.length === 4)) {
                             return true;
                        }else if ((_this.state.settings["fourDigitPINEn"].value === "0") && (value.length === 7)) {
                             return true;
                        }*/
                        return true;
                    }
                },
                "onChange": (_this) => {
                    SettingHelpers.setChangeMapModifiedPinFea(_this);
                    //Now we only need to send 7 digits to RX.
                    /*if (_this.state.settings["staticPinVal"].value.length == 7) {
                        var pin_code = _this.state.settings["staticPinVal"].value;
                        var pin = parseInt(pin_code) * 10;
                        var acccum = 0;
                        var result = "";

                        acccum = 3 * parseInt(((pin / 10000000) % 10), 10);
                        acccum += 1 * parseInt(((pin / 1000000) % 10), 10);
                        acccum += 3 * parseInt(((pin / 100000) % 10), 10);
                        acccum += 1 * parseInt(((pin / 10000) % 10), 10);
                        acccum += 3 * parseInt(((pin / 1000) % 10), 10);
                        acccum += 1 * parseInt(((pin / 100) % 10), 10);
                        acccum += 3 * parseInt(((pin / 10) % 10), 10);
                        result = ((10 - (acccum % 10)) % 10);
                        value = pin_code + result.toString();
                        _this.changeMap["staticPinVal"] = value;
                        setSettingState(_this, "staticPinVal", "value", value);
                    }*/
                    //if (_this.state.settings["fourDigitPINEn"].value === "0") {
                    //    setSettingState(_this,
                    //        "fourDigitPINEn",
                    //        "info",
                    //        "Enter the first 7 digits of the PIN - the 8th digit will be generated for you. Static PIN will not appear on the HDTV/Projector to enable protected mode.");
                    //}
                },
                "onSubmit": (_this) => {
                    // Slice the value if it is 4-digit PIN
                    if (_this.state.settings["fourDigitPINEn"].value == "1" && _this.state.settings["staticPinVal"].value.length > 4) {
                        _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value.slice(0, 4);
                    } else {
                        _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value;
                    }
                },
                "validate": (value, _this) => {

                    if ((_this.state.settings["staticPinEnable"].value == "1") && (_this.state.settings["fourDigitPINEn"].value === "1") && (value.length !== 4)) {
                        // In case, the length is 7 which means the default value, we will ignore it. Otherwise, it is an error
                        if (value.length != 7) return "StaticPin4Error";
                    }
                    if ((_this.state.settings["staticPinEnable"].value == "1") && (_this.state.settings["fourDigitPINEn"].value === "0") && (value.length !== 7)) {
                        return "StaticPin8Error";
                    }
                }
            },
            /* PIN Display Period - INPUT TEXT */
            "PINDisplayPeriod": {
                "type": "input",
                "value": "60",
                "label": "PinDisplayPeriod",
                "info": "PinDisplayPeriodInfo",
                "display": "0",
                "preValidate": (value) => {
                    if (/^[0-9]+$/.test(value) || value === "") {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value > 120 || value < 30 || value === "") {
                        return "PinDisplayPeriodError";
                    }
                }
            },
            /* Security Setting Title - TITLE */
            "SecuritySettingTitle": {
                "type": "title",
                "title": "SecuritySetting",
                "display": "0"
            },
            /*Disable EAPOL Key Retries (for WPA2-PSK) - Radio Button*/
            "ApBsDisableEapolKeyRetries": {
                "type": "radioButton",
                "value": "0",
                "label": "DisableEAPOLKeyRetries",
                "info": "",
                "display": "0",
                "options": {
                    "On": "1",
                    "Off": "0"
                },
                "onStart": (settings, data) => {
                    var tempInfo = "";
                    if (settings["ApBsDisableEapolKeyRetries"].value === "1") {
                        tempInfo = "DisableEAPOLKeyRetriesOnInfo";
                    }
                    else {
                        tempInfo = "DisableEAPOLKeyRetriesOffInfo";
                    }
                    settings["ApBsDisableEapolKeyRetries"].info = tempInfo;
                    return settings["ApBsDisableEapolKeyRetries"];
                },
                "onChange": (_this) => {
                    var tempInfo = "";
                    if (_this.state.settings["ApBsDisableEapolKeyRetries"].value === "1") {
                        tempInfo = "DisableEAPOLKeyRetriesOnInfo";
                    }
                    else {
                        tempInfo = "DisableEAPOLKeyRetriesOffInfo";
                    }
                    SettingHelpers.setSettingState(_this, "ApBsDisableEapolKeyRetries", "info", tempInfo);
                }
            },
            /* Advanced Feature Title - TITLE */
            "AdvancedFeatureTitle": {
                "type": "title",
                "title": "AdvancedFeature",
                "display": "0"
            },
            /*Enhanced Miracast P2P security - Radio Button*/
            "dynAgoPassphraseEn": {
                "type": "radioButton",
                "value": "0",
                "label": "EnhancedMiracastP2Psecurity",
                "info": "",
                "display": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "onStart": (settings, data) => {
                    var tempInfo = "";
                    if (settings["dynAgoPassphraseEn"].value === "1") {
                        tempInfo = "EnhancedMiracastP2PsecurityEnableInfo";
                    }
                    else {
                        tempInfo = "EnhancedMiracastP2PsecurityDisableInfo";
                    }
                    settings["dynAgoPassphraseEn"].info = tempInfo;
                    return settings["dynAgoPassphraseEn"];
                },
                "onChange": (_this) => {
                    var tempInfo = "";
                    if (_this.state.settings["dynAgoPassphraseEn"].value === "1") {
                        tempInfo = "EnhancedMiracastP2PsecurityEnableInfo";
                    }
                    else {
                        tempInfo = "EnhancedMiracastP2PsecurityDisableInfo";
                    }
                    SettingHelpers.setSettingState(_this, "dynAgoPassphraseEn", "info", tempInfo);
                }
            },
            /* Display Setting Title - TITLE */
            "DisplaySettingTitle": {
                "type": "title",
                "title": "DisplaySetting",
                "display": "0"
            },
            /*Display Sharing Mode - DROPDOWN*/
            "hijackingEn": {
                "type": "dropdown",
                "value": "1",
                "label": "HiJacking",
                "info": "",
                "display": "0",
                "options": {
                    "HiJackingSingle": "0",
                    "HiJackingQuickSwitch": "1",
                    "HiJackingMultiView": "2"
                },
                "onStart": (settings, data) => {
                    if (settings["hijackingEn"].value === "0" || settings["hijackingEn"].value === "2") {
                        settings["hijackingEn"].info = "HiJackingSingleInfo";
                    } else {
                        settings["hijackingEn"].info = "HiJackingQuickSwitchInfo";
                    }
                    return settings["hijackingEn"];
                },
                "onChange": (_this) => {
                    if (_this.state.settings["hijackingEn"].value === "0" || _this.state.settings["hijackingEn"].value === "2") {
                        SettingHelpers.setSettingState(_this, "hijackingEn", "info", "HiJackingSingleInfo");
                    } else {
                        var strtmp = "";
                        if (_this.state.settings["showOverlayRxNameOpt"].value === "0") {
                            strtmp = "ShowRxNameAlwaysInfo";
                        }
                        else if (_this.state.settings["showOverlayRxNameOpt"].value === "1") {
                            strtmp = "ShowRxNameUponScanInfo";
                        } else {
                            strtmp = "ShowRxNameDonnotShowInfo";
                        }

                        SettingHelpers.setSettingState(_this, "hijackingEn", "info", "HiJackingQuickSwitchInfo",
                            () => {
                                SettingHelpers.setSettingState(_this, "showOverlayRxNameOpt", "info", strtmp);
                            });
                    }
                }
            },
            /*Show Receiver Name*/
            "showOverlayRxNameOpt": {
                "type": "radioButton",
                "value": "0",
                "label": "ShowRxName",
                "info": "",
                "display": "0",
                "checkbox": "ShowReceiverName",
                "options": {
                    "ShowRxNameAlways": "0",
                    //"ShowRxNameUponScan": "1"
                    "ShowRxNameDonnotShow": "2"
                },
                "dependencies": ["hijackingEn"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1"
                },
                "onStart": (settings, data) => {
                    if (data["hijackingEn"] === "1") {
                        if (settings["showOverlayRxNameOpt"].value === "0") {
                            settings["showOverlayRxNameOpt"].info = "ShowRxNameAlwaysInfo";
                        }
                        else if (settings["showOverlayRxNameOpt"].value === "1") {
                            settings["showOverlayRxNameOpt"].info = "ShowRxNameUponScanInfo";
                        } else {
                            settings["showOverlayRxNameOpt"].info = "ShowRxNameDonnotShowInfo";
                        }
                    }
                    return settings["showOverlayRxNameOpt"];
                },
                "onChange": (_this) => {
                    if (_this.state.settings["showOverlayRxNameOpt"].value === "0") {
                        SettingHelpers.setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameAlwaysInfo");
                    } else if (_this.state.settings["showOverlayRxNameOpt"].value === "1") {
                        SettingHelpers.setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameUponScanInfo");
                    } else {
                        SettingHelpers.setSettingState(_this, "showOverlayRxNameOpt", "info", "ShowRxNameDonnotShowInfo");
                    }
                }
            },
            /* Receiver Name Placement - DROPDOWN */
            "overlayRxNamePlacement": {
                "type": "dropdown",
                "value": "0",
                "label": "ShowRxNamePlacement",
                "info": "ShowRxNamePlacementInfo",
                "display": "0",
                "options": {
                    "ShowRxNamePlacementBL": "0",
                    "ShowRxNamePlacementBR": "1",
                    "ShowRxNamePlacementBCT": "2",
                    "ShowRxNamePlacementUL": "3",
                    "ShowRxNamePlacementUR": "4",
                    "ShowRxNamePlacementUCT": "5"
                },
                "dependencies": ["hijackingEn", "showOverlayRxNameOpt"],
                "dependencyMap": {
                    "00": "1",
                    "01": "1",
                    "02": "1",
                    "12": "1",
                    "20": "1",
                    "21": "1",
                    "22": "1"
                }
            },
            /* ScreenBeam Network Info - DROPDOWN */
            "showMoreInfoEnable": {
                "type": "dropdown",
                "value": "1",
                "label": "ScreenBeamNetworkInfo",
                "info": "",
                "display": "0",
                "options": {
                    "DisplayAll": "1",
                    "DisplayMinimal": "2",
                    "DisplayNone": "0"
                }
            },
            /* Connection Instruction - RADIO BUTTON */
            "hideReadyInfoEnable": {
                "type": "radioButton",
                "label": "ConnectionInstruction",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Show": "0",
                    "Hide": "1"
                }
            },
            /* Show "Connect to WiFi" instructions */
            "showConnectWiFiEnable": {
                "type": "radioButton",
                "label": "ConnectionWiFiInstruction",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Show": "1",
                    "Hide": "0"
                },
                "dependencies": ["hideReadyInfoEnable"],
                "dependencyMap": {
                    "0": "0",
                    "1": "1"
                },
            },
            /* HDMI/VGA Port Power Management */
            "hdmiPortPowerManagement": {
                "type": "dropdown",
                "label": "HdmiVgaManagement",
                "value": "01",
                "display": "0",
                "info": "HdmiVgaManagementInfo",
                "isNotInOriginalData": true,
                "options": {
                    "AlwaysOn": "01",
                    "ScreenSaver": "11",
                    "DisplayOff": "10"
                },
                "onStart": (settings, data) => {
                    if (data["scrSaverEnable"] && data["scrHDMIOutEnable"]) {
                        let scrSaverEnable = data["scrSaverEnable"];
                        let scrHdmiOutEnable = data["scrHDMIOutEnable"];
                        settings["hdmiPortPowerManagement"].value = scrSaverEnable + scrHdmiOutEnable;
                    }
                    return settings["hdmiPortPowerManagement"];
                },
                "onChange": (_this, value, cb) => {
                    let selection = _this.state.settings["hdmiPortPowerManagement"].value;
                    _this.changeMap["scrSaverEnable"] = selection[0];
                    _this.changeMap["scrHDMIOutEnable"] = selection[1];
                    _this.changeMap["scrSaverSecond"] = _this.state.settings["scrSaverSecond"].value;
                    delete _this.changeMap["hdmiPortPowerManagement"];
                    _this.setState({
                        settings: {
                            ..._this.state.settings,
                            "scrHDMIOutEnable": {
                                ..._this.state.settings["scrHDMIOutEnable"],
                                value: selection[1]
                            },
                            "scrSaverEnable": {
                                ..._this.state.settings["scrSaverEnable"],
                                value: selection[0]
                            }
                        }
                    }, cb);
                }
            },
            "scrHDMIOutEnable": {
                "value": "1"
            },
            "scrSaverEnable": {
                "value": "0"
            },
            /* Wait - Triggered when HDMI/VGA selects Screensaver or Display Off - INPUT TEXT */
            "scrSaverSecond": {
                "type": "input",
                "label": "Wait",
                "info": "WaitInfo",
                "display": "1",
                "value": "180",
                "dependencies": ["hdmiPortPowerManagement"],
                "dependencyMap": {
                    "01": "1",
                    "11": "0",
                    "10": "0"
                },
                "preValidate": (value) => {
                    var result = SettingHelpers.check_screen_saver_second(value)
                    if (!result) return true
                    else return false
                },
                "validate": (value, _this) => {
                    if (value == "") {
                        return "ScreenSaverWaitEmptyError"
                    }

                    if (value < 1 || value > 9999) {
                        return "InvalidScreenSaverSecond"
                    }
                }
            },
            /* Wake up - DROPDOWN */
            "wakedByScan": {
                "type": "dropdown",
                "label": "Wakeup",
                "info": "",
                "value": "0",
                "options": {
                    "OnConnect": "0",
                    "OnScan": "1"
                },
                "display": "2",
                "dependencies": ["hdmiPortPowerManagement"],
                "dependencyMap": {
                    "01": "2",
                    "11": "0",
                    "10": "0"
                }
            },
            /* Adjust TV Screen Size - DROPDOWN */
            "newOverScanVal": {
                "type": "dropdown",
                "value": "25",
                "label": "AdjustTvScreenSize",
                "info": "",
                "display": "0",
                "options": {
                    "0": "0",
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                    "11": "11",
                    "12": "12",
                    "13": "13",
                    "14": "14",
                    "15": "15",
                    "16": "16",
                    "17": "17",
                    "18": "18",
                    "19": "19",
                    "20": "20",
                    "21": "21",
                    "22": "22",
                    "23": "23",
                    "24": "24",
                    "25": "25"
                },
                "onSubmit": (_this) => {
                    // Need to send rtOverscanEnable together
                    _this.changeMap["newOverScanVal"] = _this.state.settings["newOverScanVal"].value;
                    _this.changeMap["rtOverscanEnable"] = _this.state.settings["rtOverscanEnable"].value;
                }
            },
            /* Allow source device to override overscan value */
            "rtOverscanEnable": {
                "type": "checkbox",
                "label": "OverrideOverScanVal",
                "info": "",
                "display": "0",
                "value": "0"
            },
            /* Auto-reboot receiver when idle for - DROPDOWN */
            "InactivityRebootTime": {
                "type": "dropdown",
                "value": "0",
                "label": "AutoRebootReceiver",
                "info": "",
                "options": {
                    "Never": "0",
                    "6 hours": "6",
                    "8 hours": "8",
                    "10 hours": "10",
                    "12 hours": "12",
                    "24 hours": "24"
                }
            },
            /* Receiver Logging - RADIO BUTTON */
            "RxCsvLogEn": {
                "type": "radioButton",
                "label": "ReceiverLogging",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /*Digital Signage Mode Title - TITLE*/
            "DigitalSignageModeTitle": {
                "type": "title",
                "title": "DigitalSignageMode",
                "display": "0"
            },
            /*Digital Signage Mode SubTitle - SUBTITLE*/
            "DigitalSignageModeSubTitle": {
                "type": "subtitle",
                "title": "DigitalSignageModeSubTitle",
                "display": "0"
            },
            /*Digital Signage*/
            "digitalSignEn": {
                "type": "radioButton",
                "label": "DigitalSignage",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "onChange": (_this) => {
                    SettingHelpers.setDigitalSignageModeSettings(_this);
                },
            },
            /*Display Mode*/
            "digitalSignFullScreenEn": {
                "type": "radioButton",
                "label": "SignageDisplayMode",
                "info": "SignageDisplayModeInfo",
                "display": "0",
                "value": "1",
                "options": {
                    "digitalSignFramed": "0",
                    "digitalSignFullscreen": "1"
                },
                "dependencies": ["digitalSignEn"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setDigitalSignageModeSettings(_this);
                }
            },
            /*Source URL*/
            "digitalSignUrl": {
                "type": "input",
                "label": "digitalSignSourceUrl",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["digitalSignEn"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    SettingHelpers.setDigitalSignageModeSettings(_this);
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["digitalSignEn"].value == "1" && value.length == 0) {
                        return "CannotBeEmpty"
                    }
                }
            },
            /*Restart Delay(seconds)*/
            "digitalSignDelaySec": {
                "type": "input",
                "label": "digitalSignRestartDelay",
                "info": "digitalSignRestartDelayInfo",
                "display": "0",
                "value": "10",
                "dependencies": ["digitalSignEn"],
                "dependencyMap": {
                    "0": "2"
                },
                "preValidate": (value) => {
                    if (/^[0-9]+$/.test(value) || value === "") {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value < 10 || value > 1000) {
                        return "digitalSignRestartDelayErr";
                    } else {
                        return "";
                    }
                },
                "onChange": (_this) => {
                    SettingHelpers.setDigitalSignageModeSettings(_this);
                }
            },
            /*Digital Signage Audio Volume - DROPDOWN */
            "digitalSignAudioVol": {
                "type": "dropdown",
                "label": "digitalSignAudioVolume",
                "info": "",
                "display": "0",
                "value": "0",
                "dependencies": ["digitalSignEn"],
                "dependencyMap": {
                    "0": "2"
                },
                "options": {
                    "Mute": "0",
                    "Low": "1",
                    "Medium": "2",
                    "High": "3"
                },
                "onChange": (_this) => {
                    SettingHelpers.setDigitalSignageModeSettings(_this);
                }
            },
            /* Network Mode - DROPDOWN */
            "NetworkMode": {
                "type": "dropdown",
                "value": "1",
                "label": "NetworkMode",
                "info": "",
                "display": "0",
                "options": {
                    "Nat": "1",
                    "Bridge": "2",
                    "None": "0"
                }
            },
            /*Block communication to devices on the LAN - Radio Button */
            "BlockLanIpEnable": {
                "type": "radioButton",
                "label": "BlockLanIpLabel",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["NetworkMode"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1"
                }
            },
            /* Local Wifi Network Title - TITLE */
            "LocalWifiNetworkTitle": {
                "type": "title",
                "title": "LocalWifiNetwork",
                "display": "0"
            },
            /* Interface Feature Assignment Title - TITLE */
            "InterfaceFeatureTitle": {
                "type": "title",
                "title": "InterfaceFeatureAssignment",
                "display": "0"
            },
            /* CMS Interface - DROPDOWN */
            "cmsInterface": {
                "type": "dropdown",
                "value": "0",
                "label": "CmsInterface",
                "info": "",
                "display": "0",
                "options": {
                    "Auto": "0",
                    "Ethernet": "1",
                    "Wireless": "2"
                },
                "dependencies": ["NetworkMode"],
                "dependencyMap": {
                    "0": "1"
                }
            },
            /* Internet WAN Interface - DROPDOWN */
            "apBridgeInterface": {
                "type": "dropdown",
                "value": "1",
                "label": "InternetWanInterface",
                "info": "",
                "display": "0",
                "options": {
                    "Ethernet": "1",
                    "Wireless": "2"
                },
                "dependencies": ["NetworkMode"],
                "dependencyMap": {
                    "0": "1"
                }
            },
            /* Network Interface Settings Title - TITLE */
            "NetworkInterfaceTitle": {
                "type": "title",
                "title": "NetworkInterfaceSettings",
                "display": "0"
            },
            /* Ethernet Interface Subtitle - SUBTITLE */
            "EthernetInterfaceSubtitle": {
                "type": "subtitle",
                "title": "EthernetInterface",
                "display": "0"
            },
            /* Network Name - INPUT TEXT */
            "EthNetworkName": {
                "type": "input",
                "label": "NetworkName",
                "info": "",
                "display": "0",
                "value": "",
                "preValidate": (value) => {
                    if (value.length <= 16) {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value.length === 0) {
                        return "EmptyNetworkNameError";
                    }
                    if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                        return "InvalidNetworkNameError";
                    }
                }
            },
            /* Connection Settings Subtitle - SUBTITLE */
            "ConnectionSettingsSubtitle": {
                "type": "subtitle",
                "title": "ConnectionSettings",
                "display": "0"
            },
            /* Ethernet Connection Authentication - DROPDOWN */
            "CurEthAuthentication": {
                "type": "dropdown",
                "value": "0",
                "label": "Authentication",
                "info": "",
                "display": "0",
                "options": {
                    "Open": "0",
                    "PEAP-MSCHAPV2": "2",
                    "EAP-TLS": "3"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["CurEthAuthentication"].value !== "0") {
                        _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                        _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                        _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                    }
                    if (_this.state.settings["CurEthAuthentication"].value == "2") {
                        _this.changeMap["CurEthInnerAuth"] = "2";
                        if (_this.state.settings["CurEthInnerAuth"]) {
                            _this.setState({
                                settings: {
                                    ..._this.state.settings,
                                    "CurEthInnerAuth": {
                                        ..._this.state.settings["CurEthInnerAuth"],
                                        value: "2"
                                    }
                                }
                            });
                        }
                    }
                }
            },
            // Used when CurEthAuthentication = 2
            "CurEthInnerAuth": {
                "value": "0"
            },
            /* Ethernet Connection Username - INPUT TEXT */
            // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
            "CurEthRadiusID": {
                "type": "input",
                "value": "",
                "label": "Username",
                "info": "",
                "display": "0",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "0",
                    "3": "0"
                },
                "preValidate": (value) => {
                    if (value.length <= 64) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurEthAuthentication"].value == "2"
                        || _this.state.settings["CurEthAuthentication"].value == "3") {
                        if (value.length == 0) return "EmptyUsernameError";
                        if (!(/^[a-zA-Z0-9\s._@#$-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    }
                },
                "onChange": (_this) => {
                    if (_this.state.settings["CurEthAuthentication"] !== "0") {
                        _this.changeMap["CurEthRadiusPWD"] = _this.state.settings["CurEthRadiusPWD"].value;
                        _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                    }
                }
            },
            /* Ethernet Connection Password - INPUT PASSWORD */
            // ONLY DISPLAY WHEN "CurEthAuthentication" = "2" || "3"
            "CurEthRadiusPWD": {
                "type": "password",
                "value": "",
                "label": "Password",
                "info": "",
                "display": "0",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "0",
                    "3": "0"
                },
                "preValidate": (value) => {
                    if (value.length <= 64) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurEthAuthentication"].value == "2"
                        || _this.state.settings["CurEthAuthentication"].value == "3") {
                        if (value.length == 0) return "EmptyPasswordError";
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                            return "InvalidPasswordError";
                        }
                    }
                },
                "onChange": (_this) => {
                    if (_this.state.settings["CurEthAuthentication"] !== "0") {
                        _this.changeMap["CurEthRadiusID"] = _this.state.settings["CurEthRadiusID"].value;
                        _this.changeMap["EthNetworkName"] = _this.state.settings["EthNetworkName"].value;
                    }
                }
            },
            // NEED TO ADD SYSTEM DATE & TIME, CA CERTIFICATE, USER CERTIFICATE, PRIVATE KEY, VALIDITY PERIOD
            /* System Date & Time */
            "EthCertDateTime": {
                "type": "date",
                "label": "SystemDateTime",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                }
            },
            /* Ethernet - CA Certificate URL - DISABLED INPUT TEXT */
            "EthRootCertURL": {
                "type": "file",
                "acceptFile": ".pem",
                "fileName": "caroot.pem",
                "label": "CaCertificateUrl",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                },
                "onStart": (settings, data) => {
                    if (data["CurEthAuthentication"] == "3") {
                        settings["EthRootCertURL"].value = "1"
                    }
                    return settings["EthRootCertURL"]
                }
            },
            /* Ethernet - User Certificate - DISABLED INPUT TEXT */
            "EthUserCertURL": {
                "type": "file",
                "acceptFile": ".pem",
                "fileName": "usercert.pem",
                "label": "UserCertificateUrl",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                },
                "onStart": (settings, data) => {
                    if (data["CurEthAuthentication"] == "3") {
                        settings["EthUserCertURL"].value = "1"
                    }
                    return settings["EthUserCertURL"]
                }
            },
            /* Ethernet - Private Key URL - DISABLED INPUT TEXT */
            "EthUserKeyURL": {
                "type": "file",
                "acceptFile": ".pem",
                "fileName": "userkey.pem",
                "label": "PrivateKeyUrl",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                },
                "onStart": (settings, data) => {
                    if (data["CurEthAuthentication"] == "3") {
                        settings["EthUserKeyURL"].value = "1"
                    }
                    return settings["EthUserKeyURL"]
                }
            },
            /* Ethernet - Validity Period - INPUT TEXT */
            "EthCertsValidity": {
                "type": "input",
                "label": "ValidityPeriod",
                "info": "",
                "value": "",
                "display": "2",
                "dependencies": ["CurEthAuthentication"],
                "dependencyMap": {
                    "0": "1",
                    "2": "1",
                    "3": "2"
                }
            },
            /* TCP/IP Settings Subtitle - SUBTITLE */
            "TCPIPSettingsSubtitle": {
                "type": "subtitle",
                "title": "TcpIpSettings",
                "display": "0"
            },
            /* Ethernet IP Assignment - RADIO BUTTON */
            "ethAutoIpEnable": {
                "type": "radioButton",
                "label": "IpAssignment",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                        _this.changeMap["ethAutoDnsEnable"] = "0";
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;

                        _this.setState({
                            settings: {
                                ..._this.state.settings,
                                "ethAutoDnsEnable": {
                                    ..._this.state.settings["ethAutoDnsEnable"],
                                    value: "0"
                                }
                            }
                        });
                    }

                }
            },
            /* Ethernet IP Address - INPUT IP */
            "ethIpAddress": {
                "type": "ip",
                "label": "IpAddress",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                    }
                }
            },
            /* Ethernet Subnet Mask - INPUT IP */
            "ethNetmask": {
                "type": "ip",
                "label": "SubnetMask",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                    }
                }
            },
            /* Default Gateway - INPUT IP */
            "ethGateway": {
                "type": "ip",
                "label": "DefaultGateway",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoIpEnable"].value === "0") {
                        _this.changeMap["ethAutoIpEnable"] = _this.state.settings["ethAutoIpEnable"].value;
                        _this.changeMap["ethIpAddress"] = _this.state.settings["ethIpAddress"].value;
                        _this.changeMap["ethNetmask"] = _this.state.settings["ethNetmask"].value;
                        _this.changeMap["ethGateway"] = _this.state.settings["ethGateway"].value;
                    }
                }
            },
            /* DNS Assignment - RADIO BUTTON */
            "ethAutoDnsEnable": {
                "type": "radioButton",
                "label": "DnsAssignment",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "dependencies": ["ethAutoIpEnable"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                        //_this.changeMap["ethAutoDnsEnable"] = "0";
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                    }

                }
            },
            /* Ethernet Primary DNS Server */
            "ethPrimaryDns": {
                "type": "ip",
                "label": "PrimaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["ethAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                        _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                    }

                }
            },
            /* Ethernet Secondary DNS Server */
            "ethSecondaryDns": {
                "type": "ip",
                "label": "SecondaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["ethAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ethAutoDnsEnable"].value === "0") {
                        _this.changeMap["ethAutoDnsEnable"] = _this.state.settings["ethAutoDnsEnable"].value;
                        _this.changeMap["ethPrimaryDns"] = _this.state.settings["ethPrimaryDns"].value;
                        _this.changeMap["ethSecondaryDns"] = _this.state.settings["ethSecondaryDns"].value;
                    }

                }
            },
            /* Wireless Interface Subtitle - SUBTITLE */
            "WirelessInterfaceSubtitle": {
                "type": "subtitle",
                "title": "WirelessInterface",
                "display": "0"
            },
            /* Network Name - INPUT TEXT */
            "CurConnectApName": {
                "type": "input",
                "label": "WirelessNetworkName",
                "value": "",
                "info": "",
                "display": "0",
                "preValidate": (value) => {
                    if (value.length <= 64) {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value.length === 0) {
                        return "EmptyNetworkNameError";
                    }
                    if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];,\.\/]+$/.test(value)) {
                        return "InvalidNetworkNameError";
                    }
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                }
            },
            /* Security Type - DROPDOWN */
            "CurConnectApSecType": {
                "type": "dropdown",
                "value": "0",
                "label": "SecurityType",
                "info": "",
                "display": "0",
                "options": {
                    "Open": "0",
                    "Shared": "1",
                    "WPA-PSK[TKIP]": "2",
                    "WPA2-PSK[AES]": "3",
                    "WPA-PSK[TKIP]+WPA2-PSK[AES]": "4",
                    "PEAP-MSCHAPV2": "5",
                    "EAP-TLS": "6"
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                }
            },
            /* Username - INPUT TEXT */
            "CurConnectApRadiusId": {
                "type": "input",
                "value": "",
                "label": "Username",
                "info": "",
                "display": "0",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "0",
                    "6": "0"
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurConnectApSecType"].value == "5" || _this.state.settings["CurConnectApSecType"].value == "6") {
                        if (value.length == 0) return "EmptyUsernameError";
                        if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                            return "InvalidUsernameError";
                        }
                    }
                }
            },
            /* Password - INPUT PASSWORD */
            "CurConnectApPwd": {
                "type": "password",
                "value": "",
                "label": "Password",
                "info": "",
                "display": "0",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "0",
                    "2": "0",
                    "3": "0",
                    "4": "0",
                    "5": "0",
                    "6": "0"
                },
                "onChange": (_this) => {
                    SettingHelpers.setWirelessInterfaceConnSettings(_this);
                },
                "validate": (value, _this) => {
                    if (_this.state.settings["CurConnectApSecType"].value == "1"
                        || _this.state.settings["CurConnectApSecType"].value == "2"
                        || _this.state.settings["CurConnectApSecType"].value == "3"
                        || _this.state.settings["CurConnectApSecType"].value == "4"
                        || _this.state.settings["CurConnectApSecType"].value == "5"
                        || _this.state.settings["CurConnectApSecType"].value == "6") {
                        if (value.length == 0) return "EmptyPasswordError";
                        if (!/^[a-zA-Z0-9\s~!@#$%^*()_+{}|:?\-=\[\];',\.\/`&\\"]+$/.test(value)) {
                            return "InvalidPasswordError";
                        }
                    }
                }
            },
            /* Wireless - Date Time - DISABLED INPUT */
            "TLSCertDateTime": {
                "type": "date",
                "value": "",
                "label": "SystemDateTime",
                "info": "",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless - CA Certificate URL - DISABLE INPUT */
            "TLSRootCertURL": {
                "type": "file",
                "acceptFile": ".pem",
                "value": "",
                "label": "CaCertificateUrl",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless - User Certificate URL - DISABLE INPUT */
            "TLSUserCertURL": {
                "type": "file",
                "acceptFile": ".pem",
                "value": "",
                "label": "UserCertificateUrl",
                "info": "",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless - Private Key URL - DISABLE INPUT */
            "TLSUserKeyURL": {
                "type": "file",
                "acceptFile": ".pem",
                "value": "",
                "label": "PrivateKeyUrl",
                "info": "",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            "TLSCertsValidity": {
                "type": "input",
                "value": "",
                "label": "ValidityPeriod",
                "info": "",
                "display": "1",
                "dependencies": ["CurConnectApSecType"],
                "dependencyMap": {
                    "0": "1",
                    "1": "1",
                    "2": "1",
                    "3": "1",
                    "4": "1",
                    "5": "1",
                    "6": "2"
                }
            },
            /* Wireless IP Assignment - RADIO BUTTON */
            "staAutoIpEnable": {
                "type": "radioButton",
                "label": "IpAssignment",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                        _this.changeMap["staAutoDnsEnable"] = "0";
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;

                        _this.setState({
                            settings: {
                                ..._this.state.settings,
                                "staAutoDnsEnable": {
                                    ..._this.state.settings["staAutoDnsEnable"],
                                    value: "0"
                                }
                            }
                        });
                    }
                }
            },
            /* Wireless IP Address - INPUT IP */
            "staIpAddress": {
                "type": "ip",
                "label": "IpAddress",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                    }
                }

            },
            /* Wireless Subnet Mask - INPUT IP */
            "staNetmask": {
                "type": "ip",
                "label": "SubnetMask",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                    }
                }
            },
            /* Wireless Default Gateway - INPUT IP */
            "staGateway": {
                "type": "ip",
                "label": "DefaultGateway",
                "info": "",
                "display": "0",
                "value": "0.0.0.0",
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoIpEnable"].value === "0") {
                        _this.changeMap["staAutoIpEnable"] = _this.state.settings["staAutoIpEnable"].value;
                        _this.changeMap["staIpAddress"] = _this.state.settings["staIpAddress"].value;
                        _this.changeMap["staNetmask"] = _this.state.settings["staNetmask"].value;
                        _this.changeMap["staGateway"] = _this.state.settings["staGateway"].value;
                    }
                }
            },
            /* Wireless DNS Assignment - RADIO BUTTON */
            "staAutoDnsEnable": {
                "type": "radioButton",
                "label": "DnsAssignment",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "1",
                    "Static": "0"
                },
                "dependencies": ["staAutoIpEnable"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                        //_this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                    }
                }
            },
            /* Wireless Primary DNS Server - INPUT IP */
            "staPrimaryDns": {
                "type": "ip",
                "label": "PrimaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["staAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                        _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                    }
                }
            },
            /* Wireless Secondary DNS Server - INPUT IP */
            "staSecondaryDns": {
                "type": "ip",
                "label": "SecondaryDnsServer",
                "info": "",
                "display": "0",
                "value": "",
                "dependencies": ["staAutoDnsEnable"],
                "dependencyMap": {
                    "1": "2"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["staAutoDnsEnable"].value === "0") {
                        _this.changeMap["staAutoDnsEnable"] = _this.state.settings["staAutoDnsEnable"].value;
                        _this.changeMap["staPrimaryDns"] = _this.state.settings["staPrimaryDns"].value;
                        _this.changeMap["staSecondaryDns"] = _this.state.settings["staSecondaryDns"].value;
                    }
                }
            },
            /* Local-Wifi Settings Title - TITLE */
            "LocalWifiSettingsTitle": {
                "type": "title",
                "title": "LocalWifiSettings",
                "display": "0"
            },
            /* ScreenBeam Local Wi-Fi - RADIO BUTTON */
            "ApBsS1Enable": {
                "type": "radioButton",
                "label": "ScreenBeamLocalWifi",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ApBsS1Enable"].value === "0") {
                        if (_this.state.settings["networkInterfaceAllowed"].value === "3") {
                            SettingHelpers.popupConfirmAlertDialog(/*_this, "ApBsS1Enable",*/ "DisableLocalWIFIWarning", () => { this.handleWarningClickedNo(_this, "ApBsS1Enable") }, null);
                        }
                    }
                    SettingHelpers.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                },
                "onSubmit": (_this) => {
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                    _this.changeMap["ApBsSSIDIndex"] = "1";
                    _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                    delete _this.changeMap["ApBsS1Enable"];
                }

            },
            /* Wireless Network Name - INPUT TEXT*/
            "ApBsS1Name": {
                "type": "input",
                "label": "WirelessNetworkName",
                "value": "",
                "info": "",
                "display": "0",
                "dependencies": ["ApBsS1Enable"],
                "dependencyMap": {
                    "0": "2"
                },
                "preValidate": (value) => {
                    if (value.length <= 32) {
                        return true;
                    }
                },
                "onChange": (_this) => {
                    _this.changeMap["ApBsSSName"] = _this.changeMap["ApBsS1Name"];
                    _this.changeMap["ApBsSSIDIndex"] = "1";
                    delete _this.changeMap["ApBsS1Name"];
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                    _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                    _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value;
                },
                "validate": (value) => {
                    if (value.length == 0) {
                        return "EmptyUsernameError";
                    }
                    if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                        return "InvalidUsernameError";
                    }
                },
                "onSubmit": (_this) => {
                    _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value;
                    delete _this.changeMap["ApBsS1Name"]
                }
            },
            /* Wireless Security Type - DROPDOWN */
            "ApBsS1SecurityType": {
                "type": "dropdown",
                "value": "3",
                "label": "SecurityType",
                "info": "",
                "display": "0",
                "options": {
                    "Open": "0",
                    "WPA2 PSK": "3"
                },
                "dependencies": ["ApBsS1Enable"],
                "dependencyMap": {
                    "0": "2"
                },
                "onChange": (_this) => {
                    _this.changeMap["ApBsSSSecurityType"] = _this.changeMap["ApBsS1SecurityType"]
                    _this.changeMap["ApBsSSIDIndex"] = "1"
                    delete _this.changeMap["ApBsS1SecurityType"]
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value
                    _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value
                    _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value
                },
                "onSubmit": (_this) => {
                    _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value
                    delete _this.changeMap["ApBsS1SecurityType"]
                }
            },
            /* Password Phrase - INPUT PASSWORD */
            "ApBsS1Passwd": {
                "type": "password",
                "value": "",
                "label": "PasswordPhrase",
                "info": "",
                "display": "0",
                "dependencies": ["ApBsS1SecurityType", "ApBsS1Enable"],
                "dependencyMap": {
                    "00": "1",
                    "01": "1",
                    "30": "2"
                },
                "preValidate": (value) => {
                    if (value.length < 64) {
                        return true;
                    }
                },
                "onChange": (_this) => {
                    _this.changeMap["ApBsSSPasswd"] = _this.changeMap["ApBsS1Passwd"];
                    _this.changeMap["ApBsSSIDIndex"] = "1";
                    delete _this.changeMap["ApBsS1Passwd"];
                    _this.changeMap["ApBsSSEnable"] = _this.state.settings["ApBsS1Enable"].value;
                    _this.changeMap["ApBsSSName"] = _this.state.settings["ApBsS1Name"].value;
                    _this.changeMap["ApBsSSSecurityType"] = _this.state.settings["ApBsS1SecurityType"].value;
                },
                "validate": (value) => {
                    if (value.length === 0) {
                        return "EmptyPasswordError";
                    }
                    if (value.length < 8 || value.length > 63) {
                        return "InvalidLocalWifiPasswordLengthError"
                    }
                    if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                        return "InvalidLocalWiFiAPPasswordErr";
                    }
                },
                "onSubmit": (_this) => {
                    _this.changeMap["ApBsSSPasswd"] = _this.state.settings["ApBsS1Passwd"].value;
                    delete _this.changeMap["ApBsS1Passwd"]
                }
            },
            /* ScreenBeam Local Wi-Fi - RADIO BUTTON - THIS IS USED IN USB PROVISIONING*/
            "ApBsSSEnable": {
                "type": "radioButton",
                "label": "ScreenBeamLocalWifi",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "onChange": (_this) => {
                    if (_this.state.settings["ApBsSSEnable"].value == "0")
                        _this.state.settings["showWiFiQREnable"].display = "2"
                    else _this.state.settings["showWiFiQREnable"].display = "0"
                }
                // "onChange": (_this) => {
                //     if (_this.state.settings["ApBsSSEnable"].value === "0") {
                //         if (_this.state.settings["networkInterfaceAllowed"].value === "3") {
                //             SettingHelpers.popupConfirmAlertDialog(/*_this, "ApBsS1Enable",*/ "DisableLocalWIFIWarning", () => { this.handleWarningClickedNo(_this, "ApBsSSEnable") }, null);
                //         }
                //     }
                //     SettingHelpers.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                // }
            },
            "ApBsSSIDIndex": {
                "value": "1"
            },
            /* Wireless Network Name - INPUT TEXT - THIS IS USED IN USB PROVISIONING*/
            "ApBsSSName": {
                "type": "input",
                "label": "WirelessNetworkName",
                "value": "",
                "info": "",
                "display": "0",
                "dependencies": ["ApBsSSEnable"],
                "dependencyMap": {
                    "0": "2"
                },
                "preValidate": (value) => {
                    if (value.length <= 32) {
                        return true;
                    }
                },
                "validate": (value) => {
                    if (value.length == 0) {
                        return "EmptyUsernameError";
                    }
                    if (!(/^[a-zA-Z0-9\s@#$_-]+$/.test(value))) {
                        return "InvalidUsernameError";
                    }
                }
            },
            /* Wireless Security Type - DROPDOWN - THIS IS USED IN USB PROVISIONING*/
            "ApBsSSSecurityType": {
                "type": "dropdown",
                "value": "3",
                "label": "SecurityType",
                "info": "",
                "display": "0",
                "options": {
                    "Open": "0",
                    "WPA2 PSK": "3"
                },
                "dependencies": ["ApBsSSEnable"],
                "dependencyMap": {
                    "0": "2"
                }
            },
            /* Password Phrase - INPUT PASSWORD - THIS IS USED IN USB PROVISIONING*/
            "ApBsSSPasswd": {
                "type": "password",
                "value": "",
                "label": "PasswordPhrase",
                "info": "",
                "display": "0",
                "dependencies": ["ApBsSSSecurityType", "ApBsSSEnable"],
                "dependencyMap": {
                    "00": "1",
                    "01": "1",
                    "30": "2"
                },
                "preValidate": (value) => {
                    if (value.length < 64) {
                        return true;
                    }
                },
                "validate": (value, _this) => {
                    // If the security type is WPA2 PSK, we need to validate the password. Otherwise, we ignore it.
                    if (_this.state.settings["ApBsSSSecurityType"].value == "3") {
                        if (value.length === 0) {
                            return "EmptyPasswordError";
                        }
                        if (value.length < 8 || value.length > 63) {
                            return "InvalidLocalWifiPasswordLengthError"
                        }
                        if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                            return "InvalidLocalWiFiAPPasswordErr";
                        }
                    }
                },
            },
            /* Wireless Channel - DROPDOWN */
            "ApBsChannelNum": {
                "type": "dropdown",
                "value": "165",
                "label": "WirelessChannel",
                "info": "",
                "display": "2",
                "options": {
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
            },
            "showWiFiQREnable": {
                "type": "radioButton",
                "label": "ShowWifiQRCode",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "dependencies": ["ApBsS1Enable"],
                "dependencyMap": {
                    "0": "2"
                }
            },
            /* Central Management System Settings - Title */
            "CentralMgtSystemTitle": {
                "type": "title",
                "title": "CentralManagementSystemSettings",
                "display": "0"
            },
            /* CMS Server - INPUT TEXT */
            "mgtSrvFullAddr": {
                "type": "input",
                "value": "",
                "label": "CmsServer",
                "info": "CmsServerInfo",
                "display": "2",
                "validate": (value) => {
                    return SettingHelpers.check_cms_host(value);
                }
            },
            /* CMS Communication Port - INPUT TEXT */
            "mgtSrvPort": {
                "type": "input",
                "value": "",
                "label": "CmsCommunicationPort",
                "info": "CmsCommunicationPortInfo",
                "display": "2",
                "preValidate": (value) => {
                    if (/^[0-9]*$/.test(value)) {
                        return true
                    }
                },
                "validate": (value) => {
                    return SettingHelpers.check_cms_port(value);
                }
            },
            /* Web Communication Port - INPUT TEXT */
            "webServerPort": {
                "type": "input",
                "value": "",
                "label": "WebCommunicationPort",
                "info": "WebCommunicationPortInfo",
                "display": "0",
                "preValidate": (value) => {
                    if (/^[0-9]*$/.test(value)) {
                        return true
                    }
                },
                "validate": (value) => {
                    return SettingHelpers.check_web_port(value);
                }
            },
            /* Local Management Wireless Access Settings Title - TITLE */
            "LocalMgtWirelessAccessTitle": {
                "type": "title",
                "title": "LocalManagementWirelessAccess",
                "display": "0"
            },
            /* Local Management Interface Access - DROPDOWN */
            "webMgtOverWiFiEn": {
                "type": "dropdown",
                "label": "LocalManagementInterfaceAccess",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Auto": "2",
                    "Enable": "1",
                    "Disable": "0"
                },
                "onStart": (settings, data) => {
                    var tempInfo = "";
                    switch (settings["webMgtOverWiFiEn"].value) {
                        case "0":
                            {
                                tempInfo = "LocalManagementInterfaceAccessDisableInfo";
                            }
                            break;
                        case "1":
                            {
                                tempInfo = "LocalManagementInterfaceAccessEnableInfo";
                            }
                            break;
                        case "2":
                            {
                                tempInfo = "LocalManagementInterfaceAccessAutoInfo";
                            }
                            break;
                        default: break;
                    }
                    settings["webMgtOverWiFiEn"].info = tempInfo;
                    return settings["webMgtOverWiFiEn"];
                },
                "onChange": (_this) => {
                    var tempInfo = "";
                    switch (_this.state.settings["webMgtOverWiFiEn"].value) {
                        case "0":
                            {
                                tempInfo = "LocalManagementInterfaceAccessDisableInfo";
                            }
                            break;
                        case "1":
                            {
                                tempInfo = "LocalManagementInterfaceAccessEnableInfo";
                            }
                            break;
                        case "2":
                            {
                                tempInfo = "LocalManagementInterfaceAccessAutoInfo";
                            }
                            break;
                        default: break;
                    }
                    SettingHelpers.setSettingState(_this, "webMgtOverWiFiEn", "info", tempInfo);
                }
                //"type": "dropdown",
                //"value": "",
                //"label": "Local Management Interface Access",
                //"info": "",
                //"disabled": false,
                //"options": [
                //    { "value": "2", "label": "Auto" },
                //    { "value": "1", "label": "Enable" },
                //    { "value": "0", "label": "Disable" }
                //]
            },
            /* Network Interface Allowed - DROPDOWN */
            "networkInterfaceAllowed": {
                "type": "dropdown",
                "label": "NetworkInterfaceAllowed",
                "info": "NetworkInterfaceAllowedInfo",
                "display": "0",
                "value": "1",
                "options": {
                    "Any": "0",
                    "EthernetLAN": "1",
                    "WirelessLAN": "2",
                    "LocalWi-FiAP": "3",
                    "MiracastP2P": "4"
                },
                "dependencies": ["webMgtOverWiFiEn"],
                "dependencyMap": {
                    "0": "1"
                },
                "onStart": (settings, data) => {
                    SettingHelpers.ChangeLMIInterfaceAllowedList(null, settings, true, data);
                    return settings["networkInterfaceAllowed"];
                },
            },
            /* Allow Force Disconnection - RADIO BUTTON */
            /*                "hijackingEn": {
                                "type": "radioButton",
                                "label": "AllowForceDisconnection",
                                "info": "AllowForceDisconnectionInfo",
                                "display": "0",
                                "value": "0",
                                "options": {
                                    "Enable": "1",
                                    "Disable": "0"
                                }
                            },*/ //1100 change to drop menu
            /* PCR Synchronization - RADIO BUTTON */
            "PCRSyncEnable": {
                "type": "radioButton",
                "label": "PcrSync",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* HDCP Encryption - DROPDOWN */
            "HDCPEncrypMode": {
                "type": "dropdown",
                "label": "HdcpEncryption",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "0",
                    "Disable": "1",
                    "DemoMode": "2"
                },
            },
            /* Aspect Ratio - DROPDOWN */
            "aspectRatio": {
                "type": "dropdown",
                "label": "AspectRatio",
                "info": "",
                "display": "2",
                "value": "1",
                "options": {
                    "4:3": "0",
                    "16:9": "1",
                    "16:10": "2"
                }
            },
            /* Horizontal/Veritcal Offset - RATIO */
            "scrHoffset": {
                "type": "ratio",
                "value": "0",
                "display": "2",
                "horizontalName": "scrHoffset",
                "verticalName": "scrVoffset",
                "label": "HorizontalVerticalOffset",
                "onChange": (_this) => {
                    _this.changeMap["scrVoffset"] = _this.state.settings["scrVoffset"].value;
                }
            },
            "scrVoffset": {
                "value": "0",
                "onChange": (_this) => {
                    _this.changeMap["scrHoffset"] = _this.state.settings["scrHoffset"].value;
                }
            },
            /* Debug View - RADIO BUTTON */
            "debugViewEnable": {
                "type": "radioButton",
                "label": "DebugView",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "On": "1",
                    "Off": "0"
                }
            },
            /* P2P IP Address - INPUT IP */
            "p2pIpAddr": {
                "type": "ip",
                "label": "P2PIpAddress",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                    _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                    _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                }
            },
            /* P2P Subnet Mask - INPUT IP */
            "p2pSubnet": {
                "type": "ip",
                "label": "P2PSubnetMask",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                    _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;
                    _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                }
            },
            /* P2P DHCP IP Start - INPUT IP */
            "p2pDhcpIpStart": {
                "type": "ip",
                "label": "P2PDhcpIpStart",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                    _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                    _this.changeMap["p2pDhcpIpEnd"] = _this.state.settings["p2pDhcpIpEnd"].value;

                }
            },
            /* P2P DHCP IP End - INPUT IP */
            "p2pDhcpIpEnd": {
                "type": "ip",
                "label": "P2PDhcpIpEnd",
                "info": "",
                "value": "0.0.0.0",
                "display": "0",
                "onChange": (_this) => {
                    _this.changeMap["p2pIpAddr"] = _this.state.settings["p2pIpAddr"].value;
                    _this.changeMap["p2pSubnet"] = _this.state.settings["p2pSubnet"].value;
                    _this.changeMap["p2pDhcpIpStart"] = _this.state.settings["p2pDhcpIpStart"].value;

                }
            },
            /* P2P Wireless Network Name (SSID) - Special INPUT */
            "p2pSsid": {
                "type": "postfixInput",
                "label": "P2PWirelessNetworkName",
                "prefix": "DIRECT-xy",
                "info": "",
                "display": "0",
                "preValidate": (value, _this) => {
                    let prefixLength = _this.state.settings["p2pSsid"].prefix.length
                    if ((value.length - prefixLength) > 23) {
                        return false
                    }
                    return true
                },
                "validate": (value, _this) => {
                    if (!(/^[a-zA-Z0-9\s-]+$/.test(value))) {
                        return "InvalidNetworkNameError";
                    }
                }
            },
            /* P2P Wireless Network Password - INPUT PASSWORD */
            "p2pPassword": {
                "type": "password",
                "label": "P2PWirelessPassword",
                "info": "",
                "value": "",
                "display": "0",
                "preValidate": (value, _this) => {
                    if (value.length > 24) {
                        return false
                    }
                    return true
                },
                "validate": (value) => {
                    if (value.length < 8 || value.length > 24) {
                        return "InvalidP2PPasswordLengthError"
                    }
                    if (!(/^[a-zA-Z0-9-]+$/.test(value))) {
                        return "InvalidLocalWiFiAPPasswordErr";
                    }
                }
            },
            /* Fast-IP - RADIO BUTTON */
            "fastIPEn": {
                "type": "radioButton",
                "label": "FastIp",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* AGO beacon broadcast - RADIO BUTTON */
            "txAgoBcnEnable": {
                "type": "radioButton",
                "label": "AGOBeaconBroadcast",
                "info": "",
                "display": "1",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            ///* User Input Back Channel (UIBC) - DROPDOWN */
            "UIBCEnable": {
                "type": "dropdown",
                "value": "0",
                "label": "Uibc",
                "info": "",
                "display": "0",
                "options": {
                    "Enable": "0",
                    "Disable": "1"
                }
            },
            ///* HDMI-CEC - RADIO BUTTON*/
            "CECEnable": {
                "type": "radioButton",
                "label": "HdmiCec",
                "info": "",
                "value": "0",
                "display": "0",
                // "disabled": false,
                "options": {
                    "On": "1",
                    "Off": "0"
                }
            },
            /* Firmware Upgrade - RADIO BUTON */
            "fwupdateAccess": {
                "type": "radioButton",
                "label": "FirmwareUpgradeUsb",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Miracast Connection - RADIO BUTTON */
            "miracastConnEnable": {
                "type": "radioButton",
                "label": "MiracastConnection",
                "info": "MiracastConnectionEnInfo",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                },
                "onStart": (settings, data) => {
                    settings["miracastConnEnable"].info = "MiracastConnectionEnInfo";
                    if (settings["miracastConnEnable"].value === "0") {
                        settings["miracastConnEnable"].info = "MiracastConnectionDisInfo";
                    }
                    return settings["miracastConnEnable"];
                },
                "onChange": (_this) => {
                    var strInfo = "MiracastConnectionEnInfo";
                    if (_this.state.settings["miracastConnEnable"].value === "0") {
                        strInfo = "MiracastConnectionDisInfo";
                        if (_this.state.settings["networkInterfaceAllowed"].value === "4") {
                            SettingHelpers.popupConfirmAlertDialog(/*_this, "miracastConnEnable",*/ "DisableMiracastConnWarning", () => { this.handleWarningClickedNo(_this, "miracastConnEnable") }, null);
                        }
                    }
                    //this.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                    SettingHelpers.setSettingState(_this, "miracastConnEnable", "info", strInfo, () => {
                        SettingHelpers.ChangeLMIInterfaceAllowedList(_this, _this.state.settings, false, null);
                    });
                }
            },
            /* P2P Operating Channel - DROPDOWN */
            "autoGoChannel": {
                "type": "dropdown",
                "label": "P2POperatingChannel",
                "info": "P2POperatingChannelInfo",
                "value": "165",
                "display": "0",
                "options": {
                    "36": "36",
                    "40": "40",
                    "44": "44",
                    "48": "48",
                    "149": "149",
                    "153": "153",
                    "157": "157",
                    "161": "161",
                    "165": "165"
                }
                //"region": "clWiFiRegionCode",
                //"country": "clWiFiCountryCode",
                //"wifi20Channels": "clWiFi20Channels",
                //"wifi40Channels": "clWiFi40Channels",
                //"wifi80Channels": "clWiFi80Channels"
            },
            /* USB Auto Config - RADIO BUTTON */
            "usbConfigEn": {
                "type": "radioButton",
                "label": "UsbAutoConfigure",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /************************ New features in 11.0.8.0 ****************************/
            /* Commander Mode
            * Hide when Wireless Display Mode (WiFiOperateMode) is Standard Wireless Display (Value = 5)
            */
            "ccChromeEnable": {
                "type": "dropdown",
                "label": "CommanderMode",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "ForWindows": "0",
                    "ForChromebook": "1"
                },
                "dependencies": ["WiFiOperateMode"],
                "dependencyMap": {
                    "5": "1"
                }
            },
            /* Show "Help URL" instructions */
            "showHelpUrlEnable": {
                "type": "radioButton",
                "label": "ShowHelpUrlInstructions",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Show": "1",
                    "Hide": "0"
                }
            },
            /* Screenbeam logo */
            "showSBLogoEnable": {
                "type": "radioButton",
                "label": "ScreenBeamLogo",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Show": "1",
                    "Hide": "0"
                }
            },
            /* Ghost Inking */
            "ghostInkEnable": {
                "type": "radioButton",
                "label": "GhostInking",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Ghost Inking opacity level */
            "ghostInkOpacity": {
                "type": "dropdown",
                "label": "GhostInkingOpacityLevel",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Light": "0",
                    "Dark": "1"
                },
                "dependencies": ["ghostInkEnable"],
                "dependencyMap": {
                    "0": "1"
                }
            },
            /* Debug Setting title */
            "DebugSettingTitle": {
                "type": "title",
                "title": "DebugSettingTitle",
                "display": "0"
            },
            /* Debug Log output */
            "debugLogOutputEnable": {
                "type": "radioButton",
                "label": "DebugLogOutput",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Debug Setting title */
            "DebuggingTitle": {
                "type": "title",
                "title": "DebuggingTitle",
                "display": "0"
            },
            /* SSH Debug Console */
            "sshServerEnable": {
                "type": "radioButton",
                "label": "SshDebugConsole",
                "info": "",
                "display": "0",
                "value": "0",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* SSH Communication Port */
            "SSHComPort": {
                "type": "input",
                "value": "22",
                "label": "SshCommunicationPort",
                "info": "SshCommunicationPortInfo",
                "display": "0",
                "dependencies": ["sshServerEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "preValidate": (value) => {
                    if (/^[0-9]*$/.test(value) && value < 65535) {
                        return true
                    }
                },
                "validate": (value) => {
                    return SettingHelpers.check_ssh_port(value);
                }
            },
            /* ScreenBeam MAC Address */
            "RxMacAddr": {
                "type": "input",
                "value": "",
                "label": "RxMacAddr",
                "info": "RxMacAddrInfo",
                "display": "2",
                "dependencies": ["sshServerEnable"],
                "dependencyMap": {
                    "0": "1"
                },
                "onStart": (settings, data) => {
                    settings["RxMacAddr"].value = data["RxMacAddr"]
                    return settings["RxMacAddr"]
                }
            },
            /* Push Button */
            "PushButtonEn": {
                "type": "radioButton",
                "label": "PushButton",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            /* Airplay Discovery Broadcast */
            "BluetoothSettingTitle": {
                "type": "title",
                "title": "BluetoothSetting",
                "display": "0"
            },
            "iBeaconEnable": {
                "type": "radioButton",
                "label": "AirplayDiscoveryBroadcast",
                "info": "",
                "display": "0",
                "value": "1",
                "options": {
                    "Enable": "1",
                    "Disable": "0"
                }
            },
            "BackgroundCustom": {
                "type": "file",
                "acceptFile": "image/png, image/jpeg, image/jpg",
                "fileName": "background.png",
                "label": "SetBackground",
                "info": "BackgroundUrlInfo",
                "value": ""
            },
            "ScreensaverCustom": {
                "type": "file",
                "acceptFile": "image/png",
                "fileName": "scrsaver.png",
                "label": "SetScreenSaver",
                "info": "ScreenSaverUrlInfo",
                "value": ""
            }
        }
    }
    static getAllSettings = () => {
        var settings = {}
        settings["SingleRxSettings"] = this.getSingleRxSettings()
        settings["MultipleRxSettings"] = this.getMultipleRxSettings()
        settings["MultipleRxSettingsForUSB"] = this.getUSBProvisioningSettings()
        settings["AllSettings"] = this.getAllConfigParameters()
        return settings
    }
}

export default Settings_In_11_1