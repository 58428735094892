import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as userActions } from '../../../store/User'
import ModalFooter from '../ModalFooter'
import {Translate} from 'react-localize-redux'

class Logout extends React.Component {
    render() {
        return (     
            <div>
                <div className='modal-body'>
                    {this.props.isModal &&  <div>
                        <Translate id="LogoutConfirm"/>
                    </div>
                    }               
                </div>
                {this.props.isModal && <ModalFooter button2Submit={()=>{this.props.logout();this.props.hideModal()}}/>}               
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...modalActions, ...userActions }, dispatch)
)(Logout);