import React from 'react';
import { Route,Redirect,Switch } from 'react-router';
import { bindActionCreators } from 'redux';
import {actionCreators as signalRActions} from './store/SignalR';
import { actionCreators as siteActions } from './store/Sites'
import { actionCreators as receiverActions} from './store/Receiver'
import { connect } from 'react-redux';
import Layout from './components/Layout';
import Dashboard from './Pages/Dashboard/Dashboard'
import ReceiverPage from './Pages/ReceiverPage/ReceiverPage'
import Navbar from './components/Bars/Navbar'
import Sidebar from './components/Bars/Sidebar'
import Modal from './components/Modals/Modal'
import Scheduler from './Pages/Scheduler/Scheduler'
import ReceiverLogs from './Pages/ReceiverLog/ReceiverLogPage'
import SchedulerLogs from './Pages/SchedulerLog/SchedulerLogPage'
import  { actionCreators as FeatureToggleActions} from './store/FeatureToggle'

// import Logs from './Pages/Logs/LogsPage'
import Members from './Pages/Members/Members'


class HomeContainer extends React.Component{

    state={
        sidebarActive: true
    }

    componentDidMount(){
        if(this.props.user.username != ""){
            this.props.connect()
            this.props.getFeatureToggle()
        }
    }

    onClick = (event) => {
        if(event.target.className.includes("deselect-trigger")){
            this.props.deselectSites()
            this.props.deselectReceivers()
        }
    }
    render(){
        if (this.props.user.username == ""){
            return(
                <Redirect to='/Login' />
            )
        }
        return(
            <div onClick={this.onClick}>
                <Layout>
                    <Navbar />
                    <Sidebar />
                    <Switch>
                        <Route exact path ='/Receivers' component={ReceiverPage} />
                        <Route exact path ='/Scheduler' component={Scheduler} />
                        <Route exact path ='/Logs' component={ReceiverLogs}/>
                        <Route exact path ='/Logs/ServerBusy' component={ReceiverLogs}/>
                        {/* <Route exact path ='/Logs' component={Logs}/> */}
                        <Route exact path ="/Admin/MemberManagement" component={Members}/>
                        <Route path ="/SchedulerLogs" component={SchedulerLogs}/>
                        <Route path ="/SchedulerLogs/ServerBusy" component={SchedulerLogs}/>
                        <Route path ='/' component={Dashboard}  />
                    </Switch>
                    <Modal />
                </Layout>
            </div>
        )
    }
}


    



export default connect(
    state => ({user: state.user, signalR: state.signalR}),
    dispatch => bindActionCreators({...receiverActions,...siteActions,...signalRActions,...FeatureToggleActions}, dispatch)
  )(HomeContainer);