// /5/13/2020 - Removed ethAuthentication: aei_config_ethAuthentication,  "ethRadiusID": "aei_config_ethRadiusID",
//    "ethRadiusPWD": "aei_config_ethRadiusPWD",    "primaryDns": "aei_config_ethPrimaryDns",    "secondaryDns": "aei_config_ethSecondaryDns", "overScanVal": "aei_config_OverscanVal",
  


var AeiConfig = {
    "AirplayMirroringOnlyEn": "aei_config_AirplayMirroringOnly",
    "ApBsSSEnable": "aei_config_apBsSSEnable",
    "ApBsSSIDIndex": "aei_config_apBsSSIDIndex",
    "ApBsSSName": "aei_config_apBsSSName",
    "ApBsSSPasswd": "aei_config_apBsSSPasswd",
    "ApBsSSSecurityType": "aei_config_apBsSSSecurityType",
    "BackgroundCustom": "aei_config_BackgroundCustom",
    "BlockLanIpEnable": "aei_config_BlockLanIpEnable",
    "ChannelBandWidth": "aei_config_channelbandwidth",
    "CurConnectApName": "aei_config_connectApName",
    "CurConnectApPwd": "aei_config_connectApPasswd",
    "CurConnectApRadiusId": "aei_config_connectApRadiusId",
    "CurConnectApSecType": "aei_config_connectApSecType",
    "CurEthAuthentication": "aei_config_ethAuthentication",
    "CurEthRadiusID": "aei_config_ethRadiusID",
    "CurEthRadiusPWD": "aei_config_ethRadiusPWD",
    "DNSPolicy": "aei_config_ethDnsPolicy",
    "EthNetworkName": "aei_config_EthNetworkName",
    "ForceEdidM3En": "aei_config_ForceEdidM3En",
    "GoogleCastEnable": "aei_config_googleCastEnable",
    "HDCPEncrypMode": "aei_config_hdcpEncryption",
    "InactivityRebootTime": "aei_config_InactivityRebootTime",
    "MacInfracastEn": "aei_config_macinfracastEnable",
    "NGOListenChan": "aei_config_NGOListenChannel",
    "NetworkMode": "aei_config_networkMode",
    "PCRSyncEnable": "aei_config_PCRSyncEnable",
    "PINDisplayPeriod": "aei_config_PINDisplayPeriod",
    "RMIWatchdogEn": "aei_config_RMIWatchdogEnable",
    "RMIWatchdogSec": "aei_config_RMIWatchdogTimer",
    "RXHostname": "aei_config_hostName",
    "RxCsvLogEn": "aei_config_RxCsvLogEnable",
    "RxName": "aei_config_ReceiverName",
    "RxNameAccess": "aei_config_ReceiverNameAccess",
    "VGACompMode": "aei_config_vgaCompatibilityMode",
    "VGADefaultFormat": "aei_config_vgaDefaultFormat",
    "WiFiOperateMode": "aei_config_wifiOperateMode",
    "airPlayEn": "aei_config_airPlayEnable",
    "apBridgeInterface": "aei_config_apBridgeInterface",
    "aspectRatio": "aei_config_aspectRatio",
    "autoGoChannel": "aei_config_autoGoChannel",
    "autonomousGoEn": "aei_config_autonomousGoEnable",
    "p2pDhcpIpEnd": "aei_config_p2pIpDhcpEnd",
    "p2pDhcpIpStart": "aei_config_p2pIpDhcpStart",
    "p2pIpAddr": "aei_config_p2pIpAddr",
    "p2pSubnet": "aei_config_p2pIpSubnetMask",
    "broadcastWirelessSsid": "aei_config_broadcastWirelessSsid",
    "ccMode": "aei_config_ccMode",
    "debugBitmap": "aei_config_debugEnableBitmap",
    "defaultGateway": "aei_config_ethGateWay",
    "digitalSignDelaySec": "aei_config_digitalSignDelaySec",
    "digitalSignEn": "aei_config_digitalSignEn",
    "digitalSignFullScreenEn": "aei_config_digitalSignFullScreenEn",
    "digitalSignUrl": "aei_config_digitalSignUrl",
    "digitalSignAudioVol": "aei_config_digitalSignAudioVol",
    "dstTimeEnable": "aei_config_timezone_dst_enable",
    "ethAutoDnsEnable": "aei_config_ethAutoDnsEnable",
    "ethAutoIpEnable": "aei_config_ethAutoIpEnable",
    "ethGateway": "aei_config_ethGatewayIp",
    "ethInnerAuth": "aei_config_ethInnerAuth",
    "ethIpAddress": "aei_config_ethIpAddress",
    "ethIpPolicy": "aei_config_ethIpPolicy",
    "ethDhcpEnable": "aei_config_ethIpPolicy",
    "ethIpaddr": "aei_config_ethIpAddr",
    "ethNetmask": "aei_config_ethSubnetMask",
    "ethPrimaryDns": "aei_config_ethPrimaryDns",
    "ethSecondaryDns": "aei_config_ethSecondaryDns",
    "ethSubnet": "aei_config_ethNetMask",
    "fourDigitPINEn": "aei_config_4digitPINEnable",
    "fwupdateAccess": "aei_config_fwUpgradeAccess",
    "hideProductLogoEn": "aei_config_hideProductLogoEnable",
    "hideReadyInfoEnable": "aei_config_hideGuiReadyInfoEnable",
    "hijackingEn": "aei_config_hijackingEnable",
    "infracastEnable": "aei_config_infracastEnable",
    "language": "aei_config_Language",
    "mgtSrvFullAddr": "aei_config_remoteMgtSvrIpAddr",
    "mgtSrvPort": "aei_config_remoteMgtSvrPort",
    "networkInterfaceAllowed": "aei_config_networkInterfaceAllowed",
    "newOverScanVal": "aei_config_OverscanVal",
    "overlayRxNamePlacement": "aei_config_overlayRxNamePlacement",
    "p2pDrvResetEnable": "aei_config_p2pDrvResetEnable",
    "p2pNewSsidPrefix": "aei_config_p2pSsid",
    "p2pNewSsidUserDef": "aei_config_p2pNewSsidUserDef",
    "pinEachConnEnable": "aei_config_eachConnPINEnable",
    "pinEnforceEnable": "aei_config_PINEnforceEnable",
    "preOpChannelList": "aei_config_preOpChannelList",
    "primaryDNS": "aei_config_ethDnsPrimary",
    "remoteMgtWifiBand": "aei_config_remoteMgtWifiBand",
    "rtOverscanEnable": "aei_config_rtOverscanControl",
    "scrHDMIOutEnable": "aei_config_HDMIOutEnable",
    "scrHoffset": "aei_config_horizontalOffset",
    "scrSaverEnable": "aei_config_ScrSaveEnable",
    "scrSaverSecond": "aei_config_ScreenSaveSec",
    "scrVoffset": "aei_config_verticalOffset",
    "secondDNS": "aei_config_ethDnsSecondary",
    "showConnectWiFiEnable": "aei_config_showConnectWiFiEnable",
    "showMoreInfoEnable": "aei_config_showNetInfoOnTv",
    "showOverlayRxNameOpt": "aei_config_showOverlayRxNameOpt",
    "staAutoDnsEnable": "aei_config_staAutoDnsEnable",
    "staAutoIpEnable": "aei_config_staAutoIpEnable",
    "staGateway": "aei_config_staGateway",
    "staIpAddress": "aei_config_staIpAddress",
    "staNetmask": "aei_config_staNetmask",
    "staPrimaryDns": "aei_config_staPrimaryDns",
    "staSecondaryDns": "aei_config_staSecondaryDns",
    "staticPinEnable": "aei_config_StaticPINEnable",
    "staticPinVal": "aei_config_StaticPINCode",
    "timezoneItem": "aei_config_timezone_item",
    "txAgoBcnEnable": "aei_config_txAgoBcnEnable",
    "txPowerItem": "aei_config_txPowerItem",
    "usbConfigEn": "aei_config_usbConfigEnable",
    "usbUibcEnable": "aei_config_usbUibcEnable",
    "UIBCEnable":"aei_config_usbUibcEnable",
    "wakedByScan": "aei_config_wakedByScan",
    "webMgtOverWiFiEn": "aei_config_webMgtOverWiFiEn",
    "webPassword": "aei_config_loginAdminPassword",
    "webServerPort": "aei_config_webServerPort",
    "webUserName": "aei_config_loginAdminUsername",
    "wirelessNetworkName": "aei_config_wirelessNetworkName",
    "wirelessNetworkPwd": "aei_config_wirelessNetworkPwd",
    "CECEnable": "aei_config_CECEnable",
    "VideoMaxResolution": "aei_config_videoMaxResolution",
    "AudioSpeakerChs": "aei_config_audioSpeakerChs",
    "infracastPinEn": "aei_config_infracastPinEnable",
    "DTLSSupport": "aei_config_DTLSSupport",
    "infracastIPEnable": "aei_config_infracastIPEnable",
    "ApName": "aei_config_wirelessNetworkName",
    "ApPassword": "aei_config_wirelessNetworkPwd",
    "LMIBeaconEnable": "aei_config_broadcastWirelessSsid",
    "UOIPEnable": "aei_config_usbUoipEnable",
    "usbLocalAccessCtrl": "aei_config_usbLocalAccess",
    "p2pInterfaceEnable": "aei_config_p2pInterfaceEnable",
    "webMgtOverLanEn": "aei_config_webMgtOverLanEnable",
    "dynAgoPassphraseEn": "aei_config_dynAgoPassphraseEnable",
    "undiscoverRebootEn": "aei_config_undiscoverRebootEnable",
    "switchP2pDongle": "aei_config_switchP2pDongle",
    "fastIPEn": "aei_config_fastIPEnable",
    "ApBsDisableEapolKeyRetries": "aei_config_apBsDisableEapolKeyRetries",
    "debugViewEnable": "aei_config_debugViewEnable",
    "miracastConnEnable": "aei_config_miracastConnEnable",
    "ccChromeEnable": "aei_config_ccChromeEnable",
    "showHelpUrlEnable": "aei_config_showHelpUrlEnable",
    "showSBLogoEnable": "aei_config_showSBLogoEnable",
    "ghostInkEnable": "aei_config_ghostInkEnable",
    "ghostInkOpacity": "aei_config_ghostInkOpacity",
    "debugLogOutputEnable": "aei_config_debugLogOutputEnable",
    "PushButtonEn": "aei_config_pushButtonEnable",
    "cmsInterface": "aei_config_cmsInterface",
    "ScreensaverCustom": "aei_config_ScrSaveCustom",
    "showWiFiQREnable": "aei_config_showWiFiQREnable",
    "iBeaconEnable": "aei_config_iBeaconEnable",
    "NTPServerConfigUrl": "aei_config_NTPServerConfigUrl",
    "sshServerEnable": "aei_config_sshServerEnable",
    "SSHComPort": "aei_config_SSHComPort"
}
module.exports = {
    AeiConfig: AeiConfig
}