import React from 'react'
import Sites from './Sites'
import { actionCreators as modalActions } from '../../store/Modal'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class SitesCard extends React.Component {

    render() {
        return (
            <div className="card-wrapper scroll-parts site-list-scroll-height">
                <Sites isScheduler={this.props.isScheduler}/>
                {/* <div id="AddSiteButton" onClick={()=>{this.props.createModal("Add Site")}} ><img  src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" /></div> */}
                {(!this.props.isScheduler && (this.props.isSystemAdmin == true || this.props.isSystemAdmin == "true")) &&
                <div className="add-site d-flex justify-content-end">
                    <div className="circle" onClick={() => { this.props.createModal("Add Site") }} ></div>
                </div>
                }
            </div>
        )
    }
}

export default connect(
    state => state.user,
    dispatch => bindActionCreators({ ...modalActions }, dispatch)
)(SitesCard);
