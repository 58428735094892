import React from 'react';
import { Translate } from 'react-localize-redux'

class ModalOperatingChannel extends React.Component {
    constructor(props) {
        super(props)
    }

    handleChange = (e) => {
        let array = this.props.value
        let val = e.target.value;
        let isChecked = e.target.checked;
        if (isChecked) {
            array.push(val)
        } else {
            const index = array.indexOf(val);
            if (index > -1) {
                array.splice(index, 1);
            }
        }
        let event = {
            target: {
                value: array,
                name: e.target.name
            }
        };
        this.props.onChange(event);
    }

    handleAllCheckboxChange = (e) => {
        let isChecked = e.target.checked
        var items = []        
        if (isChecked) {
            for (var key of this.props.opChannelList) {
                items.push(key)
            }            
        }
        let event = {
            target: {
                value: items,
                name: ""
            }
        }
        this.props.onChange(event);  
    }

    getCheckboxes = () => {
        var len = this.props.opChannelList.length
        var rows = 7
        var cols = len / rows
        var items = []
        for (var i = 0; i < rows; i++) {
            var val = this.props.opChannelList[i]
            items.push(
                <div className="row">
                    {
                        this.displayCheckbox(i, cols, rows)
                    }

                </div>
            )

        }
        return items
    }

    displayCheckbox = (start, cols, increment) => {
        var items = []
        var channelList = this.props.value
        while (cols > 0) {
            var val = this.props.opChannelList[start]
            if (val) {
                var isChecked = channelList.length < 1 ? false : channelList.includes(val)
                items.push(<div className="col-3">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" name={"operatingChannel" + start} disabled={false} className="custom-control-input" id={"checkboxType" + val} value={val} checked={isChecked} onChange={this.handleChange} />
                        <label className="custom-control-label" htmlFor={"checkboxType" + val}>{val}</label>
                    </div>
                </div>)
            }
            start += increment
            cols -= 1
        }
        return items
    }

    render() {
        return (
            <div className="row">
                <label className="modal-label col-sm-4 col-form-label" htmlFor="operating-channel-list">NGO Mode-Allowed P2P Channel</label>
                <div className="col-sm-8">
                    <div className="row col-3">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" name={"operatingChannel-All"} disabled={false} className="custom-control-input" id={"checkboxType" + "All"} value="All" checked={this.props.opChannelList.length == this.props.value.length} onChange={this.handleAllCheckboxChange} />
                            <label className="custom-control-label" htmlFor={"checkboxType" + "All"}>All</label>
                        </div>
                    </div>
                    {this.getCheckboxes()}
                </div>
            </div>
        );
    }
}


export default ModalOperatingChannel