import React from 'react'
import { Translate } from 'react-localize-redux'

class ModalUrlInput extends React.Component {

    render() {
        return (
            <div>
                <div className="form-group row modal-row customize-column-width">
                    <label htmlFor="imageURL" className="modal-label col-sm-4"><Translate id={this.props.label} /></label>
                    <input type="text" className="modal-url-input col-sm-6" id={this.props.name} name={this.props.name} value={this.props
                        .value} onChange={this.props.onChange} />
                    <small id="fileURLHelp" className="info col-sm-12"><Translate id={this.props.info} /></small>
                </div>
                <div className="form-group modal-row">
                    <button className="btn btn-secondary test-url-btn" ref="testUrlBtn" type="button" onClick={this.props.onTestUrlClick} disabled={this.props.disabled}><Translate id="TestUrl" /></button>
                    <small className={"col-sm-10 " + this.props.urlStatusClass} ref="testUrlStatus"><Translate id={this.props.urlStatus} /></small>
                </div>
            </div>
        );
    }
}

export default ModalUrlInput
