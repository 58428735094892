import actionTypes from '../../Utils/actionTypes'
import triggerTypes from '../../Utils/triggerTypes'
import resultTypes from '../../Utils/schedulerResultTypes'
const LANGUAGE = {
    "en": {
        //Actions
        "Settings": "Settings",
        "UpdateFirmware": "Update Firmware",
        "SetScreenSaver": "Set Screensaver",
        "SetBackground": "Set Background",
        "Reboot": "Reboot",
        "ResetToDefault": "Reset",
        "ActionCompleted": "Action Completed Successfully",
        "UploadLogs": "Upload Logs",

        //Triggers
        "Once": "Once",
        "Daily": "Daily",
        "Weekly": "Weekly",
        "Monthly": "Monthly",
        "Minutes": "Minutes",

        //Results
        "Success": "Success",
        "Unsuccessful": "Unsucessful",
        "NoReceivers": "No associated receivers.",
        "NoConnection": "No connection to CMS Hub"
    },
    "zhcn": {
        "Settings": "CN<Settings>",
        "UpdateFirmware": "CN<UpdateFirmware>",
        "SetScreenSaver": "CN<Set Screensaver>",
        "SetBackground": "CN<Set Background>",
        "Reboot": "CN<Reboot>",
        "ResetToDefault": "CN<Reset>",
        "ActionCompleted": "CN<ActionCompleted>",
        "UploadLogs": "CN<UploadLogs>",

        "Once": "CN<Once>",
        "Daily": "CN<Daily>",
        "Weekly": "CN<Weekly>",
        "Monthly": "CN<Monthly>",
        "Minutes": "CN<Minutes>",

        //Results
        "Success": "CN<Success>",
        "Unsuccessful": "CN<Unsucessful>",
        "NoReceivers": "CN<NoReceivers>",
        "NoConnection": "CN<NoConnection>"
    }
}

var ConvertMessageCodeToText = (messageType, code,lang) => {
    switch(messageType) {
        case "policy":
            if (code == actionTypes.Settings) return LANGUAGE[lang]["Settings"]
            if (code == actionTypes.UpdateFirmware) return LANGUAGE[lang]["UpdateFirmware"]
            if (code == actionTypes.SetScreenSaver) return LANGUAGE[lang]["SetScreenSaver"]
            if (code == actionTypes.SetBackground) return LANGUAGE[lang]["SetBackground"]
            if (code == actionTypes.Reboot) return LANGUAGE[lang]["Reboot"]
            if (code == actionTypes.ResetToDefault) return LANGUAGE[lang]["ResetToDefault"]
            if (code == actionTypes.UploadLogs) return LANGUAGE[lang]["UploadLogs"]
            return "Unknown Message Code."
            break;
        case "trigger":
            if (code == triggerTypes.Once) return LANGUAGE[lang]["Once"]
            if (code == triggerTypes.Daily) return LANGUAGE[lang]["Daily"]
            if (code == triggerTypes.Weekly) return LANGUAGE[lang]["Weekly"]
            if (code == triggerTypes.Monthly) return LANGUAGE[lang]["Monthly"]
            if (code == triggerTypes.Minutes) return LANGUAGE[lang]["Minutes"]
            return "Unknown Message Code."
            break;
        case "result":
            if (code == resultTypes.Success) return LANGUAGE[lang]["Success"] 
            if (code == resultTypes.Fail) return LANGUAGE[lang]["Unsuccessful"] 
            if (code == resultTypes.NoConnectionHub) return LANGUAGE[lang]["NoConnection"] 
            if (code == resultTypes.None) return ""
            if (code == resultTypes.NoReceivers) return LANGUAGE[lang]["NoReceivers"]
            return "Unknown Message Code."
            // break;
        default:
            return "Unknown Message Code."
    
    }
}

export default ConvertMessageCodeToText
