import React from 'react'
import SchedulerTable from '../SchedulerTable/SchedulerTable'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Translate} from 'react-localize-redux'

class TabbedTableWrapper extends React.Component {
    render() {
        return (
            <div className="card-wrapper deselect-trigger">
                <div className="card deselect-trigger">
                    <Tabs selectedIndex={this.props.tabIndex} onSelect={this.props.onChangeTab ? (tabIndex => this.props.onChangeTab(tabIndex)) : null}>
                        <TabList className="react-tabs__tab-list deselect-trigger">
                            <Tab><Translate id="Scheduler"/></Tab>
                        </TabList>
                        <TabPanel>
                            <SchedulerTable/>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default TabbedTableWrapper
