import moment from 'moment'

var codeConverter = require('./CodeConverter')

var getVisibility = () => {
    return {
        Status:  sessionStorage.getItem('schedulerTableStatus') == 'false' ? false : true,
        Name: sessionStorage.getItem('schedulerTableName') =='false' ? false : true ,
        Trigger: sessionStorage.getItem('schedulerTableTrigger') == 'false' ? false : true ,
        NextRunTime: sessionStorage.getItem('schedulerTableNextRunTime') == 'false' ? false : true ,
        LastRunTime: sessionStorage.getItem('schedulerTableLastRunTime') == 'false' ? false : true,
        LastRunResult: sessionStorage.getItem('schedulerTableLastRunResult') == 'false' ? false : true,
        Policy: sessionStorage.getItem('schedulerTablePolicy') == 'false' ? false : true,
        ReceiverAmount: sessionStorage.getItem('schedulerTableReceiverAmount') == 'false' ? false : true,
        Description: sessionStorage.getItem('schedulerTableDescription') == 'false' ? false : true,
        Created: sessionStorage.getItem('schedulerTableCreated') == 'false' ? false : true,
        StartDate: sessionStorage.getItem('schedulerTableStartDate') == 'false' ? false: true,
        EndDate:  sessionStorage.getItem('schedulerTableEndDate') == 'false' ? false : true,
        Author: sessionStorage.getItem('schedulerTableAuthor') == 'false' ? false : true,
    }
}


var getConfig = () => {
    var savedVisibility = getVisibility()

    
    //Return configs through a function to create a new instance of configs each time.
    return {
        "info": false,
        "autoWidth": false,
        "dom": "ti",
        "scrollY": "20vh",
        "sScrollX": "100%",
        "scroller": {
            loadingIndicator: true,
        },
        "select": true,
        "columns": [
            {data: "sche_Stat", orderable: false, visible: savedVisibility.Status},
            {data: "sche_Name", visible: savedVisibility.Name},
            {data: "translatedTrigger", visible: savedVisibility.Trigger},
            {data: "sche_Next_Run_Time", visible: savedVisibility.NextRunTime},
            {data: "sche_Last_Run_Time", visible: savedVisibility.LastRunTime},
            {data: "translatedResult", visible: savedVisibility.LastRunResult},
            {data: "translatedPolicy", visible: savedVisibility.Policy},
            {data: "sche_Receiver_Amount", visible: savedVisibility.ReceiverAmount},
            {data: "sche_Desc", visible: savedVisibility.Description},
            {data: "sche_Cre_Date", visible: savedVisibility.Created},
            {data: "sche_Start_Time", visible: savedVisibility.StartDate},
            {data: "sche_End_Date", visible: savedVisibility.EndDate},
            {data: "sche_Author", visible: savedVisibility.Author}
        ],
        order: [1],
        //columnDefs are defined on schedulertable.js
    }    
}

var getHeaderMap = () => {
    var savedVisibility = getVisibility()
    return {
        "Status" : {name:  "sche_Stat", visible: savedVisibility.Status},
        "Name" : {name: "sche_Name", visible: savedVisibility.Name},
        "Trigger": {name: "sche_Trigger_Type", visible: savedVisibility.Trigger},
        "NextRunTime" : {name: "sche_Next_Run_Time", visible: savedVisibility.NextRunTime},
        "LastRunTime" : {name: "sche_Last_Run_Time", visible: savedVisibility.LastRunTime},
        "LastRunResult" : {name: "sche_Last_Run_Result", visible: savedVisibility.LastRunResult},
        "Policy" : {name: "sche_Policy_Command", visible: savedVisibility.Policy},
        "Receivers" : {name: "sche_Receiver_Amount", visible: savedVisibility.ReceiverAmount},
        "Description": {name: "sche_Desc", visible: savedVisibility.Description},
        "Created": {name: "sche_Cre_Date", visible: savedVisibility.Created},
        "StartDate" : {name: "sche_Start_Date", visible: savedVisibility.StartDate},
        "EndDate" : {name: "sche_End_Date", visible: savedVisibility.EndDate},
        "Author" : {name: "sche_Author", visible: savedVisibility.Author}
    }
    
}


export default {
    getHeaderMap: getHeaderMap,
    getConfig: getConfig
}
