
import React from 'react';
import {Translate} from 'react-localize-redux'


const SettingTitle = (props) => {
    return (
        <div className="setting-section-title">
            <p className="section-title"><Translate id={props.title}/></p>
            <hr/>
        </div>
    );
}

export default SettingTitle