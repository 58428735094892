import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions} from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import API from '../../../REST/RestApi'
import ModalFileInput from '../ModalInputs/ModalFileInput'
import { Translate } from 'react-localize-redux'
import ModalRadio from '../ModalInputs/ModalRadio'
import Validation from '../../Validation/Validation'
import ModalUrlInput from '../ModalInputs/ModalUrlInput'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import urlValidation from '../../../Utils/urlValidation'


class ScreenSaver extends React.Component {
    file = null;
    isUrlValid = false;
    state = {
        downloadUrl: "",
        inputOption: "1", // By default, it will be file upload unless created from scheduler
        validUrl: false,
        urlStatus: "TestScreenSaverUrlInfo",
        urlStatusClass: "info",
        host: "",
        checkingUrl: false
    }

    componentDidMount(){
        if(this.props.error){
            this.setState({ urlStatus: "InvalidScreenSaverUrlForm", urlStatusClass: "error" })
        }
        API.getHost((res)=>{
            this.setState({host: res.host})
        })
    }
    // Handle the change of input type File or URL
    onInputOptionChange(event) {
        this.setState({ inputOption: event.target.value });
    }

    // Handle URL change
    onChange = (event) => {
        let urlStatus = ""
        let urlStatusClass = "info"
        if (!urlValidation.isValidBackgroundUrl(event.target.value)) {
            urlStatus = "InvalidScreenSaverUrlForm"
            urlStatusClass = "error"
        }
        this.setState({ [event.target.name]: event.target.value, urlStatus: urlStatus, urlStatusClass: urlStatusClass });
        var value = {
            downloadURL: event.target.value
        }
        if(this.props.onChange){
            this.props.onChange(value)
        }
    }

    // Handle File change
    onFileChange = (event) => {
        this.file = event.target.files[0]
    }

    onHostChange = (event) => {
        this.setState({host: event.target.value})
    }

    // Handle test url button click in ModalUrlInput
    onTestUrlClick = () => {
        //call the fetch function
        if(!this.state.checkingUrl){
            this.validateURL()
        }
        else{
            //pass
        }
    }

    validateURL = (cb) => {
        var url = this.getUrlValue()
        this.setState({checkingUrl: true, urlStatus: "CheckingUrl", urlStatusClass: "info"},()=>{
            API.validateURL(url,()=>{
                this.isUrlValid = true
                this.setState({ urlStatus: "ValidUrl", urlStatusClass: "valid" , checkingUrl: false},()=>{
                    if(this.props.onValidationChange){
                        this.props.onValidationChange(this.isUrlValid)
                    }
                    if(cb){
                        cb()
                    }
                })
            },(res)=>{
                this.setState({ urlStatus: "InvalidUrl", urlStatusClass: "error" , checkingUrl: false},()=>{
                    if(this.props.onValidationChange){
                        this.props.onValidationChange(this.isUrlValid)
                    }
                })
            })
        })
    }

    // Submit
    onSubmit = () => {
        // Check selected receivers
        var selected = this.props.receiver.selected
        if(selected.start >= 0){
            var macs = Object.keys(selected.macs)
        }
        else {
            return
        }
        // File upload
        if (this.state.inputOption == "0") {
            API.setScreenSaver(this.props.signalr.id, macs, this.file)
            this.props.hideModal();
        } else {
            // URL input
            // Send the request to backend without checking URL 
            let url = this.getUrlValue()
            if (urlValidation.isValidScreenSaverUrl(url)) {
                API.setScreenSaverUrl(this.props.signalr.id, macs, url)
                this.props.hideModal();
            } else {
                this.setState({ urlStatus: "InvalidScreenSaverUrlForm", urlStatusClass: "error", checkingUrl: false }, () => {
                    if (this.props.onValidationChange) {
                        this.props.onValidationChange(this.isUrlValid)
                    }
                })
            }
            
            // Check if Url is reachable, then send the request to backend
            // this.validateURL(()=>{
            //     API.setScreenSaverUrl(this.props.signalr.id, macs, this.getUrlValue())
            //     this.props.hideModal();
            // })
        }
    }

    getFileInput() {
        if (this.state.inputOption == "0") {
            return (
                <div>
                    <ModalTextInput value={this.state.host} label="Host" onChange={this.onHostChange}/>
                    <ModalFileInput
                        name="imageFile"
                        label="BackgroundImage"
                        onChange={(event) => { this.onFileChange(event) }}
                        info="UploadFileInfo" />
                </div>
            )
        }
    }

    getUrlValue = () => {
        var url = this.props.url || ""
        if(this.props.isModal){
            url = this.state.downloadUrl;
        }
        return url
    }


    getUrlInput() {
        if (this.state.inputOption == "1") {
            return (
                <div>
                    <ModalUrlInput
                        label="EnterImageUrl"
                        name="downloadUrl"
                        value={this.getUrlValue()}
                        onChange={this.onChange}
                        info="ScreenSaverUrlInfo"
                        onTestUrlClick={this.onTestUrlClick}
                        urlStatus={this.state.urlStatus}
                        urlStatusClass={this.state.urlStatusClass}
                    />
                </div>
            );
        }
    }

    render() {
        return (
            <div className='modal-body'>
                {/* {this.props.isModal &&
                    <ModalRadio label="InputType"
                        disabled={false}
                        options={{ "File": "0", "Url": "1" }}
                        checked={this.state.inputOption}
                        onChange={(event) => { this.onInputOptionChange(event) }} />
                } */}
                {this.getFileInput()}
                {this.getUrlInput()}
                
                {this.props.isModal && <ModalFooter button2Submit={() => { this.onSubmit() }} />}
            </div>
        );
    }
}


export default connect(
    state => ({receiver: state.receiver, signalr: state.signalr}),
    dispatch => bindActionCreators({...modalActions,...signalRActions}, dispatch)
  )(ScreenSaver);
