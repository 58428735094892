import React from 'react'
import SitesCard from '../Sites/SitesCard'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Translate} from 'react-localize-redux'
import { actionCreators as siteActions } from '../../store/Sites'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


//Up and down arrows to show sort. Need to update to point to a local file.
const imageTypes = {
    0: "https://cdn.datatables.net/1.10.18/images/sort_both.png",
    1: "https://cdn.datatables.net/1.10.18/images/sort_asc.png",
    2: "https://cdn.datatables.net/1.10.18/images/sort_desc.png"
}

class TabbedCardWrapper extends React.Component {
    componentDidMount = () => {
        var siteSortType = parseInt(localStorage.getItem("siteSortType")) || 0
        // var groupSortType = parseInt(localStorage.getItem("groupSortType")) || 0
        this.props.setSortState(siteSortType)
    }

    changeSort = (target) => {
        if(this.props.sortState+1 > 2){
            this.props.setSortState(0)
            localStorage.setItem(target,0) 
        }
        else{
            this.props.setSortState(this.props.sortState+1)
            localStorage.setItem(target, this.props.sortState+1) 
        }
    }

    render(){
        let siteCardStyle = "site-card"
        let tabHeight = "tab-height"
        if (this.props.isScheduler) {
            siteCardStyle = "site-card-in-scheduler"
            tabHeight = "tab-height-in-scheduler"
        }
        return(
            <div className="card-wrapper deselect-trigger">
                <div className={"card deselect-trigger " + siteCardStyle}>
                    <Tabs className={tabHeight} selectedIndex={this.props.tabIndex} onSelect={tabIndex => this.props.onChangeTab(tabIndex)}>
                        <TabList className="react-tabs__tab-list deselect-trigger">
                            <Tab>
                                <Translate id="Sites"/>
                                {this.props.tabIndex == 0 && <img onClick={()=>{this.changeSort("siteSortType")}} src={imageTypes[this.props.sortState]}/>}
                            </Tab>
                            {/* <Tab>
                                <Translate id="Groups"/>
                                {this.props.tabIndex == 1 && <img onClick={()=>{this.changeSort("groupSortType")}} src={imageTypes[this.state.groupSortType]}/>}
                            </Tab> */}
                        </TabList>
                        <TabPanel className="site-list-scroll-height">
                            <SitesCard isScheduler={this.props.isScheduler}/>
                        </TabPanel>
                        {/* <TabPanel>
                            <Translate id="Groups"/>
                        </TabPanel> */}
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default connect(
    state => state.sites,
    dispatch => bindActionCreators({...siteActions},  dispatch)
)(TabbedCardWrapper);
  
  