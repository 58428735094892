import { actionCreators as Receiver } from './Receiver'
import Utils from '../Utils/utils.js'
import API from '../REST/RestApi'
import { actionCreators as Notification, notifications, notificationTypes } from './Notifications'
import { actionCreators as Task } from './Tasks'
const siteUpdateType = 'UPDATE_SITES';
const siteInitType = "INIT_SITES"
const selectType = "SELECT_SITE"
const targetRenameType = "SELECT_TARGET_SITE_RENAME"
const updateContextTargetSite = "SELECT_CONTEXT_TARGET"
const updateSiteOrderType = "UPDATE_NESTED_SITE"
const siteSortType = "SORT_SITE"
const updateSiteRenameValueType = "UPDATE_NAME_VALUE"

const initialState = {
    selectedSites: {
        start: -1,
        end: -1,
        direction: "UP",
        sites: { "-1": { id: "-1", parentId: "", code: "allReceivers", name: "All Receivers" } }
    },
    rawSites: [],
    sortState: 0, //0 none, 1 ascending, 2 descending
    //Tree used for displaying sites in Receiver Page(might not be used)
    siteTree: {},
    flatSites: {},
    contextTargetSite: "",
    nestedSites: [],
    orderedSites: [],
    rolesMap: {},
    renameId: null,
    renameValue: ""
}

export const actionCreators = {
    setSortState: (sortState) => async (dispatch, getState) => {
        var state = getState()
        var nestedSites = Utils.treeSort(state.sites.nestedSites, sortState)
        var orderedSites = Utils.orderedSitesFromNested(nestedSites)
        dispatch({ type: siteSortType, sortState })
        dispatch({ type: updateSiteOrderType, orderedSites, nestedSites })
    },

    createSite: (name, parent, admins, monitors, cb) => async (dispatch, getState) => {
        API.createSite(name, parent, admins, monitors, (res) => {
            if (res === true) {
                dispatch(actionCreators.updateSites())
            }
            else {
                dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.genericFail))
            }
            cb(res)
        }, (err) => {
            dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.duplicateName(name)))
        })
    },
    updateSites: (cb, errorcb) => async (dispatch, getState) => {
        API.getSites((sites, roles) => {
            var state = getState()
            var sortState = state.sites.sortState
            var rawSites = sites
            var flatSites = {}
            rawSites.forEach((site) => {
                flatSites[site.id] = site
            })
            sites.unshift({ id: "-1", parentId: "", code: "allReceivers", name: "All Receivers" })
            if (state.user.isSystemAdmin === "true" || state.user.isSystemAdmin === true) {
                sites.push({ id: "-2", parentId: "-1", code: "unassigned", name: "Unassigned" })
                roles = [{ siteId: "-1", roleId: "ScreenBeamAdmin" }] //If system admin add admin role to "All Receivers" so that all sites inherit admin privilege
            }
            var rolesMap = {}
            var nestedSites = Utils.buildNestedData(sites, roles, rolesMap, sortState)
            var orderedSites = Utils.orderedSitesFromNested(nestedSites)

            dispatch({ type: siteUpdateType, orderedSites, nestedSites, rolesMap, rawSites, flatSites })
            if (cb) {
                cb()
            }
        }, () => {
            if (errorcb) {
                errorcb()
            }
        })
    },
    addUsersToSite: (siteId, admins, monitors, removed, cb, errorcb) => async (dispatch, getState) => {
        API.addUsersToSite(siteId, admins, monitors, removed, cb, errorcb)
    },
    selectContextTarget: (site) => async (dispatch, getState) => {
        var contextTargetSite = site
        dispatch({ type: updateContextTargetSite, contextTargetSite })
    },
    removeContextTarget: () => async (dispatch, getState) => {
        var contextTargetSite = null
        dispatch({ type: updateContextTargetSite, contextTargetSite })
    },
    selectSingleSite: (index, force) => async (dispatch, getState) => {
        var state = getState()
        var sites = state.sites
        var receivers = state.receiver
        var selected = sites.selectedSites
        var orderedSites = sites.orderedSites
        selected = Utils.select(orderedSites, selected, 0, index, "sites", "key", force)
        dispatch({ type: selectType, selected })
        dispatch(Receiver.setTargetType("Sites"))
        dispatch(Task.clearTaskSelection())
        receivers.dataTables.rows().invalidate()
        receivers.dataTables.draw()
    },
    selectMultipleSites: (index) => async (dispatch, getState) => {
        var state = getState()
        var sites = state.sites
        var receivers = state.receiver
        var selected = sites.selectedSites
        if (Object.keys(selected.sites).length <= 0) {
            dispatch(actionCreators.selectSingleSite(index, true))
            return
        }
        var orderedSites = sites.orderedSites
        var filter = (item) => {
            //Prevent user from choosing unassigned with other sites
            if (item.key == "-2") {
                return false
            }
            return true
        }
        selected = Utils.selectMultiple(orderedSites, selected, index, "sites", "key", filter)
        dispatch({ type: selectType, selected })
        dispatch(Receiver.setTargetType("Sites"))
        dispatch(Task.clearTaskSelection())
        receivers.dataTables.rows().invalidate()
        receivers.dataTables.draw()
    },
    selectAddSite: (index) => async (dispatch, getState) => {
        var state = getState()
        var sites = state.sites
        var receivers = state.receiver
        var selected = sites.selectedSites
        var orderedSites = sites.orderedSites
        var filter = (item) => {
            //Prevent user from choosing unassigned with other sites
            if (item.key == "-2") {
                return false
            }
            return true
        }
        selected = Utils.selectAdd(orderedSites, selected, 0, index, "sites", "key", filter)
        dispatch({ type: selectType, selected })
        dispatch(Receiver.setTargetType("Sites"))
        dispatch(Task.clearTaskSelection())
        receivers.dataTables.rows().invalidate()
        receivers.dataTables.draw()
    },
    deselectSites: () => async (dispatch, getState) => {
        var selected = {
            start: -1,
            end: -1,
            direction: "UP",
            sites: { "-1": { id: "-1", parentId: "", code: "allReceivers", name: "All Receivers" } }
        }
        dispatch({ type: selectType, selected })
    },
    deselectSitesFull: () => async (dispatch, getState) => {
        var selected = {
            start: -1,
            end: -1,
            direction: "UP",
            sites: {}
        }
        dispatch({ type: selectType, selected })
    },
    deleteSite: (siteId) => async (dispatch, getState) => {
        var state = getState()
        var receivers = state.receiver
        API.deleteSite(siteId, () => {
            dispatch(actionCreators.deselectSites())
            dispatch(actionCreators.updateSites())
            receivers.dataTables.rows().invalidate()
            receivers.dataTables.draw()
        })
    },
    targetRenameSite: (targetSite) => async (dispatch, getState) => {
        var renameId = targetSite.id
        var renameValue = targetSite.name

        dispatch({ type: targetRenameType, renameId, renameValue })
    },
    updateRenameValue: (value) => async (dispatch, getState) => {
        dispatch({ type: updateSiteRenameValueType, renameValue: value })
    },
    renameSite: (name, cb, errorcb) => async (dispatch, getState) => {

        var state = getState().sites
        var target = state.renameId
        var renameId = null

        dispatch({ type: targetRenameType, renameId })

        if (name.length < 2 || name.length > 36) {
            dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.sitenNameLengthError))
            return
        }

        API.renameSite(target, name, () => {
            dispatch(actionCreators.updateSites())
            if (cb) {
                cb()
            }
        }, (err) => {
            if (errorcb) {
                Notification.createNotification(notificationTypes.error, notifications.errorMessages.genericFail)
                errorcb()
            }
        })
    },
    joinReceivers: () => async (dispatch, getState) => {
        var state = getState()
        var targetSite = state.sites.contextTargetSite
        var targetSiteName = targetSite.name
        // var targetSiteName = state.sites.flatSites[targetSite]        
        var receivers = Object.keys(state.receiver.selected.macs).map((mac) => {
            return mac
        })
        if (receivers.length > 0) {
            var startTime = new Date()
            API.joinReceivers(targetSite, receivers, () => {
                var timeElapsed = (new Date() - startTime) / 1000
                if (state.receiver.dataTables) {
                    state.receiver.dataTables.rows().invalidate()
                    state.receiver.dataTables.draw()
                }
                dispatch(Notification.createNotification(notificationTypes.success, notifications.successMessages.joinReceiverToSite(targetSiteName, timeElapsed)))
            })
        } else {
            dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.emptyReceivers))
        }

    },
    releaseReceivers: () => async (dispatch, getState) => {
        var state = getState()
        var targetSite = state.sites.contextTargetSite
        var receivers = Object.keys(state.receiver.selected.macs)
        if (receivers.length > 0) {
            var startTime = new Date()
            API.releaseReceivers(receivers, () => {
                var timeElapsed = (new Date() - startTime) / 1000
                if (state.receiver.dataTables) {
                    state.receiver.dataTables.rows().invalidate()
                    state.receiver.dataTables.draw()
                }
                dispatch(Notification.createNotification(notificationTypes.success, notifications.successMessages.releaseReceiverFromSite(timeElapsed)))
            })
        } else {
            dispatch(Notification.createNotification(notificationTypes.error, notifications.errorMessages.emptyReceivers))            
        }
    },
};

export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;

    //Return copy of state with edited state
    if (action.type === siteUpdateType) {
        return { ...state, orderedSites: action.orderedSites, nestedSites: action.nestedSites, rolesMap: action.rolesMap, rawSites: action.rawSites, flatSites: action.flatSites };
    }
    else if (action.type === selectType) {
        return { ...state, selectedSites: action.selected }
    }
    else if (action.type === targetRenameType) {
        return { ...state, renameId: action.renameId, renameValue: action.renameValue }
    }
    else if (action.type === updateContextTargetSite) {
        return { ...state, contextTargetSite: action.contextTargetSite }
    }
    else if (action.type === siteSortType) {
        return { ...state, sortState: action.sortState }
    }
    else if (action.type === updateSiteOrderType) {
        return { ...state, orderedSites: action.orderedSites, nestedSites: action.nestedSites }
    }
    else if (action.type === updateSiteRenameValueType) {
        return { ...state, renameValue: action.renameValue }
    }
    return state;
};
