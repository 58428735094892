import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Modal';
import ReactModal from 'react-modal'
import './Modal.css'
import About from './ModalActions/About'
import CMSConfig from './ModalActions/CMSConfig'
import NewUsbProvisioning from './ModalActions/NewUsbProvisioning';
// import UsbProvisioning from './ModalActions/UsbProvisioning';
// import Settings from './ModalActions/Settings'
import Settings from './ModalActions/SingleRxSettings'
import UpdateFirmware from './ModalActions/UpdateFirmware'
import Background from './ModalActions/Background'
import ScreenSaver from './ModalActions/ScreenSaver'
import Reboot from './ModalActions/Reboot'
import Reset from './ModalActions/Reset'
import UpdateCredentials from './ModalActions/UpdateCredentials'
import SwitchCMS from './ModalActions/SwitchCMS'
import ReceiverDescription from './ModalActions/ReceiverDescription'
import ModalDelete from './ModalActions/ModalDelete'
import InstallCertificate from './ModalActions/InstallCertificate'
import DeleteTaskModal from './ModalActions/DeleteTaskModal'
import ScheduleModal from './ScheduleModal/ScheduleModal'
import DeleteUser from './ModalActions/DeleteUser'
import EditUser from './ModalActions/EditUser'
import Logout from './ModalActions/Logout'
import RefreshNotice from './ModalActions/RefreshNotice'
import AddSite from './ModalActions/AddSite'
import DeleteSiteWarning from './ModalActions/DeleteSiteWarning'
import MultipleRxSettings from './ModalActions/MultipleRxSettings'
import ReceiverLog from './ModalActions/ReceiverLog'
import SchedulerWarning from './ModalActions/SchedulerWarning'
import ServerBusy from './ModalActions/ServerBusy'
import LogUploadBusy from './ModalActions/LogUploadBusy'
import { Translate } from 'react-localize-redux'

const customStyles = {
    content: {
        top: '30px',
        left: '30px',
        right: '30px',
        bottom: '30px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

class Modal extends React.Component {
    componentMap = {
        "About": ["About", About],
        "CMS Configuration": ["CmsSettings", CMSConfig],
        // "USB Auto Config": ["UsbProvisioning", AutoConfig],
        "Log Upload Busy": ["LogUploadBusy", LogUploadBusy],
        "USB Auto Config": ["UsbProvisioning", NewUsbProvisioning],        
        "Settings": ["Settings", Settings],
        "Update Firmware": ["UpdateFirmware", UpdateFirmware],
        "Set Background": ["SetBackground", Background],
        "Set Screen Saver": ["SetScreenSaver", ScreenSaver],
        "Reboot": ["Reboot", Reboot],
        "Reset to Default": ["ResetToDefault", Reset],
        "Update Credentials": ["UpdateCredentials",UpdateCredentials],
        "Switch CMS for Receiver": ["SwitchCmsForReceivers", SwitchCMS],
        "Edit Receiver Description": ["EditReceiverDescriptions", ReceiverDescription],
        "Delete Receiver": ["DeleteReceivers", ModalDelete],
        "Install Certificate": ["InstallCertificate", InstallCertificate],
        "Delete Task": ["DeleteTask", DeleteTaskModal],
        "Schedule Task": ["ScheduleTask", ScheduleModal],
        "Delete User": ["DeleteUser", DeleteUser],
        "Edit User": ["EditUser", EditUser],
        "Add New Member": ["AddNewMember", EditUser],
        "Logout": ["Logout", Logout],
        "RefreshNotice": ["RefreshNotice", RefreshNotice],
        "Add Site": ["AddSite", AddSite],
        "Add Users to Site": ["AddUserToSite", AddSite],
        "Delete Site": ["DeleteSite", DeleteSiteWarning],
        "MultipleRxSettings": ["MultipleRxSettings", MultipleRxSettings],
        "ReceiverLog": ["ReceiverLog", ReceiverLog],
        "Scheduler Warning" : ["ScheduleTask", SchedulerWarning],
        "Server Busy": ["ServerBusy", ServerBusy]
    }

    render() {
        var siteName = null;
        var modal = this.componentMap[this.props.modal.id];
        if (!modal) { return (null) }
        var TargetModal = modal[1];
        var titleId = this.props.modal.title || modal[0];
        return (
            <div>
                <ReactModal
                    ariaHideApp={false}
                    className="light-mode Modal"
                    overlayClassName="light-mode Overlay"
                    isOpen={this.props.modal.show}
                    contentLabel="Example"
                    onRequestClose={this.props.hideModal}
                    styles={customStyles}
                >
                    <div className="modal-header">
                        <h5 className="modal-title"><Translate id={titleId} /> {this.props.modal.additionalInfo ? this.props.modal.additionalInfo : "" }</h5>
                        <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close" onClick={this.props.hideModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <TargetModal className='modal-body-custom' arg1 ={this.props.modal.arg1} arg2={this.props.modal.arg2} isModal={true}/>
                </ReactModal>
            </div>


        );
    }
}






export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Modal);
