import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as userActions } from '../../../store/User'
import { Translate } from 'react-localize-redux'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalPasswordInput from '../ModalInputs/ModalPasswordInput'
import ModalCheckbox from '../ModalInputs/ModalCheckbox'
import $ from 'jquery';

class EditUser extends React.Component {
    constructor(props) {
        super(props);

        this.mode = this.props.modal.arg1["username"] ? "Edit" : "Create";
        this.token = this.props.modal.arg1["token"];
        this.rootEdit = this.props.modal.arg1["rootEdit"];
        this.state = {
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            hidePassword: true,
            hidePasswordConfirm: true,
            changePassword: false,
            formValidate: {
                email: { valid: false, message: '' },
                password: { valid: false, message: '' },
                passwordConfirm: { valid: false, message: '' },
                username: { valid: false, message: '' },
                firstName: { valid: false, message: '' },
                lastName: { valid: false, message: '' }
            },
            formValid: false
        }
        this.formFields = ["username", "firstName", "lastName", "email", "password", "passwordConfirm"];
    }
    componentDidMount() {
        this.formFields.forEach((value) => {
            if (this.props.arg1[value])
                this.setState({ [value]: this.props.arg1[value] });
        });

        if (this.mode == "Create")
            this.setState({ changePassword: true });
    }
    onChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({ [name]: value },
            () => {
                this.validateField(name, value)
            });
    }

    validateAllField(callback) {
        this.formFields.forEach((value) => {
            this.validateField(value, this.state[value])
        });
        this.validateForm(callback);
    }

    validateField(fieldName, value) {

        let _formValidate = this.state.formValidate;

        switch (fieldName) {
            case 'firstName':
                if (!(value.match("(?=^[a-z0-9A-Z]+$)(?=.{2,})") && value.length <= 20)) {
                    _formValidate.firstName.valid = false;
                    _formValidate.firstName.message = "FirstNameWarning";
                }
                else {
                    _formValidate.firstName.valid = true;
                    _formValidate.firstName.message = "EmptyString";
                }
                break;
            case 'lastName':
                if (!(value.match("(?=^[a-z0-9A-Z]+$)(?=.{2,})") && value.length <= 20)) {
                    _formValidate.lastName.valid = false;
                    _formValidate.lastName.message = "LastNameWarning";
                }
                else {
                    _formValidate.lastName.valid = true;
                    _formValidate.lastName.message = "EmptyString";
                }
                break;
            case 'username':
                if (!(value.match("(?=^[a-z0-9A-Z]+$)(?=.{4,})") && value.length <= 20)) {
                    _formValidate.username.valid = false;
                    _formValidate.username.message = "UsernameWarning";
                }
                else {
                    _formValidate.username.valid = true;
                    _formValidate.username.message = "EmptyString";
                }
                break;
            case 'email':
                // _formValidate.email.valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/) ? true : false;
                // _formValidate.email.message = _formValidate.email.valid ? '' : "InvalidEmailWarning";
                if (!(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/) && value.length < 128)) {
                    _formValidate.email.valid = false;
                    _formValidate.email.message = "InvalidEmailWarning";
                }
                else {
                    _formValidate.email.valid = true;
                    _formValidate.email.message = "EmptyString";
                }
                break;
            case 'password':
                if (this.state.changePassword == false) {
                    _formValidate.password.valid = true;
                    _formValidate.password.message = "EmptyString";

                }
                else {
                    if (value <= 0) {
                        _formValidate.password.valid = false;
                        _formValidate.password.message = "PasswordWarning";
                    }
                    // else if (!(value.match("(?=^[a-z0-9A-Z]+$)(?=.*[!@#\$%\^&\*])(?=.{8,})") && value.length <= 20)) {
                    else if (!(value.match("^(?=.*[a-z])(?=.*[A-Z])(?=^[a-z0-9A-Z!@#\$%\^&\*]+$)(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})") && value.length <= 20)) {

                        _formValidate.password.valid = false;
                        _formValidate.password.message = "PasswordWarning";
                    }
                    else {
                        _formValidate.password.valid = true;
                        _formValidate.password.message = "EmptyString";

                        if (this.state.passwordConfirm == this.state.password) {
                            _formValidate.passwordConfirm.valid = true;
                            _formValidate.passwordConfirm.message = "EmptyString";
                        }
                    }
                }
                break;
            case 'passwordConfirm':
                if (this.state.changePassword == false) {
                    _formValidate.passwordConfirm.valid = true;
                    _formValidate.passwordConfirm.message = "EmptyString";
                }
                else {
                    if (value <= 0) {
                        _formValidate.passwordConfirm.valid = false;
                        _formValidate.passwordConfirm.message = "UnmatchPassword";
                    }
                    else if (this.state.password != this.state.passwordConfirm) {
                        _formValidate.passwordConfirm.valid = false;
                        _formValidate.passwordConfirm.message = "UnmatchPassword";
                    }
                    else {
                        _formValidate.passwordConfirm.valid = true;
                        _formValidate.passwordConfirm.message = "EmptyString";
                    }
                }
                break;
            default:
                break;
        }

        this.setState({
            formValidate: _formValidate
        }, this.validateForm);
    }

    validateForm(callback) {
        var formValidate = this.state.formValidate;
        var _formValid = true;
        Object.keys(formValidate).map((key, index) => {
            if (!formValidate[key].valid) {
                _formValid = false;
            }
        });
        if (this.rootEdit == true) {
            this.mode = "Edit";
            this.setState({
                username: "Administrator"
                //firstName: "Administrator",
                //lastName: "Administrator",
                //email: "Administrator@screenbeam.com"
            })
            if (this.state.formValidate.password.valid && this.state.formValidate.passwordConfirm.valid)
                _formValid = true;
        }
        this.setState({ formValid: _formValid },
            () => {
                if (callback)
                    callback();
            });
    }

    handleSubmit = () => {
        this.props.isTokenValid(
            () => {
                this.validateAllField(
                    () => {
                        if (this.state.formValid) {
                            if (this.state.changePassword == 0)
                                this.setState({ password: "" });

                            if (this.mode == "Create") {
                                this.props.createUser(
                                    this.token,
                                    this.state.firstName,
                                    this.state.lastName,
                                    this.state.username,
                                    this.state.email,
                                    this.state.password,
                                    () => {
                                        this.props.hideModal();
                                    });
                            }
                            else {
                                this.props.editUser(
                                    this.token,
                                    this.state.firstName,
                                    this.state.lastName,
                                    this.state.username,
                                    this.state.email,
                                    this.state.password,
                                    () => {
                                        this.props.hideModal();
                                    });
                            }
                        }
                    });
            },
            () => {
                this.props.hideModal();
                this.props.logout();
            });
    }

    render() {
        let changePasswordFields =
            <div>
                <ModalPasswordInput hidden={true} label="Password" name="password" value={this.state.Password} placeholder="" error={this.state.formValidate.password.message} onChange={this.onChange} />
                <ModalPasswordInput label="RetypePassword" name="passwordConfirm" value={this.state.passwordConfirm} placeholder="" error={this.state.formValidate.passwordConfirm.message} onChange={this.onChange} />
            </div>


        let wholeFormFields =
            <div>
                <ModalTextInput label="FirstName" name="firstName" value={this.state.firstName} placeholder="" error={this.state.formValidate.firstName.message} onChange={this.onChange} />
                <ModalTextInput label="LastName" name="lastName" value={this.state.lastName} placeholder="" error={this.state.formValidate.lastName.message} onChange={this.onChange} />
                <ModalTextInput label="Username" name="username" disabled={this.mode == "Edit" ? true : false} value={this.state.username} placeholder="" error={this.state.formValidate.username.message} onChange={this.onChange} />
                <ModalTextInput label="Email" name="email" value={this.state.email} placeholder="" error={this.state.formValidate.email.message} onChange={this.onChange} />
                {this.mode == "Edit" ?
                    <div>
                        <ModalCheckbox label="ChangePassword" name="changePassword" value={this.state.changePassword} checked={this.state.changePassword} onChange={this.onChange} />
                        {this.state.changePassword == 1 ?
                            changePasswordFields
                            :
                            null
                        }
                    </div>
                    :
                    <div>
                        <ModalPasswordInput hidden={true} label="Password" name="password" value={this.state.Password} placeholder="" error={this.state.formValidate.password.message} onChange={this.onChange} />
                        <ModalPasswordInput label="RetypePassword" name="passwordConfirm" value={this.state.passwordConfirm} placeholder="" error={this.state.formValidate.passwordConfirm.message} onChange={this.onChange} />
                    </div>

                }
            </div>
        return (
            <div>
                <div className='modal-body'>
                    <form ref='userForm' id="userForm">

                        {
                            this.rootEdit == true ?
                                changePasswordFields
                                :
                                wholeFormFields
                        }
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => { this.props.hideModal(); }}><Translate id="Cancel" /></button>
                    <button type="button" className="btn btn-primary" onClick={this.handleSubmit}><Translate id="Proceed" /></button>
                </div>
            </div >
        );
    }
}

export default connect(
    state => ({ modal: state.modal }),
    dispatch => bindActionCreators({ ...modalActions, ...userActions }, dispatch)
)(EditUser);
