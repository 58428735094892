import React from 'react';
import { Redirect } from 'react-router'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/User';
import '../../Styles-Shared.css'
import './LoginStyle.css'
import { Translate } from "react-localize-redux";

class Login extends React.Component {
    state = {
        username: "",
        password: "",
        error: "",
        reveal: false,
        remember: false
    }

    onChange(event, target) {
        this.setState({ [target]: event.target.value })
    }

    onToggle(target) {
        this.setState({ [target]: !this.state[target] })
    }

    handleSubmit(event) {
        this.props.login(this.state.username, this.state.password, this.state.remember, (user) => { }, (error) => {
            // console.log()
            this.setState({ error: error })
        })
        event.preventDefault();
    }

    render() {
        if (this.props.username != "") {
            return <Redirect to='/Dashboard' />
        }
        return (
            <div className='primary-color' id="loginPage">
                <div className="login-page container-fluid h-100">
                    <div className="row justify-content-center align-items-center h-100">
                        <form className="col-10 col-md-3 card justify-content-center rounded animated zoomIn" onSubmit={(event) => this.handleSubmit(event)}>
                            <h1 className="title" id="central-mgt"><Translate id="CentralManagement" /></h1>
                            <h1 className="title"><Translate id="Enterprise" /></h1>
                            <div className="form-group">
                                <span className='text-danger'>{this.state.error}</span>
                                <Translate>
                                    {({ translate }) =>
                                        <input className='form-control col-md-11 login-input-field' id="login-username" placeholder={translate("Username")} name="UserName" value={this.state.username} onChange={(event) => this.onChange(event, "username")}></input>
                                    }
                                </Translate>
                            </div>
                            <div className="form-group">
                                <div id="show_hide_password">
                                    <Translate>
                                        {({ translate }) =>
                                            <input type={this.state.reveal ? "text" : "password"} className="form-control col-md-11 login-input-field" id="Password" name="Password" value={this.state.password} placeholder={translate("Password")} onChange={(event) => this.onChange(event, "password")}></input>
                                        }
                                    </Translate>
                                    <span className={this.state.reveal ? "fa fa-eye field-icon toggle-password" : "fa fa-eye-slash field-icon toggle-password"} onClick={() => { this.onToggle("reveal") }}></span>
                                </div>
                            </div>
                            <div className="form-row" id="login-options">
                                <div className="form-group col-md-12 form-group-no-padding login-input-field">
                                    <div className="align-items-center custom-control custom-checkbox" id="remember-me-wrapper" onClick={() => this.onToggle("remember")}>
                                        <input className="custom-control-input" checked={this.state.remember} type="checkbox" ></input>
                                        <label className="custom-control-label" ><Translate id="RememberMe" /></label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" value="Log in" id="log-in" className="col-md-11 btn btn-primary login-input-field"><Translate id="Login" /></button>
                           
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(
    state => state.user,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);
