import React from 'react';
import ModalSelect from'../ModalInputs/ModalSelect'
import ModalTitle from '../ModalInputs/ModalTitle'
import UpdateFirmwareBody from '../ModalBodies/UpdateFirmwareBody'
import Background from '../ModalActions/Background'
import ScreenSaver from '../ModalActions/ScreenSaver'
import UpdateFirmware from '../ModalActions/UpdateFirmware'
import Reboot from '../ModalActions/Reboot'
import Reset from '../ModalActions/Reset'
import MultipleRxSettings from '../ModalActions/MultipleRxSettings'
import Settings from '../ModalActions/Settings'
import actionTypes from '../../../Utils/actionTypes'

class ActionsScheduleTab extends React.Component {
    options={
        "SelectAction" : -1,
        ...actionTypes
    }

    onChange = (value) => {
        var event = {
            target: {
                value: value,
            }
        }
        this.props.onChange(event,"sche_Policy_Content")
    }

    onChangeSettings = (settings) => {
        this.body = settings
    }

    renderSubOptions=()=>{
        if(this.props.values.sche_Policy_Command == -1){
            return
        }
        else if(this.props.values.sche_Policy_Command==actionTypes.Settings){
            return <Settings isModal={false} displayScreenBeamProduct={true}/>
        }
        else if(this.props.values.sche_Policy_Command==actionTypes.UpdateFirmware){
            return(
                <UpdateFirmware isModal={false} content={this.props.values.sche_Policy_Content} onChange={this.onChange} onValidationChange={this.props.onValidationChange} error={this.props.warningActive}/>
            )
        }
        else if(this.props.values.sche_Policy_Command==actionTypes.SetScreenSaver){
            return <ScreenSaver isModal={false} url={this.props.values.sche_Policy_Content.downloadURL}  onChange={this.onChange} onValidationChange={this.props.onValidationChange} error={this.props.warningActive}/>
        }
        else if(this.props.values.sche_Policy_Command==actionTypes.SetBackground){
            return <Background isModal={false} url={this.props.values.sche_Policy_Content.downloadURL} onChange={this.onChange} onValidationChange={this.props.onValidationChange} error={this.props.warningActive}/>
        }
        else if(this.props.values.sche_Policy_Command==actionTypes.Reboot){
            return <Reboot isModal={false}/>
        }
        else if(this.props.values.sche_Policy_Command==actionTypes.ResetToDefault){
            return <Reset isModal={false}/>
        }
    }

    render() {
        return (     
            <div>
                <ModalSelect options={this.options} value={this.props.values.sche_Policy_Command} onChange={(event)=>{this.props.onChange(event,"sche_Policy_Command")}} label="Actions" error={this.props.warningActive && this.props.warnings.sche_Policy_Command}/>
                {this.renderSubOptions()}
            </div>
        );
    }
}


export default ActionsScheduleTab;
