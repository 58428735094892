import React from 'react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {Translate} from 'react-localize-redux'
import { Portal } from 'react-overlays'
import { isTomorrow, startOfTomorrow, startOfYesterday, endOfYesterday } from 'date-fns';


const CalendarContainer = ({children}) => {
    //Solution to react-date input being constrained by its container
    //Reference: https://github.com/Hacker0x01/react-datepicker/pull/1074
    const el = document.getElementById('root')
    return (
      <Portal container={el}>
        {children}
      </Portal>
    )
  }

class ModalDateInput extends React.Component {
    state={
        minTime: new Date()
    }
    
    componentDidMount(){
        var newMinTime = new Date()
        //If the new date is greater than tomorrow at midnight. Remove the minimum time restriction
        if(new Date(this.props.value) >= startOfTomorrow()){
            newMinTime.setHours(0,0,0,0)
        }
        else if(new Date(this.props.value) <= endOfYesterday()){
            newMinTime.setHours(23,0,0,0)
        }
        this.setState({minTime: newMinTime})
    }

    handleChange = (date) => {
        if(date >= startOfTomorrow()){
            var newMinTime = new Date()
            newMinTime.setHours(0,0,0,0)
            this.setState({minTime: newMinTime})
        }
        else{
            this.setState({minTime: new Date()})
        }

        this.props.onChange({target: {value: date}})
    }

    render() {
        return (     
            <div className="form-group row settings-row">
                <label className="modal-label col-form-label col-sm-4" ><Translate id={this.props.label}/></label>
                <div className="col-sm-4 modal-date-wrapper">
                    <DatePicker 
                        onChangeRaw={(e)=>{e.preventDefault()}}
                        className={this.props.disabled ? "disabled modal-date-input" : "modal-date-input"}
                        selected={this.props.disabled ? null : this.props.value} 
                        onChange={(date)=>{this.handleChange(date)}}
                        showTimeSelect={this.props.showTimeSelect}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={new Date()}
                        minTime={this.state.minTime}
                        maxTime={new Date().setHours(23,59,0,0)}
                        disabled={this.props.disabled}
                        timeIntervals={5}
                        popperPlacement="bottom"
                        popperContainer={CalendarContainer}
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"] // don't allow it to flip to be above
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                    />
                </div>
                <div className="col-sm-4 error"><Translate id={this.props.error} /></div>
            </div>
        );
    }
}


export default ModalDateInput
