import React from 'react';
import ModalFooter from '../ModalFooter'
import {Translate} from 'react-localize-redux'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'

class About extends React.Component {
    render() {
        return (            
            <div>
                <div className='modal-body'>
                    <p class="modal-text text-center"><Translate id="ScreenbeamCmsTitle"/></p>
                    <p class="modal-text text-center"><Translate id="Version"/> {this.props.arg1}</p>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions }, dispatch)
)(About);
