import React from 'react';
import {Translate } from 'react-localize-redux'

class ModalRadio extends React.Component {
    radioId = this.props.name ? "radioInput-" + this.props.name + "-": "radioInput-" + this.props.label + "-";
    render() {
        return (     
            <div className="form-group row settings-row">
                <label className="modal-label col-sm-4"><Translate id={this.props.label}/></label>
                {Object.keys(this.props.options).map((key,index)=>{
                    return(
                        <div key={"radio"+index} className="custom-control custom-radio custom-control-inline col-sm-2">
                            <input disabled={this.props.disabled} 
                                className="custom-control-input" 
                                type="radio" 
                                id={this.radioId + key}
                                checked={this.props.checked == this.props.options[key]} 
                                onChange={(event)=>{this.props.onChange(event)}}
                                value={this.props.options[key]}
                            />
                            <label className="custom-control-label" htmlFor={this.radioId + key}><Translate id={key}/></label>
                        </div>
                    )
                })}
                {this.props.info &&
                    <div className="info col-sm-3">
                        <Translate id={this.props.info}/>
                    </div>
                }
            </div>
        );
    }
}


export default ModalRadio
