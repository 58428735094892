import React from 'react';

class ModalCheckBoxGroup extends React.Component {
    render() {
        return (     
            <div className="form-group row settings-row">
                <label className="modal-label col-sm-4 col-form-label">
                    <div>{this.props.label}</div>
                    {this.props.warningActive && <div className="error">{this.props.error}</div>}
                </label>
                <div className={"modal-label" + (this.props.nospacing ? "" : " col-sm-4")}>
                    {this.props.children}
                </div>
                {this.props.info &&
                    <div className="info col-sm-3">
                        {this.props.info}
                    </div>
                }
            </div>
        );
    }
}


export default ModalCheckBoxGroup
