import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions} from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import API from '../../../REST/RestApi'

class DeleteTaskModal extends React.Component {
    render() {
        return (     
            <div>
                <div className='modal-body'>
                    <div className="lead">
                        Are you sure you want to delete the select task(s)?
                    </div>
                </div>
                <ModalFooter button2Submit={this.props.arg1.onSubmit}/>
            </div>
        );
    }
}


export default connect(
    null,
    dispatch => bindActionCreators({...modalActions}, dispatch)
  )(DeleteTaskModal);
