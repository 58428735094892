
import './Bars.css'
import React from 'react';
import MenuItem from './MenuItem'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as UserActions } from '../../store/User';
import { actionCreators as SignalRActions } from '../../store/SignalR'
import { actionCreators as ModalActions } from '../../store/Modal'
import Avatar from '../Avatar/Avatar'
class Sidebar extends React.Component {
    // Key is the name of the menu Item, value is an array that contains the id and the icon associated with the menu item.
    // Menu that display when the user is SB Admin/System Admin
    menuItems = {
        "Dashboard": ["Dashboard", "dashboard"],
        "Receivers": ["Receivers", "cast"],
        "Scheduler": ["Scheduler", "today"],
        "Logs": ["ReceiverLog", "format_list_bulleted"],
        "SchedulerLogs": ["SchedulerLogs", "format_list_bulleted"],
        // "USBAutoConfigRx": ["UsbProvisioning", "usb"],
        "USBAutoConfigRx": ["UsbProvisioning", "usb"],        
        // "ConfigureCMS": ["CmsSettings", "build"],
        "MemberManagement": ["MemberManagement", "people"],
        "RootPassword": ["RootPassword", "people"],
        "About": ["About", "help_outline"]

    };

    state = {
        selected: ""
    }

    handleClickEvent = (event, name) => {
        if (name == "SchedulerLogs" || name == "Dashboard" || name == "Receivers" || name == "Logs" || name == "MemberManagement" || name == "Scheduler" || name == "SchedulerLogs" || name == "RootPassword") {
            this.setState({ selected: name })
        }
        if (name === "About") {
            event.preventDefault()
            // this.props.createModal("About")
            this.props.getCmsWebInfo()
        }
        else if (name === "RootPassword") {
            event.preventDefault();

            this.props.createModal("Edit User", {
                "token": sessionStorage.getItem("jwtoken") ? sessionStorage.getItem("jwtoken") : localStorage.getItem("jwtoken"),
                "rootEdit": true

            })

        }
        else if (name === "ConfigureCMS") {
            event.preventDefault()
            this.props.createModal("CMS Configuration")
        }
        else if (name === "USBAutoConfigRx") {
            event.preventDefault()
            this.props.createModal("USB Auto Config")
        }
    }

    render() {
        return (
            <nav className={this.props.user.sidebarActive ? 'sidebar deselect-trigger' : "sidebar inactive"}>
                <Avatar />
                <ul className="nav flex-column mb-2">
                    {
                        Object.keys(this.menuItems).map((key, index) => {
                            if (this.props.user.username === "Administrator") {
                                if (key == "MemberManagement" || key == "About" || key == "RootPassword") {
                                    return (
                                        <MenuItem
                                            key={index}
                                            id={key} item={this.menuItems[key]}
                                            handleClickEvent={this.handleClickEvent}
                                            selected={this.state.selected == key ? true : false}
                                        />
                                    )
                                }
                            }
                            else {
                                if (key == "MemberManagement" || key == "USBAutoConfigRx" || key == "ConfigureCMS" || key == "SchedulerLogs" || key == "Logs") {
                                    return ((this.props.user.isSystemAdmin === "true") &&
                                        <MenuItem
                                            key={index}
                                            id={key} item={this.menuItems[key]}
                                            handleClickEvent={this.handleClickEvent}
                                            selected={this.state.selected == key ? true : false}
                                        />
                                    )
                                } else {
                                    if (key != "RootPassword")
                                        return (
                                            <MenuItem
                                                key={index}
                                                id={key} item={this.menuItems[key]}
                                                handleClickEvent={this.handleClickEvent}
                                                selected={this.state.selected == key ? true : false}
                                            />
                                        )
                                }
                            }
                        })
                    }
                </ul>
            </nav>
        );
    }
}

export default connect(
    state => (state),
    dispatch => bindActionCreators({ ...UserActions, ...SignalRActions, ...ModalActions }, dispatch)
)(Sidebar);
