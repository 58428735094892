import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as modalActions } from '../../../store/Modal';
import { actionCreators as signalRActions } from '../../../store/SignalR'
import ModalFooter from '../ModalFooter'
import UpdateFirmwareBody from '../ModalBodies/UpdateFirmwareBody'
import API from '../../../REST/RestApi'
import { Translate } from 'react-localize-redux'
import ModalRadio from '../ModalInputs/ModalRadio'
import Validation from '../../Validation/Validation'
import ModalTextInput from '../ModalInputs/ModalTextInput'
import ModalUrlInput from '../ModalInputs/ModalUrlInput'
import ModalSelect from '../ModalInputs/ModalSelect'
import ModalFileInput from '../ModalInputs/ModalFileInput'
import ProgressBar from '../../ProgressBar/ProgressBar'
import urlValidation from '../../../Utils/urlValidation'



class UpdateFirmware extends React.Component {
    state = {
        inputOption: "1", // By default, it will be file upload unless created from scheduler        updateType: "000",
        updateProgress: 0,
        showProgress: false,
        downloadUrl: "",
        validUrl: false,
        urlStatus: "TestFirmwareUrlInfo",
        urlStatusClass: "info",
        updateType: "110",
        host: "",
        checkingUrl: false
    }
    options = {
        "PerReceiverSetting": "000",
        "ForceUpgradeToHigherVersion": "100",
        "ForceUpgradeOrDowngrade": "110",
        "SpecialFirmwareUpdate": "111"
    }
    file = null;
    isUrlValid = false;

    componentDidMount() {
        if (this.props.error) {
            this.setState({ urlStatus: "InvalidFirmwareUrlForm", urlStatusClass: "error" })
        }
    }

    // Handle the change of input type File or URL
    onInputOptionChange(event) {
        this.setState({ inputOption: event.target.value });
    }

    // Handle URL change
    onUrlChange = (event) => {
        let urlStatus = ""
        let urlStatusClass = "info"
        if (!urlValidation.isValidFirmwareUrl(event.target.value)) {
            urlStatus = "InvalidFirmwareUrlForm"
            urlStatusClass = "error"
        }
        this.setState({ [event.target.name]: event.target.value, urlStatus: urlStatus, urlStatusClass: urlStatusClass });
        if (this.props.onChange) {
            var value = {
                fwupdateURL: event.target.value,
                forceFwupdate: this.props.content.forceFwupdate,
                downgradeEnable: this.props.content.downgradeEnable,
                specialFwUpdate: this.props.content.specialFwUpdate,
            }
            this.props.onChange(value)
        }
    }

    onFileChange = (event) => {
        this.file = event.target.files[0]
    }

    onSelectChange = (event) => {
        this.setState({ updateType: event.target.value })
        if (this.props.onChange) {
            var value = {
                fwupdateURL: this.props.content.fwupdateURL,
                forceFwupdate: event.target.value[0],
                downgradeEnable: event.target.value[1],
                specialFwUpdate: event.target.value[2],
            }
            this.props.onChange(value)
        }
    }

    // Handle test url button click in ModalUrlInput
    onTestUrlClick = () => {
        //call the fetch function
        if(!this.state.checkingUrl){
            this.validateURL()
        }
        else{
            //pass
        }
    }

    validateURL = (cb) => {
        var url = this.getUrlValue()
        this.setState({checkingUrl: true, urlStatus: "CheckingUrl", urlStatusClass: "info"},()=>{
            API.validateURL(url,()=>{
                this.isUrlValid = true
                this.setState({ urlStatus: "ValidUrl", urlStatusClass: "valid" , checkingUrl: false},()=>{
                    if(this.props.onValidationChange){
                        this.props.onValidationChange(this.isUrlValid)
                    }
                    if(cb){
                        cb()
                    }
                })
            },(res)=>{
                this.setState({ urlStatus: "InvalidUrl", urlStatusClass: "error" , checkingUrl: false},()=>{
                    if(this.props.onValidationChange){
                        this.props.onValidationChange(this.isUrlValid)
                    }
                })
            })
        })
    }

    getUrlValue = () => {
        if (this.props.isModal) {
            return this.state.downloadUrl;
        }
        else if (this.props.content) {
            return this.props.content.fwupdateURL || ""
        }
    }

    getUpdateType = () => {
        var updateType
        if (this.props.isModal) {
            updateType = this.state.updateType
        }
        else{
            if(this.props.content.forceFwupdate){
                updateType = this.props.content.forceFwupdate + this.props.content.downgradeEnable + this.props.content.specialFwUpdate 
            }
            else{
                updateType = "110"
            }
        }
        return updateType
    }
    onHostChange = (event) => {
        this.setState({host: event.target.value})
    }
    onSubmit = () => {
        var selected = this.props.receiver.selected
        if (selected.start >= 0) {
            var macs = Object.keys(selected.macs)
        }
        else {
            return
        }

        // File Upload
        if (this.state.inputOption == "0") {
            this.setState({ showProgress: true })
            API.updateFirmware(this.props.signalr.id, macs, this.file, this.getUpdateType(), (progressEvent) => {
                var percent = progressEvent.loaded / progressEvent.total
                this.setState({ updateProgress: percent })
                if (percent >= 1) {
                    setTimeout(() => {
                        this.props.hideModal()
                    }, 1000)
                }
            })
        } else {
            // URL input
             // Send the request to backend without checking URL 
            //  let url = this.getUrlValue()
            //  let regex = /^(http:\/\/).*\.(img|IMG)$/
            //  if (regex.test(url)) {
            //     API.updateFirmwareUrl(this.props.signalr.id, macs, url, this.state.updateType, (res) => {})
            //     this.props.hideModal();
            //  } else {
            //      this.setState({ urlStatus: "InvalidUrlForm", urlStatusClass: "error" , checkingUrl: false},()=>{
            //          if(this.props.onValidationChange){
            //              this.props.onValidationChange(this.isUrlValid)
            //          }
            //      })
            //  }

             let url = this.getUrlValue()
             if (urlValidation.isValidFirmwareUrl(url)) {
                API.updateFirmwareUrl(this.props.signalr.id, macs, url, this.state.updateType, (res) => {})
                this.props.hideModal();
             } else {
                 this.setState({ urlStatus: "InvalidFirmwareUrlForm", urlStatusClass: "error", checkingUrl: false }, () => {
                     if (this.props.onValidationChange) {
                         this.props.onValidationChange(this.isUrlValid)
                     }
                 })
             }
            // Check if Url is reachable
            // var url = this.getUrlValue()
            // this.validateURL(() => {
            //     API.updateFirmwareUrl(this.props.signalr.id, macs, url, this.state.updateType, (res) => {
            //     })
            //     this.props.hideModal();
            // })
        }

    }
    getFileInput() {
        if (this.state.inputOption == "0") {
            return (
                <div>
                    <ModalTextInput
                        value={this.state.host} 
                        label="Host" 
                        onChange={this.onHostChange}
                        info="FileUploadInfo"
                    />
                    <ModalFileInput
                        name="imageFile"
                        label="FirmwareFile"
                        onChange={(event) => { this.onFileChange(event) }}
                        info="FirmwareFileInfo" />
                    {this.state.showProgress && <ProgressBar progress={this.state.updateProgress * 100} />}

                </div>
            )
        }
    }

    getUrlInput() {
        if (this.state.inputOption == "1") {
            return (
                <div>
                    <ModalUrlInput
                        label="FirmwareUrl"
                        name="downloadUrl"
                        value={this.getUrlValue()}
                        onChange={this.onUrlChange}
                        info="FirmwareUrlInfo"
                        onTestUrlClick={this.onTestUrlClick}
                        urlStatus={this.state.urlStatus}
                        urlStatusClass={this.state.urlStatusClass}
                    />
                </div>
            );
        }
    }

    render() {
        return (
            <div className='modal-body'>
                <ModalSelect onChange={(event) => { this.onSelectChange(event) }} options={this.options} label="UpdateType" value={this.getUpdateType()} customClassName="customize-column-width" customDropdownClassName="col-sm-5"/>
                {/* {this.props.isModal &&
                    <ModalRadio label="InputType"
                        disabled={false}
                        options={{ "File": "0", "Url": "1" }}
                        checked={this.state.inputOption}
                        onChange={(event) => { this.onInputOptionChange(event) }} />
                } */}
                {/* {this.getFileInput()} */}
                {this.getUrlInput()}
                {this.props.isModal && !this.state.showProgress && <ModalFooter button2Submit={() => { this.onSubmit() }} />}
            </div>
        );
    }
}


export default connect(
    state => ({ receiver: state.receiver, signalr: state.signalr }),
    dispatch => bindActionCreators({ ...modalActions, ...signalRActions }, dispatch)
)(UpdateFirmware);
