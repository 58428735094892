import React from 'react'
import ModalRadio from '../Modals/ModalInputs/ModalRadio'
import ModalIp from '../Modals/ModalInputs/ModalIp'
import {Translate} from 'react-localize-redux'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as usbProvisioningActions } from '../../store/UsbProvisioning'

class TcpIpSettings extends React.Component {
    state = {
        ethIpPolicy: {
            "type": "radioButton",
            "label": "IpAssignment",
            "info": "",
            "display": "0",
            "value": "1",
            "options": {
                "Auto": "1",
                "Static": "0"
            }
        },
        ethIpaddr: {
            "type": "ip",
            "label": "IpAddress",
            "info": "",
            "display": "0",
            "value": "0.0.0.0",
            "dependencies": ["ethIpPolicy"],
            "dependencyMap": {
                "1": "2"
            }
        },
        ethSubnet: {
            "type": "ip",
            "label": "SubnetMask",
            "info": "",
            "display": "0",
            "value": "0.0.0.0",
            "dependencies": ["ethIpPolicy"],
            "dependencyMap": {
                "1": "2"
            }
        },
        defaultGateway: {
            "type": "ip",
            "label": "DefaultGateway",
            "info": "",
            "display": "0",
            "value": "0.0.0.0",
            "dependencies": ["ethIpPolicy"],
            "dependencyMap": {
                "1": "2"
            }
        },
        DNSPolicy: {
            "type": "radioButton",
            "label": "DnsAssignment",
            "info": "",
            "display": "0",
            "value": "1",
            "options": {
                "Auto": "1",
                "Static": "0"
            },
            "dependencies": ["ethIpPolicy"],
            "dependencyMap": {
                "0": "2"
            }
        },
        primaryDNS: {
            "type": "ip",
            "label": "PrimaryDnsServer",
            "info": "",
            "display": "0",
            "value": "",
            "dependencies": ["DNSPolicy"],
            "dependencyMap": {
                "1": "2"
            }
        },
        secondDNS: {
            "type": "ip",
            "label": "SecondaryDnsServer",
            "info": "",
            "display": "0",
            "value": "",
            "dependencies": ["DNSPolicy"],
            "dependencyMap": {
                "1": "2"
            }
        }
    }
    data = {
        "ethIpPolicy": "1",
        "ethIpaddr": "0.0.0.0",
        "ethSubnet": "0.0.0.0",
        "defaultGateway": "0.0.0.0",
        "DNSPolicy": "1",
        "primaryDNS": "",
        "secondDNS": ""
    }

    componentDidMount = () => {
        this.props.updateTcpIpSettings(this.data, true)
    }

    onChange(event, key) {
        if (key == "ethIpPolicy" && event.target.value == "0") {
            this.data[key] = event.target.value;
            this.data["DNSPolicy"] = event.target.value
            this.props.updateTcpIpSettings(this.data, true)
            this.setState({
                ...this.state,
                [key]: {
                    ...this.state[key],
                    "value": event.target.value
                },
                DNSPolicy: {
                    ...this.state["DNSPolicy"],
                    "value": event.target.value
                }
                // staAutoDnsEnable: {
                //     ...this.state["DNSPolicy"],
                //     "value": "0"
                // }
            });

        } else {
            this.data[key] = event.target.value;
            this.props.updateTcpIpSettings(this.data, true)            
            this.setState({
                ...this.state,
                [key]: {
                    ...this.state[key],
                    "value": event.target.value
                }
            });
        }

    }

    Content() {
        return (
            <div>
                {
                    Object.keys(this.state).map((key, index) => {
                        //Map through each object based on tabContent
                        var setting = this.state[key];
                        if (!setting) {
                            return null;
                        }
                        var display = setting.display;

                        //If there are dependencies loop through them
                        if (setting.dependencies) {
                            var dependencyKey = ""; //Build a string based on the values of the dependencies\
                            //!!!!!!!!!!!This algorithm may cause issues if feature values become longer than 1 digit!!!!!!!!!!!!!!!!!
                            setting.dependencies.forEach((dependency) => {
                                dependencyKey += this.state[dependency].value;
                            });
                            display = setting.dependencyMap[dependencyKey] || display;
                        }
                        if (display === "1") {
                            return null;
                        } //1: Don't show

                        //Switch case for all setting component types
                        if (setting.type === "radioButton") {
                            return (
                                <ModalRadio
                                    index={index}
                                    id={key}
                                    label={setting.label}
                                    info={setting.info}
                                    disabled={display === "2" ? true : false}
                                    options={setting.options}
                                    checked={setting.value}
                                    onChange={(event) => { this.onChange(event, key) }} />
                            );
                        } else if (setting.type === "ip") {
                            return (
                                <ModalIp
                                    index={index}
                                    label={setting.label}
                                    value={setting.value}
                                    info={setting.info}
                                    disabled={display === "2" ? true : false}
                                    onChange={(event) => { this.onChange(event, key) }}
                                    error={setting.error} />
                            );
                        }
                    })
                }
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.Content()}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => bindActionCreators({ ...usbProvisioningActions }, dispatch)
)(TcpIpSettings);