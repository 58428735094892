import React from 'react';
// import './ReceiverPageContentStyle.css'
import './ReceiverLogPage.css'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userActions } from '../../store/User'
import TabbedCardWrapper from '../../components/Wrappers/TabbedCardWrapper'
import TabbedTableWrapper from '../../components/Wrappers/TabbedTableWrapper'
import ReceiverLogTable from '../../components/ReceiverLogTable/ReceiverLogTable'
import { actionCreators as notificationActions, notifications, notificationTypes } from '../../store/Notifications'
import { actionCreators as modalActions} from '../../store/Modal'
import NoPrivilege from '../../components/NoPrivilegeGeneric'




class ReceiverLogPage extends React.Component {
    state = {
        siteGroupTabIndex: 0,
        tableTabIndex: 0
    }
    componentDidMount() {
        if(this.props.location.pathname == "/Logs/ServerBusy"){
            this.props.createModal("Server Busy")
        }
        this.props.validate();
    }

    render() {
        if(this.props.isSystemAdmin == true || this.props.isSystemAdmin == "true"){
            return (
                <div id="receiver-log-page" className="row no-gutters" >
                    <div id="receiver-log-card" className="col-md-12">
                        <div className="card-wrapper">
                            <div className="card">
                                <ReceiverLogTable />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <NoPrivilege/>
            )
        }
    }
}

export default connect(
    state => state.user,
    dispatch => bindActionCreators({ ...userActions , ...notificationActions, ...modalActions}, dispatch)
)(ReceiverLogPage);