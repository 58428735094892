import React from 'react';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/User';
import { Translate } from "react-localize-redux";

class MenuItem extends React.Component {
    render() {
        return (
            <li className='nav-item'>
                <Link id={this.props.id} to={(this.props.id == 'MemberManagement') ? "/admin/MemberManagement" : "/" + this.props.id} className={("nav-link ") + (this.props.selected ? "selected" : "")} onClick={(event) => { this.props.handleClickEvent(event, this.props.id) }}>
                    <i className="material-icons menu-item-icon">{this.props.item[1]}</i>
                    <span><Translate id={this.props.item[0]} /></span>
                </Link>
            </li >
        );
    }
}

export default MenuItem