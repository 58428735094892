import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Translate } from 'react-localize-redux';
import React from 'react';
import englishTranslation from "../../localization/en.translations.json";

import Settings_In_11_0 from './Settings_In_11_0'
import Settings_In_11_1 from './Settings_In_11_1'
import Settings_In_9_6 from './Settings_In_9_6'
import Settings_In_9_15 from './Settings_In_9_15'

class SettingHelpers {

    static setSettingState = (_this, key, subkey, value, cb = null) => {
        _this.setState({
            settings: {
                ..._this.state.settings,
                [key]: {
                    ..._this.state.settings[key],
                    [subkey]: value
                }
            }
        }, cb)
    }

    static getSettingsBasedOnFirmware = (version) => {
        var settings = {}
        if (version == "ScreenBeam960Firmware9dot6" || version == "EnterpriseEdition950A" || String(version).startsWith("9.6")) {
            settings = Settings_In_9_6.getAllSettings()
        } else if (version == "ScreenBeam960Firmware9dot15" || String(version).startsWith("9.15")) {
            settings = Settings_In_9_15.getAllSettings()
        } else if (version == "ScreenBeam1100Plus" || String(version).startsWith("11.1")) {
            settings = Settings_In_11_1.getAllSettings()
        } else {
            settings = Settings_In_11_0.getAllSettings()
        }
        return settings
    }

    static check_device_name = (str, supportChinese) => {
        var name = str;
        var regEx;

        // regEx = /(^\s+)|(\s+$)/g;
        // if (regEx.test(name)) {
        //     // "The first and last character of Device Name cannot be blank."
        //     return "EmptyDeviceNameError";
        // }
        if (name[0] == " " || name[name.length - 1] == " ") {
            return "EmptyDeviceNameError";
        }

        if (supportChinese) {
            regEx = /^[A-Za-z0-9\u3040-\u30FF\u31F0-\u31FF\u4E00-\u9FBF~!@#$%^*()_+{}|:?\-=\[\];'./(\s*)]*$/g;
        } else {
            regEx = /^[A-Za-z0-9~!@#$%^*()_+{}|:?\-=\[\];'./(\s*)]*$/g;
        }
        if (!regEx.test(name)) {
            return "InvalidDeviceNameError";
        }
        return "";
    }

    static getTimezoneList = (version) => {
        let timezone;
        switch (version) {
            case "9.6": {
                timezone = {
                    "(UTC - 12:00) International Date Line West": "0",
                    "(UTC - 11:00) American Samoas": "1",
                    "(UTC - 10:00) Honolulu": "2",
                    "(UTC - 09:30) French Polynesia": "3",
                    "(UTC - 09:00) Anchorage": "4",
                    "(UTC - 08:00) Pacific Time": "5",
                    "(UTC - 07:00) Mountain Time": "6",
                    "(UTC - 06:00) Central Time": "7",
                    "(UTC - 05:00) Eastern Time": "8",
                    "(UTC - 04:30) Caracas": "9",
                    "(UTC - 04:00) Atlantic Time": "10",
                    "(UTC - 03:30) Newfoundland": "11",
                    "(UTC - 03:00) Buenos Aires": "12",
                    "(UTC - 02:00) South Sandwish Island": "13",
                    "(UTC - 01:00) Azores Islands": "14",
                    "(UTC + 00:00) Greenwich": "15",
                    "(UTC + 01:00) Amsterdam": "16",
                    "(UTC + 02:00) Cairo": "17",
                    "(UTC + 03:00) Moscow": "18",
                    "(UTC + 03:30) Tehran": "19",
                    "(UTC + 04:00) Dubai": "20",
                    "(UTC + 04:30) Kabul": "21",
                    "(UTC + 05:00) Islamabad": "22",
                    "(UTC + 05:30) New Delhi": "23",
                    "(UTC + 05:45) Katmandu": "24",
                    "(UTC + 06:00) Dhaka": "25",
                    "(UTC + 06:30) Rangoon": "26",
                    "(UTC + 07:00) Bangkok": "27",
                    "(UTC + 08:00) Beijing": "28",
                    "(UTC + 09:00) Tokyo": "29",
                    "(UTC + 09:30) Adelaide": "30",
                    "(UTC + 10:00) Sydney": "31",
                    "(UTC + 11:00) Solomon Island": "32",
                    "(UTC + 12:00) Auckland": "33",
                    "(UTC + 13:00) Samoa": "34",
                    "(UTC + 14:00) Kiritimati Island": "35"
                }
                break;
            }
            case "9.15": {
                timezone = {
                    "(UTC - 12:00) International Date Line West": "0",
                    "(UTC - 11:00) American Samoas": "1",
                    "(UTC - 10:00) Honolulu": "2",
                    "(UTC - 09:30) French Polynesia": "3",
                    "(UTC - 09:00) Anchorage": "4",
                    "(UTC - 08:00) Pacific Time": "5",
                    "(UTC - 07:00) Mountain Time": "6",
                    "(UTC - 06:00) Central Time": "7",
                    "(UTC - 05:00) Eastern Time": "8",
                    "(UTC - 04:30) Caracas": "9",
                    "(UTC - 04:00) Atlantic Time": "10",
                    "(UTC - 03:30) Newfoundland": "11",
                    "(UTC - 03:00) Buenos Aires": "12",
                    "(UTC - 02:00) South Sandwish Island": "13",
                    "(UTC - 01:00) Azores Islands": "14",
                    "(UTC + 00:00) Greenwich": "15",
                    "(UTC + 01:00) Amsterdam": "16",
                    "(UTC + 02:00) Cairo": "17",
                    "(UTC + 03:00) Moscow": "18",
                    "(UTC + 03:30) Tehran": "19",
                    "(UTC + 04:00) Dubai": "20",
                    "(UTC + 04:30) Kabul": "21",
                    "(UTC + 05:00) Islamabad": "22",
                    "(UTC + 05:30) New Delhi": "23",
                    "(UTC + 05:45) Katmandu": "24",
                    "(UTC + 06:00) Dhaka": "25",
                    "(UTC + 06:30) Rangoon": "26",
                    "(UTC + 07:00) Bangkok": "27",
                    "(UTC + 08:00) Beijing": "28",
                    "(UTC + 09:00) Tokyo": "29",
                    "(UTC + 09:30) Adelaide": "30",
                    "(UTC + 10:00) Sydney": "31",
                    "(UTC + 11:00) Solomon Island": "32",
                    "(UTC + 12:00) Auckland": "33",
                    "(UTC + 13:00) Samoa": "34",
                    "(UTC + 14:00) Kiritimati Island": "35"
                }
                break;
            }
            default:
            // SB1100/1000
                timezone = {
                    "(UTC - 11:00) American Samoas": "0",
                    "(UTC - 10:00) Honolulu": "1",
                    "(UTC - 09:30) French Polynesia": "2",
                    "(UTC - 09:00) Anchorage": "3",
                    "(UTC - 08:00) Pacific Time": "4",
                    "(UTC - 07:00) Mountain Time": "5",
                    "(UTC - 06:00) Central Time": "6",
                    "(UTC - 05:00) Eastern Time": "7",
                    "(UTC - 05:00) Mexico Time": "8",
                    "(UTC - 04:30) Caracas": "9",
                    "(UTC - 04:00) Atlantic Time": "10",
                    "(UTC - 03:30) Newfoundland": "11",
                    "(UTC - 03:00) Buenos Aires": "12",
                    "(UTC - 02:00) South Sandwish Island": "13",
                    "(UTC - 01:00) Azores Islands": "14",
                    "(UTC + 00:00) Universal Time": "15",
                    "(UTC + 00:00) Greenwich": "16",
                    "(UTC + 01:00) Amsterdam": "17",
                    "(UTC + 02:00) Cairo": "18",
                    "(UTC + 03:00) Moscow": "19",
                    "(UTC + 03:30) Tehran": "20",
                    "(UTC + 04:00) Dubai": "21",
                    "(UTC + 04:30) Kabul": "22",
                    "(UTC + 05:00) Islamabad": "23",
                    "(UTC + 05:30) New Delhi": "24",
                    "(UTC + 05:45) Katmandu": "25",
                    "(UTC + 06:00) Dhaka": "26",
                    "(UTC + 06:30) Rangoon": "27",
                    "(UTC + 08:00) Beijing": "28",
                    "(UTC + 09:00) Tokyo": "29",
                    "(UTC + 09:30) Adelaide": "30",
                    "(UTC + 10:00) Sydney": "31",
                    "(UTC + 11:00) Solomon Island": "32",
                    "(UTC + 12:00) Auckland": "33",
                    "(UTC + 13:00) Samoa": "34",
                    "(UTC + 14:00) Kiritimati Island": "35"
                }
                break;
        }
        return timezone;
    }

    static check_screen_saver_second = (value) => {
        var regex = /^[0-9]*$/;
        if (!regex.test(value)) {
            return "InvalidScreenSaverSecond"
        }
    }
    static check_number_input = (value) => {
        var regex = /^[0-9]*$/;
        return regex.test(value)
    }
    static getP2POperatingChannel = (chnPlan) => {
        let p2pOperatingChannel;
        switch (chnPlan) {
            case "12": //0x0C
                p2pOperatingChannel = {
                    "des": "CountryName0x0C",//China, Taiwan
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                };
                break;
            case "17": //0x11
                p2pOperatingChannel = {
                    "des": "CountryName0x11", //"United States, Canada", 
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                };
                break;
            case "18": //0x12
                p2pOperatingChannel = {
                    "des": "CountryName0x12", //"European Union, EUTA, Hong Kong, Japan"
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48"
                    }
                };
                break;
            case "127": //0x7F
                p2pOperatingChannel = {
                    "des": "CountryName0x7F", //"Australia, New Zealand, Brazil"
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                };
                break;
            case "67": //0x43
                p2pOperatingChannel = {
                    "des": "CountryName0x43",//"United States, Canada, Taiwan, India, Colombia, Peru"
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                };
                break;
            case "66": //0x42
                p2pOperatingChannel = {
                    "des": "CountryName0x42", //"European Union, Japan"
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48"
                    }
                };
                break;
            case "50": //0x32
                p2pOperatingChannel = {
                    "des": "CountryName0x32", //"China"
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                };
                break;
            case "41": //0x29
                p2pOperatingChannel = {
                    "des": "CountryName0x29", //"Australia, New Zealand"
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "12": "12",
                        "13": "13",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                };
                break;
            default:
                p2pOperatingChannel = {
                    "des": "CountryName0x11", //"United States, Canada", 
                    "channels": {
                        "1": "1",
                        "2": "2",
                        "3": "3",
                        "4": "4",
                        "5": "5",
                        "6": "6",
                        "7": "7",
                        "8": "8",
                        "9": "9",
                        "10": "10",
                        "11": "11",
                        "36": "36",
                        "40": "40",
                        "44": "44",
                        "48": "48",
                        "149": "149",
                        "153": "153",
                        "157": "157",
                        "161": "161",
                        "165": "165"
                    }
                };
                break;
        }
        return p2pOperatingChannel;
    }

    static check_hostname = (str) => {
        var rex = /^(([A-Za-z0-9][A-Za-z0-9-]{0,61})?[A-Za-z0-9]){1,1}$/i;
        if (rex.test(str))
            return true;
        else
            return false;
    }

    static check_domain = (str) => {
        var rex = /^(([A-Za-z0-9][A-Za-z0-9-]{0,61})?[A-Za-z0-9]\.){1,5}[a-zA-Z]{2,63}$/i;
        if (rex.test(str))
            return true;
        else
            return false;
    }

    static check_ip = (ip) => {
        var re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
        if (re.test(ip)) {
            if (RegExp.$1.length > 3 || RegExp.$2.length > 3 || RegExp.$3.length > 3 || RegExp.$4.length > 3)
                return false;
            if (RegExp.$1 < 256 && RegExp.$2 < 256 && RegExp.$3 < 256 && RegExp.$4 < 256)
                return true;
        }

        return false;
    }

    static check_cms_host = (hostValue) => {
        if (hostValue.length <= 0)
            return "CannotBeEmptyErr";

        if (hostValue == "") {
            return "CannotBeEmptyErr";
        }

        if (hostValue.length > 255) {
            // Cannot exceed 255 characters
            return "CannotExceed255Err";
        }

        if (this.check_ip(hostValue))
            return "";
        if (this.check_hostname(hostValue))
            return "";
        if (this.check_domain(hostValue))
            return "";

        // Invalid IP, hostname or domain name
        return "InvalidIPHostnameDomainErr";
    }

    static check_cms_port = (cmsPortVal) => {
        if (cmsPortVal === "") {
            return "CannotBeEmptyErr";
        }

        if (cmsPortVal < 5000 || cmsPortVal > 65535) {
            // "Invalid entry. The value must be between 5000 - 65535."
            return "CmsCommunicationPortErr";
        }
        return "";
    }

    static check_web_port = (webPortVal) => {
        if (webPortVal === "") {
            return "CannotBeEmptyErr";
        }

        if ((webPortVal != 443) && (webPortVal < 5000 || webPortVal > 65535)) {
            // "Invalid entry. The value must be between 5000 - 65535."
            return "WebCommunicationPortErr";
        }
        if (webPortVal === "5555") {
            return "WebCommunicationPortErr2";
        }
        return "";
    }

    static check_ssh_port = (sshPortVal) => {
        if (sshPortVal === "") {
            return "CannotBeEmptyErr";
        }

        if ((sshPortVal != 22) && (sshPortVal < 5000 || sshPortVal > 65535)) {
            // "Invalid entry. The value must be between 5000 - 65535."
            return "SshCommunicationPortInfo";
        }

        return "";
    }

    static setChangeMapModifiedPinFea = (_this) => {
        _this.changeMap["pinEnforceEnable"] = _this.state.settings["pinEnforceEnable"].value;
        if (_this.changeMap["pinEnforceEnable"] === "1") {
            _this.changeMap["pinEachConnEnable"] = _this.state.settings["pinEachConnEnable"].value;
            _this.changeMap["fourDigitPINEn"] = _this.state.settings["fourDigitPINEn"].value;
            _this.changeMap["staticPinEnable"] = _this.state.settings["staticPinEnable"].value;
            if (_this.state.settings["staticPinEnable"].value === "1") {
                _this.changeMap["staticPinVal"] = _this.state.settings["staticPinVal"].value;
            }
        }
    }

    /**
     * Only for sb960 9.15/9.6
     * @param {any} _this
     * @param {any} onChangeIPAssignment
     */
    static setEthernetTCPIPSettings = (_this, onChangeIPAssignment) => {
        if (_this.state.settings["ethDhcpEnable"].value === "0") {
            _this.changeMap["ethDhcpEnable"] = _this.state.settings["ethDhcpEnable"].value;
            _this.changeMap["ethIpaddr"] = _this.state.settings["ethIpaddr"].value;
            _this.changeMap["ethSubnet"] = _this.state.settings["ethSubnet"].value;
            _this.changeMap["defaultGateway"] = _this.state.settings["defaultGateway"].value;
            if (onChangeIPAssignment) {
                _this.changeMap["DNSPolicy"] = "0";
                _this.changeMap["primaryDNS"] = _this.state.settings["primaryDNS"].value;
                _this.changeMap["secondDNS"] = _this.state.settings["secondDNS"].value;

                _this.setState({
                    settings: {
                        ..._this.state.settings,
                        "DNSPolicy": {
                            ..._this.state.settings["DNSPolicy"],
                            value: "0"
                        }
                    }
                });
            }
        }
    }

    /**
     * Only for sb960 9.15/9.6
     * @param {any} _this
     */
    static setEthernetDNSAssignmentSettings = (_this) => {
        if (_this.state.settings["DNSPolicy"].value === "0") {
            _this.changeMap["DNSPolicy"] = _this.state.settings["DNSPolicy"].value;
            _this.changeMap["primaryDNS"] = _this.state.settings["primaryDNS"].value;
            _this.changeMap["secondDNS"] = _this.state.settings["secondDNS"].value;
        }
    }

    static setWirelessInterfaceConnSettings = (_this) => {
        _this.changeMap["CurConnectApSecType"] = _this.state.settings["CurConnectApSecType"].value;
        switch (_this.changeMap["CurConnectApSecType"]) {
            case "0":
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                }
                break;
            case "5":
            case "6":
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                    _this.changeMap["CurConnectApRadiusId"] = _this.state.settings["CurConnectApRadiusId"].value;
                    _this.changeMap["CurConnectApPwd"] = _this.state.settings["CurConnectApPwd"].value;
                }
                break;
            case "1":
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                    _this.changeMap["CurConnectApPwd"] = _this.state.settings["CurConnectApPwd"].value;
                }
                break;
            default:
                {
                    _this.changeMap["CurConnectApName"] = _this.state.settings["CurConnectApName"].value;
                    _this.changeMap["CurConnectApPwd"] = _this.state.settings["CurConnectApPwd"].value;
                }
                break;
        }
    }

    static setDigitalSignageModeSettings = (_this) => {
        _this.changeMap["digitalSignEn"] = _this.state.settings["digitalSignEn"].value;
        _this.changeMap["digitalSignFullScreenEn"] = _this.state.settings["digitalSignFullScreenEn"].value;
        _this.changeMap["digitalSignUrl"] = _this.state.settings["digitalSignUrl"].value;
        _this.changeMap["digitalSignDelaySec"] = _this.state.settings["digitalSignDelaySec"].value;
        _this.changeMap["digitalSignAudioVol"] = _this.state.settings["digitalSignAudioVol"].value;
    }

    static ChangeLMIInterfaceAllowedList = (_this, _settings, onStartType, _data) => {
        /* 0: display all; 1:Remove Local WiFi AP; 2:Remove MiracastP2P; 3: Remove Remove Local WiFi AP + MiracastP2P*/
        var optionsType = 0;
        var removeLocalWiFiAP = false;
        var removeMiracastP2P = false;
        var WiFiOperateModeVal;
        var ApBsS1EnableVal;
        var miracastConnEnableVal;
        if (_data && onStartType) {
            WiFiOperateModeVal = _data["WiFiOperateMode"];
            ApBsS1EnableVal = _data["ApBsS1Enable"];
            miracastConnEnableVal = _data["miracastConnEnable"];
        } else {
            WiFiOperateModeVal = _settings["WiFiOperateMode"].value;
            ApBsS1EnableVal = _settings["ApBsS1Enable"].value;
            miracastConnEnableVal = _settings["miracastConnEnable"].value;
        }
        if ((_settings["networkInterfaceAllowed"].value != "3")
            && (WiFiOperateModeVal === "1" || ApBsS1EnableVal === "0")) {
            removeLocalWiFiAP = true;
        }
        if ((_settings["networkInterfaceAllowed"].value != "4")
            && (miracastConnEnableVal === "0")) {
            removeMiracastP2P = true;
        }
        if (removeLocalWiFiAP && removeMiracastP2P) {
            optionsType = 3;
        } else if (removeLocalWiFiAP) {
            optionsType = 1;
        } else if (removeMiracastP2P) {
            optionsType = 2;
        }
        var optionsTemp = "";
        switch (optionsType) {
            case 0:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2",
                        "LocalWi-FiAP": "3",
                        "MiracastP2P": "4"
                    };
                }
                break;
            case 1:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2",
                        "MiracastP2P": "4"
                    };
                }
                break;
            case 2:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2",
                        "LocalWi-FiAP": "3"
                    };
                }
                break;
            case 3:
                {
                    optionsTemp = {
                        "Any": "0",
                        "EthernetLAN": "1",
                        "WirelessLAN": "2"
                    };
                }
                break;
            default:
                break;
        }
        if (onStartType) {
            _settings["networkInterfaceAllowed"].options = optionsTemp;
        } else {
            this.setSettingState(_this, "networkInterfaceAllowed", "options", optionsTemp);
        }
    }

    static handleWarningClickedNo = (_this, key) => {
        this.setSettingState(_this, key, "value", "1");
        _this.changeMap[key] = "1";
    }

    static popupConfirmAlertDialog = (/*_this, key,*/displayMsg, WarningOnCancel, WarningOnProceed) => {
        const addDialog = ({ onClose }) => {
            let handleClickedNo = () => {
                //setSettingState(_this, key, "value", "1");
                //_this.changeMap[key] = "1";
                if (WarningOnCancel) {
                    WarningOnCancel();
                }
                onClose();
            }
            const handleClickedYes = () => {
                if (WarningOnProceed) {
                    WarningOnProceed();
                }
                onClose()
            }
            //handleClickedNo = handleClickedNo.bind(_this, key);
            const customStyles = {
                content: {
                    top: '30px',
                    left: '30px',
                    right: '30px',
                    bottom: '30px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            }
            return (
                <div>
                    <div className='add-dialog light-mode ConfirmAlertOverlay' styles={customStyles}>
                        <div className="modal-header">
                            <h5 className="modal-title">{englishTranslation["Warning"]}</h5>
                            <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close" onClick={handleClickedNo}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            {<div>
                                {englishTranslation[displayMsg]}
                            </div>
                            }
                        </div>
                        {
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" onClick={handleClickedNo}>{englishTranslation["Cancel"]}</button>
                                <button type="button" className="btn btn-primary" onClick={handleClickedYes}>{englishTranslation["Proceed"]}</button>
                            </div>
                        }
                    </div>
                </div>
            )
        };
        confirmAlert({ customUI: addDialog });
    }
    static compareNumbers = (a, b) => {
        return a - b
    }
}

export default SettingHelpers