import React from 'react';
import ModalSelect from '../ModalInputs/ModalSelect'
import ModalFileInput from '../ModalInputs/ModalFileInput'
import ProgressBar from '../../ProgressBar/ProgressBar'

//FOR USE IN SCHEDULER/STANDALONE MODAL

var options={
    "Per receiver's setting": "000",
    "Force upgrade to a higher version": "100",
    "Force upgrade or downgrade": "110",
    "Special Firmware Update": "111"
}

class UpdateFirmwareBody extends React.Component {    
    render() {
        return (     
            <div>
                <ModalSelect onChange={(event)=>{this.props.onSelectChange(event)}} options={options} name="Update Type" value={this.props.updateType}/>
                <ModalFileInput index="0" onChange={(event)=>{this.props.onFileChange(event)}} name="Firmware file upload"/>
                {this.props.showProgress && <ProgressBar progress={this.props.progress}/>}
            </div>
        );
    }
}
 


export default UpdateFirmwareBody
