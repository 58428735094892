
import React from 'react';
import {Translate} from 'react-localize-redux'

const SettingSubtitle = (props) => {
    return (
        <div>
            <p className="subsection-title"><Translate id={props.title}/></p>
        </div>
    );
}

export default SettingSubtitle