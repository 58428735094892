import React from 'react';
import {Translate} from 'react-localize-redux'

class InputBox extends React.Component {
    render() {
        return (     
            <div>
                <div className="modal-label">{this.props.label}</div>
                <div className="add-user-input-box" id="add-user-input-box">
                    <Translate>
                        {({translate}) => 
                        <input type="text" className="name-search" placeholder={translate("NameSearch")} value={this.props.value} onChange={this.props.onChange}/>
                        }
                    </Translate>
                    <div className="input-user-box">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}


export default InputBox