import Settings from '../Utils/AllSettings'

const stateType = 'UPDATE_STATE'
const dataType = "DATA_TYPE"
const validateType = "VALIDATION_TYPE"
const resetType = "RESET_TYPE"
const fileType = "UPDATE_ZIP_FILE"

const initialState = {
    state: null,
    data: null,
    fwVersion: null,
    isDataValid: true,
    zipFile: null    
};

export const actionCreators = {
    saveState: (state, data, fwVersion) => async (dispatch, getState) => {
        // console.log(data)
        dispatch({ type: stateType, state: state, data: data, fwVersion: fwVersion })
    },
    saveData: (data, fwVersion, cb) => async (dispatch, getState) => {
        // Example of fwVersion: {fwVersion: "ScreenBeam1100Plus", model: "SBWD1100P"}
        //Saves policy_content and firmware version to store for later use
        dispatch({ type: dataType, data: data, fwVersion: fwVersion })
        if (cb) cb()
    },
    saveZipFile: (file) => async (dispatch, getState) => {
        dispatch({type: fileType, zipFile: file})
    },
    loadData: (state, checkboxDict, cb) => async (dispatch, getState) => {
        //Called during initialization of Settings.js component
        //Maps the data into state
        // var state = getState().settings.state
        var data = getState().settings.data
        var fwVersion = getState().settings.fwVersion
        //Create a reverse mapping from checkbox children to checkboxes
        //This technically only needs to be created once and should be moved to the "Allsettings" file|performance increase should be minimal
        var reverseDict = {}
        Object.keys(checkboxDict).forEach((checkboxKey) => {
            var checkbox = checkboxDict[checkboxKey]
            if (checkbox.type == "checkbox") {
                checkbox.children.forEach((child) => {
                    reverseDict[child] = checkboxKey
                })
            }
        })
        // Go through each setting and parse data
        for (let key in state.settings) {
            if (data[key]) {
                state.settings[key].value = data[key];
                // console.log(state.settings[key].value);
                
                // Check the checkbox
                if (state.selectedCheckbox[reverseDict[key]]) {
                    state.selectedCheckbox[reverseDict[key]].selected = "1"
                }
            }
              // Call onStart method on Object. Used when one feature depends on other feature.
              if (state.settings[key].onStart) {
                let temp = state.settings[key].onStart(state.settings, data);
            }

        }
        //Toggle all related checkboxes to "1"
        // Object.keys(data).forEach((key) => {
        //     if (state.settings[key] != null && state.settings[key] != undefined) {
        //         state.settings[key].value = data[key]
        //         if (state.settings[key].onStart) {
        //             let temp = state.settings[key].onStart(state.settings, data)
        //             state.settings[key] = temp
        //         }
        //         if (state.selectedCheckbox[reverseDict[key]]) {
        //             state.selectedCheckbox[reverseDict[key]].selected = "1"
        //         }
        //     }
        // })
        dispatch({ type: stateType, state: state, data: data, fwVersion: fwVersion })
        cb()
    },
    validateData: (cb) => async (dispatch, getState) => {
        var state = getState().settings.state
        var data = getState().settings.data
        var isDataValid = true
        if (Object.keys(data).length > 0) {
            Object.keys(data).forEach((item) => {
                // Check if the item is in settings, then validate it
                if (state.settings[item]) {
                    var type = state.settings[item].type
                    var display = state.settings[item].display
                    // var error = state.settings[item].error
                    if (state.settings[item].validate) {
                        var value = state.settings[item].value
                        var error = state.settings[item].validate(value, getState().settings)
                        if (error) {
                            isDataValid = false
                            state.settings[item].error = error
                        }
                    }
                }
            })
        } else isDataValid = false

        dispatch({ type: validateType, state: state, isDataValid: isDataValid })
        cb(isDataValid, data)
    },
    resetState: () => async (dispatch, getState) => {
        dispatch({ type: resetType, state: null, data: null, fwVersion: null, isDataValid: true })
    }
};

export const reducer = (state, action) => {
    //Initialize state
    state = state || initialState;

    //Return copy of state with edited state
    if (action.type === stateType) {
        return { ...state, state: action.state, data: action.data, fwVersion: action.fwVersion };
    }
    if (action.type === dataType) {
        return { ...state, data: action.data, fwVersion: action.fwVersion }
    }
    if (action.type === fileType) {
        return { ...state, data: action.data, fwVersion: action.fwVersion, isDataValid: action.isDataValid, zipFile: action.zipFile }
    }
    if (action.type === validateType) {
        return { ...state, state: action.state, isDataValid: action.isDataValid }
    }
    if (action.type === resetType) {
        return { ...state, state: action.state, data: action.data, fwVersion: action.fwVersion, isDataValid: true };
    }
    return state;
};
