import React from 'react';
import ModalTextInput from '../ModalInputs/ModalTextInput'

class GeneralScheduleTab extends React.Component {
    render() {
        return (     
            <div>
                <ModalTextInput onChange={(event)=>{this.props.onChange(event,"sche_Name")}} value={this.props.values.sche_Name} label={"Name"} error={this.props.warningActive && this.props.warnings.sche_Name}/>
                <ModalTextInput onChange={(event)=>{this.props.onChange(event,"sche_Desc")}} value={this.props.values.sche_Desc} label={"Description"} error={this.props.warningActive && this.props.warnings.sche_Desc}/> 
            </div>
        );
    }
}


export default GeneralScheduleTab;
